<template>
  <div class="login-container signup_main">
    <div class="login-container-header">SIGN UP</div>
    <div>
      <div class="input p-fluid">

        <div class="email">
          <label for="email"><sup>*</sup> Email</label>
          <InputText id="email" class="top" v-model="email" type="email" placeholder="Enter Email" :class="msg.email ? 'p-error' : ''"/>
          <small class="p-invalid" v-if="msg.email">{{ msg.email }}</small>
        </div>

        <div class="dual_fields pb0">
          <div class="first_name">
            <label for="First-Name"><sup>*</sup> First Name</label>
            <InputText id="First-Name" class="top" v-model="firstName"  type="text" placeholder="Enter First Name" :class="msg.firstName ? 'p-error' : ''"/>
            <small class="p-invalid" v-if="msg.firstName">{{ msg.firstName }}</small>
          </div>

          <div class="last_name">
            <label for="Last-Name"><sup>*</sup> Last Name</label>
            <InputText id="Last-Name" class="top" v-model="lastName" type="text" placeholder="Enter Last Name" :class="msg.lastName ? 'p-error' : ''"/>
            <small class="p-invalid" v-if="msg.lastName">{{ msg.lastName }}</small>
          </div>
        </div>

        <div class="dual_fields pb0">
          <div class="company_name">
            <label for="Company-Name"><sup>*</sup> Company Name</label>
            <InputText id="Company-Name" class="top" v-model="companyName" type="text" placeholder="Company Name" :class="msg.companyName ? 'p-error' : ''"/>
            <small class="p-invalid" v-if="msg.companyName">{{ msg.companyName }}</small>
          </div>

          <div class="last_name">
            <label for="Role"><sup>*</sup> Designation</label>
            <Dropdown :class="msg.role ? 'p-error' : ''" v-model="selectedDesignation" :options="roleList" optionLabel="name" optionValue="name" filter="true" placeholder="Select designation" />
            <small class="p-invalid" v-if="msg.role">{{ msg.role }}</small>
          </div>
        </div>

        <div class="password-sign-up dual_fields">
          <div class="password_field">
            <label for="password"><sup>*</sup> Password</label>
            <Password id="password" class="top" v-model="password" placeholder="Enter Password" :class="msg.password ? 'p-error' : ''" :feedback="false" :autocomplete="false" maxlength="32" toggleMask />
            <small class="p-invalid" v-if="msg.password">{{ msg.password }}</small>
          </div>
          <div class="conf_password_field">
            <label for="password"><sup>*</sup> Confirm Password</label>
            <Password id="password" class="top" v-model="confirmPassword" placeholder="Confirm Password" :class="msg.confirmPassword ? 'p-error' : ''" :feedback="false" :autocomplete="false" maxlength="32" toggleMask :disabled="!password.length" />
            <small class="p-invalid" v-if="msg.confirmPassword">{{ msg.confirmPassword }}</small>
          </div>
        </div>

        <div class="mobile_input_fields pb0">
          <label><sup>*</sup> Phone</label>
          <div :class="['p-field field', msg.inputPhoneNumber ? 'field-err' : '']">
            <div class="phone">
              <div class="country">
                <Dropdown v-model="desiredCountry" :options="countryList" optionLabel="name" :filter="true"
                          @change="onChangeCountryPhoneCode" placeholder="Select a Country">
                  <template #value="slotProps">
                    <div class="country-item country-item-value" v-if="slotProps.value">
                      <img :src="`https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/1x1/${slotProps.value.shortName.toLowerCase()}.svg`" :class="'flag flag-' + slotProps.value.shortName.toLowerCase()"/>
                      <div class="country-name">{{ slotProps.value.name }}</div>
                    </div>
                    <span v-else>
                                        {{ slotProps.placeholder }}
                                    </span>
                  </template>
                  <template #option="slotProps">
                    <div class="country-item">
                      <img :src="`https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/1x1/${slotProps.option.shortName.toLowerCase()}.svg`"
                          :class="'flag ' + slotProps.option.shortName.toLowerCase()"
                      />
                      <div class="country-name">{{ slotProps.option.name }}</div>
                    </div>
                  </template>
                </Dropdown>
              </div>
              <div class="phone-number">
                <InputText v-model="countryCode" type="text" disabled/>
              </div>
              <div class="phone-number-digits">
                <InputText v-model="inputPhoneNumber" type="text" @keypress="numberOnly"
                          placeholder="Enter Phone Number"/>
              </div>
            </div>
          </div>
          <small class="p-invalid" v-if="msg.inputPhoneNumber">{{ msg.inputPhoneNumber }}</small>
        </div>

        <Button class="signUpBtn" label="CREATE ACCOUNT" @click="clickSignupHandler"></Button>
        <div class="sign-in-link">
          Already have an account?
          <router-link to="/login"><u> Sign In</u></router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters } from 'vuex';
import {getOsName} from '../../utils/helpers';

export default {
  data() {
    return {
      error: false,
      msg: {},
      firstName: '',
      lastName: '',
      companyName: '',
      email: '',
      password: '',
      confirmPassword: '',
      desiredCountry: {name: 'United States', shortName: 'US', phoneCode: 1},
      countryCode: '+ 1 ',
      phoneCountryShortName: '',
      selectedDesignation: '',
      emailValidation: false,
      passwordValidation: false,
      inputPhoneNumber: '',
      roleList: [
        { "_id": 1, "name": "Owner" },
        { "_id": 2, "name": "CTO" },
        { "_id": 3, "name": "Project Manager" },
        { "_id": 4, "name": "Software Engineer" },
        { "_id": 5, "name": "SQA Engineer" },
      ],
      blackListedEmailDomains: ["gmail.com", "hotmail.com", "yahoo.com"],
    };
  },
  mounted() {
    this.fetchCountries()

    setTimeout(() => {
      console.log("countryList )))))))))))))))))))))0 ", this.countryList);
    }, 1500);
  },
  computed: {
    ...mapGetters({
      countryList: 'profile/countryList',
    }),
  },
  watch: {
    firstName(value) {
      delete this.msg['firstName']
    },
    lastName(value) {
      delete this.msg['lastName']
    },
    companyName(value) {
      delete this.msg['companyName']
    },
    selectedDesignation(value) {
      delete this.msg['role']
    },
    inputPhoneNumber(value) {
      delete this.msg['inputPhoneNumber']
    },
    email(value) {
      this.validateEmail(value);
    },
    password(value) {
      this.validatePassword(value);
    },
    desiredCountry(val) {
      console.log(val);
    },
    confirmPassword(value) {
      if (value !== this.password) {
        this.msg['confirmPassword'] = 'Password did not match!';
        this.error = true;
      } else {
        delete this.msg['confirmPassword']
        this.error = false;
      }
    },
  },
  methods: {
    ...mapActions({
      sendVerifyEmailToLink: 'auth/actionVerifyEmailLink',
      fetchCountries: 'profile/actionFetchCountryList',
    }),
    numberOnly(evt) {
      // console.log(evt);
      let keyCode = evt.keyCode ? evt.keyCode : evt.which;
      if (keyCode < 48 || keyCode > 57) {
        // 46 is dot
        evt.preventDefault();
      }

      if (evt.target.value.length === 20) {
        evt.preventDefault();
      }
    },
      onChangeCountryPhoneCode() {
      this.countryCode = '+' + ' ' + this.desiredCountry.phoneCode + ' ';
      this.phoneCountryShortName = this.desiredCountry.shortName;
    },


    clickSignupHandler: function () {
      let errored = false;

      if (Object.keys(this.msg).length) {
        console.log(this.msg);
        errored = true;
      }

      if (!this.email) {
        console.log('no email');
        this.msg['email'] = 'Email is required.';
        errored = true;
      }

      if (!this.firstName) {
        console.log('no first name');
        this.msg['firstName'] = 'First name is required.';
        errored = true;
      }

      if (!this.lastName) {
        console.log('no last name');
        this.msg['lastName'] = 'Last name is required.';
        errored = true;
      }

      if (!this.companyName) {
        console.log('no company');
        this.msg['companyName'] = 'Company name is required.';
        errored = true;
      }

      // if (!this.selectedCountry || (this.selectedCountry && !this.selectedCountry.name) ) {
      //   this.msg['country'] = 'Country name is required.';
      //   return
      // }

      if (!this.selectedDesignation) {
        console.log('no designation');
        this.msg['role'] = 'Designation is required.';
        errored = true;
      }

      if (!this.inputPhoneNumber) {
        this.msg['inputPhoneNumber'] = 'phone number is required.';
        errored = true;
      }

      // if (!this.inputPhoneNumber) {}

      if (!this.password || !this.confirmPassword) {
        !this.password ? this.msg['password'] = 'Password field is required.' : null
        !this.confirmPassword ? this.msg['confirmPassword'] = 'Confirm Password field is required.' : null
        errored = true;
      }

      if (errored) {
        return;
      }


        let form_obj = {
          email: this.email.toLowerCase(),
          password: this.password,
          user_profile: {
            first_name : this.firstName,
            last_name: this.lastName,
            phone: this.inputPhoneNumber,
            phoneCountryShortName: this.desiredCountry.shortName,
            company_name: this.companyName,
            designation: this.selectedDesignation
          },
          registration_source: {
            source: 'TestJet',
            type: 'TestJet',
            info: {
              os: getOsName(),
            },
            ip_address: this.deviceIp,
          },
        };

        console.log('form_obj--', form_obj);
        this.$store
            .dispatch('auth/actionSignupHandler', form_obj)
            .then(body => {
              if (body.status === 200) {
                this.msg = [];
                this.error = false;
                this.showSuccess('Success', 'Sign up completed successfully.');
                // this.sendVerifyEmailToLink();
                setTimeout(() => this.$router.push({path: '/verification-email'}), 1000);
              } else if (body.status === 409) {
                this.error = true;
                if (body && body.data && body.data.errorMsg) {
                  this.msg['email'] = body && body.data && body.data.errorMsg;
                }
              } else {
                this.error = false;
                this.showError(body && body.data && body.data.errorMsg);
              }
            })
            .catch(err => console.log(err));
    },

    validateEmail(value) {
            const index = value.indexOf('@');
            const emailDomain = value.substr(index+1)
            const env =process.env.VUE_APP_ENVIRONMENT;
            if (env === "production") {
                this.blackListedEmailDomains.push("yopmail.com","aol.com");
            }
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
                 if (this.blackListedEmailDomains.includes(emailDomain)) {
                    this.msg['email'] = 'Please provide your work email address!';
                    this.error = true;
                    this.emailValidation = false;
                } else {
                    delete this.msg['email'];
                    this.error = false;
                }
            } else {
                this.msg['email'] = 'Invalid email address.';
                this.error = true;
                this.emailValidation = false;
            }
      this.error = false;
    },

    validatePassword(value) {
      if (value.length < 8) {
        this.msg['password'] = 'Minimum 8 characters or more!';
        this.error = true;
        this.passwordValidation = false;
      } else if (this.password.search(/[a-z]/) < 0) {
        this.msg['password'] = 'Your password must contain at least one lowercase letter.';
        this.passwordValidation = false;
      } else if (this.password.search(/[A-Z]/) < 0) {
        this.msg['password'] = 'Your password must contain at least one uppercase letter.';
        this.passwordValidation = false;
      } else if (this.password.search(/[0-9]/) < 0) {
        this.msg['password'] = 'Your password must contain at least one digit.';
        this.passwordValidation = false;
      } else if (this.password.match(/\s/)) {
        this.msg['password'] = 'Forbidden Characters: spaces.';
        this.passwordValidation = false;
      } else if (this.password.search(/[!@#$%^&*(),.?":{}|<>]/g) < 0) {
        this.msg['password'] = 'Your password must contain at least one special character.';
        this.passwordValidation = false;
      }else if (this.confirmPassword.length && this.confirmPassword != this.password) {
        delete this.msg['password'];
        this.msg['confirmPassword'] = 'Password Does Not Match!';
      } else if (this.confirmPassword.length && this.confirmPassword === this.password) {
        delete this.msg['password'];
        delete this.msg['confirmPassword']
      } else {
        delete this.msg['password'];
        this.error = false;
      }

      this.error = false;
    },

    showError(detail) {
      this.$toast.add({severity: 'error', summary: 'Error Message', detail: detail, life: 3000});
    },
    showSuccess(summary, detail) {
      this.$toast.add({severity: 'success', summary: summary, detail: detail, life: 3000});
    },
  },
};
</script>

<style></style>
