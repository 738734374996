import AuthServiceRequest from '../utils/request/auth_request';

export const getOrganizationPackageConsumptionApi = (id) => {
    return AuthServiceRequest.get(`/api/v2/organizations/${id}/package/consumption`);
  };

export const getOrganizationApi = (id) => {
  return AuthServiceRequest.get(`/api/v2/organizations/${id}`);
};

export const getOrganizationMembersApi = (id) => {
  return AuthServiceRequest.get(`/api/v2/organizations/${id}/members`);
};

export const updateOrganizationApi = async (id, data) => {
  return await AuthServiceRequest.patch(`/api/v2/organizations/${id}`, data);
};