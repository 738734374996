<template>
  <div class="validations">
    <ul>
      <li v-for="(validation, k) in validationList" :key="k" :title="isFreeTrial() && isValidationDisabledInFreeTrial(validation.action) ? 'Only available in paid version' : ''">
        <div :class="['list-container', isFreeTrial() && isValidationDisabledInFreeTrial(validation.action) ? 'disabled-container' : '']" @click="$emit('onListClick', validation)">
          <i :class="`${validation.icon}`"></i>
          {{ validation.name }}
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';


export default {
  name: 'ValidationsList',

  props: {
    customValidationList: {
      type: Array,
      default: [],
    },
    filteredList: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {};
  },
  methods: {
    isFreeTrial() {
      // console.log(this.validationList);
      if (this.loggedInUserInfo && this.loggedInUserInfo.subscription.pricing.isFree) {
        return true
      }
      return false
    },
    isValidationDisabledInFreeTrial(action) {
      switch (action) {
        case "generate_phone_number":
          return true;

        case "otp_validation":
          return true;

        case "scroll":
          return true;

        default:
          return false;
      }
    },
  },
  computed: {
    ...mapGetters({
      loggedInUserInfo: 'auth/loggedInUserInfo',
    }),

    validationList() {
      if (this.filteredList.length > 0) {
        return this.customValidationList.filter((customValidation) => {
          return this.filteredList.includes(customValidation.action);
        });
      } else {
        return this.customValidationList;
      }
    },
  },
};
</script>
