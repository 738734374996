import AuthServiceRequest from '../utils/request/auth_request';

export const fetchSubscriptionAndOverviewInfo = async () => {
    return await AuthServiceRequest.get('/tj-admin/dashboard/subscription-overview-details');
}

export const fetchTotalSubscriberInfo = async (duration) => {
    return await AuthServiceRequest.get(`/tj-admin/dashboard/total-subscriber-info/${duration}`)
}

export const fetchClientActivityInfo = async (duration) => {
    return await AuthServiceRequest.get(`/tj-admin/dashboard/client-activity-info/${duration}`)
}

export const fetchCFilteredlientActivityInfo = async (duration, bodyData) => {
    return await AuthServiceRequest.post(`/tj-admin/filtered-client/${duration}`, bodyData)
}