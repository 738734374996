<template>
  <div class="p-grid">
    <div class="p-col-12 broken-link-container">
      <div class="card p-px-0 p-py-0">
        <div class="url-counter">
          <div class="counter-details">
            <div class="count">
              <p class="num">{{ links.length }}</p>
              <p><b>Scanned Links</b></p>
            </div>
            <div class="icon">
              <img src="../../assets/img/scanned_links.svg">
            </div>
          </div>
          <div class="counter-details">
            <div class="count">
              <p class="num">{{ errors }}</p>
              <p><b>Error (Total)</b></p>
            </div>
            <div class="icon">
              <img src="../../assets/img/error_links.svg">
            </div>
          </div>
          <div class="counter-details">
            <div class="count">
              <p class="num">{{ percentage }}%</p>
              <p><b>Error (%)</b></p>
            </div>
            <div class="icon">
              <img src="../../assets/img/error_percentage.svg">
            </div>
          </div>
        </div>

        <div class="url-filter">
          <div class="p-inputgroup flex-1" style="flex-basis: 80%;" >
            <span class="p-inputgroup-addon">
              <i class="fa fa-link"></i>
              <p style="padding-left: 8px;">URL</p>
            </span>
            <InputText :placeholder="config && config.base_url ? config.base_url : 'URL not found'" disabled/>
          </div>

          <div class="p-inputgroup flex-1" style="flex-basis: 20%;" v-if="links && links.length">
            <span class="p-input-icon-right">
                <i class="pi pi-search" />
                <InputText v-model="searchCode" type="text" class="p-column-filter" style="height: 100%; width: 100%;" placeholder="Search status code" />
            </span>
          </div>
        </div>




        <div class="url-table">
          <DataTable :value="brokenLinks" paginator :rows="10" :rowsPerPageOptions="[10, 20, 50]">
            <Column field="status" header="Status" sortable filter headerStyle="padding-left : 1rem" bodyStyle="padding-left : 1rem">
              <!-- <template #body="{ data }">
                  {{ data.status }}
              </template>
              <template #filter="{ filterModel, filterCallback }">
                  <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="Search by name" />
              </template> -->
            </Column>
            <Column field="url" header="Link" sortable filter headerStyle="padding-left : 1rem" bodyStyle="padding-left : 1rem"/>
            <Column field="status" header="Result" sortable filter headerStyle="padding-left : 1rem" bodyStyle="padding-left : 1rem">
              <template #body="slotProps" >
<!--                <span v-if="Number(slotProps.data.status) > 99 && Number(slotProps.data.status) <= 199">Informational</span>-->
                <span v-if="Number(slotProps.data.status) > 199 && Number(slotProps.data.status) <= 299">Successful</span>
<!--                <span v-if="Number(slotProps.data.status) > 299 && Number(slotProps.data.status) <= 399">Redirection</span>-->
                <span v-else-if="Number(slotProps.data.status) > 399 && Number(slotProps.data.status) <= 499">Client error</span>
                <span v-else-if="Number(slotProps.data.status) > 499 && Number(slotProps.data.status) <= 599">Server error</span>
                <span v-else>Unknown Error</span>
              </template>
            </Column>
            <template #empty>
              <div class="empty_field">
                <p>No Links Found</p>
              </div>
            </template>
          </DataTable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    links: {
      type: Array,
      default: [],
    },
    config: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      searchCode : '',
      brokenLinks: []
    };
  },
  created(){
    this.brokenLinks = [...this.links];
  },

  watch: {
    searchCode(value) {
      if(value){
        this.brokenLinks = this.links.filter((item)=> item.status.toString().startsWith(value.toString()) );
      }
      else {
        this.brokenLinks = [...this.links];
      }
    },
  },
  computed: {
    errors() {
      var total = 0;
      this.links.forEach((l) => {
        if (!String(l.status).startsWith('2')) {
          total += 1;
        }
      });
      return total;
    },
    percentage() {
      return this.links && this.links.length ? (this.errors / this.links.length * 100).toFixed(2) : 0;
    },
  },
};
</script>

<style>
</style>
