export const setSelectedCurrentProjectType = (projectType) => {
    localStorage.setItem('projectType', projectType);
}

export const getSelectedCurrentProjectType = () => {
    return localStorage.getItem('projectType');
}

export const setSelectedCurrentProjectId = (projectId) => {
    localStorage.setItem('projectId', projectId);
}

export const getSelectedCurrentProjectId = () => {
    return localStorage.getItem('projectId');
}

export const removeSelectedCurrentProjectId = () => {
    return localStorage.removeItem('projectId');
}
export const removeSelectedCurrentProjectType = () => {
    return localStorage.removeItem('projectType');
}

export const setSelectedCaseId = (caseId) => {
    sessionStorage.setItem('testCaseId', caseId);
}

export const getSelectedCaseId = () => {
    return sessionStorage.getItem('testCaseId');
}