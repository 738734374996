import Cookie from 'js-cookie';
import decode from 'jwt-decode';

const cookieName = 'token';

/**
 *  get cookies
 * @param {string} name
 */
export const getToken = () => {
    return Cookie.get(cookieName);
};
/**
 * Set Token of cookies
 * @param {string} name
 * @param {any |  string} value
 * @param {object} path
 */
export const setToken = (value, path = null) => {
    let encodedToken = decode(value);
    let expDate = (encodedToken.exp - encodedToken.iat) / 86400;
    return Cookie.set(cookieName, value, {expires: expDate}, path);
};
/**
 * Remove Token from client
 * @param {string} name
 */
export const removeToken = () => {
    return Cookie.remove(cookieName);
};
