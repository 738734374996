<template>
  <div class="screenshot_Label">
    <p v-if="action === customValidationList.URL_Validation.action">Validate URL</p>
    <p v-if="action === customValidationList.ValidateDatabse.action">Validate Database</p>
    <p v-if="action === customValidationList.API_VALIDATION.action">Validate API</p>
    <p v-if="action === customValidationList.ValidateEmail.action">Validate Email</p>
    <p v-if="action === customValidationList.ValidateSMS.action">Validate SMS</p>
    <p v-if="action === customValidationList.Navigate.action">Navigate</p>
    <p v-if="action === customValidationList.Refresh.action">Refresh</p>
    <p v-if="action === customValidationList.Sleep.action">Sleep({{ sleep_for }}ms)</p>
    <p v-if="action === customValidationList.CHECK_BROKEN_LINK.action">Link Checker</p>
    <p v-if="action === domEvents.SCROLL">Scroll({{ Math.round(coordinates.x) }} x {{ Math.round(coordinates.y) }})</p>
    <p v-else-if="action === domEvents.RESIZE">Resize({{ screen_size.width }} x {{ screen_size.height }})</p>
    <p v-else-if="action === domEvents.INPUT">'{{
        text.length > 30 ? String(text).substring(0, 30) + '....' : text
      }}'</p>
  </div>
</template>
<script>
export default {
  props: {
    action: {
      type: String,
      default: undefined,
    },
    coordinates: {
      type: Object,
      default: {},
    },
    screen_size: {
      type: Object,
      default: {},
    },
    sleep_for: {
      type: Number,
      default: 0,
    },
    text: {
      type: String,
      default: '',
    },
    customValidationList: {
      type: Object,
      default: {},
    },
    domEvents: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
// .screenshot_Label {
//     border: 1px solid #eee;
//     height: 100% !important;
//     p {
//         font-size: 14px;
//         font-weight: bold;
//         color:rgba(105, 105, 105,0.86);

//     }
// }
</style>