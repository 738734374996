const AutomationStatus = Object.freeze({
  RECORD_PENDING: "record_pending",
  RECORD_RUNNING: "record_running",
  RECORD_STOPPED: "record_stopped",
  RECORD_FINISHED: "record_finished",
  AUTOMATION_PENDING: "automation_pending",
  AUTOMATION_RUNNING: "automation_running",
  AUTOMATION_PAUSED: "automation_paused",
  AUTOMATION_STOPPED: "automation_stopped",
  AUTOMATION_FINISHED: "automation_finished"
});

export default AutomationStatus;