<template>
  <div class="custom-chip p-fluid">
    <div class="input-description">
      <label for="email">{{labelName}}</label>
      <AutoComplete type="text" v-model="tagname" :suggestions="filteredTags" @complete="searchTag($event)" :placeholder="placeholder" @keydown.enter="handleEnter" @blur="tagsBlured"/>
    </div>
    <div class="custom-chip-list">
      <Chip :label="tag" v-for="tag in tags" :key="tag" removable @remove="onRemoveTag"/>
    </div>
  </div>
</template>

<script>
const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
import { mapGetters } from 'vuex';

export default {
  name: 'TjCustomChips',
  props: {
    tagList: {
      type: Array,
      required: true,
    },
    labelName: {
      type: String,
      required: false,
      default: 'Tag Name'
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Enter Tag Name'
    },
    type: {
      type: String,
      required: false,
      default: ''
    },
  },

  data() {
    return {
      value1: null,
      value2: null,
      value3: null,
      tagname: null,
      filteredTags: null,
      tags: this.tagList || [],
    };
  },
  methods: {
    handleEnter() {
      if (this.tagname.length > 0 && this.tagname.trim().length != 0) {
        if(this.type === 'email') {
          if (!re.test(this.tagname)) {
            this.$toast.add({severity: 'warn', detail: 'Invalid email address', life: 3000});
            return;
          }
        }

        if (!this.tags.includes(this.tagname)) {
          this.tags = [...this.tags, this.tagname];
          this.tagname = '';
          this.$emit('onUpdateChipsList', this.tags);
        } else {
          this.$toast.add({severity: 'warn', detail: 'Tag already exist.', life: 3000});
        }
      } else {
        this.tagname = '';
      }
    },

    tagsBlured(event) {
      // console.log(event.target.value);
      if (event.target.value?.length > 0 && event.target.value?.trim().length != 0) {

        if(this.type === 'email') {
          if (!re.test(event.target.value)) {
            this.$toast.add({severity: 'warn', detail: 'Invalid email address', life: 3000});
            return;
          }
        }

        if (!this.tags.includes(event.target.value)) {
          this.tags = [...this.tags, event.target.value];
          event.target.value = '';
          this.$emit('onUpdateChipsList', this.tags);
          this.tagname = ''
        } else {
          this.$toast.add({severity: 'warn', detail: 'Tag already exist.', life: 3000});
        }
      }
    },
    onRemoveTag(event, label) {
      this.tags = this.tags.filter(tag => tag !== label);
      this.$emit('onUpdateChipsList', this.tags);
    },

    searchTag(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredTags = [...this.tagList];
        }
        else {
          this.filteredTags = this.tagList.filter((tag) => {
            return tag.toLowerCase().startsWith(event.query.toLowerCase());
          });
        }
      }, 250);
    },
  },

  computed: {
    ...mapGetters({
      tagList: 'project/tagList',
    }),
  },
};
</script>

<style></style>
