import customValidationList from '../utils/custom_validation_list';
import domEvents from '../utils/dom-events-to-record';

export default {
    /**
     * Properties edit mixin.
     */
    created() {
        // console.log('Properties Edit Mixins');
    },
    methods: {
        /**
         * Handle custom validation
         * @param {*} type
         * @param {*} fieldName
         * @param {*} event
         */
        handlePropertiesChange(type, fieldName, event) {
            // console.log('phoneNumber1', fieldName, event);
            console.log(type, fieldName, event);
            var stepRecursive = (st, id, eventType, defaultValue = null) => {
                var i = 0;
                while (i < st.length) {
                    var value = '';
                    if (event.target && event.target.value) {
                        value = event.target.value;
                    }

                    // console.log('phoneNumber2', event);

                    if (st[i].type === 'testStep' && st[i]._id === id) {
                        switch (eventType) {
                            case 'description':
                                st[i].description = defaultValue || value;
                                break;
                            case 'sms_from':
                                st[i].sms_from = value;
                                break;
                            case 'value':
                                st[i].value = value;
                                break;
                            case 'phoneNumber':
                                st[i].value = event.phoneNumber;
                                break;
                            case 'css_property_name':
                                st[i].css_property.property = value;
                                break;
                            case 'css_expected_value':
                                st[i].css_property.expected = value;
                                break;
                            case 'attr_property_name':
                                st[i].attr_property.property = value;

                                break;
                            case 'attr_expected_value':
                                st[i].attr_property.expected = value;

                                break;
                            case 'url':
                                st[i].url = value;

                                break;
                            case 'text':
                                st[i].text = value;

                                break;
                            case 'sleep_for':
                                if (value.length > 0) {
                                    st[i].sleep_for = value;
                                } else {
                                    st[i].sleep_for = 1000;
                                }
                                break;
                            case 'x':
                                if (event.target.value > 0) {
                                    st[i].coordinates.x = event.target.value;
                                }

                                break;
                            case 'y':
                                if (event.target.value > 0) {
                                    st[i].coordinates.y = event.target.value;
                                }
                                break;
                            case 'width':
                                if (event.target.value > 0) {
                                    st[i].screen_size.width = event.target.value;
                                }
                                break;
                            case 'height':
                                if (event.target.value > 0) {
                                    st[i].screen_size.height = event.target.value;
                                }
                                break;
                            case 'override_timeout':
                                st[i].override_timeout = !st[i].override_timeout;
                                break;
                            case 'fail_causes':
                                st[i].fail_causes = event;
                                break;
                            case 'value':
                                st[i].value = value;
                                break;
                        }
                    } else if (st[i].type === 'group' && st[i]._id === id) {
                        // console.log('group', eventType, st[i], value);

                        switch (eventType) {
                            case 'description':
                                st[i].description = defaultValue || value;
                                break;
                        }
                    }

                    if (st[i].children && st[i].children.length > 0) {
                        stepRecursive(st[i].children, id, eventType, defaultValue);
                    }

                    i++;
                }
            };

            switch (type) {
                case customValidationList.ValidateCssProperty.action:
                    switch (fieldName) {
                        case 'description':
                            // stepRecursive(this.steps, this.currentProperties._id, 'description');
                            this.currentProperties.description = event.target.value;
                            break;

                        case 'css_property_name':
                            // stepRecursive(this.steps, this.currentProperties._id, 'css_property_name');
                            this.currentProperties.css_property.property = event.target.value;
                            break;
                        case 'css_expected_value':
                            // stepRecursive(this.steps, this.currentProperties._id, 'css_expected_value');
                            this.currentProperties.css_property.expected = event.target.value;
                            break;
                    }
                    //this.steps[index].css_property.property = 'asdasdas';
                    break;
                case customValidationList.ValidateHtmlAttribute.action:
                    switch (fieldName) {
                        case 'description':
                            // stepRecursive(this.steps, this.currentProperties._id, 'description');
                            this.currentProperties.description = event.target.value;
                            break;

                        case 'attr_property_name':
                            // stepRecursive(this.steps, this.currentProperties._id, 'attr_property_name');
                            this.currentProperties.attr_property.property = event.target.value;

                            break;
                        case 'attr_expected_value':
                            // stepRecursive(this.steps, this.currentProperties._id, 'attr_expected_value');
                            this.currentProperties.attr_property.expected = event.target.value;

                            break;
                    }
                    break;

                case customValidationList.TextToAssign.action:
                    if (fieldName === 'text') {
                        stepRecursive(this.steps, this.currentProperties._id, 'text');
                    }

                    if (fieldName === 'description') {
                        stepRecursive(this.steps, this.currentProperties._id, 'description');
                    }
                    break;

                case customValidationList.URL_Validation.action:
                    if (fieldName === 'url') {
                        // stepRecursive(this.steps, this.currentProperties._id, 'url');
                        this.currentProperties.url = event.target.value;
                    }

                    if (fieldName === 'description') {
                        stepRecursive(this.steps, this.currentProperties._id, 'description', customValidationList.URL_Validation.name + "('" + event.target.value.substring(0, 50) + "')");
                        this.currentProperties.description = event.target.value;
                    }

                    break;
                case customValidationList.Navigate.action:
                    if (fieldName === 'url') {
                        // stepRecursive(this.steps, this.currentProperties._id, 'url');
                        this.currentProperties.url = event.target.value;
                    }
                    if (fieldName === 'description') {
                      let val = ''
                      if (this.currentProperties.description.toLowerCase().includes(customValidationList.Navigate.name.toLowerCase())) {
                        val = event.target.value;
                      } else {
                        val = customValidationList.Navigate.name
                      }

                        // stepRecursive(this.steps, this.currentProperties._id, 'description', customValidationList.Navigate.name + "('" + event.target.value.substring(0, 50) + "')");
                        this.currentProperties.description = val;
                    }

                    break;

                case customValidationList.Sleep.action:
                    if (fieldName === 'sleep_for') {
                      // stepRecursive(this.steps, this.currentProperties._id, 'sleep_for');
                      this.currentProperties.sleep_for = event.target.value;
                    }
                    break;
                case customValidationList.GENERATE_PHONE_NUMBER.action:
                    if (fieldName === 'phoneNumber') {
                        stepRecursive(this.steps, this.currentProperties._id, 'phoneNumber');
                    }
                    break;
                case customValidationList.OTP_VALIDATION.action:
                    if (fieldName === 'phoneNumber') {
                        stepRecursive(this.steps, this.currentProperties._id, 'phoneNumber');
                    }
                    break;
                case domEvents.SCROLL:
                    // stepRecursive(this.steps, this.currentProperties._id, fieldName);
                    // console.log("SCROLL", fieldName);

                    if (fieldName === 'description') {
                      this.currentProperties.description = event.target.value;
                    }

                    if (fieldName === 'x') {
                      this.currentProperties['coordinates'].x = event.target.value;
                    }

                    if (fieldName === 'y') {
                      this.currentProperties['coordinates'].y = event.target.value;
                    }
                    break;
                case domEvents.RESIZE:
                    stepRecursive(this.steps, this.currentProperties._id, fieldName);
                    break;
                case domEvents.INPUT:
                    stepRecursive(this.steps, this.currentProperties._id, fieldName);
                    break;
                case 'override_timeout':
                    stepRecursive(this.steps, this.currentProperties._id, fieldName);
                    break;
                case 'fail_causes':
                    stepRecursive(this.steps, this.currentProperties._id, fieldName);
                    break;
            }
        },
    },
};
