/* eslint-disable no-unused-vars */
import MobileServiceRequest from '@/utils/request/mobile_request';

export const addMobileTestRun = payload => {
    return MobileServiceRequest.post(`/test-runs`, payload)
};

export const updateMobileProject = (projectId,projectData) => {
    return MobileServiceRequest.patch(`/projects/${projectId}`,projectData)
};

export const fetchMobileTestRuns = async ({projectID, projectId, duration}, metaData = null) => {
    return await MobileServiceRequest.get(`/projects/${projectID || projectId}/${duration}/test-runs`)
};

export const fetchSingleMobileTestRun = async (testRunId) => {
    return await MobileServiceRequest.get(`/test-runs/${testRunId}?populateCases=true&populateSteps=true`)
};

export const fetchSingleExecutedMobileTestRun = async (testRunId) => {
    return MobileServiceRequest.get(`/executed-runs?testRun=${testRunId}&skip=0&limit=1&populateCases=true&populateSteps=true`)
};

export const sendNativeExecRunResult = async (runResult) => {
    return await MobileServiceRequest.post(`/executed-runs`, runResult)
};

export const initiateCloudRun = async (runInfo) => {
    return await MobileServiceRequest.post(`/test-runs/cloud-run`, {runInfo})
};

export const initiateIosCloudRun = async (runInfo) => {
    return await MobileServiceRequest.post(`/test-runs/ios-cloud-run`, {runInfo})
};

export const fetchMobileTestRunsReports = async (runId) => {
    return await MobileServiceRequest.get(`/automated/test-runs/report/${runId}`)
        .then(res => {
            return res;
        })
        .catch(error => {
            return error;
        });
};
export const fetchMobileTestrunResult = async testCaseId => {
    return await MobileServiceRequest.get(`/test-cases/test-run-result/by/${testCaseId}`).then(res => {
        return res;
    })
        .catch(error => {
            return error;
        });
};

export const fetchExecutedMobileTestRunsByRunId = runId => {
    return MobileServiceRequest.get(`test-runs/${runId}/executed-runs`)
};

export const updateMobileTestRunsByRunId = (runId, data) => {
    return MobileServiceRequest.patch(`test-runs/${runId}`, data)
};
export const deleteMobileTestRunByRunId = (runId) => {
    return MobileServiceRequest.delete(`test-runs/${runId}`)
};

export const fetchExecutedMobileTestRunById = (executedRunId,metaData) => {
    return MobileServiceRequest.get(`executed-runs/${executedRunId}`,{params : { populateCases : metaData.populateCases, populateSteps : metaData.populateSteps }})
};

export const fetchExecutedMobileTestRunByIdCloud = (runId, executedRunId) => {
    return MobileServiceRequest.get(`test-runs/${runId}/cloud-execution-results/${executedRunId}`)
};

export const fetchExecutedMobileTestRunByArn = (runId, arn) => {
    console.log("✨ ✨ ✨ ✨ ✨ ✨ ✨ ✨ ✨ ✨ ✨ ✨ ", encodeURIComponent(arn));
    return MobileServiceRequest.get(`test-runs/${runId}/cloud-execution-results/run-arn/${encodeURIComponent(arn)}`)
};

export const fetchCloudRunStatusByRunId = (runId) => {
    return MobileServiceRequest.get(`test-runs/${runId}/check-cloud-run`)
};
