import {sendTestStepToSelenium} from '../../api/seleniumService';

const state = {};

const mutations = {};

const actions = {
    actionSendTestStepToSelenium(state, reqDataObj) {
        console.log('actionSendTestStepToSelenium', state, reqDataObj);

        let res = sendTestStepToSelenium(reqDataObj);
        return res;
    },
};

const getters = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
