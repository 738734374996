import router, { routes } from './router';
import { hasPermission } from '@/utils/helpers';
import { getSelectedCurrentProjectId } from '@/utils/localstorage';
import { getToken } from '@/utils/cookies';
import getPageTitle from '@/utils/get-page-title';
import store from '@/store';
import decode from 'jwt-decode';
import Ngprogess from 'nprogress';
import 'nprogress/nprogress.css';
import { setSelectedCurrentProjectId, setSelectedCurrentProjectType } from '@/utils/localstorage';

export const whiteListed = ['/login', '/success', '/forget-password', '/auth-redirect', '/signup', '/reset-password', '/check-email', '/verification-email', '/verify-email', '/error'];
const whiteListedWithStatus = ['/user/set-up-account', '/member/set-up-account'];

/**
 *  Middleware of each page
 */
router.beforeEach(async (to, from, next) => {
    // Main Document Title
    document.title = getPageTitle(to.meta.title);

    let projectId = localStorage.getItem('projectId') ? localStorage.getItem('projectId') : null;

    Ngprogess.configure({ showSpinner: false })
    Ngprogess.start();

    const hasToken = getToken();
    const decoded_token = hasToken ? decode(hasToken) : false;

    if (hasToken && decoded_token !== false && decoded_token.verification.email_verified === true) {
        if (whiteListed.includes(to.path)) {
            // if is logged in, redirect to the home page
            // next({
            //     path: '/dashboard',
            // });
            next();
            // if (projectId) {
            //     next({
            //         path: '/project-overview/' + localStorage.getItem('projectId'),
            //     });
            // } else {
            //     next({
            //         path: '/dashboard',
            //     });
            // }

            NProgress.done();
        } else {

          next();

            // try {
            //       const { roleDetails, user_status } = await store.dispatch('auth/actionFetchAuthInfo');
            //       if (hasPermission(to, roleDetails.role) && user_status === 1) {
            //           next();
            //       }
            //       else if (hasPermission(to, roleDetails.role) && user_status != 1 && whiteListedWithStatus.includes(to.path)) {
            //               next();
            //       }
            //       else {
            //           // check is not found
            //           if (to.matched.some(m => m.path === '/:pathMatch(.*)*')) {

            //               next();
            //           } else if (roleDetails.role == 'tester') {
            //               if (!getSelectedCurrentProjectId()){
            //                   const projects = await store.dispatch('project/actionGetProjectsList');
            //                   if (projects && projects.length){
            //                       if (projects[0].platform === 'web') {
            //                           setSelectedCurrentProjectId(projects[0]._id);
            //                           setSelectedCurrentProjectType(projects[0].type);
            //                           await store.dispatch('project/actionOnchangeProjectHandler',projects[0]._id);
            //                           next({
            //                               path: `/project-overview/${projects[0]._id}`,
            //                               replace: true,
            //                           });
            //                       }
            //                       else if (projects[0].platform === 'mobile') {
            //                           setSelectedCurrentProjectId(projects[0]._id);
            //                           setSelectedCurrentProjectType(projects[0].type);
            //                           await store.dispatch('project/actionOnchangeProjectHandler',projects[0]._id);
            //                           next({
            //                               path: `/project-overview-mobile/${projects[0]._id}`,
            //                               replace: true,
            //                           });
            //                       }
            //                       else {
            //                           next({
            //                               path: `/logout`,
            //                               replace: true,
            //                           });
            //                       }
            //                   }
            //                   else {
            //                       next({
            //                           path: `/logout`,
            //                           replace: true,
            //                       });
            //                   }

            //               }
            //               else {
            //                   next({
            //                       path: `/project-overview/${getSelectedCurrentProjectId()}`,
            //                       replace: true,
            //                   });
            //               }
            //           } else {
            //               next({
            //                   path: '/access-denied',
            //                   replace: true,
            //               });
            //           }

            //           NProgress.done();
            //       }
            // } catch (e) {
            //     next(`/login`);
            //     NProgress.done();
            // }
        }
    } else {
        if (whiteListed.indexOf(to.path) !== -1) {
            // in the free login whitelist, go directly

            next();
        } else {
            // other pages that do not have permission to access are redirected to the login page.
            next("/login");
            NProgress.done();
        }
    }
});
/**
 *  After Page Loaded
 */
router.afterEach(() => {
    Ngprogess.done();
});
