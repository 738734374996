<template>
  <div class="home">
    <Message v-if="noExtention" @close="closeMessage" severity="warn" :sticky="true"
      :style="{ display: noExtention ? '' : 'none' }">
      Please install <strong>TestJet</strong> extention from <a :href="$extensionURL" target="_blank"
        rel="noopener noreferrer"><strong>here</strong></a>
    </Message>
    <!-- TestCase Dialog -->
    <div class="test__editorPanel">
      <div class="p-grid p-fluid">
        <div class="p-col-9 p-col-md-7 p-col-sm-12 test-editor-table-container"
          v-if="showDifferentComponent.show === false">
          <div class="card">
            <div class="testcase-info-header-wrapper">
              <div class="testcase-info-header-top">
                <div class="testcase-info-header-left">
                  <span class="chevron-left-icon">
                    <Button @click="goBackToTestcaseHandler" icon="fas fa-chevron-left"
                      class="p-button-rounded p-button-outlined" />
                  </span>
                  <div class="testcase-info">
                    <div class="testcase-name">
                      {{ currentTestcase ? currentTestcase.test_case_name : 'Test Case Name' }}
                      <span class="testcase-total-count">{{ totalStepLength ? totalStepLength : 0 }} steps</span>
                    </div>
                    <div class="testcase-tags">
                      <!-- :style="{height: '24px', marginTop: '12px' }" -->
                      <div v-if="currentTestcase && currentTestcase.tags && currentTestcase.tags.length > 0">
                        <Chip :label="tagPrefix(tag)" v-for="(tag, k) in currentTestcase.tags" :key="k" />
                      </div>
                      <div v-else>
                        <Chip :label="tagPrefix(tag)" v-for="(tag, k) in tags" :key="k" />
                      </div>

                      <!-- <Chips v-model="tags" removable placeholder="Enter Tag Name" /> -->
                      <!-- {{ newTestCaseDetails ? newTestCaseDetails.testDescription : 'Test Case Description' }} -->
                    </div>
                  </div>
                </div>

                <div class="testcase-info-header-right">
                  <Button v-if="automationStatus == automationStatusEnums.RECORD_RUNNING" icon="fas fa-stop" title="Stop"
                    class="p-button-rounded p-button-outlined record-icon" @click="onStopRecord"
                    :disabled="!isStepsLoaded || automationStatus === automationStatusEnums.AUTOMATION_RUNNING" />
                  <Button v-else icon="fas fa-circle" title="Record Test Case"
                    class="p-button-rounded p-button-outlined record-icon" @click="onStartRecord"
                    :disabled="noExtention || baseUrlUpdateable || !isStepsLoaded || automationStatus === automationStatusEnums.AUTOMATION_RUNNING || automationStatus === automationStatusEnums.AUTOMATION_PAUSED" />
                  <Button v-if="automationStatus === automationStatusEnums.AUTOMATION_RUNNING" icon="fas fa-pause"
                    title="Paused" class="p-button-rounded p-button-outlined pause-icon" @click="onPausedAutomation"
                    :disabled="!totalStepLength || isAutomationCompleted" />
                  <Button
                    v-if="automationStatus === automationStatusEnums.AUTOMATION_PENDING || automationStatus === automationStatusEnums.RECORD_RUNNING"
                    icon="fas fa-play" title="Play" class="p-button-rounded p-button-outlined play-icon"
                    @click="onStartAutomation"
                    :disabled="noExtention || baseUrlUpdateable || !totalStepLength || automationStatus === automationStatusEnums.AUTOMATION_RUNNING || automationStatus === automationStatusEnums.RECORD_RUNNING || automationStatus === automationStatusEnums.AUTOMATION_PAUSED" />
                  <Button v-if="automationStatus === automationStatusEnums.AUTOMATION_PAUSED" icon="fas fa-play"
                    title="Play" class="p-button-rounded p-button-outlined play-icon" @click="onRestartAutomation"
                    :disabled="!totalStepLength || automationStatus === automationStatusEnums.AUTOMATION_RUNNING" />
                  <Button icon="fas fa-edit" title="Edit" class="p-button-rounded p-button-outlined update-icon"
                    @click="updateTestCaseModalVisible = !updateTestCaseModalVisible"
                    :disabled="baseUrlUpdateable || automationStatus === automationStatusEnums.AUTOMATION_RUNNING || automationStatus === automationStatusEnums.RECORD_RUNNING || automationStatus === automationStatusEnums.AUTOMATION_PAUSED" />
                  <Button icon="fas fa-trash" title="Delete" class="p-button-rounded p-button-outlined update-icon"
                    @click="() => visibleDeleteTestSteps = true"
                    :disabled="!isStepsLoaded || !selectedCheckedArr.length || automationStatus === automationStatusEnums.AUTOMATION_RUNNING || automationStatus === automationStatusEnums.RECORD_RUNNING || automationStatus === automationStatusEnums.AUTOMATION_PAUSED" />
                  <!-- <Button icon="fas fa-cloud" class="p-button-rounded p-button-outlined delete-icon" @click="clickSendStepsToSelenuim" /> -->
                </div>
              </div>
              <div v-if="isRecordNeeded" class="open-record-message">To choose an element <a @click="onStartRecord">
                  Open base URL</a> or Install from <a target="_blank" :href="$extensionURL">here</a></div>
              <div class="testcase-info-header-bottom">
                <div class="testcase-base-config-info-left">
                  <div class="base-config-left-element">
                    <div class="element" v-if="steps && steps.length">
                      <Checkbox v-model="selectAllSteps" @change="changeSelectAllStepd" name="select-all"
                        :binary="true" />
                      <label for="select-all">Select All</label>
                    </div>
                    <div class="element">
                      <span class="icon"><i class="fab fa-chrome"></i></span>
                      <span class="content">{{ "Chromium" }}</span>
                    </div>
                    <div class="element os-info">
                      <span v-if="config.os === 'Windows'" class="icon"><i class="fab fa-windows"></i></span>
                      <span v-else-if="config.os === 'Linux'" class="icon"><i class="fab fa-linux"></i></span>
                      <span v-else class="icon"><i class="fab fa-apple"></i></span>
                      <span class="content">{{ config.os }}</span>
                    </div>
                    <!-- %asd% -->
                    <div class="element screen-resolution" @click="showResolutionModalHandler">
                      <div class="screen-resolution-inside">
                        <span class="icon"><i class="fas fa-laptop"></i></span>
                        <span class="content">{{ config.resolution.width }}x{{ config.resolution.height }}</span>
                        <!-- @focus="handleFocusResolution" @focusout="handleFocusOutResolution" -->
                        <!-- @focus="handleFocusResolution" @focusout="handleFocusOutResolution"  :class="showResolutionModal ? 'block' : 'none'"-->
                      </div>
                      <div class="resolution-edit-icon">
                        <Button icon="fas fa-edit" title="Edit Resolution"
                          class="p-button-rounded p-button-outlined edit-icon" />

                        <!-- <Button icon="fas fa-edit" class="p-button-rounded p-button-outlined edit-icon" @click="showResolutionModal = !showResolutionModal" /> -->
                      </div>
                    </div>

                    <div class="element base-url" @click="baseUrlUpdateEnable">
                      <div class="base-url-update-input-field" v-if="baseUrlUpdateable">
                        <InputText type="url" v-model="config.baseUrl" :class="requiredField.baseUrl"
                          @input="changeBaseUrl" v-on:keyup.enter="submitBaseUrlChange"
                          placeholder="https://testjet.ai" />
                      </div>
                      <div class="base-url-inside" v-else>
                        <span class="icon"><i class="fas fa-link"></i></span>
                        <span class="content">{{ baseURLPrefix(config.baseUrl) }}</span>
                      </div>
                      <div class="base-url-edit-icon">
                        <Button icon="fas fa-edit" title="Edit Base URL" class="p-button-rounded p-button-outlined"
                          @click="submitBaseUrlChange" :disabled="!config.baseUrl" />
                      </div>
                    </div>
                    <small class="p-invalid">{{ errorMsg.baseUrl }}</small>
                  </div>
                </div>
              </div>
            </div>

            <OverlayPanel ref="resolutionUpdateModal" class="editor-resolution-update">
              <div class="resolution-update-modal-wrapper">
                <div class="modal-header">
                  <label class="header-label"> Screen Resolution </label>
                  <span class="modal-close-icon" @click="showResolutionModalHandler"> <i class="fas fa-times" /></span>
                </div>

                <div class="input-section">
                  <div class="input-field">
                    <InputNumber required="true" v-model="cusResolutionWidth" :min="0" :max="7680" mode="decimal"
                      :useGrouping="false" placeholder="Width (max: 7680)" :class="requiredField.cusResolutionWidth" />
                  </div>

                  <div class="multiplication-icon">X</div>
                  <div class="input-field">
                    <InputNumber required="true" v-model="cusResolutionHeight" :min="0" :max="4320" mode="decimal"
                      :useGrouping="false" placeholder="Height (max: 4320)" :class="requiredField.cusResolutionHeight" />
                  </div>
                  <Button icon="fas fa-save" class="p-button-primary" @click="clickSaveCusResolution" />
                </div>
                <div class="resolution-list">
                  <div class="title">Custom</div>
                  <ul>
                    <li v-for="resolutionItem in caseResolutionList.customScreenResolution" :key="resolutionItem._id"
                      v-bind:class="{ selected: resolutionItem._id === config.resolution.resolutionId }">
                      <label @click="handleSelectedResolution(resolutionItem)">{{
                        `${resolutionItem.screen_size.width} x ${resolutionItem.screen_size.height}`
                      }}</label>
                      <i v-if="resolutionItem._id != config.resolution.resolutionId" class="fas fa-trash"
                        @click="deleteCustomResolution(resolutionItem._id)"></i>
                      <i v-else class="pi pi-check"></i>
                    </li>
                    <li v-if="!caseResolutionList?.customScreenResolution?.length">
                      <label>Custom Resolution Not Found!</label>
                    </li>
                  </ul>
                  <div class="custorm-res-divider"></div>
                  <div class="title">System Default Resolution</div>
                  <ul>
                    <li v-for="resolutionItem in caseResolutionList.screenResolution" :key="resolutionItem._id"
                      @click="handleSelectedResolution(resolutionItem)"
                      v-bind:class="{ selected: resolutionItem._id === config.resolution.resolutionId }">
                      <label>{{
                        `${resolutionItem.screen_size.width} x ${resolutionItem.screen_size.height}`
                      }}</label>
                      <i v-if="resolutionItem._id === config.resolution.resolutionId" class="pi pi-check"></i>
                      <!-- <i class="fas fa-trash" @click="deleteCustomResolution(resolutionItem._id)"></i> -->
                    </li>
                  </ul>
                </div>
              </div>
            </OverlayPanel>
            <!-- <div class="testStep">
                <div class="testStepCount">
                    <b>Test Steps ({{ steps.length + 1 }} steps)</b>
                </div>
                <div class="recordButton"><Button @click="onStartRecord" label="Record" icon="fas fa-circle" class="p-button-outlined p-button-danger" /></div>
            </div> -->

            <div class="teststep-msg-wrapper" v-if="errorStatus === 'success'">
              <div class="testStep-msg">
                <div class="execute-icon success">
                  <i class="fas fa-check-circle" />
                </div>

                <div class="execution-details success">
                  <span>Run Passed</span>
                  <span>|</span>
                  <span> Execution Time: {{ executionTime.start && executionTime.end ? parseFloat((executionTime.end -
                    executionTime.start) / 1000) : 0 }} sec </span>
                </div>
              </div>
            </div>

            <div class="teststep-msg-wrapper" v-else-if="errorStatus === 'failed'">
              <div class="testStep-msg">
                <div class="execute-icon error">
                  <i class="fas fa-times-circle"></i>
                </div>

                <div class="execution-details error">
                  <span>Run Failed</span>
                  <span>|</span>
                  <span> Execution Time: {{ executionTime.start && executionTime.end ? parseFloat((executionTime.end -
                    executionTime.start) / 1000) : 0 }} sec </span>
                </div>
              </div>
            </div>

            <div class="testStep-table-wrapper" ref="testStepsTableWrapper" v-if="isStepsLoaded">
              <nested-draggable @onMove="onMoveListener" @onEnd="onEndListener" @onAdd="onAddListener"
                :testCase="currentTestcase" @onChange="onChangeListener" @getCheckedValue="getCheckeBoxValue"
                @removeCheckedValue="removeCheckedValue" @onSetting="onSetting" @onBreakPoint="onBreakPoint"
                :testSteps="steps" :checkedList="checkedStepList" :break_point="breakPoint" ref="nestedTestStepsRef"
                :current_step="currentAutomationStep" class="teststep-nested-table" :srcType="srcType"
                @onBrokenLink="onBrokenLink" @onApiValidation="onApiValidationClicked"
                @onEmailValidation="onEmailValidationClicked" @onDatabaseValidation="onDatabaseValidationClicked"
                @onSMSValidation="onSMSValidationClicked" @stepScroll="onStepScroll"
                @onShowComparison="(elem) => processComparisonDialog(elem)" />
            </div>
            <div v-else class="progressbar--container">
              <div>
                <ProgressSpinner style="margin-top: 50px; width: 50px; height: 50px" strokeWidth="2" fill="#fff"
                  animationDuration=".5s" />
              </div>
            </div>
          </div>

          <Dialog :draggable="false" header="Add New Group" v-model:visible="displayConfirmation"
            :style="{ width: '350px' }" :modal="true">
            <div class="p-grid">
              <div class="p-col-12">
                <h6>Group Name</h6>
                <InputText type="text" v-model="groupName" :style="{ width: '100%' }" />
              </div>
              <div class="p-col-12">
                <Button label="ADD GROUP" @click="clickCreateGroup" :style="{ marginTop: '8px' }" />
              </div>
            </div>
          </Dialog>
        </div>
        <div class="p-col-12 p-col-m-12 p-col-sm-12"
          v-else-if="showDifferentComponent.show === true && showDifferentComponent.componentName === 'brokenLink'">
          <div class="card">
            <div class="testcase-info-header-wrapper">
              <div class="testcase-info-header-top">
                <div class="testcase-info-header-left">
                  <span class="chevron-left-icon">
                    <Button @click="goBackToTestEditorHandler" icon="fas fa-chevron-left"
                      class="p-button-rounded p-button-outlined" />
                  </span>
                  <div class="testcase-info">
                    <div class="testcase-name">
                      {{ currentTestcase ? currentTestcase.test_case_name : 'Test Case Name' }}
                      <span class="testcase-total-count">{{ steps ? steps.length : 0 }} steps</span>
                    </div>
                    <div class="testcase-tags">
                      <!-- :style="{height: '24px', marginTop: '12px' }" -->
                      <div v-if="currentTestcase && currentTestcase.tags && currentTestcase.tags.length > 0">
                        <Chip :label="tag" v-for="(tag, k) in currentTestcase.tags" :key="k" />
                      </div>
                      <div v-else>
                        <Chip :label="tag" v-for="(tag, k) in tags" :key="k" />
                      </div>

                      <!-- <Chips v-model="tags" removable placeholder="Enter Tag Name" /> -->
                      <!-- {{ newTestCaseDetails ? newTestCaseDetails.testDescription : 'Test Case Description' }} -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="validation-component">
            <broken-link :links="currentLinks" :config="config" />
          </div>
        </div>
        <div class="p-col-12 p-col-m-12 p-col-sm-12"
          v-else-if="showDifferentComponent.show === true && showDifferentComponent.componentName === 'databaseValidation'">
          <div class="card">
            <div class="testcase-info-header-wrapper">
              <div class="testcase-info-header-top">
                <div class="testcase-info-header-left">
                  <span class="chevron-left-icon">
                    <Button @click="goBackToTestEditorHandler" icon="fas fa-chevron-left"
                      class="p-button-rounded p-button-outlined" />
                  </span>
                  <div class="testcase-info">
                    <div class="testcase-name">
                      <!-- {{ currentTestcase ? currentTestcase.test_case_name : 'Test Case Name' }} -->
                      {{ "Validate Database" }}
                      <!-- <span class="testcase-total-count">{{ steps ? steps.length : 0 }} steps</span> -->
                    </div>
                    <div class="testcase-tags">
                      <!-- :style="{height: '24px', marginTop: '12px' }" -->
                      <!-- <div v-if="currentTestcase && currentTestcase.tags && currentTestcase.tags.length > 0">
                        <Chip :label="tag" v-for="(tag, k) in currentTestcase.tags" :key="k" />
                      </div> -->
                      <!-- <div v-else>
                        <Chip :label="tag" v-for="(tag, k) in tags" :key="k" />
                      </div> -->

                      <!-- <Chips v-model="tags" removable placeholder="Enter Tag Name" /> -->
                      <!-- {{ newTestCaseDetails ? newTestCaseDetails.testDescription : 'Test Case Description' }} -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="validation-component">
            <Database-validation :sendData="differentComponentData" :existingData="differentComponentStepData"
              @sendExecuteQuery="onDatabaseValidationValidate" @save="onDatabaseValidationValidate" />
          </div>
        </div>

        <div class="p-col-12 p-col-m-12 p-col-sm-12"
          v-else-if="showDifferentComponent.show === true && showDifferentComponent.componentName === 'apiValidation'">
          <div class="card">
            <div class="testcase-info-header-wrapper">
              <div class="testcase-info-header-top">
                <div class="testcase-info-header-left">
                  <span class="chevron-left-icon">
                    <Button @click="goBackToTestEditorHandler" icon="fas fa-chevron-left"
                      class="p-button-rounded p-button-outlined" />
                  </span>
                  <div class="testcase-info">
                    <div class="testcase-name">
                      <!-- {{ currentTestcase ? currentTestcase.test_case_name : 'Test Case Name' }} -->
                      {{ "API Validation" }}
                      <!-- <span class="testcase-total-count">{{ steps ? steps.length : 0 }} steps</span> -->
                    </div>
                    <div class="testcase-tags">
                      <!-- :style="{height: '24px', marginTop: '12px' }" -->
                      <!-- <div v-if="currentTestcase && currentTestcase.tags && currentTestcase.tags.length > 0">
                        <Chip :label="tag" v-for="(tag, k) in currentTestcase.tags" :key="k" />
                      </div> -->
                      <!-- <div v-else>
                        <Chip :label="tag" v-for="(tag, k) in tags" :key="k" />
                      </div> -->

                      <!-- <Chips v-model="tags" removable placeholder="Enter Tag Name" /> -->
                      <!-- {{ newTestCaseDetails ? newTestCaseDetails.testDescription : 'Test Case Description' }} -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="validation-component">
            <Api-validation :sendData="differentComponentData" :existingData="differentComponentStepData"
              @save="onApiValidationValidate" />
          </div>
        </div>

        <div class="p-col-12 p-col-m-12 p-col-sm-12"
          v-else-if="showDifferentComponent.show === true && showDifferentComponent.componentName === 'emailValidation'">
          <div class="card">
            <div class="testcase-info-header-wrapper">
              <div class="testcase-info-header-top">
                <div class="testcase-info-header-left">
                  <span class="chevron-left-icon">
                    <Button @click="goBackToTestEditorHandler" icon="fas fa-chevron-left"
                      class="p-button-rounded p-button-outlined" />
                  </span>
                  <div class="testcase-info">
                    <div class="testcase-name">
                      Email validation
                    </div>
                    <div class="testcase-tags">

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <EmailValidation :sendData="differentComponentData" :existingData="differentComponentStepData"
            @onSendMockEmailTest="onValidateEmailTest" @save="onValidateEmailTest" />
        </div>

        <div class="p-col-12 p-col-m-12 p-col-sm-12"
          v-else-if="showDifferentComponent.show === true && showDifferentComponent.componentName === 'smsValidation'">
          <div class="card">
            <div class="testcase-info-header-wrapper">
              <div class="testcase-info-header-top">
                <div class="testcase-info-header-left">
                  <span class="chevron-left-icon">
                    <Button @click="goBackToTestEditorHandler" icon="fas fa-chevron-left"
                      class="p-button-rounded p-button-outlined" />
                  </span>
                  <div class="testcase-info">
                    <div class="testcase-name">
                      SMS validation
                    </div>
                    <div class="testcase-tags">

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SMSValidation :sendData="differentComponentData" :existingData="differentComponentStepData"
            @onSendMockSMSTest="onValidateSMSTest" @save="onValidateSMSTest" @updateRunTimeData="onUpdateRunTimeData" />
        </div>

        <div class="p-col-3 p-col-md-5 p-col-sm-12 validation-panel-container"
          v-if="showDifferentComponent.show === false">
          <!-- <div class="validationPanel" :class="!isTestCaseCreated ? 'side_bar_disable' : ''">    @clickSendStepsToSelenuim="clickSendStepsToSelenuim" -->
          <div class="validationPanel">
            <ValidationPanel ref="validationPanelRef" :testEditoServerInfo="serverInfo"
              :customValidationList="filteredCustomValidationList" @onSearchChange="onSearchChange"
              :disableSaveBtn="disableSaveBtn" @onListClick="onCustomValidation" @clickSave="clickTestStepsSave"
              :shareableGroupList="shareableGroupList" @clickAddSharedGroup="clickAddSharedGroupInSteps" />
          </div>
        </div>
      </div>
    </div>

    <!-- <pre>
        {{ steps[steps.length - 1] }}
    </pre> -->

    <!-- TestCase Dialog -->
    <testcase-update-dialog v-if="updateTestCaseModalVisible" :updateModalVisibleProps="updateTestCaseModalVisible"
      :testCaseId="test_case_id" :savedTestCase="currentTestcase" @fetchUpdateTestcase="fetchCurrentTestcaseDetails" />

    <properties-dialog ref="propertiesDialogRef" v-model:displayConfirmation="visibleProperties"
      :properties="currentProperties" @onPropertySaveChanges="onPropertySaveChanges" @onCancel="onPropertyCancel"
      @handleChange="handlePropertiesChange" @hide="onPropertiesHide" />

    <Dialog :draggable="false" header="Header" v-model:visible="visibleDeleteTestSteps" closable :modal="true"
      class="delete-confirmation-modal" :style="{ maxWidth: '26em' }">
      <!-- @click="handleMemberRemove(slotProps.data.id)" -->
      <template #header>
        <div class="head">
          <img src="/assets/warning-icon.svg" alt="warning-icon.svg">
          <p>Delete Confirmation</p>
        </div>
      </template>

      <p class="content">
        Are you sure you want to delete?
      </p>

      <template #footer>
        <div class="footer">
          <Button label="No" class="p-button-outlined" @click="() => visibleDeleteTestSteps = false" />
          <Button label="Yes" class="p-button-primary" @click="clickDeleteTeststep" />
        </div>
      </template>
    </Dialog>

    <!-- eslint-disable-next-line -->
    <Dialog :draggable="false" header="Insert variable" v-model:visible="openInsertVariableModal" :modal="true"
      class="delete-confirmation-modal" style="width: 32rem">
      <div class="insrt-vr-content" style="display: flex;flex-direction: column;row-gap: 16px;">
        <label>Variables</label>
        <Dropdown :options="caseVariables" optionLabel="name" v-model="selectedVariable" placeholder="Choose variable">
          <template #empty>
            <p style="text-align: center;"> No variables available </p>
          </template>
        </Dropdown>
      </div>
      <template #footer>
        <div class="footer">
          <Button label="Use variable" class="p-button-primary" @click="useVariable(selectedVariable)"
            :disabled="!selectedVariable" style="width: 100%;" />
        </div>
      </template>
    </Dialog>

    <comparison-automation-dialog :isVisible="isComparisonShow" :testSteps="currentComparisonCase" @hide="oHideComparison"
      :isComparisonDataLoaded="isComparisonDataLoaded" />
  </div>
</template>

<script>
// Components
import nestedDraggable from './NestedTestSteps';
import ctrl from '../utils/control_keys';
import customValidationList from '../utils/custom_validation_list';
import actionsList from '../utils/actions_list';
import { mapActions, mapGetters, mapState } from 'vuex';
import { caseBinder, childrenTree, getBrowser, getBrowserName, getOsName, sendKeepAliveMessage } from '../utils/helpers';
import PropertiesDialog from '../components/testEditorProperties/PropertiesDialog';
import { ExtensionNotFoundException, ExtensionRuntimeConnectException } from '../utils/exception_list';
import ValidationPanel from '../components/ValidationPanel.vue';
import ClickOutside from 'vue-click-outside';
import TestcaseUpdateDialog from '../components/test-cases/TestCaseUpdate';
import BrokenLink from '../components/broken-link/BrokenLink.vue';
import DatabaseValidation from '../components/databaseValidation/DatabaseValidation.vue';
import ApiValidation from '../components/apiValidation/ApiValidation.vue';
import EmailValidation from '../components/email-validation/EmailValidation.vue';
import SMSValidation from '../components/sms-validation/SMSValidation.vue';
import ComparisonAutomationDialog from '../components/ComparisonAutomationDialog.vue';
import { getSelectedCurrentProjectId } from '../utils/localstorage';
// mixins
import PropertiesEditMixins from '../mixins/propertiesEditMixins';
import comparisonMixins from '../mixins/comparisonMixins';
import variableMixin from '../mixins/variableMixin';

//enums
import { AutomationStatusEnums } from "./../enums";

// Extension Port
let getPort = null;
let preDefinedStepPort = null;
let autWindowId = null;

let localStorageConfig = localStorage.getItem('config') ? JSON.parse(localStorage.getItem('config')) : null;

export default {
  components: {
    nestedDraggable,
    PropertiesDialog,
    TestcaseUpdateDialog,
    ValidationPanel,
    BrokenLink,
    DatabaseValidation,
    ApiValidation,
    ComparisonAutomationDialog,
    EmailValidation,
    SMSValidation
  },
  name: 'TestEditorPage',

  mixins: [PropertiesEditMixins, comparisonMixins, variableMixin],
  data() {
    return {
      keepAliveInterval: null,
      keepAlivePredefinedStepInterval: null,
      visibleDeleteTestSteps: false,
      serverInfo: null,
      disableSaveBtn: false,
      isRecordNeeded: false,
      shareableGroupList: [],
      srcType: 'testSteps',
      cusResolutionWidth: null,
      cusResolutionHeight: null,
      selectedIssueTypeObj: '',
      test_case_id: null,
      customValidationList,
      baseUrlUpdateable: false,
      showResolutionModal: false,
      validationListSearch: '',
      tags: [],
      newTestCaseDetails: '',
      // Check automation value is completed
      isAutomationCompleted: false,
      isTestCaseCreated: !!localStorageConfig,
      emptyGroupList: [],
      updateTestCaseModalVisible: false,
      displayConfirmation: false,
      groupName: '',
      selectedCheckedArr: [],
      checkedStepList: [], // Step checked all id in this array
      checkedKeysArray: [],
      checkedIndexArray: [],
      checkedIndexWithDataArray: [],
      current: null,
      // Whole Steps collection
      steps: [],
      isStepsLoaded: false,
      port: null,
      previousEvent: null,
      tabId: null,
      winId: null,
      showProperties: false,
      currentProperties: {},
      config: {
        baseUrl: '',
        browser_type: getBrowserName(),
        os: getOsName(),
        resolution: {
          width: 1366,
          height: 720,
          resolutionId: '',
        },
      },
      currentAutomationStep: {},
      exceptEvents: ['resize', 'scroll', 'wheel', 'input'],
      visibleProperties: false,
      // Automation
      isAutomationPaused: false,
      timer: null,
      //selected step list
      selectAllSteps: false,
      selectAllStepsDisabled: false,
      selectedSteps: [],
      stepList: [],
      isPlay: false,
      isRecord: false,
      breakPoint: {},
      //input field required handle
      errorMsg: [],
      requiredField: {
        baseUrl: '',
        resolution: {
          cusResolutionWidth: null,
          cusResolutionHeight: null,
        },
      },
      currentMoveData: {},
      allCheckList: [],
      currentChangePropertiesObj: {},
      isShowJiraForm: false,
      isAnyStepChanged: false,
      isStepSharedProps: false,
      differentComponentStepData: null,
      showDifferentComponent: {
        show: false,
        componentName: '',
      },
      differentComponentData: {
        componentName: '',
        data: null,
      },
      // keycode
      previousKeyCode: null,
      testCaseResult: {},
      scrollPosition: 0,
      noExtention: false,
      automationStatusEnums: AutomationStatusEnums,
      automationStatus: AutomationStatusEnums.AUTOMATION_PENDING,
      executionTime: {
        start: 0,
        end: 0
      },
      openInsertVariableModal: false,
      selectedVariable: null
    };
  },

  created() {
    var vm = this;
    // document.querySelector('body').style.overflow = 'hidden';

    // window.addEventListener('keyup', this.handleSelectAll);

    if (this.$route.params.id) {
      this.fetchCurrentTestcaseDetails(this.$route.params.id);
      this.test_case_id = this.$route.params.id;
      this.getVariables();
      this.fetchShareableGroupList();

      this.fetchResolutionListByCaseId(this.$route.params.id);
    }
    if (this.$refs.testStepsTableWrapper) {
      this.$refs.testStepsTableWrapper.style.scrollBehavior = 'smooth';
    }

    // this.fetchServerInfo().then((res) => {
    //     this.serverInfo = res.data.running;
    // });

    // Steps Before load
    // function showADialog(e) {
    //     var confirmationMessage = 'Please save data before reload';

    //     (e || window.event).returnValue = confirmationMessage;
    //     return confirmationMessage;
    // }
    // window.addEventListener('beforeunload', function (e) {
    //     if (vm.isAnyStepChanged) {
    //         vm.clickTestStepsSave();
    //         return showADialog(e);
    //     } else {
    //         return false;
    //     }
    // });
  },
  beforeUnmount() {
    // document.querySelector('body').style.overflow = 'unset'
  },
  unmounted() {
    if (this.keepAliveInterval) {
      clearInterval(this.keepAliveInterval)
    }
  },

  async mounted() {
    // Each Automation Running timing

    // Interval of automation started

    // flatten single array of steps
    //var this.stepList = [];

    /**
     * Flatmap all steps
     */
    // var stepRecursive = (st) => {
    //   var i = 0;
    //   while (i < st.length) {
    //     if (st[i].type === 'testStep') {
    //       this.stepList.push(st[i]);
    //     }

    //     if (st[i].children && st[i].children.length > 0) {
    //       stepRecursive(st[i].children);
    //     }
    //     if (st[i]._id === this.breakPoint._id) {
    //       break;
    //     }
    //     i++;
    //   }
    // };
    // Flatten All Steps to single step
    // stepRecursive(this.steps);

    // Connection chrome runtime
    try {
      getPort = await getBrowser().runtime.connect(this.$extensionId, { name: 'testjet-extension-port' });
      await getBrowser().runtime.sendMessage(this.$extensionId, { type: "ping" });
      // Throw custom exception
      // if (!getPort) {
      //   throw new ExtensionNotFoundException('Failed to connect extension');
      // }
      this.attachPortListeners();

      // Add Event Listener
    } catch (e) {
      this.noExtention = true;
      console.error('Extension Exception', e);
    }
    // -----------------------------------
  },

  directives: {
    ClickOutside,
  },

  methods: {
    ...mapActions({
      updateTestcase: 'testCase/actionUpdateTestcase',
      clickInsertTestStep: 'testCase/actionSaveTestStep',
      actionUpdateTestCaseVariable: 'variable/actionUpdateTestCaseVariable',
      actionCheckGroupNameAlreadyExists: 'testCase/actionCheckGroupNameAlreadyExists',
      fetchCurrentTestCase: 'testCase/actionFetchCurrentTestCase',
      actionGetTestCaseVariables: 'variable/actionGetTestCaseVariables',
      insertStepOnEachMove: 'testCase/actionInsertStepOnEachMove',
      deleteTestSteps: 'testCase/actionDeleteTestSteps',
      insertShareableGroup: 'testCase/actionInsertShareableGroup',
      fetchShareableGroup: 'testCase/actionFetchShareableGroup',
      insertResolution: 'TjAdminFeatures/actionInsertResolution',
      deleteResolution: 'TjAdminFeatures/actionDeleteResolution',
      fetchResolutionListByCaseId: 'TjAdminFeatures/actionFetchResolutionListByCaseId',
      clickAssignTestStepShareable: 'testCase/actionAssignTestStepShareable',
      insertPropertieChanges: 'testCase/actionInsertPropertieChanges',
      updateTestStep: 'testCase/actionUpdateTestStep',
      fetchTestCases: 'testCase/actionFetchCaseList',
      clickSendStepsToSelenuimGrid: 'selenium/actionSendTestStepToSelenium',
      fetchServerInfo: 'testCase/actionServerInfo',
    }),

    PredefinedStepFunctionality() {

      preDefinedStepPort.onMessage.addListener(message => {
        console.log("🔰 🔰 🔰 🔰 🔰 🔰 🔰 🔰 🔰 🔰 🔰 🔰 🔰 🔰 🔰 🔰 🔰 🔰 🔰 🔰 🔰 🔰 from Pre Defined Port", message);

        if (message.from === 'content') {
          const { type, error_obj } = message;
          switch (type) {
            case 'api_test_result':
              this.differentComponentData = {
                componentName: 'apiValidation',
                data: error_obj,
              }
              this.updateCaseVariable(this.getExports(message))
              break;
            case 'validate_email_test_result':
              this.differentComponentData = {
                componentName: 'emailValidation',
                data: error_obj,
              }
              this.updateCaseVariable(this.getExports(message))
              break;
            case 'validate_db_test_result':
              this.differentComponentData = {
                componentName: 'databaseValidation',
                data: error_obj,
              }
              this.updateCaseVariable(this.getExports(message))
              break;
            case 'validate_sms_test_result':
              this.differentComponentData = {
                componentName: 'smsValidation',
                data: error_obj,
              }
              this.updateCaseVariable(this.getExports(message))
              break;

            default:
              break;
          }



          if (this.keepAlivePredefinedStepInterval) {
            clearInterval(this.keepAlivePredefinedStepInterval)
          }
        }

      })

      if (!this.keepAlivePredefinedStepInterval) {

        this.keepAlivePredefinedStepInterval = setInterval(() => {
          try {
            sendKeepAliveMessage(preDefinedStepPort)
          } catch (e) {
          }
        }, 10000);

      }
    },

    changeSelectAllStepd() {
      this.handleSelectAll(this.selectAllSteps)
    },
    onUpdateRunTimeData(data) {
      console.log("data data data", data);
      console.log("this.steps", this.steps);

      // if (data.actionType === 'updateAsDraftRunTime') {
      //   const newData = { action: customValidationList.ValidateSMS.action, type: ctrl.ADD_PREDEFINED_STEP, totalStep: this.steps.length, coordinates: {}, validationInfo: data }

      //   caseBinder(newData)
      // }
      const ind = this.steps.findIndex(el => el._id == data.existingStepId)

      const newStepObj = {
        ...this.steps[ind],
        validationInfo: {
          ...data
        },
        // is_drafted: true
      }
      // if(is_drafted in data) {
      //   newStepObj.is_drafted= true;
      // }
      if (data.actionType === 'updateAsDraftRunTime') {
        newStepObj.is_drafted = true;
      }
      delete newStepObj.validationInfo.existingStepId;
      this.steps.splice(ind, 1, newStepObj)

      console.log('this.steps :::::::::::::::::', { data, ind, steps: this.steps });
      this.differentComponentStepData = null;
      this.showDifferentComponent = { show: false, componentName: '' };
    },
    async onValidateSMSTest(data) {
      const message = {
        from: 'local',
        baseUrl: this.config.baseUrl,
        validation: this.showDifferentComponent.data,
        winId: this.winId,
        config: this.config,
        tabId: this.tabId,
        type: ctrl.ADD_PREDEFINED_STEP,
        data: { action: customValidationList.ValidateSMS.action, type: ctrl.ADD_PREDEFINED_STEP, totalStep: this.steps.length, coordinates: {}, validationInfo: data }
      }
      // delete data._id

      console.log("=================>HELLO");
      if (data.actionType === 'run_sms') {
        message.type = 'execute_sms_validation';
        try {
          preDefinedStepPort.disconnect();
        } catch (error) {
          console.log("couldn't disconnect port", error);
        }

        try {
          preDefinedStepPort = await getBrowser().runtime.connect(this.$extensionId, { name: 'testjet-extension-predefined-port' });

        } catch (error) {
          throw new ExtensionNotFoundException('Failed to connect extension');
        }

        this.PredefinedStepFunctionality();

        await new Promise(r => setTimeout(r, 300));
        preDefinedStepPort.postMessage(message);
      }
      else if (data.actionType === 'udraft') {
        console.log(" Hello Hello Hello");
        const existingStep = this.steps.filter(st => st._id == data.existingStepId)[0]
        if (existingStep) {
          this.addToStepFromDraft(data)
        }
        // else {
        //   this.stepsSaveAsDraft(message.data);
        // }
      }
      else if (data.actionType === 'addToStepFromDraft' && data.existingStepId) {
        this.addToStepFromDraft(data);
      }
      //? This is for updating the update validation step
      else if (data.actionType === 'updateStep') {
        if (data.existingStepId) {
          const existingStep = this.steps.filter(st => st._id == data.existingStepId)[0]
          if (existingStep) {
            const ind = this.steps.indexOf(existingStep);
            const updatedSMSValidationStep = { ...existingStep, validationInfo: { ...data } };
            delete updatedSMSValidationStep["validationInfo"]["existingStepId"];
            delete updatedSMSValidationStep["validationInfo"]["actionType"];

            for (let j = 0; j < updatedSMSValidationStep.validationInfo.assertions.length; j++) {
              const assertion = updatedSMSValidationStep.validationInfo.assertions[j];
              delete assertion.result;
            }

            this.steps[ind] = updatedSMSValidationStep
            await this.updateTestStep({ "stepId": data.existingStepId, "step": updatedSMSValidationStep })
              .then((resBody) => {
                if (resBody.status === 200) {
                  this.steps = [];
                  this.showSuccess(resBody.message.MSG_SHORT, resBody.message.MSG_LONG);
                  this.fetchCurrentTestcaseDetails(this.test_case_id);
                  this.showDifferentComponent = { show: false, componentName: '', }
                } else {
                  this.showError('Test step create unsuccessful.');
                }
              })
              .catch((err) => {
                console.log("🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 ERROR", err);
              });
          } else {
            console.log("Nothing to Update");
          }
        }
      }
      else {
        if (!this.tabId) {
          this.isRecordNeeded = true;
          return
        }

        try {
          getPort.postMessage(message);
        } catch (e) {
          console.log("ERROR +++++++++++++++++++", e);
        } finally {
          this.showDifferentComponent = { show: false, componentName: '', }
          this.differentComponentData = { componentName: '', data: null }
        }
      }


    },

    async onValidateEmailTest(data) {
      const message = {
        from: 'local',
        baseUrl: this.config.baseUrl,
        validation: this.showDifferentComponent.data,
        winId: this.winId,
        config: this.config,
        tabId: this.tabId,
        type: ctrl.ADD_PREDEFINED_STEP,
        data: { action: customValidationList.ValidateEmail.action, type: ctrl.ADD_PREDEFINED_STEP, totalStep: this.steps.length, coordinates: {}, validationInfo: data }
      }
      // delete data._id

      if (data.actionType === 'execute_email') {
        message.type = 'execute_email_validation';
        try {
          preDefinedStepPort.disconnect();
        } catch (error) {
        }

        try {
          preDefinedStepPort = await getBrowser().runtime.connect(this.$extensionId, { name: 'testjet-extension-predefined-port' });

        } catch (error) {
          throw new ExtensionNotFoundException('Failed to connect extension');
        }

        this.PredefinedStepFunctionality();

        await new Promise(r => setTimeout(r, 300));
        preDefinedStepPort.postMessage(message);
      }
      else if (data.actionType === 'draft' || data.actionType === 'addToStepFromDraft' && data.existingStepId) {
        const existingStep = this.steps.filter(st => st._id == data.existingStepId)[0]
        if (existingStep) {
          const drafted = data.actionType === 'addToStepFromDraft' ? false : true;
          const ind = this.steps.indexOf(existingStep);
          const updatedEmailValidationStep = { ...existingStep, validationInfo: { ...data } };
          delete updatedEmailValidationStep["validationInfo"]["existingStepId"];
          updatedEmailValidationStep['is_drafted'] = drafted;
          delete updatedEmailValidationStep["validationInfo"]["actionType"];

          for (let j = 0; j < updatedEmailValidationStep.validationInfo.assertions.length; j++) {
            const assertion = updatedEmailValidationStep.validationInfo.assertions[j];
            delete assertion.result;
          }

          this.steps[ind] = updatedEmailValidationStep;

          await this.updateTestStep({ "stepId": data.existingStepId, "step": updatedEmailValidationStep })
            .then((resBody) => {
              if (resBody.status === 200) {
                this.steps = [];
                this.showSuccess(resBody.message.MSG_SHORT, drafted ? "Step drafted" : "Step Added");
                this.fetchCurrentTestcaseDetails(this.test_case_id);
                this.showDifferentComponent = { show: false, componentName: '', }
              } else {
                this.showError('Test step create unsuccessful.');
              }
            })
            .catch((err) => {
            });
        }
      }
      //? This is for updating the update validation step
      else if (data.actionType === 'updateStep') {
        if (data.existingStepId) {
          const existingStep = this.steps.filter(st => st._id == data.existingStepId)[0]
          if (existingStep) {
            const ind = this.steps.indexOf(existingStep);
            const updatedEmailValidationStep = { ...existingStep, validationInfo: { ...data } };
            delete updatedEmailValidationStep["validationInfo"]["existingStepId"];
            delete updatedEmailValidationStep["validationInfo"]["actionType"];

            for (let j = 0; j < updatedEmailValidationStep.validationInfo.assertions.length; j++) {
              const assertion = updatedEmailValidationStep.validationInfo.assertions[j];
              delete assertion.result;
            }

            this.steps[ind] = updatedEmailValidationStep
            await this.updateTestStep({ "stepId": data.existingStepId, "step": updatedEmailValidationStep })
              .then((resBody) => {
                if (resBody.status === 200) {
                  this.steps = [];
                  this.showSuccess(resBody.message.MSG_SHORT, resBody.message.MSG_LONG);
                  this.fetchCurrentTestcaseDetails(this.test_case_id);
                  this.showDifferentComponent = { show: false, componentName: '', }
                } else {
                  this.showError('Test step create unsuccessful.');
                }
              })
              .catch((err) => {
              });
          } else {
          }
        }
      }
      else {
        if (!this.tabId) {
          this.isRecordNeeded = true;
          return
        }

        try {
          getPort.postMessage(message);
        } catch (e) {
        } finally {
          this.showDifferentComponent = { show: false, componentName: '', }
          this.differentComponentData = { componentName: '', data: null }
        }
      }


    },

    async onApiValidationValidate(data) {
      const message = {
        from: 'local',
        baseUrl: this.config.baseUrl,
        validation: this.showDifferentComponent.data,
        winId: this.winId,
        config: this.config,
        tabId: this.tabId,
        type: ctrl.ADD_PREDEFINED_STEP,
        data: { _id: data._id, action: customValidationList.API_VALIDATION.action, type: ctrl.ADD_PREDEFINED_STEP, totalStep: this.steps.length, coordinates: {}, validationInfo: data }
      }
      delete data._id

      //? This condition is for when the user presses send button
      if (data.actionType === 'send') {
        message.type = 'execute_api'
        try {
          preDefinedStepPort.disconnect();
        } catch (error) {
        }

        //* preDefinedStepPort could be null when the user is trying to send api request
        try {
          preDefinedStepPort = await getBrowser().runtime.connect(this.$extensionId, { name: 'testjet-extension-predefined-port' });

        } catch (error) {
          throw new ExtensionNotFoundException('Failed to connect extension');
        }
        this.PredefinedStepFunctionality();

        await new Promise(r => setTimeout(r, 300));
        preDefinedStepPort.postMessage(message);

      }
      //? This is for updating the Api validation step to DRAFT if the step already exists !
      else if (data.actionType === 'draft' || data.actionType === 'addToStepFromDraft' && data.existingStepId) {
        const existingStep = this.steps.filter(st => st._id == data.existingStepId)[0]
        if (existingStep) {
          const drafted = data.actionType === 'addToStepFromDraft' ? false : true;
          const ind = this.steps.indexOf(existingStep);
          const updatedApivalidationStep = { ...existingStep, validationInfo: { ...data } };
          delete updatedApivalidationStep["validationInfo"]["existingStepId"];
          updatedApivalidationStep['is_drafted'] = drafted;
          delete updatedApivalidationStep["validationInfo"]["actionType"];

          for (let j = 0; j < updatedApivalidationStep.validationInfo.assertions.length; j++) {
            const assertion = updatedApivalidationStep.validationInfo.assertions[j];
            delete assertion.result;
          }

          this.steps[ind] = updatedApivalidationStep;

          await this.updateTestStep({ "stepId": data.existingStepId, "step": updatedApivalidationStep })
            .then((resBody) => {
              if (resBody.status === 200) {
                this.steps = [];
                this.showSuccess(resBody.message.MSG_SHORT, drafted ? "Step drafted" : "Step Added");
                this.fetchCurrentTestcaseDetails(this.test_case_id);
                this.showDifferentComponent = { show: false, componentName: '', }
              } else {
                this.showError('Test step create unsuccessful.');
              }
            })
            .catch((err) => {
            });
        }
      }
      //? This is for updating the Api validation step
      else if (data.actionType === 'updateStep') {
        if (data.existingStepId) {
          const existingStep = this.steps.filter(st => st._id == data.existingStepId)[0]
          if (existingStep) {
            const ind = this.steps.indexOf(existingStep);
            const updatedApivalidationStep = { ...existingStep, validationInfo: { ...data } };
            delete updatedApivalidationStep["validationInfo"]["existingStepId"];
            delete updatedApivalidationStep["validationInfo"]["actionType"];

            for (let j = 0; j < updatedApivalidationStep.validationInfo.assertions.length; j++) {
              const assertion = updatedApivalidationStep.validationInfo.assertions[j];
              delete assertion.result;
            }

            this.steps[ind] = updatedApivalidationStep
            await this.updateTestStep({ "stepId": data.existingStepId, "step": updatedApivalidationStep })
              .then((resBody) => {
                if (resBody.status === 200) {
                  this.steps = [];
                  this.showSuccess(resBody.message.MSG_SHORT, resBody.message.MSG_LONG);
                  this.fetchCurrentTestcaseDetails(this.test_case_id);
                  this.showDifferentComponent = { show: false, componentName: '', }
                } else {
                  this.showError('Test step create unsuccessful.');
                }
              })
              .catch((err) => {
              });
          } else {
          }
        }
      }
      //? This is for sending api request to extention while Automation is playing
      else {

        if (!this.tabId) {
          this.isRecordNeeded = true;
          return
        }

        if (data.actionType === 'draft') {
          message.data["is_drafted"] = true;
        }

        try {
          getPort.postMessage(message);
        } catch (e) {
        } finally {
          this.showDifferentComponent = { show: false, componentName: '', }
          this.differentComponentData = { componentName: '', data: null }
        }
      }


    },

    async onDatabaseValidationValidate(data) {
      const message = {
        from: 'local',
        baseUrl: this.config.baseUrl,
        validation: this.showDifferentComponent.data,
        winId: this.winId,
        config: this.config,
        tabId: this.tabId,
        type: ctrl.ADD_PREDEFINED_STEP,
        data: { action: customValidationList.ValidateDatabse.action, type: ctrl.ADD_PREDEFINED_STEP, totalStep: this.steps.length, coordinates: {}, validationInfo: data }
      }

      //? This condition is for when the user presses send button
      if (data.actionType === 'executeQuery') {
        message.type = 'execute_db_validation';
        try {
          preDefinedStepPort.disconnect();
        } catch (error) {
        }

        //* preDefinedStepPort could be null when the user is trying to send execute database query request
        try {
          preDefinedStepPort = await getBrowser().runtime.connect(this.$extensionId, { name: 'testjet-extension-predefined-port' });

        } catch (error) {
          throw new ExtensionNotFoundException('Failed to connect extension');
        }

        this.PredefinedStepFunctionality();

        await new Promise(r => setTimeout(r, 300));

        preDefinedStepPort.postMessage(message);

        //? This is for sending execute database query request to extention while Automation is playing
      } //? This is for updating the Api validation step to DRAFT if the step already exists !
      else if (data.actionType === 'draft' || data.actionType === 'addToStepFromDraft' && data.existingStepId) {
        const existingStep = this.steps.filter(st => st._id == data.existingStepId)[0]
        if (existingStep) {
          const drafted = data.actionType === 'addToStepFromDraft' ? false : true;
          const ind = this.steps.indexOf(existingStep);
          const updatedApivalidationStep = { ...existingStep, validationInfo: { ...data } };
          delete updatedApivalidationStep["validationInfo"]["existingStepId"];
          updatedApivalidationStep['is_drafted'] = drafted;
          delete updatedApivalidationStep["validationInfo"]["actionType"];

          for (let j = 0; j < updatedApivalidationStep.validationInfo.assertions.length; j++) {
            const assertion = updatedApivalidationStep.validationInfo.assertions[j];
            delete assertion.result;
          }

          this.steps[ind] = updatedApivalidationStep;

          await this.updateTestStep({ "stepId": data.existingStepId, "step": updatedApivalidationStep })
            .then((resBody) => {
              if (resBody.status === 200) {
                this.steps = [];
                this.showSuccess(resBody.message.MSG_SHORT, drafted ? "Step drafted" : "Step Added");
                this.fetchCurrentTestcaseDetails(this.test_case_id);
                this.showDifferentComponent = { show: false, componentName: '', }
              } else {
                this.showError('Test step create unsuccessful.');
              }
            })
            .catch((err) => {
            });
        }
      }
      //? This is for updating the Api validation step
      else if (data.actionType === 'updateStep') {
        if (data.existingStepId) {
          const existingStep = this.steps.filter(st => st._id == data.existingStepId)[0]
          if (existingStep) {
            const ind = this.steps.indexOf(existingStep);
            const updatedApivalidationStep = { ...existingStep, validationInfo: { ...data } };
            delete updatedApivalidationStep["validationInfo"]["existingStepId"];
            delete updatedApivalidationStep["validationInfo"]["actionType"];

            for (let j = 0; j < updatedApivalidationStep.validationInfo.assertions.length; j++) {
              const assertion = updatedApivalidationStep.validationInfo.assertions[j];
              delete assertion.result;
            }

            this.steps[ind] = updatedApivalidationStep
            await this.updateTestStep({ "stepId": data.existingStepId, "step": updatedApivalidationStep })
              .then((resBody) => {
                if (resBody.status === 200) {
                  this.steps = [];
                  this.showSuccess(resBody.message.MSG_SHORT, resBody.message.MSG_LONG);
                  this.fetchCurrentTestcaseDetails(this.test_case_id);
                  this.showDifferentComponent = { show: false, componentName: '', }
                } else {
                  this.showError('Test step create unsuccessful.');
                }
              })
              .catch((err) => {
              });
          } else {
          }
        }
      }

      else {

        if (!this.tabId) {
          this.isRecordNeeded = true;
          return
        }

        try {
          getPort.postMessage(message);
        } catch (e) {
        } finally {
          this.showDifferentComponent = { show: false, componentName: '', }
          this.differentComponentData = { componentName: '', data: null }
        }
      }


    },
    attachPortListeners() {
      var vm = this;
      var PER_SCROLL_SIZE = 80;
      let idx = 0;
      let eachIterator = 200;
      let timeStart = 0;
      var stepRecursive = (st) => {
        var i = 0;
        while (i < st.length) {
          if (st[i].type === 'testStep') {
            this.stepList.push(st[i]);
          }

          if (st[i].children && st[i].children.length > 0) {
            stepRecursive(st[i].children);
          }
          if (st[i]._id === this.breakPoint._id) {
            break;
          }
          i++;
        }
      };
      /**
      * Set Steps
      */
      var setStepRecursive = (steps, _id, error_obj, broken_links = [], automation_screenshot_src = null) => {
        var i = 0;
        //var groups = vm.steps;
        while (i < steps.length) {
          if (steps[i]._id === _id) {
            vm.eachIteratorStep.call(vm, steps[i]);
            steps[i].error_obj = error_obj;
            steps[i].automation_screenshot_src = automation_screenshot_src;
            if (broken_links && broken_links.length) {
              steps[i].broken_links = broken_links;
            }
            if (error_obj.status === true) {
            }
          }
          if (steps[i].children && steps[i].children.length > 0) {
            setStepRecursive(steps[i].children, _id, error_obj, broken_links, automation_screenshot_src);
          }
          i++;
        }
      };

      stepRecursive(this.steps);

      getPort.onMessage.addListener((message) => {
        const { data, type, tabId, _id, error_obj, winId, broken_links, automation_screenshot_src, screenshotData } = message;
        // ---------------------------------
        console.log("🔰 🔰 🔰 🔰 🔰 🔰 🔰 🔰 🔰 🔰 🔰 🔰 🔰 🔰 🔰 🔰 🔰 🔰 🔰 🔰 🔰 🔰 from GET PORT", message);
        if (message.from === 'background') {
          switch (type) {
            case ctrl.WINDOW_CREATED:
              autWindowId = winId;
              this.winId = winId;
              this.tabId = tabId;
              break;

            case ctrl.EVENT:
              if (winId != autWindowId)
                break;
              this.isAnyStepChanged = true;
              var cases = caseBinder(this, data);
              const continuousActions = ['input', 'scroll', 'resize', 'click', 'select', 'keydown', 'mousedown'];
              if (continuousActions.includes(data.action)) {
                // Increment counter through background script
                let indicatorAction = data.action;
                if (data.action === 'keydown') {
                  if (data.key_code === 13) {
                    indicatorAction = 'enter'
                  } else if (data.key_code === 9) {
                    indicatorAction = 'tab'
                  }
                }
                getPort.postMessage({
                  type: 'increment_counter',
                  action: indicatorAction,
                  totalStep: this.steps.length,
                });
              }
              switch (data.action) {
                case customValidationList.TextToAssign.action:
                  this.visibleProperties = true;
                  this.currentProperties = cases;
                  break;
                case customValidationList.ValidateCssProperty.action:
                  this.visibleProperties = true;
                  this.currentProperties = cases;
                  break;
                case customValidationList.ValidateHtmlAttribute.action:
                  this.visibleProperties = true;
                  this.currentProperties = cases;
                  break;
                case customValidationList.Refresh.action:
                  break;
                case customValidationList.Sleep.action:
                  this.visibleProperties = true;
                  this.currentProperties = cases;
                  break;
                case customValidationList.CHECK_BROKEN_LINK.action:
                  break;
                case customValidationList.ValidateDatabse.action:
                  break;
                case customValidationList.API_VALIDATION.action:
                  break;
                case customValidationList.Navigate.action:
                  this.visibleProperties = true;
                  this.currentProperties = cases;
                  break;
                case customValidationList.URL_Validation.action:
                  this.visibleProperties = true;
                  this.currentProperties = cases;
                  break;
                case customValidationList.SCROLL.action:
                  if (data.type && data.type === ctrl.ADD_PREDEFINED_STEP) {
                    this.visibleProperties = true;
                    this.currentProperties = cases;
                  }
                  break;
                case customValidationList.GENERATE_PHONE_NUMBER.action:
                  this.visibleProperties = true;
                  this.steps[this.steps.length - 1].value = '';
                  this.currentProperties = this.steps[this.steps.length - 1];
                  break;
                case customValidationList.OTP_VALIDATION.action:
                  this.visibleProperties = true;
                  this.currentProperties = this.steps[this.steps.length - 1];
                  break;
              }
              break;
            case ctrl.TABID:
              this.tabId = tabId;
              this.winId = winId;
              break;
            case ctrl.WINDOWCLOSED:
              this.tabId = null;
              this.winId = null;

              idx = 0;
              clearInterval(this.timer);
              this.currentAutomationStep = {};

              if (message.closeType == ctrl.STOP_RECORD) {
                this.$toast.add({
                  severity: 'warn',
                  summary: 'Recording has been stopped.',
                  life: 3000,
                });
              } else if (message.closeType === ctrl.HARD) {
                const toastMessage = {
                  severity: 'warn',
                  summary: 'AUT Window has been closed.',
                  life: 3000,
                };
                if (this.automationStatus === this.automationStatusEnums.RECORD_RUNNING) {
                  toastMessage.summary = 'Recording has been stopped.';
                }
                if (this.automationStatus === this.automationStatusEnums.AUTOMATION_RUNNING) {
                  toastMessage.summary = 'Automation has been stopped.';
                }
                this.$toast.add(toastMessage);
              }
              if (this.keepAliveInterval) {
                clearInterval(this.keepAliveInterval)
              }

              this.automationStatus = this.automationStatusEnums.AUTOMATION_PENDING;
              break;
            case ctrl.RECEIVED_AUTOMATION_CONFIRMATION:
              if (this.automationStatus != this.automationStatusEnums.AUTOMATION_RUNNING) {
                break;
              }
              this.tabId = tabId;
              this.winId = winId;
              idx = 0;
              var { baseUrl } = this.config;
              // Clear StepList
              this.stepList = [];
              // Set values recursivel
              stepRecursive(this.steps);
              if (this.timer) {
                clearInterval(this.timer);
              }

              this.timer = setInterval(() => {
                if (idx >= this.stepList.length) {
                  // check automation is completed
                  this.isAutomationCompleted = true;
                  this.automationStatus = this.automationStatusEnums.AUTOMATION_PENDING;
                  this.closeWindow();
                  this.$toast.add({
                    severity: 'success',
                    summary: 'Test Step completed',
                    life: 3000,
                  });

                  this.executionTime.end = Date.now();

                  //Timer Stop
                  clearInterval(this.timer);
                  setTimeout(() => {
                    this.currentAutomationStep = {};
                  }, 1000);
                } else {
                  //  check automation is not completed
                  this.isAutomationCompleted = false;
                  if (this.isAutomationPaused && this.automationStatus != this.automationStatusEnums.AUTOMATION_PAUSED) {
                    // get steplist with increment index idx
                    var step = this.stepList[idx];

                    this.setVariableValue(step, this.caseVariables);
                    this.currentAutomationStep = step;

                    // console.log('%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%',step);

                    getPort.postMessage({
                      type: ctrl.PLAY_AUTOMATION,
                      from: 'local',
                      baseUrl,
                      index: idx,
                      total: this.stepList.length,
                      config: this.config,
                      tabId: this.tabId,
                      winId: this.winId,
                      step,
                    });
                  } else {
                  }
                }
                // Check timeout of automation complete
                timeStart = timeStart + eachIterator / 200;
              }, eachIterator);

              break;
            case ctrl.RECEIVED_TEST_RESULT:
              let context = this;
              context.updateCaseVariable(context.getExports(message))
              this.drawBoundingRect(screenshotData).then(data => {
                setStepRecursive(context.steps, _id, error_obj, broken_links, data ? data.screenshot_src : "");
              })

              break;
            case ctrl.CURRENT_AUTOMATION_STEP:
              var lastStep = this.stepList[idx - 1];
              if (lastStep._id === _id) {
                this.currentAutomationStep = this.stepList.find((step) => step._id === _id);
                setTimeout(() => {
                  this.currentAutomationStep = {};
                }, 2000);
              } else {
                this.currentAutomationStep = this.stepList.find((step) => step._id === _id);
              }
              break;

            case ctrl.BEFORE_NAVIGATION:
              this.isAutomationPaused = true;
              break;
            case ctrl.ON_COMPLETE:
              this.tabId = tabId;
              this.isAutomationPaused = false;
              break;
            case ctrl.COMPLETED_STEP:
              // eslint-disable-next-line no-case-declarations
              let completedStep = this.stepList.find((step) => step._id === _id);
              // Check whether it is currentAutomationStep, then increment count
              if (completedStep._id == this.currentAutomationStep._id) {
                idx++;
                timeStart = 0;
              }
              break;
            case ctrl.PAUSE:
              this.isAutomationPaused = false;
              break;
            case ctrl.PlAY_AGAIN:
              this.isAutomationPaused = true;
              break;
          }
        }
      });
    },
    drawBoundingRect(data) {
      return new Promise((resolve, _reject) => {
        if (data && data.bounding_react && data.screenshot_src && data.tabInfo && data.tabInfo.size) {
          const img = new Image();
          img.src = data.screenshot_src;
          img.onload = () => {
            const canvas = document.createElement('canvas');
            // if there's tabInfo in data, use that for height and width from tabInfo.size
            canvas.width = data.tabInfo.size.width;
            canvas.height = data.tabInfo.size.height;

            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, data.tabInfo.size.width, data.tabInfo.size.height);
            // Check if the bounding_react is within the image boundaries
            if (data.bounding_react.x >= 0 && data.bounding_react.y >= 0 && data.bounding_react.x + data.bounding_react.width <= img.width && data.bounding_react.y + data.bounding_react.height <= img.height) {
              ctx.beginPath();
              ctx.lineWidth = '3';
              ctx.strokeStyle = 'red';
              ctx.rect(data.bounding_react.x, data.bounding_react.y, data.bounding_react.width, data.bounding_react.height);
              ctx.stroke();
            }
            data.screenshot_src = canvas.toDataURL("image/webp", 0.5);
            resolve(data);
          };
        } else {
          resolve(data)
        }
      });
    },

    closeMessage(val) {
      this.noExtention = !this.noExtention;
    },


    // New Steps ALL Selection and Deselection Method
    handleSelectAll(selectionType) {
      this.allCheckList.splice(0, this.allCheckList.length)
      this.selectedCheckedArr.splice(0, this.selectedCheckedArr.length)
      var selectedRecursive = (st, type) => {
        var i = 0;
        while (i < st.length) {
          if (type === 'select') {
            st[i].is_checked = true;
            this.selectedCheckedArr.push(st[i]);
            this.allCheckList.push(st[i]._id);
          }

          if (type === 'unselect') {
            this.selectedCheckedArr = [];
            st[i].is_checked = false;
            this.allCheckList.splice(this.allCheckList.indexOf(st[i]._id), 1);
          }

          if (st[i].children && st[i].children.length > 0) selectedRecursive(st[i].children, type);
          i++;
        }
      };

      //Select All by recursive way
      if (selectionType) {
        selectedRecursive(this.steps, 'select');
      } else {
        selectedRecursive(this.steps, 'unselect');
      }
    },

    onApiValidationClicked(step) {
      this.differentComponentStepData = step;
      this.showDifferentComponent = {
        show: true,
        componentName: 'apiValidation'
      }
    },
    onEmailValidationClicked(step) {
      this.differentComponentStepData = step;
      this.showDifferentComponent = {
        show: true,
        componentName: 'emailValidation'
      }
    },

    onSMSValidationClicked(step) {
      console.log('++++++++++onSMSValidationClicked++++++++++', step);
      this.differentComponentStepData = step;
      this.showDifferentComponent = {
        show: true,
        componentName: 'smsValidation'
      }
    },

    onDatabaseValidationClicked(step) {
      this.differentComponentStepData = step;
      this.showDifferentComponent = {
        show: true,
        componentName: 'databaseValidation'
      }
    },
    onBrokenLink(step) {
      const { broken_links } = step;
      this.currentLinks = broken_links;
      this.differentComponentStepData = step;
      this.showDifferentComponent = {
        show: true,
        componentName: 'brokenLink'
      }
    },
    //Each Test Step Complete
    eachIteratorStep(data) {
    },
    onStepScroll(y) {

      if (y > this.scrollPosition && this.$refs.testStepsTableWrapper) {
        this.$refs.testStepsTableWrapper.scrollTo({
          top: y,
          left: 0,
          behavior: 'smooth',
        });
        this.scrollPosition = y;
      }
    },

    showSuccess(summary, detail) {
      this.$toast.add({
        severity: 'success',
        summary: summary,
        detail: detail,
        life: 3000,
      });
    },

    showError(detail) {
      this.$toast.add({
        severity: 'error',
        summary: 'Error Message',
        detail: detail,
        life: 3000,
      });
    },

    resetCheckBoxValues() {
      this.$refs.nestedTestStepsRef.resetCheckValue();
    },

    resetRequiredField(fieldName) {
      this.requiredField[fieldName] = '';
      this.errorMsg[fieldName] = '';
    },

    changeBaseUrl() {
      const regex = RegExp('(https?:\/\/)((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+@#]*)*(\\[;&a-z\\d%_.~+=-@]*)?(\\#[-a-z\\d_@]*)?$', 'i');

      if (this.config.baseUrl.match(regex)) {
        return this.resetRequiredField('baseUrl');
      } else if (!this.config.baseUrl) {
        this.errorMsg['baseUrl'] = 'Base Url is required';
        this.requiredField.baseUrl = 'p-invalid';
      } else {
        this.errorMsg['baseUrl'] = 'Invalid URL.';
        this.requiredField.baseUrl = 'p-invalid';
      }
    },

    onHideResolutionModal() {
      this.showResolutionModal = false;
    },

    async getVariables() {
      const data = {
        data: { caseId: this.$route.params.id },
        type: 'web'
      }
      const [resp, err] = await this.actionGetTestCaseVariables(data);
    },

    setVariableValue(step, variables) {
      if (step.variable_id && step.action === customValidationList.InsertVariable.action && variables.length) {
        const variable = variables.find(v => v._id == step.variable_id);
        // console.log({step,variables, variable});
        step.value = variable?.value || '';
      }
    },

    async fetchCurrentTestcaseDetails(test_case_id) {
      let resp = [];
      try {
        const resBody = await this.fetchCurrentTestCase(test_case_id);
        const resData = resBody.data.testCaseResult;
        this.testCaseResult = resData;
        this.config.baseUrl = resData.base_url;
        this.config.base_url = resData.base_url;
        this.steps = [...resBody.data.testStepsResult];
        console.log(this.steps);
        if (resData && resData.screen_resolution_id) {

          this.config.resolution.width = resData.screen_resolution_id.screen_size.width;
          this.config.resolution.height = resData.screen_resolution_id.screen_size.height;
          this.config.resolution.resolutionId = resData.screen_resolution_id._id;
        }
        this.isStepsLoaded = true;
        resp = [resData, null];

      } catch (err) {
        this.isStepsLoaded = true;
        resp = [null, err];
      } finally {
        return resp;
      }
      // await this.fetchCurrentTestCase(test_case_id)
      //   .then((resBody) => {
      //     if (resBody.status === 200 && resBody.data.testCaseResult) {
      //       const resData = resBody.data.testCaseResult;
      //       this.testCaseResult = resData;
      //       this.config.baseUrl = resData.base_url;
      //       this.config.base_url = resData.base_url;
      //       this.steps = [...resBody.data.testStepsResult];
      //       if (resData && resData.screen_resolution_id) {

      //         this.config.resolution.width = resData.screen_resolution_id.screen_size.width;
      //         this.config.resolution.height = resData.screen_resolution_id.screen_size.height;
      //         this.config.resolution.resolutionId = resData.screen_resolution_id._id;
      //       }
      //       this.isStepsLoaded = true;
      //     }
      //   })
      //   .catch((err) => {
      //     this.isStepsLoaded = true;
      //   });
    },

    async testcaseUpdate(msgvisible) {
      let selectedIssueTypeId = '';
      if (this.selectedIssueTypeObj && this.selectedIssueTypeObj._id) {
        selectedIssueTypeId = this.selectedIssueTypeObj._id;
      }

      let updateTestCaseObj = {
        _id: this.currentTestcase._id,
        test_case_name: this.currentTestcase.test_case_name,
        base_url: this.config.baseUrl,
        screen_resolution_id: this.config.resolution.resolutionId,
        tags: this.currentTestcase.tags,
        issue_type_id: selectedIssueTypeId,
        project_id: this.currentTestcase.project_id,
      };

      await this.updateTestcase(updateTestCaseObj)
        .then((resBody) => {
          if (resBody.status === 200) {
            this.isAnyStepChanged = false;
            if (msgvisible) {
              this.showSuccess(resBody.message.MSG_SHORT, resBody.message.MSG_LONG);
              this.baseUrlUpdateable = false;
            }
          } else {
            this.showError('Test Case Update Unsuccessful.');
          }
        })
        .catch((err) => {
        });
    },

    clickSaveCusResolution() {
      if (!this.saveCusResolutionValidation()) {
        const reqDataObj = {
          title: 'custom',
          device_name: 'custom',
          screen_size: {
            width: this.cusResolutionWidth,
            height: this.cusResolutionHeight,
          },
          resolution_type: 'custom',
          test_case_id: this.test_case_id,
        };

        this.cusResObject = reqDataObj;

        this.insertResolution(reqDataObj)
          .then((resBody) => {
            if (resBody.status === 200) {
              this.showSuccess(resBody.message.MSG_SHORT, resBody.message.MSG_LONG);
              this.fetchResolutionListByCaseId(this.test_case_id);
              this.cusResolutionWidth = null;
              this.cusResolutionHeight = null;

              // this.showResolutionModal = false;
            } else {
              this.showError('Resolution Save Unsuccessful.');
            }
          })
          .catch((err) => {
          });
      }
    },

    saveCusResolutionValidation() {
      let requiredFieldEmpty = false;
      if (this.cusResolutionWidth == null) {
        requiredFieldEmpty = true;
        this.requiredField.cusResolutionWidth = 'p-invalid';
        this.errorMsg['cusResolutionWidth'] = 'Width is missing.';
      }
      if (this.cusResolutionHeight == null) {
        requiredFieldEmpty = true;
        this.requiredField.cusResolutionHeight = 'p-invalid';
        this.errorMsg['cusResolutionHeight'] = 'HeightL is missing.';
      }
      return requiredFieldEmpty;
    },

    fetchShareableGroupList() {
      this.fetchShareableGroup(getSelectedCurrentProjectId())
        .then((resBody) => {
          if (resBody.status === 200) {
            this.shareableGroupList = resBody.data.shareableGroupResult;
          }
        })
        .catch((err) => {
        });
    },

    shareableGroupSave(changeProperties, sharesteps) {
      let idArrays = [changeProperties._id];
      // changeProperties.children.filter((steps) => {
      //     idArrays.push(steps._id);
      // });

      function fillIdArrays(parent) {
        // idArrays.push(parent._id);
        if (parent.children.length > 0) {
          parent.children.map((child) => {
            idArrays.push(child._id);
            if (child.type == 'group') {
              fillIdArrays(child);
            }
          });
        }
      }

      fillIdArrays(changeProperties);

      let shareableStepsObj = {
        list_index: 0,
        is_sharable: sharesteps,
        test_step_ids: idArrays,
        shareable_to_case_ids: [],
        project_id: this.currentTestcase.project_id,
      };

      return new Promise((resolve, reject) => {
        this.insertShareableGroup(shareableStepsObj)
          .then((resBody) => {
            if (resBody.status === 200) {
              // this.showSuccess(resBody.message.MSG_SHORT, resBody.message.MSG_LONG);
              this.$refs.propertiesDialogRef.isStepShared = false;
              this.fetchShareableGroupList();
              resolve(resBody);
            } else {
              reject();
              this.showError('Shareable Group Unsuccessful.');
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    clickAddSharedGroupInSteps(checkedSharedGroupList) {
      this.clickTestStepsSave();
      if (checkedSharedGroupList !== null) {
        let sharedGroupCreateObj = {
          base_info: {
            test_case_id: this.test_case_id,
            project_id: getSelectedCurrentProjectId(),
          },
          test_step_list: checkedSharedGroupList,
        };

        this.clickAssignTestStepShareable(sharedGroupCreateObj)
          .then((resBody) => {
            if (resBody.status === 200) {
              this.showSuccess(resBody.message.MSG_SHORT, resBody.message.MSG_LONG);
              this.$refs.validationPanelRef.checkedShareGroup = null;
              this.fetchCurrentTestcaseDetails(this.test_case_id);
            } else {
              this.showError('Shared Group Add Unsuccessfull.');
            }
          })
          .catch((err) => {
          });
      } else {
        this.$toast.add({
          severity: 'warn',
          summary: 'Warn Message',
          detail: 'Please select shareable group first.',
          life: 3000
        });
      }
    },

    async clickCreateGroup() {
      const data = {
        project_id: getSelectedCurrentProjectId(),
        test_group_name: this.groupName,
      };

      if (!this.groupName.length) {
        return this.$toast.add({
          severity: 'error',
          summary: 'Error Message',
          detail: `Please provide a group name`,
          life: 3000
        });
      }

      const [resp, err] = await this.actionCheckGroupNameAlreadyExists(data);

      if (err) {
        return this.$toast.add({
          severity: 'error',
          summary: 'Error Message',
          detail: `"${this.groupName}" ${err.response.data.errorMsg} Please provide a unique name`,
          life: 6000
        });
      } else {
        await this.clickTestStepsSave();
        let newGroupCreateObj = {
          base_info: {
            test_case_id: this.test_case_id,
            project_id: getSelectedCurrentProjectId(),
          },
          test_step_group: {
            description: this.groupName,
            type: 'group',
            test_case_id: this.test_case_id,
            project_id: getSelectedCurrentProjectId(),
          },
          organizationId: this.loggedInUserInfo.organizationId,
          test_step_list: this.selectedCheckedArr.map((selectedStep, index) => {
            let step = this.steps.find((step) => step._id == selectedStep._id);
            if (!selectedStep.parent && step) {
              selectedStep._id = step._id;
              selectedStep.screenshot_src = step.screenshot_src;
              selectedStep.list_index = index;
            }
            return selectedStep;
          }),
        };
        if (this.selectedCheckedArr.length > 0) {
          this.clickInsertTestStep(newGroupCreateObj)
            .then((resBody) => {
              if (resBody.status === 200) {
                this.groupName = '';
                this.selectedCheckedArr = [];
                this.showSuccess(resBody.message.MSG_SHORT, resBody.message.MSG_LONG);
                this.displayConfirmation = !this.displayConfirmation;
                this.resetCheckBoxValues();
                this.fetchCurrentTestcaseDetails(this.test_case_id);
              } else {
                this.showError('Group create unsuccessful.');
              }
            })
            .catch((err) => {
            });
        } else {
          this.showError('Please select test steps first.');
        }
      }
    },
    async addToStepFromDraft(data) {
      const existingStep = this.steps.filter(st => st._id == data.existingStepId)[0]
      console.log("=======existingStep==========>HELLO", existingStep);
      if (existingStep) {
        const drafted = data.actionType === 'addToStepFromDraft' ? false : true;
        const ind = this.steps.indexOf(existingStep);
        const updatedSMSValidationStep = { ...existingStep, validationInfo: { ...data } };
        delete updatedSMSValidationStep["validationInfo"]["existingStepId"];
        updatedSMSValidationStep['is_drafted'] = drafted;
        delete updatedSMSValidationStep["validationInfo"]["actionType"];

        for (let j = 0; j < updatedSMSValidationStep.validationInfo.assertions.length; j++) {
          const assertion = updatedSMSValidationStep.validationInfo.assertions[j];
          delete assertion.result;
        }

        this.steps[ind] = updatedSMSValidationStep;

        await this.updateTestStep({ "stepId": data.existingStepId, "step": updatedSMSValidationStep })
          .then((resBody) => {
            if (resBody.status === 200) {
              this.steps = [];
              this.showSuccess(resBody.message.MSG_SHORT, drafted ? "Step drafted" : "Step Added");
              this.fetchCurrentTestcaseDetails(this.test_case_id);
              this.differentComponentStepData = null;
              this.showDifferentComponent = { show: false, componentName: '', }
            } else {
              this.showError('Test step create unsuccessful.');
            }
          })
          .catch((err) => {
            console.log("🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 ERROR", err);
          });
      }
    },
    async stepsSaveAsDraft(data) {

      const newStepObj = [...this.steps];
      let step = caseBinder(this, data);
      step.is_drafted = true;

      newStepObj.push(step)

      let test_steps_obj;

      test_steps_obj = {
        base_info: {
          test_case_id: this.test_case_id,
          project_id: getSelectedCurrentProjectId(),
        },
        organizationId: this.loggedInUserInfo.organizationId,
        test_step_list: newStepObj,
      };

      this.clickInsertTestStep(test_steps_obj)
        .then((resBody) => {
          this.isAnyStepChanged = false;
          if (resBody.status === 200) {
            this.showSuccess(resBody.message.MSG_SHORT, 'Step drafted');
            this.fetchCurrentTestcaseDetails(this.test_case_id);
            this.showDifferentComponent = { show: false, componentName: '', }
            this.differentComponentStepData = null;
            this.steps = [];
            this.resetCheckBoxValues()
          } else {
            this.showError('Test step create unsuccessfull.');
          }
        })
        .catch((err) => {
        });
    },

    async clickTestStepsSave() {
      if (this.errorMsg['baseUrl']) {
        if (!this.config.baseUrl) {
          return this.$toast.add({ severity: 'error', summary: 'Error', detail: `Base url is required`, life: 5000 });
        }
        return this.$toast.add({ severity: 'error', summary: 'Error', detail: `Please provide a valid Base Url!`, life: 5000 });
      } else if (this.baseUrlUpdateable) {
        let update = true;
        this.testcaseUpdate(update);
        // this.fetchCurrentTestcaseDetails(this.test_case_id)
        this.baseUrlUpdateable = false
        return
      }

      let stepSaveMsg = false;
      let testCaseSaveMsg = false;
      let newTeststepArr = [];

      if (this.currentSavedTestSteps && this.currentSavedTestSteps.length) {
        newTeststepArr = this.steps.filter(({ _id: id1 }) => !this.currentSavedTestSteps.some(({ _id: id2 }) => id2 === id1));
        stepSaveMsg = false;
        testCaseSaveMsg = true;
      } else {
        newTeststepArr = this.steps;
        stepSaveMsg = true;
        testCaseSaveMsg = false;
      }

      this.disableSaveBtn = true;
      let test_steps_obj;

      if (this.automationStatus === this.automationStatusEnums.RECORD_RUNNING) {
        this.closeWindow()
      }
      if (newTeststepArr && newTeststepArr.length > 0) {

        for (let i = 0; i < newTeststepArr.length; i++) {
          const stp = newTeststepArr[i];

          if (stp.action === customValidationList.CHECK_BROKEN_LINK.action && stp.broken_links.length) {
            stp.broken_links = [];
          }

          if (stp.action === customValidationList.API_VALIDATION.action) {
            for (let j = 0; j < stp.validationInfo.assertions.length; j++) {
              const assertion = stp.validationInfo.assertions[j];
              delete assertion.result;
            }
          }
          if (stp.action === customValidationList.ValidateDatabse.action) {
            for (let j = 0; j < stp.validationInfo.assertions.length; j++) {
              const assertion = stp.validationInfo.assertions[j];
              delete assertion?.status;
            }
          }
        }

        test_steps_obj = {
          base_info: {
            test_case_id: this.test_case_id,
            project_id: getSelectedCurrentProjectId(),
          },
          organizationId: this.loggedInUserInfo.organizationId,
          test_step_list: newTeststepArr,
        };

        newTeststepArr = [];
        await this.clickInsertTestStep(test_steps_obj)
          .then((resBody) => {
            this.isAnyStepChanged = false;
            if (resBody.status === 200) {
              this.showSuccess(resBody.message.MSG_SHORT, 'New Steps Saved Successfully!');
              this.steps = [];
              this.resetCheckBoxValues();
              // if (stepSaveMsg) {
              //   this.showSuccess(resBody.message.MSG_SHORT, resBody.message.MSG_LONG);
              // }
            } else {
              this.showError('Test step create unsuccessfull.');
            }
          })
          .catch((err) => {
          });

        // await this.testcaseUpdate(testCaseSaveMsg);
        await this.fetchCurrentTestcaseDetails(this.test_case_id);

        this.disableSaveBtn = false;
        stepSaveMsg = false;
        testCaseSaveMsg = false;
      } else {
        this.$toast.add({ severity: 'warn', summary: 'Warn Message', detail: `You have no new steps to save.`, life: 5000 });
        this.disableSaveBtn = false;
      }
    },

    async clickSendStepsToSelenuim() {
      // let newTeststepArr = [];

      // if (this.currentSavedTestSteps && this.currentSavedTestSteps.length) {
      //     newTeststepArr = this.steps.filter(({ _id: id1 }) => !this.currentSavedTestSteps.some(({ _id: id2 }) => id2 === id1));
      //     // stepSaveMsg = false;
      //     // testCaseSaveMsg = true;
      // } else {
      //     newTeststepArr = this.steps;
      //     // stepSaveMsg = true;
      //     // testCaseSaveMsg = false;
      // }

      let test_steps_obj = {
        base_info: {
          test_case_id: this.test_case_id,
          project_id: getSelectedCurrentProjectId(),
        },
        test_step_list: this.steps,
      };

      this.clickSendStepsToSelenuimGrid(test_steps_obj);
    },

    clickDeleteTeststep() {
      var vm = this;
      var selected = vm.selectedCheckedArr.map((s) => s._id);

      const recursive = (children) => {
        return children.map((_child) => {
          var tempChildren = _child.children && Array.isArray(_child.children) ? Array.from(_child.children).filter((tc) => !selected.includes(tc._id)) : [];
          return {
            ..._child,
            children: _child.children && Array.isArray(_child.children) ? recursive(tempChildren) : [],
          };
        });
      };

      var steps = recursive(this.steps).filter((tc) => !selected.includes(tc._id));
      this.steps = steps;

      if (this.selectedCheckedArr && this.selectedCheckedArr.length > 0) {

        this.deleteTestSteps(this.selectedCheckedArr)
          .then((resBody) => {
            this.visibleDeleteTestSteps = false;
            if (resBody.status === 200) {
              this.selectedCheckedArr = [];
              this.showSuccess(resBody.message.MSG_SHORT, resBody.message.MSG_LONG);
              this.resetCheckBoxValues();
              this.fetchCurrentTestcaseDetails(this.test_case_id);
            } else {
              this.showError('step delete Unsuccessful.');
            }
          })
          .catch((err) => {
            this.visibleDeleteTestSteps = false;
            console.error('deleteTestSteps', err);
          });
      } else {
        this.showError('Please select test step first.');
        this.visibleDeleteTestSteps = false;
      }
    },

    deleteTeststepsFromLocal(remove_id) {

      this.steps.filter((step, index) => {
        if (step._id === remove_id) {
          return this.steps.splice(index, 1);
        }
      });
    },

    submitBaseUrlChange() {
      if (this.baseUrlUpdateable) {
        if (!this.errorMsg['baseUrl']) {
          let update = true;
          this.testcaseUpdate(update);
        }
      }
    },
    baseUrlUpdateEnable() {
      if (!this.baseUrlUpdateable
        && this.automationStatus != this.automationStatusEnums.AUTOMATION_RUNNING
        && this.automationStatus != this.automationStatusEnums.RECORD_RUNNING
        && this.automationStatus != this.automationStatusEnums.AUTOMATION_PAUSED) {
        this.baseUrlUpdateable = true;
      }
    },

    handleSelectedResolution(selectedResdata, i) {
      this.config.resolution.resolutionId = selectedResdata._id;
      this.config.resolution.width = selectedResdata.screen_size.width;
      this.config.resolution.height = selectedResdata.screen_size.height;
      this.testcaseUpdate();
      this.showResolutionModalHandler();
      setTimeout(() => {
        this.fetchCurrentTestcaseDetails(this.$route.params.id);
      }, 450);
    },

    deleteCustomResolution(selectedResId) {
      const reqDataObj = { _id: selectedResId };
      if (this.config.resolution.resolutionId != selectedResId) {
        this.deleteResolution(reqDataObj)
          .then((resBody) => {
            if (resBody.status === 200) {
              this.showSuccess(resBody.message.MSG_SHORT, resBody.message.MSG_LONG);
              this.fetchResolutionListByCaseId(this.$route.params.id);
              this.showResolutionModalHandler();
            } else {
              this.showError('Resolution delete Unsuccessful.');
            }
          })
          .catch((err) => {
          });
      } else {
        this.showError("You Can't Delete Selected Resolution.");
      }
    },

    async onPropertySaveChanges(changeProperties, sharesteps) {
      const inputRequiredValidations = [
        // customValidationList.SMS_Validation.action,
        customValidationList.GENERATE_PHONE_NUMBER.action,
        customValidationList.OTP_VALIDATION.action,
        customValidationList.ValidateCssProperty.action,
        customValidationList.ValidateHtmlAttribute.action,
        customValidationList.URL_Validation.action,
        customValidationList.Navigate.action,
        customValidationList.SCROLL.action,
        customValidationList.Sleep.action,
        customValidationList.TextToAssign.action,
        // customValidationList.AddNewGroup.action,
        'input'
      ];
      const stepsWhichShouldNotBeAddedToTheListAfterPropertiesAreAdded = [customValidationList.Navigate.action, customValidationList.Sleep.action, customValidationList.URL_Validation.action, customValidationList.ValidateHtmlAttribute.action, customValidationList.ValidateCssProperty.action];

      //Re enter properties value to Generate phone number
      if (changeProperties.action === customValidationList.GENERATE_PHONE_NUMBER.action && changeProperties.value.length > 0) {
        //logs
        // Send to background with chrome runtime as SET PHONE NUMBER from ctrlKeys
        // Sending value selector and value for setup value of input
        if (this.tabId) {
          getPort.postMessage({
            type: ctrl.SET_PHONE_NUMBER,
            baseUrl: '',
            config: this.config,
            tabId: this.tabId,
            totalStep: 0,
            value: changeProperties.value,
            selector: changeProperties.selector,
          });
        }
      }

      if (changeProperties && Object.keys(changeProperties).length !== 0 && !changeProperties.createdAt) {
        Object.assign(this.currentChangePropertiesObj, { list_index: this.steps.length + 1 });
        let test_steps_obj = {
          base_info: {
            test_case_id: this.test_case_id,
            project_id: getSelectedCurrentProjectId(),
          },
          organizationId: this.loggedInUserInfo.organizationId,
          test_step_list: [changeProperties],
        };

        if (inputRequiredValidations.includes(changeProperties.action)) {
          const stExists = this.steps.find(st => st._id == changeProperties._id);
          if (!stExists && stepsWhichShouldNotBeAddedToTheListAfterPropertiesAreAdded.includes(changeProperties.action)) {
            this.steps.push(this.currentProperties);
            this.currentProperties = {};
          } else {
            this.isAnyStepChanged = true;
          }
        } else {
          await this.clickInsertTestStep(test_steps_obj)
            .then((resBody) => {
              if (resBody.status === 200) {
                this.steps = [];
                this.showSuccess(resBody.message.MSG_SHORT, resBody.message.MSG_LONG);
                this.fetchCurrentTestcaseDetails(this.test_case_id);
              } else {
                this.showError('Test step create unsuccessful.');
              }
            })
            .catch((err) => {
            });
        }

        this.currentChangePropertiesObj = {};
      } else {
        let reqObject;
        if (this.currentChangePropertiesObj && Object.keys(this.currentChangePropertiesObj).length) {
          delete this.currentChangePropertiesObj.dom;
          delete this.currentChangePropertiesObj.xpath;
          delete this.currentChangePropertiesObj.xpath_collections;
          delete this.currentChangePropertiesObj.error_obj;

          reqObject = {
            id: changeProperties._id,
            propertiesObj: this.currentChangePropertiesObj,
          };
        } else {
          reqObject = {
            id: changeProperties._id,
            propertiesObj: changeProperties,
          };
        }

        // let reqObject = {
        //     id: changeProperties._id,
        //     propertiesObj: this.currentChangePropertiesObj,
        // };


        await this.insertPropertieChanges(reqObject)
          .then((resBody) => {
            if (resBody.status === 200) {
              if (sharesteps) {
                var vm = this;
                this.shareableGroupSave(changeProperties, sharesteps).then((data) => {
                  vm.fetchCurrentTestcaseDetails(vm.test_case_id);
                  vm.showSuccess(resBody.message.MSG_SHORT, resBody.message.MSG_LONG);
                  vm.currentProperties = {};
                });
              }
            } else {
              this.showError(resBody && resBody.data && resBody.data.errorMsg);
            }
          })
          .catch((err) => {
          });

        this.currentChangePropertiesObj = '';
      }

      this.visibleProperties = false;
      this.isAnyStepChanged = false;
    },

    onPropertyCancel() {
      this.visibleProperties = false;
      this.currentProperties = {};
    },
    onSetting(properties) {
      this.visibleProperties = true;
      this.currentProperties = properties;

    },
    handleChange(type, event) {
      this.config.baseUrl = event.target.value;
    },
    totalLength(len) {
      return len - 1;
    },
    useCaseMouseOver(e, i) {
      this.current = i;
    },
    useCaseMouseOut(e, i) {
      this.current = null;
    },
    onStopRecord(e) {
      // prevent Default actions
      e.preventDefault();

      try {
        if (getPort && getPort.disconnect) {
          getPort.disconnect();
        }
        getPort = getBrowser().runtime.connect(this.$extensionId, { name: 'testjet-extension-port' });
        // Throw custom exception
        if (!getPort) {
          this.noExtention = true;
          this.automationStatus === this.automationStatusEnums.AUTOMATION_PENDING;
          throw new ExtensionRuntimeConnectException('Failed to connect with runtime');
        }
        this.attachPortListeners();
        this.noExtention = false;
        getPort.postMessage({
          from: 'local',
          type: ctrl.WINDOWCLOSED,
          closeType: ctrl.STOP_RECORD,
          baseUrl: this.config.baseUrl,
          config: this.config,
          tabId: this.tabId,
          winId: this.winId,
        });
        if (this.keepAliveInterval) {
          clearInterval(this.keepAliveInterval)
        }
      } catch (e) {
      }
    },

    processComparisonDialog(singleTestStep) {
      let testStep = { ...singleTestStep };
      testStep.automation_screenshot_src = testStep?.automation_screenshot_src ? testStep.automation_screenshot_src : process.env.VUE_APP_NOT_EXECUTED_STEP_IMAGE;
      this.onShowComparison(testStep);
    },

    async onStartRecord(e) {
      // prevent Default actions
      e.preventDefault();
      //! check for if the screen resolution is less than or equal to the test step's defined resolution !

      let testCaseScreenResolution = this.currentTestcase.screen_resolution_id.screen_size.width;
      let windowScreenResolution = window.screen.availWidth;

      if (testCaseScreenResolution < windowScreenResolution || testCaseScreenResolution === windowScreenResolution) {
        this.isRecordNeeded = false;
        var stepList = [];
        var { baseUrl } = this.config;

        // fetch total steps recursive
        childrenTree(this.steps, stepList);

        try {
          // if (!getPort) {
          //   this.noExtention = true;
          //   this.automationStatus === this.automationStatusEnums.AUTOMATION_PENDING;
          //   throw new ExtensionRuntimeConnectException('Failed to connect with runtime');
          // }
          if (getPort && getPort.disconnect) {
            getPort.disconnect();
          }
          getPort = await getBrowser().runtime.connect(this.$extensionId, { name: 'testjet-extension-port' });
          // Throw custom exception
          if (!getPort) {
            this.noExtention = true;
            this.automationStatus === this.automationStatusEnums.AUTOMATION_PENDING;
            throw new ExtensionRuntimeConnectException('Failed to connect with runtime');
          }
          this.attachPortListeners();
          this.noExtention = false;
          this.automationStatus = this.automationStatusEnums.RECORD_RUNNING;
          getPort.postMessage({
            type: ctrl.START_RECORD,
            baseUrl,
            config: this.config,
            tabId: this.tabId,
            totalStep: stepList.length,
          });
          this.keepAliveInterval = setInterval(() => {
            try {
              sendKeepAliveMessage(getPort)
            } catch (e) {
            }
          }, 10000);
        } catch (e) {
          this.noExtention = true;
          this.automationStatus = this.automationStatusEnums.AUTOMATION_PENDING;
        }
      } else {
        this.automationStatus = this.automationStatusEnums.AUTOMATION_PENDING;
        return this.$toast.add({
          severity: 'error',
          summary: `The screen resolution for the test case must be equal to or less than your monitor screen resolution.`,
          life: 5000,
        });
      }
    },
    onActionList(action) {
      switch (action.name) {
        case actionsList.Play_Scenario.name:
          this.onStartAutomation();
          break;
        case actionsList.Delete_Step.name:
          this.deleteSteps();
          break;
      }
    },
    deleteSteps() {
      if (this.selectedSteps.length > 0) {
        this.selectedSteps.forEach((step) => {
          this.steps.splice(this.steps.indexOf(step), 1);
        });
        this.selectedSteps = [];
      } else {
        this.steps = [];
        localStorage.removeItem('steps');
        localStorage.removeItem('config');
        this.isTestCaseCreated = false;
      }
    },
    async onStartAutomation() {
      var i = 0;
      while (i < this.steps.length) {
        this.steps[i].error_obj = {type : ""};
        this.steps[i].automation_screenshot_src = null;
        i++;
      }
      this.executionTime.start = Date.now()
      this.executionTime.end = 0;

      this.automationStatus = this.automationStatusEnums.AUTOMATION_RUNNING;
      this.isRecordNeeded = false;
      try {
        this.noExtention = false;
        var { baseUrl } = this.config;
        this.isAutomationSatrted = true;
        this.stepList = [];
        if (getPort && getPort.disconnect) {
          getPort.disconnect();
        }
        getPort = await getBrowser().runtime.connect(this.$extensionId, { name: 'testjet-extension-port' });
        // Throw custom exception
        if (!getPort) {
          this.noExtention = true;
          this.automationStatus === this.automationStatusEnums.AUTOMATION_PENDING;
          throw new ExtensionRuntimeConnectException('Failed to connect with runtime');
        }
        this.attachPortListeners();
        getPort.postMessage({
          type: ctrl.START_AUTOMATION,
          baseUrl,
          steps: this.steps,
          config: this.config,
          tabId: this.tabId,
          winId: this.winId,
        });
        this.keepAliveInterval = setInterval(() => {
          try {
            sendKeepAliveMessage(getPort)
          } catch (e) {
          }
        }, 10000);
      } catch (e) {
        // this.$toast.add({
        //     severity: 'error',
        //     summary: 'Please install extension',
        //     life: 3000,
        // });
        this.noExtention = true;
        this.automationStatus = this.automationStatusEnums.AUTOMATION_PENDING;
      }
    },
    onPausedAutomation() {
      try {
        getPort.postMessage({
          type: ctrl.PAUSE_AUTOMATION,
          baseUrl: this.config.baseUrl,
          steps: this.steps,
          config: this.config,
          tabId: this.tabId,
          winId: this.winId,
        });
        this.$toast.add({
          severity: 'success',
          summary: 'Your test case play has been paused.',
          life: 3000,
        });
        this.automationStatus = this.automationStatusEnums.AUTOMATION_PAUSED;
      } catch (e) {
      }
    },
    onRestartAutomation() {
      try {
        if (getPort && getPort.disconnect) {
          getPort.disconnect();
        }
        getPort = getBrowser().runtime.connect(this.$extensionId, { name: 'testjet-extension-port' });
        // Throw custom exception
        if (!getPort) {
          this.noExtention = true;
          this.automationStatus === this.automationStatusEnums.AUTOMATION_PENDING;
          throw new ExtensionRuntimeConnectException('Failed to connect with runtime');
        }
        this.attachPortListeners();
        getPort.postMessage({
          type: ctrl.RESTART_AUTOMATION,
          baseUrl: this.config.baseUrl,
          steps: this.steps,
          config: this.config,
          tabId: this.tabId,
          winId: this.winId,
        });
        this.automationStatus = this.automationStatusEnums.AUTOMATION_RUNNING;
      } catch (e) {
      }
    },
    closeWindow() {
      try {
        getPort.postMessage({
          type: ctrl.WINDOWCLOSED,
          closeType: ctrl.NORMAL,
          from: 'local',
          baseUrl: this.config.baseUrl,
          steps: this.steps,
          config: this.config,
          tabId: this.tabId,
          winId: this.winId,
        });
      } catch (e) {
      }
    },

    onSearchChange(value) {
      this.validationListSearch = value;
    },
    useVariable(selectedVariable) {
      if (!this.tabId) {
        this.isRecordNeeded = true;
        return
      }
      const message = {
        from: 'local',
        baseUrl: this.config.baseUrl,
        validation: customValidationList.InsertVariable,
        winId: this.winId,
        config: this.config,
        tabId: this.tabId,
      }

      message.type = ctrl.CUSTOM_VALIDATION_START;
      message.data = { action: customValidationList.InsertVariable.action, type: ctrl.ADD_PREDEFINED_STEP, variable_id: selectedVariable._id, text: selectedVariable.value, totalStep: this.steps.length, coordinates: {} };

      try {
        getPort.postMessage(message);
      } catch (e) {

      }
      this.openInsertVariableModal = false;
    },
    onCustomValidation(validation) {
      if (!this.tabId) {
        this.isRecordNeeded = true;
        return
      }
      var vm = this;
      var { name } = validation;

      const message = {
        from: 'local',
        baseUrl: this.config.baseUrl,
        validation,
        winId: this.winId,
        config: this.config,
        tabId: this.tabId,
      }

      switch (name) {
        // case customValidationList.AddNewGroup.name:
        //   if (this.selectedCheckedArr.length && this.selectedCheckedArr.length > 1) {
        //     this.selectedCheckedArr.sort((a, b) => {
        //       let ia = a.arrayIndex;
        //       let ib = b.arrayIndex;
        //       return ib - ia;
        //     });
        //     this.selectedCheckedArr.reverse();
        //     let lastListIndex = this.selectedCheckedArr[0].arrayIndex;
        //     for (let i = 1; i <= this.selectedCheckedArr.length; i++) {
        //       if (this.selectedCheckedArr[i].arrayIndex == lastListIndex + 1) {
        //         lastListIndex++;
        //         if (this.selectedCheckedArr.length - 1 == i) {
        //           this.modalOpenConfirmation();
        //         }
        //       } else if (this.selectedCheckedArr.length - 1 == i) {
        //         this.$toast.add({
        //           severity: 'warn',
        //           summary: 'Please select sequential steps to create a group',
        //           life: 3000,
        //         });
        //       }
        //     }
        //   } else {
        //     this.$toast.add({
        //       severity: 'warn',
        //       summary: 'Please select sequential steps to create a group',
        //       life: 3000,
        //     });
        //   }
        //   break;

        case customValidationList.InsertVariable.name:
          this.selectedVariable = null;
          this.openInsertVariableModal = true;
          break;
        case customValidationList.Refresh.name:
          message.type = ctrl.ADD_PREDEFINED_STEP;
          message.data = { action: customValidationList.Refresh.action, type: ctrl.ADD_PREDEFINED_STEP, totalStep: this.steps.length, coordinates: {} };
          break;
        case customValidationList.Sleep.name:
          message.type = ctrl.ADD_PREDEFINED_STEP;
          message.data = { action: customValidationList.Sleep.action, type: ctrl.ADD_PREDEFINED_STEP, totalStep: this.steps.length, coordinates: {} };
          break;
        case customValidationList.CHECK_BROKEN_LINK.name:
          message.type = ctrl.ADD_PREDEFINED_STEP;
          message.data = { action: customValidationList.CHECK_BROKEN_LINK.action, type: ctrl.ADD_PREDEFINED_STEP, totalStep: this.steps.length, coordinates: {} };
          break;
        case customValidationList.ValidateDatabse.name:
          vm.showDifferentComponent = { show: true, componentName: 'databaseValidation', data: validation }
          break;
        case customValidationList.API_VALIDATION.name:
          vm.differentComponentStepData = null;
          vm.showDifferentComponent = { show: true, componentName: 'apiValidation', data: validation }
          break;
        case customValidationList.ValidateEmail.name:
          vm.showDifferentComponent = { show: true, componentName: 'emailValidation', data: validation }
          break;
        case customValidationList.ValidateSMS.name:
          vm.showDifferentComponent = { show: true, componentName: 'smsValidation', data: validation }
          break;
        case customValidationList.Navigate.name:
          console.log("NAVIGATE ++++++ ");
          message.type = ctrl.ADD_PREDEFINED_STEP;
          message.data = { action: customValidationList.Navigate.action, type: ctrl.ADD_PREDEFINED_STEP, totalStep: this.steps.length, coordinates: {} };
          break;
        case customValidationList.URL_Validation.name:
          message.type = ctrl.ADD_PREDEFINED_STEP;
          message.data = { action: customValidationList.URL_Validation.action, type: ctrl.ADD_PREDEFINED_STEP, totalStep: this.steps.length, coordinates: { section: '#document' } };
          break;
        case customValidationList.SCROLL.name:
          message.type = ctrl.ADD_PREDEFINED_STEP;
          message.data = { action: customValidationList.SCROLL.action, type: ctrl.ADD_PREDEFINED_STEP, totalStep: this.steps.length, coordinates: {} };
          break;
        default:
          message.type = ctrl.CUSTOM_VALIDATION_START;
          message.validation = validation;
          break;
      }
      if (validation.name != customValidationList.InsertVariable.name) {
        try {
          getPort.postMessage(message);
        } catch (e) {

        }
      }
    },
    onEditConfig() {
      this.isTestCaseCreated = false;
    },
    deleteStepItem(step) {
      this.steps.splice(this.steps.indexOf(step), 1);
    },
    deleteAllSteps() {
      this.selectedSteps.forEach((step) => {
      });
    },
    showCurrentProperties(step) {
      this.showProperties = true;
      this.currentProperties = step;
    },

    _incrementIndex(value) {
      return value + 2;
    },

    // Start for dialog confirmation
    modalOpenConfirmation() {
      this.displayConfirmation = true;
    },
    closeConfirmation() {
      this.displayConfirmation = false;
    },

    checkMove: function (evt) {
      return evt.draggedContext.element.name !== 'apple';
    },

    onMoveListener(evt, currentChooseData, mainData, type, parentData) {
      this.currentMoveData = currentChooseData;
    },

    onEndListener(evt, type) {
      let flatStepArray = [];
      Promise.all(
        this.steps.map(async (item, index) => {
          delete item.screeshot_src;
          item.path = item._id;
          item.list_index = index;
          !item.test_case_id && (item.test_case_id = this.test_case_id);
          !item.project_id && (item.project_id = this.currentTestcase.project_id);
          flatStepArray.push(item);

          if (item.type == 'group') {
            await childrenTreeCreate(item);
          }

          return this.steps;
        })
      ).then((res) => {
        if (res) {
          this.insertStepOnEachMove(flatStepArray)
            .then((resBody) => {
              if (resBody.status === 200) {
                this.fetchCurrentTestcaseDetails(this.test_case_id);
                // this.showSuccess(resBody.message.MSG_SHORT, resBody.message.MSG_LONG);
              } else {
                this.showError(resBody && resBody.data && resBody.data.errorMsg);
              }
            })
            .catch((err) => {
            });
        }
      });

      async function childrenTreeCreate(childrenItem) {
        // if (childrenItem && childrenItem.children && childrenItem.children.length > 0) {
        (await childrenItem) &&
          childrenItem.children &&
          childrenItem.children.map(async (cItem, cIndex) => {
            delete cItem.screeshot_src;
            cItem.parent = childrenItem._id;
            cItem.path = childrenItem.path + '#' + cItem._id;
            cItem.list_index = cIndex;
            flatStepArray.push(cItem);

            if (cItem.type == 'group') {
              return await childrenTreeCreate(cItem);
            }
          });
        // } else {
        return '';
        // }
      }
    },

    removeCheckedValue(checkValue) {
      /**
       *  Traverse
       */
      function removeFromArrayOfObj(array, idToRemove) {
        for (const [i, e] of array.entries()) {
          if (e._id === idToRemove) {
            array.splice(i, 1);
            continue;
          }
          if (e.children) {
            removeFromArrayOfObj(e.children, idToRemove);
          }
        }
        return array;
      }
      var temp = JSON.parse(JSON.stringify(this.selectedCheckedArr));
      var childrenTree = (st) => {
        var i = 0;
        while (i < st.length) {
          const current = st[i];
          const j = this.allCheckList.indexOf(current._id);
          if (j > -1) {
            this.allCheckList.splice(this.allCheckList.indexOf(current._id), 1);



            if (current._id === st[i]._id) {
              removeFromArrayOfObj(temp, current._id);
              this.selectedCheckedArr = temp;
            }

            var traverseStepList = (stl) => {
              var i = 0;
              while (i < stl.length) {
                if (current._id == stl[i]._id) {
                  stl[i].is_checked = false;
                }
                if (stl[i].children && stl[i].children.length > 0) {
                  traverseStepList(stl[i].children);
                }
                i++;
              }
            };
            traverseStepList(this.steps);
          }

          if (st[i].children && st[i].children.length > 0) {
            childrenTree(st[i].children);
          }
          i++;
        }
      };

      childrenTree([checkValue]);
    },
    getCheckeBoxValue(checkValue) {
      var temp = JSON.parse(JSON.stringify(this.selectedCheckedArr));
      this.selectedCheckedArr.push(checkValue);

      var childrenTree = (st) => {
        var i = 0;
        while (i < st.length) {
          const current = st[i];
          if (!this.allCheckList.includes(current._id)) {
            this.allCheckList.push(current._id);
          }

          var traverseStepList = (stl) => {
            var i = 0;
            while (i < stl.length) {
              if (current._id === stl[i]._id) {
                stl[i].is_checked = true;
              }
              if (stl[i].children && stl[i].children.length > 0) {
                traverseStepList(stl[i].children);
              }
              i++;
            }
          };

          traverseStepList(this.steps);

          if (st[i].children && st[i].children.length > 0) {
            childrenTree(st[i].children);
          }
          i++;
        }
      };
      childrenTree([checkValue]);
    },

    addNewGroup() {
      this.closeConfirmation();

      if (!this.selectedCheckedArr.length > 0) {
        this.createNewEmptyGroup();
      } else {
        this.createNewGroup();
      }
    },

    createNewEmptyGroup() {
      let newEmptyGroupKey = 0;

      if (this.steps && this.steps.length > 0) {

        if (this.emptyGroupList && this.emptyGroupList.length > 0) {
          newEmptyGroupKey = this.emptyGroupList.length + 1;
        } else {
          newEmptyGroupKey;
        }

        let emptyGroupObj = {
          description: this.groupName,
          // key: 'g' + newEmptyGroupKey + '-' + Math.floor(Math.random() * 1000 + 1),
          rootId: 'g' + newEmptyGroupKey + '-' + Math.floor(Math.random() * 1000 + 1),
          type: 'group',
          children: [],
        };
        this.emptyGroupList.push(emptyGroupObj);
        this.steps.unshift(emptyGroupObj);

        this.groupName = '';

      } else {
      }
    },

    createNewGroup() {
      let newGroup = [];
      let getCheckedFirstIndex;

      // let checkedKeysArray = [];
      let checkedMinIndex;
      let minIndexKeyValue;

      this.selectedCheckedArr.forEach((element) => {
        this.checkedKeysArray.push(element._id);
      });


      this.getCheckedRowIndex();

      checkedMinIndex = Math.min(...this.checkedIndexArray);

      this.checkedIndexWithDataArray.forEach((element) => {
        if (checkedMinIndex == element.index) {
          minIndexKeyValue = element.item._id;
        }
      });


      let newGroupObject = {
        description: this.groupName,
        // key: 'g' + checkedMinIndex + '-' + this.selectedCheckedArr[0].key + '-' + Math.floor(Math.random() * 1000 + 1),
        rootId: 'g' + checkedMinIndex + '-' + this.selectedCheckedArr[0]._id + '-' + Math.floor(Math.random() * 1000 + 1),
        type: 'group',
        children: this.selectedCheckedArr,
      };

      this.spliceCheckedData(minIndexKeyValue, newGroupObject);

      this.selectedCheckedArr = [];
      this.checkedKeysArray = [];
      this.checkedIndexArray = [];
      this.checkedIndexWithDataArray = [];
      this.groupName = '';
    },

    getCheckedRowIndex() {
      if (this.steps && this.steps.length > 0) {
        for (let index = 0; index < this.steps.length; index++) {

          if (this.checkedKeysArray.includes(this.steps[index]._id)) {
            this.checkedIndexArray.push(index);
            this.checkedIndexWithDataArray.push({
              index: index,
              item: this.steps[index],
            });
          }

          if (this.steps.length > 0 && this.steps[index].children && this.steps[index].children.length) {
            this.getNestedCheckedRowIndex(this.steps[index].children);
          }
        }
      }
    },

    getNestedCheckedRowIndex(currentArray) {
      for (let index = 0; index < currentArray.length; index++) {

        if (this.checkedKeysArray.includes(currentArray[index]._id)) {
          this.checkedIndexArray.push(index);
          this.checkedIndexWithDataArray.push({
            index: index,
            item: currentArray[index],
          });
        }

        if (currentArray.length > 0 && currentArray[index].children && currentArray[index].children.length) {
          this.getNestedCheckedRowIndex(currentArray[index].children);
        }
      }
    },

    spliceCheckedData(minIndexKeyValue, newGroupObject) {
      if (this.steps && this.steps.length > 0) {
        for (let index = 0; index < this.steps.length; index++) {

          let nestedIgnore = false;

          if (this.checkedKeysArray.includes(this.steps[index]._id)) {
            if (this.steps[index]._id === minIndexKeyValue) {
              this.steps.splice(index, 1, newGroupObject);
              nestedIgnore = true;
            } else {
              this.removeNotMinKeysValue(index);
            }
          }

          if (!nestedIgnore && this.steps[index] !== undefined) {
            if (this.steps.length > 0 && this.steps[index].type === 'group') {
              this.spliceNestedCheckedData(this.steps[index].children, minIndexKeyValue, newGroupObject);
            }
          }
        }
      }
    },

    removeNotMinKeysValue(index) {
      if (this.checkedKeysArray.includes(this.steps[index]._id)) {

        this.steps.splice(index, 1);

        if (this.steps[index] !== undefined) {
          this.removeNotMinKeysValue(index);
        }
      }
    },

    spliceNestedCheckedData(currentArray, minIndexKeyValue, newGroupObject) {
      for (let index = 0; index < currentArray.length; index++) {

        let nestedIgnore = false;
        if (this.checkedKeysArray.includes(currentArray[index]._id)) {

          if (currentArray[index]._id === minIndexKeyValue) {
            currentArray.splice(index, 1, newGroupObject);
            nestedIgnore = true;
          } else {
            this.removeNestedNotMinKeysValue(index, currentArray);
          }
        }

        if (!nestedIgnore && currentArray[index] !== undefined) {
          if (currentArray.length > 0 && currentArray[index].type === 'group') {
            this.spliceNestedCheckedData(currentArray[index].children, minIndexKeyValue, newGroupObject);
          }
        }
      }
    },

    removeNestedNotMinKeysValue(index, currentArray) {
      if (this.checkedKeysArray.includes(currentArray[index]._id)) {

        currentArray.splice(index, 1);
        if (currentArray[index] !== undefined) {
          this.removeNestedNotMinKeysValue(index, currentArray);
        }
      }
    },
    onBreakPoint(step) {
      if (this.breakPoint._id === step._id) {
        this.breakPoint = {};
      } else {
        this.breakPoint = step;
      }
    },

    goBackToTestcaseHandler() {
      // let testcaseReqObj = {
      //     projectID: getSelectedCurrentProjectId(),
      //     duration: this.testCaseFilterDuration,
      //     issueTypeID: '',
      //     lastActivity: '',
      // };
      // this.fetchTestCases(testcaseReqObj);
      // this.$router.push({path: `/test-cases`});
      this.$router.back();
    },
    goBackToTestEditorHandler() {
      this.differentComponentStepData = null;
      this.showDifferentComponent = { show: false, componentName: '' };
      this.differentComponentData = { componentName: '', data: null }
    },

    onPropertiesHide() {
      this.visibleProperties = false;
      this.currentProperties = {};
    },
    baseURLPrefix(baseUrl) {
      const height = 40;
      const strlen = window.innerHeight / height;
      const dotprefix = baseUrl.length > strlen ? '....' : '';

      return baseUrl && String(baseUrl).substr(0, strlen) + dotprefix;
    },
    titlePrefix(title) {
      const strlen = 15;
      const dotprefix = title.length > strlen ? '....' : '';

      return title && String(title).substr(0, strlen) + dotprefix;
    },
    tagPrefix(tag) {
      const strlen = 20;
      const dotprefix = tag.length > strlen ? '....' : '';
      return tag && String(tag).substr(0, strlen) + dotprefix;
    },

    showResolutionModalHandler(event) {
      if (
        this.automationStatus != this.automationStatusEnums.RECORD_RUNNING &&
        this.automationStatus != this.automationStatusEnums.AUTOMATION_RUNNING &&
        this.automationStatus != this.automationStatusEnums.AUTOMATION_PAUSED
      ) {
        this.baseUrlUpdateable = false;
        this.$refs.resolutionUpdateModal.toggle(event);
      }
    },
    bugtypeModalHandler(event) {
      this.$refs.bugTypeModal.toggle(event);
    },
  },

  // watch: {
  //     cusResolutionWidth() {
  //         this.resetRequiredField('cusResolutionWidth');
  //     },
  //     cusResolutionHeight() {
  //         this.resetRequiredField('cusResolutionHeight');
  //     },

  //     steps: {
  //         handler(val) {
  //         },
  //         deep: true,
  //         immediate: true,
  //     },
  // },

  watch: {
    config(value) {
    },
    steps: {
      handler(currentSteps, previousSteps) {
        if (currentSteps.length) {
          this.selectAllStepsDisabled = false;
        }
        else {
          this.selectAllSteps = false;
          this.selectAllStepsDisabled = true;
        }
      },
      deep: true,
    },
    selectedCheckedArr: {
      handler(selected, notSelected) {
        if (this.steps.length == selected.length) {
          this.selectAllSteps = true;
        }
        else {
          this.selectAllSteps = false;
        }
      },
      deep: true,
    },
  },

  computed: {
    ...mapState({
      username: (state) => state.user.name,
    }),

    ...mapGetters({
      currentTestcase: 'testCase/currentTestcase',
      currentSavedTestSteps: 'testCase/currentSavedSteps',
      testStepList: 'testCase/testStepList',
      caseResolutionList: 'TjAdminFeatures/caseResolutionList',
      projectID: 'project/projectID',
      loggedInUserInfo: 'auth/loggedInUserInfo',
      testCaseFilterDuration: 'testCase/filterDuration',
      caseVariables: 'variable/caseVariables',
    }),

    // Check all steps is successfully executed
    errorStatus() {
      // eslint-disable-next-line no-prototype-builtins
      var isHasField = this.stepList.every((step) => step.error_obj.hasOwnProperty('status'));
      if (isHasField) {
        if (this.stepList.length && this.executionTime.end > 0) {
          var isDone = this.stepList.every((step) => step.error_obj && step.error_obj.status === false);
          return isDone ? 'success' : 'failed';
        } else {
          return '';
        }
      } else {
        return '';
      }
    },

    filteredCustomValidationList() {
      return Object.values(this.customValidationList).filter((v) => String(v.name).toLowerCase().includes(String(this.validationListSearch).toLowerCase()));
    },
    //Total Step Length
    totalStepLength() {
      var temp = [];
      childrenTree(this.steps, temp);
      return temp.length;
    },
  },
};
</script>
<style scoped lang="scss">
.open-record-message {
  padding: 10px 37px;
  background: #00a0ee;
  color: #fff;

  a {
    text-decoration: underline;
    cursor: pointer;
    color: #000;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.selected {
  background-color: #f5fafc;
}

.p-dialog {}
</style>
