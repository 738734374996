<template>
  <div :class="containerClass" @click="onClick($event)" :style="style">
    <div class="p-hidden-accessible">
      <input ref="input" type="checkbox" :checked="checked" :value="value" v-bind="$attrs" @focus="onFocus"
             @blur="onBlur"/>
    </div>
    <div ref="box"
         :class="['p-checkbox-box', { 'p-highlight': checked, 'p-disabled': $attrs.disabled, 'p-focus': focused }]"
         role="checkbox" :aria-checked="checked">
      <span :class="['p-checkbox-icon', { 'pi pi-check': checked }]"></span>
    </div>
  </div>
</template>

<script>

export default {
  inheritAttrs: false,
  emits: ['click', 'update:modelValue', 'change'],
  props: {
    value: null,
    modelValue: null,
    binary: Boolean,
    class: null,
    style: null,
    isChecked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      focused: false,
    };
  },
  methods: {
    onClick(event) {
      if (!this.$attrs.disabled) {


        this.$emit('click', event, this.isChecked);

        this.$emit('change', event);
        this.$refs.input.focus();
      }
    },
    onFocus() {
      this.focused = true;
    },
    onBlur() {
      this.focused = false;
    },
  },
  computed: {
    checked() {
      return this.isChecked;
    },
    containerClass() {
      return ['p-checkbox p-component', this.class, {
        'p-checkbox-checked': this.checked,
        'p-checkbox-disabled': this.$attrs.disabled,
        'p-checkbox-focused': this.focused
      }];
    },
  },
};
</script>