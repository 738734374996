import AuthServiceRequest from '../utils/request/auth_request';

export const fetchCountryList = async () => {
    return await AuthServiceRequest.get('/countries/list');
};

export const fetchCityList = async countryId => {
    console.log("service", countryId)
    return await AuthServiceRequest.get('/cities/list/by-country/' + countryId);
};

export const fetchSuperAdminInfo = async () => {
    console.log("service userinfo");
    return await AuthServiceRequest.get('/member/super-admin-info');
};

export const userProfileSetup = async userProfileInfo => {
    console.log('from user profile update api===>');
    return AuthServiceRequest.put('/auth/profile/update', userProfileInfo)
    // .then(res => {
    //     return res;
    // })
    // .catch (error => {
    //     return error.response;
    // })
}


