<template>
    <div class="db-assertion-main">
        <div class="body">
            <span v-for="(assertion, i) in assertions" :key="i">
                <div class="stat_section">
                    <span class="status passed" v-if="response && response[i]?.status === 'success'">Passed</span>
                    <span class="status failed" v-if="response && response[i]?.status === 'failed'">Failed</span>
                </div>
                <!-- <pre>{{ assertion }}</pre> -->
                <Checkbox v-model="assertion['enabled']" :disabled="!assertion['type']" :binary="true" />
                <Dropdown v-model="assertion['type']" :options="assertionTypes" optionLabel="name" optionValue="code" placeholder="NONE" class="" @change="assertionTypeChange(assertion)" />
                <Dropdown v-if="assertion['type']" v-model="assertion['arithmeticRule']" :options="getarithmeticOptionsBasedOnAssertionType(assertion)" optionLabel="name" optionValue="code" placeholder="NONE" class="" />

                <InputNumber v-if="assertion['type'] === 'statusCode' && assertion['arithmeticRule'] === 'equals'" v-model="assertion['value']" placeholder="value ( eg: 200 )" />
                <InputNumber v-if="assertion['type'] === 'statusCode' && assertion['arithmeticRule'] === 'doesNotEqual'" v-model="assertion['value']" placeholder="value ( eg: 200 )" />
                <InputNumber v-if="assertion['type'] === 'statusCode' && assertion['arithmeticRule'] === 'range'" v-model="assertion['from']" placeholder="From ( eg: 200 )" />
                <InputNumber v-if="assertion['type'] === 'statusCode' && assertion['arithmeticRule'] === 'range'" v-model="assertion['to']" placeholder="to ( eg: 500 )" />

                <InputText v-if="assertion['type'] === 'header'" v-model="assertion['key']" placeholder="key" />
                <InputText v-if="assertion['type'] === 'header'" v-model="assertion['value']" placeholder="value" />

                <InputText v-if="assertion['type'] === 'jsonBody'" v-model="assertion['key']" placeholder="name ( eg: content-type )" />
                <InputText v-if="assertion['type'] === 'jsonBody'" v-model="assertion['value']" placeholder="value ( eg: application/json )" />
                <!-- <pre>{{ assertion['arithmeticOption'] }}</pre> -->
            </span>
        </div>
    </div>
</template>

<script>
import { compact } from 'lodash';
export default {
    name: 'assetion',
    data: () => ({
        status: null,
        assertions: [],
        assertionTypes: [{ name: 'Query Result', code: 'header' }],
        queryArithmaticOptions: [
            { name: 'Equals', code: 'equals' },
            { name: 'Does not equal', code: 'doesNotEqual' },
            { name: 'Contains', code: 'contains' },
        ],
        statusCodeArithmaticOptions: [
            { name: 'Equals', code: 'equals' },
            { name: 'Does not equal', code: 'doesNotEqual' },
            // { name: 'Range', code: 'range' },
        ],
        jsonArithmaticOptions: [
            { name: 'Equals', code: 'equals' },
            { name: 'Does not equal', code: 'doesNotEqual' },
        ],
    }),
    mounted() {
        if (this.allAssertions.length) {
            this.assertions = [...this.allAssertions];
        } else {
            this.assertions.push({
                index: 0,
                enabled: false,
                type: null,
                arithmeticRule: 'equals',
                key: null,
                value: 200,
                from: null,
                to: null,
            });
        }
    },
    props: {
        // readonly: {
        //   type: Boolean,
        //   default: false
        // },
        allAssertions: {
            type: Array,
            default: [],
        },
        response: {
            type: Object || null,
            default: null,
        },
    },
    watch: {
        assertions: {
            handler(value) {
                this.$emit('assertionUpdated', value);
            },
            deep: true,
        },
    },
    methods: {
        assertionTypeChange(assertion) {
            console.log(assertion);
            const indexOfchangedAssertion = this.assertions.indexOf(assertion);

            assertion.enabled = true;
            assertion.arithmeticRule = 'equals';

            if (assertion.type === 'statusCode') {
                assertion.key = null;
                assertion.value = 200;
            }

            if (assertion.type === 'header') {
                assertion.key = null;
                assertion.value = null;
            }

            if (assertion.type === 'jsonBody') {
                assertion.key = null;
                assertion.value = null;
            }

            if (indexOfchangedAssertion === this.assertions.length - 1) {
                this.assertions.push({
                    index: this.assertions.length - 1,
                    enabled: false,
                    arithmeticRule: 'equals',
                    type: null,
                    key: null,
                    value: 200,
                    from: null,
                    to: null,
                });
            }
        },
        getarithmeticOptionsBasedOnAssertionType(assertion) {
            if (assertion.type === 'statusCode') {
                return this.statusCodeArithmaticOptions;
            } else if (assertion.type === 'header') {
                return this.queryArithmaticOptions;
            } else if (assertion.type === 'jsonBody') {
                return this.jsonArithmaticOptions;
            }
        },
    },
};
</script>

<style lang="scss" scoped></style>
