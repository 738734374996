import AuthServiceRequest from '../utils/request/auth_request';

export const createSubscription = async data => {
    return await AuthServiceRequest.post(`/subscriptions/save`, data);
};

export const fetchSubscriptionList = async () => {
    return await AuthServiceRequest.get(`/subscriptions/list`);
};

export const deleteSubscriptionPackage = async reqDataObj => {
    return await AuthServiceRequest.delete(`/subscriptions/delete`, {data: reqDataObj});
};

export const updateSubscription = async data => {
    return await AuthServiceRequest.put(`/subscriptions/update`, data);
};
