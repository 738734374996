<template>
    <div class="assertion-code">
        <div class="head">
            <h6>Query String</h6>
        </div>
        <div class="body">
            <Textarea v-model="query" rows="5" cols="30" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'query-string',
    props: {
        sqlQuery: {
            type: Object,
        },
    },
    data: () => ({
        checked: false,
        query: '',
    }),
    created() {
        if (this.sqlQuery) {
            this.query= this.sqlQuery;
            this.$emit('sqlQueryUpdated', this.query);
        }
    },
    watch: {
        query(value) {
            this.$emit('sqlQueryUpdated', this.query);
        },
    },
};
</script>
