<template>
  <!-- v-if="element.type !=='group'"  -->
  <div class="action-main">
    <div class="draft" v-if="element.is_drafted">
      <p>Draft</p>
    </div>
    <div v-if="!element.is_checked || current_step._id !== element._id" :class="['action__right_0', current_step._id !== element._id ? 'test-step__action_icons' : '']" :style="{ ...(hovered_step._id === element._id || break_point._id === element._id ? { display: 'block' } : '') }">
      <div class="action__right" v-if="current_step._id !== element._id">
        <span class="action-button">
          <Button icon="fas fa-cog" title="Properties" class="p-button-rounded p-button-outlined"
            @click="$emit('onSetting', element)" />
        </span>
        <!--<div :style="{ display: 'flex', flexFlow: 'row' }" v-if="element.type !== 'group'">
            <span class="action-button" v-if="element._id !== break_point._id">
                <Button icon="fas fa-pause" class="p-button-rounded p-button-outlined" @click="$emit('onBreakPoint', element)" />
            </span>
            <span class="action-button" v-else>
                <Button
                    icon="fas fa-play"
                    @click="$emit('onBreakPoint', element)"
                    :style="{ ...(element._id == break_point._id ? { border: '5px solid rgba(0,0,255,0.7)' } : {}) }"
                    class="p-button-rounded p-button-outlined"
                    v-if="element.type !== 'group'"
                />
            </span>
        </div>-->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    current_step: {
      type: Object,
      required: true,
    },
    element: {
      type: Object,
      required: true,
    },
    break_point: {
      type: Object,
      required: true,
      default: {
        _id: null,
      },
    },
    hovered_step: {
      type: Object,
      required: true,
      default: {
        _id: null,
      },
    },
  },
  methods: {},
};
</script>
<style scoped>
.action__right {
  display: flex;
  flex-flow: row;
}
</style>
