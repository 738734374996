<!-- eslint-disable no-unused-vars -->
<script>

export default {
    name: 'MobileNestedTestSteps',
    components: {},
    data() {
        return {
            scrollPosition: 0,
            vm: null,
            stepCounter: 0,
            mainParent: null,
            $host: `${process.env.VUE_APP_MOBILE_SERVICE_HOST}/api/v1`,
            arrOfIds: [],
            selectedSteps: [],
        };
    },
    mounted() {
        this.mainParent = document.querySelector('.listed_steps');
        this.vm = this;
    },
    props: {
      autoScrollOff: {
            type: Boolean,
            required: false,
            default: false
        },
        showCheckBox: {
            type: Boolean,
            required: false,
            default: true
        },
        listsOfSteps: {
            type: Array,
            required: true,
            default: []
        },
        validateType: {
            type: String,
            required: false,
            default: ''
        },
        activeStep: {
            type: Object,
            required: false,
            default: {}
        },
        allExecutedSteps: {
            type: Array,
            required: false,
            default: []
        },
        status: {
            type: String,
            required: true
        }
    },

    watch: {
        activeStep(val) {
            let el = this.mainParent.querySelector('.ACTIVATEDSTEP');
            let rect = el && el.getBoundingClientRect();

            console.log('size', this.listsOfSteps.length);
            console.log('stepCounter', this.stepCounter);

            if (el && rect) {
              let scrollHeight = rect.height * this.stepCounter

              if (this.autoScrollOff) {
                console.log('Auto scroll is off')
              } else {
                this.$emit('stepScroll', scrollHeight);
                this.StepScroll(scrollHeight);
              }
              this.stepCounter++;
            }
            let size = this.listsOfSteps.length - 1;

            if (this.stepCounter >= size) {
              this.stepCounter = 0;
            }
            if (this.activeStep?._id == this.listsOfSteps[this.listsOfSteps.length-1]._id) {
              this.scrollPosition = 0;
            }
        },
        selectedSteps(val) {
            // console.log(val);
            this.$emit("selectedSteps", val)
        },
    },

    methods: {
        onValidateStepClick(step) {
            if (this.allExecutedSteps.length) {
                const executedStep = this.allExecutedSteps?.find((executedStep) => executedStep?._id == step?._id);

                if ((step.eventType === 'validate_email')) {
                    if (executedStep) {
                        this.$emit('showEmailModal', executedStep);
                    } else {
                        this.$emit('showEmailModal', step);
                    }
                }

                if ((step.eventType === 'validate_sms')) {
                    if (executedStep) {
                        this.$emit('showSmsModal', executedStep);
                    } else {
                        this.$emit('showSmsModal', step);
                    }
                }

                if (executedStep) {
                    this.$emit('onStepClicked', executedStep);
                } else {
                    this.$emit('onStepClicked', step);
                }
            } else {
                this.$emit('onStepClicked', step);
            }
        },
        StepScroll(y) {
          if (y > this.scrollPosition) {
            this.mainParent.scrollTo({
              top: y,
              left: 0,
              behavior: 'smooth',
            });
            this.scrollPosition = y;
          }
        },
        StepScrollTop() {
            this.mainParent.scrollTop = 0;
            this.stepCounter = 0;
        },

        truncate(str, length) {
            return str?.length > length ? str.slice(0, length)+'...' : str;
        },

        onImageComparisonClick(step) {
            const executedStep = this.getExecuedStepById(step._id);

            if (executedStep) {
              step.screenshot.executedImgUrl = executedStep.executionResult?.screenshot?.uri ? executedStep.executionResult.screenshot.uri : executedStep.screenshot.executedImgUrl;
            }

            this.$emit("onImageComparison", step, executedStep);
        },

        getExecuedStepById(stepId){
            let stepArr = this.allExecutedSteps.filter((step) => step._id == stepId)

            if (!stepArr.length) {
              stepArr = this.allExecutedSteps.filter((step) => step.testStepID == stepId)
            }


            if (stepArr.length) {
                return stepArr[0]
            } else {
                return null
            }
        },
        classNameSplit(className) {
            let nameArray = className.split('.')
            return nameArray[nameArray.length - 1]
        }
    }
}
</script>

<template>
  <div class="listed_steps">
    <div v-for="(step, i) in listsOfSteps" :key="i" :class="['step', activeStep && activeStep._id == step._id ? 'ACTIVATEDSTEP' : '']">
        <span class="">
            <Checkbox v-if="showCheckBox" name="city" :value="step._id" v-model="selectedSteps" :disabled="!step._id ? true : false" />
        </span>
        <div class="element_assets">

            <img v-if="step.screenshot?.uri"
            @click="() => onImageComparisonClick(step)"
            :src="`${step.screenshot && step.screenshot.uri && step.screenshot.uri.toLowerCase().includes('data:image/png;base64') ?
                step.screenshot.uri :
                `${$host}${step.screenshot.uri}`
            }`"
            alt="no ss uri"
            />

            <img v-else-if="step.screenshot?.elementScreenshot"
            @click="() => onImageComparisonClick(step)"
            :src="`${step.screenshot && step.screenshot.elementScreenshot && step.screenshot.elementScreenshot.toLowerCase().includes('data:image/png;base64') ?
                step.screenshot.elementScreenshot :
                `${$host}${step.screenshot.elementScreenshot}`
            }`"
            alt="no ss element screenshot"
            />
            <p v-else-if="step.eventType && step.eventType.toLowerCase() === 'swipe'"> {{ `${step.eventType} ${step.coordinates.start.x},${step.coordinates.start.y} X ${step.coordinates.end.x},${step.coordinates.end.y}`}} </p>
            <p v-else-if="step.eventType && step.eventType.toLowerCase() === 'input'"> Input </p>
            <p v-else-if="step.eventType && step.eventType.toLowerCase() === 'sleep'"> Sleep </p>
            <p v-else-if="step.eventType && step.eventType.toLowerCase() === 'validate_email'"> Validate Email </p>
            <p v-else-if="step.eventType && step.eventType.toLowerCase() === 'validate_sms'"> Validate SMS </p>
            <p v-else-if="step.eventType && step.eventType.toLowerCase() === 'insert_variable'"> Insert variable </p>
            <p v-else-if="step.validation?.validationType  === 'custom_tap'"> Custom tap </p>
            <p v-else> validation </p>
        </div>

        <div class="element_info">
            <i v-if="step?.eventType.toLowerCase() === 'insert_variable'" class="fa fa-location-arrow"></i>
            <i v-else-if="step?.eventType.toLowerCase() === 'validate_email'" class="fas fa-envelope"></i>
            <i v-else-if="step?.eventType.toLowerCase() === 'validate_sms'" class="fas fa-sms"></i>
            <i v-else class="fas fa-mouse"></i>

            <div class="info">

                <p v-if="step.validation && step.validation.shouldValidate">
                    <span v-if="step.validation.validationType === 'validate_checked'">
                        {{ `Validate if ${classNameSplit(step.selectors.class)} is checked` }}
                    </span>

                    <span v-else-if="step.validation.validationType === 'validate_checkbox'">
                        {{ `Validate if ${classNameSplit(step.selectors.class)} is a checkbox` }}
                    </span>

                    <span v-else-if="step.validation.validationType === 'validate_input_field'">
                        {{ `Validate if ${classNameSplit(step.selectors.class)} is an input field` }}
                    </span>

                    <span v-else-if="step.validation.validationType === 'validate_button'">
                        {{ `Validate if ${classNameSplit(step.selectors.class)} is a button` }}
                    </span>

                    <span v-else-if="step.validation.validationType === 'validate_text'" :title="`Validate ${step.text} is ${step.validation.expectedOutput}`">
                        {{ `Validate if ${truncate(step.text ? step.text : step.attributes.contentDescription,17)} is ${truncate(step.validation.expectedOutput,17)} ` }}
                    </span>

                    <span v-else-if="step.validation.validationType === 'validate_element_clickable'">
                        {{ `Validate if ${classNameSplit(step.selectors.class)} is clickable` }}
                    </span>

                    <span v-else-if="step.validation.validationType === 'validate_element_enabled'">
                        {{ `Validate if ${classNameSplit(step.selectors.class)} is enabled` }}
                    </span>

                    <span v-else-if="step.validation.validationType === 'validate_element_visible'">
                        {{ `Validate if ${classNameSplit(step.selectors.class)} is visible` }}
                    </span>

                    <span v-else-if="step.validation.validationType === 'validate_element_invisible'">
                        {{ `Validate if ${classNameSplit(step.selectors.class)} is invisible` }}
                    </span>

                    <span v-else-if="step.validation.validationType === 'validate_element_present'">
                        {{ `Validate if ${classNameSplit(step.selectors.class)} is present` }}
                    </span>

                    <span v-else-if="step.validation.validationType === 'validate_element_selected'">
                        {{ `Validate if ${classNameSplit(step.selectors.class)} is selected` }}
                    </span>

                    <span v-else-if="step.validation.validationType === 'custom_tap'" :title="`Custom tap on '' ${step.text ? step.text : step.attributes.contentDescription} '' at (${step.coordinates?.tap?.x} x ${step.coordinates?.tap?.y})`">
                        {{ `Tap on (${step.coordinates?.tap?.x} x ${step.coordinates?.tap?.y})` }}
                    </span>

                    <span v-else>
                        {{ `Element not found` }}
                    </span>
                </p>

                <p v-else-if="step.sleepFor">
                    <span>
                        {{ ` Sleep for "${step.sleepFor} ms"` }}
                    </span>
                </p>

                <p v-else-if="step.eventType.toLowerCase() == 'insert_variable' && step.inputText">Insert Variable</p>

                <p v-else-if="step.eventType.toLowerCase() == 'input' && step.inputText"> {{ 'Type ' + step.inputText }}</p>

                <p v-else-if="step.text || step.selectors?.class">
                    <span :title="`Press on ${step.text ? step.text : step.selectors.class}`">
                        {{` Press on "${step.text ? truncate(step.text,17) : classNameSplit(step.selectors.class)}"`}}
                    </span>
                </p>

                <p v-else-if="step.eventType && step.eventType.toLowerCase() === 'validate_email'" class="cickable" @click="() => onValidateStepClick(step)">
                    <span>
                        {{ `Validate Email` }}
                    </span>
                </p>

                <p v-else-if="step.eventType && step.eventType.toLowerCase() === 'validate_sms'" class="cickable" @click="() => onValidateStepClick(step)">
                    <span>
                        {{ `Validate SMS` }}
                    </span>
                </p>

                <p v-else>
                    <span>
                        {{ `Swipe` }}
                    </span>
                </p>

                <span class="failed_status" v-if="allExecutedSteps.length && activeStep && activeStep._id != step._id && getExecuedStepById(step._id)" >
                    {{ getExecuedStepById(step._id).error_obj.error && getExecuedStepById(step._id).error_obj.error_cause.length ? getExecuedStepById(step._id).error_obj.error_cause : ''}}
                </span>

            </div>
        </div>

        <div class="element_execution" >
<!--            <StepLoader v-if="activeStep && activeStep._id == step._id" />-->
            <img v-if="activeStep && activeStep._id == step._id" :src="'/assets/Pulse-preloader.svg'">
        </div>
        <span v-if="allExecutedSteps.length && activeStep && activeStep._id != step._id" :class="[
            'el_status',
            getExecuedStepById(step._id) ? getExecuedStepById(step._id).error_obj.status : '',
            ]" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.listed_steps {
  height: 100%;
  overflow-y: scroll;

  .step {
    display: grid;
    grid-template-columns: 1.5em 10em 1fr 5em;
    justify-items: baseline;
    align-items: center;
    padding: 10px 7px;
    padding-left: 15px;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    // background: #c8c8c8;
    margin-bottom: 15px;
    color: black;
    position: relative;
    min-height: 7em;
    max-height: 9em;
    width: 100%;
    transition: .1s ease-in-out;

    &:hover {
        box-shadow: 0px 3px 6px #00000029;
    }

    .success {
      background: rgb(4, 125, 0);
      color: #ffffff;
    }

    .fail {
      background: rgb(148, 0, 47);
      color: #ffffff;
    }

    .element_assets {
        padding: 10px 0;
        width: 100%;
        text-align: center;

        p {
            border: 1px solid #dee2e6;
            border-left: 0;
            padding: 3px 5px;
        }

        img {
            max-width: 10em;
            max-height: 65px;
            object-fit: contain;
            cursor: pointer
        }
    }

    .element_info {
        display: grid;
        grid-template-columns: 23px 1fr;
        place-items: center;
        justify-items: baseline;
        width: 100%;

        .info {
            width: 100%;

            .cickable {
              cursor: pointer;
            }

            p {
                // max-width: 90%;
                overflow: hidden;
                white-space: normal;
                text-overflow: ellipsis;
                font-size: 16px;
                margin: 0;
                margin-bottom: 5px;
                word-break: break-word;
            }
            .failed_status {
                font-size: 13px;
                color: red;
            }
        }

        i {
            margin-left: 3px;
        }
    }

    .element_execution {
        height: 4em;
        display: grid;
        place-items: center;
        margin: 0 auto;

        img {
            height: 50px;
            width: 50px;
        }
    }

    .el_status {
        position: absolute;
        height: 100%;
        width: 5px;
        right: 0;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;

        &.success {
            background: #12C258;
        }

        &.fail {
            background: #DC3545;
        }

        &.failed {
            background: #DC3545;
        }

    }
  }
}

</style>
