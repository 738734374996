/* eslint-disable no-unused-vars */
import TestServiceRequest from '../utils/request/test_request';

const root = 'sms';
/**
 * Get Phone number list from server
 * @param {*} data
 * @returns
 */
export const phoneNumberList = async (data) => {
    return await TestServiceRequest.get(`${root}/phone-list`);
};

export const getPhoneNumberList = async (projectId) => {
    return await TestServiceRequest.get(`v2/phone-numbers?projectId=${projectId}`);
}
export const createPhoneNumber = async (data) => {
    return await TestServiceRequest.post('v2/phone-numbers', data);
}
export const deletePhoneNumber = async (numberId) => {
    return await TestServiceRequest.delete(`v2/phone-numbers/${numberId}`);
}
