<template>
  <div class="home" v-if="!testCaseNotFound">
    <Message severity="warn" :sticky="true" :style="{ display: agentStatus.active ? 'none' : '' }" @close="closeMessage">
      <span v-if="agentStatus.msg.length">{{ agentStatus.msg }}</span>
      <span v-else>
        Please install <strong>TestJet Mobile Agent</strong> from <a
          href="https://chrome.google.com/webstore/detail/testjet/nfpikmjhnlbdljobpkdfeddgjmkmdcdo" target="_blank"
          rel="noopener noreferrer"><strong>here</strong></a>.
      </span>
    </Message>
    <!-- TestCase Dialog -->
    <div class="test__editorPanel">
      <div class="p-grid p-fluid">
        <div class="p-col-9 p-col-md-7 p-col-sm-12 test-editor-table-container editor_main_section">
          <div class="card">
            <div class="testcase-info-header-wrapper">
              <div class="testcase-info-header-top">
                <div class="testcase-info-header-left">
                  <span class="chevron-left-icon">
                    <Button icon="fas fa-chevron-left" class="p-button-rounded p-button-outlined"
                      @click="() => $router.back()" />
                  </span>
                  <div class="testcase-info">
                    <div class="testcase-name">
                      {{ testCase.name }}
                      <span class="testcase-total-count">{{ testCase?.steps?.length || 0 }}
                        steps</span>
                    </div>
                    <div class="testcase-tags">
                      <div v-if="testCase && testCase.tags && testCase.tags.length > 0">
                        <Chip :label="tagPrefix(tag)" v-for="(tag, k) in testCase.tags" :key="k" />
                      </div>
                      <div v-else>
                        <Chip :label="tagPrefix(tag)" v-for="(tag, k) in tags" :key="k" />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="testcase-info-header-right">
                  <Button icon="pi pi-mobile" title="Start/Refresh Stream"
                    class="p-button-rounded p-button-outlined view-icon" @click="_toggleStream"
                    :disabled="isRecording || isNativeRunOn" />

                  <Button :icon="isRecording ? 'fas fa-stop' : 'fas fa-circle'"
                    :title="isRecording ? 'Stop Recording' : 'Record Test Case'"
                    class="p-button-rounded p-button-outlined record-icon" @click="_onStartRecord"
                    :disabled="isNativeRunOn" />

                  <!-- <Button icon="fas fa-cog" title="Settings" class="p-button-rounded p-button-outlined update-icon"
                    :disabled="isRecording || isNativeRunOn || true" /> -->

                  <Button :icon="isNativeRunOn ? 'fas fa-stop' : 'fas fa-play'" :title="isNativeRunOn ? 'Stop' : 'Play'"
                    :class="isNativeRunOn ? 'p-button-rounded p-button-outlined record-icon' : 'p-button-rounded p-button-outlined play-icon'"
                    @click="_onStartAutomation" :disabled="isRecording || !testCase.steps.length" />
                  <Button icon="fas fa-edit" title="Edit Test Case" class="p-button-rounded p-button-outlined update-icon"
                    @click="clickFrEditModal(testCase)" :disabled="isRecording || isNativeRunOn" />
                  <Button icon="fas fa-trash" title="Delete Steps" class="p-button-rounded p-button-outlined update-icon"
                    @click="_clickDeleteTeststep" :disabled="isRecording || isNativeRunOn || !selectedSteps.length" />

                  <!-- <Button icon="fas fa-cloud" class="p-button-rounded p-button-outlined delete-icon" @click="clickSendStepsToSelenuim" /> -->
                </div>
              </div>

              <!--                            <div v-if="isRecordNeeded" class="open-record-message">To choose an element <a @click="onStartRecord"> Open base URL</a> or Install <strong>Agent</strong> from <a target="_blank" :href="$extensionURL">here</a></div>-->

              <div class="testcase-info-header-bottom mobile">
                <div class="testcase-base-config-info-left">
                  <div class="base-config-left-element">
                    <div class="select-all">
                      <Checkbox inputId="chkbox1" v-model="selectAllSteps" value="selectall" @change="selectAll"
                        :disabled="testCase.steps.length && testCase.steps[0]._id ? false : true" />
                      <label for="chkbox1">Select All</label>
                    </div>

                    <div class="element">
                      <span class="icon"><i class="fab fa-android"></i></span>
                      <span class="content">{{ 'Android' }}</span>
                    </div>

                    <div class="element os-info">
                      <span class="icon"><i class="fab fa-google-play"></i></span>
                      <!-- <span class="content">{{ config.os }}</span> -->
                      <span class="content" :title="thisProject?.appInfo?.fileName">
                        {{ thisProject?.appInfo?.fileName || '' }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="teststep-msg-wrapper mobile" v-if="errorStatus === 'passed'">
              <div class="testStep-msg">
                <div class="execute-icon success">
                  <i class="fas fa-check-circle" />
                </div>

                <div class="execution-details success">
                  <span>Run Passed</span>
                  <span>|</span>
                  <span> Execution Time: {{ executionTime.start && executionTime.end ?
                    parseFloat((executionTime.end - executionTime.start) / 1000) : 0 }} sec </span>
                </div>
              </div>
            </div>

            <div class="teststep-msg-wrapper mobile" v-else-if="errorStatus === 'failed'">
              <div class="testStep-msg">
                <div class="execute-icon error">
                  <i class="fas fa-times-circle"></i>
                </div>

                <div class="execution-details error">
                  <span>Run Failed</span>
                  <span>|</span>
                  <span> Execution Time: {{ executionTime.start && executionTime.end ?
                    parseFloat((executionTime.end - executionTime.start) / 1000) : 0 }} sec </span>
                </div>
              </div>
            </div>

            <div :class="['two-sided-panels', errorStatus ? 'sml' : '']">
              <div class="testEditor-leftPanel">
                <MobileNestedTestSteps v-if="testCase && testCase.steps" :listsOfSteps="testCase.steps"
                  :key="testCase.steps" :activeStep="activeRunningStep" :allExecutedSteps="executedSteps"
                  ref="MobileNestedTestSteps"
                  @selectedSteps="stepsSelected"
                  @onImageComparison="onImageComparison"
                  @onStepClicked="onStepClicked"
                />
              </div>

              <div class="testEditor-rightPanel">
                <div class="emulator_section">
                  <div id="streamParent">
                    <video id="player" autoplay muted></video>
                    <div id="highlighterOverlay"></div>
                    <div :class="['block-view', viewBlocked ? 'show' : 'hide']">
                      <img src="@/assets/double-ring-loader.svg" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Dialog :draggable="false" header="Edit Test Case" v-model:visible="editModalVisible"
            :style="{ width: '24rem' }" :modal="true">
            <div class="p-grid p-fluid">
              <div class="p-col-12 m-0 p-0">
                <div class="input-group">
                  <div class="p-field input-description">
                    <label>Test Case Name</label>
                    <InputText v-model="selectedTCaseRowData.name" type="text" placeholder="Enter Test Case Name" />
                  </div>

                  <div class="p-field">
                    <label>Priority</label>
                    <Dropdown v-model="selectedPriority" :options="priorities" optionValue="name" optionLabel="name"
                      style="width: 100%" showClear placeholder="Select Priority" />
                  </div>

                  <div class="p-field">
                    <label>Category</label>
                    <Dropdown v-model="selectedCategory" :options="categories" optionValue="id" optionLabel="name"
                      style="width: 100%" showClear filter placeholder="Select Category" />
                  </div>

                  <TjCustomChipsVue :tagList="selectedTCaseRowData.tags" @onUpdateChipsList="onUpdateChipsList" />
                  <Button label="Save Changes" class="p-button-primary save-changes-button" @click="clickEditTestCase"
                    :style="{ marginTop: '15px' }" />
                </div>
              </div>
            </div>
            <!-- <pre>{{ selectedTCaseRowData }}</pre> -->
          </Dialog>

          <Dialog :draggable="false" :header="validationModal['data'] ? validationModal['data'].name : ''"
            v-model:visible="validationModal['open']" :style="{ width: '500px' }" :modal="true"
            @hide="cancelValidationStepCreation">
            <div class="p-grid">
              <div class="p-col-12"
                v-if="stepToBeValidated?.text || stepToBeValidated?.attributes?.contentDescription || stepToBeValidated?.selectors?.class">
                <h6>Test Description</h6>
                <InputText type="text" :value="showValue(stepToBeValidated)" :style="{ width: '100%' }" disabled />
              </div>

              <div class="p-col-12"
                v-if="validationModal['data'].InputRequired && validationModal['data'].action === 'sleep'">
                <h6>Sleep Time</h6>
                <InputNumber v-model="stepToBeValidated['sleepFor']" :style="{ width: '100%' }" suffix=" (ms)"
                  placeholder="1,000 (ms)" />
              </div>

              <div class="p-col-12" v-else-if="validationModal['data'].InputRequired">
                <h6>Expected Value</h6>
                <InputText type="text" v-model="stepToBeValidated.validation['expectedOutput']"
                  :style="{ width: '100%' }" />
              </div>

              <div class="p-col-12">
                <h6>When This Step Fails</h6>
                <InputText type="text" v-model="whenStepFails" :style="{ width: '100%' }" disabled />
              </div>

              <div class="p-col-12">
                <Button label="SAVE CHANGES" @click="_createValidationStep" :style="{ marginTop: '8px' }" />
              </div>
            </div>
          </Dialog>

          <Dialog :draggable="false" :header="'Edit Text'" v-model:visible="textViewModal.open"
            :style="{ width: '500px' }" :modal="true" @hide="_cancelEditInput">
            <div class="p-grid">
              <div class="p-col-12">
                <h6>Enter Text</h6>
                <InputText type="text" v-model="textViewModal['data']" :style="{ width: '100%' }" />
              </div>

              <div class="p-col-12">
                <Button label="SAVE CHANGES" @click="_editInput" id="inputText" :style="{ marginTop: '8px' }" />
                <!-- <Button label="CANCEL" @click="_cancelEditInput" :style="{ marginTop: '8px' }" /> -->
              </div>
            </div>
          </Dialog>

          <Dialog :draggable="false" :header="'Tap outside View-Port'" v-model:visible="customTapModal.open"
            :style="{ width: '500px' }" :modal="true" @hide="_cancelCustomTap">
            <div class="p-grid">
              <div class="p-col-12">
                <h6>You have tapped outside the viewport of the application. Do you want to add a custom tap
                  at that co-ordinate?</h6>
              </div>

              <div class="p-col-12">
                <Button label="ADD CUSTOM TAP" @click="_addCustomTap" id="addCustomTap" :style="{ marginTop: '8px' }" />
                <Button label="CANCEL" @click="_cancelCustomTap" :style="{ marginTop: '8px' }" />
              </div>
            </div>
          </Dialog>

          <!-- eslint-disable-next-line -->
          <Dialog :draggable="false" header="Insert variable" v-model:visible="openInsertVariableModal.open" :modal="true"
          @hide="variableModalClosed" class="delete-confirmation-modal" style="width: 32rem">
            <div class="insrt-vr-content" style="display: flex;flex-direction: column;row-gap: 16px;">
              <label>Variables</label>
              <Dropdown :options="caseVariables" optionLabel="name" v-model="selectedVariable" placeholder="Choose variable">
                <template #empty>
                  <p style="text-align: center;"> No variables available </p>
                </template>
              </Dropdown>
            </div>
            <template #footer>
              <div class="footer">
                <Button label="Use variable" class="p-button-primary" @click="useVariable(selectedVariable)"
                  :disabled="!selectedVariable" style="width: 100%;" />
              </div>
            </template>
          </Dialog>

        </div>
        <div :class="['p-col-12 p-col-m-12 p-col-sm-12 another-component', secondaryComponent.show ? 'active' : '']">
          <div class="card">
            <div class="testcase-info-header-wrapper">
              <div class="testcase-info-header-top">
                <div class="testcase-info-header-left">
                  <span class="chevron-left-icon">
                    <Button @click="goBackToTestEditorHandler" icon="fas fa-chevron-left"
                      class="p-button-rounded p-button-outlined" />
                  </span>
                  <div class="testcase-info">
                    <div class="testcase-name">
                      {{
                        secondaryComponent.componentName === 'validate_email' && 'Email Validation'
                        ||
                        secondaryComponent.componentName === 'validate_sms' && 'SMS Validation'
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="validation-component">
            <EmailValidation v-if="secondaryComponent.componentName === 'validate_email'"
              platformType="android"
              :sockedConnected="this.socket?.connected"
              :existingData="secondaryComponentExistingData"
              @onSendMockEmailTest="onValidateEmailTest"
              @save="onValidateEmailSave"
              @fetchVariables="getVariables"
            />
            <SMSValidation v-else-if="secondaryComponent.componentName === 'validate_sms'"
              platformType="android"
              :sockedConnected="this.socket?.connected"
              :existingData="secondaryComponentExistingData"
              @onSendMockSMSTest="onValidateSMSTest"
              @save="onValidateSMSSave"
              @fetchVariables="getVariables"
              @updateRunTimeData="onUpdateRunTimeData"
            />
            <span v-else class="card"
              style="display: grid; place-items: center; padding: 1rem; font-size: 2rem; font-weight: 600;">
              Loading...
            </span>
          </div>
        </div>

        <div class="p-col-3 p-col-md-5 p-col-sm-12 validation-panel-container"
          v-if="!secondaryComponent.show && !secondaryComponent.componentName">
          <div class="validationPanel">
            <ValidationPanelMobile ref="validationPanelRef" :deviceType="deviceType"
              :testCaseInAction="isRecording || screenshotUploading" @onListClick="_openValidationModal"
              @clickSave="_saveTestCase" :savingTestCase="savingTestCase" />
          </div>
        </div>
      </div>
    </div>
    <ComparisonAutomationDialogMobile :isVisible="showComparisonModal" :testStep="comparisonStep"
      @hide="hideComparisonModal" />

  </div>
  <div v-else>
    <span class="card"
      style="display: grid; place-items: center; padding: 25rem; font-size: 2rem; font-weight: 600;">
      No Data
    </span>
  </div>
</template>

<script>
// Components
import MobileNestedTestSteps from '../../commonComponents/MobileNestedTestSteps.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import ValidationPanelMobile from '../editorComponents/ValidationPanelMobile.vue';

import { $startNativeAutomation, $startRecording, $stopNativeAutomation, $stopRecording, getCurrentPageSource, waitForApp, $startStreamServer } from '../../dump/axios';
import { generateRandomString, rescale } from '../../utility/utils';
import { findElementXpathByCoordinates } from '../../utility/locator';
import { io } from 'socket.io-client';
import Step from '../../utility/classes/Step';
import TjCustomChipsVue from '../../../components/TjCustomChips.vue';
import ComparisonAutomationDialogMobile from '../../commonComponents/ComparisonAutomationDialogMobile.vue';
import { OverlayHandler } from '../../utility/classes/overlayHandler';
import { androidLists } from '@/utils/mobile/mobile_validation_list';

//* Components Components
import EmailValidation from '../customValidationComponents/email-validation/EmailValidation.vue';
import SMSValidation from '../customValidationComponents/sms-validation/SMSValidation.vue';

//* MIXINS
import variableMixin from './mixins/variableMixin';
import { getSelectedCurrentProjectId } from '../../../utils/localstorage';

// let localStorageConfig = localStorage.getItem('config') ? JSON.parse(localStorage.getItem('config')) : null;
let overlayHandler = null;
let liveOverlayInterval = 40;

export default {
  components: {
    MobileNestedTestSteps,
    ValidationPanelMobile,
    ComparisonAutomationDialogMobile,
    TjCustomChipsVue,
    EmailValidation,
    SMSValidation,
  },
  name: 'MobileTestEditorPage',
  data() {
    return {
      deviceType: 'android',
      testCase: {
        steps: [],
      },
      secondaryComponent: {
        show: false,
        componentName: '',
      },
      secondaryComponentExistingData: null,
      selectedSteps: [],
      selectAllSteps: [],
      streamConnected: false,
      socketUrl: process.env.VUE_APP_AGENT_SERVICE_HOST,
      streamSocket: null,
      jmuxer: null,
      streamParent: null,
      videoElement: null,
      highlighterDiv: null,
      seekInterval: null,
      deviceDimension: null,
      videoDimention: { height: 0, width: 0 },
      socket: null,
      validateType: '',
      selectedIssueTypeObj: {},
      isRecording: false,
      shouldUpdateHighlights: true,
      whenStepFails: 'Mark error & continue',
      swipeCord: { x: null, y: null },
      validationModal: {
        open: false,
        data: null,
      },
      textViewModal: {
        open: false,
        data: '',
        textElement: null,
      },
      customTapModal: {
        open: false,
      },
      stepToBeValidated: null,
      activeRunningStep: null,
      executedSteps: [],
      testCaseId: null,
      currentStepCnt: -1,
      isNativeRunOn: false,
      thisProject: null,
      testRailTestCase: null,
      agentStatus: {
        active: true,
        msg: '',
      },
      savingTestCase: false,
      viewBlocked: false,
      showComparisonModal: false,
      comparisonStep: null,
      selectedTCaseRowData: {},
      editModalVisible: false,
      errorStatus: '',
      executionTime: {
        start: '',
        end: ''
      },
      screenshotUploading: false,
      resolutionData: null,
      selectedPriority: null,
      priorities: [
        {
          name: 'Critical',
          code: 1,
        },
        {
          name: 'High',
          code: 2,
        },
        {
          name: 'Medium',
          code: 3,
        },
        {
          name: 'Low',
          code: 4,
        },
      ],
      selectedCategory: null,
      categories: [],
      testCaseNotFound: false,
      openInsertVariableModal: {
        open: false,
        data: '',
        textElement: null,
      },
      selectedVariable: null,
    };
  },

  async created() {
    this.testCaseId = this.$route?.params?.id;
    const [testCaseData, testCaseDataErr] = await this.getTestCase(this.testCaseId);

    if (testCaseDataErr) {
      this.testCaseNotFound = true;
      return;
    }
    if(!this.testCaseNotFound){
      const [testSteps, testStepsErr2] = await this.getTestStepsofEachTestCase(this.testCaseId);
      if (testStepsErr2) {
        console.log(testStepsErr2);
        return;
      }
      this.testCase = { ...testCaseData, steps: testSteps };
      this.getVariables();
      this.getCategories(getSelectedCurrentProjectId()).then(res => {
        if (res?.status === 200 && res?.data?.length) {
          this.categories = res.data;
        }
      });

      if (this.testCase?.priority !== null) {
        this.selectedPriority = this.testCase.priority;
      }

      if (this.testCase?.category_id !== null) {
        this.selectedCategory = this.testCase?.category_id;
      }
    }
  },

  watch: {
    config(value) {
      console.log('base===>', value);
    },
    // selectAllSteps(val) {
    //   if(val.length) {
    //     this.$refs.MobileNestedTestSteps.selectedSteps = this.testCase.steps.map(el => el._id);
    //   } else {
    //     this.$refs.MobileNestedTestSteps.selectedSteps = [];
    //   }
    // },

    async currentStepCnt(i) {
      if (i === -1 || !this.isNativeRunOn) {
        return;
      }
      await new Promise((r) => setTimeout(r, 1500));
      this.activeRunningStep = this.testCase.steps[i];

      if (this.activeRunningStep.eventType === 'insert_variable') {
        const variable = this.caseVariables.find(el => el._id == this.activeRunningStep.variableId)

        variable && (this.testCase.steps[i].inputText = variable.value)
      }

      this.socket.emit('action', this.testCase.steps[i]);
    },
    projectList(lists) {
      this.thisProject = lists.filter((prj) => prj._id == localStorage.getItem('projectId'))[0];
    },
  },

  computed: {
    ...mapState({
      username: (state) => state.user.name,
    }),

    ...mapGetters({
      currentTestcase: 'testCase/currentTestcase',
      projectID: 'project/projectID',
      loggedInUserInfo: 'auth/loggedInUserInfo',
      projectList: 'project/projectList',
      mobileTestCase: 'mobileTestCases/mobileTestCase',
      caseVariables: 'variable/caseVariables',
    }),
  },

  mounted() {
    if(!this.testCaseNotFound){
      // console.log("%mounted Hook Called !", "background-color: green;")
      this.testCase.steps = [];
      this._getHtmlElements();

      setTimeout(() => {
        this.thisProject = this.projectList?.filter((prj) => prj._id === localStorage.getItem('projectId'))[0];
      }, 1700);

      this._setup();
    }
  },

  unmounted() {
    try {
      if (this.streamSocket && this.streamSocket.close && this.streamSocket.readyState === 1) {
        this.streamSocket.close();
        this.streamSocket = null;
      }
    } catch (ex) {
      console.log(ex);
    }
    this.socket.emit('connectionEnd');
    if (this.seekInterval) {
      clearInterval(this.seekInterval);
    }
  },

  directives: {},

  methods: {
    ...mapActions({
      deleteTestSteps: 'mobileTestCases/actionDeleteTestSteps',
      actionUpdateCase: 'mobileTestCases/actionUpdateMobileTestcase',
      actionGetMobileCase: 'mobileTestCases/actionGetMobileCase',
      saveTestCase: 'mobileTestCases/saveTestCase',
      getTestCase: 'mobileTestCases/getTestCase',
      getTestStepsofEachTestCase: 'mobileTestCases/getTestStepsofEachTestCase',
      sendScreenshotToS3: 'mobileTestCases/actionSendTestStepsScreenshotToS3',
      actionGetTestCaseVariables: 'variable/actionGetTestCaseVariables',
      getCategories: 'category/actionGetCategories',
      updateTestStep: 'mobileTestCases/actionUpdateTestStep',

    }),

    variableModalClosed() {
      this.openInsertVariableModal = {
        open: false,
        data: ''
      }

      this.selectedVariable = null;

      setTimeout(() => {
        this.viewBlocked = false
        this.shouldUpdateHighlights = true;
      }, 400);
    },

    useVariable(selectedVariable) {
      const step = {...this.openInsertVariableModal.data};

      setTimeout(() => {
        this.openInsertVariableModal = {
          open: false,
          data: ''
        }
      }, 400);


      step.inputText = selectedVariable.value;
      step.variableId = selectedVariable._id;

      // console.log(" STEPPPPPPPP ", step);
      this.socket.emit('action', step);

    },

    onValidateEmailTest(data) {
      console.log("onValidateEmailTest", data);
    },

    onValidateEmailSave( { step, validationInfo } ) {
      delete validationInfo.actionType;
      step['validationInfo'] = validationInfo;

      this.testCase.steps.push(step);

      this.secondaryComponent = { show: false, componentName: '', data: null };
      this.secondaryComponentExistingData = null;

      console.log("onValidateEmailSave", { step, validationInfo });
    },

    onValidateSMSTest(data) {
      console.log("onValidateSMSTest", data);
    },
    async addToStepFromDraft(data) {
      const existingStep = this.testCase.steps.filter(st => st._id == data.existingStepId)[0]
      console.log("=======existingStep==========>HELLO", existingStep);
      if (existingStep) {
        const drafted = data.actionType === 'addToStepFromDraft' ? false : true;
        const ind = this.testCase.steps.indexOf(existingStep);
        const updatedSMSValidationStep = { ...existingStep, validationInfo: { ...data } };
        delete updatedSMSValidationStep["validationInfo"]["existingStepId"];
        updatedSMSValidationStep['is_drafted'] = drafted;
        delete updatedSMSValidationStep["validationInfo"]["actionType"];

        for (let j = 0; j < updatedSMSValidationStep.validationInfo.assertions.length; j++) {
          const assertion = updatedSMSValidationStep.validationInfo.assertions[j];
          delete assertion.result;
        }

        this.testCase.steps[ind] = updatedSMSValidationStep;

        await this.updateTestStep({ "stepId": data.existingStepId, "step": updatedSMSValidationStep })
          .then((resBody) => {
            if (resBody.status === 200) {
              this.showSuccess(resBody.message.MSG_SHORT, drafted ? "Step drafted" : "Step Added");
              // this.fetchCurrentTestcaseDetails(this.test_case_id);
              this.differentComponentStepData = null;
              this.showDifferentComponent = { show: false, componentName: '', }
            } else {
              this.showError('Test step create unsuccessful.');
            }
          })
          .catch((err) => {
            console.log("🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 ERROR", err);
          });
      }
    },

    async onValidateSMSSave( { step, validationInfo } ) {
      // delete validationInfo.actionType;
      if (validationInfo.actionType === 'addToStep') {
        delete validationInfo.actionType;
        step['validationInfo'] = validationInfo;

        this.testCase.steps.push(step);

      }else if (validationInfo.actionType === 'udraft') {
        console.log(" Hello Hello Hello");
        const existingStep = this.testCase.steps.filter(st => st._id == step._id)[0]
        if (existingStep) {
          this.addToStepFromDraft(step, validationInfo)
        }
        // else {
        //   this.stepsSaveAsDraft(message.data);
        // }
      }
      else if (validationInfo.actionType === 'addToStepFromDraft' && step._id) {
        this.addToStepFromDraft(step, validationInfo);
      }
      //? This is for updating the update validation step
      else if (validationInfo.actionType === 'updateStep') {
        delete validationInfo.actionType;
        step['validationInfo'] = validationInfo;
        if (step._id) {
          const existingStep =  this.testCase.steps.filter(st => st._id == step._id)[0]
          if (existingStep) {
            const ind = this.testCase.steps.indexOf(existingStep);
            const updatedSMSValidationStep = { ...step };
            // delete updatedSMSValidationStep["validationInfo"]["existingStepId"];
            // delete updatedSMSValidationStep["validationInfo"]["actionType"];

            for (let j = 0; j < updatedSMSValidationStep.validationInfo.assertions.length; j++) {
              const assertion = updatedSMSValidationStep.validationInfo.assertions[j];
              delete assertion.result;
            }

            this.testCase.steps[ind] = updatedSMSValidationStep
            await this.updateTestStep({ "stepId": step._id, "step": updatedSMSValidationStep })
              .then((resBody) => {
                // if (resBody.status.code === 200) {
                  this.showSuccess('success', resBody.message);
                  // this.fetchCurrentTestcaseDetails(this.test_case_id);
                  this.showDifferentComponent = { show: false, componentName: '', }
                // } else {
                //   this.showError('Test step create unsuccessful.');
                // }
              })
              .catch((err) => {
                console.log("🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 ERROR", err);
              });
          } else {
            console.log("Nothing to Update");
          }
        }
      }




      this.secondaryComponent = { show: false, componentName: '', data: null };
      this.secondaryComponentExistingData = null;

      console.log("onValidateSMSSave", { step, validationInfo });
    },

    onUpdateRunTimeData({step, validationInfo }) {
      console.log("this.steps", this.testCase.steps);

      const ind = this.testCase.steps.findIndex(el => el._id == step.existingStepId)

      const newStepObj = {
        ...this.testCase.steps[ind],
        validationInfo: {
          ...validationInfo
        },
      }

      if (validationInfo.actionType === 'updateAsDraftRunTime') {
        newStepObj.is_drafted = true;
      }
      console.log("data data data 🤬🤬🤬🤬🤬🤬🤬🤬🤬🤬🤬🤬🤬🤬🤬🤬🤬🤬🤬🤬🤬🤬🤬🤬🤬🤬🤬🤬", newStepObj);
      delete newStepObj.validationInfo.existingStepId;
      this.testCase.steps.splice(ind, 1, newStepObj)

      console.log('this.steps :::::::::::::::::', { validationInfo, ind, steps: this.testCase.steps });
      this.secondaryComponent = { show: false, componentName: '', data: null };
      this.secondaryComponentExistingData = null;
    },

    onStepClicked(step) {
      const eventType = step.eventType;
      const customValidations = Object.values(androidLists).filter(vl => vl?.hasOwnComponent === true)

      console.log({step, customValidations});

      switch (eventType) {
        case "validate_email":
          this.secondaryComponent = { show: true, componentName: eventType, }
          this.secondaryComponentExistingData = step;
          break;

        case "validate_sms":
          this.secondaryComponent = { show: true, componentName: eventType, }
          this.secondaryComponentExistingData = step;
          break;

        default:
          console.log(" No Case is defined for this event type ", eventType);
          break;
      }
    },

    async getVariables() {
      const data = {
        data: { caseId: this.$route.params.id },
        type: 'mobile'
      }
      const [resp, err] = await this.actionGetTestCaseVariables(data);
    },

    goBackToTestEditorHandler() {
      this.secondaryComponent = { show: false, componentName: '' };
      this.secondaryComponentExistingData = null;

      if(this.isRecording === true) {
        this._updateResolution()
      }
    },

    seekVideo(threshold = 4) {
      if (!this.videoElement || this.videoElement.readyState < 3) {
        return;
      }
      // Get the buffered time ranges of the video
      const buffered = this.videoElement.buffered;
      // Get the end time of the last buffered range
      if (buffered.length < 5) {
        return;
      }
      const lastBuffered = buffered.end(buffered.length - 2);
      // Get the current time of the video
      const currentTime = this.videoElement.currentTime;
      // Calculate the lag
      const lag = lastBuffered - currentTime;
      // If the lag is larger than the threshold, seek the video to the end of the buffered range
      if (lag > threshold) {
        this.videoElement.currentTime = lastBuffered;
      }
    },
    showValue(step) {
      if (step.text) {
        return step.text.replaceAll('\n', ' ')

      } else if (step.attributes?.contentDescription) {
        return step.attributes.contentDescription.replaceAll('\n', ' ')

      } else {
        return step.selectors.class
      }
    },

    closeMessage() {
      this.agentStatus = { ...this.agentStatus, active: true, msg: '' };
      this._setup();
    },

    onImageComparison(step) {
      // process.env.VUE_APP_NOT_EXECUTED_STEP_IMAGE
      // step.executedImgUrl
      let ind = this.executedSteps.findIndex(el => el._id == step._id);
      let latestStep = ind > -1 ? this.executedSteps[ind] : null;
      console.log({ latestStep, step });
      const st = { ...step, ...(latestStep ? { screenshot: latestStep.screenshot } : {}) }

      this.comparisonStep = st
      this.showComparisonModal = true;
    },

    hideComparisonModal() {
      this.showComparisonModal = false;
      this.comparisonStep = null
    },

    titlePrefix(title) {
      const strlen = 15;
      const dotprefix = title.length > strlen ? '....' : '';

      return title && String(title).substr(0, strlen) + dotprefix;
    },

    async setScreenShot(testStep) {
      this.screenshotUploading = true;
      if (testStep.screenshot.executedImgUrl || testStep.screenshot.elementScreenshot) {
        this.sendScreenshotToS3({ prjId: this.thisProject._id, caseId: this.testCaseId, testStep, filename: testStep.fileId }).then((data) => {
          const [{ uri, fileName }, err] = data;
          const stIdn = this.testCase.steps.findIndex((st) => st.fileId == fileName);
          this.testCase.steps[stIdn].screenshot.uri = uri;
          this.screenshotUploading = false;
        });
      } else {
        this.screenshotUploading = false;
        console.log('No Screenshot 🖼️ 🖼️ 🖼️ 🖼️ ');
      }
    },

    selectAll(e) {
      if (this.selectAllSteps.length) {
        this.$refs.MobileNestedTestSteps.selectedSteps = this.testCase.steps.map((el) => el._id);
      } else {
        this.selectAllSteps = [];
        this.$refs.MobileNestedTestSteps.selectedSteps = [];
      }
    },

    clickFrEditModal(rowData) {
      this.selectedTCaseRowData = { ...rowData };
      this.editModalVisible = !this.editModalVisible;
    },

    onUpdateChipsList(updatedChipsList) {
      this.selectedTCaseRowData.tags = updatedChipsList;
    },

    clickEditTestCase() {
      this.selectedTCaseRowData.priority = this.selectedPriority;
      this.selectedTCaseRowData.category_id = this.selectedCategory;
      this.actionUpdateCase(this.selectedTCaseRowData)
        .then((resBody) => {
          // if (resBody.status.code === 200) {
            this.showSuccess(resBody.message, resBody.message);
            this.editModalVisible = !this.editModalVisible;
            this.fetchTestCase()
          // } else {
          //   this.showErrorMsg('Test Case Update Unsuccessful.');
          // }
        })
        .catch((err) => {
          console.log('update-res-err', err.response);
        });
    },

    tagPrefix(tag) {
      const strlen = 20;
      const dotprefix = tag.length > strlen ? '....' : '';
      return tag && String(tag).substr(0, strlen) + dotprefix;
    },

    async fetchTestCase() {
      if (this.testCaseId) {
        const [testCaseData, testCaseDataErr] = await this.getTestCase(this.testCaseId);
        const [testSteps, testStepsErr2] = await this.getTestStepsofEachTestCase(this.testCaseId);
        // this.actionGetMobileCase(this.testCaseId);
        this.testCase = { ...testCaseData, steps: testSteps };
      } else {
        console.log('Please create or select project first.');
      }
    },

    stepsSelected(selSteps) {
      if (selSteps.length < this.selectedSteps.length) {
        this.selectAllSteps = [];
      }

      this.selectedSteps = selSteps;
    },

    async _clickDeleteTeststep() {
      // this.selectedSteps

      const [data, err] = await this.deleteTestSteps(this.selectedSteps);

      if (err) {
        this.showError('Failed to delete steps !');
        return;
      }

      this.selectedSteps.map((stepId) => {
        let ind = this.testCase.steps.findIndex((st) => {
          console.log(st._id, stepId);
          return st._id == stepId;
        });

        if (ind != -1) {
          this.testCase.steps.splice(ind, 1);
        }
      });

      this.showSuccess('Steps deleted !', 'Your selected test steps were deleted!');
      this.$refs.MobileNestedTestSteps.selectedSteps = [];
      this.selectedSteps = [];
      this.selectAllSteps = [];
    },

    onStepScroll(y) {
      // console.log('onStepScroll', y);
      if (y > this.scrollPosition && this.$refs.testStepsTableWrapper) {
        this.$refs.testStepsTableWrapper.scrollTo({
          top: y,
          left: 0,
          behavior: 'smooth',
        });
        this.scrollPosition = y;
      }
    },

    showSuccess(summary, detail) {
      this.$toast.add({
        severity: 'success',
        summary: summary,
        detail: detail,
        life: 3000,
      });
    },

    showError(detail) {
      this.$toast.add({
        severity: 'error',
        summary: 'Error Message',
        detail: detail,
        life: 3000,
      });
    },

    bugtypeModalHandler(event) {
      this.$refs.bugTypeModal.toggle(event);
    },

    _getHtmlElements() {
      this.streamParent = document.querySelector('#streamParent');
      this.videoElement = document.querySelector('#player');
      this.highlighterDiv = document.querySelector('#highlighterOverlay');
    },

    async _toggleStream(options) {
      const _pageContext = this;
      //! if stream is connected, disconnect stream
      if (this.streamSocket && this.streamSocket.close && this.streamSocket.readyState === 1) {
        try {
          await this.streamSocket.close();
        } catch (e) {
          console.log('error', e);
        }
        this.streamSocket = null;
      }
      //! connecting stream
      try {
        if (options.shouldStartServer !== false) {
          const [data, err] = await $startStreamServer();
          if (err) {
            return;
          }
          this.resolutionData = data;
          this._updateResolution(data);
        }
        _pageContext.jmuxer = new JMuxer({
          node: 'player',
          mode: 'video',
          flushingTime: 1,
          fps: 30,
          debug: false
        });

        // this.wsavc.connect(`ws://${process.env.VUE_APP_STREAM_SOCKET_HOST}:${process.env.VUE_APP_STREAM_SOCKET_PORT}/device`);
        this.streamSocket = new WebSocket(`ws://${process.env.VUE_APP_STREAM_SOCKET_HOST}:${process.env.VUE_APP_STREAM_SOCKET_PORT}/device`);
        this.streamConnected = true;

        this.streamSocket.binaryType = 'arraybuffer';
        this.streamSocket.addEventListener('message', function (event) {
          try {
            _pageContext.jmuxer.feed({
              video: new Uint8Array(event.data)
            });
          } catch (e) {
            // do nothing
          }
          if ((_pageContext.videoElement.paused || _pageContext.videoElement.ended) && _pageContext.videoElement.readyState >= 2) {
            _pageContext.videoElement.play();
          }
        });
        this.streamSocket.addEventListener('close', function (event) {
          console.log('Socket Closed');
          _pageContext.streamConnected = false;
          _pageContext.jmuxer.destroy();
          _pageContext.jmuxer = null;
          _pageContext.videoElement.style.height = '0px';
          _pageContext.videoElement.style.width = '0px';
          _pageContext.streamSocket = null;
        });
        this.streamSocket.addEventListener('connect', function (event) {
          console.log('Socket Connected');
        });
        this.streamSocket.addEventListener('error', function (e) {
          console.log('Socket Error');
        });
      } catch (e) {
        console.log('error starting stream', e);
      }
    },

    async _onStartRecord(e) {
      if (e) { e.preventDefault() }

      if (!this.socket || !this.socket?.connected) {
        this.agentStatus = { ...this.agentStatus, active: false, msg: 'Please install the Agent !' };
        console.log('%cSocket not connected ', 'background-color: darkblue; color: white; font-size: 1.3em');
        return;
      }

      if (this.isRecording) {
        const [data, err] = await $stopRecording();
        await new Promise(r => setTimeout(r, 1000));
        // console.log(data, err);
        this.viewBlocked = false;
        this.isRecording = false;
        return;
      }

      const [data, err] = await $startRecording(this.thisProject._id, this.thisProject.appInfo.fileName, this.thisProject.type);

      if (err) {
        console.log(err);
        this.isRecording = false;
        return;
      }
      this.isRecording = true;
      this.viewBlocked = false;
      this.resolutionData = data;
      this._updateResolution(data);
      this.streamConnected = !this.streamConnected;
      this._toggleStream({ shouldStartServer: false });
      this.viewBlocked = true;
      this.startLiveOverlay();
    },

    async startLiveOverlay() {
      const context = this;
      await new Promise(r => setTimeout(r, 10000));
      const pageSource = await getCurrentPageSource();
      setTimeout(() => { context.viewBlocked = false; }, 1000);
      overlayHandler = new OverlayHandler(pageSource, context.videoElement, context.highlighterDiv);
      await overlayHandler.processXml();
      console.log('generated overlay')

      for (let i = 0; context.isRecording === true; i++) {
        if (context.shouldUpdateHighlights) {
          try {
            const pageSource = await getCurrentPageSource();
            overlayHandler = new OverlayHandler(pageSource, context.videoElement, context.highlighterDiv);
            await overlayHandler.processXml();
          } catch (e) {
            console.log("Overlay Error => ", e);
          }
        }

        await new Promise(r => setTimeout(r, liveOverlayInterval));
      }
    },

    async _openValidationModal(validation_type) {
      // console.log(validation_type);

      //* If testCase is not recording !
      if (!this.isRecording || this.isNativeRunOn) {
        return console.log("Not recording !");
      }

      //* If testCase is recording and validation is a custom validation
      //INFO => hasOwnComponent is not a js method It is a custom made atrribute so that we can find out if the step is a custom validation should show it's own component or not.
      if (validation_type.hasOwnComponent) {
          const newStep = new Step({
            projectID: this.testCase.projectID,
            testCaseID: this.testCase._id,
            singleStepID: generateRandomString(15),
          }, validation_type.action)
          newStep.isRecording = true

        this.secondaryComponent = { show: true, componentName: validation_type.action }
        this.secondaryComponentExistingData = newStep;
        return;
      }

      //* If testCase is recording and validation type is Sleep !
      if (validation_type.action === 'sleep') {

        this.viewBlocked = true;
        const newSleepStep = new Step(
          {
            projectID: this.testCase.projectID,
            testCaseID: this.testCase._id,
            testStepID: generateRandomString(15),
            // listIndex,
            sleepFor: null,
          }, 'sleep')

        this.stepToBeValidated = newSleepStep;

        this.validationModal = {
          open: true,
          data: validation_type,
        };

      } else if (validation_type.action === 'custom_tap') {

        this.validationModal = {
          open: false,
          data: validation_type,
        };

      } else if (validation_type.action === 'insert_variable') {
        this.openInsertVariableModal = {
          open: false,
          data: validation_type
        }
      } else {
        //* If testCase is recording !

        this.validationModal = {
          open: false,
          data: validation_type,
        };

      }

    },

    async _editInput() {
      this.textViewModal = {
        ...this.textViewModal,
        open: false,
      };

      this.socket.emit('action', { ...this.textViewModal.textElement, inputText: this.textViewModal.data });
      this.textViewModal = {
        open: false,
        data: '',
        textElement: null,
      }
    },

    _cancelEditInput() {
      this.viewBlocked = false;

      this.textViewModal = {
        open: false,
        data: '',
        textElement: null,
      }
    },

    _addCustomTap() {
      this.customTapModal = {
        ...this.customTapModal,
        open: false,
      };

      console.log("Requesting to add custom tap");

      const newStep = new Step({
        ...this.customTapModal.element
      }, 'tap');

      newStep.validation.shouldValidate = true;
      newStep.validation.validationType = 'custom_tap';


      console.log("new step ------------------------------> ", newStep)

      this.socket.emit('action', { ...newStep, isRecording: true });
    },

    _cancelCustomTap() {
      this.viewBlocked = false;

      this.customTapModal = {
        open: false
      }
    },

    cancelValidationStepCreation() {
      //* If this.stepToBeValidated is true that means the modal is closed after validation steps is send to agent and modal closed programatically !
      if (this.stepToBeValidated) {
        this.stepToBeValidated = null;
        this.validationModal = { ...this.validationModal, open: false, data: null };
        this.viewBlocked = false;

        //* else the validation modal is closed by clicking X button
      } else {
        this.stepToBeValidated = null;
        this.validationModal = { ...this.validationModal, open: false, data: null };
        this.viewBlocked = false;
      }
    },

    async _createValidationStep() {

      if ('validation' in this.stepToBeValidated) {
        this.stepToBeValidated.validation.shouldValidate = true;
        this.stepToBeValidated.validation.validationType = this.validationModal.data.action;
      }

      if (this.validationModal.data.action === 'custom_tap') {
        await new Promise(resolve => setTimeout(resolve, 1000));
        console.log("🧚 🧚 🧚 🧚 🧚 🧚 🧚 🧚 🧚 🧚 🧚 🧚 ", this.stepToBeValidated)
        this.socket.emit('action', { ...this.stepToBeValidated, eventType: 'tap', isRecording: true });
        this.validationModal = { ...this.validationModal, open: false, data: null };
        // this.testCase.steps.push({ ...this.stepToBeValidated, eventType: 'tap' });
        this.stepToBeValidated = null;
        return;

      }
      else if (this.stepToBeValidated.eventType === 'sleep') {
        this.testCase.steps.push(this.stepToBeValidated);
        this.viewBlocked = false;
        this.stepToBeValidated = null;
      }

      else {
        this.socket.emit('action', { ...this.stepToBeValidated, isRecording: true });
      }

      this.validationModal = { ...this.validationModal, open: false, data: null };
    },

    async _saveTestCase() {
      if (!this.testCase.steps.length) {
        return this.$toast.add({ severity: 'error', summary: 'Error', detail: `You have no new steps to save.`, life: 5000 });
      }

      for (let i = 0; i < this.testCase.steps.length; i++) {
        this.testCase.steps[i].listIndex = i
      }

      const testStepsToStore = this.testCase.steps.filter((st) => !st._id);
      testStepsToStore.testCaseID = this.testCaseId
      testStepsToStore.projectID = this.thisProject._id

      if (!testStepsToStore.length) {
        return this.$toast.add({ severity: 'warn', summary: 'Warning', detail: `You don't have any new test steps to save!`, life: 5000 });
      }

      this.savingTestCase = true;

      const [data, err] = await this.saveTestCase(testStepsToStore);


      const [testSteps, testStepsErr] = await this.getTestStepsofEachTestCase(this.testCase._id);

      if (err || testStepsErr) {
        console.log({ testCaseSavingError: err, testStepsGetError: testStepsErr });
        this.$toast.add({ severity: 'error', summary: 'Test Case save failed', detail: err.msg, life: 5000 });
        return;
      } else {
        this.$toast.add({ severity: 'success', summary: 'Success', detail: 'New Steps Saved Successfully!', life: 5000 });
      }

      this.savingTestCase = false;

      this.testCase = {
        ...this.testCase,
        steps: testSteps,
      };
    },

    _updateResolution(d) {
      const data = d ? d : this.resolutionData;
      console.log(JSON.stringify(data));
      const elem = document.querySelector('.testEditor-rightPanel');
      this.deviceDimension = { height: data.height, width: data.width };

      if (elem) {
        const rect = elem.getBoundingClientRect();
        const parentHeight = rect.height;
        const parentWidth = rect.width;

        const deviceRatio = parseFloat(data.width) / parseFloat(data.height);

        let videoWidth = parseFloat(parentHeight) * deviceRatio;
        let videoHeight = parentHeight;
        // might be caused by device being landscape orientation
        if (videoWidth > parentWidth) {
          videoWidth = parentWidth;
          videoHeight = parseFloat(videoWidth / deviceRatio);
          this.videoDimention = { height: parseInt(videoHeight), width: parseInt(videoWidth) };
        } else this.videoDimention = { height: parseInt(videoHeight), width: parseInt(videoWidth) };
      }

      this.videoElement.style.height = `${parseInt(this.videoDimention.height)}px`;
      this.videoElement.style.width = `${parseInt(this.videoDimention.width)}px`;
    },

    async stopAutomation() {
      let [data, err] = await $stopNativeAutomation();
      this.isNativeRunOn = false;
      this.activeRunningStep = { _id: 'asd' };
      this.currentStepCnt = -1;
      this.stepToBeValidated = null;

      return [data, err];
    },

    async _onStartAutomation() {
      const stepsActivitySet = new Set();
      const stepsPackageSet = new Set();

      let projectActivity = this.thisProject.appInfo.activity;
      let projectPackageName = this.thisProject.appInfo.package;

      stepsActivitySet.add(projectActivity)
      stepsPackageSet.add(projectPackageName)

      for (let ind = 0; ind < this.testCase.steps.length; ind++) {
        const step = this.testCase.steps[ind];

        if (Boolean(step?.appInfo?.activity)) {
          stepsActivitySet.add(step?.appInfo?.activity)
        }

        if (Boolean(step?.appInfo?.package)) {
          stepsPackageSet.add(step?.appInfo?.package)
        }
      }

      const packages = Array.from(stepsPackageSet)
      const activities = Array.from(stepsActivitySet)

      console.log('_onStartAutomation CALLED');

      const notSavedTestSteps = this.testCase.steps.filter(st => !st._id);

      if (notSavedTestSteps.length) {
        this.showError("Please save all test-steps before executing!")
        return;
      }

      this.errorStatus = '';

      if (!this.socket || !this.socket?.connected) {
        this.agentStatus = { ...this.agentStatus, active: false, msg: 'Please install the Agent !' };
        console.log('%cSocket not connected ', 'background-color: darkblue; color: white; font-size: 1.3em');
        return;
      }

      if (this.isNativeRunOn) {
        const [data, err] = await this.stopAutomation();

        if (err) {
          console.log('failed to stop native automation');
        }

        return;
      }

      this.executedSteps = [];
      this.currentStepCnt = -1;
      this.activeRunningStep = null;

      const [data, err] = await $startNativeAutomation(this.thisProject._id, this.thisProject.appInfo.fileName, this.thisProject.type);



      const _pageContext = this;
      //! if stream is connected, disconnect stream
      if (this.streamSocket && this.streamSocket.close && this.streamSocket.readyState === 1) {
        try {
          console.log(this.streamSocket.close, 'closeeeeeeeeeeeeeeeeeeee ||||||||||||||||||||||||||||||||||?>>>>>>>>>>>>>>><<<<<<<<<')
          await this.streamSocket.close();
        } catch (e) {
          console.log('error', e);
        }
        this.streamSocket = null;
      }
      //! connecting stream
      try {
        this._updateResolution(data);
        _pageContext.jmuxer = new JMuxer({
          node: 'player',
          mode: 'video',
          flushingTime: 1,
          fps: 30,
          debug: false
        });
        // this.wsavc.connect(`ws://${process.env.VUE_APP_STREAM_SOCKET_HOST}:${process.env.VUE_APP_STREAM_SOCKET_PORT}/device`);
        this.streamSocket = new WebSocket(`ws://${process.env.VUE_APP_STREAM_SOCKET_HOST}:${process.env.VUE_APP_STREAM_SOCKET_PORT}/device`);
        this.streamConnected = true;

        this.streamSocket.binaryType = 'arraybuffer';
        this.streamSocket.addEventListener('message', function (event) {
          try {
            _pageContext.jmuxer.feed({
              video: new Uint8Array(event.data)
            });
          } catch (e) {
            console.log('error', e)
          }
          if ((_pageContext.videoElement.paused || _pageContext.videoElement.ended) && _pageContext.videoElement.readyState >= 2) {
            _pageContext.videoElement.play();
          }
        });
        this.streamSocket.addEventListener('close', function (event) {
          console.log('Socket Closed');
          _pageContext.streamConnected = false;
          _pageContext.jmuxer.destroy();
          _pageContext.jmuxer = null;
          _pageContext.videoElement.style.height = '0px';
          _pageContext.videoElement.style.width = '0px';
          _pageContext.streamSocket = null;
        });
        this.streamSocket.addEventListener('connect', function (event) {
          console.log('Socket Connected------------------------------------');
        });
        this.streamSocket.addEventListener('error', function (e) {
          console.log('Socket Error');
        });
      } catch (e) {
        console.log('error starting stream', e);
      }




      this.$refs.MobileNestedTestSteps.StepScrollTop()

      if (err) {
        this.isNativeRunOn = false;
        return console.log('_____________________', err);
      }

      this.isNativeRunOn = true;

      let el = this.testCase.steps.find((el) => el.validation && el.validation.shouldValidate === false);


      this.executionTime.start = Date.now()

      const [waitForAppRes, waitForAppError] = await waitForApp({ packages, activities });


      if (waitForAppError) {
        const [automationStopData, automationStopError] = await this.stopAutomation();

        console.log("⛑️ ⛑️ ⛑️ ⛑️ ⛑️ ⛑️ ⛑️ ⛑️ error", [automationStopData, automationStopError]);
        this.$toast.add({
          severity: 'error',
          summary: `Failed to reach expected Activities or Packages!`,
          detail: 'Check Activity and Package in Project Settings',
          life: 4000,
        });
      } else {
        this.currentStepCnt++;
        console.log("⛑️ ⛑️ ⛑️ ⛑️ ⛑️ ⛑️ ⛑️ ⛑️ success", waitForAppRes);
      }

    },

    _setup() {
      // this.streamSocket = new WebSocket();
      // this.wsavc = new WSAvcPlayer({ useWorker: true });
      // this.wsavc.AvcPlayer.canvas.style.height = `${parseInt(this.videoDimention.height)}px`;
      // this.wsavc.AvcPlayer.canvas.style.width = `${parseInt(this.videoDimention.width)}px`;

      // this.streamParent.appendChild();
      this.videoElement.style.height = `${parseInt(this.videoDimention.height)}px`;
      this.videoElement.style.width = `${parseInt(this.videoDimention.width)}px`;

      console.log('this.jmuxer------------------------------------------', this.jmuxer);

      this.socket = io(this.socketUrl);


      this.highlighterDiv.addEventListener('mousedown', (e) => {
        const overlayRect = this.highlighterDiv.getBoundingClientRect();
        this.shouldUpdateHighlights = false;
        if (!this.isRecording || this.isNativeRunOn) {
          this.shouldUpdateHighlights = true;
          return console.log(" NOT RECORDING 🚫 🚫 🚫 🚫 🚫 🚫");
        }

        const x = e.clientX - overlayRect.left; // Subtract the overlay's left offset from the clientX
        const y = e.clientY - overlayRect.top;
        this.swipeCord = rescale(x, y, this.videoDimention.width, this.videoDimention.height, this.deviceDimension);
      });

      this.highlighterDiv.addEventListener('mouseup', async (e) => {
        const eventTarget = e.target;
        if (this.viewBlocked) {
          this.shouldUpdateHighlights = true;
          return console.log(" VIEW IS BLOCKED 🚫 🚫 🚫 🚫 🚫 🚫");
        }

        // check if appium session is running before executing
        if (!this.isRecording || this.isNativeRunOn) {
          this.shouldUpdateHighlights = true;
          return console.log(" NOT RECORDING 🚫 🚫 🚫 🚫 🚫 🚫");
        }
        console.log('MOUSEUP =======================<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>|||||||||||||||||||||||||')
        this.viewBlocked = true;

        const overlayRect = this.highlighterDiv.getBoundingClientRect();
        const x = e.clientX - overlayRect.left; // Subtract the overlay's left offset from the clientX
        const y = e.clientY - overlayRect.top;
        let rescaled = rescale(x, y, this.videoDimention.width, this.videoDimention.height, this.deviceDimension);
        let step = null;
        // console.log({
        //     xdiff: Math.abs(this.swipeCord.x - rescaled.x),
        //     ydiff: Math.abs(this.swipeCord.y - rescaled.y),
        // });
        if (Math.abs(this.swipeCord.x - rescaled.x) >= 10 || Math.abs(this.swipeCord.y - rescaled.y) >= 10) {


          if (!this.openInsertVariableModal.data) {

            step = new Step({
              projectID: this.thisProject._id,
              testCaseID: this.testCase._id,
              listIndex: this.testCase.steps.length ? this.testCase.steps.length - 1 : 0,
              isRecording: this.isRecording,
              eventType: 'swipe',
              startCord: this.swipeCord,
              endCord: { x: rescaled.x, y: rescaled.y },
            }, 'swipe')

          }

          step.isRecording = this.isRecording;

          this.socket.emit('action', step);
          this.shouldUpdateHighlights = true;
          return;
        }

        const domTree = overlayHandler?.xmlDoc;
        console.log({ domTree });
        const xmlElement = overlayHandler.getElement(eventTarget);
        console.log({ eventTarget, xmlElement })
        const locators = overlayHandler.getLocators(eventTarget);
        console.log('LOCATORS!===================LOCATORS!', locators);

        let foundedElementValues = null;

        let elem = findElementXpathByCoordinates(
          'android',
          domTree,
          rescaled.x,
          rescaled.y,
          xmlElement,
          locators.xPath
        )
        elem.projectID = this.thisProject._id;
        elem.testCaseID = this.testCase._id;
        elem.listIndex = this.testCase.steps.length ? this.testCase.steps.length - 1 : 0;

        if (this.openInsertVariableModal.data) {
            step = new Step(elem, this.openInsertVariableModal.data.action)

            step.boundingRect.x = rescaled.x;
            step.boundingRect.y = rescaled.y;
            step.isRecording = this.isRecording;
            this.shouldUpdateHighlights = true;

            if (step.selectors.class.toLowerCase().includes('edittext')) {
              // console.log("CLICKED ON INPUT TEXT 🌂 🌂 🌂 🌂 🌂 🌂 🌂 🌂 🌂 🌂 🌂 🌂 🌂 🌂 🌂 🌂 ", step);
              this.openInsertVariableModal = {
                open: true,
                data: step
              }
            } else {
              this.openInsertVariableModal = {
                open: false,
                data: ''
              }
              // console.log("CLICKED OUTSIDE INPUT TEXT 🌂 🌂 🌂 🌂 🌂 🌂 🌂 🌂 🌂 🌂 🌂 🌂 🌂 🌂 ", step);
              this.$toast.add({ severity: 'warn', summary: 'Warning', detail: `The element you clicked is not an Input field !`, life: 5000 });
            }

            return;
        } else {
          foundedElementValues = new Step(elem, 'tap');
        }


        console.log("\n\n\n 🐼 🐼 🐼 🐼 🐼 🐼 🐼 🐼 🐼 🐼 🐼 🐼 ", foundedElementValues, "\n\n\n");

        if (!foundedElementValues.selectors.xPath && !foundedElementValues.selectors.class) {

          if (this.validationModal.data && this.validationModal.data.action !== 'custom_tap') {

            this.$toast.add({
              severity: 'warn',
              summary: `The Element you clicked is outside the ${this.thisProject.appInfo.fileName.split("_")[0]} app's boundary`,
              detail: '',
              life: 4000,
            });

            this.viewBlocked = false;

          } else {

            this.customTapModal = {
              ...this.customTapModal,
              open: true,
              element: foundedElementValues
            }

          }
          this.shouldUpdateHighlights = true;
          return;
        }


        if (this.validationModal.data) {

          if (this.validationModal.data.action === 'custom_tap') {

            console.log("🧚 🧚 🧚 🧚 🧚 🧚 🧚 🧚 🧚 🧚 🧚 🧚 ", foundedElementValues)
            foundedElementValues.validation.shouldValidate = true;
            foundedElementValues.validation.validationType = this.validationModal.data.action;
            this.socket.emit('action', { ...foundedElementValues, eventType: 'tap', isRecording: true });
            this.validationModal = { ...this.validationModal, open: false, data: null };
            // this.testCase.steps.push({ ...foundedElementValues, eventType: 'tap' });
            this.shouldUpdateHighlights = true;
            return;
          }


          this.validationModal = {
            ...this.validationModal,
            open: true,
          };
          this.stepToBeValidated = { ...foundedElementValues };
          this.shouldUpdateHighlights = true;
          return;
        }

        if (foundedElementValues.selectors.class.toLowerCase().includes('edittext')) {
          // foundedElementValues = {
          //     ...foundedElementValues,
          //     x: rescaled.x,
          //     y: rescaled.y,
          //     isRecording: this.isRecording,
          //     eventType: 'input',
          // };

          foundedElementValues.boundingRect.x = rescaled.x;
          foundedElementValues.boundingRect.y = rescaled.y;
          foundedElementValues.eventType = 'input';
          foundedElementValues.isRecording = this.isRecording;

          this.textViewModal = { ...this.textViewModal, open: true, textElement: foundedElementValues };
          this.shouldUpdateHighlights = true;
          return;
        } else {
          // foundedElementValues = {
          //     ...foundedElementValues,
          //     x: rescaled.x,
          //     y: rescaled.y,
          //     isRecording: this.isRecording,
          //     eventType: 'tap',
          // };

          foundedElementValues.boundingRect.x = rescaled.x;
          foundedElementValues.boundingRect.y = rescaled.y;
          foundedElementValues.isRecording = this.isRecording;
        }
        this.socket.emit('action', foundedElementValues);
        this.shouldUpdateHighlights = true;
      });

      this.socket.on('action-success', (testStep) => {
        console.log("🎙️ 🎙️ 🎙️ 🎙️ 🎙️ 🎙️ 🎙️ 🎙️ 🎙️ ", testStep);
        delete testStep.isRecording;
        let filename;

        switch (testStep.eventType) {
          case 'tap':
            filename = generateRandomString(15);
            testStep['fileId'] = filename;

            console.log(filename, testStep.fileId);

            this.testCase.steps.push(testStep);
            this.viewBlocked = false;
            this.setScreenShot(testStep);
            break;

          case 'swipe':
            // console.log("🎙️ 🎙️ 🎙️ 🎙️ 🎙️ 🎙️ 🎙️ 🎙️ 🎙️ ", testStep);
            this.testCase.steps.push(testStep);
            this.viewBlocked = false;
            break;

          case 'input':
            filename = generateRandomString(15);
            testStep['fileId'] = filename;

            console.log(filename, testStep.fileId);
            this.testCase.steps.push(testStep);
            this.viewBlocked = false;
            this.setScreenShot(testStep);
            break;

          case 'insert_variable':
            filename = generateRandomString(15);
            testStep['fileId'] = filename;

            console.log(filename, testStep.fileId);
            this.testCase.steps.push(testStep);
            this.viewBlocked = false;
            this.setScreenShot(testStep);
            break;

          default:
            break;
        }

      });

      this.socket.on('action-failed', (err) => {
        console.log(`action failed with ${err.message}`);
      });

      this.socket.on('executedStep', async (step) => {
        if (!this.isNativeRunOn) return;
        this.shouldUpdateHighlights = true;
        console.log('🇧🇷🇧🇷🇧🇷🇧🇷🇧🇷🇧🇷🇧🇷🇧🇷🇧🇷🇧🇷🇧🇷🇧🇷🇧🇷🇧🇷🇧🇷 ', step);
        this.executedSteps = [...this.executedSteps, step];

        if (this.currentStepCnt + 1 < this.testCase.steps.length) {
          this.currentStepCnt++

        } else if (this.currentStepCnt === this.testCase.steps.length - 1) {
          setTimeout(() => {
            this.activeRunningStep = { _id: '' };
          }, 1500);

          // delete appium session
          await new Promise((r) => setTimeout(r, 3000));
          await $stopNativeAutomation();
          this.executionTime.end = Date.now()
          this.isNativeRunOn = false;
          this.showSuccess(`Test Step completed`, ``);

          //* Set status after run

          let passedSteps = 0;
          let failedSteps = 0;

          for (let i = 0; i < this.executedSteps.length; i++) {
            let st = this.executedSteps[i];
            if (st.error_obj.error === true && st.error_obj.error_cause.length) {
              failedSteps++
            } else {
              passedSteps++
            }
          }

          if (failedSteps > 0) {
            this.errorStatus = 'failed';
          } else {
            this.errorStatus = 'passed';
          }

          this.currentStepCnt = -1;
        }
      });

      this.seekInterval = setInterval(() => {
        this.seekVideo();
      }, 5000);
    }
  },

  mixins: [variableMixin],
};
</script>

<style scoped lang='scss'>
.open-record-message {
  padding: 10px 37px;
  background: #00a0ee;
  color: #fff;

  a {
    text-decoration: underline;
    cursor: pointer;
    color: #000;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}</style>
