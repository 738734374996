import TestServiceRequest from '../utils/request/test_request';
// Jira API Section
/**
 * Fetch Jira project list
 * @param {*} projectId
 * @returns
 */
export const fetchProjectListFromJira = async (projectId) => {
    return await TestServiceRequest.get(`jira/project/list/${projectId}`).then((res) => res);
};
/**
 * Fetch Jira issue type list
 * @param {*} projectId
 * @returns
 */
export const fetchIssueTypeListFromJira = async (projectId) => {
    return await TestServiceRequest.get(`jira/project/list/${projectId}`).then((res) => res);
};
/**
 * Fetch Priority list from Jira
 * @param {*} projectId
 * @returns
 */
export const fetchPriorityListFromJira = async (projectId) => {
    return await TestServiceRequest.get(`jira/project/list/${projectId}`).then((res) => res);
};
/**
 * Fetch Label list from Jira
 * @param {*} projectId
 * @returns
 */
export const fetchLabelListFromJira = async (projectId) => {
    return await TestServiceRequest.get(`jira/project/list/${projectId}`).then((res) => res);
};
/**
 * Fetch Reporter list from Jira
 * @param {*} projectId
 * @returns
 */
export const fetchReporterListFromJira = async (projectId) => {
    return await TestServiceRequest.get(`jira/project/list/${projectId}`).then((res) => res);
};
/**
 * Fetch Linked Issues list from Jira
 * @param {*} projectId
 * @returns
 */
export const fetchLinkedIssuesFromJira = async (projectId) => {
    return await TestServiceRequest.get(`jira/project/list/${projectId}`).then((res) => res);
};
/**
 * Fetch Issue list from Jira
 * @param {*} projectId
 * @returns
 */
export const fetchIssuesFromJira = async (projectId) => {
    return await TestServiceRequest.get(`jira/project/list/${projectId}`).then((res) => res);
};
/**
 * Fetch Assingee list from Jira
 * @param {*} projectId
 * @returns
 */
export const fetchAssigneListFromJira = async (projectId) => {
    return await TestServiceRequest.get(`jira/project/list/${projectId}`).then((res) => res);
};
/**
 * Fetch fix version from Jira
 * @param {*} projectId
 * @returns
 */
export const fetchFixVersionListFromJira = async (projectId) => {
    return await TestServiceRequest.get(`jira/project/list/${projectId}`).then((res) => res);
};
/**
 * Fet
 * @param {*} projectId
 * @returns
 */
export const fetchEpicLinkListFromJira = async (projectId) => {
    return await TestServiceRequest.get(`jira/project/list/${projectId}`).then((res) => res);
};

export const fetchSprintListFromJira = async (projectId) => {
    return await TestServiceRequest.get(`jira/project/list/${projectId}`).then((res) => res);
};
