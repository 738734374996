import MobileServiceRequest, {
    insertNewMobileTestCase,
    fetchMobileTestCaseList,
    fetchMobileTestCase,
    updateMobileTestcase,
    deleteTestSteps,
    sendImgToS3,
    deleteTestcases,
    fetchCaseResultsFromRuns,
    updateTestStep,
} from '../../api/MobileTestCaseService';

const state ={
        data: {},
        mobileTestCaseList: [],
        mobileTestCase:{},
        isCaseResultListLoaded: false,
    }
const getters={
            data: state => state.data,
            mobileTestCaseList: state => state.mobileTestCaseList,
            mobileTestCase: state => state.mobileTestCase,
            isCaseResultListLoaded: state => state.isCaseResultListLoaded
        }
const actions = {
    //--- Test Case
    actionInsertNewMobileTestCase(state, newTestCaseObj) {
        let res = insertNewMobileTestCase(newTestCaseObj); //method from service
            return res;
        },
    // fetch Mobile Test Case List
    async actionGetMobileCaseList(state,bodyData) {
      if (!bodyData.duration) return;
        const data = await fetchMobileTestCaseList(bodyData)
        .then(resBody => {
            state.commit('FETCH_TEST_CASE_LIST', resBody.data.testCases);
        })
    },

    async saveTestCase(state, data) {
      let response = [null, null];

      try {
        const res = await MobileServiceRequest.post(`/test-steps/many`, data);
        response = [res.data, null];

      } catch (err) {
        response = [null, err.response];

      } finally {
        return response;
      }
    },

    async getTestCase(state, testCaseId) {
      let response = [null, null];

      try {
        const res = await MobileServiceRequest.get(`/test-cases/${testCaseId}/`);
        response = [res.data.testCase, null];

      } catch (err) {
        response = [null, err.response];

      } finally {
        console.table('getTestCase', {response});
        return response;
      }
    },

    async getTestStepsofEachTestCase(state, testCaseId) {
      let response = [null, null];

      try {
        const res = await MobileServiceRequest.get(`/test-cases/${testCaseId}/test-steps`);
        response = [res.data.testSteps, null];

      } catch (err) {
        response = [null, err.response];

      } finally {
        console.table('getTestStepsofEachTestCase', {response});
        return response;
      }
    },

   // fetch Mobile Test Case
   async actionGetMobileCase(state,testCaseId) {
    const data = await fetchMobileTestCase(testCaseId)
    .then(resBody => {
        state.commit('FETCH_TEST_CASE', resBody.data.testCase);
    }).catch(err => {
        state.commit('FETCH_TEST_CASE', []);
    });
},
    actionUpdateMobileTestcase(state, payload) {
        return updateMobileTestcase(payload);
    },

    async actionDeleteTestSteps(state, stepIds) {
        let response = [null, null];
        try {
          const res = await deleteTestSteps(stepIds);
          response = [res, null]

        } catch (err) {
          response = [null, err.response]

        } finally {
          return response;
        }
    },

    async actionSendTestStepsScreenshotToS3 (state, data) {
        const { prjId, caseId, testStep, filename } = data;
        let response = [null, null];
        const ssParse = () => {
            if ( testStep.screenshot && testStep.screenshot.executedImgUrl) {
                return testStep.screenshot.executedImgUrl

            } else if (testStep.screenshot && testStep.screenshot.elementScreenshot) {
                return testStep.screenshot.elementScreenshot

            } else {
                return null
            }
        }


        let formData = new FormData();
        let ss = ssParse();
        // const buffer = new Buffer.from(testStep.elementScreenshot.replace(/^data:application\/\w+;base64,/, ''), 'base64');


        try {
            if (!ss) {
                let uri = '/screenshots/public/error/screenshot-not-found.png'
                response = [{ uri, fileName: filename }, null]
            } else {
                const buff = await fetch(ss, { method: 'GET' })
                const blob = await buff.blob()
                formData.append('screenshot', blob, `${filename}.png`);

                const res = await sendImgToS3(prjId, caseId, formData);
                response = [res, null]
            }

        } catch (err) {
          response = [null, err.response]

        } finally {
          return response;
        }
    },

    ActionDeleteTestcase(state, reqDataArray) {
        let res = deleteTestcases(reqDataArray); //method from service
        return res;
    },
    async actionGetCaseResultsFromRuns(state, testCaseID) {
      return fetchCaseResultsFromRuns(testCaseID)
      .then(resBody => {
          if (resBody.status === 200) {
              state.commit('IS_CASE_RESULT_FETCH_LOADED', true);
              return resBody;
          } else {
              state.commit('IS_CASE_RESULT_FETCH_LOADED', true);
              return resBody;
          }
      })
      .catch(err => {
          state.commit('IS_CASE_RESULT_FETCH_LOADED', true);
          return err;
      });
    },
    actionUpdateTestStep(state, obj) {
      console.log('testStepId--', obj);
      let res = updateTestStep(obj.stepId, obj.step); //method from service
      return res;
    },

    }
const mutations = {
    SUBIT_NEW_TEST_CASE(state, newTestCase) {
        state.mobileTestCaseList.unshift(newTestCase);
    },
    // SET_DATA(state, data) {
    //     state.data = data;
    // },
    FETCH_TEST_CASE_LIST(state, cases) {
        state.mobileTestCaseList = cases;
    },
    FETCH_TEST_CASE(state, mobileTestCase) {
        state.mobileTestCase = mobileTestCase;
    },
    IS_CASE_RESULT_FETCH_LOADED(state, loaderStatus) {
      state.isCaseResultListLoaded = loaderStatus;
    },
}
export default {
    namespaced: true,
    getters,
    state,
    actions,
    mutations
}
