<template>
  <div>
    <Dialog :draggable="false" header="Properties" v-model:visible="/* eslint-disable vue/no-mutating-props */displayConfirmation"
            :style="{ width: '20.9vw', border: 'none !important' }" :modal="true" @hide="$emit('hide')">
      <!-- <pre>{{ properties }}</pre> -->

      <div class="p-grid p-fluid">
        <div class="p-col-12 m-0 p-0">
          <div class="properties-input-wrapper">
            <div class="p-field input-description">
              <label>Test Description</label>
              <!-- @keyup="$emit('handleChange', customValidationList.ValidateHtmlAttribute.action, 'description', $event)" -->
              <InputText type="text" placeholder="Enter Test Description" :disabled="true"
                @keyup="$emit('handleChange', properties.action, 'description', $event)"
                         :value="properties.description"/>
            </div>
          </div>
        </div>

        <div class="p-col-12 m-0 p-0" v-if="properties.action === customValidationList.ValidateHtmlAttribute.action">
          <div class="properties-input-wrapper">
            <div class="p-field input-description">
              <label for="fieldId">Attribute Name</label>
              <InputText placeholder="e.g. type, class, placeholder" type="text"
                         @keyup="$emit('handleChange', customValidationList.ValidateHtmlAttribute.action, 'attr_property_name', $event)"
                         :value="properties.attr_property.property"/>
            </div>

            <div class="p-field input-description">
              <label>Expected Value</label>
              <InputText placeholder="e.g. text, some-name, value" type="text"
                         @keyup="$emit('handleChange', customValidationList.ValidateHtmlAttribute.action, 'attr_expected_value', $event)"
                         :value="properties.attr_property.expected"/>
            </div>
          </div>
        </div>

        <div class="p-col-12 m-0 p-0" v-if="properties.action == customValidationList.TextToAssign.action">
          <div class="properties-input-wrapper">
            <div class="p-field input-description">
              <label>Expected Text</label>
              <InputText type="text"
                         @keyup="$emit('handleChange', customValidationList.TextToAssign.action, 'text', $event)"
                         :value="properties.text"/>
            </div>
          </div>
        </div>
        <div class="p-col-12 m-0 p-0" v-if="properties.action == customValidationList.URL_Validation.action">
          <div class="properties-input-wrapper">
            <div class="p-field input-description">
              <label>URL:</label>
              <InputText placeholder="https://www.google.com/" type="text"
                         @keyup="$emit('handleChange', customValidationList.URL_Validation.action, 'url', $event)"
                         :value="properties.url"/>
            </div>
          </div>
        </div>

        <div class="p-col-12 m-0 p-0" v-if="properties.action == domEvents.INPUT">
          <div class="properties-input-wrapper">
            <div class="p-field input-description">
              <label>Value:</label>
              <InputText placeholder="" type="text" @input="$emit('handleChange', domEvents.INPUT, 'value', $event)"
                         :value="properties.value"/>
            </div>
          </div>
        </div>

        <!-- SMS_Validation  -->
        <!-- <div class="p-col-12 m-0 p-0" v-if="properties.action == customValidationList.SMS_Validation.action">
            <div class="properties-input-wrapper">
                <div class="p-field input-description">
                    <label>Sms From:</label>
                    <InputText placeholder="+16123123123" type="text" @input="$emit('handleChange', customValidationList.SMS_Validation.action, 'sms_from', $event)" :value="properties.sms_from" />
                </div>
            </div>
        </div> -->

        <!-- SMS_Validation  -->
        <!-- GENERATE_PHONE_NUMBER -->
        <div class="p-col-12 m-0 p-0" v-if="properties.action == customValidationList.GENERATE_PHONE_NUMBER.action">
          <div class="properties-input-wrapper">
            <div class="p-field input-description">
              <label>Choose Numbers: </label>
              <Dropdown :options="phoneNumberList" optionLabel="phoneNumber" v-model="selectedPhoneNumber"
                        @keyup="$emit('handleChange', customValidationList.GENERATE_PHONE_NUMBER.action, 'phoneNumber', selectedPhoneNumber)"/>
            </div>
          </div>
        </div>

        <!-- GENERATE_PHONE_NUMBER -->
        <!-- SMS_Validation  -->
        <div class="p-col-12 m-0 p-0" v-if="properties.action == customValidationList.OTP_VALIDATION.action">
          <div class="properties-input-wrapper">
            <div class="p-field input-description">
              <label>Choose Numbers:</label>
              <Dropdown :options="phoneNumberList" optionLabel="phoneNumber" v-model="selectedPhoneNumber"
                        @keyup="$emit('handleChange', customValidationList.OTP_VALIDATION.action, 'phoneNumber', selectedPhoneNumber)"/>
            </div>
          </div>
        </div>
        <!-- SMS_Validation  -->
        <!-- Navigate  -->
        <div class="p-col-12 m-0 p-0" v-if="properties.action == customValidationList.Navigate.action">
          <div class="properties-input-wrapper">
            <div class="p-field input-description">
              <label>URL:</label>
              <InputText placeholder="https://www.google.com/" type="text"
                         @keyup="$emit('handleChange', customValidationList.Navigate.action, 'url', $event)"
                         :value="properties.url"/>
            </div>
          </div>
        </div>
        <!-- Navigate  -->
        <div class="p-col-12 m-0 p-0" v-else-if="properties.action == customValidationList.ValidateCssProperty.action">
          <div class="properties-input-wrapper">
            <div class="p-field input-description">
              <label>Property Name</label>
              <InputText
                  type="text"
                  @keyup="$emit('handleChange', customValidationList.ValidateCssProperty.action, 'css_property_name', $event)"
                  :value="properties.css_property.property"
                  placeholder="e.g. color, font-family, background-color"
              />
            </div>

            <div class="p-field input-description">
              <label>Expected Value</label>
              <InputText placeholder="e.g. green, roboto, #000000" type="text"
                         @keyup="$emit('handleChange', customValidationList.ValidateCssProperty.action, 'css_expected_value', $event)"
                         :value="properties.css_property.expected"/>
            </div>
          </div>
        </div>
        <div class="p-col-12 m-0 p-0" v-else-if="properties.action == customValidationList.Sleep.action">
          <div class="properties-input-wrapper">
            <div class="p-field input-description">
              <label>Time(ms)</label>
              <InputText type="number"
                         @keyup="$emit('handleChange', customValidationList.Sleep.action, 'sleep_for', $event)"
                         :value="properties.sleep_for"/>
            </div>
          </div>
        </div>

        <div class="p-col-12 m-0 p-0" :style="{ display: 'flex', flexFlow: 'row' }"
             v-else-if="properties.action == domEvents.SCROLL">
          <div class="properties-input-wrapper">
            <div class="p-field input-description">
              <label>Scroll(X)</label>
              <InputText type="number" @keyup="handleScrollDataChange(domEvents.SCROLL, 'x', $event)" :value="properties.coordinates.x"/>
            </div>
          </div>
          <div class="properties-input-wrapper">
            <div class="p-field input-description">
              <label>Scroll(Y)</label>
              <InputText type="number" @keyup="handleScrollDataChange(domEvents.SCROLL, 'y', $event)" :value="properties.coordinates.y"/>
            </div>
          </div>
        </div>

        <div class="p-col-12 m-0 p-0" :style="{ display: 'flex', flexFlow: 'row' }"
             v-else-if="properties.action == domEvents.RESIZE">
          <div class="properties-input-wrapper">
            <div class="p-field input-description">
              <label>Resize(X)</label>
              <InputText type="number" @keyup="$emit('handleChange', domEvents.RESIZE, 'width', $event)"
                         :value="properties.screen_size.width"/>
            </div>
          </div>
          <div class="properties-input-wrapper">
            <div class="p-field input-description">
              <label>Resize(Y)</label>
              <InputText type="number" @keyup="$emit('handleChange', domEvents.RESIZE, 'height', $event)"
                         :value="properties.screen_size.height"/>
            </div>
          </div>
        </div>

        <div class="p-col-12 m-0 p-0">
          <div class="properties-input-wrapper">
            <div class="p-field input-description">
              <label>When This Step Fails</label>
              <Dropdown :options="testStepsFailOptions" optionLabel="value" v-model="testStepsFailReason"
                        placeholder="Choose When Steps Fail"
                        @keyup="$emit('handleChange', 'fail_causes', 'fail_causes', testStepsFailReason)"/>
              <!-- <InputText v-model="testDescription" type="text" placeholder="Enter Test Description" /> -->
            </div>
          </div>
        </div>

        <!-- <div class="p-col-12 m-0 p-0">
            <div class="properties-input-wrapper">
                <div class="p-field input-description">
                    <label>Test in TestRail</label>
                    <Dropdown :options="teststepsOptions" optionLabel="name" v-model="selectedTeststeps" placeholder="Select Test Case" />
                </div>
            </div>
        </div> -->

        <div class="p-col-12 m-0 p-0">
          <div class="properties-input-wrapper no-border">
            <!-- <div class="properties-override-timeout">
              <Checkbox v-model="override" :binary="true"
                        @keyup="$emit('handleChange', 'override_timeout', 'override_timeout', $event)"/>
              <label>Override Timeout</label>
            </div> -->

            <!-- <div class="properties-sharesteps" v-if="properties.type === 'group' && properties.level === 1"> -->
            <div class="properties-sharesteps"
                 v-if="properties.type === 'group' && properties.is_shareable === false && properties.parent == null">
              <Checkbox v-model="isStepShared" :binary="true"/>
              <label>Shared Group</label>
            </div>
          </div>
        </div>

        <div class="p-col-12 m-0 p-0">
          <div class="properties-input-wrapper border-none">
            <p class="target-lable" v-if="properties.parent_nodes.length">Target Element</p>

            <div class="target-element-screen" v-if="properties.parent_nodes.length"
                 :style="[!properties.screenshot_src ? 'margin-bottom: 32px' : '']">
              <img alt="Logo" :src="properties.screenshot_src" v-if="properties.screenshot_src"/>
              <div class="view-locator-button" @click="ViewLocatorDisplay = true"
                   :style="[!properties.screenshot_src ? 'top: -7px' : '']">View locator
              </div>
            </div>
            <Button label="Save Changes" class="p-button-primary save-changes-button" autofocus
                    @click="onSubmit"/>
          </div>
        </div>
      </div>
    </Dialog>

    <Dialog :draggable="false" header="View Locator" class="custom-properties-dialog" v-model:visible="ViewLocatorDisplay"
            :style="{ width: '20.9vw', height: '720px' }" :modal="true" :position="right">
      <Accordion :multiple="true" class="custom-properties-accordian">
        <AccordionTab :header="eachNode.tagName" :key="k" v-for="(eachNode, k) in properties.parent_nodes">
          <div class="element">
            <label>Id</label>
            <p>{{ eachNode.id }}</p>
            <template v-for="className in eachNode.classList">
              <label>Class</label>
              <p>{{ className }}</p>
            </template>

            <label>Tag</label>
            <p>{{ eachNode.tagName }}</p>
          </div>
        </AccordionTab>
      </Accordion>
    </Dialog>
  </div>
</template>
<script>
import customValidationList from '../../utils/custom_validation_list';
import PropertiesVue from './Properties.vue';
import {phoneNumberList} from '../../api/smsService';
import domEvents from '../../utils/dom-events-to-record';
import {watch} from 'vue';

export default {
  components: {
    PropertiesVue,
  },
  name: 'PropertiesDialog',
  props: {
    displayConfirmation: {
      type: Boolean,
      required: true,
    },
    properties: {
      type: Object,
      required: true,
    },
  },
  beforeCreate() {
  },
  data() {
    return {
      customValidationList,
      domEvents,
      ViewLocatorDisplay: false,
      testDescription: this.properties.description,
      resolutionOptions: [
        {name: 'FHD 1920X1080', value: '1920x1080'},
        {name: 'Laptop 1366X768', value: '1366x768'},
        {name: 'Tablet', value: '768x435'},
      ],
      testCaseResolution: '',

      testStepsFailOptions: [
        // {
        //     id: 1,
        //     value: 'Mark error & stop',
        // },
        {
          id: 2,
          value: 'Mark error & continue',
        },
        // {
        //     id: 3,
        //     value: 'Mark warning & continue',
        // },
      ],

      testStepsFailReason: {
        id: 2,
        value: 'Mark error & continue',
      },

      checkedXaxis: false,
      checkedYaxis: false,

      teststepsOptions: [
        {name: 'A', value: 'A'},
        {name: 'B', value: 'B'},
        {name: 'C', value: 'C'},
      ],
      selectedTeststeps: {},

      override: false,
      isStepShared: false,
      phoneNumberList: [],
      selectedPhoneNumber: null,
    };
  },
  methods: {
    onSubmit() {
      const pattern = /(http(s)?):\/\/.+/
      if (this.properties.url && !pattern.test(this.properties.url)) {
        this.properties.url = "http://" + this.properties.url;
      }
      this.$emit('onPropertySaveChanges', this.properties, this.isStepShared)
    },
    handleScrollDataChange(type, coor, e) {
      this.$emit('handleChange', type, coor, e)
    }
  },

  mounted() {
    phoneNumberList()
        .then((data) => {
          if (data.length > 0) {
            this.phoneNumberList = data;
          }
        })
        .catch(console.error);
    watch(
        () => this.properties,
        () => {
          this.testStepsFailReason = this.properties.fail_causes;
        },
        {
          immediate: true,
          deep: true,
        }
    );
    watch(
        () => this.properties,
        () => {
          this.override = this.properties.override_timeout;
        },
        {
          immediate: true,
          deep: true,
        }
    );

    watch(
        () => this.displayConfirmation,
        () => {
          var vm = this;

          if (this.displayConfirmation) {
            if (vm.properties.value && vm.properties.value.length > 0) {
              this.selectedPhoneNumber = this.phoneNumberList.find((p) => {
                return String(p.phoneNumber).includes(vm.properties.value);
              });
            }
          } else {
            vm.selectedPhoneNumber = {};
          }
        },
        {
          immediate: true,
          deep: true,
        }
    );
  },
  unmounted() {
    this.phoneNumberList = [];
    console.log('unmounted');
    // this.$destroy();
  },
};
</script>
