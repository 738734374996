/*
    Freshdesk widget implemented!!
    Embeded code from freshdesk.
*/
window.fwSettings = {
    widget_id: 151000001248,
};
!(function () {
    if ('function' != typeof window.FreshworksWidget) {
        var n = function () {
            n.q.push(arguments);
        };
        (n.q = []), (window.FreshworksWidget = n);
    }
})();

/*
    Freshdesk widget's embed code!!
*/
let script = document.createElement('script')
script.type='text/javascript' 
script.src = 'https://widget.freshworks.com/widgets/151000001248.js'
script.defer = true; 
script.async = true;
document.head.appendChild(script)