<template>
  <div class="custom-chip p-fluid">
    <div class="input-description">
      <label for="email">{{labelName}}</label>
      <InputText :class="fieldError.msg ? 'p-error' : ''" type="text" v-model="tagname" :placeholder="placeholder" @keydown.enter="handleEnter" @blur="tagsBlured"/>
      <small class="p-invalid" >{{ fieldError.msg ? fieldError.msg : '' }}</small>
    </div>
    <div class="custom-chip-list">
      <Chip :label="tag" v-for="tag in tags" :key="tag" removable @remove="onRemoveTag"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TjCustomChipsMobile',
  props: {
    tagList: {
      type: Array,
      required: true,
    },
    labelName: {
      type: String,
      required: false,
      default: 'Tag Name'
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Enter Tag Name'
    },
    type: {
      type: String,
      required: false,
      default: 'text'
    },
  },

  data() {
    return {
      emailRegex: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
      tagname: null,
      fieldError: {},
      tags: this.tagList || [],
    };
  },
  watch: {
    tagname(tag) {
      this.fieldError = {};
    }
  },
  methods: {
    handleEnter() {
      if (this.tagname.length > 0 && this.tagname.trim().length != 0) {
        if (!this.tags.includes(this.tagname)) {
          switch (this.type) {
            case 'email':
              if (!this.tagname.match(this.emailRegex)) {
                this.fieldError = {msg: `Invalid Email Address !`};
                return;
              }

              this.tags = [...this.tags, this.tagname];
              this.tagname = '';
              this.$emit('onUpdateChipsList', this.tags);

              break;

            default:
              this.tags = [...this.tags, this.tagname];
              this.tagname = '';
              this.$emit('onUpdateChipsList', this.tags);
              break;
          }
        } else {
          this.$toast.add({severity: 'warn', detail: `${this.type} already exist.`, life: 3000});
        }
      } else {
        this.tagname = '';
      }
    },
    tagsBlured(event) {
      console.log(event.target.value);
      if (event.target.value?.length > 0 && event.target.value?.trim().length != 0) {
        if (!this.tags.includes(event.target.value)) {
          switch (this.type) {
            case 'email':
              if (!event.target.value.match(this.emailRegex)) {
                this.fieldError = {msg: `Invalid Email Address !`};
                return;
              }

              this.tags = [...this.tags, event.target.value];
              event.target.value = '';
              this.$emit('onUpdateChipsList', this.tags);

              break;

            default:
              this.tags = [...this.tags, event.target.value];
              event.target.value = '';
              this.$emit('onUpdateChipsList', this.tags);
              break;
          }
        } else {
          this.$toast.add({severity: 'warn', detail: `${this.type} already exist.`, life: 3000});
        }
      }
    },
    onRemoveTag(event, label) {
      this.tags = this.tags.filter(tag => tag !== label);
      this.$emit('onUpdateChipsList', this.tags);
    },
  },
};
</script>

<style></style>
