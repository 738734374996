<template>
  <div class="test-cases-top-section-container">
    <div class="p-grid p-mt-0 p-mr-0 p-ml-0">
      <div class="card p-px-0 p-py-0">
        <div class="test-cases-top-section p-grid p-mt-0 p-mr-0 p-ml-0">
          <div class="left-section">
            <div class="test-cases-detail">
              <h5>Test Cases</h5>
              <p v-if="testCaseCount > 1">{{ testCaseCount }} Cases</p>
              <p v-else>{{ testCaseCount ? testCaseCount : 0 }} Case</p>
            </div>
            <div class="search-box">
              <span class="p-input-icon-right">
                  <i class="pi pi-search"/>
                  <InputText type="text" v-model="searchTestcase" placeholder="Search Test"/>
              </span>
            </div>
            <div class="filter-options">
              <p>Filter By Last</p>
              <div class="duration-selector">
                <Dropdown v-model="selectedDays" :options="durationDays" optionValue="id" optionLabel="name"
                          style="width: 100%" placeholder="Search Duration"/>
              </div>
              <div class="duration-selector">
                <MultiSelect v-model="selectedPriorities" display="chip" :options="priorities" optionLabel="name" placeholder="Select Priorities"
                  :maxSelectedLabels="3" style="width: 100%" class="w-full md:w-20rem" />
              </div>
              <div class="duration-selector">
                  <MultiSelect v-model="selectedCategories" display="chip" :options="categories" optionLabel="name" placeholder="Select Categories"
                  :maxSelectedLabels="3" style="width: 100%" class="w-full md:w-20rem" />
              </div>
            </div>
          </div>

          <div class="test-cases-actions">
<!--            <div class="button-action">-->
<!--              <Button icon="fas fa-folder" class="p-button-rounded p-button-outlined" title="Create group"/>-->
<!--            </div>-->
<!--            <div class="button-action">-->
<!--              <Button icon="fas fa-copy" class="p-button-rounded p-button-outlined" title="Clone" />-->
<!--            </div>-->
            <div class="button-action">
              <Button icon="fas fa-play" class="p-button-rounded p-button-outlined"  title="Create test run" @click="clickFrTestRunModalVisible" :disabled="!mobileTestCaseList || !mobileTestCaseList.length"/>
            </div>
            <div class="button-action">
              <Button icon="fas fa-trash" class="p-button-rounded p-button-outlined" title="Delete" @click="clickRemoveTestcases" :disabled="!selectedTestCases.length"/>
            </div>
            <div>
              <Button class="newTest" label="NEW TEST" icon="fas fa-plus" @click="createNewTestCase" />
            </div>
          </div>

        </div>

          <Dialog :draggable="false" header="Create New Test Run" v-model:visible="displayCreateNewTestRun" :style="{ width: '40.83vw' }"
                  :modal="true">
                  <TestRunCreate @displayOffCreateTestRun="displayOffCreateTestRun" :tableCheckedCasesForRun="caseForRun" />
          </Dialog>

          <Dialog :draggable="false" header="Header" v-model:visible="visibleDeleteModal" closable :modal="true"
                  class="delete-confirmation-modal">
            <template #header>
              <img src="/assets/warning-icon.svg" alt="warning-icon.svg"/>
              <p>Delete Confirmation</p>
            </template>

            Are you sure you want to delete?

            <template #footer>
              <Button label="No" class="p-button-outlined" @click="visibleDeleteModal = !visibleDeleteModal"/>
              <Button label="Yes" class="p-button-primary" @click="clickDeleteTestcase"/>
            </template>
          </Dialog>

        <div>
          <MobileTestCaseTable
          @updateTag="testCaseUpdated"
          @deleteSingleTestCase="deleteSingleTestCase"
          @redirectToTestEditor="redirectToEditor"
          @createNewTestRun="createRunWithSingleCase"
          @selectedTestCaseIds="testCaseIdsSelected"
          :searchTestcase="searchTestcase"
          :selectedPriorities="selectedPriorities"
          :selectedCategories="selectedCategories"
          ref="testCaseTableRef" />
        </div>

        <!-- <Loader v-else/> -->
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import { getSelectedCurrentProjectId } from '../../utils/localstorage';
import MobileTestCaseTable from './testCaseComponents/MobileTestCaseTable.vue';
import TestRunCreate from '../components/testRun/TestRunCreate.vue';
import { dateComparison } from '@/utils/helpers';

export default {
  components: {
    MobileTestCaseTable,
    TestRunCreate,
  },
  name: 'TestCaseListPageMobile',
  data() {
    return {
        testCaseCount: 0,
        searchTestcase: '',
        selectedDays: 'all',
        durationDays: [
            {name: 'Last 24 hours', id: '1'},
            {name: 'Last 7 Days', id: '7'},
            {name: 'Last 30 Days', id: '30'},
            {name: 'Last 3 Months', id: '90'},
            {name: 'All', id: 'all'},
        ],
        flatTestCaseList: [],
        selectedTestCases: [],
        caseForRun: [],
        displayCreateNewTestRun: false,
        visibleDeleteModal: false,
        subscriptionIsExpired: false,
        selectedCategories: null,
        categories: [],
        selectedPriorities: null,
        priorities: [
                {
                    name: 'Critical',
                    code: 1,
                },
                {
                    name: 'High',
                    code: 2,
                },
                {
                    name: 'Medium',
                    code: 3,
                },
                {
                    name: 'Low',
                    code: 4,
                },
            ],
    }
  },

  created() {
    this.fetchMobileTestCaseList();
    this.getCategories(this.selectedProjectId).then(res => {
        if (res?.status === 200 && res?.data?.length) {
            this.categories = res.data;
        }
    })
  },

  mounted() {
      // this.subscriptionIsExpired = dateComparison(this.subscriptionPlan.consumed_plan.validity_expire);
  },

  methods: {
    ...mapActions({
      getMobileTestCaseList: 'mobileTestCases/actionGetMobileCaseList',
      handleSelectedTestCase: 'mobileTestCases/handleSelectedTestCase',
      actionInsertNewTestCase: 'mobileTestCases/actionInsertNewTestCase',
      deleteTestcase: 'mobileTestCases/ActionDeleteTestcase',
      getCategories: 'category/actionGetCategories',
    }),

    showSuccess(summary, msgDetail) {
      this.$toast.add({severity: 'success', summary: summary, detail: msgDetail, life: 5000});
    },

    showErrorMsg(msgDetail) {
      this.$toast.add({severity: 'error', summary: 'Error Message', detail: msgDetail, life: 5000});
    },
    showWarningMsg(msgDetail) {
      this.$toast.add({severity: 'warn', summary: 'Warn Message', detail: msgDetail, life: 5000});
    },

    testCaseIdsSelected(casesArr) {
      this.selectedTestCases = casesArr;
    },

    redirectToEditor(testCase) {
    },

    testCaseUpdated(testCase) {
      this.fetchMobileTestCaseList()
    },

    async deleteSingleTestCase(testCase) {
      this.fetchMobileTestCaseList()
    },


    fetchMobileTestCaseList() {
      if (getSelectedCurrentProjectId()) {
        let reqBody = {
          projectID: getSelectedCurrentProjectId(),
          duration: this.selectedDays
        };
        this.getMobileTestCaseList(reqBody);
      } else {
        console.log('Please create or select project first.')
        // this.showWarningMsg('Please select project first.');
      }
    },
    clickFrTestRunModalVisible() {
        let selectedCasesForRun = []
        if (this.subscriptionIsExpired) {
          this.displayCreateNewTestRun = false;
          this.checkSubscription();
          return;
        } else if (this.loggedInUserInfo.subscription.mobileConfig.runLimit && Number(this.subscriptionPlan.consumed_plan.test_run_limit) >= Number(this.loggedInUserInfo.subscription.mobileConfig.runLimit)) {
            this.$toast.add({
                severity: 'warn',
                summary: 'Warning',
                detail: 'Testrun limit reached',
                life: 3000,
            });
            return;
        }

        if (this.selectedTestCases.length) {
            for (let x = 0; x < this.selectedTestCases.length; x++) {
                let casId = this.selectedTestCases[x]._id

                for (let i = 0; i < this.mobileTestCaseList.length; i++) {
                    let cas = this.mobileTestCaseList[i]

                    if (casId == cas._id) {
                        selectedCasesForRun.push(cas)
                    }
                }
            }
        }

        this.caseForRun = selectedCasesForRun
        this.displayCreateNewTestRun = true;

    },
    createNewTestCase() {
      if (this.subscriptionIsExpired) {
        this.checkSubscription();
        return;
      } else if (Boolean(this.loggedInUserInfo.subscription.mobileConfig.caseLimit) && (Number(this.subscriptionPlan.consumed_plan.mobile_test_case_limit) >= Number(this.loggedInUserInfo.subscription.mobileConfig.caseLimit))) {
        return this.$toast.add({
          severity: 'warn',
          summary: 'Warning',
          detail: 'Testcase limit reached',
          life: 3000,
        });
      } else {
        return this.$router.push('/create/new-testcase-mobile')
      }
    },

    checkSubscription(){
        this.showErrorMsg('Your free trial has expired. Please renew or upgrade your subscription package.');
    },

    createRunWithSingleCase(eachCase) {
      if (this.subscriptionIsExpired) {
          this.checkSubscription();
          return;
      } else if (Boolean(this.loggedInUserInfo.subscription.mobileConfig.runLimit) && Number(this.subscriptionPlan.consumed_plan.test_run_limit) >= Number(this.loggedInUserInfo.subscription.mobileConfig.runLimit)) {
            this.$toast.add({
                severity: 'warn',
                summary: 'Warning',
                detail: 'Testrun limit reached',
                life: 3000,
            });
            return;
        }

        this.caseForRun = [eachCase]
        this.displayCreateNewTestRun = true
    },

    displayOffCreateTestRun() {
        this.displayCreateNewTestRun = !this.displayCreateNewTestRun;
        this.caseForRun = [];
        this.$router.push('/test-runs-mobile')
    },
    resetCheckedBox() {
      this.$refs.testCaseTableRef.selectedCaseIds = [];
    },
    clickRemoveTestcases() {
        if (this.subscriptionIsExpired) {
          this.checkSubscription();
          return;
        }
      this.visibleDeleteModal = !this.visibleDeleteModal;
    },
    clickDeleteTestcase() {
      const caseIds = this.selectedTestCases.map(cas => cas._id)

      this.deleteTestcase(caseIds)
          .then((resBody) => {
              // if (resBody.status && resBody.status.code == 200) {
              this.showSuccess('Success', 'Test case deleted successfully');
              this.visibleDeleteModal = false;
              this.selectedTestCases = [];
              this.fetchMobileTestCaseList();
              this.resetCheckedBox();
            // } else {
            //   this.showErrorMsg('Test Case delete Unsuccessful.');
            // }
          })
          .catch((err) => {
            this.showErrorMsg('Something went wrong!');
            console.log('delete-res-err', err.response);
          });
    },

  },

  computed: {
    ...mapGetters({
      mobileTestCaseList: 'mobileTestCases/mobileTestCaseList',
      subscriptionPlan: 'auth/subscriptionPlan',
      loggedInUserInfo: 'auth/loggedInUserInfo',
      selectedProjectId: 'project/projectID',
    }),
    testCaseCount() {
      this.flatTestCaseList = [];
      if (this.mobileTestCaseList && this.mobileTestCaseList.length > 0) {
        this.mobileTestCaseList.map((testCase) => {
          if (testCase.type === 'group') {
            if (testCase.children !== undefined) {
              testCase.children.map((item) => this.flatTestCaseList.push(item));
            }
          } else {
            this.flatTestCaseList.push(testCase);
          }
        });
        return this.flatTestCaseList.length;
      }
    },
  },
  watch: {
    selectedDays(val) {
      this.selectedDays = val;
      this.fetchMobileTestCaseList()
    }
  },
};
</script>

<style lang="scss">
.delete_testcases_modal {
  min-width: 20vw;

  .p-dialog-header {
    max-height: 65px;
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid #c3c9cf;
    padding-top: 1em;
    padding-bottom: 0;
    font-weight: 400;
    font-size: 20px;
    color: #282828;

    img {
      margin-top: 4px;
    }

    p {
      margin-right: auto;
      margin-left: 10px;
    }
  }

  .p-dialog-content {
    padding-top: 1em;
    text-align: center;
  }

  .p-dialog-footer {
    display: flex;
    justify-content: space-around;

    button {
      width: 8.1875em;
      font-weight: 700;
      font-size: 14px;
    }
  }
}
</style>
