/* eslint-disable no-unused-vars */
import TestServiceRequest from '../utils/request/test_request';
import MobileServiceRequest from '../utils/request/mobile_request';
import SeleniumRequest from '@/utils/request/selenium_request';

export const insertNewIssue = async (data) => {
    return await TestServiceRequest.post(`/v2/issues`, data);
};
export const getIssues = async (projectId) => {
    return await TestServiceRequest.get(`/v2/issues/projects/${projectId}`);
        // .then(res => {
        //     return res;
        // })
        // .catch(error => {
        //     console.log('TestCaseService--getIssues--Error', error);
        // });
};
export const getIssueById = async (id) => {
    return await TestServiceRequest.delete(`/v2/issues/${id}`);
};

export const assignIssueInTestCase = async (data) => {
    if ( data.native_test_run_id?.length) {
        return await TestServiceRequest.put(`/v2/native-test-cases/${data._id}`, data);
    } else if( data.cloud_test_run_id?.length) {
        return await SeleniumRequest.put(`api/v2/cloud-test-cases/${data._id}`, data);
    }
}

export const assignIssueInMobileTestCase = async (data) => {
    return await MobileServiceRequest.put(`/executed-cases/${data.id}`, data);
}
export const deleteIssueById = async (id) => {
    return await TestServiceRequest.delete(`/v2/issues/${id}`);
};
export const updateIssueById = async (data) => {
    return await TestServiceRequest.put(`/v2/issues/${data._id}`, data);
};