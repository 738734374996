import TestServiceRequest, {fetchWholeSettings} from '@/api/settingService';
import { getCliAuthToken } from '@/api/auth';
import {fetchMobileTestRuns} from "@/api/MobileTestRunService";

const state = {
    jiraConfig: null,
    testRailsConfig: null,
};

const mutations = {
    FETCH_JIRA_CONFIG(state, jiraConfig) {
        state.jiraConfig = jiraConfig;
    },
    FETCH_TESTRAILS_CONFIG(state, testRailsConfig) {
        state.testRailsConfig = testRailsConfig;
    },
};

const actions = {

    async getIntegrationDetails(state, payload) {
      return await TestServiceRequest.get(`/bug-tracker/integration?type=${payload.type}`);
    },

    async getCliToken(state, payload) {
      let response = [];
      try {
        const resp = await getCliAuthToken();
        response = [resp.data, null]
        console.log("🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 ", resp.data);
      } catch (err) {
        response = [null, err]
        console.log("🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 ", err);
      } finally {
        return response;
      }
    },

    async fetchIntegrationSettings({commit}, payload) {

      // console.log("🍛 🍛 🍛 🍛 🍛 payload 🍛 🍛 🍛 🍛 🍛 ", payload)

      const { data } = await TestServiceRequest.get(`/bug-tracker/integration/${payload.project_id}?type=${payload.type}`)
          // const {jira, testrails} = data;
          const {bugTracker} = data;


          if (payload.type === 'jira') {
            // console.log("🍛 🍛 🍛 🍛 🍛 JIRA 🍛 🍛 🍛 🍛 🍛 ", bugTracker)
            commit('FETCH_JIRA_CONFIG', bugTracker);
          }

          if (payload.type === 'testrails') {
          // console.log("🍛 🍛 🍛 🍛 🍛 TESTRAIL 🍛 🍛 🍛 🍛 🍛 ", bugTracker)

            commit('FETCH_TESTRAILS_CONFIG', bugTracker);
          }

          return data;
    },

    fetchSettings({commit}) {
        //request
        fetchWholeSettings()
            .then(({data}) => {
                const {jira} = data;
                commit('FETCH_JIRA_CONFIG', jira);
            })
            .catch(err => {
            });
    },
};

const getters = {
    jiraConfig: state => state.jiraConfig,
    testRailsConfig: state => state.testRailsConfig,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
