<template>
    <div class="card sms_validation-container overlay-modal-readOnly">
        <div class="sms_validation-wrapper">
            <section class="top-modal">
                <div class="input_field">
                    <div class="p-inputgroup flex-1">
                        <span class="p-inputgroup-addon">Receiver</span>
                        <InputText v-model="validationInfo['number']" optionValue="number" optionLabel="number" />
                    </div>
                </div>
            </section>

            <section class="section3">
                <span>
                    <h4>Assertion</h4>
                </span>
                <SMSAssertion v-if="!loadingAssertionStatus" @assertionUpdated="assertionUpdated"
                    :allAssertions="assertions" :response="assertionResultResponse" />
                <section v-if="!loadingAssertionStatus">
                    <SMSBody v-if="!loadingAssertionStatus" :smsBody="smsBody" />
                    <div class="assertion-code-header">
                        <InputSwitch v-model="runCallbackScript" />
                        <h6>Run additional code on request results</h6>
                    </div>
                    <AssertionCode v-if="!loadingAssertionStatus && callback_script"
                        @updateAssertionCode="setCallBackScript" :assertionCode="callback_script"
                        :response="scriptResultResponse" />
                </section>

                <span v-if="loadingAssertionStatus">Loading...</span>
            </section>
            <Logs v-if="scriptResultResponse && !loadingAssertionStatus" :response="scriptResultResponse" />
        </div>
    </div>
</template>

<script>
import SMSAssertion from './SMSAssertion.vue';
import SMSBody from './SMSBody.vue';
import Logs from './Logs.vue';
import AssertionCode from './AssertionCode.vue';

export default {
    name: 'SMSValidationModal',
    components: {
        SMSAssertion,
        SMSBody,
        AssertionCode,
        Logs,
    },
    data: () => ({
        assertions: [],
        validationInfo: {
            number: null,
            assertions: [],
            timeout: 1000,
        },
        callback_script: null,
        runCallbackScript: false,
        assertionResultResponse: null,
        scriptResultResponse: null,
        sending: false,
        loadingAssertionStatus: false,
        smsBody: null,
        isEnableDraft: false,
    }),
    props: {
        existingData: {
            type: Object,
        },
    },
    mounted() {

        if (this.existingData?.validationInfo) {
            console.log("this.existingData", this.existingData);
            const { validationInfo, error_obj, testCaseID } = this.existingData;
            console.log("👺👺👺👺👺👺👺👺👺👺👺👺👺👺👺👺👺👺👺👺👺👺👺👺👺👺👺👺", error_obj)
            this.validationInfo.number = validationInfo.number;
            this.validationInfo.timeout = validationInfo.timeout;
            this.callback_script = validationInfo.callback_script;
            this.runCallbackScript = validationInfo.runCallbackScript;

            this.assertions = validationInfo.assertions.length ? [...validationInfo.assertions] : [];
            this.dataLoaded = true;
            if (error_obj?.execution_result?.validationResult) {
                this.assertionResultResponse = error_obj.execution_result.validationResult.assertionResult;
                this.smsBody = error_obj.execution_result.validationResult.sms.body;
                this.scriptResultResponse = error_obj.execution_result.validationResult.scriptResult;
            }
            if (testCaseID) {
                this.isEnableDraft = true;
            }
        } else {
            this.smsBody = null;
            this.config = {};
            this.ms = 1000;
            this.callback_script = 'console.log(JSON.stringify(sms, null, 3));\n';
            this.runCallbackScript = false;
            this.dataLoaded = true;
        }
    },
};
</script>

<style lang="scss" src="@/styles/smsValidation.scss" />
