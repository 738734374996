import {
    createSubscription,
    deleteSubscriptionPackage,
    fetchSubscriptionList,
    updateSubscription
} from '../../api/TjAdminSubscriptionService';

const state = {subscriptionList: [], selectedSubscriptionPkgInfo: {}};

const mutations = {
    FETCH_SUBSCRIPTION_PACKAGE_LIST(state, res) {
        state.subscriptionList = res;
    },
    GET_SELECTED_SUBSCRIPTION_PACKAGE_INFO(state, res) {
        state.selectedSubscriptionPkgInfo = res;
    },
};

const actions = {
    actionCreateSubscription({state, commit}, reqDataObj) {
        return createSubscription(reqDataObj);
    },
    actionGetSubscriptionList(state) {
        return fetchSubscriptionList().then(resBody => {
            if (resBody.status === 200) {
                console.log('in action resBody', resBody);
                state.commit('FETCH_SUBSCRIPTION_PACKAGE_LIST', resBody.data.result);
                return resBody.data.result;
            } else {
                state.commit('FETCH_SUBSCRIPTION_PACKAGE_LIST', resBody);
            }
        });
    },

    actionDeleteSubscriptionPackage({state, commit}, reqDataObj) {
        let res = deleteSubscriptionPackage(reqDataObj);
        return res;
    },
    actionUpdateSubscription({state, commit}, reqDataObj) {
        return updateSubscription(reqDataObj);
    },
    actionSelectedSubscriptionPkgInfo({state, commit}, reqDataObj) {
        commit('GET_SELECTED_SUBSCRIPTION_PACKAGE_INFO', reqDataObj);
    },
};

const getters = {
    subscriptionList: state => state.subscriptionList,
    selectedSubscriptionPkgInfo: state => state.selectedSubscriptionPkgInfo
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
