<template>
  <!-- <div class="newTest"> -->
  <div class="card" :style="{ position: 'relative' }">
    <div class="new-test-header-section">
      <div class="to-test-cases">
        <router-link to="/test-cases">
          <Button icon="fas fa-chevron-left" class="p-button-rounded p-button-outlined" />
        </router-link>
      </div>
      <div class="new-test-header">New Test</div>
      <Button label="ADD VARIABLE" icon="fas fa-plus-circle" class="p-button-outlined add-variable-button"
        @click="displayAddVariableModal" />
    </div>
    <div class="p-grid" v-if="projectDetails">
      <div class="p-xl-6 p-lg-8 p-md-12">
        <div class="new-test-config-container p-fluid">
          <div class="input">
            <label for="testName">Test Name <span class="required-icon"> *</span></label>
            <InputText type="text" placeholder="Enter Test Name" v-model="testCaseName"
              :class="requiredField.testCaseName" />
            <small class="p-invalid">{{ errorMsg.testCaseName }}</small>
          </div>
          <div class="input">
            <label for="baseURL">Base URL <span class="required-icon"> *</span> </label>
            <div class="base-url-method">
              <!-- <Dropdown v-model="selectedMethod" :options="methods" optionLabel="name" optionValue="code" placeholder="Protocol" /> -->
              <!-- <InputText type="text" placeholder="Enter Base URL" v-model="baseURL" :class="requiredField.baseURL" /> -->
              <InputText type="text" placeholder="https://testjet.ai" v-model="baseURL" :class="requiredField.baseURL" />
            </div>
            <small class="p-invalid">{{ errorMsg.baseURL }}</small>
          </div>
          <div class="input">
            <label>Priority</label>
            <Dropdown v-model="selectedPriority" :options="priorities" optionLabel="name" optionValue="name"
              placeholder="Select a Priority" class="w-full md:w-14rem" />
          </div>
          <div class="input">
            <label>Category</label>
            <Dropdown v-model="selectedCategory" :options="categories" optionLabel="name" optionValue="id" filter
              placeholder="Select a Category" class="w-full md:w-14rem" />
          </div>

          <div class="input custom-resolution">
            <label>Choose Resolution <span class="required-icon"> *</span></label>

            <div class="p-inputgroup resolution-input">
              <InputText class="custom-input-field" :class="requiredField.selectedResolution" type="text"
                v-model="testCaseResolution.title" @click="showResolutionModal = !this.showResolutionModal"
                placeholder="Select Resolution" readonly />
              <Button class="edit-button" icon="fas fa-edit" @click="showResolutionModal = !this.showResolutionModal" />

              <div class="resolution-update-modal-wrapper" v-show="showResolutionModal">
                <div class="modal-header">
                  <label class="header-label"> Screen Resolution </label>
                  <!-- <span class="modal-close-icon" @click="showResolutionModal = !showResolutionModal"> <i class="fas fa-times" /></span> -->
                </div>

                <div class="input-section">
                  <InputNumber v-model="cusResolutionWidth" :min="0" :max="7680" mode="decimal" :useGrouping="false"
                    placeholder="Width (max: 7680)" :class="requiredField.cusResolutionWidth" />
                  <div class="multiplication-icon">X</div>
                  <InputNumber v-model="cusResolutionHeight" :min="0" :max="4320" mode="decimal" :useGrouping="false"
                    placeholder="Height (max:4320)" :class="requiredField.cusResolutionHeight" />
                  <Button class="p-button-primary" icon="fas fa-save" @click="clickSaveCusResolution" />
                </div>
                <div class="resolution-list">
                  <div class="title">Custom</div>
                  <ul>
                    <li @click="handleSelectedResolution(cusResObject)"
                      v-bind:class="{ selected: testCaseResolution.title && !testCaseResolution.value }">
                      <label v-if="cusResObject?.screen_size">{{
                        `${cusResObject?.screen_size?.width} x ${cusResObject?.screen_size?.height}`
                      }}</label>
                      <label v-else>No custom resolution has been added yet</label>
                      <i v-if="testCaseResolution.title && !testCaseResolution.value" class="pi pi-check"></i>
                    </li>
                  </ul>
                  <div class="custorm-res-divider"></div>
                  <div class="title">System Default Resolution</div>
                  <ul>
                    <li v-for="resolutionItem in resolutionList.screenResolution" :key="resolutionItem._id"
                      @click="handleSelectedResolution(resolutionItem)"
                      v-bind:class="{ selected: resolutionItem._id === testCaseResolution.value }">
                      <label>{{
                        `${resolutionItem.screen_size.width} x ${resolutionItem.screen_size.height}`
                      }}</label>
                      <i v-if="resolutionItem._id === testCaseResolution.value" class="pi pi-check"></i>
                      <!-- <i class="fas fa-trash" @click="deleteCustomResolution(resolutionItem._id)"></i> -->
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <small class="p-invalid">{{ errorMsg.selectedResolution }}</small>
          </div>
          <!-- <div class="tags">
              <label for="tag">Add Tags</label>
              <Chips v-model="tags" ref="chipInput" removable placeholder="Enter Tag Name" />
          </div> -->

          <div class="input tags">
            <label for="tag">Add Tags</label>
            <AutoComplete v-model="selectedTag" :suggestions="filteredTags" @complete="searchTag($event)"
              @item-select="itemSelect($event)" @keydown.enter="itemEnter($event)" @blur="tagsBlured"/>
            <Chip :label="tag" v-for="tag in tags" :key="tag" removable @remove="onRemoveTag(tag)"
              :style="{ marginTop: '1rem' }" />
          </div>
          <!-- <div class="add-variable-button-section">
            <div class="add-variable-button">
              <Button label="+ ADD VARIABLE " class="p-button-outlined" @click="displayAddVariableModal" />
            </div>
          </div> -->
          <div class="create-button-section">
            <div class="create-button">
              <Button :disabled="disableSaveBtn" label="CREATE TEST CASE" v-on:click="handleSubmitValidation">

              </Button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="variables.length" class="p-xl-6 p-lg-8 p-md-12">
        <div class="variables-container">
          <h3>Variables</h3>
          <div v-for="(variable, index) in variables" :key="index" class="variable-container" style="position: relative;">
            <div class="info">
              <p class="name">{{ variable.name }}</p>
              <p class="value">{{ variable.value }}</p>
            </div>
            <i class="fas fa-ellipsis-v" @click="(event) => displayOptions(event, variable)"></i>
            <OverlayPanel :ref="variable.id" :key="variable.id" appendTo="body">
              <div class="options">
                <div class="option" @click="displayEditVariableModal(variable, index)">Update <i class="fas fa-sync"></i>
                </div>
                <!-- <div class="option" @click="copyVariableHandler(variable)">Copy</div> -->
                <!-- <div class="option" @click="shareToProjectHandler">Share to project</div> -->
                <div class="option" @click="deleteVariableHandler(index)">Delete <i class="fas fa-trash"></i></div>
              </div>
            </OverlayPanel>
          </div>
        </div>
      </div>
      <div v-else class="p-xl-6 p-lg-8 p-md-12">
        <!-- No variable added yet. Please click ‘Add Variable’ to add -->
        <div class="variables-container">
          <h3>Variables</h3>
          <div style="font-size: 16px; font-weight: bold; text-align: center;">No Data</div>
        </div>
      </div>
    </div>
    <Loader v-else />
    <Dialog :draggable="false" class="add-variable-modal" v-model:visible="displayAddVariable" modal header="Add Variable"
      :style="{ width: '30vw' }" @hide="closeAddVariableModal">
      <div class="add-variable-container p-fluid">
        <div class="custom-p-field-setup-account">
          <label>Variable Name <span class="required-icon"> *</span></label>
          <InputText id="var_name" v-model="variableName" :class="requiredField.variableName" type="text"
            placeholder="Enter Variable Name" @keypress="nameFieldValidation" @blur="onBlurUniqueVariableNameCheck" />
          <small class="p-invalid">{{ errorMsg.varName || errorMsg.varNameLength }}</small>
        </div>
        <label> Scope <span class="required-icon"> *</span></label>
        <div class="scope-container">
          <div class="scope">
            <RadioButton v-model="scope" inputId="scope1" name="pizza" value="project" />
            <label for="scope1" class="ml-2">Project Scope Variable</label>
          </div>
          <div class="scope">
            <RadioButton v-model="scope" inputId="scope2" name="pizza" value="testCase" />
            <label for="scope2" class="ml-2">Test Case Scope Variable</label>
          </div>
        </div>
        <div class="custom-p-field-dropdown">
          <label>Type <span class="required-icon"> *</span></label>
          <Dropdown v-model="selectedType" :options="types" optionLabel="name" optionValue="name"
            :class="requiredField.selectedType" filter="true" @change="onChangeType" placeholder="Select Type" />
        </div>
        <div class="custom-p-field-setup-account">
          <label>Value <span class="required-icon"> *</span></label>
          <InputText v-model="variableValue" :class="requiredField.variableValue" type="text" placeholder="Enter Value" />
          <small class="p-invalid">{{ errorMsg.varValue }}</small>
        </div>
      </div>
      <template #footer>
        <Button label="CREATE VARIABLE" @click="clickAddVariable" autofocus />
      </template>
    </Dialog>
    <Dialog :draggable="false" class="edit-variable-modal" v-model:visible="displayEditVariable" modal
      header="Edit Variable" :style="{ width: '30vw' }" @hide="closeEditVariableModal">
      <div class="edit-variable-container p-fluid">
        <div class="custom-p-field-setup-account">
          <label>Variable Name <span class="required-icon"> *</span></label>
          <InputText disabled v-model="updatedvariableName" :class="requiredField.updatedvariableName" type="text"
            placeholder="Enter Variable Name" />
        </div>
        <label> Scope <span class="required-icon"> *</span></label>
        <div class="scope-container">
          <div class="scope">
            <RadioButton v-model="updatedScope" inputId="scope1" name="pizza" value="project" />
            <label for="scope1" class="ml-2">Project Scope Variable</label>
          </div>
          <div class="scope">
            <RadioButton v-model="updatedScope" inputId="scope2" name="pizza" value="testCase" />
            <label for="scope2" class="ml-2">Test Case Scope Variable</label>
          </div>
        </div>
        <div class="custom-p-field-dropdown">
          <label>Type <span class="required-icon"> *</span></label>
          <Dropdown v-model="selectedType" :options="types" optionLabel="name" optionValue="name"
            :class="requiredField.selectedType" filter="true" @change="onChangeType" placeholder="Select Type" />
        </div>
        <div class="custom-p-field-setup-account">
          <label>Value <span class="required-icon"> *</span></label>
          <InputText v-model="updatedvariableValue" :class="requiredField.updatedvariableValue" type="text"
            placeholder="Enter Value" />
        </div>
      </div>
      <template #footer>
        <Button label="UPDATE VARIABLE" @click="clickUpdateVariable" autofocus />
      </template>
    </Dialog>
    <Dialog :draggable="false" header="Header" v-model:visible="displayDeleteConfirmationModal" closable :modal="true"
      class="delete-confirmation-modal" @hide="closeDeleteConfirmationModal">
      <template #header>
        <img src="/assets/warning-icon.svg" alt="warning-icon.svg" />
        <p>Delete Confirmation</p>
      </template>

      Are you sure you want to delete this variable?

      <template #footer>
        <Button label="No" class="p-button-outlined" @click="clickCancelDeteleVariable" />
        <Button label="Yes" class="p-button-primary" @click="clickDeleteVariable" />
      </template>
    </Dialog>
    <!-- <OverlayPanel v-for="variable in variables"  :ref="variable.id" :key="variable.id" appendTo="body">
      <div>
        <ul>
          <li>Edit</li>
          <li>Copy</li>
          <li>Share to project</li>
          <li>Delete</li>
        </ul>
      </div>
    </OverlayPanel> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { getToken } from '../utils/cookies';
import decode from 'jwt-decode';
import { getSelectedCurrentProjectId, setSelectedCaseId } from '../utils/localstorage';
import Loader from '../components/Loader.vue';
import { ReservedKeywords } from '@/utils/ReservedKeywords';

export default {
  props: {
    basicConfig: {},
  },
  name: 'NewTest',
  components: {
    Loader
  },
  data() {
    return {
      // add variable's variables
      displayAddVariable: false,
      variableName: '',
      types: [
        {
          id: 1,
          name: 'TEXT',
        }
      ],
      selectedType: 'TEXT',
      variableValue: '',
      variables: [],

      // edit variable's variables
      displayEditVariable: false,
      updateVariableIndex: null,
      // updateVariableValue: null,
      updatedvariableName: '',
      updatedvariableValue: '',

      // delete variable
      displayDeleteConfirmationModal: false,
      selectedVariableIndexForDelete: null,

      // variable scope
      scope: 'testCase',
      updatedScope: '',

      requiredField: {
        variableName: '',
        variableValue: '',
        updatedvariableName: '',
        updatedvariableValue: '',
      },


      disableSaveBtn: false,
      showResolutionModal: false,
      testCaseName: '',
      baseURL: '',
      testCaseURLError: null,
      testCaseResolution: {},
      cusResolutionWidth: null,
      cusResolutionHeight: null,
      cusResObject: {},
      tags: [],
      errorMsg: [],
      // requestMethod: '', For Protocol Dropdown
      // selectedMethod: null, For Protocol Dropdown
      projectDetails: null,

      selectedPriority: null,
      priorities: [
        {
          name: 'Critical',
          code: 1,
        },
        {
          name: 'High',
          code: 2,
        },
        {
          name: 'Medium',
          code: 3,
        },
        {
          name: 'Low',
          code: 4,
        },
      ],
      selectedCategory: null,
      categories: [],

      // methods: [
      //     { name: 'https://', code: 1 },
      //     { name: 'http://', code: 2 },
      // ], For Protocol Dropdown

      requiredField: {
        testCaseName: '',
        baseURL: '',
        selectedResolution: '',
        cusResolutionWidth: '',
        cusResolutionHeight: '',
        isValidBaseUrl: true,
      },
      selectedTag: null,
      filteredTags: null
    };
  },

  created() {
    if (this.selectedProjectId) {
      this.projectDetails = this.projectList?.find(project => project._id == this.selectedProjectId);
      this.projectDetails && this.loadInitialData(this.projectDetails);
      this.getTagsByProjectId(this.selectedProjectId);
      this.getCategories(this.selectedProjectId).then(res => {
        if (res?.status === 200 && res?.data?.length) {
          this.categories = res.data;
        }
      })
    }
  },
  mounted() {
    this.getProjectsList();
  },

  methods: {
    ...mapActions({
      actionInsertNewTestCase: 'testCase/actionInsertNewTestCase',
      insertResolution: 'TjAdminFeatures/actionInsertResolution',
      getTagsByProjectId: 'project/actionFetchTagsByProjectId',
      getProjectsList: 'project/actionGetProjectsList',
      checkUniqueVariableName: 'variable/actionUniqueVariableNameCheck',
      getCategories: 'category/actionGetCategories',
    }),

    tagsBlured(event) {
      console.log(event.target.value);
      if (event.target.value?.length) {
        this.tags.push(event.target.value);
        this.selectedTag = ''
      }
    },

    nameFieldValidation(evt) {
      let key = evt.key;
      let keyCode = evt.keyCode || evt.which;

      if (this.variableName.length == 0 && (keyCode >= 48 && keyCode <= 57)) {
        evt.preventDefault();
        this.errorMsg.varNameLength = '';
        this.errorMsg.varName = `'${key}' cannot be used as first character in the variable name`;
      }
      else {
        let regExp = /[\$_a-zA-Z0-9]/g;
        if (!regExp.test(key)) {
          evt.preventDefault();
          // this.showWarningMsg(`'${key}' can not be used in variable name`)
          this.errorMsg.varNameLength = '';
          this.errorMsg.varName = `'${key}' cannot be used in the variable name`;
        }
        else {
          this.errorMsg.varNameLength = '';
          this.errorMsg.varName = '';
        }
      }

      if (evt.target.value.length > 255) {
        evt.preventDefault();
        this.errorMsg.varNameLength = '';
        this.errorMsg.varName = 'Reached maximum character length of 255 for variable name';
      }
    },

    onBlurUniqueVariableNameCheck() {
      if (this.variableName.length) {
        this.checkUniqueVariableName({ name: this.variableName, projectId: this.selectedProjectId }).then(res => {
          if (res.isUnique) {
            if (this.variables.filter(variable => variable.name == this.variableName).length > 0) {
              this.errorMsg.varName = `Variable with name ( ${this.variableName} ) already exists. Please enter a unique name.`;
            }
          }
          else {
            this.errorMsg.varName = `Variable with name ( ${this.variableName} ) already exists. Please enter a unique name.`;
          }
        })
      }
    },

    searchTag(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredTags = [...this.tagList];
        }
        else {
          this.filteredTags = this.tagList.filter((tag) => {
            return tag.toLowerCase().startsWith(event.query.toLowerCase());
          });
        }
      }, 250);
    },

    itemSelect(event) {
      let value = event.value;
      if (value) {
        if (this.tags && this.tags.length) {
          if (!this.tags.includes(value)) {
            this.tags = [...this.tags, value];
            this.selectedTag = null;
          } else {
            this.$toast.add({ severity: 'warn', detail: 'Tag already exist.', life: 3000 });
          }
        } else {
          this.tags = [...this.tags, value];
          this.selectedTag = null;
        }
      }
    },

    itemEnter(event) {
      let value = event.target.value;
      if (value) {
        let highlitedList = document.getElementsByClassName('p-autocomplete-item p-highlight');
        if (highlitedList?.length) {
          let highlitedItem = highlitedList[0];
          value = highlitedItem.outerText;
        }

        if (this.tags && this.tags.length) {
          if (!this.tags.includes(value)) {
            this.tags = [...this.tags, value];
            this.selectedTag = null;
          } else {
            this.$toast.add({ severity: 'warn', detail: 'Tag already exist.', life: 3000 });
          }
        } else {
          this.tags = [...this.tags, value];
          this.selectedTag = null;
        }
      }
      // To close the overlay
      let btn = document.createElement("button");
      document.body.appendChild(btn);
      btn.click();
    },

    showSuccess(summary, detail) {
      this.$toast.add({ severity: 'success', summary: summary, detail: detail, life: 3000 });
    },

    showError(detail) {
      this.$toast.add({ severity: 'error', summary: 'Error Message', detail: detail, life: 3000 });
    },

    showWarningMsg(msgDetail) {
      this.$toast.add({ severity: 'warn', summary: 'Warn Message', detail: msgDetail, life: 5000 });
    },

    showInfo(summary, detail) {
      this.$toast.add({ severity: 'info', summary: summary, detail: detail, life: 3000 });
    },

    onRemoveTag(label) {
      this.tags = this.tags.filter((tag) => tag !== label);
    },

    handleSelectedResolution(selectedResdata) {
      this.testCaseResolution = {
        title: `${selectedResdata.title} ${selectedResdata.screen_size.width} x ${selectedResdata.screen_size.height}`,
        value: selectedResdata._id
      };
      this.showResolutionModal = !this.showResolutionModal;
    },

    clickSaveCusResolution() {
      if (this.cusResolutionWidth == null || this.cusResolutionHeight == null) {
        if (this.cusResolutionWidth == null) {
          this.requiredField.cusResolutionWidth = 'p-invalid';
        }
        if (this.cusResolutionHeight == null) {
          this.requiredField.cusResolutionHeight = 'p-invalid';
        }
      } else {
        this.testCaseResolution = {}
        this.testCaseResolution.title = this.cusResolutionWidth + ' x ' + this.cusResolutionHeight;
        this.showResolutionModal = !this.showResolutionModal;
        this.resetRequiredField('selectedResolution');

        const reqDataObj = {
          title: 'custom',
          device_name: 'custom',
          screen_size: {
            width: this.cusResolutionWidth,
            height: this.cusResolutionHeight,
          },
          resolution_type: 'custom',
        };
        this.cusResObject = reqDataObj;
        if (this.cusResolutionWidth != null || this.cusResolutionHeight != null) {
          this.cusResolutionWidth = null
          this.cusResolutionHeight = null
        }
      }
    },

    isAddVariableRequiredFieldEmpty() {
      let requiredFieldEmpty = false;

      if (this.variableName === '') {
        requiredFieldEmpty = true;
        this.requiredField.variableName = 'p-invalid';
        this.errorMsg.varName = 'Please provide variable name';
      };

      if (this.variableValue === '') {
        requiredFieldEmpty = true;
        this.requiredField.variableValue = 'p-invalid';
        this.errorMsg.varValue = 'Please provide value';
      };

      return requiredFieldEmpty;
    },

    isEditVariableRequiredFieldEmpty() {
      let requiredFieldEmpty = false;

      if (this.updatedvariableName === '') {
        requiredFieldEmpty = true;
        this.requiredField.updatedvariableName = 'p-invalid';
      };

      if (this.updatedvariableValue === '') {
        requiredFieldEmpty = true;
        this.requiredField.updatedvariableValue = 'p-invalid';
      };

      return requiredFieldEmpty;
    },

    resetAddVariableValues() {
      this.variableName = '';
      this.variableValue = '';
      this.scope = 'testCase';
      this.errorMsg.varName = '';
      this.errorMsg.varNameLength = '';
      this.errorMsg.varValue = '';
      this.requiredField.variableName = '';
      this.requiredField.variableValue = '';
    },

    resetEditVariableValues() {
      this.updateVariableIndex = null;
      this.updatedvariableName = '';
      this.updatedvariableValue = '';
      this.updatedScope = '';
      this.errorMsg.varValue = '';
      this.requiredField.updatedvariableName = '';
      this.requiredField.updatedvariableValue = '';
    },

    displayAddVariableModal() {
      this.displayAddVariable = true;
    },

    clickAddVariable() {
      if (!this.isAddVariableRequiredFieldEmpty() && this.errorMsg.varName == '' && this.errorMsg.varNameLength == '') {
        let variable = {
          id: (this.variables.length + 1).toString(),
          name: this.variableName,
          type: this.selectedType,
          value: this.variableValue,
          scope: this.scope,
        };

        this.variables.push(variable);
        this.displayAddVariable = false;
        this.resetAddVariableValues();
        this.showInfo('Info', 'Variable is created successfully but will not be published until the test case is created.')

      }
    },

    closeAddVariableModal() {
      this.resetAddVariableValues();
    },

    displayOptions(event, variable) {
      this.$refs[variable.id][0].toggle(event);
    },

    copyVariableHandler(variable) {
      navigator.clipboard.writeText(variable.name).then(() => {
        this.showSuccess('Copied', 'Variable Name Copied To Clipboard')
      })
    },

    displayEditVariableModal(variable, index) {
      this.updateVariableIndex = index;
      this.updatedvariableName = variable.name;
      this.updatedvariableValue = variable.value;
      this.updatedScope = variable.scope;
      this.displayEditVariable = true;
    },

    clickUpdateVariable() {
      if (!this.isEditVariableRequiredFieldEmpty()) {
        let updatedVariable = {
          id: (this.updateVariableIndex + 1).toString(),
          name: this.updatedvariableName,
          type: this.selectedType,
          value: this.updatedvariableValue,
          scope: this.updatedScope,
        };

        this.variables[this.updateVariableIndex] = updatedVariable;
        this.showSuccess("Successful", "Variable updated successfully");
        this.resetEditVariableValues();
        this.displayEditVariable = false;
      }
    },

    closeEditVariableModal() {
      this.resetEditVariableValues();
    },

    deleteVariableHandler(index) {
      // this.variables.splice(index, 1);
      // this.showSuccess("Successful", "The variable has been deleted successfully.");

      this.selectedVariableIndexForDelete = index;
      this.displayDeleteConfirmationModal = true;
    },

    clickDeleteVariable() {
      this.variables.splice(this.selectedVariableIndexForDelete, 1);
      this.selectedVariableIndexForDelete = null;
      this.displayDeleteConfirmationModal = false;
      this.showSuccess("Successful", "The variable has been deleted successfully.");
    },

    clickCancelDeteleVariable() {
      this.selectedVariableIndexForDelete = null;
      this.displayDeleteConfirmationModal = false;
    },

    closeDeleteConfirmationModal() {
      this.selectedVariableIndexForDelete = null;
      this.displayDeleteConfirmationModal = false;
    },

    async handleSubmitValidation() {
      if (!this.isRequiredFieldEmpty()) {
        this.disableSaveBtn = true;
        let userLoginInfo = decode(getToken());

        await new Promise(r => setTimeout(r, 200));

        if (getSelectedCurrentProjectId()) {
          let newTestCaseObj = {};
          if (this.variables.length === 0) {
            newTestCaseObj = {
              organizationId: this.loggedInUserInfo.organizationId,
              test_case_list: [
                {
                  test_case_name: this.testCaseName,
                  type: 'testCase',
                  description: '',
                  base_url: this.baseURL,
                  tags: this.tags,
                  variables: [],
                  priority: this.selectedPriority,
                  category_id: this.selectedCategory,
                  screen_resolution_id: this.testCaseResolution.value,
                  screen_resolution_obj: this.cusResObject,
                  project_id: getSelectedCurrentProjectId(),
                  created_by: userLoginInfo._id,
                },
              ],
            };
          }
          else {
            let variables = this.variables.map(variable => {
              delete variable.id;
              return variable;
            })
            newTestCaseObj = {
              organizationId: this.loggedInUserInfo.organizationId,
              test_case_list: [
                {
                  test_case_name: this.testCaseName,
                  type: 'testCase',
                  description: '',
                  base_url: this.baseURL,
                  tags: this.tags,
                  variables,
                  priority: this.selectedPriority,
                  category_id: this.selectedCategory,
                  screen_resolution_id: this.testCaseResolution.value,
                  screen_resolution_obj: this.cusResObject,
                  project_id: getSelectedCurrentProjectId(),
                  created_by: userLoginInfo._id,
                },
              ],
            };
          }
          this.actionInsertNewTestCase(newTestCaseObj)
            .then((resBody) => {
              if (resBody.status === 200) {
                this.showSuccess("Successful", "Test Case created successfully.");
                setSelectedCaseId(resBody.data.cases._id);
                const id = resBody.data.cases._id;
                setTimeout(() => {
                  this.$router.push({ path: `/test-editor/${id}` });
                }, 700);
                this.disableSaveBtn = false;
              } else {
                this.disableSaveBtn = false;
                this.showError('Test Case create unccessfull.');
              }
            })
            .catch((err) => {
              this.disableSaveBtn = false;
              console.log('create-project-res-err', err);
            });
        } else {
          this.disableSaveBtn = false;
          this.showWarningMsg('Please select project first.');
        }
      }
    },

    isRequiredFieldEmpty() {
      let requiredFieldEmpty = false;
      if (this.testCaseName == '') {
        requiredFieldEmpty = true;
        this.requiredField.testCaseName = 'p-invalid';
        this.errorMsg['testCaseName'] = 'Test Name is missing.';
      }
      if (this.baseURL == '') {
        requiredFieldEmpty = true;
        this.requiredField.baseURL = 'p-invalid';
        this.errorMsg['baseURL'] = 'Base URL is missing.';
      }
      if (this.testCaseURLError == true) {
        requiredFieldEmpty = true;
        this.requiredField.baseURL = 'p-invalid';
      }
      if (Object.keys(this.testCaseResolution).length === 0) {
        requiredFieldEmpty = true;
        this.requiredField.selectedResolution = 'p-invalid';
        this.errorMsg['selectedResolution'] = 'Resolution is missing.';
      }
      return requiredFieldEmpty;
    },

    resetRequiredField(fieldName) {
      this.requiredField[fieldName] = '';
      this.errorMsg[fieldName] = '';
    },

    loadInitialData(projectDetails) {
      this.baseURL = projectDetails.base_url;
      // if (projectDetails.base_url.includes('https://')) {
      //     this.selectedMethod = 1;
      //     let url = projectDetails.base_url.split('https://');
      //     this.baseURL = url[1];
      // } else if (projectDetails.base_url.includes('http://')) {
      //     this.selectedMethod = 2;
      //     let url = projectDetails.base_url.split('http://');
      //     this.baseURL = url[1];
      // }
    }
  },

  computed: {
    ...mapGetters({
      getTestCaseList: 'testCase/testCaseList',
      resolutionList: 'TjAdminFeatures/resolutionList',
      projectList: 'project/projectList',
      selectedProjectId: 'project/projectID',
      tagList: 'project/tagList',
      loggedInUserInfo: 'auth/loggedInUserInfo',
    }),
  },

  watch: {
    projectList() {
      if (this.selectedProjectId) {
        this.projectDetails = this.projectList?.find(project => project._id == this.selectedProjectId);
        this.projectDetails && this.loadInitialData(this.projectDetails);
      }
    },
    selectedProjectId() {
      if (this.selectedProjectId) {
        this.projectDetails = this.projectList?.find(project => project._id == this.selectedProjectId);
        this.projectDetails && this.loadInitialData(this.projectDetails);
      }
    },
    testCaseName() {
      this.resetRequiredField('testCaseName');
    },
    baseURL(value) {
      // if (value) {
      //     const regexURL = RegExp('(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+@#]*)*(\\?[;&a-z\\d%_.~+=-@]*)?(\\#[-a-z\\d_@]*)?$', 'i');
      //     const regex = /^(h?t?t?p?s?:\/?\/?)/gi;

      //     if (value.match(regexURL)) {
      //         if (regex.test(value)) {
      //             this.errorMsg.baseURL = 'Remove Protocol from URL';
      //             this.requiredField.baseURL = 'p-invalid';
      //             this.testCaseURLError = true;
      //         } else {
      //             this.errorMsg.baseURL = '';
      //             this.requiredField.baseURL = '';
      //             this.testCaseURLError = false;
      //             this.resetRequiredField('baseURL');
      //         }
      //     } else {
      //         if (regex.test(value)) {
      //             this.errorMsg.baseURL = 'Remove Protocol from URL';
      //             this.requiredField.baseURL = 'p-invalid';
      //             this.testCaseURLError = true;
      //         } else {
      //             this.errorMsg.baseURL = 'Invalid URL.';
      //             this.requiredField.baseURL = 'p-invalid';
      //             this.testCaseURLError = true;
      //         }
      //     }
      // } else {
      //     this.errorMsg.baseURL = '';
      // } For Protocol Dropdown
      const regexURL = RegExp('(https?:\/\/)((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+@#]*)*(\\[;&a-z\\d%_.~+=-@]*)?(\\#[-a-z\\d_@]*)?$', 'i');

      if (!value?.match(regexURL)) {
        this.errorMsg.baseURL = 'Provide a valid URL';
        this.requiredField.baseURL = 'p-invalid';
        this.testCaseURLError = true;
      } else {
        this.errorMsg.baseURL = '';
        this.requiredField.baseURL = '';
        this.testCaseURLError = false;
      }
    },
    testCaseResolution(value) {
      this.resetRequiredField('selectedResolution');
    },

    selectedMethod(value) {
      if (value === 1) {
        this.requestMethod = 'https://';
      } else {
        this.requestMethod = 'http://';
      }
    },

    variableName(value) {
      if (value.length) {
        this.requiredField.variableName = '';

        let firstCharRegex = /[0-9]/g;

        if (value.length > 255) {
          this.errorMsg.varNameLength = 'Reached maximum character length of 255 for variable name';
        }
        else if (firstCharRegex.test(value[0])) {
          this.errorMsg.varNameLength = '';
          this.errorMsg.varName = `'${value[0]}' cannot be used as first character in the variable name`;
          this.variableName = '';
          document.getElementById('var_name').value = '';
        }
        else {
          let regex = /[\$_a-zA-Z0-9]/g;

          if (value.match(regex).length !== value.length) {
            this.errorMsg.varNameLength = '';
            this.errorMsg.varName = `'${value}' cannot be used as variable name`
            // this.variableName = '';
            // document.getElementById('var_name').value = '';
          }
          else if (ReservedKeywords.includes(value)) {
            this.errorMsg.varNameLength = '';
            this.errorMsg.varName = `'${value}' is a reserved JavaScript keyword. Please enter a different name.`;
          }
          else {
            this.errorMsg.varNameLength = '';
            this.errorMsg.varName = '';
          }
        }
      }
      else {
        this.errorMsg.varNameLength = '';
        this.errorMsg.varName = '';
      }
    },
    variableValue(value) {
      if (value.length) {
        this.requiredField.variableValue = '';
        this.errorMsg.varValue = '';
      }
    },
    updatedvariableName(value) {
      if (value.length) {
        this.requiredField.updatedvariableName = '';
      }
    },
    updatedvariableValue(value) {
      if (value.length) {
        this.requiredField.updatedvariableValue = '';
      }
    },
  },
};
</script>

<style lang="scss">
/* .p-xl-6 {
    margin: 0 auto;
    width: 50%; } */
.selected {
  background-color: #f5fafc;
}

.p-overlaypanel {
  .p-overlaypanel-content {
    padding: 0;

    .options {
      .option {
        padding: 0.75rem;

        &:hover {
          cursor: pointer;
          background: #F8FBFC;
          transition: 0.25s ease;
        }
      }
    }
  }
}</style>
