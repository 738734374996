import {
    getProjectsList,
    insertNewProject,
    projectUpdate,
    fetchTagsByProjectId,
    insertMobileProject,
    fetchMobileProjects,
    updateMobileProject,
    uploadMobileProjectAvatar,
    upploadMobileProjectApk,
    upploadMobileProjectApkToAgent,
    fetchProjectMembers,
    fetchMobileProjectMembers,
    uploadIosCertToAgent,
    uploadIosProvaToAgent,
    iosAgentCertChecker,
    iosAgentProvaChecker,
    iosAgentGenerateCsr,
} from '../../api/ProjectService';

const state = {
    projectList: null,
    tagList: [],
    projectID: null,
    apkUploadPercentage : 0,
};

const mutations = {
    SAVE_PROJECTLIST(state, projectList) {
        state.projectList = projectList;
    },
    UPDATE_PROJECT_LIST(state, updatedProject) {
        state.projectList?.map(project => {
            if (project._id === updatedProject._id) {
                project = updatedProject;
            }
        })
    },
    SET_CURRENT_PROJECT_ID(state, projectID) {
        state.projectID = projectID;
    },
    SET_TAGS(state,tags) {
        state.tagList = tags;
    },
    SET_APK_UPLOAD_PERCENTAGE(state, percentage) {
        state.apkUploadPercentage = percentage;
    },
    IS_TEAM_MEMBER_LIST_LOADED(state, status) {
        state.isTeamMemberListLoaded = status;
    },
    IS_MOBILE_TEAM_MEMBER_LIST_LOADED(state, status) {
        state.isMobileTeamMemberListLoaded = status;
    },
    NO_DATA_FOUND_MSG(state, msg) {
        state.noDataFoundMsg = msg;
    }
};

const actions = {
    actionInsertNewProject(state, reqDataObj) {
        console.log('store-SaveReqData', reqDataObj);
        let res = insertNewProject(reqDataObj); //method from service
        return res;
    },

    actionInsertMobileProject(state, reqDataObj) {
        return insertMobileProject(reqDataObj);
    },

    async actionGetProjectsList(state) {
        let projects = null;
        try {
            const response = await Promise.all([getProjectsList(), fetchMobileProjects()]);
            if (response[0].status === 200) {
                projects = projects === null ? response[0].data.projects : projects.concat(response[0].data.projects);
            }
            if (response[1].status.code === 200) {
                projects = projects === null ? response[1].data.projects : projects.concat(response[1].data.projects);
            }
            projects = projects.sort(function (a,b){
                return new Date(b.createdAt) - new Date(a.createdAt)
            });
            state.commit('SAVE_PROJECTLIST', projects);
            return new Promise((resolve,reject) => {
                resolve(projects);
            });

        } catch (error) {
            projects = [];
            return new Promise((resolve,reject) => {
                reject(projects)
            });
        }
    },

    actionOnchangeProjectHandler({state, commit}, projectID) {
        console.log('projectID in action', projectID);
        commit('SET_CURRENT_PROJECT_ID', projectID);
    },

    async actionClickProjectUpdate({state, commit}, newData) {
        let res = await projectUpdate(newData);
        console.log("action project update===>", res);
        if (res.status === 200) {
            commit("UPDATE_PROJECT_LIST", res.data.updatedProject)
        }
        return res;
    },

    actionUpdateMobileProject(state, projectData) {
        return updateMobileProject(localStorage.getItem('projectId'), projectData)
        .then(response=>{
            if (response?.data?.project){
                state.commit("UPDATE_PROJECT_LIST", response.data.project)
            }
        })
    },

    actionClearProjectList({commit}) {
        commit('SAVE_PROJECTLIST', [])
    },

    actionFetchTagsByProjectId(state,projectId){
        fetchTagsByProjectId(projectId)
        .then(res =>{
            state.commit("SET_TAGS", res.data.tags);
        })
        .catch(err => {
            state.commit("SET_TAGS", [])
        })
    },

    actionUploadMobileProjectApk(state, fileForm){
        state.commit('SET_APK_UPLOAD_PERCENTAGE', 0);
        const onUploadProgress = (progressEvent)=> {
            state.commit('SET_APK_UPLOAD_PERCENTAGE', Math.round((progressEvent.loaded * 100) / progressEvent.total));
        }
        return upploadMobileProjectApk(fileForm.get('projectId'),fileForm, onUploadProgress);
    },
    actionUploadMobileProjectAvatar(state, fileForm){
        return uploadMobileProjectAvatar(fileForm)
    },
    actionUploadMobileProjectApkToAgent(state, fileForm){
        return upploadMobileProjectApkToAgent(fileForm.get('projectId'),fileForm);
    },

    actionUploadIosCertToAgent(state, fileForm){
        return uploadIosCertToAgent(fileForm.get('projectId'), fileForm)
    },

    actionIosAgentCertChecker(state, fileForm){
        return iosAgentCertChecker(fileForm.projectId)
    },

    actionIosAgentProvaChecker(state, fileForm){
        return iosAgentProvaChecker(fileForm.projectId)
    },

    actionUploadIosProvaToAgent(state, fileForm){
        return uploadIosProvaToAgent(fileForm.get('projectId'), fileForm)
    },

    actionIosAgentGenerateCsr(state, id){
        return iosAgentGenerateCsr(id)
    },

    actionFetchProjectMembers(state, orgId) {
        state.commit('IS_TEAM_MEMBER_LIST_LOADED', false);
        return fetchProjectMembers(orgId)
            .then(resBody => {
                state.commit('IS_TEAM_MEMBER_LIST_LOADED', true);
                if (resBody.status === 200) {
                    state.commit('NO_DATA_FOUND_MSG', null);
                    return resBody;
                } else {
                    state.commit('NO_DATA_FOUND_MSG', resBody?.data?.errors);
                    return resBody;
                }
            })
            .catch(err => {
                return err;
            })
    },
    actionFetchMobileProjectMembers(state, orgId) {
        state.commit('IS_MOBILE_TEAM_MEMBER_LIST_LOADED', false);
        return fetchMobileProjectMembers(orgId)
            .then(resBody => {
                state.commit('IS_MOBILE_TEAM_MEMBER_LIST_LOADED', true);
                if (resBody.status === 200) {
                    state.commit('NO_DATA_FOUND_MSG', null);
                    console.log("Mobile project member action", resBody);
                    return resBody;
                } else {
                    console.log('Mobile resBody member error action===>', resBody?.data?.errors);
                    state.commit('NO_DATA_FOUND_MSG', resBody?.data?.errors);
                    return resBody;
                }
            })
            .catch(err => {
                return err;
            })
    }
};

const getters = {
    projectList: state => state.projectList,
    tagList: state => state.tagList,
    projectID: state => state.projectID,
    apkUploadPercentage: state => state.apkUploadPercentage,
    isTeamMemberListLoaded: state => state.isTeamMemberListLoaded,
    isMobileTeamMemberListLoaded: state => state.isMobileTeamMemberListLoaded,
    noDataFoundMsg: state => state.noDataFoundMsg,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
