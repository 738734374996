<template>
  <div class="card url_validation-container">
    <div class="url_validation-wrapper">
      <section class="top">
        <div class="input_field">
          <Dropdown class="url_method" v-model="selectedMethod" :options="methods" optionLabel="name"
            optionValue="code" />
          <InputText class="url_input" v-model="url" placeholder="eg: https://example.com?q='search'" />
          <Button type="button" label="SEND" @click="SAVE('send')" :disabled="sending || !url" />
        </div>
        <Button class="saveDraft" label="Save as draft" @click="SAVE('draft')" />
        <Button v-if="componentMode === 'create' && !existingData?.is_drafted" label="ADD TO STEP"
          @click="SAVE('addToStep')" />
        <Button v-else-if="componentMode === 'update' && !existingData?.is_drafted" label="UPDATE"
          @click="SAVE('updateStep')" />
        <Button v-else label="ADD TO STEP" @click="SAVE('addToStepFromDraft')" />
      </section>

      <section class="section2">
        <div class="actions">
          <Button label="REQUEST" :class="['req', apiValidationActiveMode === 'request' ? 'active' : '']"
            @click="() => {apiValidationActiveMode = 'request'; setComponentVars()}" />
          <Button label="RESPONSE" :class="['res', , apiValidationActiveMode === 'response' ? 'active' : '']"
            :disabled="!response" @click="apiValidationActiveMode = 'response'" />
        </div>
        <div class="timeout_info">
          <i class="fas fa-clock"></i>
          <p>Override Timeout : </p>
          <InputNumber v-model="ms" inputId="milliseconds" suffix=" ms" :min="1000" :max="10000"
            placeholder="Step Timeout (milliseconds)" disabled />
        </div>
        <AvailableVariables :componentVariables="variables" v-if="apiValidationActiveMode === 'request'" />
        <div class="varaible_info" v-if="apiValidationActiveMode === 'response'">
          <div class="status_and_time">
            <h6>{{ `Status: ${response?.response?.status}` }}</h6>
            <h6>{{ `Time: ${response?.response?.timeTaken || 0} ms` }}</h6>
          </div>
        </div>
      </section>

      <section class="section3" v-if="dataLoaded">
        <RequestHeader :allHeaders="headers" :auth="auth" @headerValueUpdated="updateHeader"
          @authValueUpdated="authTypeUpdated" v-if="apiValidationActiveMode === 'request'" />
        <RequestBody :code="json" @updatedCode="updatedCode" v-if="apiValidationActiveMode === 'request'" />

        <ResponseHeader :allHeaders="response?.response?.headers || []" :auth="auth"
          v-if="apiValidationActiveMode === 'response'" />
        <ResponseBody :code="response?.response?.body || '{}'" v-if="apiValidationActiveMode === 'response'" />
        <span>
          <h6>
            <strong>Note:</strong> Assertion will run before additional code when both are there.
          </h6>
          <i class="fas fa-info-circle"></i>
        </span>
        <span class="second">
          <h4>Assertion</h4>
          <div class="assertion-code-header">
            <InputSwitch v-model="runCallbackScript" />
            <h6>Run additional code on request results</h6>
          </div>
        </span>

        <Assertion v-if="!loadingAssertionStatus" @assertionUpdated="assertionUpdated" :allAssertions="assertions"
          :response="assertionResultResponse" />

        <AssertionCode v-if="!loadingAssertionStatus && apiValidationActiveMode === 'request'"
          @updateAssertionCode="setCallBackScript" :assertionCode="callback_script" />
        <AssertionCode v-if="!loadingAssertionStatus && apiValidationActiveMode === 'response'"
          @updateAssertionCode="setCallBackScript" :assertionCode="callback_script" :response="scriptResultResponse" />

        <span v-if="loadingAssertionStatus">Loading...</span>
      </section>

      <section v-else>
        <p>Loading...</p>
      </section>

      <Logs v-if="apiValidationActiveMode === 'response'" :response="scriptResultResponse" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import RequestHeader from './components/RequestHeader.vue';
import RequestBody from './components/RequestBody.vue';
import ResponseHeader from './components/ResponseHeader.vue';
import ResponseBody from './components/ResponseBody.vue';
import Assertion from './components/Assertion.vue';
import AssertionCode from './components/AssertionCode.vue';
import Logs from './components/Logs.vue';
import AvailableVariables from './components/AvailableVariables.vue';

export default {
  name: 'url-validation',
  components: {
    RequestHeader,
    RequestBody,
    ResponseHeader,
    ResponseBody,
    Assertion,
    AssertionCode,
    Logs,
    AvailableVariables,
  },
  data: () => ({
    apiValidationActiveMode: 'request', // request, response
    selectedMethod: 'get',
    json: '',
    auth: { type: 'basic', value: '' },
    ms: 1000,
    assertions: [],
    headers: [],
    url: null,
    callback_script: '',
    runCallbackScript: false,
    variables: [],
    response: null,
    assertionResultResponse: null,
    scriptResultResponse:null,
    sending: false,
    loadingAssertionStatus: false,
    methods: [
      { name: 'GET', code: 'get' },
      { name: 'POST', code: 'post' },
      { name: 'PATCH', code: 'patch' },
      { name: 'PUT', code: 'put' },
      { name: 'DELETE', code: 'delete' }
    ],
    errors: {},
    dataLoaded: true,
    componentMode: 'create' //* create / update
  }),
  props: {
    existingData: {
      type: Object,
    },
    sendData: {
      type: Object,
      required: true,
      default: {},
    }
  },
  created() {
    this.setComponentVars();
    this.dataLoaded = false;
  },
  mounted() {
    // console.log("🥊 🥊 🥊 🥊 🥊", this.existingData, this.caseVariables);
    if (this.existingData) {
      this.componentMode = 'update';

      const { validationInfo, error_obj } = this.existingData;
      this.url = validationInfo.endpoint;
      this.json = validationInfo.body ? validationInfo.body : '';
      this.auth = validationInfo.authorization ? validationInfo.authorization : null;
      this.ms = validationInfo.timeout;
      this.headers = validationInfo.headers.length ? [...validationInfo.headers] : [];
      this.selectedMethod = validationInfo.method;
      this.runCallbackScript = validationInfo.runCallbackScript;
      this.callback_script = validationInfo.callback_script ? validationInfo.callback_script : '';

      if (error_obj?.validationResult) {
        this.apiValidationActiveMode = 'response';
        this.assertionResultResponse = error_obj.validationResult.assertionResult;
      }
      if (error_obj?.validationResult?.scriptResult) {
        this.scriptResultResponse = error_obj.validationResult.scriptResult;
      }

      this.assertions = validationInfo.assertions.length ? [...validationInfo.assertions] : [];

      this.dataLoaded = true;
    } else {
      this.componentMode = 'create';

      this.url = null;
      this.json = '';
      this.auth = { type: 'basic', value: '' };
      this.ms = 1000;
      this.headers = [];
      this.runCallbackScript = false;
      this.selectedMethod = 'get';
      this.callback_script = '';
      this.dataLoaded = true;
    }
    console.log("🎃 🎃 🎃 🎃 🎃 🎃 🎃 🎃 🎃 🎃", this.response);
  },
  computed: {
    ...mapGetters({
      caseVariables: 'variable/caseVariables',
    }),
  },
  watch: {
    sendData: {
      deep: true,
      handler(value) {
        console.log("🥛 🥛 🥛 🥛 🥛 🥛 🥛 🥛 🥛 🥛 🥛 🥛 🥛 ", value);
        const { data, componentName } = value;
        if (componentName === 'apiValidation') {
          const { validationResult, execution_time, type } = data;

          this.sending = false;
          this.loadingAssertionStatus = false;
          this.apiValidationActiveMode = 'response';

          this.assertionResultResponse = validationResult.assertionResult;
          if (validationResult) {
            this.scriptResultResponse = validationResult.scriptResult;
          }

          this.response = validationResult;
          // this.assertions = validationInfo.assertions.length ? [...validationInfo.assertions] : [];
          // this.callback_script = validationInfo?.callback_script?.length ? validationInfo.callback_script : '';

          console.log("🎃 🎃 🎃 🎃 🎃 🎃 🎃 🎃 🎃 🎃 sendData ", this.response);
        }
      }
    }
  },
  methods: {
    setComponentVars() {
      this.variables = [];

      this.variables = this.caseVariables.map(varr => {

        return {
          _id: varr._id,
          [varr.name]: varr.value
        }
      });
    },
    setCallBackScript(value) {
      // console.log("🥊 🥊 🥊 🥊 🥊 🥊 🥊 🥊 🥊 ", value);
      this.callback_script = value;
    },
    SAVE(actionType) {
      const obj = {
        ...(this.existingData ? { "existingStepId": this.existingData._id } : {}),
        "method": this.selectedMethod,
        "endpoint": this.url,
        "body": this.json,
        "authorization": this.auth,
        "timeout": this.ms,
        "assertions": this.assertions,
        "headers": this.headers,
        "callback_script": this.callback_script,
        "runCallbackScript": this.runCallbackScript,
        "actionType": actionType,
        "response": null,
      }

      if (Object.keys(this.errors).length > 0) {
        for (let i = 0; i < Object.values(this.errors).length; i++) {
          const er = Object.values(this.errors)[i];
          this.$toast.add({ severity: 'error', summary: 'ERROR', detail: er, life: 3000 });
        }
        return
      }

      if (actionType == 'send') {
        this.apiValidationActiveMode = 'request';
        this.sending = true;
        this.loadingAssertionStatus = true;
        // obj._id = obj.existingStepId ? obj.existingStepId : 'randomidgeneratorfromt_j'
      }

      this.$emit('save', obj);
      // console.log(this.existingData);
      // console.log(obj);
    },
    authTypeUpdated(auth) {
      this.auth = auth;
    },
    assertionUpdated(assertions) {
      // console.log("⭕ ⭕ ⭕ ⭕ ⭕ ⭕ ⭕ ⭕ ⭕ ⭕ ⭕ ⭕ ", assertions);
      this.assertions = [...assertions]
    },
    updateHeader(allHeaders) {
      this.headers = [...allHeaders];
    },
    async updatedCode(val) {
      try {
        const response = await JSON.parse(val)
        this.json = response;
        if ('jsonError' in this.errors) {
          delete this.errors["jsonError"]
        }
      } catch (err) {
        const msg = err.message;
        console.log(msg);
        if (msg?.toLowerCase()?.includes('unterminated string in json at position')) {
          this.errors = { ...this.errors, jsonError: `Unterminated string found in json, please rechheck the request body` };
        } else {
          this.errors = { ...this.errors, jsonError: `${msg}. on request body` };
        }
      }
    }
  },

}
</script>

<style lang="scss" src="@/styles/urlValidation.scss" />
