<template>
  <div class="varaible_info">
    <h6>Available Variables: </h6>
    <div class="variables" v-if="variables?.length">
      <span v-for="(variable, i) in variables" :key="i" :style="{ display: i < variableCount ? 'inherit' : 'none' }">
        <p class="var variable" v-tooltip.bottom="Object.values(variable)[1]">{{ Object.keys(variable)[1] }}{{ i <
          variables.length - 1 ? ',' : '' }}</p>
      </span>
      <p class="var more_variable" v-if="hiddenVariableCount > 0" v-tooltip.bottom="hiddenVariableString">{{ `more
        +${hiddenVariableCount}` }}</p>
    </div>
    <p v-else class="var no_variables">No variables</p>
  </div>
</template>

<script>
export default {
  name: 'available-variables',
  data: () => ({
    variables: [],
    variableCount: 4,
    isThereAnyVariableWhoseLengthIsGreaterThan50Chars: false,
  }),
  props: {
    componentVariables: {
      type: Array,
      required: true,
      default: [],
    }
  },
  watch: {
    componentVariables(newVal) {
      this.configureVariables();
    }
  },
  created() {
    this.configureVariables();
  },
  computed: {
    hiddenVariableCount() {
      if ((this.variables && this.variables.length > this.variableCount) + 1) {
        return (this.variables.length - this.variableCount);
      } else {
        return null;
      }
    },
    hiddenVariableString() {
      if ((this.variables && this.variables.length > this.variableCount) + 1) {
        let varsString = '';
        const vars = this.variables.slice(this.variableCount, this.variables.length);
        for (let i = 0; i < vars.length; i++) {
          const element = vars[i];
          varsString += `${Object.keys(element)[1]}: ${Object.values(element)[1]} \n\n`;
        }
        return varsString
      } else {
        return null;
      }
    }
  },
  methods: {
    textTruncate(text, limit) {
      // remove all spaces from the text then check for length
      if (text.length > limit) {
        return text.substring(0, limit) + '...';
      } else {
        return text;
      }
    },
    configureVariables() {
      this.variables = this.componentVariables;
      let totalNumbersOfVariablesExceedingThe50CharLimit = 0;
      this.componentVariables.map(varr => {
        const el = Object.keys(varr)[1];
        if (el.length > 50) {
          totalNumbersOfVariablesExceedingThe50CharLimit++;
          this.isThereAnyVariableWhoseLengthIsGreaterThan50Chars = true;
          const ind = this.componentVariables.indexOf(varr);
          const oldObjKeys = Object.keys(varr);
          const oldObjValues = Object.values(varr);
          const truncatedObjectKey = this.textTruncate(oldObjKeys[1], 30);
          const newObj = {
            [oldObjKeys[0]]: oldObjValues[0],
            [truncatedObjectKey]: oldObjValues[1],
          };
          console.log(newObj);
          this.componentVariables.splice(ind, 1, newObj);
        }
      });

      if (totalNumbersOfVariablesExceedingThe50CharLimit >= 2) {
        this.variableCount = 2;
      }
    }
  },
}
</script>

<style lang="scss" scoped></style>
