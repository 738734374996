import {getToken, removeToken} from './cookies';

export default (service, name) => {
    /**
     * @description Interceptor for request
     */
    service.interceptors.request.use(
        (config) => {
            const hasToken = getToken();
            if (hasToken) {
                config.headers['Authorization'] = `Bearer ${getToken()}`;
            } else {
                config.headers['Authorization'] = `Basic dGVzdGpldF9iYXNpY19hdXRoOnRlc3RqZXRfYmFzaWNfYXV0aEAxMjM=`;
            }
            // do something before request is sent
            return config;
        },
        (error) => {
            // do something with request error
            console.log(error); // for debug
            return Promise.reject(error);
        }
    );

    /**
     * Response interceptor
     */
    service.interceptors.response.use(
        /**
         * If you want to get http information such as headers or status
         * Please return  response => response
         */

        /**
         * Determine the request status by custom code
         * Here is just an example
         * You can also judge the status by HTTP Status Code
         */
        (response) => {
            const res = response.data;

            // if the custom code is not 200, it is judged as an error.
            if (res.status === 200) {
                return res;
            } else {
                return res;
                // return Promise.reject(new Error(res.message || 'Error'));
            }
        },
        (error) => {
            if (error && error.response && error.response.status === 401 && getToken()) {
                removeToken();
                window.location.reload();
            }

            if (error.message === 'Network Error') {
                // Emitter.emit('network-failed', {
                //     error,
                // });
            }
            if (process.env.NODE_ENV === 'development') {
                // Emitter.emit('show-message', {
                //     type: 'error',
                //     message: generateErrorMessage(error.config.baseURL, error.config.url, error.message, 3000),
                // });
            }
            return Promise.reject(error);
        }
    );
};
