export default {
    // AddNewGroup: {
    //     name: 'Add New Group',
    //     action: 'add_new_group',
    //     icon: 'fas fa-folder-plus',
    // },
    InsertVariable: {
        name: 'Insert variable',
        action:'insert_variable',
        icon: 'fa fa-location-arrow',
    },
    TextToAssign: {
        name: 'Validate Text',
        action: 'text_to_assign',
        icon: 'fas fa-text-width',
    },

    Hover: {
        name: 'Hover',
        action: 'hover',
        icon: 'fas fa-hand-pointer',
    },
    // AddCustomValidation: {
    //     name: 'Add Custom Validation',
    //     action: 'add_custom_validation',
    //     icon: 'fa-location-arrow',
    // },

    Navigate: {
        name: 'Navigate',
        action: 'navigate',
        icon: 'fas fa-location-arrow',
    },

    ValidateCssProperty: {
        name: 'Validate CSS Property',
        action: 'validate_css_property',
        icon: 'fab fa-css3-alt',
    },

    ValidateHtmlAttribute: {
        name: 'Validate HTML Attribute',
        action: 'validate_html_attribute',
        icon: 'fab fa-html5',
    },

    ValidateElementVisible: {
        name: 'Validate Element Visible',
        action: 'validate_element_visible',
        icon: 'fas fa-eye',
    },

    ValidateDatabse: {
        name: 'Validate Database',
        action: 'validate_database',
        icon: 'fas fa-database',
    },

    ValidateElementNotVisible: {
        name: 'Validate Element Not Visible',
        action: 'validate_element_not_visible',
        icon: 'fas fa-eye-slash',
    },

    ValidateCheckbox: {
        name: 'Validate Checkbox',
        action: 'validate_checkbox',
        icon: 'fas fa-check-square',
    },

    ValidateRadioButton: {
        name: 'Validate Radio Button',
        action: 'validate_radio_button',
        icon: 'fas fa-dot-circle',
    },

    API_VALIDATION: {
        name: 'Validate API',
        action: 'validate_api',
        icon: 'fas fa-sitemap',
    },
    Refresh: {
        name: 'Refresh',
        action: 'refresh',
        icon: 'fas fa-sync',
    },
    Sleep: {
        name: 'Sleep',
        action: 'sleep',
        icon: 'fas fa-bed',
    },

    CHECK_BROKEN_LINK: {
        name: 'Link Checker',
        action: 'check_broken_link',
        icon: 'fas fa-unlink',
    },

    URL_Validation: {
        name: 'Validate URL',
        action: 'url_validation',
        icon: 'fas fa-link',
    },

    // SMS_Validation: {
    //     name: 'SMS validation',
    //     action: 'sms_validation',
    //     icon: 'fas fa-comment-alt',
    // },
    GENERATE_PHONE_NUMBER: {
        name: 'Generate Phone Number',
        action: 'generate_phone_number',
        icon: 'fas fa-phone',
    },
    OTP_VALIDATION: {
        name: 'OTP Validation',
        action: 'otp_validation',
        icon: 'fas fa-key',
    },
    SCROLL: {
        name: 'Scroll',
        action: 'scroll',
        icon: 'fas fa-scroll',
    },
    // Email_Validation: {
    //     name: 'Email validation',
    //     action: 'email_validation',
    //     icon: 'fas fa-envelope',
    // },
    // ValidateViewportPixel: {
    //     name: 'Validate Viewport Pixel',
    //     action: 'validate_viewport_pixel',
    //     icon: 'fa-location-arrow',
    // },
    ValidateEmail: {
        name: 'Validate Email',
        action: 'validate_email',
        icon: 'fas fa-envelope',
    },

    ValidateSMS: {
        name: 'Validate SMS',
        action: 'validate_sms',
        icon: 'fas fa-sms',
    },

    // ViewportPixel:{
    //     name: 'Viewport Pixel',
    //     action: 'viewport_pixel',
    //     icon: 'fa-location-arrow',
    // },
    // DocumentPixel:{
    //     name:"Document Pixel",
    //     action:"document_pixel",
    //     icon:'fa-location-arrow'
    // },
    // CustomJavascript:{
    //     name:"Custom (Javascript)",
    //     action:"custom_javascript",
    //     icon:'fa-location-arrow'
    // },
    // Navigate: {
    //     name: 'Navigate',
    //     action: 'navigate',
    //     icon: 'fa-location-arrow',
    // },
};
