export const ClientProfileMenu = [
    {
        label: 'Profile',
        icon: 'far fa-building',
        to: `/organization-details`,
    },
    {
        label: 'Account Settings',
        icon: 'fas fa-wrench',
        to: '/account-settings',
    },
    {
        label: 'Team Management',
        icon: 'fas fa-users',
        to: '/team-management',
    },
    {
        label: 'Subscription',
        icon: 'fas fa-money-check-alt',
        to: '/package-details',
    },
    // {
    //     label: 'Billing History',
    //     icon: 'far fa-credit-card',
    //     to: '/billing-history',
    // },
];
