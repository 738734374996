<template>
  <div class="header-main req-body">
    <div class="head">
      <h6>Body</h6>
    </div>
    <div class="body">
      <span v-if="codeBlock">
        <JsonEditor readonly :code="codeBlock" @updateCode="val => codeBlock = val" />
      </span>
    </div>
  </div>
</template>

<script>
import JsonEditor from './JsonEditor.vue';
export default {
  name: "response-body",
  components: {
    JsonEditor
  },
  props: {
    code: {
      type: Object
    }
  },
  created() {
    this.codeBlock = JSON.stringify(this.code ? this.code : {}, null, 2)
  },
  data: () => ({
    codeBlock: '',
  }),
  watch: {
    codeBlock(val) {
      if (!val) {
        this.codeBlock = '{}'
      } else {
        this.$emit('updatedCode', val)
      }
    }
  }
}
</script>
