let projectId = localStorage.getItem('projectId') ? localStorage.getItem('projectId') : null;

export const ClientAdminMenu = [
  {
    label: 'Overview',
    icon: 'fas fa-columns',
    to: `/project-overview/${projectId}`,
  },
  {
    label: 'Test Cases',
    icon: 'fas fa-list-ul',
    to: '/test-cases',
  },
  {
    label: 'Test Runs',
    icon: 'fas fa-play',
    to: '/test-runs',
  },
  {
    label: 'Variables',
    icon: 'fas fa-file-code',
    to: '/variables',
  },
  {
    label: 'Reports',
    icon: 'fas fa-chart-pie',
    // to: '/reports/create-new',
    items: [
      {
        label: 'Create New',
        icon: 'fas fa-stream',
        to: '/reports/create-new',
      },
      {
        label: 'All Reports',
        icon: 'fas fa-sitemap',
        to: '/reports/all',
      },
    ],
  },
  {
    label: 'Settings',
    icon: 'fas fa-cog',
    to: '/settings',
  },
];
