<template>
  <div class="header-main req-header">
    <div class="head">
      <H6>Database Configuration</H6>
    </div>
    <div class="body">
      <div class="headers">
        <div class="dbc-field">
          <label class="lbl" for="">Connection String <span class="required-icon"> *</span></label>
          <InputText v-model="config.connectionString" placeholder="Type your connection string here" />
        </div>
        <div class="dbc-field">
          <label class="lbl" for="">JDBC Driver Type <span class="required-icon"> *</span></label>
          <Dropdown v-model="config.jdbcDriverType" :options="jdbcDriverTypes" optionValue="id" optionLabel="name" placeholder="Select JDBC driver type" />
        </div>
        <div class="dbc-field">
          <label class="lbl" for="">User Name</label>
          <InputText v-model="config.userName" placeholder="Enter user name" />
        </div>
        <div class="dbc-pass">
          <label class="lbl" for="">Password</label>
          <InputText v-model="config.password" placeholder="Enter Password" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "database-config",
  props: {
    dbConfig: {
      type: Object,
    },
  },
  created() {
    // if (this.allHeaders?.length) {
    //   this.headers = [...this.allHeaders]
    //   this.$emit("headerValueUpdated", this.headers)
    // } else {
    //   this.headers = [
    //     {
    //       checked: true,
    //       header: 'Content-Type',
    //       value: 'application/json',
    //     },
    //     {
    //       checked: true,
    //       header: '',
    //       value: '',
    //     },
    //   ]
    // }

    if (this.dbConfig) {
      this.config.jdbcDriverType = this.dbConfig.jdbcDriverType;
      this.config.connectionString = this.dbConfig.connectionString;
      this.config.userName = this.dbConfig.userName;
      this.config.password = this.dbConfig.password;
      this.$emit("databaseConfigUpdated", this.config)
    }
  },
  data: () => ({
    jdbcDriverTypes: [
      { name: 'MONGODB', id: 'MONGODB' },
      { name: 'MYSQL', id: 'MYSQL' },
      { name: 'POSTGRESQL', id: 'POSTGRESQL' },
      { name: 'ORACLE', id: 'ORACLE' },
    ],
    config: {
      connectionString : '',
      jdbcDriverType: '',
      userName : '',
      password : ''
    },

  }),
  watch: {
    config: {
      handler(val){
        this.$emit("databaseConfigUpdated", this.config)
      },
      deep: true
    }
  },
  methods: {
    keyUP(e, i) {
      const lastElement = (this.headers.length - 1) === i;
      const elementBeforeTheLastElement = (this.headers.length - 2) === i;

      if (lastElement && e.target.value) {
        this.headers.push({
          checked: true,
          header: '',
          value: '',
        })
      } else if (!lastElement && elementBeforeTheLastElement && !e.target.value) {
        this.headers.splice(i, 1)
      }
      // console.log('changed', e.target.value);
    },
    removeReadOnly(e){
      e.target.removeAttribute('readonly');
    },

  },
}
</script>
