import {
    insertNewIssue,
    getIssues,
    assignIssueInTestCase,
    assignIssueInMobileTestCase,
    updateIssueById,
    deleteIssueById, 
} from '../../api/IssueService';

const state = {
};

const mutations = {

};

const actions = {
    actionInsertNewIssue(state, data) {
        let res = insertNewIssue(data); //method from service
        return res;
    },
    actionGetIssues(state, projectId) {
        let res = getIssues(projectId); //method from service
        return res;
    },
    actionAssignIssueInTestCase(state, data) {
        let res = assignIssueInTestCase(data);
        return res;
    },
    actionAssignIssueInMobileTestCase(state, data) {
        let res = assignIssueInMobileTestCase(data);
        return res;
    },
    async actionDeleteIssue(state, id) {
        let res = await deleteIssueById(id);
        return res;
    },
    async actionUpdateIssue(state, data) {
        let res = await updateIssueById(data);
        return res;
    },
};

const getters = {
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
