<template>
  <div class="topbar clearfix tj-topbar">
    <div class="topbar-left">
      <center>
        <router-link class="p-link" to="/dashboard">
          <img alt="Logo" src="/assets/layout/images/logo.png" class="topbar-logo" />
        </router-link>
      </center>
    </div>

    <div class="topbar-right">
      <button class="p-link" :style="{ display }" id="menu-button" @click="onMenuButtonClick">
        <i class="pi pi-angle-left"></i>
      </button>

      <button class="p-link" id="topbar-menu-button" @click="onTopbarMenuButtonClick">
        <i class="pi pi-bars"></i>
      </button>
      <div class="tj-topbar-options">
        <div class="tj-topbar-left-options">
          <router-link v-if="role === 'admin' || role === 'user'" to="/dashboard" class="link">Dashboard</router-link>
          <router-link v-if="role == 'tj_super_admin' || role == 'tj_admin'" to="/admin-dashboard"
            class="link">Dashboard</router-link>
        </div>
      </div>
      <ul :class="topbarItemsClass">
        <Button :label="'CREATE PROJECT'" icon="fas fa-plus" class="new-project-btn" @click="openCreateDialogue" />
        <div class="support-menu">
          <a class="link" href="https://support.testjet.ai" target="_blank" rel="nofollow">Support</a>
          <router-link class="link" to="/downloads">Downloads</router-link>
        </div>
        <li :class="['profile-item', { 'active-top-menu': activeTopbarItem === 'profile' }]"
          @click="$emit('topbar-item-click', { originalEvent: $event, item: 'profile' })">
          <button class="p-link">
            <!-- <img
                alt="User"
                class="profile-image"
                :src="
                    loggedInUserInfo && loggedInUserInfo.profile && loggedInUserInfo.profile.user_photo && loggedInUserInfo.profile.user_photo.photo_url
                        ? loggedInUserInfo && loggedInUserInfo.profile && loggedInUserInfo.profile.user_photo && loggedInUserInfo.profile.user_photo.photo_url
                        : defaultUserAvatar
                "
            /> -->
            <avatar shape="circle" class="p-avatar-sm profile-image"
              v-if="loggedInUserInfo && loggedInUserInfo.user_profile && loggedInUserInfo.user_profile.user_photo && loggedInUserInfo.user_profile.user_photo.photo_url"
              :image="loggedInUserInfo && loggedInUserInfo.user_profile && loggedInUserInfo.user_profile.user_photo && loggedInUserInfo.user_profile.user_photo.photo_url" />
            <avatar shape="circle" class="p-avatar-sm profile-image" style="background-color: #dee2e6" v-else
              :label="(loggedInUserInfo && loggedInUserInfo.user_profile && loggedInUserInfo.user_profile.first_name && loggedInUserInfo.user_profile.first_name[0] && loggedInUserInfo.user_profile.first_name[0].toUpperCase()) + (loggedInUserInfo && loggedInUserInfo.user_profile && loggedInUserInfo.user_profile.last_name && loggedInUserInfo.user_profile.last_name[0] && loggedInUserInfo.user_profile.last_name[0].toUpperCase())" />
            <!-- <span class="topbar-item-name">Isabel Lopez</span>
            <span class="topbar-item-role">Marketing</span> -->
          </button>

          <transition name="layout-submenu-container">
            <ul class="layout-menu fadeInDown" v-show="activeTopbarItem === 'profile'">
              <!-- <li role="menuitem">
                  <button class="p-link">
                      <i class="pi pi-fw pi-user"></i>
                      <span>Profile</span>
                  </button>
              </li>
              <li role="menuitem">
                  <button class="p-link">
                      <i class="pi pi-fw pi-lock"></i>
                      <span>Privacy</span>
                  </button>
              </li> -->
              <a @click="profileSettings">
                <li role="menuitem">
                  <button class="p-link">
                    <i class="pi pi-fw pi-user"></i>
                    <span>Profile</span>
                  </button>
                </li>
              </a>
              <a v-if="projectList?.length" @click="accountSettings">
                <li role="menuitem">
                  <button class="p-link">
                    <i class="pi pi-fw pi-cog"></i>
                    <span>Account Settings</span>
                  </button>
                </li>
              </a>
              <a v-if="projectList?.length" @click="teamManagementSettings">
                <li role="menuitem">
                  <button class="p-link">
                    <i class="fas fa-users"></i>
                    <span>Team Management</span>
                  </button>
                </li>
              </a>
              <a v-if="projectList?.length" @click="subscriptionSettings">
                <li role="menuitem">
                  <button class="p-link">
                    <i class="fas fa-money-bill-wave"></i>
                    <span>Subscription</span>
                  </button>
                </li>
              </a>
              <li role="menuitem">
                <button class="p-link" @click="clickLogoutHandler">
                  <i class="pi pi-fw pi-sign-out"></i>
                  <span>Logout</span>
                </button>
              </li>
            </ul>
          </transition>
        </li>
        <!-- <li :class="[{ 'active-top-menu': activeTopbarItem === 'notifications' }]" @click="$emit('topbar-item-click', { originalEvent: $event, item: 'notifications' })">
            <button class="p-link">
                <i class="topbar-icon pi-fw fas fa-bell" style="color: #00a0ee"></i>
                <span class="topbar-badge animated rubberBand">4</span>
                <span class="topbar-item-name">Notifications</span>
            </button>

            <transition name="layout-submenu-container">
                <ul class="layout-menu fadeInDown" v-show="activeTopbarItem === 'notifications'">
                    <li role="menuitem">
                        <button class="p-link">
                            <i class="pi pi-fw pi-sliders-h"></i>
                            <span>Pending tasks</span>
                        </button>
                    </li>
                    <li role="menuitem">
                        <button class="p-link">
                            <i class="pi pi-fw pi-calendar"></i>
                            <span>Meeting today at 3pm</span>
                        </button>
                    </li>
                    <li role="menuitem">
                        <button class="p-link">
                            <i class="pi pi-fw pi-download"></i>
                            <span>Download documents</span>
                        </button>
                    </li>
                    <li role="menuitem">
                        <button class="p-link">
                            <i class="pi pi-fw pi-bookmark"></i>
                            <span>Book flight</span>
                        </button>
                    </li>
                </ul>
            </transition>
        </li> -->
        <!-- <li>
            <div class="right-divider"></div>
        </li> -->
      </ul>
    </div>
    <Dialog :draggable="false" header="Create a new project" v-model:visible="showProjectTypeDialog" :modal="true"
      class="project-type" :style="{ width: '50vw' }">
      <div v-if="slider == 'first'">
        <div class="label">
          <p>Choose your project type</p>
        </div>
        <div class="project-type-container">
          <div class="mobile-dialog-container">
            <i class="pi pi-check-circle checked" v-if="activeType === projectTypeEnums.WEB"></i>
            <div class="icon-container" v-bind:class="{ active: activeType === projectTypeEnums.WEB }"
              @click="selectProjectType(projectTypeEnums.WEB, projectTypeEnums.WEB)">
              <!-- <img src="/assets/web.png" alt="web-icon" /> -->
              <i class="fas fa-globe"></i>
            </div>
            <div class="type-title">
              <p>Web</p>
              <i class="pi pi-info-circle"></i>
            </div>
            <!-- <small class="p-invalid">{{ errMsg.wLimit }}</small> -->
          </div>
          <div class="web-dialog-container">
            <i class="pi pi-check-circle checked" v-if="activeType === projectTypeEnums.ANDROID"></i>
            <div class="icon-container" v-bind:class="{ active: activeType === projectTypeEnums.ANDROID }"
              @click="selectProjectType(projectTypeEnums.MOBILE, projectTypeEnums.ANDROID)">
              <!-- <img src="/assets/mobile.png" alt="mobile-icon" /> -->
              <i class="pi pi-android"></i>
            </div>
            <div class="type-title">
              <p>Android</p>
              <i class="pi pi-info-circle"></i>
            </div>
            <!-- <small class="p-invalid">{{ errMsg.mLimit }}</small> -->
          </div>
          <div class="web-dialog-container">
            <i class="pi pi-check-circle checked" v-if="activeType === projectTypeEnums.IOS"></i>
            <div class="icon-container" v-bind:class="{ active: activeType === projectTypeEnums.IOS }"
              @click="selectProjectType(projectTypeEnums.MOBILE, projectTypeEnums.IOS)">
              <!-- <img src="/assets/mobile.png" alt="mobile-icon" /> -->
              <i class="pi pi-apple"></i>
            </div>
            <div class="type-title">
              <p>IOS</p>
              <i class="pi pi-info-circle"></i>
            </div>
            <!-- <small class="p-invalid">{{ errMsg.mLimit }}</small> -->
          </div>

        </div>
      </div>

      <div v-if="slider == 'second'" class="project-details-container">
        <div class="label">
          <p>Provide your project details</p>
        </div>
        <div class="file-upload">
          <div class="uploaded-img-container" v-if="project.imageSource">
            <img :src="project.imageSource" alt="">
            <div class="hover-element">
              <Button for="avatar-input" icon="fas fa-upload" class="p-button-rounded p-button-outlined upload-icon"
                @click="$refs.avatarInput.click()" />
              <Button icon="fas fa-times-circle" class="p-button-rounded p-button-outlined remove-icon"
                @click="removeAvatar()" />
            </div>
          </div>

          <div class="empty-img-container" v-if="!project.imageSource">
            <label class="empty-img-container" for="avatar-input" style="cursor:pointer">
              <i class="fas fa-upload" for="avatar-input"></i>
            </label>
          </div>
          <input id="avatar-input" ref="avatarInput" class="file-choose-input" type="file" style="display: none"
            @change="uploadAvatar($event)" />
        </div>
        <div class="Avatar-title">
          <p>Upload Avatar</p>
        </div>
        <div class="project-title">
          <div class="input p-fluid">
            <label for="name">Project Name <span class="required-icon"> *</span></label>
            <InputText type="text" placeholder="Enter Project Name" v-model="project.title"
              :class="requiredField.projectName" />
          </div>
        </div>
        <div class="project-title" v-if="activeType === projectTypeEnums.WEB">
          <div class="input p-fluid">
            <label for="name">Base URL <span class="required-icon"> *</span></label>
            <InputText type="text" placeholder="https://testjet.ai" v-model="project.base_url"
              :class="requiredField.baseURL" />
            <small class="p-invalid">{{ errMsg.projectURL }}</small>
          </div>
        </div>
        <div class="team-member">
          <div class="input p-fluid">
            <label for="name">Add Member</label>
            <MultiSelect v-model="project.selected_members" display="chip" :options="members" optionLabel="name"
              optionValue="access" placeholder="Select Team Members" :filter="true" class="w-full md:w-20rem" />
          </div>
        </div>
      </div>

      <template #footer>
        <div class="project-footer-container">
          <div class="prev-button-content">
            <Button label="BACK" @click="prevPage()" :disabled="slider == 'first'" icon="pi pi-angle-left" />
          </div>
          <div class="radio-button-content">
            <RadioButton class="radioBtn" id="type1" v-model="slider" value="first" @click="prevPage()" />
            <RadioButton id="type2" v-model="slider" value="second" :disabled="!selectedProjectType"
              @click="nextPage()" />
          </div>
          <div class="next-button-content">
            <Button v-if="!createProjectBtn" label="NEXT" icon="pi pi-angle-right" iconPos="right" @click="nextPage()"
              :disabled="!selectedProjectType" />
            <Button v-else label="NEXT" icon="pi pi-angle-right" iconPos="right" @click="createProject"
              :disabled="!project.title.length" />
          </div>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import { removeToken } from '@/utils/cookies';
import { mapActions, mapGetters } from 'vuex';
import { ProjectType } from '../enums/modules/project.enum';
import { setSelectedCurrentProjectId, setSelectedCurrentProjectType, removeSelectedCurrentProjectId, removeSelectedCurrentProjectType } from '@/utils/localstorage';
import { dateComparison } from '../utils/helpers';
import { ProjectTypeEnums } from '@/enums';

export default {
  components: {
    // ProjectList
  },
  props: {
    topbarMenuActive: Boolean,
    profileMode: String,
    horizontal: Boolean,
    activeTopbarItem: String,
  },
  data() {
    return {
      role: '',
      defaultUserAvatar: '/assets/layout/images/Default-User-Avatar.png',

      searchProject: '',
      srcName: 'projectList',
      image: '/assets/layout/images/ProjectAvatar.png',
      showProjectTypeDialog: false,
      errMsg: [],
      requiredField: {
        projectName: '',
        baseURL: '',
        selectedMember: '',
        choosefileData: '',
      },
      slider: 'first',
      createProjectBtn: false,
      members: [],
      ownAccess: null,
      projectTypeEnums: ProjectTypeEnums,
      selectedProjectType: null,
      project: {
        imageSource: null,
        imageFile: null,
        photo: {
          path: ''
        },
        title: '',
        subscriptionID: '',
        selected_members: [],
        base_url: null
      },
      isWebActive: false,
      isMobileActive: false,
      projectURLError: false,
    };
  },
  watch: {},
  methods: {
    ...mapActions({
      clearProjectListState: 'project/actionClearProjectList',
      clearTestCaseListSate: 'testCase/actionClearTestCaseList',
      clearTestRunListState: 'testRun/actionClearTestRunList',
      onChangeProjectHandler: 'project/actionOnchangeProjectHandler',
      getProjectsList: 'project/actionGetProjectsList',
      fetchMembers: 'TjAdminFeatures/actionFetchMembers',
      fetchOrganizationMembers: 'organization/GetOrganizationMembers',
      createMobileProject: 'project/actionInsertMobileProject',
      createWebProject: 'project/actionInsertNewProject',
      upploadMobileProjectAvater: 'project/actionUploadMobileProjectAvatar',
      getOrgInfo: 'organization/GetOrganizationData',
      orgConsumption: 'organization/GetOrganizationPackageConsumption'
    }),
    onMenuButtonClick(event) {

      this.$emit('menubutton-click', event);
    },
    onTopbarMenuButtonClick(event) {
      this.$emit('topbar-menubutton-click', event);
    },

    clickLogoutHandler: function () {
      //var vm = this;
      this.clearProjectListState();
      this.clearTestCaseListSate();
      this.clearTestRunListState();
      this.$store
        .dispatch('auth/actionLogoutHandler')
        .then((body) => {
          console.log('log out body', body);
          if (body.status == 200) {
            removeSelectedCurrentProjectId();
            this.onChangeProjectHandler()
            removeSelectedCurrentProjectType();
            removeToken();
            this.$router.push({ path: '/login' });
            this.$socket.disconnect();
          }
        })
        .catch((err) => {
          removeSelectedCurrentProjectId();
          this.onChangeProjectHandler()
          removeToken();
          this.$router.push({ path: '/login' });
        });
    },
    gotoOverview(e) {
      e.preventDefault();

      if (this.projectID) {
        const project = this.projectList?.find(project => project._id == this.projectID);
        if (project.platform === ProjectType.WEB) {
          this.$router.push('/project-overview/' + this.projectID);
        } else {
          this.$router.push('/project-overview-mobile/' + this.projectID);
        }
      } else {
        if (this.projectList[0]?.platform === ProjectType.WEB) {
          setSelectedCurrentProjectType(ProjectType.WEB)
          setSelectedCurrentProjectId(this.projectList[0]._id);
          this.$router.push('/project-overview/' + this.projectList[0]._id);
        } else {
          setSelectedCurrentProjectType(ProjectType.MOBILE)
          setSelectedCurrentProjectId(this.projectList[0]._id);
          this.$router.push('/project-overview-mobile/' + this.projectList[0]._id);
        }
      }
    },
    profileSettings() {
      this.$router.push({ path: '/organization-details' });
    },
    accountSettings(e) {
      if (this.projectID) {
        this.$router.push({ path: '/account-settings' });
      } else {
        if (this.projectList[0]?.platform === ProjectType.WEB) {
          setSelectedCurrentProjectType(ProjectType.WEB)
          setSelectedCurrentProjectId(this.projectList[0]._id);
          this.$router.push({ path: '/account-settings' });
        } else {
          setSelectedCurrentProjectType(ProjectType.MOBILE)
          setSelectedCurrentProjectId(this.projectList[0]._id);
          this.$router.push({ path: '/account-settings' });
        }
      }
    },

    teamManagementSettings() {
      this.$router.push({ path: '/team-management' });
    },

    subscriptionSettings() {
      this.$router.push({ path: '/package-details' });
    },
    openCreateDialogue() {
      const dateIsExpired = dateComparison(this.orgInfo.package.activeTill)

      if (dateIsExpired) {
        this.showProjectTypeDialog = false;
        // checkSubscription
        this.showError('Your free trial has expired. Please renew or upgrade your subscription package.');
        return;
      } else {
        this.errMsg['limit'] = '';
        this.project.title = '';
        this.project.base_url = null;
        this.selectedProjectType = null;
        this.isWebActive = false;
        this.isMobileActive = false;
        this.slider = 'first';
        this.createProjectBtn = false;
        this.projectURLError = false;
        this.errMsg.projectURL = '';
        this.project.imageSource = null;
        return this.showProjectTypeDialog = true;
      }
    },
    createProject() {
      if (this.selectedProjectType === this.projectTypeEnums.WEB) {
        this.addWebProject()
      }
      else {
        this.addMobileProject();
      }
    },

    async addMobileProject() {
      this.project.subscriptionID = this.loggedInUserInfo.subscription._id;
      const payloadProject = { ...this.project };
      if (this.ownAccess) {
        payloadProject.selected_members = [...payloadProject.selected_members, ...[this.ownAccess]];
      }
      payloadProject.selected_members = payloadProject.selected_members.map((member_info) => {
        return { roleId: member_info.access_type, userId: member_info.user_ids }
      });
      payloadProject.organizationId = this.loggedInUserInfo.organizationId;
      payloadProject.type = this.activeType;
      payloadProject.platform = this.projectTypeEnums.MOBILE;
      console.log(this.project == payloadProject)
      this.createMobileProject(payloadProject)
        .then(async response => {
          if (response?.data?.project) {
            this.showProjectTypeDialog = false;
            this.showSuccess('Success', 'Project created successfully.');
            await this.getProjectsList();
            setSelectedCurrentProjectId(response.data.project._id);
            setSelectedCurrentProjectType(response.data.project.platform);
            await this.onChangeProjectHandler(response.data.project._id);
            await new Promise(r => setTimeout(r, 200));
            this.$router.push({ path: `/settings` })
          }
        })
        .catch(err => {
          if (err.response?.data?.error?.code == 56002) {
            this.showError('Project Limit Exceeded!');
          } else {
            this.showError('Project Created Unsuccessful.');
          }
        })
    },

    async addWebProject() {
      const createProjectForm = new FormData();
      let selectedMembers = [...this.project.selected_members];
      if (this.ownAccess) {
        selectedMembers.push(this.ownAccess);
      }
      createProjectForm.append('title', this.project.title);
      createProjectForm.append('base_url', this.project.base_url);
      createProjectForm.append('description', 'default description');
      createProjectForm.append('super_admin_user_id', this.loggedInUserInfo._id);
      createProjectForm.append('selected_members', JSON.stringify(selectedMembers));
      createProjectForm.append('photo', this.project.imageFile);
      createProjectForm.append('organizationId', this.loggedInUserInfo.organizationId);


      this.createWebProject(createProjectForm)
        .then(async (response) => {
          if (response.status === 200) {
            this.showProjectTypeDialog = false;
            this.showSuccess('Success', 'Project created successfully.');
            await this.getProjectsList();
            setSelectedCurrentProjectId(response.data.project._id);
            setSelectedCurrentProjectType(response.data.project.type);


            this.onChangeProjectHandler(response.data.project._id);
            setTimeout(() => this.$router.push({ path: '/settings' }), 1000);
          } else if (response?.response.status === 409) {
            this.showError(response.response.data.errors);
          } else {
            this.showError('Project Created Unsuccessful.');
          }
        })
        .catch((err) => {

        });
    },
    // closable(){
    //   this.selectedProjectType = null;
    //   this.errMsg['mLimit'] = '';
    //   this.errMsg['wLimit'] = '';
    // },

    showSuccess(summary, detail) {
      this.$toast.add({ severity: 'success', summary: summary, detail: detail, life: 3000 });
    },

    showError(detail) {
      this.$toast.add({ severity: 'error', summary: 'Error Message', detail: detail, life: 3000 });
    },

    showWarn(detail) {
      this.$toast.add({ severity: 'warn', summary: 'Warning Message', detail: detail, life: 3000 });
    },
    checkProjectLimit(type, platform) {
      console.log(type, platform);
      if (type == this.projectTypeEnums.MOBILE) {
        const mobileProjectlimit = Number(this.orgInfo.package.mobileConfig.projectLimit)
        if (this.consumptionInfo?.mobile.project >= mobileProjectlimit) {
          this.errMsg['mLimit'] = 'Mobile project limit reached';
          this.isWebActive = false;
          this.selectedProjectType = null;
          this.showProjectTypeDialog = false;
          return this.showWarn('Mobile project limit reached.');
        } else {
          this.selectedProjectType = type;
          this.isMobileActive = true;
          this.isWebActive = false;
          this.errMsg['mLimit'] = '';
          this.errMsg['wLimit'] = '';
        }
      } else {
        const webProjectlimit = Number(this.orgInfo.package.webConfig.projectLimit)
        console.log(this.consumptionInfo);
        if (this.consumptionInfo?.web?.project >= webProjectlimit) {
          this.errMsg['wLimit'] = 'Web project limit reached';
          this.isMobileActive = false;
          this.selectedProjectType = null;
          this.showProjectTypeDialog = false;
          return this.showWarn('Web project limit reached.');
        } else {
          this.selectedProjectType = type;
          this.isWebActive = true;
          this.isMobileActive = false;
          this.errMsg['wLimit'] = '';
          this.errMsg['mLimit'] = '';
        }
      }
    },

    selectProjectType(type, platform) {
      this.selectedProjectType = null;
      this.activeType = platform;
      this.checkProjectLimit(type, platform);
    },

    nextPage() {
      this.slider = 'second';
      this.createProjectBtn = true;
    },
    prevPage() {
      this.slider = 'first';
      this.createProjectBtn = false;
    },
    getMembers() {
      this.fetchOrganizationMembers(this.loggedInUserInfo.organizationId).then((resbody) => {
        if (resbody[1] == null) {
          // let members = resbody[0].data.memberList.filter(member => member._id !== this.orgInfo.ownerId && member.user_status === 1);
          let members = resbody[0].data.memberList;
          for (const member of members) {
            if (member.user_status === 1) {
              const memberInfo = {
                id: member._id,
                name: member.user_profile.first_name + ' ' + member.user_profile.last_name,
                role_id: member && member.role_id && member.role_id._id,
                access: {
                  access_type: member && member.role_id && member.role_id._id,
                  user_ids: member._id,
                },
              };
              if (member._id == this.loggedInUserInfo._id) {
                this.ownAccess = { ...memberInfo.access };
              }
              else {
                this.members.push(memberInfo);
              }
            }
          }
        }
      });
    },
    uploadAvatar(event) {
      if (event.target.files.length > 0) {
        const reader = new FileReader();
        const project = this.project;
        reader.onload = e => {
          project.imageSource = e.target.result;
          project.imageFile = event.target.files[0];

        };
        reader.readAsDataURL(event.target.files[0]);
        if (this.selectedProjectType == this.projectTypeEnums.MOBILE) {
          const avatarForm = new FormData();
          const file = new File([event.target.files[0]], event.target.files[0].name);
          avatarForm.append('file', file);

          this.upploadMobileProjectAvater(avatarForm)
            .then((response => {
              this.project.photo.path = response.uri;
            }))
            .catch((error) => {

            })
        }
      }
    },

    removeAvatar() {
      this.project.imageSource = null;
      this.project.imageFile = null;
    },
  },

  created() {
    this.getProjectsList();
    this.getMembers();
    this.getOrgInfo(this.loggedInUserInfo.organizationId)
    this.orgConsumption(this.loggedInUserInfo.organizationId);
  },

  watch: {
    project: {
      deep: true,
      handler(value) {
        const regexURL = RegExp('(https?:\/\/)((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+@#]*)*(\\[;&a-z\\d%_.~+=-@]*)?(\\#[-a-z\\d_@]*)?$', 'i');
        const bUrl = value.base_url;
        if (this.selectedProjectType === this.projectTypeEnums.WEB) {

          if (bUrl != null && !bUrl.match(regexURL)) {
            this.errMsg.projectURL = 'Provide a valid URL';
            this.requiredField.baseURL = 'p-invalid';
            this.projectURLError = true;
            if (!bUrl.length) {
              this.errMsg.projectURL = '';
              this.requiredField.baseURL = '';
            }
          } else {
            this.errMsg.projectURL = '';
            this.requiredField.baseURL = '';
            this.projectURLError = false;
          }
        }
      }
    },
  },

  computed: {
    ...mapGetters({
      projectID: 'project/projectID',
      projectList: 'project/projectList',
      loggedInUserInfo: 'auth/loggedInUserInfo',
      orgInfo: 'organization/organizationData',
      subscriptionPlan: 'auth/subscriptionPlan',
      consumptionInfo: 'organization/consumptionInfo'
    }),

    role() {
      return this.loggedInUserInfo && this.loggedInUserInfo.roleDetails && this.loggedInUserInfo.roleDetails.role;
    },
    topbarItemsClass() {
      return [
        'topbar-items fadeInDown',
        {
          'topbar-items-visible': this.topbarMenuActive,
        },
      ];
    },
    display() {
      if ((this.$route && this.$route.path === '/dashboard') || (this.$route && this.$route.path === '/dashboard/create-project')) {
        return 'none';
      } else {
        return 'block';
      }
    },
  },
};
</script>
