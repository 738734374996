import { fetchVariablesByProjectId, getTestCaseVariables, getTestCaseVariablesMobile, updateTestCaseVariables, createVariable, deleteVariable, uniqueVariableNameCheck } from '@/api/variableService';

const state = {
  caseVariables: [],
  projectVariables: [],
  isCaseVariablesLoaded: false,
  isProjectVariablesLoaded: false,
};
const mutations = {
  SET_CASE_VARIABLES(state, res) {
    state.caseVariables = res;
  },
  FETCH_PROJECT_VARIABLES(state, variables) {
    state.projectVariables = variables;
  },
  SET_PROJECT_VARIABLES_FLAG(state, isProjectVariablesLoaded) {
    state.isProjectVariablesLoaded = isProjectVariablesLoaded;
  },
};
const actions = {
  async getProjectVariables(state, {projectId, type}) {
    let response;
    try {
      response = await fetchVariablesByProjectId(projectId, type);
      state.commit('SET_PROJECT_VARIABLES_FLAG', true);
      if (response?.data?.variables?.length) {
        state.commit('FETCH_PROJECT_VARIABLES', response.data.variables);
      } else if (response?.length) {
        state.commit('FETCH_PROJECT_VARIABLES', response);
      } else {
        state.commit('FETCH_PROJECT_VARIABLES', []);
      }
      console.log(response);
    } catch (error) {
      state.commit('FETCH_PROJECT_VARIABLES', []);
      state.commit('SET_PROJECT_VARIABLES_FLAG', false);
    }
    return {}
  },


  async actionDeleteVariable(state, {data, type}) {
    let response = [];

    try {
      let res = await deleteVariable(data, type);
      console.log('⛎ ⛎ ⛎ ⛎ ⛎ ⛎ ⛎ ⛎ ⛎ ⛎ ⛎ ⛎ ⛎ ⛎ ⛎ ⛎ actionDeleteVariable', res);
      response = [res, null];

    } catch (err) {
      console.log("🍎 🍎 🍎 🍎 🍎 🍎 🍎 🍎 🍎 🍎 🍎 🍎 🍎 🍎 🍎 🍎 actionDeleteVariable", err);
      response = [null, err.response];
    } finally {
      return response;
    }
  },

  async actionCreateVariable(state, {data, type}) {
    let response = [];

    try {
      let res = await createVariable(data, type);
      console.log('⛎ ⛎ ⛎ ⛎ ⛎ ⛎ ⛎ ⛎ ⛎ ⛎ ⛎ ⛎ ⛎ ⛎ ⛎ ⛎ actionCreateVariable', res);
      response = [res, null];

    } catch (err) {
      console.log("🍎 🍎 🍎 🍎 🍎 🍎 🍎 🍎 🍎 🍎 🍎 🍎 🍎 🍎 🍎 🍎 actionCreateVariable", err);
      response = [null, err.response];
    } finally {
      return response;
    }
  },
  async actionGetTestCaseVariables(state, {data, type}) {
    let response = [];
    state.commit('SET_CASE_VARIABLES', []);

    try {
      let res = null;

      if (type === 'mobile') {
        const resp = await getTestCaseVariablesMobile(data);
        res = resp.data.variables;
      } else {
        res = await getTestCaseVariables(data);
      }

      console.log('⛎ ⛎ ⛎ ⛎ ⛎ ⛎ ⛎ ⛎ ⛎ ⛎ ⛎ ⛎ ⛎ ⛎ ⛎ ⛎ actionGetTestCaseVariables', res);
      state.commit('SET_CASE_VARIABLES', res);
      response = [res, null];

    } catch (err) {
      console.log("🍎 🍎 🍎 🍎 🍎 🍎 🍎 🍎 🍎 🍎 🍎 🍎 🍎 🍎 🍎 🍎 actionGetTestCaseVariables", err);
      state.commit('SET_CASE_VARIABLES', []);
      response = [null, err.response];
    } finally {
      return response;
    }
  },

  async actionUpdateTestCaseVariable(state, {data, type}) {
    let response = [];
    try {
      let res = await updateTestCaseVariables(data, type);
      console.log('⛎ ⛎ ⛎ ⛎ ⛎ ⛎ ⛎ ⛎ ⛎ ⛎ ⛎ ⛎ ⛎ ⛎ ⛎ ⛎ action Update TestCase Variable', res);
      response = [res, null];

    } catch (err) {
      console.log("🍎 🍎 🍎 🍎 🍎 🍎 🍎 🍎 🍎 🍎 🍎 🍎 🍎 🍎 🍎 🍎 action Update TestCase Variable", err);
      response = [null, err.response];
    } finally {
      return response;
    }
  },

  async actionUniqueVariableNameCheck(state, data) {
    return uniqueVariableNameCheck(data).then(resBody => {
      if (resBody.status === 200) {
        return resBody.data;
      } else {
        return resBody.data;
      }
    }).catch(error => {

    })
  }
};

const getters = {
  caseVariables: state => state.caseVariables,
  projectVariables: state => state.projectVariables,
  isProjectVariablesLoaded: state => state.isProjectVariablesLoaded,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
