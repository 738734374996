import TestServiceRequest from '../utils/request/test_request';
import MobileServiceRequest from '@/utils/request/mobile_request';
import AgentServiceRequest from '@/utils/request/agent_request';

export const insertNewProject = async data => {
    // console.log('before-api-hit');
    return await TestServiceRequest.post(`/projects/save`, data)
        .then(res => {
            console.log('service-response-create-project', res);
            return res;
        })
        .catch(error => {
            console.log('CreateProjectService-saveCeateProject-Error', error);
            return error;
        });
};

export const insertMobileProject = data => {
    return MobileServiceRequest.post(`/projects`, data)
};

export const getProjectsList = async () => {
    // console.log('before-api-hit');
    return await TestServiceRequest.get(`/projects/list`)
        .then(res => {
            // console.log('projects-list-api-res===>', res);
            return res;
        })
        .catch(error => {
            console.log('projects-list-api-error===?', error);
            return error;
        });
};

export const projectUpdate = async newData => {
    return await TestServiceRequest.put('/projects/update', newData)
        .then(res => {
            console.log("project update api===>", res);
            return res;
        })
        .catch(error => {
            console.log("project update api error===>", error);
            return error;
        })
}

export const fetchTagsByProjectId = (projectId) => {
  return TestServiceRequest.get(`/projects/${projectId}/tags`)
}

export const fetchMobileProjects = () => {
    return MobileServiceRequest.get(`/projects`)
};

export const upploadMobileProjectApk = (projectId,fileForm,onUploadProgress) => {
    return MobileServiceRequest.post(`/projects/${projectId}/apks`,fileForm,{headers: {'Content-Type': 'multipart/form-data'}, onUploadProgress: onUploadProgress})
};
export const uploadMobileProjectAvatar = (fileForm) => {
    return MobileServiceRequest.post(`/static-files?file_type=project-avatar`,fileForm)
};

export const upploadMobileProjectApkToAgent = (projectId,fileForm) => {
    return AgentServiceRequest.post(`/${projectId}/apks`,fileForm);
};

export const uploadIosCertToAgent = (projectId, fileForm) => {
    return AgentServiceRequest.post(`/${projectId}/certificate`, fileForm)
};

export const uploadIosProvaToAgent = (projectId, fileForm) => {
    return AgentServiceRequest.post(`/${projectId}/provisioningProfile`, fileForm)
};

export const iosAgentCertChecker = (projectId, fileName = 'Testjet_cert.cer') => {
    return AgentServiceRequest.head(`/ios-configs/${projectId}/${fileName}`)
};

export const iosAgentProvaChecker = (projectId, fileName = 'Testjet_profile.mobileprovision') => {
  return AgentServiceRequest.head(`/ios-configs/${projectId}/${fileName}`)
};

export const iosAgentGenerateCsr = (projectId) => {
  return AgentServiceRequest.post(`/${projectId}/generateCsr`)
};


export const updateMobileProject = (projectId,projectData) => {
    return MobileServiceRequest.patch(`/projects/${projectId}`,projectData)
};

export const fetchProjectMembers = (orgId) => {
    return TestServiceRequest.post(`/projects/${orgId}`);
}

export const fetchMobileProjectMembers = (orgId) => {
    return MobileServiceRequest.post(`/projects/${orgId}`);
}
