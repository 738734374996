<template>
    <div class="main">
        <!--    <header>-->
        <!--      <img alt="Logo" src="/assets/layout/images/logo.png" class="topbar-logo" @click="$router.push('/')">-->
        <!--    </header>-->

        <section class="section sec-1">
            <p class="heading">Download Chromium Extension for TestJet Web Automation</p>
            <p class="description">Introducing the TestJet Web Automation extension for Chromium, the perfect tool for all your website testing and automation needs!</p>
            <p class="description">
                With TestJet, you can automate repetitive and time-consuming tasks like filling out forms, clicking buttons, and navigating through your website. It's easy to set up and use, and works seamlessly with Chromium, one of the most popular
                web browsers around.
            </p>
            <br />
            <br />
            <a :href="$extensionURL" target="_blank" rel="noreferrer" class="link">Go to the Chrome Webstore</a>
        </section>
        <br />
        <br />
        <section class="section sec-1">
            <p class="heading">Download Mobile Agent for TestJet Mobile Automation</p>
            <p class="description">
                TestJet’s powerful mobile automation agent allows you to easily and efficiently test your mobile applications on Android devices. With a user-friendly interface and advanced testing capabilities, Mobile Agent ensures that your mobile
                applications are functioning seamlessly and meeting your quality standards.
            </p>
            <p class="description">
                Whether you're a seasoned mobile developer or just getting started, TestJet Mobile Automation offers the flexibility and power you need to streamline your testing processes and deliver top-notch mobile applications to your users.
            </p>
            <br />
            <br />
            <div class="dwn-link-section">
                <div class="dwn-link" v-if="agentWindowsLink">
                    <div class="dwn-link-icon">
                        <img src="../assets/agent-installation-guide/windows-icon.png" alt="" />
                        <div>
                            <p>Agent Version {{agentBuildVersion}} (64-bit)</p>
                        </div>
                    </div>
                    <div class="dwn-btn-grp">
                        <div class="btn-grp" @click.prevent="download(agentWindowsLink)">
                            <i class="pi pi-download"></i>
                            <p>Download Windows</p>
                        </div>
                        <div>
                            <p>Windows 8, 10, 11</p>
                        </div>
                    </div>
                </div>
                <div class="dwn-link" v-if="agentDebianLink || agentRedHatLink">
                    <div class="dwn-link-icon">
                        <img src="../assets/agent-installation-guide/linux-icon.png" alt="" />
                        <div>
                            <p>Agent Version {{agentBuildVersion}} (64-bit)</p>
                        </div>
                    </div>
                    <div class="linux-agent">
                        <div class="xy" v-if="agentDebianLink">
                            <div class="btn-grp" @click.prevent="download(agentDebianLink)">
                                <i class="pi pi-download"></i>
                                <p>Download .deb</p>
                            </div>
                            <div>
                                <p>Debian, Ubuntu</p>
                            </div>
                        </div>
                        <div class="xy" v-if="agentRedHatLink">
                            <div class="btn-grp" @click.prevent="download(agentRedHatLink)">
                                <i class="pi pi-download"></i>
                                <p>Download .rpm</p>
                            </div>
                            <div>
                                <p>Red Hat, Fedora, SUSE</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="dwn-link" v-if="agentMacLink">
                    <div class="dwn-link-icon">
                        <img src="../assets/agent-installation-guide/apple-icon.png" alt="" />
                        <div>
                            <p>Agent Version {{agentBuildVersion}} (64-bit intel + Apple Silicon)</p>
                        </div>
                    </div>
                    <div class="dwn-btn-grp">
                        <div class="btn-grp" @click.prevent="download(agentMacLink)">
                            <i class="pi pi-download"></i>
                            <p>Download Mac</p>
                        </div>
                        <div>
                            <p>macOS 14.2.1+</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'DownloadAssets',
    mounted() {
        let sidePanel = document.querySelector('.layout-menu-container');
        let menuToggleButton = document.querySelector('#menu-button');
        let layout = document.querySelector('.menu-layout-static');

        sidePanel.style.display = 'none';
        menuToggleButton.style.display = 'none';
        layout.classList.add('layout-menu-static-inactive');
    },
    data: () => ({
      agentBuildVersion: process.env.VUE_APP_AGENT_BUILD_VERSION,
      agentWindowsLink: process.env.VUE_APP_WINDOWS_LINK,
      agentDebianLink: process.env.VUE_APP_DEBIAN,
      agentRedHatLink: process.env.VUE_APP_RED_HAT,
      agentMacLink: process.env.VUE_APP_MAC,
    }),
    methods: {
      download(url) {
        const name = `testjet-agent`;
        const link = document.createElement('a');
        link.href = `${url}`;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
    },
};
</script>

<style scoped lang="scss">
$color-black: #282828;
$color-bg: #e2eff6;
$color-blue: #00a0ee;
$color-red: #e25686;

.layout {
    background: linear-gradient(90.13deg, #ffffff 0.06%, #eef9ff 99.83%);
}

.main {
    max-width: 75%;
    width: 100%;
    margin: 0 auto;
    padding-bottom: 30px;

    header {
        padding: 16px 0 16px 0;
        width: 100%;

        .topbar-logo {
            cursor: pointer;
        }
    }

    .section {
        margin: 3em 0 0 0;
        display: grid;
    }
    .dwn-link-section {
        display: flex;
        .dwn-link {
            display: grid;
            justify-content: center;
            align-items: center;
            width: 459px;
            height: 289px;
            box-shadow: 4px 4px 8px 4px #3e87ae1c;
            border-radius: 8px;
            margin: 15px;
            .dwn-link-icon {
                display: grid;
                justify-items: center;
                img {
                    width: 80px;
                    height: 80px;
                }
            }
            .dwn-btn-grp {
                display: grid;
                justify-items: center;
                margin-top: -85px;
                p {
                  margin-top: 8px;
                }
            }

            .btn-grp {
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                background-color: #00a0ee;
                color: #ffffff;
                //text-decoration: underline;
                font-size: 18px;
                padding: 16px, 24px, 16px, 24px;
                border-radius: 5px;
                font-weight: 600;
                width: 209px;
                height: 52px;
                i {
                    margin-right: 8px;
                }
            }
            .linux-agent {
                display: flex;
                margin-top: -85px;
                .xy {
                    display: grid;
                    justify-items: center;
                    margin: 8px;
                }
                p {
                  margin-top: 8px;
                }
            }
        }
    }

    .description {
        color: $color-black;
        font-weight: 500;
        font-size: 16px;
        margin-top: 1.5em;
    }

    .heading {
        //max-width: 60%;
        background: $color-bg;
        height: 72px;
        font-weight: 600;
        font-size: 24px;
        display: grid;
        place-items: center;
        color: $color-blue;
    }
}
</style>
