let projectId = localStorage.getItem('projectId') ? localStorage.getItem('projectId') : null;

export const ClientAdminMobileMenu = [
  {
    label: 'Overview',
    icon: 'fas fa-columns',
    to: `/project-overview-mobile/${projectId}`,
  },
  {
    label: 'Test Cases',
    icon: 'fas fa-list-ul',
    to: '/test-cases-mobile',
  },
  {
    label: 'Test Runs',
    icon: 'fas fa-play',
    to: '/test-runs-mobile',
  },
  {
    label: 'Variables',
    icon: 'fas fa-file-code',
    to: '/variables',
  },
  {
      label: 'Reports',
      icon: 'fas fa-chart-pie',
      // to: '/reports/create-new',
      items: [
          {
              label: 'Create New',
              icon: 'fas fa-stream',
              to: '/mobile-reports/create-new',
          },
          {
              label: 'All Reports',
              icon: 'fas fa-sitemap',
              to: '/mobile-reports/all',
          },
      ],
  },
  {
    label: 'Settings',
    icon: 'fas fa-cog',
    to: '/settings',
  },
];
