import AuthServiceRequest from '../utils/request/auth_request';
import {removeToken} from '../utils/cookies';

export const signIn = async userInfo => {
    return await AuthServiceRequest.post('/auth/signin', userInfo)
        .then(res => {
            console.log('login api', res);
            return res;
        })
        .catch(error => {
            console.log('login error api', error.response);

            return error.response;
        });
};

export const logout = async userInfo => {
    return await AuthServiceRequest.post('/auth/logout', userInfo)
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response;
        });
};

export const signup = async userInfo => {
    return await AuthServiceRequest.post('/auth/signup', userInfo)
        .then(res => {
            console.log('signup api res', res);
            return res;
        })
        .catch(error => {
            return error.response;
        });
};

export const resetPasswordLink = async emailInfo => {
    return await AuthServiceRequest.post('/email/verify', emailInfo)
        .then(res => {
            console.log('reset password email varification res', res);
            return res;
        })
        .catch(error => {
            return error.response;
        });
};

export const sendEmailVerificationLink = async emailInfo => {
    return await AuthServiceRequest.post('/auth/verify-email', emailInfo)
        // .then((res) => {
        //     console.log(res)
        //     this.confirmation = true
        // })
        // .catch((err) => {
        //     console.log(err)
        //     this.somethingWrong = true
        // })
        .then(res => {
            console.log('email varify res', res);
            return res;
        })
        .catch(error => {
            return error.response;
        });
};

export const resendEmailVerificationLink = async email => {
    removeToken();
    return await AuthServiceRequest.post('/auth/send-verification-email', email)
        .then(res => {
            console.log('email resend res', res);
            return res;
        })
        .catch(error => {
            return error.response;
        });
};

export const resetPassword = async resetPasswordInfo => {
    return await AuthServiceRequest.put('/email/reset-password', resetPasswordInfo)
        .then(res => {
            console.log('reset password res', res);
            return res;
        })
        .catch(error => {
            return error.response;
        });
};

export const fetchCountryList = async () => {
    return await AuthServiceRequest.get('/countries/list');
}

export const authInfoFetch = async () => {
    return await AuthServiceRequest.get('/auth/user/info')
}

export const getCliAuthToken = async () => {
  return await AuthServiceRequest.get(`/auth/cli-auth-token`);
}

export const authService = {
    signIn,
    logout,
    signup,
    resetPasswordLink,
    sendEmailVerificationLink,
    resendEmailVerificationLink,
    resetPassword,
    fetchCountryList,
    getCliAuthToken
};
