export class ExtensionNotFoundException extends Error {
    constructor(message) {
        super(message);
        this.name = ExtensionNotFoundException.prototype.constructor.name;
        this.type = 'extensionErrror';
    }
}

export class ExtensionRuntimeConnectException extends Error {
    constructor(message) {
        super(message);
        this.name = ExtensionNotFoundException.prototype.constructor.name;
        this.type = 'extensionErrror';
    }
}

