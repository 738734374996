<template>
    <div v-if="dataLoaded" class="data-view-section">
        <div class="section-header">
            <div class="vew-action">
                <div id="table-view" :class="tableActiveViewClass" @click="toggleView(1)"><i class="fas fa-columns"></i>
                </div>
                <div id="code-view" :class="codeActiveViewClass" @click="toggleView(2)"><i class="fas fa-file-code"></i>
                </div>
            </div>
        </div>
        <DataTable v-if="viewTableSection" :value="tableData" paginator :rows="5" :rowsPerPageOptions="[5, 10, 20, 50]">
            <template #empty>
              <div style="text-align: center; display: grid; place-items: center;">
                <span style="color: #282828; font-size: 1rem; font-weight: bold;">No Data</span>
              </div>
            </template>
            <Column v-for="col of columns" :field="col.field" :header="col.header" :key="col.field"></Column>
        </DataTable>
        <div v-if="viewCodeSection" class="code-view-section">
            <pre><code>{{ JSON.stringify(response, null, 3) }}</code></pre>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            dataLoaded: false,
            activeViewId: 1,
            tableActiveViewClass: 'active-view',
            codeActiveViewClass: '',
            viewTableSection: false,
            viewCodeSection: false,
            tableData: [],
            columns: [],
            jsonData: null,
        }
    },

    props: {
        response: {
            type: Object || null,
            default: null,
        },
    },

    methods: {
        toggleView(view) {
            if (view !== this.activeViewId) {
                if (view == 1) {
                    this.activeViewId = view;
                    this.tableActiveViewClass = 'active-view';
                    this.codeActiveViewClass = '';
                    this.viewTableSection = true;
                    this.viewCodeSection = false;
                }
                else {
                    this.activeViewId = view;
                    this.codeActiveViewClass = 'active-view';
                    this.tableActiveViewClass = '';
                    this.viewCodeSection = true;
                    this.viewTableSection = false;
                }
            }
        },
    },

    watch: {
        response: {
            handler(value) {
                if (value.length > 0) {
                    let columns = [];
                    let columnsProperty = [];
                    for (const row of value) {
                        let keys = Object.keys(row);
                        if (!columnsProperty.length) {
                            columnsProperty = keys;
                        }
                        else {
                            for (const key of keys) {
                                if (!columnsProperty.includes(key)) {
                                    columnsProperty.push(key);
                                }
                            }
                        }
                    }

                    for (const property of columnsProperty) {
                        let columnObj = {
                            field: property,
                            header: property.toUpperCase(),
                        }

                        columns.push(columnObj);
                    }

                    this.tableData = value;
                    this.columns = columns;
                    this.dataLoaded = true;
                    this.viewTableSection = true;
                }
                else {
                    this.tableData = [];
                    this.columns = [];
                    this.dataLoaded = true;
                }
            },
            deep: true,
        },
    }
}
</script>

<style></style>