<template>
  <div>
    <div class="create-newtest-run-modal">
      <!-- <button @click="logged()">log</button> -->
      <div class="run-name p-fluid">
        <label for="runName">Run Name <span class="required-icon"> *</span></label>
        <InputText type="text" v-model="testRunName" :class="[requiredField.testRunName, errorMsg.testRunName ? 'p-invalid' : '']" placeholder="Enter Test Run Name"/>
        <small class="p-invalid">{{ errorMsg.testRunName }}</small>
      </div>

      <div class="run-name p-fluid">
        <label for="runName">Tag Name</label>
        <AutoComplete v-model="selectedTag" :suggestions="filteredTags" @complete="searchTag($event)" @item-select="itemSelect($event)" @keydown.enter="itemEnter($event)"/>
        <Chip :label="tag" v-for="tag in tags" :key="tag" removable @remove="onRemoveTag(tag)"
              :style="{ marginTop: '1rem' }"/>
      </div>

      <!-- <div class="run-name p-fluid">
        <label>Filter by</label>
        <Dropdown v-model="selectedDays" :options="durationDays" optionValue="id" optionLabel="name" style="width: 100%"
                  placeholder="Search Duration"/>
      </div> -->

      <div class="test-search">
        <label>Test Name <span class="required-icon"> *</span></label>
        <div class="search-container p-fluid">
          <MultiSelect v-model="selectedCaseForRun" :options="mobileTestCaseList" optionLabel="name" dataKey="_id"
                       :filter="true" filterIcon="pi pi-search" placeholder="Search Test Case" :class="requiredField.checkedTestCases"
                       :showClear="true"/>
          <div class="search-suggestions">
            <Chip :label="selectedCase.name" v-for="(selectedCase) in selectedCaseForRun"
                  :key="selectedCase._id" removable @remove="onRemoveTestcaseTag(selectedCase._id)"/>
          </div>
        </div>
        <!-- <pre>{{ selectedCaseForRun }}</pre> -->
      </div>

      <Button label="CREATE TEST RUN" @click="clickTestRunCreate" :disabled="testRunBtnDisabled"/>
    </div>
  </div>
</template>

<script>
import TjCustomChipsVue from '@/components/TjCustomChips.vue';
import {mapActions, mapGetters} from 'vuex';
import {getSelectedCurrentProjectId} from '@/utils/localstorage';
import organization from '@/store/modules/organization';


export default {
  name: 'MobileTestRunCreate',
  components: {TjCustomChipsVue},

  props: {
    testCaseList: {
      default: [],
      type: Array,
    },
    tableCheckedCasesForRun: {
      default: [],
      type: Array,
    },
    parentSource: {
      type: String,
    },
  },

  data() {
    return {
      noSpecialCharsRegex: /^[A-Za-z0-9 ]+$/,
      errorMsg: {},
      testRunBtnDisabled: false,
      tagName: null,
      selectedTag: null,
      tags: [],
      filteredTags : null,
      testRunName: '',
      selectedCaseForRun: [...this.tableCheckedCasesForRun],

      requiredField: {
        testRunName: '',
        checkedTestCases: '',
      },
      selectedDays: 'all',
      durationDays: [
        {name: 'Last 24 hours', id: '1'},
        {name: 'Last 7 Days', id: '7'},
        {name: 'Last 30 Days', id: '30'},
        {name: 'Last 3 Months', id: '90'},
        {name: 'All', id: 'all'},
      ],
    };
  },

  mounted() {
    this.getAllTestCases(this.selectedDays);
    this.getTagsByProjectId(this.projectID);
  },

  unmounted() {
    this.selectedCaseForRun = [];
  },

  methods: {
    ...mapActions({
      fetchTestCases: 'testCase/actionFetchCasesListForDropdown',
      actionGetMobileCaseList: 'mobileTestCases/actionGetMobileCaseList',
      insertTestRun: 'testRun/actionInsertTestRun',
      fetchTestRunList: 'testRun/actionFetchTestRunList',
      getTagsByProjectId: 'project/actionFetchTagsByProjectId',
      actionAddTestRun: 'mobileTestRun/actionAddTestRun',
      actionFetchTestRuns: 'mobileTestRun/actionFetchTestRuns',
    }),
    searchTag(event) {
      setTimeout(() => {
          if (!event.query.trim().length) {
            this.filteredTags = [...this.tagList];
          }
          else {
            this.filteredTags = this.tagList.filter((tag) => {
                return tag.toLowerCase().startsWith(event.query.toLowerCase());
            });
          }
      }, 250);
    },
    itemSelect(event){
      console.log('item selected',event)
      let value = event.value;
      if (value) {
        if (this.tags && this.tags.length) {
          if (!this.tags.includes(value)) {
            this.tags = [...this.tags, value];
            this.selectedTag = null;
          } else {
            this.$toast.add({severity: 'warn', detail: 'Tag already exist.', life: 3000});
          }
        } else {
          this.tags = [...this.tags, value];
          this.selectedTag = null;
        }
      }
    },
    itemEnter(event){
      let value = event.target.value;
      if (value) {
        let highlitedList = document.getElementsByClassName('p-autocomplete-item p-highlight');
        if (highlitedList?.length){
          let highlitedItem = highlitedList[0];
          value = highlitedItem.outerText;
        }

        if (this.tags && this.tags.length) {
          if (!this.tags.includes(value)) {
            this.tags = [...this.tags, value];
            this.selectedTag = null;
          } else {
            this.$toast.add({severity: 'warn', detail: 'Tag already exist.', life: 3000});
          }
        } else {
          this.tags = [...this.tags, value];
          this.selectedTag = null;
        }
      }
      // To close the overlay
      let btn = document.createElement("button");
      document.body.appendChild(btn);
      btn.click();
    },

    showSuccess(summary, msgDetail) {
      this.$toast.add({severity: 'success', summary: summary, detail: msgDetail, life: 5000});
    },

    showErrorMsg(msgDetail) {
      this.$toast.add({severity: 'error', summary: 'Error Message', detail: msgDetail, life: 5000});
    },
    showWarningMsg(msgDetail) {
      this.$toast.add({severity: 'warn', summary: 'Warn Message', detail: msgDetail, life: 5000});
    },

    handleEnterTag() {
      if (this.tagName !== null) {
        if (this.tags && this.tags.length) {
          if (!this.tags.includes(this.tagName)) {
            this.tags = [...this.tags, this.tagName];
            this.tagName = null;
          } else {
            this.$toast.add({severity: 'warn', detail: 'Tag already exist.', life: 3000});
          }
        } else {
          this.tags = [...this.tags, this.tagName];
          this.tagName = null;
        }
      }
    },

    onRemoveTag(label) {
      this.tags = this.tags.filter(tag => tag !== label);
    },

    onRemoveTestcaseTag(testCaseId) {
      this.selectedCaseForRun = this.selectedCaseForRun.filter(item => item && item._id !== testCaseId);
    },

    clickTestRunCreate() {
      if (!this.isRequiredFieldEmpty()) {
        if(!this.noSpecialCharsRegex.test(this.testRunName)) {
          this.errorMsg['testRunName'] = 'Special characters are not allowed'
          return
        }

        let testcaseIdArr = [];
        for (let index = 0; index < this.selectedCaseForRun.length; index++) {
          // testcaseIdArr.push(this.selectedCaseForRun[index]._id);
          testcaseIdArr.push(this.selectedCaseForRun[index]._id);
        }
        // test_case_ids: testcaseIdArr,
        let selectedProject = this.projectList?.find(project=> project._id == getSelectedCurrentProjectId());

        let testRunObj = {
          name: this.testRunName,
          projectID: selectedProject._id,
          appInfo: {name : selectedProject?.appInfo?.fileName || '', activity : selectedProject?.appInfo?.activity || '', package: selectedProject?.appInfo?.package || ''},
          testCases: testcaseIdArr,
          tags: this.tags,
          organizationId: this.loggedInUserInfo.organizationId,
          deviceType: selectedProject.type
        };

        this.testRunBtnDisabled = true;
        this.actionAddTestRun(testRunObj)
            .then(resBody => {
              this.testRunBtnDisabled = false;
              if (resBody){
                this.selectedCaseForRun = [];
                this.testRunName = '';
                testcaseIdArr = [];
                this.showSuccess('Successful', 'Testrun Created Succcessfully !');
                if (resBody.data && resBody.data.testRun) {
                  this.$emit('displayOffCreateTestRun', resBody.data.testRun);
                }

              }
              else {
                this.showErrorMsg('Test Run Create Unsuccessful.');
              }

            })
            .catch(err => {
              if(err.response?.data?.error?.code == 56003){
                this.showErrorMsg('Test Run Limit Exceeded!');
              }else{
                this.showErrorMsg('Test Run Create Unsuccessful.');
              }
              this.testRunBtnDisabled = false;
            });
      }
    },

    isRequiredFieldEmpty() {
      let requiredFieldEmpty = false;
      if (this.testRunName == '') {
        requiredFieldEmpty = true;
        this.requiredField.testRunName = 'p-invalid';
      }
      if (this.selectedCaseForRun && this.selectedCaseForRun.length < 1) {
        requiredFieldEmpty = true;
        this.requiredField.checkedTestCases = 'p-invalid';
      }
      return requiredFieldEmpty;
    },
    resetRequiredField(fieldName) {
      this.requiredField[fieldName] = '';
    },
    getAllTestCases(dur) {
      const reqBody = {
        projectID: getSelectedCurrentProjectId(),
        duration: dur,
      };
      this.actionGetMobileCaseList(reqBody)
    },
  },
  computed: {
    ...mapGetters({
      projectID: 'project/projectID',
      filterDuration: 'testRun/filterDuration',
      mobileTestCaseList: 'mobileTestCases/mobileTestCaseList',
      tagList: 'project/tagList',
      projectList: 'project/projectList',
      loggedInUserInfo: 'auth/loggedInUserInfo',
    }),
  },

  watch: {
    testRunName() {
      this.resetRequiredField('testRunName');
      delete this.errorMsg['testRunName']
    },
    selectedCaseForRun() {
      this.resetRequiredField('checkedTestCases');
    },
    selectedDays(value) {
      this.getAllTestCases(value)
    },
  },
};
</script>

<style></style>
