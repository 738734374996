/* eslint-disable no-unused-vars */
import TestServiceRequest from '../utils/request/test_request';

export const insertNewCategory = async (data) => {
    return await TestServiceRequest.post(`/v2/categories`, data);
};
export const getCategories = async (projectId) => {
    return await TestServiceRequest.get(`/v2/categories/projects/${projectId}`)
        .then(res => {
            return res;
        })
        .catch(error => {
            console.log('TestCaseService--getCategorys--Error', error);
        });
};
export const deleteCategoryById = async (id) => {
    return await TestServiceRequest.delete(`/v2/categories/${id}`);
};
export const updateCategoryById = async (data) => {
    return await TestServiceRequest.put(`/v2/categories/${data._id}`, data);
};