<template>
  <div :class="['code-editor-wrapper']">
    <div class="readmode" :style="{ display: readonly ? 'block' : 'none' }"></div>
    <Codemirror v-model="jsonData" :indent-with-tab="true" :tab-size="2" :options="editorOptions"></Codemirror>
  </div>
</template>

<script>
import { Codemirror } from "vue-codemirror";
import "@codemirror/lang-javascript";
import { basicSetup } from 'codemirror';
'@codemirror/view'

export default {
  name: "HelloWorld",
  components: {
    Codemirror
  },
  props: {
    code: {
      type: String,
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.jsonData = this.code;
  },
  data() {
    return {
      jsonData: '',
      editorOptions: {
        mode: "application/json",
        lineNumbers: true,
        line: true,
        lineWrapping: true,
        foldGutter: true,
        extensions: [basicSetup],
        lint: true,
        gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter", 'CodeMirror-lint-markers'],
      }
    };
  },
  watch: {
    jsonData(val) {
      this.$emit("updateCode", val);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vue-codemirror {
  border: 1px solid #ddd;
}
</style>
