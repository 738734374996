<template>
  <AuthComponentLayout>
    <div class="login-container check-email">
      <div class="login-container-header">Success</div>
      <div class="input p-fluid">
        <!-- <div class="email">
            <label for="email">Email</label>
            <InputText id="email" v-model="email" type="email" @keyup.enter="clickForgotPasswordHandler" placeholder="Enter Email"
              :class="requiredFieldState?.email" />
            <small class="p-invalid" v-if="msg?.email">{{ msg?.email }}</small>
          </div> -->
        <div class="email-sent">
          <svg viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="22.5" cy="22.5" r="22.5" fill="#045AFF" />
            <path d="M15 22.0909L20.7683 27.2288C21.2244 27.635 21.9349 27.5411 22.2698 27.0303L29.5 16" stroke="#F8FBFF"
              stroke-width="3" stroke-linecap="round" />
          </svg>

          <p>Your password has been successfully changed. Please use your new password for future logins.</p>
        </div>

        <Button class="login-btn" label="Back to Log in" @click="$router.replace('/login')" :disabled="false" />
      </div>
    </div>
  </AuthComponentLayout>
</template>

<script>
//! OLD AUTH COMPONENT LAYOUT ( this file still exists delete it when necessary && is signup design is changed! )
// import AuthComponentLayout from "../components/AuthComponentLayout.vue";
// import LoginForm from "../components/guest/LoginForm.vue";

//! NEW AUTH COMPONENT LAYOUT
import AuthComponentLayout from "../components/authPageComponents/AuthComponentLayout.vue";

export default {
  components: {
    AuthComponentLayout,
  }
}
</script>

<style></style>
