export const TJSupportMenu = [
    {
        label: 'Clients',
        icon: 'fas fa-user-tie',
        to: `/clients`,
    },
    {
        label: 'Subscription',
        icon: 'fas fa-money-check-alt',
        to: '/subscription-packages',
    },
    {
        label: 'Features',
        icon: 'fas fa-wrench',
        to: '/tj-admin-features',
    },
    {
        label: 'Settings',
        icon: 'fas fa-cog',
        to: '/settings',
    },
];
