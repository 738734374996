<template>
  <!-- <div class="newTest"> -->
  <div class="create-subscription-wrapper">
    <div class="card">
      <div class="header-container">
        <div class="header-wrapper">
          <div class="header-info">
            <div class="back-btn">
              <router-link to="/subscription-packages">
                <Button icon="fas fa-chevron-left" class="p-button-rounded p-button-outlined"/>
              </router-link>
            </div>
            <p>{{ viewType === 'insert' ? 'Create New' : 'Update' }} Subscription Package</p>
          </div>
        </div>
      </div>

      <div class="p-grid subscription-form">
        <div class="p-col-4">
          <div class="subscription-info-container p-fluid">
            <h4>Subscription Information</h4>

            <div class="form-input">
              <div class="input p-fluid">
                <label for="name">Package Name <span class="required-icon"> *</span></label>
                <InputText type="text" placeholder="Enter Package Name" v-model="packageName"
                           :class="msg.packageName ? 'p-error' : ''"/>
                <small class="p-invalid" v-if="msg.packageName">{{ msg.packageName }}</small>
              </div>
            </div>

            <!-- <div class="form-input">
                <div class="input p-fluid">
                    <label for="name">Package Description <span class="required-icon"> *</span></label>
                    <Textarea placeholder="Enter Description" :autoResize="false" :rows="2" v-model="packageDescription" :class="error ? 'p-error' : ''"></Textarea>
                    <small class="p-invalid" v-if="msg.packageDescription">{{ msg.packageDescription }}</small>
                </div>
            </div> -->

            <div class="form-input">
              <div class="input p-fluid">
                <label for="name">Price <span class="required-icon"> *</span></label>
                <InputNumber placeholder=" Enter Price (USD)" v-model="price" :class="msg.price ? 'p-error' : ''"/>
                <small class="p-invalid" v-if="msg.price">{{ msg.price }}</small>
              </div>
            </div>

            <div class="form-input">
              <div class="input p-fluid">
                <label for="name">Package Duration <span class="required-icon"> *</span></label>
                <InputNumber placeholder=" Enter Duration (Days)" v-model="packageDuration"
                             :class="msg.packageDuration ? 'p-error' : ''"/>
                <small class="p-invalid" v-if="msg.packageDuration">{{ msg.packageDuration }}</small>
              </div>
            </div>
            <!-- <div class="radio-group-container">
                <div class="p-grid p-lg-12 radio-group-wrapper">
                    <div class="p-lg-6"><h4>Package Status</h4></div>
                    <div class="p-lg-3">
                        <div class="p-field-radiobutton">
                            <RadioButton id="option2" name="option" value="true" v-model="packageStatus" />
                            <label for="option2">Active</label>
                        </div>
                    </div>
                    <div class="p-lg-3">
                        <div class="p-field-radiobutton">
                            <RadioButton id="option3" name="option" value="false" v-model="packageStatus" />
                            <label for="option3">Inactive</label>
                        </div>
                    </div>
                    <small class="p-invalid" v-if="msg.packageStatus">{{ msg.packageStatus }}</small>
                </div>

                <div class="p-grid p-lg-12 radio-group-wrapper">
                    <div class="p-lg-6"><h4>Package Renewal</h4></div>
                    <div class="p-lg-3">
                        <div class="p-field-radiobutton">
                            <RadioButton id="option2" name="option" value="true" v-model="packageRenewal" />
                            <label for="option2">Yes</label>
                        </div>
                    </div>
                    <div class="p-lg-3">
                        <div class="p-field-radiobutton">
                            <RadioButton id="option3" name="option" value="false" v-model="packageRenewal" />
                            <label for="option3">No</label>
                        </div>
                    </div>
                    <small class="p-invalid" v-if="msg.packageRenewal">{{ msg.packageRenewal }}</small>
                </div>

                <div class="p-grid p-lg-12 radio-group-wrapper">
                    <div class="p-lg-6"><h4>Upgradable</h4></div>
                    <div class="p-lg-3 radio-group">
                        <div class="p-field-radiobutton yes">
                            <RadioButton id="option2" name="option" value="true" v-model="upgradable" />
                            <label for="option2">Yes</label>
                        </div>
                    </div>
                    <div class="p-lg-3 radio-group">
                        <div class="p-field-radiobutton no">
                            <RadioButton id="option3" name="option" value="false" v-model="upgradable" />
                            <label for="option3">No</label>
                        </div>
                    </div>
                    <small class="p-invalid" v-if="msg.upgradable">{{ msg.upgradable }}</small>
                </div>
            </div> -->
          </div>
        </div>

        <div class="p-col-4">
          <div class="subscription-info-container p-fluid">
            <h4>Subscription Configuration</h4>

            <div class="form-input">
              <div class="input p-fluid">
                <label for="name">Project Limit <span class="required-icon"> *</span></label>
                <InputNumber placeholder=" Enter Limit (Number)" v-model="projectLimit"
                             :class="msg.projectLimit ? 'p-error' : ''"/>
                <small class="p-invalid" v-if="msg.projectLimit">{{ msg.projectLimit }}</small>
              </div>
            </div>

            <div class="form-input">
              <div class="input p-fluid">
                <label for="name">Test Case Limit <span class="required-icon"> *</span></label>
                <InputNumber placeholder=" Enter Limit (Number)" v-model="testCaseLimit"
                             :class="msg.testCaseLimit ? 'p-error' : ''"/>
                <small class="p-invalid" v-if="msg.testCaseLimit">{{ msg.testCaseLimit }}</small>
              </div>
            </div>

            <div class="form-input">
              <div class="input p-fluid">
                <label for="name">Test Run Limit <span class="required-icon"> *</span></label>
                <InputNumber placeholder=" Enter Limit (Number)" v-model="testRunLimit"
                             :class="msg.testRunLimit ? 'p-error' : ''"/>
                <small class="p-invalid" v-if="msg.testRunLimit">{{ msg.testRunLimit }}</small>
              </div>
            </div>

            <div class="form-input">
              <div class="input p-fluid">
                <label for="name">Test Step Limit <span class="required-icon"> *</span></label>
                <InputNumber placeholder=" Enter Limit (Number)" v-model="testStepLimit"
                             :class="msg.testStepLimit ? 'p-error' : ''"/>
                <small class="p-invalid" v-if="msg.testStepLimit">{{ msg.testStepLimit }}</small>
              </div>
            </div>

            <div class="form-input">
              <div class="input p-fluid">
                <label for="name">Test Execution Limit <span class="required-icon"> *</span></label>
                <InputNumber placeholder=" Enter Limit (Number)" v-model="testExecutionLimit"
                             :class="msg.testExecutionLimit ? 'p-error' : ''"/>
                <small class="p-invalid" v-if="msg.testExecutionLimit">{{ msg.testExecutionLimit }}</small>
              </div>
            </div>

            <div class="form-input">
              <div class="input p-fluid">
                <label for="name">Team Member Limit <span class="required-icon"> *</span></label>
                <InputNumber placeholder=" Enter Limit (Number)" v-model="teamMemberLimit"
                             :class="msg.teamMemberLimit ? 'p-error' : ''"/>
                <small class="p-invalid" v-if="msg.teamMemberLimit">{{ msg.teamMemberLimit }}</small>
              </div>
            </div>

            <div class="form-input">
              <div class="input p-fluid">
                <label for="name">Mobile Project Limit <span class="required-icon"> *</span></label>
                <InputNumber placeholder=" Enter Limit (Number)" v-model="mobileProjectLimit"
                             :class="msg.mobileProjectLimit ? 'p-error' : ''"/>
                <small class="p-invalid" v-if="msg.mobileProjectLimit">{{ msg.mobileProjectLimit }}</small>
              </div>
            </div>

            <div class="form-input">
              <div class="input p-fluid">
                <label for="name">Mobile Test Case Limit <span class="required-icon"> *</span></label>
                <InputNumber placeholder=" Enter Limit (Number)" v-model="mobileTestCaseLimit"
                             :class="msg.mobileTestCaseLimit ? 'p-error' : ''"/>
                <small class="p-invalid" v-if="msg.mobileTestCaseLimit">{{ msg.mobileTestCaseLimit }}</small>
              </div>
            </div>

            <div class="form-input">
              <div class="input p-fluid">
                <label for="name">Mobile Test Run Limit <span class="required-icon"> *</span></label>
                <InputNumber placeholder=" Enter Limit (Number)" v-model="mobileTestRunLimit"
                             :class="msg.mobileTestRunLimit ? 'p-error' : ''"/>
                <small class="p-invalid" v-if="msg.mobileTestRunLimit">{{ msg.mobileTestRunLimit }}</small>
              </div>
            </div>


            
            <!-- <div class="form-input">
                <div class="input p-fluid">
                    <label for="name">SMS Limit <span class="required-icon"> *</span></label>
                    <InputText type="number" placeholder=" Enter Limit (Number)" v-model="smsLimit" :class="error ? 'p-error' : ''" />
                    <small class="p-invalid" v-if="msg.smsLimit">{{ msg.smsLimit }}</small>
                </div>
            </div>

            <div class="form-input">
                <div class="input p-fluid">
                    <label for="name">Email Limit<span class="required-icon"> *</span></label>
                    <InputText type="number" placeholder="Enter Limit (Number)" v-model="emailLimit" :class="error ? 'p-error' : ''" />
                    <small class="p-invalid" v-if="msg.emailLimit">{{ msg.emailLimit }}</small>
                </div>
            </div> -->
          </div>
        </div>
        <div class="p-col-4">
          <div class="radio-group-container">
            <div class="p-grid p-lg-12 p-px-0 p-py-0 radio-group-wrapper">
              <div class="p-lg-6 p-px-0 p-py-0"><h4>Package Status</h4></div>
              <div class="p-lg-3 p-px-0 p-py-0">
                <div class="p-field-radiobutton">
                  <RadioButton id="option2" name="option" value="true" v-model="packageStatus"/>
                  <label for="option2">Active</label>
                </div>
              </div>
              <div class="p-lg-3 p-px-0 p-py-0">
                <div class="p-field-radiobutton">
                  <RadioButton id="option3" name="option" value="false" v-model="packageStatus"/>
                  <label for="option3">Inactive</label>
                </div>
              </div>
              <small class="p-invalid" v-if="msg.packageStatus">{{ msg.packageStatus }}</small>
            </div>

            <div class="p-grid p-lg-12 p-px-0 p-py-0 radio-group-wrapper">
              <div class="p-lg-6 p-px-0 p-py-0"><h4>Package Renewal</h4></div>
              <div class="p-lg-3 p-px-0 p-py-0">
                <div class="p-field-radiobutton">
                  <RadioButton id="option2" name="option" value="true" v-model="packageRenewal"/>
                  <label for="option2">Yes</label>
                </div>
              </div>
              <div class="p-lg-3 p-px-0 p-py-0">
                <div class="p-field-radiobutton">
                  <RadioButton id="option3" name="option" value="false" v-model="packageRenewal"/>
                  <label for="option3">No</label>
                </div>
              </div>
              <small class="p-invalid" v-if="msg.packageRenewal">{{ msg.packageRenewal }}</small>
            </div>

            <div class="p-grid p-lg-12 p-px-0 p-py-0 radio-group-wrapper">
              <div class="p-lg-6 p-px-0 p-py-0"><h4>Upgradable</h4></div>
              <div class="p-lg-3 p-px-0 p-py-0 radio-group">
                <div class="p-field-radiobutton yes">
                  <RadioButton id="option2" name="option" value="true" v-model="upgradable"/>
                  <label for="option2">Yes</label>
                </div>
              </div>
              <div class="p-lg-3 p-px-0 p-py-0 radio-group">
                <div class="p-field-radiobutton no">
                  <RadioButton id="option3" name="option" value="false" v-model="upgradable"/>
                  <label for="option3">No</label>
                </div>
              </div>
              <small class="p-invalid" v-if="msg.upgradable">{{ msg.upgradable }}</small>
            </div>
            <div class="p-grid p-lg-12 p-px-0 p-py-0 radio-group-wrapper">
              <div class="p-lg-6 p-px-0 p-py-0"><h4>Mobile App Testing</h4></div>
              <div class="p-lg-3 p-px-0 p-py-0 radio-group">
                <div class="p-field-radiobutton yes">
                  <RadioButton id="option2" name="option" value="true" v-model="mobileAppTesting"/>
                  <label for="option2">Yes</label>
                </div>
              </div>
              <div class="p-lg-3 p-px-0 p-py-0 radio-group">
                <div class="p-field-radiobutton no">
                  <RadioButton id="option3" name="option" value="false" v-model="mobileAppTesting"/>
                  <label for="option3">No</label>
                </div>
              </div>
              <small class="p-invalid" v-if="msg.upgradable">{{ msg.mobileAppTesting }}</small>
            </div>
            <div class="p-grid p-lg-12 p-px-0 p-py-0 radio-group-wrapper">
              <div class="p-lg-6 p-px-0 p-py-0"><h4>Test executed in the cloud (SaaS)</h4></div>
              <div class="p-lg-3 p-px-0 p-py-0 radio-group">
                <div class="p-field-radiobutton yes">
                  <RadioButton id="option2" name="option" value="true" v-model="executionInCloud"/>
                  <label for="option2">Yes</label>
                </div>
              </div>
              <div class="p-lg-3 p-px-0 p-py-0 radio-group">
                <div class="p-field-radiobutton no">
                  <RadioButton id="option3" name="option" value="false" v-model="executionInCloud"/>
                  <label for="option3">No</label>
                </div>
              </div>
              <small class="p-invalid" v-if="msg.upgradable">{{ msg.executionInCloud }}</small>
            </div>
            <div class="p-grid p-lg-12 p-px-0 p-py-0 radio-group-wrapper">
              <div class="p-lg-6 p-px-0 p-py-0"><h4>Web Testing</h4></div>
              <div class="p-lg-3 p-px-0 p-py-0 radio-group">
                <div class="p-field-radiobutton yes">
                  <RadioButton id="option2" name="option" value="true" v-model="webTesting"/>
                  <label for="option2">Yes</label>
                </div>
              </div>
              <div class="p-lg-3 p-px-0 p-py-0 radio-group">
                <div class="p-field-radiobutton no">
                  <RadioButton id="option3" name="option" value="false" v-model="webTesting"/>
                  <label for="option3">No</label>
                </div>
              </div>
              <small class="p-invalid" v-if="msg.upgradable">{{ msg.webTesting }}</small>
            </div>
            <div class="p-grid p-lg-12 p-px-0 p-py-0 radio-group-wrapper">
              <div class="p-lg-6 p-px-0 p-py-0"><h4>Mobile Web Testing</h4></div>
              <div class="p-lg-3 p-px-0 p-py-0 radio-group">
                <div class="p-field-radiobutton yes">
                  <RadioButton id="option2" name="option" value="true" v-model="mobileWebTesting"/>
                  <label for="option2">Yes</label>
                </div>
              </div>
              <div class="p-lg-3 p-px-0 p-py-0 radio-group">
                <div class="p-field-radiobutton no">
                  <RadioButton id="option3" name="option" value="false" v-model="mobileWebTesting"/>
                  <label for="option3">No</label>
                </div>
              </div>
              <small class="p-invalid" v-if="msg.upgradable">{{ msg.mobileWebTesting }}</small>
            </div>
            <div class="p-grid p-lg-12 p-px-0 p-py-0 radio-group-wrapper">
              <div class="p-lg-6 p-px-0 p-py-0"><h4>TestRail Integration</h4></div>
              <div class="p-lg-3 p-px-0 p-py-0 radio-group">
                <div class="p-field-radiobutton yes">
                  <RadioButton id="option2" name="option" value="true" v-model="testrailIntegration"/>
                  <label for="option2">Yes</label>
                </div>
              </div>
              <div class="p-lg-3 p-px-0 p-py-0 radio-group">
                <div class="p-field-radiobutton no">
                  <RadioButton id="option3" name="option" value="false" v-model="testrailIntegration"/>
                  <label for="option3">No</label>
                </div>
              </div>
              <small class="p-invalid" v-if="msg.upgradable">{{ msg.testrailIntegration }}</small>
            </div>
            <div class="p-grid p-lg-12 p-px-0 p-py-0 radio-group-wrapper">
              <div class="p-lg-6 p-px-0 p-py-0"><h4>JIRA Integration</h4></div>
              <div class="p-lg-3 p-px-0 p-py-0 radio-group">
                <div class="p-field-radiobutton yes">
                  <RadioButton id="option2" name="option" value="true" v-model="jiraIntegration"/>
                  <label for="option2">Yes</label>
                </div>
              </div>
              <div class="p-lg-3 p-px-0 p-py-0 radio-group">
                <div class="p-field-radiobutton no">
                  <RadioButton id="option3" name="option" value="false" v-model="jiraIntegration"/>
                  <label for="option3">No</label>
                </div>
              </div>
              <small class="p-invalid" v-if="msg.upgradable">{{ msg.jiraIntegration }}</small>
            </div>
            <div class="p-grid p-lg-12 p-px-0 p-py-0 radio-group-wrapper">
              <div class="p-lg-6 p-px-0 p-py-0"><h4>Chrome Browser Testing</h4></div>
              <div class="p-lg-3 p-px-0 p-py-0 radio-group">
                <div class="p-field-radiobutton yes">
                  <RadioButton id="option2" name="option" value="true" v-model="chromeBrowserTesting"/>
                  <label for="option2">Yes</label>
                </div>
              </div>
              <div class="p-lg-3 p-px-0 p-py-0 radio-group">
                <div class="p-field-radiobutton no">
                  <RadioButton id="option3" name="option" value="false" v-model="chromeBrowserTesting"/>
                  <label for="option3">No</label>
                </div>
              </div>
              <small class="p-invalid" v-if="msg.upgradable">{{ msg.chromeBrowserTesting }}</small>
            </div>
            <div class="p-grid p-lg-12 p-px-0 p-py-0 radio-group-wrapper">
              <div class="p-lg-6 p-px-0 p-py-0"><h4>Chrome Extension</h4></div>
              <div class="p-lg-3 p-px-0 p-py-0 radio-group">
                <div class="p-field-radiobutton yes">
                  <RadioButton id="option2" name="option" value="true" v-model="chromeExtension"/>
                  <label for="option2">Yes</label>
                </div>
              </div>
              <div class="p-lg-3 p-px-0 p-py-0 radio-group">
                <div class="p-field-radiobutton no">
                  <RadioButton id="option3" name="option" value="false" v-model="chromeExtension"/>
                  <label for="option3">No</label>
                </div>
              </div>
              <small class="p-invalid" v-if="msg.upgradable">{{ msg.chromeExtension }}</small>
            </div>
            <!-- <div class="p-grid p-lg-12 p-px-0 p-py-0 radio-group-wrapper">
                <div class="p-lg-6 p-px-0 p-py-0"><h4>JIRA Integration</h4></div>
                <div class="p-lg-3 p-px-0 p-py-0 radio-group">
                    <div class="p-field-radiobutton yes">
                        <RadioButton id="option2" name="option" value="true" v-model="upgradable" />
                        <label for="option2">Yes</label>
                    </div>
                </div>
                <div class="p-lg-3 p-px-0 p-py-0 radio-group">
                    <div class="p-field-radiobutton no">
                        <RadioButton id="option3" name="option" value="false" v-model="upgradable" />
                        <label for="option3">No</label>
                    </div>
                </div>
                <small class="p-invalid" v-if="msg.upgradable">{{ msg.upgradable }}</small>
            </div> -->
            <div class="p-grid p-lg-12 p-px-0 p-py-0 radio-group-wrapper">
              <div class="p-lg-6 p-px-0 p-py-0"><h4>Test executed in local browser</h4></div>
              <div class="p-lg-3 p-px-0 p-py-0 radio-group">
                <div class="p-field-radiobutton yes">
                  <RadioButton id="option2" name="option" value="true" v-model="testExecutedLocally"/>
                  <label for="option2">Yes</label>
                </div>
              </div>
              <div class="p-lg-3 p-px-0 p-py-0 radio-group">
                <div class="p-field-radiobutton no">
                  <RadioButton id="option3" name="option" value="false" v-model="testExecutedLocally"/>
                  <label for="option3">No</label>
                </div>
              </div>
              <small class="p-invalid" v-if="msg.upgradable">{{ msg.testExecutedLocally }}</small>
            </div>
            <div class="p-grid p-lg-12 p-px-0 p-py-0 radio-group-wrapper">
              <div class="p-lg-6 p-px-0 p-py-0"><h4>Live Chat Technical Support 24/7</h4></div>
              <div class="p-lg-3 p-px-0 p-py-0 radio-group">
                <div class="p-field-radiobutton yes">
                  <RadioButton id="option2" name="option" value="true" v-model="liveChatSupport"/>
                  <label for="option2">Yes</label>
                </div>
              </div>
              <div class="p-lg-3 p-px-0 p-py-0 radio-group">
                <div class="p-field-radiobutton no">
                  <RadioButton id="option3" name="option" value="false" v-model="liveChatSupport"/>
                  <label for="option3">No</label>
                </div>
              </div>
              <small class="p-invalid" v-if="msg.upgradable">{{ msg.liveChatSupport }}</small>
            </div>
            <div class="p-grid p-lg-12 p-px-0 p-py-0 radio-group-wrapper">
              <div class="p-lg-6 p-px-0 p-py-0"><h4>Parallel Test Run</h4></div>
              <div class="p-lg-3 p-px-0 p-py-0 radio-group">
                <div class="p-field-radiobutton yes">
                  <RadioButton id="option2" name="option" value="true" v-model="parallelTestRun"/>
                  <label for="option2">Yes</label>
                </div>
              </div>
              <div class="p-lg-3 p-px-0 p-py-0 radio-group">
                <div class="p-field-radiobutton no">
                  <RadioButton id="option3" name="option" value="false" v-model="parallelTestRun"/>
                  <label for="option3">No</label>
                </div>
              </div>
              <small class="p-invalid" v-if="msg.upgradable">{{ msg.parallelTestRun }}</small>
            </div>
            <div class="p-grid p-lg-12 p-px-0 p-py-0 radio-group-wrapper">
              <div class="p-lg-6 p-px-0 p-py-0"><h4>1x Dedicated Resource</h4></div>
              <div class="p-lg-3 p-px-0 p-py-0 radio-group">
                <div class="p-field-radiobutton yes">
                  <RadioButton id="option2" name="option" value="true" v-model="dedicatedResource"/>
                  <label for="option2">Yes</label>
                </div>
              </div>
              <div class="p-lg-3 p-px-0 p-py-0 radio-group">
                <div class="p-field-radiobutton no">
                  <RadioButton id="option3" name="option" value="false" v-model="dedicatedResource"/>
                  <label for="option3">No</label>
                </div>
              </div>
              <small class="p-invalid" v-if="msg.upgradable">{{ msg.dedicatedResource }}</small>
            </div>
          </div>
          <div class="p-grid">
            <div class="p-col-12 p-fluid">

              <Button :label="viewType === 'insert' ? 'CREATE PACKAGE' : 'UPDATE PACKAGE'" class="create-package-btn"
                      v-on:click="clickCreateSubscription"/>
            </div>
          </div>
        </div>

        <!-- <div class="p-col-12 p-grid p-fluid p-justify-end">
            <Button :label="viewType === 'insert' ? 'CREATE PACKAGE' : 'UPDATE PACKAGE'" class="create-package-btn" v-on:click="clickCreateSubscription" />
        </div> -->
      </div>
    </div>
    <!-- <pre>getSelectedSubscriptionPkgInfo===> {{getSelectedSubscriptionPkgInfo}}</pre> -->
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
  props: {},
  name: 'CreateSubscription',

  data() {
    return {
      id: '',
      pckgInfo: {},
      error: false,
      msg: {},
      packageName: '',
      packageDescription: '',
      price: null,
      packageDuration: null,
      packageStatus: '',
      packageRenewal: '',
      upgradable: '',
      mobileAppTesting: '',
      executionInCloud: '',
      webTesting: '',
      mobileWebTesting: '',
      testrailIntegration: '',
      jiraIntegration: '',
      chromeBrowserTesting: '',
      chromeExtension: '',
      testExecutedLocally: '',
      liveChatSupport: '',
      parallelTestRun: '',
      dedicatedResource: '',
      projectLimit: null,
      testCaseLimit: null,
      testRunLimit: null,
      teamMemberLimit: null,
      testExecutionLimit: null,
      testStepLimit: null,
      mobileProjectLimit: null,
      mobileTestCaseLimit: null,
      mobileTestRunLimit: null,
      // smsLimit: '',
      // emailLimit: '',
      viewType: this.$route && this.$route.query && this.$route.query.type,
    };
  },
  created() {
  },
  methods: {
    fetchselectedSubscriptionPackage() {
      this.getSubscriptionList();
    },
    ...mapActions({
      insertNewSubscription: 'TjAdminSubscription/actionCreateSubscription',
      updateNewSubscription: 'TjAdminSubscription/actionUpdateSubscription',
    }),

    showSuccess(summary, detail) {
      this.$toast.add({severity: 'success', summary: summary, detail: detail, life: 3000});
    },

    showError(detail) {
      this.$toast.add({severity: 'error', summary: 'Error Message', detail: detail, life: 3000});
    },

    clickCreateSubscription() {
      //         "mobile_app_testing": true,
      // "execution_in_cloud": true,
      // "web_testing": true,
      // "mobile_web_testing": true,
      // "test_rail_integration": true,
      // "jira_integration": true,
      // "chrome_browser_testing": true,
      // "chrome_extention": true,
      // "local_browser_testing": true,
      // "live_chat_technical_support": true,
      // "parallel_test_run": true,
      // "dedicated_resource": true,
      if (!this.isRequiredFieldEmpty()) {
        if (this.viewType === 'insert') {
          let saveObj = {
            price: this.price,
            title: this.packageName,
            description: this.packageDescription,
            validity_in_days: this.packageDuration,
            is_active: this.packageStatus,
            is_renewal: this.packageRenewal,
            is_upgrade: this.upgradable,
            mobile_app_testing: this.mobileAppTesting,
            execution_in_cloud: this.executionInCloud,
            web_testing: this.webTesting,
            mobile_web_testing: this.mobileWebTesting,
            test_rail_integration: this.testrailIntegration,
            jira_integration: this.jiraIntegration,
            chrome_browser_testing: this.chromeBrowserTesting,
            chrome_extention: this.chromeExtension,
            local_browser_testing: this.testExecutedLocally,
            live_chat_technical_support: this.liveChatSupport,
            parallel_test_run: this.parallelTestRun,
            dedicated_resource: this.dedicatedResource,
            package_configuration: {
              project_limit: this.projectLimit,
              test_case_limit: this.testCaseLimit,
              test_step_limit: this.testStepLimit,
              test_run_limit: this.testRunLimit,
              member_limit: this.teamMemberLimit,
              test_execution_limit: this.testExecutionLimit,
              mobile_project_limit: this.mobileProjectLimit,
              mobile_test_case_limit: this.mobileTestCaseLimit,
              mobile_test_run_limit: this.mobileTestRunLimit,
              // sms_limit: this.smsLimit,
              // email_limit: this.emailLimit,
            },
          };
          console.log("package object ===>", saveObj)
          this.insertNewSubscription(saveObj)
              .then((resBody) => {
                console.log('subscription vue res', resBody);
                if (resBody.status === 200) {
                  console.log('subscription-success-', resBody);
                  this.showSuccess(resBody.message.MSG_SHORT, resBody.message.MSG_LONG);
                  this.resetFormFields();
                } else {
                  console.log('subscription else ', resBody);
                  this.showError('Test Case create unccessfull.');
                }
              })
              .catch((err) => {
                console.log('subscription-err', err);
              });
        } else {
          let saveObj = {
            _id: this.id,

            price: this.price,
            title: this.packageName,
            description: this.packageDescription,
            validity_in_days: this.packageDuration,
            is_active: this.packageStatus,
            is_renewal: this.packageRenewal,
            is_upgrade: this.upgradable,
            mobile_app_testing: this.mobileAppTesting,
            execution_in_cloud: this.executionInCloud,
            web_testing: this.webTesting,
            mobile_web_testing: this.mobileWebTesting,
            test_rail_integration: this.testrailIntegration,
            jira_integration: this.jiraIntegration,
            chrome_browser_testing: this.chromeBrowserTesting,
            chrome_extention: this.chromeExtension,
            local_browser_testing: this.testExecutedLocally,
            live_chat_technical_support: this.liveChatSupport,
            parallel_test_run: this.parallelTestRun,
            dedicated_resource: this.dedicatedResource,
            package_configuration: {
              project_limit: this.projectLimit,
              test_case_limit: this.testCaseLimit,
              test_run_limit: this.testRunLimit,
              test_step_limit: this.testStepLimit,
              member_limit: this.teamMemberLimit,
              test_execution_limit: this.testExecutionLimit,
              mobile_project_limit: this.mobileProjectLimit,
              mobile_test_case_limit: this.mobileTestCaseLimit,
              mobile_test_run_limit: this.mobileTestRunLimit,
              // sms_limit: this.smsLimit,
              // email_limit: this.emailLimit,
            },
          };
          console.log("package object ===>", saveObj)
          this.updateNewSubscription(saveObj)
              .then((resBody) => {
                console.log('subscription vue res', resBody);
                if (resBody.status === 200) {
                  console.log('subscription-success-', resBody);
                  this.showSuccess(resBody.message.MSG_SHORT, resBody.message.MSG_LONG);
                  this.resetFormFields();
                  this.$router.push({path: '/subscription-packages'});
                } else {
                  console.log('subscription else ', resBody);
                  this.showError('Test Case create unccessfull.');
                }
              })
              .catch((err) => {
                console.log('subscription-err', err);
              });
        }
      }
    },

    resetFormFields() {
      this.error = false;
      this.packageName = '';
      this.packageDescription = '';
      this.price = null;
      this.packageDuration = null;
      this.packageStatus = '';
      this.packageRenewal = '';
      this.upgradable = '';
      this.mobileAppTesting = '';
      this.executionInCloud = '';
      this.webTesting = '';
      this.mobileWebTesting = '';
      this.testrailIntegration = '';
      this.jiraIntegration = '';
      this.chromeBrowserTesting = '';
      this.chromeExtension = '';
      this.testExecutedLocally = '';
      this.liveChatSupport = '';
      this.parallelTestRun = '';
      this.dedicatedResource = '';
      this.projectLimit = null;
      this.testCaseLimit = null;
      this.testRunLimit = null;
      this.testExecutionLimit = null;
      this.teamMemberLimit = null;
    },

    isRequiredFieldEmpty() {
      let requiredFieldEmpty = false;
      if (this.packageName == '') {
        requiredFieldEmpty = true;
        this.error = true;
        this.msg['packageName'] = 'Package Name is missing';
      }
      // if (this.packageDescription == '') {
      //     requiredFieldEmpty = true;
      //     this.error = true;

      //     this.msg['packageDescription'] = 'Package Description is missing';
      // }
      if (this.testStepLimit == null) {
        requiredFieldEmpty = true;
        this.error = true;

        this.msg['testStepLimit'] = 'test step limit is missing';
      }
      if (this.testExecutionLimit == null) {
        requiredFieldEmpty = true;
        this.error = true;

        this.msg['testExecutionLimit'] = 'test execution Limit is missing';
      }

      if (this.price == null) {
        requiredFieldEmpty = true;
        this.error = true;

        this.msg['price'] = 'Price is missing';
      }
      if (this.packageDuration == null) {
        requiredFieldEmpty = true;
        this.error = true;

        this.msg['packageDuration'] = 'Package Duration  is missing';
      }

      if (this.packageStatus == '') {
        requiredFieldEmpty = true;
        this.error = true;

        this.msg['packageStatus'] = 'Package Status is missing';
      }
      if (this.packageRenewal == '') {
        requiredFieldEmpty = true;
        this.error = true;

        this.msg['packageRenewal'] = 'Package Renewal is missing';
      }
      if (this.upgradable == '') {
        requiredFieldEmpty = true;
        this.error = true;

        this.msg['upgradable'] = 'Upgradable is missing';
      }
      if (this.mobileAppTesting == '') {
        requiredFieldEmpty = true;
        this.error = true;

        this.msg['mobileAppTesting'] = 'Mobile App Testing is missing';
      }
      if (this.executionInCloud == '') {
        requiredFieldEmpty = true;
        this.error = true;

        this.msg['executionInCloud'] = 'Execution in Cloud is missing';
      }
      if (this.webTesting == '') {
        requiredFieldEmpty = true;
        this.error = true;

        this.msg['webTesting'] = 'Web Testing is missing';
      }
      if (this.mobileWebTesting == '') {
        requiredFieldEmpty = true;
        this.error = true;

        this.msg['mobileWebTesting'] = 'Mobile Web Testing is missing';
      }
      if (this.testrailIntegration == '') {
        requiredFieldEmpty = true;
        this.error = true;

        this.msg['testrailIntegration'] = 'Testrail Integration is missing';
      }
      if (this.jiraIntegration == '') {
        requiredFieldEmpty = true;
        this.error = true;

        this.msg['jiraIntegration'] = 'Jira Integration is missing';
      }
      if (this.chromeBrowserTesting == '') {
        requiredFieldEmpty = true;
        this.error = true;

        this.msg['chromeBrowserTesting'] = 'Chrome Browser Testing is missing';
      }
      if (this.chromeExtension == '') {
        requiredFieldEmpty = true;
        this.error = true;

        this.msg['chromeExtension'] = 'Chrome Extension is missing';
      }
      if (this.testExecutedLocally == '') {
        requiredFieldEmpty = true;
        this.error = true;

        this.msg['testExecutedLocally'] = 'Local Execution is missing';
      }
      if (this.liveChatSupport == '') {
        requiredFieldEmpty = true;
        this.error = true;

        this.msg['liveChatSupport'] = 'Live Chat Support is missing';
      }
      if (this.parallelTestRun == '') {
        requiredFieldEmpty = true;
        this.error = true;

        this.msg['parallelTestRun'] = 'Parallel Test Run is missing';
      }
      if (this.dedicatedResource == '') {
        requiredFieldEmpty = true;
        this.error = true;

        this.msg['dedicatedResource'] = 'Dedicated Resource is missing';
      }
      // if (this.upgradable == '') {
      //     requiredFieldEmpty = true;
      //     this.error = true;

      //     this.msg['upgradable'] = 'upgradable is missing';
      // }
      if (this.projectLimit == null) {
        requiredFieldEmpty = true;
        this.error = true;

        this.msg['projectLimit'] = 'Project Limit is missing';
      }
      if (this.testCaseLimit == null) {
          requiredFieldEmpty = true;
          this.error = true;

          this.msg['testCaseLimit'] = 'Test Case Limit is missing';
      }

      if (this.testRunLimit == null) {
        requiredFieldEmpty = true;
        this.error = true;

        this.msg['testRunLimit'] = 'Test Run Limit is missing';
      }
      if (this.teamMemberLimit == null) {
        requiredFieldEmpty = true;
        this.error = true;

        this.msg['teamMemberLimit'] = 'Team Member Limit is missing';
      }
      if (this.mobileProjectLimit == null) {
        requiredFieldEmpty = true;
        this.error = true;

        this.msg['mobileProjectLimit'] = 'Mobile Project Limit is missing';
      }
      if (this.mobileTestCaseLimit == null) {
          requiredFieldEmpty = true;
          this.error = true;

          this.msg['mobileTestCaseLimit'] = 'Mobile Test Case Limit is missing';
      }

      if (this.mobileTestRunLimit == null) {
        requiredFieldEmpty = true;
        this.error = true;

        this.msg['mobileTestRunLimit'] = 'Mobile Test Run Limit is missing';
      }
      // if (this.smsLimit == '') {
      //     requiredFieldEmpty = true;
      //     this.error = true;

      //     this.msg['smsLimit'] = 'Sms Limit is missing';
      // }

      // if (this.emailLimit == '') {
      //     requiredFieldEmpty = true;
      //     this.error = true;
      //     this.msg['emailLimit'] = 'Email Limit is missing';
      // }

      console.log('requiredFieldEmpty', requiredFieldEmpty);

      return requiredFieldEmpty;
    },
    resetRequiredField(fieldName, val) {
      this.msg[fieldName] = val ? val : '';
      this.error = false;
    },
  },
  mounted() {
    console.log("getSelectedSubscriptionPkgInfo===>", this.getSelectedSubscriptionPkgInfo)
    if (this.viewType === 'update') {
      this.packageName = this.getSelectedSubscriptionPkgInfo.title;
      this.packageDescription = this.getSelectedSubscriptionPkgInfo.description;
      this.price = this.getSelectedSubscriptionPkgInfo.price;
      this.packageDuration = this.getSelectedSubscriptionPkgInfo.validity_in_days;
      this.packageStatus = String(this.getSelectedSubscriptionPkgInfo.is_active);
      this.packageRenewal = String(this.getSelectedSubscriptionPkgInfo.is_renewal);
      this.upgradable = String(this.getSelectedSubscriptionPkgInfo.is_upgrade);
      this.mobileAppTesting = String(this.getSelectedSubscriptionPkgInfo.mobile_app_testing);
      this.executionInCloud = String(this.getSelectedSubscriptionPkgInfo.execution_in_cloud);
      this.webTesting = String(this.getSelectedSubscriptionPkgInfo.web_testing);
      this.mobileWebTesting = String(this.getSelectedSubscriptionPkgInfo.mobile_web_testing);
      this.testrailIntegration = String(this.getSelectedSubscriptionPkgInfo.test_rail_integration);
      this.jiraIntegration = String(this.getSelectedSubscriptionPkgInfo.jira_integration);
      this.chromeBrowserTesting = String(this.getSelectedSubscriptionPkgInfo.chrome_browser_testing);
      this.chromeExtension = String(this.getSelectedSubscriptionPkgInfo.chrome_extention);
      this.testExecutedLocally = String(this.getSelectedSubscriptionPkgInfo.local_browser_testing);
      this.liveChatSupport = String(this.getSelectedSubscriptionPkgInfo.live_chat_technical_support);
      this.parallelTestRun = String(this.getSelectedSubscriptionPkgInfo.parallel_test_run);
      this.dedicatedResource = String(this.getSelectedSubscriptionPkgInfo.dedicated_resource);
      this.projectLimit = this.getSelectedSubscriptionPkgInfo.package_configuration.project_limit;
      this.testCaseLimit = this.getSelectedSubscriptionPkgInfo.package_configuration.test_case_limit;
      this.testRunLimit = this.getSelectedSubscriptionPkgInfo.package_configuration.test_run_limit;
      this.teamMemberLimit = this.getSelectedSubscriptionPkgInfo.package_configuration.member_limit;
      this.testExecutionLimit = this.getSelectedSubscriptionPkgInfo.package_configuration.test_execution_limit ? this.getSelectedSubscriptionPkgInfo.package_configuration.test_execution_limit : null;
      this.testStepLimit = this.getSelectedSubscriptionPkgInfo.package_configuration.test_step_limit ? this.getSelectedSubscriptionPkgInfo.package_configuration.test_step_limit : null;
      // this.smsLimit = this.getSelectedSubscriptionPkgInfo.package_configuration.sms_limit;
      // this.emailLimit = this.getSelectedSubscriptionPkgInfo.package_configuration.email_limit;
      this.id = this.getSelectedSubscriptionPkgInfo._id;
      this.mobileProjectLimit = this.getSelectedSubscriptionPkgInfo.package_configuration.mobile_project_limit;
      this.mobileTestCaseLimit = this.getSelectedSubscriptionPkgInfo.package_configuration.mobile_test_case_limit;
      this.mobileTestRunLimit = this.getSelectedSubscriptionPkgInfo.package_configuration.mobile_test_run_limit;
    }
  },

  created() {
  },

  computed: {
    ...mapGetters({
      getSelectedSubscriptionPkgInfo: 'TjAdminSubscription/selectedSubscriptionPkgInfo',
    }),
  },

  watch: {
    testStepLimit() {
      this.resetRequiredField('testStepLimit');
    },
    testExecutionLimit() {
      this.resetRequiredField('testExecutionLimit', 0);
    },
    packageDescription() {
      this.resetRequiredField('packageDescription');
    },
    price() {
      this.resetRequiredField('price');
    },
    packageDuration() {
      this.resetRequiredField('packageDuration');
    },
    packageStatus() {
      this.resetRequiredField('packageStatus');
    },
    packageRenewal() {
      this.resetRequiredField('packageRenewal');
    },
    projectLimit() {
      this.resetRequiredField('projectLimit');
    },
    upgradable() {
      this.resetRequiredField('upgradable');
    },
    mobileAppTesting() {
      this.resetRequiredField('mobileAppTesting');
    },
    executionInCloud() {
      this.resetRequiredField('executionInCloud');
    },
    webTesting() {
      this.resetRequiredField('webTesting');
    },
    mobileWebTesting() {
      this.resetRequiredField('mobileWebTesting');
    },
    testrailIntegration() {
      this.resetRequiredField('testrailIntegration');
    },
    jiraIntegration() {
      this.resetRequiredField('jiraIntegration');
    },
    chromeBrowserTesting() {
      this.resetRequiredField('chromeBrowserTesting');
    },
    chromeExtension() {
      this.resetRequiredField('chromeExtension');
    },
    testExecutedLocally() {
      this.resetRequiredField('testExecutedLocally');
    },
    liveChatSupport() {
      this.resetRequiredField('liveChatSupport');
    },
    parallelTestRun() {
      this.resetRequiredField('parallelTestRun');
    },
    dedicatedResource() {
      this.resetRequiredField('dedicatedResource');
    },
    // testCaseLimit() {
    //     this.resetRequiredField('testCaseLimit');
    // },
    testRunLimit() {
      this.resetRequiredField('testRunLimit');
    },
    teamMemberLimit() {
      this.resetRequiredField('teamMemberLimit');

    }
    // smsLimit() {
    //     this.resetRequiredField('smsLimit');
    // },
    // emailLimit() {
    //     this.resetRequiredField('emailLimit');
    // },
  },
};
</script>

<style></style>
