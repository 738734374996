<template>
  <div>
    <AuthComponentLayout>
      <ResetPasswordForm/>
    </AuthComponentLayout>

  </div>
</template>

<script>
// import AuthComponentLayout from "../components/AuthComponentLayout.vue";
import AuthComponentLayout from "../components/authPageComponents/AuthComponentLayout.vue";
import ResetPasswordForm from "../components/guest/ResetPassword.vue";

export default {
  name: 'ResetPassword',
  components: {
    AuthComponentLayout,
    ResetPasswordForm
  }
}
</script>

<style>

</style>
