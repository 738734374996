import XPath from 'xpath';
// Attributes on nodes that we know are unique to the node
const UNIQUE_XPATH_ATTRIBUTES = [
  'name',
  'content-desc',
  'id',
  'accessibility-id',
];
const UNIQUE_CLASS_CHAIN_ATTRIBUTES = [
  'label',
  'name',
  'value',
];
const UNIQUE_PREDICATE_ATTRIBUTES = [
  'label',
  'name',
  'value',
  'type',
];
/**
 * Get an optimal XPath for a DOMNode
 *
 * @param {DOMDocument} doc
 * @param {DOMNode} domNode
 * @param {Array<String>} uniqueAttributes Attributes we know are unique (defaults to just 'id')
 * @returns {string|null}
 */
function getOptimalXPath (doc, domNode, uniqueAttributes = ['id']) {
  try {
    // BASE CASE #1: If this isn't an element, we're above the root, return empty string
    if (!domNode.tagName || domNode.nodeType !== 1) {
      return '';
    }

    // BASE CASE #2: If this node has a unique attribute, return an absolute XPath with that attribute
    for (let attrName of uniqueAttributes) {
      const attrValue = domNode.getAttribute(attrName);
      console.log('ATTR VALUE : ', attrValue)
      if (attrValue) {
        let xpath = `//${domNode.tagName || '*'}[@${attrName}="${attrValue}"]`;
        let othersWithAttr;

        // If the XPath does not parse, move to the next unique attribute
        try {
          othersWithAttr = XPath.select(xpath, doc);
        } catch (ign) {
          console.log('ign : ', ign);
          continue;
        }

        // If the attribute isn't actually unique, get it's index too
        if (othersWithAttr.length > 1) {
          let index = othersWithAttr.indexOf(domNode);
          xpath = `(${xpath})[${index + 1}]`;
        }
        return xpath;
      }
    }

    // Get the relative xpath of this node using tagName
    let xpath = `/${domNode.tagName}`;

    // If this node has siblings of the same tagName, get the index of this node
    if (domNode.parentNode) {
      // Get the siblings
      const childNodes = Array.prototype.slice.call(domNode.parentNode.childNodes, 0).filter((childNode) => (
        childNode.nodeType === 1 && childNode.tagName === domNode.tagName
      ));

      // If there's more than one sibling, append the index
      if (childNodes.length > 1) {
        let index = childNodes.indexOf(domNode);
        xpath += `[${index + 1}]`;
      }
    }

    // Make a recursive call to this nodes parents and prepend it to this xpath
    return getOptimalXPath(doc, domNode.parentNode, uniqueAttributes) + xpath;
  } catch (error) {
    // If there's an unexpected exception, abort and don't get an XPath
    log.error(`The most optimal XPATH could not be determined because an error was thrown: '${JSON.stringify(error, null, 2)}'`);

    return null;
  }
}

function getBoundingClientRect(element) {
  const x = parseFloat(element.getAttribute('x'));
  const y = parseFloat(element.getAttribute('y'));
  const width = parseFloat(element.getAttribute('width'));
  const height = parseFloat(element.getAttribute('height'));

  return { x, y, width, height };
}

function isPointInsideRect(pointX, pointY, rect) {
  return (
    pointX >= rect.x &&
    pointX <= rect.x + rect.width &&
    pointY >= rect.y &&
    pointY <= rect.y + rect.height
  );
}


const formatBounds = (bounds) => {
  let start = false;
  let cur = "";
  let numbers = [];
  for (let i = 0; i < bounds.length; i++) {
    if ((bounds[i] === '[' || bounds[i] === ',') && start === false) {
      start = true;
    } else if (start && bounds[i] !== ',' && bounds[i] !== ']' && bounds[i] !== '[')
      cur += bounds[i];
    else if (start && (bounds[i] === ',' || bounds[i] === ']')) {
      numbers.push(parseInt(cur));
      cur = "";
    }
  }
  return numbers;
}

const fits = (bounds, x, y) => {
  if (x >= bounds[0] && x <= bounds[2] && y >= bounds[1] && y <= bounds[3])
    return true;
  return false;
}


function findElementByCoordinates(xmlDoc, targetX, targetY) {
  // flatten all elements
  const elements = xmlDoc.getElementsByTagName('*');
  const elementsArray = Array.from(elements);
  const elementsArrayFiltered = elementsArray.filter((element) => {
    const rect = getBoundingClientRect(element);
    return isPointInsideRect(targetX, targetY, rect);
  });
  // return null if the filtered array is empty
  if (elementsArrayFiltered.length === 0) return null;
  // return the last element of filtered array
  return elementsArrayFiltered[elementsArrayFiltered.length - 1];
}



export const findElementXpathByCoordinates = (platformType, domTree, x, y, providedElement = null, providedXpath = null) => {
  // // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", domTree, x, y);

  let targetElement = null;
  let xpath = null;

  targetElement = providedElement ? providedElement : findElementByCoordinates(domTree, x, y);
  xpath = providedXpath ? providedXpath : getOptimalXPath(domTree, targetElement, UNIQUE_XPATH_ATTRIBUTES);
  console.log('element name : ', targetElement.getAttribute('name'));
  if (xpath.startsWith('/AppiumAUT')) {
    xpath = xpath.replace('/AppiumAUT', '/');
  }
  // // console.log("++++++++++++++++++++++++++++++++++", bounds);

  let bound = `[0,0][0,0]`;

  if (platformType == 'ios') {
    const boundingRect = {};
    boundingRect.x = parseFloat(targetElement.getAttribute('x'));
    boundingRect.y = parseFloat(targetElement.getAttribute('y'));
    boundingRect.width = parseFloat(targetElement.getAttribute('width'));
    boundingRect.height = parseFloat(targetElement.getAttribute('height'));
    bound = `[${boundingRect.x}, ${boundingRect.y}][${boundingRect.x + boundingRect.width}, ${boundingRect.y + boundingRect.height}]`
  }

  if (platformType == 'android') {
    bound = targetElement.getAttribute('bounds')
  }


  if(targetElement) {
    // console.log("🍀 🍀 🍀 🍀 🍀 🍀 🍀 🍀 🍀 : ", targetElement);
    return {
      id: targetElement.getAttribute('id') || null,
      xpath,
      bounds: bound,
      className: targetElement.nodeName || null,
      text: targetElement.getAttribute('text') || targetElement.getAttribute('content-desc') || targetElement.getAttribute('value') || targetElement.getAttribute('label') || targetElement.getAttribute('name') || null,
      checked: targetElement.getAttribute('checked') && JSON.parse(targetElement.getAttribute('checked')),
      clickable: targetElement.getAttribute('clickable') && JSON.parse(targetElement.getAttribute('clickable')),
      enabled: targetElement.getAttribute('enabled') && JSON.parse(targetElement.getAttribute('enabled')),
      focusable: targetElement.getAttribute('focusable') && JSON.parse(targetElement.getAttribute('focusable')),
      focused: targetElement.getAttribute('focused') && JSON.parse(targetElement.getAttribute('focused')),
      longClickable: targetElement.getAttribute('long-clickable') && JSON.parse(targetElement.getAttribute('long-clickable')),
      password: targetElement.getAttribute('password') && JSON.parse(targetElement.getAttribute('password')),
      scrollable: targetElement.getAttribute('scrollable') && JSON.parse(targetElement.getAttribute('scrollable')),
      selected: targetElement.getAttribute('selected') && JSON.parse(targetElement.getAttribute('selected')),
      displayed: targetElement.getAttribute('displayed') && JSON.parse(targetElement.getAttribute('displayed')),
      contentDescription: targetElement.getAttribute('content-desc') || null,
      inputText: null,
      coordinates: {
        tap: {x, y}
      },
      screenshot: {
        data: '',
        mimeType: ''
      },
      validation: {
        type: 'Tap',
        expectedOutput: '',
        validate: false,
        validationType: '',
      },
      error_obj: {
        status: 'success',
        error_cause: '',
      }
    };
  } else {
    return {
      id:  null,
      xpath: null,
      bounds: null,
      className: null,
      text: null,
      checked: null,
      clickable: null,
      enabled: null,
      focusable: null,
      focused: null,
      longClickable: null,
      password: null,
      scrollable: null,
      selected: null,
      displayed: null,
      contentDescription: null,
      inputText: null,
      coordinates: {
        tap: {x, y}
      },
      screenshot: {
        data: '',
        mimeType: ''
      },
      validation: {
        type: 'Tap',
        expectedOutput: '',
        validate: false,
        validationType: '',
      },
      error_obj: {
        status: 'success',
        error_cause: '',
      }
    };
  }
}
