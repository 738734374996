import { getPhoneNumberList, createPhoneNumber, deletePhoneNumber } from "../../api/smsService";

const state = {
    phoneNumbers: [],
};

const mutations = {
    FETCH_PHONE_NUMBERS(state, numbers) {
        state.phoneNumbers = numbers;
    },
};

const actions = {
    async actionGetPhoneNumberList(state, projectId) {
        return await getPhoneNumberList(projectId).then(resBody => {
            console.log("🌊🌊🌊🌊🌊🌊🌊🌊🌊🌊🌊🌊🌊🌊🌊🌊", resBody)
            if (resBody.status === 200) {
                state.commit('FETCH_PHONE_NUMBERS', resBody.data.phone_numbers);
                return resBody;
            }
        }).catch(error => {
            console.log("🧨🧨🧨🧨🧨🧨🧨🧨", error)
        })
    },

    async actionCreatePhoneNubmer(state, data) {
        return await createPhoneNumber(data).then(resBody => {
            if (resBody.status === 200) {
                return resBody;
            }
        }).catch(error => {
            console.log("🧨🧨🧨🧨🧨🧨🧨🧨", error)
        })
    },

    async actionDeletePhoneNumber(state, numberId) {
        return await deletePhoneNumber(numberId).then(resBody => {
            if (resBody.status === 200) {
                return resBody;
            }
        }).catch(error => {
            console.log("🧨🧨🧨🧨🧨🧨🧨🧨", error)
        })
    },
};

const getters = {
    phoneNumbers: state => state.phoneNumbers,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};