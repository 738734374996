import axios from 'axios';
import {MobileServiceBaseUrl} from '../base_urls';
import interceptors from '../interceptors';

const MobileServiceRequest = axios.create({
    baseURL: `${MobileServiceBaseUrl}/api/v1`,
    timeout: 300000,
});

interceptors(MobileServiceRequest);
export default MobileServiceRequest;