<template>
  <div class="login-container">
    <div class="login-container-header">New Password</div>
    <!-- <div> -->
    <div class="input p-fluid">
      <div class="email">
        <label for="password">Enter New Password</label>
        <Password id="password" :disabled="confirmPassword.length" v-model="password" placeholder="Enter New Password"
          :class="requiredFields.password" :feedback="false" :autocomplete="false" toggleMask></Password>
        <small class="p-invalid" v-if="msg.password">{{ msg.password }}</small>
      </div>

      <div class="password">
        <label for="confirmPassword">Confirm Password</label>
        <Password id="confirmPassword" :disabled="password.length < 4" v-model="confirmPassword"
          placeholder="Confirm Password" :class="requiredFields.confirmPassword" :feedback="false" :autocomplete="false"
          toggleMask></Password>
        <small class="p-invalid" v-if="msg.confirmPassword">{{ msg.confirmPassword }}</small>
      </div>

      <div class="options">
        <!-- <div class="float-left">
          <Checkbox id="remember-me" v-model="rememberMe" :binary="true" />
          <label for="remember-me">Remember Me</label>
        </div> -->
        <!-- <div class="float-right" @click="forgetPass">Forgot Password?</div> -->
      </div>

      <Button class="login-btn" label="CONFIRM" @click="clickResetPasswordHandler"
        :disabled="!password.length || !confirmPassword.length || !isPasswordMatched"></Button>
      <!-- <div class="sign-up-link">
        Don't have an account?
        <router-link to="/signup">Sign Up</router-link>
      </div> -->
    </div>
    <!-- <div class="no-account">No account? Contact your account manager.</div> -->
  </div>
</template>

<script>
import { getToken } from '@/utils/cookies';
import { mapActions, mapGetters } from 'vuex';

export default {
  data: () => ({
    password: '',
    confirmPassword: '',
    msg: {},
    error: false,
    isPasswordMatched: false,
    requiredFields: {
      password: '',
      confirmPassword: '',
    }
  }),
  created() {
    // console.log("🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥 🟥", this.$route.query);
  },

  watch: {
    confirmPassword(value) {
      if (value === this.password) {
        this.requiredFields.password = '';
        this.requiredFields.confirmPassword = '';
        this.isPasswordMatched = true;
        delete this.msg['confirmPassword']
      } else {
        this.requiredFields.password = 'p-invalid';
        this.requiredFields.confirmPassword = 'p-invalid';
        this.msg['confirmPassword'] = 'Password Does Not Match!';
        this.isPasswordMatched = false;
      }
      this.checkPassConfirmPass();
    },
    password(value) {
      this.password = value;
      this.validatePassword(value);
    },
  },
  methods: {
    ...mapActions({
      GetOrganizationData: 'organization/GetOrganizationData',
      resetPassword: 'auth/actionResetPassword',
    }),

    showSuccess(summary, detail) {
      this.$toast.add({ severity: 'success', summary: summary, detail: detail, life: 3000 });
    },

    showError(detail) {
      this.$toast.add({ severity: 'error', summary: 'Error Message', detail: detail, life: 3000 });
    },

    validatePassword(value) {
      if (value.length < 1) {
        this.msg['password'] = 'New Password is Required';
        this.error = false;
      } else if (value.length < 8) {
        this.msg['password'] = 'Minimum 8 characters or more!';
        this.error = true;
      } else if (this.password.search(/[a-z]/) < 0) {
        this.msg['password'] = 'Your password must contain at least one lowercase letter.';
      } else if (this.password.search(/[A-Z]/) < 0) {
        this.msg['password'] = 'Your password must contain at least one uppercase letter.';
      } else if (this.password.search(/[0-9]/) < 0) {
        this.msg['password'] = 'Your password must contain at least one digit.';
      } else if (this.password.match(/\s/)) {
        this.msg['password'] = 'Forbidden Characters: spaces.';
      } else if (this.password.search(/[!@#$%^&*(),.?":{}|<>]/g) < 0) {
        this.msg['password'] = 'Your password must contain at least one special character.';
      } else if (this.confirmPassword.length && this.confirmPassword != this.password) {
        delete this.msg['password'];
        this.msg['confirmPassword'] = 'Password Does Not Match!';
      } else if (this.confirmPassword.length && this.confirmPassword === this.password) {
        this.requiredFields.confirmPassword = '';
        delete this.msg['password'];
        delete this.msg['confirmPassword']
        this.isPasswordMatched = true;
      } else {
        delete this.msg['password'];
        this.error = true;
      }
      this.error = false;
      this.checkPassConfirmPass();
    },
    checkPassConfirmPass() {
      if (this.password === this.confirmPassword) {
        this.isPasswordMatched = true;
      } else {
        this.isPasswordMatched = false;
      }
    },

    isRequiredFieldEmpty() {
      let requiredFieldEmpty = false;

      if (this.otp == '') {
        requiredFieldEmpty = true;
        this.requiredFields.otp = 'p-invalid';
      }

      if (!this.password) {
        requiredFieldEmpty = true;
        this.requiredFields.password = 'p-invalid';
        this.msg['password'] = 'New Password is Required';
      }
      if (!this.confirmPassword) {
        requiredFieldEmpty = true;
        this.requiredFields.confirmPassword = 'p-invalid';
        this.msg['confirmPassword'] = 'Confirm Password is Required';
      }

      return requiredFieldEmpty;
    },

    clickResetPasswordHandler() {
      if (!this.isRequiredFieldEmpty() && !this.msg['password']) {
        const { id, otp } = this.$route.query;
        // if (!id || !otp) {
        if (!id) {
          this.showError('URL Parameters missing !');
          return;
        }

        let resetPasswordData = {
          urlToken: id,
          // otp: otp,
          new_password: this.password,
        };
        this.resetPassword(resetPasswordData)
            .then((resBody) => {
              if (resBody.status === 200) {
                this.showSuccess('Reset Password', 'Password Updated Successfully');
                setTimeout(() => this.$router.push({path: '/success'}), 1700);
              } else {
                this.showError('Reset Password Failed');
              }
            })
            .catch((err) => {
              console.log(resetPasswordData.token);
              console.log('reset password error', err.response);
            });
      }
    },
  },
};
</script>

<style></style>
