import {
    insertNewCategory,
    getCategories,
    updateCategoryById,
    deleteCategoryById, 
} from '../../api/CategoryService';

const state = {
};

const mutations = {

};

const actions = {
    actionInsertNewCategory(state, data) {
        let res = insertNewCategory(data); //method from service
        return res;
    },
    actionGetCategories(state, projectId) {
        let res = getCategories(projectId); //method from service
        return res;
    },
    async actionDeleteCategory(state, id) {
        let res = await deleteCategoryById(id);
        return res;
    },
    async actionUpdateCategory(state, data) {
        let res = updateCategoryById(data);
        return res;
    },
};

const getters = {
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
