import {
    authenticateDatabase,
    executeQuery,
} from '../../api/dbValidationService';

const state ={
        data: {},
        queryResults: [],
    }
const getters={
            data: state => state.data,
            queryResults: state => state.queryResults,
        }
const actions = {
    //--- Test Case
    async actionAuthenticateDatabase(state, data) {
        let res = await authenticateDatabase(data); //method from service
            return res.data;
    },
    async actionExecuteQuery(state,{ dataObj, token}) {
      let res = await executeQuery(dataObj, token); //method from service
        state.commit('RESULTSET', res.data);
        return res;
    }
    }
const mutations = {
    RESULTSET(state, results) {
        state.queryResults = results;
    },
}
export default {
    namespaced: true,
    getters,
    state,
    actions,
    mutations
}
