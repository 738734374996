import TestServiceRequest from '../utils/request/test_request';

const root = 'setting';
/**
 * Fetch settings
 * @returns Promise
 */
export const fetchWholeSettings = async () => {
    return await TestServiceRequest.get(`${root}`);
};

export default TestServiceRequest;
