<template>
  <div class="card db_validation-container">
    <div class="db_validation-wrapper">
      <section class="top">
        <div class="actions">
          <Button label="QUERY" class="qry" @click="databaseValidationActiveMode = 'query'" />
          <Button label="QUERY RESULT" class="qry-rslt" :disabled="!response" @click="databaseValidationActiveMode = 'queryResult'" />
        </div>
        <div class="timeout_info">
          <i class="fas fa-clock"></i>
          <p>Override Timeout : </p>
          <InputNumber v-model="ms" inputId="milliseconds" suffix=" ms" :min="1000" :max="10000"
            placeholder="Step Timeout (milliseconds)" />
        </div>
        <div class="varaible_info">
          <h6>Available Variables: </h6>
          <div class="variables" v-if="variables && variables.length">
            <span v-for="(variable, i) in variables" :key="i"
              :style="{ display: i < variableCount - 1 ? 'inherit' : 'none' }">
              <p class="var variable" v-tooltip.bottom="Object.values(variable)[1]">{{ Object.keys(variable)[1] }}{{ i <
                variables.length - 1 ? ',' : '' }}</p>
            </span>
            <p class="var more_variable" v-if="hiddenVariableCount" v-tooltip.bottom="hiddenVariableString">{{ `more
              +${hiddenVariableCount}` }}</p>
          </div>
          <p v-else class="var no_variables">No variables</p>
        </div>
        <Button label="EXECUTE QUERY" class="qry-rslt" @click="sendExecuteQuery('executeQuery')" />
        <Button class="saveDraft" label="Save as draft" @click="SAVE('draft')" />
        <Button v-if="componentMode === 'create' && !existingData?.is_drafted" label="ADD TO STEP"
          @click="SAVE('addToStep')" />
        <Button v-else-if="componentMode === 'update' && !existingData?.is_drafted" label="UPDATE"
          @click="SAVE('updateStep')" />
        <Button v-else label="ADD TO STEP" @click="SAVE('addToStepFromDraft')" />
      </section>

      <section class="section3" v-if="dataLoaded">
        <DatabaseConfig @databaseConfigUpdated="databaseConfigUpdated"
          @authValueUpdated="authTypeUpdated" :dbConfig="config"/>
        <QueryString @sqlQueryUpdated="sqlQueryUpdated" :sqlQuery="sqlQuery"/>
        <Dialog :draggable="false" v-model:visible="modalShow" modal header="Query Results" :style="{ width: '50vw' }" :breakpoints="{ '960px': '75vw', '641px': '100vw' }">
          <p>{{ queryResults.data }}</p>
          <p>Status:  {{ queryResults.status }}</p>
          <!-- <p>{{ queryResults.config }}</p>
          <p>{{ queryResults.status }}</p> -->
          <br>
        </Dialog>
        <!-- <RequestBody :code="json" @updatedCode="updatedCode" v-if="databaseValidationActiveMode === 'request'" /> -->

        <!-- <ResponseHeader :allHeaders="headers" :auth="auth" v-if="databaseValidationActiveMode === 'response'" /> -->
        <span>
          <h6>
            <strong>Note:</strong> Assertion will run before additional code when both are there.
          </h6>
          <i class="fas fa-info-circle"></i>
        </span>
        <span class="second">
          <h4>Assertion</h4>
          <div class="assertion-code-header">
            <InputSwitch v-model="runCallbackScript" />
            <h6>Run additional code on request results</h6>
          </div>
        </span>
        <Assertion v-if="!loadingAssertionStatus" @assertionUpdated="assertionUpdated" :allAssertions="assertions" :response="assertionResultResponse" />
        <AssertionCode v-if="!loadingAssertionStatus" @updateAssertionCode="setCallBackScript" :assertionCode="callback_script" :response="scriptResultResponse"/>
        <span v-if="loadingAssertionStatus">Loading...</span>
      </section>

      <section v-else>
        <p>Loading...</p>
      </section>
      <DataView v-if="dataLoaded && queryResultResponse" :response="queryResultResponse"/>
      <Logs v-if="databaseValidationActiveMode === 'queryResult' && !loadingAssertionStatus" :response="scriptResultResponse" />
    </div>
  </div>
</template>

<script>
import DatabaseConfig from './components/DatabaseConfig.vue';
import ResponseBody from './components/ResponseBody.vue';
import Assertion from './components/Assertion.vue';
import AssertionCode from './components/AssertionCode.vue';
import QueryString from './components/QueryString.vue';
import DataView from './components/DataView.vue';
import Logs from './components/Logs.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'database-validation',
  components: {
    DatabaseConfig,
    ResponseBody,
    Assertion,
    AssertionCode,
    QueryString,
    DataView,
    Logs,
  },
  data: () => ({
    databaseValidationActiveMode: 'query', // request, response

    selectedMethod: 'get',
    json: '',
    auth: { type: 'basic', value: '' },
    ms: 1000,
    assertions: [],
    sqlQuery: '',
    config: {},
    modalShow: false,
    queryResults: '',
    url: null,
    callback_script: null,
    runCallbackScript: false,
    variables: null,
    response: null,
    assertionResultResponse: null,
    queryResultResponse: null,
    scriptResultResponse:null,
    sending: false,
    loadingAssertionStatus: false,
    methods: [
      { name: 'GET', code: 'get' },
      { name: 'POST', code: 'post' },
      { name: 'PATCH', code: 'patch' },
      { name: 'PUT', code: 'put' },
      { name: 'DELETE', code: 'delete' }
    ],
    variableCount: 4,
    errors: {},
    dataLoaded: true,
    componentMode: 'create' //* create / update
  }),
  props: {
    existingData: {
      type: Object,
    },
    sendData: {
      type: Object,
      required: true,
      default: {},
    }
  },
  created() {
    this.dataLoaded = false;
  },
  mounted() {
    this.setComponentVars();

    if (this.existingData) {
      this.componentMode = 'update';
      const { validationInfo, error_obj } = this.existingData;
      console.log(this.existingData);

      this.sqlQuery = validationInfo.sqlQuery;
      this.config = validationInfo.config;
      this.ms = validationInfo.timeout;
      this.callback_script = validationInfo.callback_script ? validationInfo.callback_script : null;
      this.runCallbackScript = validationInfo.runCallbackScript;

      if (error_obj?.validationResult) {
        this.assertionResultResponse = error_obj.validationResult.assertionResult;
      }
      if (error_obj?.validationResult?.scriptResult) {
        this.scriptResultResponse = error_obj.validationResult.scriptResult;
      }
      this.assertions = validationInfo.assertions.length ? [...validationInfo.assertions] : [];
      this.dataLoaded = true;
    } else {
      this.componentMode = 'create';
      this.sqlQuery = null;
      this.config = {};
      this.ms = 1000;
      // this.callback_script = null;
      this.runCallbackScript = false;
      this.dataLoaded = true;
    }
  },
  computed: {
    ...mapGetters({
      caseVariables: 'variable/caseVariables',
    }),
    hiddenVariableCount() {
      if (this.variables && this.variables.length > this.variableCount) {
        return (this.variables.length - this.variableCount) + 1;
      } else {
        return null;
      }
    },
    hiddenVariableString() {
      if ((this.variables && this.variables.length > this.variableCount) + 1) {
        let varsString = '';
        const vars = this.variables.slice(this.variableCount - 1, this.variables.length);
        for (let i = 0; i < vars.length; i++) {
          const element = vars[i];
          varsString += `${Object.keys(element)[1]}: ${Object.values(element)[1]} \n\n`;
        }
        return varsString
      } else {
        return null;
      }
    }
  },
  watch: {
    sendData: {
      deep: true,
      handler(value) {
        const { data, componentName } = value;
        // if (componentName === 'databaseValidation') {
        if (componentName === 'databaseValidation') {
          const { validationResult, execution_time, type } = data;

          this.sending = false;
          this.loadingAssertionStatus = false;
          this.databaseValidationActiveMode = 'queryResult';

          // this.response = validationResult.assertionResult;
          this.assertionResultResponse = validationResult.assertionResult;
          if (validationResult) {
            this.scriptResultResponse = validationResult.scriptResult;
          }
          this.queryResultResponse = validationResult.queryResult;
          // this.callback_script = validationResult?.callback_script?.length ? validationResult.callback_script : '';
          // this.assertions = validationResult.assertionResult.length ? [...validationResult.assertionResult] : [];
        }
      }
    },
    caseVariables() {
      this.setComponentVars();
    }
  },
  methods: {
    ...mapActions({
      authenticateDatabase: 'databaseValidation/actionAuthenticateDatabase',
      executeQueryApi: 'databaseValidation/actionExecuteQuery',
    }),
    async executeQuery() {
      const cred = {
        username: "admin",
        password: "admin",
        "rememberMe": true
      }
      let res = await this.authenticateDatabase(cred);

      const config = {
        connectionString: this.config.connectionString,
        jdbcDriverClass: this.config.JDBC_Class,
        databaseType: this.config.databaseType,
        userName: this.config.username,
        password: this.config.password
      }

      if (this.sqlQuery) {
        const dataObj = {
          id: "64f96389b328f12f888fc025",
          sql: this.sqlQuery,
          config: config
        }
        let token = res.id_token

        let exeRes = await this.executeQueryApi({dataObj, token});

        if (exeRes.status == 200) {
          if (exeRes.data.length) {
            this.modalShow = true;
            // console.log(JSON.stringify(exeRes))
            this.queryResults = exeRes;
          }
          this.$toast.add({ severity: 'success', summary: 'Execute Query', detail: "SUCCESS", life: 5000 });
        }
      } else {
        this.$toast.add({ severity: 'error', summary: 'Failed To Execute Query', detail: "Database Configuration and Query String is required", life: 5000 });
      }

      return

    },

    setComponentVars() {
      this.variables = null;

      this.variables = this.caseVariables.map(varr => {

        return {
          _id: varr._id,
          [varr.name]: varr.value
        }
      });
    },
    setCallBackScript(value) {
      this.callback_script = value;
    },

    SAVE(actionType) {
      const obj = {
        ...(this.existingData ? { "existingStepId": this.existingData._id } : {}),
        "sqlQuery": this.sqlQuery,
        "config": this.config,
        "timeout": this.ms,
        "assertions": this.assertions,
        "callback_script": this.callback_script,
        "runCallbackScript": this.runCallbackScript,
        "actionType": actionType,
        "response": null,
      }

      if (Object.keys(this.errors).length > 0) {
        for (let i = 0; i < Object.values(this.errors).length; i++) {
          const er = Object.values(this.errors)[i];
          this.$toast.add({ severity: 'error', summary: 'ERROR', detail: er, life: 3000 });
        }
        return
      }

      if (actionType == 'addToStep') {
        this.sending = true;
        this.loadingAssertionStatus = true;
      }

      console.log(obj);
      this.$emit('save', obj);
    },

    sendExecuteQuery(actionType) {
      const obj = {
        "sqlQuery": this.sqlQuery,
        "config": this.config,
        "timeout": this.ms,
        "assertions": this.assertions,
        "callback_script": this.callback_script,
        "runCallbackScript": this.runCallbackScript,
        "actionType": actionType,
        "response": null,
      }

      if (Object.keys(this.errors).length > 0) {
        for (let i = 0; i < Object.values(this.errors).length; i++) {
          const er = Object.values(this.errors)[i];
          this.$toast.add({ severity: 'error', summary: 'ERROR', detail: er, life: 3000 });
        }
        return
      }

      if (actionType == 'executeQuery') {
        this.sending = true;
        this.loadingAssertionStatus = true;
      }

      console.log(obj);
      this.$emit('sendExecuteQuery', obj);
    },

    authTypeUpdated(auth) {
      this.auth = auth;
    },
    databaseConfigUpdated(config) {
      this.config = config;
    },

    sqlQueryUpdated(query) {
      this.sqlQuery = query;
    },
    assertionUpdated(assertions) {
      this.assertions = [...assertions]
    },
    updateHeader(allHeaders) {
      this.headers = [...allHeaders];
    },
    async updatedCode(val) {
      try {
        const response = await JSON.parse(val)
        this.json = response;
        if ('jsonError' in this.errors) {
          delete this.errors["jsonError"]
        }
      } catch (err) {
        const msg = err.message;
        console.log(msg);
        if (msg?.toLowerCase()?.includes('unterminated string in json at position')) {
          this.errors = { ...this.errors, jsonError: `Unterminated string found in json, please rechheck the request body` };
        } else {
          this.errors = { ...this.errors, jsonError: msg };
        }
      }
    }
  },

}
</script>

<style lang="scss" src="@/styles/databaseValidation.scss" />
