import {
    deleteAccess,
    deleteIssueType,
    deleteResolution,
    deleteRole,
    fetchAccessList,
    fetchIssueTypeList,
    fetchMembers,
    fetchResolutionList,
    fetchResolutionListByCaseId,
    fetchRoleList,
    fetchSingleProjectMembers,
    insertAccess,
    insertIssueType,
    insertResolution,
    insertRole,
    memberInvite,
    inviteMemberSingleProject,
    manageOrganizationMember,
    removeMember,
    removeSingleMember,
    removeSingleMemberMobile,
    updateAccess,
    updateIssueType,
    updateMember,
    updateResolution,
    updateRole,
} from '../../api/TjAdminFeaturesService';

const state = {
    issueTypeList: [],
    resolutionList: [],
    accessList: [],
    roleList: [],
    caseResolutionList: [],
    isTeamMemberListLoaded: false,
    noDataFoundMsg: null,
    // members: [],
}
const mutations = {

    FETCH_ISSUE_TYPE_LIST(state, res) {
        state.issueTypeList = res;
    },

    FETCH_RESOLUTION_LIST(state, res) {
        state.resolutionList = res;
    },

    FETCH_CASE_RESOLUTION_LIST(state, res) {
        state.caseResolutionList = res;
    },

    FETCH_ACCESS_LIST(state, res) {
        state.accessList = res;
    },

    FETCH_ROLE_LIST(state, res) {
        state.roleList = res;
    },
    IS_TEAM_MEMBER_LIST_LOADED(state, status) {
        state.isTeamMemberListLoaded = status;
    },
    NO_DATA_FOUND_MSG(state, msg) {
        state.noDataFoundMsg = msg;
    }
    // FETCH_MEMBER_LIST(state, res) {
    //     state.members = res;
    // },

}

const actions = {

    //---Start Issue 
    actionInsertIssueType(state, reqDataObj) {
        let res = insertIssueType(reqDataObj); //method from service
        return res;
    },

    actionUpdateIssueType(state, reqDataObj) {
        let res = updateIssueType(reqDataObj); //method from service
        return res;
    },

    actionDeleteIssueType(state, reqDataObj) {
        let res = deleteIssueType(reqDataObj); //method from service
        return res;
    },

    actionFetchIssueTypeList(state) {

        fetchIssueTypeList()
            .then((resBody) => {
                if (resBody.status === 200) {
                    state.commit("FETCH_ISSUE_TYPE_LIST", resBody.data);
                } else {
                    state.commit("FETCH_ISSUE_TYPE_LIST", resBody);
                }
            })
            .catch((err) => {
            });
    },

    //---End Issue 

    //---Start Resolution 

    actionInsertResolution(state, reqDataObj) {
        let res = insertResolution(reqDataObj); //method from service
        return res;
    },

    actionUpdateResolution(state, reqDataObj) {
        let res = updateResolution(reqDataObj); //method from service
        return res;
    },

    actionDeleteResolution(state, reqDataObj) {
        let res = deleteResolution(reqDataObj); //method from service
        return res;
    },

    actionFetchResolutionList(state) {

        fetchResolutionList()
            .then((resBody) => {
                if (resBody.status === 200) {
                    state.commit("FETCH_RESOLUTION_LIST", resBody.data);
                } else {
                    state.commit("FETCH_RESOLUTION_LIST", resBody.data);
                }
            })
            .catch((err) => {
            });
    },

    actionFetchResolutionListByCaseId(state, testCaseId) {

        fetchResolutionListByCaseId(testCaseId)
            .then((resBody) => {
                if (resBody.status === 200) {
                    state.commit("FETCH_CASE_RESOLUTION_LIST", resBody.data);
                } else {
                    state.commit("FETCH_CASE_RESOLUTION_LIST", resBody.data);
                }
            })
            .catch((err) => {
            });
    },

    //---End Resolution 

    //---Start Access 

    actionInsertAccess(state, reqDataObj) {
        let res = insertAccess(reqDataObj); //method from service
        return res;
    },

    actionUpdateAccess(state, reqDataObj) {
        let res = updateAccess(reqDataObj); //method from service
        return res;
    },

    actionDeleteAccess(state, reqDataObj) {
        let res = deleteAccess(reqDataObj); //method from service
        return res;
    },

    actionFetchAccessList(state) {

        fetchAccessList()
            .then((resBody) => {
                if (resBody.status === 200) {
                    state.commit("FETCH_ACCESS_LIST", resBody.data);
                } else {
                    state.commit("FETCH_ACCESS_LIST", resBody.data);
                }
            })
            .catch((err) => {
            });
    },
    //---End Access 

    //---Start Role 

    actionInsertRole(state, reqDataObj) {
        let res = insertRole(reqDataObj); //method from service
        return res;
    },

    actionUpdateRole(state, reqDataObj) {
        let res = updateRole(reqDataObj); //method from service
        return res;
    },

    actionDeleteRole(state, reqDataObj) {
        let res = deleteRole(reqDataObj); //method from service
        return res;
    },

    actionFetchRoleList(state) {

        fetchRoleList()
            .then((resBody) => {
                if (resBody.status === 200) {
                    state.commit("FETCH_ROLE_LIST", resBody.data);
                } else {
                    state.commit("FETCH_ROLE_LIST", resBody.data);
                }
            })
            .catch((err) => {
            });
    },


    //---End Role 
    
    //---Start Member Management
    actionMemberInvite({state, commit}, memberInfo) {
        return memberInvite(memberInfo)
            .then(res => {
                if (res.status === 200) {
                    console.log('member invite action===>', res);
                    return res;
                } else {
                    return res;
                }
            })
    },

    actionManageOrganizationMember({state, commit}, memberInfo) {
        return manageOrganizationMember(memberInfo).then(res => {
            console.log("member invite action res ====>>", res)
            if (res.status.code === 200) {
                console.log('member invite action===>', res);
                return res;
            } else {
                return res;
            }
        })
    },

    actionClickRemoveMember(state, memberId) {
        return removeMember(memberId)
            .then(res => {
                if (res.status === 200) {
                    console.log("member remove action===>", res);
                    return res;
                } else {
                    return res;
                }
            })
    },
    actionInviteSingleProjectMember(state, data) {
        return inviteMemberSingleProject(data)
            .then(res => {
                console.log("🎁🎁 member invite action res====>", res);
                return res;
            })
            .catch(err => {
                
            })
    },

    actionRemoveSingleMember(state, data) {
        return removeSingleMember(data)
            .then(res => {
                if (res.status === 200) {
                    return res;
                } else {
                    return res;
                }
            })
    },
    actionRemoveSingleMemberMobile(state, access) {
        return removeSingleMemberMobile(access)
            .then(res => {
                if (res.status === 200) {
                    console.log("single member remove action", res);
                    return res;
                } else {
                    return res;
                }
            })
    },

    actionClickUpdateMember(state, selectedMemberInfo) {
        return updateMember(selectedMemberInfo)
            .then(res => {
                if (res.status === 200) {
                    console.log("member update action===>", res);
                    return res;
                } else {
                    return res;
                }
            })
    },

    actionFetchMembers(state) {
        state.commit('IS_TEAM_MEMBER_LIST_LOADED', false);
        return fetchMembers()
            .then((resBody) => {
                state.commit('IS_TEAM_MEMBER_LIST_LOADED', true);
                if (resBody.status === 200) {
                    console.log("action members", resBody.data.members);
                    // state.commit("FETCH_MEMBER_LIST", resBody.data.members);
                    state.commit('NO_DATA_FOUND_MSG', null);
                    return resBody;
                } else {
                    // state.commit("FETCH_MEMBER_LIST", resBody.data);
                    console.log("else member response===>", resBody)
                    state.commit('NO_DATA_FOUND_MSG', resBody && resBody.data && resBody.data.errors)
                    return resBody;
                }
            })
            .catch((err) => {
            });
    },

    // Old Single Project Member Fetch Action
    // actionFetchSingleProjectMember(state, memberIds) {
    //     state.commit('IS_TEAM_MEMBER_LIST_LOADED', false);
    //     return fetchSingleProjectMembers(memberIds)
    //         .then(resBody => {
    //             state.commit('IS_TEAM_MEMBER_LIST_LOADED', true);
    //             if (resBody.status === 200) {
    //                 state.commit('NO_DATA_FOUND_MSG', null);
    //                 console.log("project member action", resBody);
    //                 return resBody;
    //             } else {
    //                 console.log('resBody member error action===>', resBody && resBody.data && resBody.data.errors);
    //                 state.commit('NO_DATA_FOUND_MSG', resBody && resBody.data && resBody.data.errors);
    //                 return resBody;
    //             }
    //         })
    //         .catch(err => {
    //             return err;
    //         })
    // }
    
    // Old Single Project Member Fetch Action
    actionFetchSingleProjectMember(state, projectId) {
        state.commit('IS_TEAM_MEMBER_LIST_LOADED', false);
        return fetchSingleProjectMembers(projectId)
        .then(resBody => {
                state.commit('IS_TEAM_MEMBER_LIST_LOADED', true);
                if (resBody.status === 200) {
                    state.commit('NO_DATA_FOUND_MSG', null);
                    console.log("project member action", resBody);
                    return resBody;
                } else {
                    console.log('resBody member error action===>', resBody && resBody.data && resBody.data.errors);
                    state.commit('NO_DATA_FOUND_MSG', resBody && resBody.data && resBody.data.errors);
                    return resBody;
                }
            })
            .catch(err => {
                return err;
            })
    }
    //---End Member Management
}

const getters = {
    issueTypeList: state => state.issueTypeList,
    resolutionList: state => state.resolutionList,
    caseResolutionList: state => state.caseResolutionList,
    accessList: state => state.accessList,
    roleList: state => state.roleList,
    isTeamMemberListLoaded: state => state.isTeamMemberListLoaded,
    noDataFoundMsg: state => state.noDataFoundMsg,
    // members: state => state.members,
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}