import Access from '../pages/Access';
import Error from '../pages/Error';
// import Login from '../pages/Login';
import NotFound from '../pages/NotFound';
import Wizard from '../pages/Wizard';
import AgentGuideLine from '../pages/AgentGuideLine.vue';
import Downloads from '../pages/Downloads.vue';
// import LoginPage2 from '../views/LoginPage2';
import LoginPage from '../views/LoginPage';
import SuccessPage from '../views/SuccessPage.vue';
import ForgotPasswordPage from '../views/ForgotPasswordPage';
import SignUpPage from '../views/SignUpPage';
import CheckEmailPage from '../views/CheckEmailPage';
import VerifyEmailPage from '../views/VerifyEmailPage';
import VerifyEmailLandingPage from '../views/VerifyEmailLandingPage';

import ResetPassword from '../views/ResetPassword';
import PermissionPage from '../views/PermissionPage.vue';

import {GUEST_MENU} from '../utilities/menu/PointWiseRouteConsts';

const guests = [
    // {
    // 	path: '/login',
    // 	name: 'login',
    // 	component: Login
    // },
    // {
    // 	path: GUEST_MENU.LOGIN_PAGE,
    // 	name: 'login',
    // 	component: AuthLayout
    // },

    // {
    // 	path: GUEST_MENU.SIGNUP_PAGE,
    // 	name: 'signup',
    // 	component: AuthLayout
    // },
    // {
    // 	path: GUEST_MENU.RESET_PASSWORD,
    // 	name: 'reset_password',
    // 	component: AuthLayout
    // },

    {
        path: GUEST_MENU.LOGIN_PAGE,
        fullPath: GUEST_MENU.LOGIN_PAGE,
        name: 'loginpage',
        component: LoginPage,

    },
    {
        path: GUEST_MENU.SUCCESS,
        fullPath: GUEST_MENU.SUCCESS,
        name: 'SuccessPage',
        component: SuccessPage,

    },
    {
        path: GUEST_MENU.FORGOT_PASS,
        fullPath: GUEST_MENU.FORGOT_PASS,
        name: 'forgotpasswordpage',
        component: ForgotPasswordPage,

    },
    // {
    //     path: '/downloads',
    //     fullPath: '/downloads',
    //     name: 'downloadspage',
    //     component: Downloads,
    //
    // },
    // {
    //     path: '/agent-installation-guideline',
    //     fullPath: '/agent-installation-guideline',
    //     name: 'agentGuideLine',
    //     component: AgentGuideLine,
    //
    // },
    {
        path: GUEST_MENU.RESET_PASSWORD,
        name: 'resetPassword',
        fullPath: GUEST_MENU.RESET_PASSWORD,
        component: ResetPassword
    },
    {
        path: GUEST_MENU.SIGNUP_PAGE,
        name: 'signuppage',
        component: SignUpPage
    },
    {
        path: GUEST_MENU.CHECKEMAIL_PAGE,
        name: 'checkemailpage',
        component: CheckEmailPage
    },
    {
        path: GUEST_MENU.VERIFY_EMAIL,
        name: 'verifyemaillandingpage',
        fullPath: GUEST_MENU.VERIFY_EMAIL,
        component: VerifyEmailLandingPage
    },
    {
        path: GUEST_MENU.VERIFY_EMAIL_PAGE,
        name: 'verifyemailpage',
        component: VerifyEmailPage
    },
    {path: '/access-denied', component: PermissionPage},
    // {
    // 	path: GUEST_MENU.LOGIN_PAGE2,
    // 	name: 'loginpage2',
    // 	component: LoginPage2
    // },
    // {
    // 	path: GUEST_MENU.LOGIN_PAGE3,
    // 	name: 'loginpage3',
    // 	component: LoginPage3
    // },
    {
        path: '/error',
        name: 'error',
        component: Error,
        meta: {
            requiresAuth: true,
            title: 'User Account Setup',
            roles: ['admin'],
        },

    }, {
        path: '/access',
        name: 'access',
        component: Access
    }, {
        path: '/notfound',
        name: 'notfound',
        component: NotFound
    }, {
        path: '/wizard',
        name: 'wizard',
        component: Wizard,

    }

]
export default guests
