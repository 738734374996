<template>
  <Dialog :draggable="false" header="Edit Test Case" v-model:visible="updateModalVisible" :style="{ width: '24rem' }"
    :modal="true" class="custom-dialog">
    <div class="p-grid p-fluid">
      <div class="p-col-12 m-0 p-0">
        <div class="input-group">
          <div class="p-field input-description">
            <label>Test Case Name</label>
            <InputText v-model="updateTestCaseObj.test_case_name" type="text" placeholder="Enter TestCase Name" />
          </div>

          <div class="p-field">
            <label>Priority</label>
            <Dropdown v-model="selectedPriority" :options="priorities" optionValue="name" optionLabel="name"
              style="width: 100%" showClear placeholder="Select Priority" />
          </div>

          <div class="p-field">
            <label>Category</label>
            <Dropdown v-model="selectedCategory" :options="categories" optionValue="id" optionLabel="name"
              style="width: 100%" showClear filter placeholder="Select Category" />
          </div>

          <TjCustomChipsVue :tagList="updateTestCaseObj.tags" @onUpdateChipsList="onUpdateChipsList" />
          <Button label="Save Changes" class="p-button-primary save-changes-button" @click="clickEditTestCase"
            :style="{ marginTop: '15px' }" />
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import TjCustomChipsVue from '../TjCustomChips.vue';

export default {
  name: 'TestcaseUpdateDialog',
  components: { TjCustomChipsVue },
  props: {
    updateModalVisibleProps: {
      type: Boolean,
      required: true,
    },
    savedTestCase: {},
    testCaseId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      resolutionOptions: [
        { name: 'FHD 1920X1080', value: '1920x1080' },
        { name: 'Laptop 1366X768', value: '1366x768' },
        { name: 'Tablet', value: '768x435' },
      ],
      updateModalVisible: false,
      updateTestCaseObj: {},
      selectedPriority: null,
      priorities: [
        {
          name: 'Critical',
          code: 1,
        },
        {
          name: 'High',
          code: 2,
        },
        {
          name: 'Medium',
          code: 3,
        },
        {
          name: 'Low',
          code: 4,
        },
      ],
      selectedCategory: null,
      categories: [],
    };
  },
  created() {
    this.updateModalVisible = this.updateModalVisibleProps;
    this.updateTestCaseObj = { ...this.savedTestCase };
    this.getCategories(this.selectedProjectId).then(res => {
      if (res?.status === 200 && res?.data?.length) {
        this.categories = res.data;
      }
    });

    if (this.updateTestCaseObj.priority !== null) {
      this.selectedPriority = this.updateTestCaseObj.priority;
    }

    if (this.updateTestCaseObj.category_id !== null) {
      this.selectedCategory = this.updateTestCaseObj.category_id.id;
    }
  },

  methods: {
    ...mapActions({
      updateTestcase: 'testCase/actionUpdateTestcase',
      getCategories: 'category/actionGetCategories',
    }),

    showSuccess(summary, msgDetail) {
      this.$toast.add({ severity: 'success', summary: summary, detail: msgDetail, life: 5000 });
    },

    showErrorMsg(msgDetail) {
      this.$toast.add({ severity: 'error', summary: 'Error Message', detail: msgDetail, life: 5000 });
    },

    onUpdateChipsList(updatedChipsList) {
      this.updateTestCaseObj.tags = updatedChipsList;
    },

    clickEditTestCase() {
      this.updateTestCaseObj.priority = this.selectedPriority;
      this.updateTestCaseObj.category_id = this.selectedCategory;
      this.updateTestcase(this.updateTestCaseObj)
        .then(resBody => {
          if (resBody.status === 200) {
            // console.log('edit-success-res', resBody);
            this.showSuccess(resBody.message.MSG_SHORT, resBody.message.MSG_LONG);
            this.updateModalVisible = !this.updateModalVisible;
            this.$emit('fetchUpdateTestcase', this.testCaseId);
          } else {
            this.showErrorMsg('Test Case Update Unsuccessful.');
          }
        })
        .catch(err => {
          console.log('update-res-err', err.response.data);
        });
    },
  },

  computed: {
    ...mapGetters({
      selectedProjectId: 'project/projectID',
    }),
  },
};
</script>

<style></style>
