import {createStore} from 'vuex'
// import getters from './getters'


// Modules Loader
const modulesFiles = require.context('./modules', true, /\.js$/)
let modules = modulesFiles
    .keys()
    .reduce((modules, modulePath) => {
        // console.log("⛳ ⛳ ⛳ ⛳ ⛳ ⛳ ⛳ ⛳ ⛳ ⛳ ⛳ ⛳ ⛳ ⛳ ⛳ ⛳ ⛳ ⛳ ⛳ ⛳ ⛳ ⛳ ⛳ ⛳ ⛳ ", {modules, modulePath});
        const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
        const value = modulesFiles(modulePath)
        modules[moduleName] = value.default
        return modules
    }, {})

console.log("⛳ ⛳ ⛳ ⛳ ⛳ ⛳ ⛳ ⛳ ⛳ ⛳ ⛳ ⛳ ⛳ ⛳ ⛳ ⛳ ⛳ ⛳ ⛳ ⛳ ⛳ ⛳ ⛳ ⛳ ⛳ ", modules);
// Store instance
const store = createStore({
    modules: modules
})

export default store