import {
    checkExistingGroupName,
    deleteTestcases,
    deleteTestSteps,
    fetchCurrentTestCase,
    fetchShareableGroup,
    fetchTestCaseList,
    fetchTestrunResult,
    fetchTestStepList,
    insertCloneTestCase,
    insertNewTestCase,
    insertPropertieChanges,
    updateTestStep,
    insertShareableGroup,
    insertTestStep,
    insertTestStepOnEachMove,
    insertTestStepShareable,
    serverInfo,
    updateTestcase,
    updateTestcaseOnMouseMove,
    updateTestCaseVariables,
    insertNewGeneratedEmail,
    getGeneratedEmails,
    deleteGeneratedEmailById,
    fetchCaseResultsFromRuns

} from '../../api/TestCaseService';

const state = {
    noTestcaseFoundMsg: null,

    resolutionOptions: [
        {name: 'FHD 1920X1080', code: '1'},
        {name: 'Laptop 1366X768', code: '2'},
        {name: 'Mobile', code: '3'},
    ],
    flatTestCaseList: null,
    testCaseList: [
        // {
        //     testCaseName: 'Test Case 1',
        //     testCaseId: 1,
        //     key: 1,
        //     rootId: 1,
        //     type: 'testCase',
        //     subTestCases: [],
        //     tagIds: [],
        //     executeStatus: 'pass',
        // },
        // {
        //     testCaseName: "Test Case 2",
        //     testCaseId: 2,
        //     key: 2,
        //     rootId: 2,
        //     type: 'testCase',
        //     subTestCases: [],
        //     tagIds: [],
        //     executeStatus: 'fail',
        // },
        // {
        //     testCaseName: "Test Case 3",
        //     testCaseId: 3,
        //     key: 3,
        //     rootId: 3,
        //     type: 'testCaseGroup',
        //     subTestCases: [
        //         {
        //             testCaseName: "Test Case 4",
        //             testCaseId: 4,
        //             key: 10,
        //             rootId: 10,
        //             type: 'testCase',
        //             parentKey: '',
        //             subTestCases: [],
        //             tagIds: [],
        //         },
        //     ],
        //     tagIds: [],
        //     executeStatus: 'pass',
        // },
    ],
    testrunResultList: [],
    isFetchTestCaseLoading: false,
    isTestrunResultListLoaded: false,
    currentTestcase: [],
    currentSavedSteps: [],
    checkedtestCaseList: [],

    // name: '',
    key: 1,
    rootId: 1,
    type: '',
    subTestCases: [],
    executeStatus: '',
    TestCaseListForDropdown: [],

    newTestCaseObj: {
        testCaseName: '',
        baseURL: '',
        testDescription: '',
        testCaseResolution: {name: 'FHD 1920X1080', code: '1'},
        tags: [],
    },

    testCaseSaveRes: {},
    testStepList: [],
    isTestCaseLoaded: false,
    filterDuration: '7',
};

const mutations = {
    SUBIT_NEW_TEST_CASE(state, newTestCase) {
        state.testCaseList.unshift(newTestCase);
    },

    CHECKED_TEST_CASE(state, checkedTestCases) {
        state.checkedTestCaseList = checkedTestCases;
    },

    HANDLE_SELECTED_TEST_CASE(state, selectedTestCases) {
        state.checkedTestCaseList.push(selectedTestCases);
    },

    // INSERT_TEST_CASE(state, res) {
    //     state.testCaseSaveRes = res;
    // },

    // INSERT_TEST_CASE(state, res) {
    //     state.testCaseSaveRes = res;
    // },

    FETCH_TEST_CASE_LIST(state, res) {
        state.testCaseList = res;
    },

    HANDLE_CASE_FETCH_LOADER(state, loaderStatus) {
        state.isFetchTestCaseLoading = loaderStatus;
    },

    FETCH_CURRENT_TEST_CASE(state, res) {
        state.currentTestcase = res.testCaseResult;
        // state.currentSavedSteps= res.testStepsResult;
    },
    SAVE_FLAT_TEST_CASE_LIST(state, res) {
        state.flatTestCaseList = res;
        // state.currentSavedSteps= res.testStepsResult;
    },
    TEST_CASE_LIST_FOR_DROPDOWN(state, res) {
        state.TestCaseListForDropdown = res;
        // state.currentSavedSteps= res.testStepsResult;
    },

    FETCH_CURRENT_SAVED_STEP(state, res) {
        state.currentSavedSteps = res.testStepsResult;
    },

    FETCH_TEST_STEP_LIST(state, res) {
        state.testStepList = res;
    },
    FETCH_TEST_RUN_RESULT(state, res) {
        state.testrunResultList = res;
    },
    FILTER_DURATION(state, duration) {
        state.filterDuration = duration;
    },
    IS_TESTCASE_LOADED(state, status) {
        state.isTestCaseLoaded = status;
    },
    NO_TESTCASE_FOUND_MSG(state, msg) {
        state.noTestcaseFoundMsg = msg;
    },
    IS_TESTRUN_RESULT_FETCH_LOADED(state, loaderStatus) {
        state.isTestrunResultListLoaded = loaderStatus;
    },
    IS_CASE_RESULT_FETCH_LOADED(state, loaderStatus) {
        state.isCaseResultListLoaded = loaderStatus;
    },

    // HANDLE_CLONE_TEST_CASE(state, reqParamObj) {

    //     console.log('testcase-store-reqParamObj', reqParamObj);
    //     if (!reqParamObj.multipleCaseCheckedOrSelected) {

    //         console.log('store-checked-checkedTestCaseList', state);
    //         state.testCaseList.filter(testcase => {

    //             if (state.checkedTestCaseList && state.checkedTestCaseList.length) {
    //                 if (testcase.testCaseId === state.checkedTestCaseList[0].testCaseId) {
    //                     console.log('matchedId-testcase--', testcase);

    //                     let cloneTestcase = { ...testcase };
    //                     cloneTestcase.testCaseId = state.testCaseList.length + 1;
    //                     cloneTestcase.testCaseName = reqParamObj.cloneTestCaseName;

    //                     console.log('testcase-store-cloneTestcase', cloneTestcase);
    //                     state.checkedTestCaseList.splice(0, 1);
    //                     state.testCaseList.unshift(cloneTestcase);
    //                 }
    //             }

    //         });

    //         state.checkedTestCaseList = [];

    //     } else {

    //         state.testCaseList.filter(testcase => {

    //             if (state.checkedTestCaseList && state.checkedTestCaseList.length) {

    //                 for (let index = 0; index < state.checkedTestCaseList.length; index++) {
    //                     // const element = state.checkedTestCaseList[index];

    //                     if (testcase.testCaseId === state.checkedTestCaseList[index].testCaseId) {
    //                         console.log('matchedId-testcase--', testcase);

    //                         let cloneTestcase = { ...testcase };
    //                         cloneTestcase.testCaseId = state.testCaseList.length + 1;
    //                         cloneTestcase.testCaseName = 'Copy of ' + cloneTestcase.testCaseName;

    //                         console.log('testcase-store-cloneTestcase', cloneTestcase);
    //                         state.checkedTestCaseList.splice(index, 1);
    //                         state.testCaseList.unshift(cloneTestcase);
    //                     }

    //                 };

    //             }

    //         });

    //         state.checkedTestCaseList = [];

    //     }

    // }
};

const actions = {
    //--- Test Case
    actionInsertNewTestCase(state, newTestCaseObj) {
        let res = insertNewTestCase(newTestCaseObj); //method from service
        return res;
    },
    actionFetchCaseList(state, reqDataObj) {
        console.log('case list===>', reqDataObj);
        state.commit('IS_TESTCASE_LOADED', false);
        state.commit('HANDLE_CASE_FETCH_LOADER', true);
        state.commit('FILTER_DURATION', reqDataObj.duration);
        let bodyData = {
            issue_type_id: reqDataObj.issueTypeID,
            last_activity: reqDataObj.lastActivity,
        };
        fetchTestCaseList(reqDataObj.projectID, reqDataObj.duration, bodyData)
            .then(resBody => {
                state.commit('IS_TESTCASE_LOADED', true);
                if (resBody.status === 200) {
                    state.commit('NO_TESTCASE_FOUND_MSG', null);
                    state.commit('HANDLE_CASE_FETCH_LOADER', false);
                    state.commit('FETCH_TEST_CASE_LIST', resBody.data.testCaseResult);
                    let flatTestcases = [];
                    resBody &&
                    resBody.data &&
                    resBody.data.testCaseResult &&
                    resBody.data.testCaseResult.map(testCase => {
                        if (testCase.type === 'group') {
                            if (testCase.children != undefined) {
                                testCase.children.map(child => {
                                    flatTestcases.push(child);
                                });
                            }
                        } else {
                            flatTestcases.push(testCase);
                        }
                    });
                    state.commit('SAVE_FLAT_TEST_CASE_LIST', flatTestcases);
                } else {
                    state.commit('SAVE_FLAT_TEST_CASE_LIST', []);
                    // state.commit('NO_TESTCASE_FOUND_MSG', resBody && resBody.response && resBody.response.data && resBody.response.data.message && resBody.response.data.message.MSG_SHORT);
                    state.commit('NO_TESTCASE_FOUND_MSG', "No test cases found on this duration");
                    state.commit('FETCH_TEST_CASE_LIST', []);
                    state.commit('HANDLE_CASE_FETCH_LOADER', false);
                }
            })
            .catch(err => {
                state.commit('NO_TESTCASE_FOUND_MSG', null);
                state.commit('IS_TESTCASE_LOADED', true);
            });
    },

    actionFetchCasesListForDropdown(state, reqDataObj) {
        let bodyData = {
            issue_type_id: reqDataObj.issueTypeID,
            last_activity: reqDataObj.lastActivity,
        };
        fetchTestCaseList(reqDataObj.projectID, reqDataObj.duration, bodyData)
            .then(resBody => {

                let flatTestcases = [];
                resBody &&
                resBody.data &&
                resBody.data.testCaseResult &&
                resBody.data.testCaseResult.map(testCase => {
                    if (testCase.type === 'group') {
                        if (testCase.children != undefined) {
                            testCase.children.map(child => {
                                flatTestcases.push(child);
                            });
                        }
                    } else {
                        flatTestcases.push(testCase);
                    }
                });
                // console.log('⛎⛎⛎⛎⛎⛎⛎⛎⛎⛎⛎', flatTestcases);
                state.commit("TEST_CASE_LIST_FOR_DROPDOWN", flatTestcases)
            })
            .catch(err => {
                console.log("🍎🍎🍎🍎🍎🍎🍎🍎🍎🍎🍎", err);
                state.commit("TEST_CASE_LIST_FOR_DROPDOWN", [])
            });
    },

    actionFetchCurrentTestCase(state, testCaseId) {
        return fetchCurrentTestCase(testCaseId)
            .then(resBody => {
                if (resBody.status === 200) {
                    state.commit('FETCH_CURRENT_TEST_CASE', resBody.data);
                    state.commit('FETCH_CURRENT_SAVED_STEP', resBody.data);
                    return resBody;
                } else {
                    state.commit('FETCH_CURRENT_TEST_CASE', resBody);
                    return resBody;
                }
            })
            .catch(err => {
            });
    },

    actionInsertStepOnEachMove(state, data) {
        let res = insertTestStepOnEachMove(data); //method from service
        return res;
    },

    actionCheckedTestCases(state, checkedTestCases) {
        state.commit('CHECKED_TEST_CASE', checkedTestCases);
    },

    handleSelectedTestCase(state, selectedTestCases) {
        state.commit('HANDLE_SELECTED_TEST_CASE', selectedTestCases);
    },

    actionCloneTestCase(state, reqDataObj) {
        let res = insertCloneTestCase(reqDataObj); //method from service
        return res;
    },

    actionUpdateTestcase(state, reqDataObj) {
        // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", reqDataObj);
        let res = updateTestcase(reqDataObj); //method from service
        return res;
    },

    actionUpdateTestcaseOnMouseMove(state, reqDataObj) {
        let res = updateTestcaseOnMouseMove(reqDataObj); //method from service
        return res;
    },

    ActionDeleteTestcase(state, reqDataArray) {
        let res = deleteTestcases(reqDataArray); //method from service
        return res;
    },

    actionGetTestrunResult(state, testcaseId) {
        state.commit('IS_TESTRUN_RESULT_FETCH_LOADED', false);
        return fetchTestrunResult(testcaseId)
            .then(resBody => {
                if (resBody.status === 200) {
                    state.commit('FETCH_TEST_RUN_RESULT', resBody && resBody.data && resBody.data.nativeTestRuns);
                    state.commit('IS_TESTRUN_RESULT_FETCH_LOADED', true);
                    return resBody;
                } else {
                    state.commit('IS_TESTRUN_RESULT_FETCH_LOADED', true);
                    state.commit('FETCH_TEST_RUN_RESULT', []);
                    return resBody;
                }
            })
            .catch(err => {
                state.commit('IS_TESTRUN_RESULT_FETCH_LOADED', true);
            });
    },

    actionGetCaseResultsFromRuns(state, testcaseId) {
        state.commit('IS_CASE_RESULT_FETCH_LOADED', false);
        return fetchCaseResultsFromRuns(testcaseId)
            .then(resBody => {
                if (resBody.status === 200) {
                    state.commit('IS_CASE_RESULT_FETCH_LOADED', true);
                    return resBody;
                } else {
                    state.commit('IS_CASE_RESULT_FETCH_LOADED', true);
                    return resBody;
                }
            })
            .catch(err => {
                state.commit('IS_CASE_RESULT_FETCH_LOADED', true);
                return err;
            });
    },

    //--- END Test Case

    //---Test Step
    actionSaveTestStep(state, reqDataObj) {
        let res = insertTestStep(reqDataObj);
        return res;
    },

    actionCheckGroupNameAlreadyExists(state, reqDataObj) {
        let res = checkExistingGroupName(reqDataObj);
        return res;
    },

    fetchTestStepList(state, testStepId) {
        let res = fetchTestStepList(state.testCaseList);
        state.commit('FETCH_TEST_STEP_LIST', res);
    },

    actionDeleteTestSteps(state, reqDataArray) {
        let res = deleteTestSteps(reqDataArray); //method from service
        return res;
    },

    actionInsertShareableGroup(state, reqDataObj) {
        let res = insertShareableGroup(reqDataObj); //method from service
        return res;
    },

    actionFetchShareableGroup(state, projectId) {
        return fetchShareableGroup(projectId);
    },

    //--- END Test Step

    actionInsertPropertieChanges(state, newPropertiesObj) {
        console.log('testStepId--', newPropertiesObj);
        let res = insertPropertieChanges(newPropertiesObj); //method from service
        return res;
    },

    actionUpdateTestStep(state, obj) {
        console.log('testStepId--', obj);
        let res = updateTestStep(obj.stepId, obj.step); //method from service
        return res;
    },

    // Start Test Steps Shareable

    actionAssignTestStepShareable(state, reqDataObj) {
        let res = insertTestStepShareable(reqDataObj);
        return res;
    },

    actionServerInfo(state) {
        return serverInfo().then(resBody => {
            if (resBody.status == 200) {
                return resBody;
            } else {
                return resBody;
            }
        })
    },
    actionClearTestCaseList({commit}) {
        commit('FETCH_TEST_CASE_LIST', [])
    },
    actionInsertNewGeneratedEmail(state, data) {
        let res = insertNewGeneratedEmail(data); //method from service
        return res;
    },
    actionGetGeneratedEmails(state, projectId) {
        let res = getGeneratedEmails(projectId); //method from service
        return res;
    },
    async actionDeleteGeneratedEmail(state, id) {
        let res = await deleteGeneratedEmailById(id);
        return res;
    },
};

const getters = {
    testStepList: state => state.testStepList,
    currentSavedSteps: state => state.currentSavedSteps,
    getCheckedTestCaseList: state => state.checkedTestCaseList,
    testCaseList: state => state.testCaseList,
    currentTestcase: state => state.currentTestcase,
    fetchTestCaseLoading: state => state.isFetchTestCaseLoading,
    testrunResultList: state => state.testrunResultList,
    flatTestCaseList: state => state.flatTestCaseList,
    filterDuration: state => state.filterDuration,
    isTestCaseLoaded: state => state.isTestCaseLoaded,
    noTestcaseFoundMsg: state => state.noTestcaseFoundMsg,
    isTestrunResultListLoaded: state => state.isTestrunResultListLoaded,
    isCaseResultListLoaded: state => state.isCaseResultListLoaded,
    testCaseListForDropdown: state => state.TestCaseListForDropdown,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
