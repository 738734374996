<template>
  <div class="p-grid p-fluid">
    <div class="custom-p-xl-9 p-lg-8 p-md-7 p-sm-12 testcase-create-wrapper" v-show="!isTestCaseCreated">
      <transition name="fade">
        <NewTest @onChangeRenderComponent="handleRenderComponent" :basicConfig="config"/>
      </transition>
    </div>
  </div>
</template>

<script>
import NewTest from '../components/NewTest.vue';

export default {
  components: {
    NewTest,
  },
};
</script>

<style>
  .custom-p-xl-9 {
    width: 100%; }
</style>