import axios from 'axios';
import {TestServiceBaseUrl} from '../base_urls';
import interceptors from '../interceptors';
//import service from './auth_request';
// const TestServiceRequest = axios.create({
//     baseURL: TestServiceBaseUrl,
//     timeout: 5000,
// });

const TestServiceRequest = axios.create({
    baseURL: TestServiceBaseUrl,
    timeout: 30000,
});

interceptors(TestServiceRequest);
export default TestServiceRequest;