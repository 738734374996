// Packagae Import
import { createApp, h } from 'vue';
import { reactive } from 'vue';

Object.defineProperty(String.prototype, 'capitalize', {
    value: function () {
        return this.charAt(0).toUpperCase() + this.slice(1);
    },
    enumerable: false,
});
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';
import Ripple from 'primevue/ripple';

import 'primevue/resources/themes/saga-blue/theme.css'; //theme
import 'primevue/resources/primevue.min.css'; //core css
import 'primeicons/primeicons.css'; //icons
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import VueApexCharts from 'vue3-apexcharts';
import PrimeVue from 'primevue/config';
// import { library } from '@fortawesome/fontawesome-svg-core';
// import {
//     faUserSecret,
//     faPlay,
//     faUser,
//     faMouse,
//     faCheckDouble,
//     faPlus,
//     faCloud,
//     faPause,
//     faTrash,
//     faCog,
//     faCogs,
//     faKeyboard,
//     faCrop,
//     faSignInAlt,
//     faCompressArrowsAlt,
//     faExpandArrowsAlt,
//     faHandPaper,
//     faTag,
//     faMinusSquare,
// } from '@fortawesome/free-solid-svg-icons';

// --------------------------------------------------------------
// --------------------------------------------------------------
// --------------------------------------------------------------
// Module Import
import router from './router/index';
import guests from './router/guest';
// ---------Input-----------------
import permission from './permission';
//--------------------------------
import ComponentLists from './component-lists';
import App from './App.vue';
import store from './store';
import '@/styles/index.scss'; // global css
import '../public/assets/sass/tj-global/tj-global.scss';
import io from 'socket.io-client';
import { TestServiceBaseUrl } from './utils/base_urls';
import { getToken } from './utils/cookies';
import { getBrowser, getBrowserInstance, getBrowserName } from './utils/helpers';
import FreshdeskPlugin from './plugins/FreshdeskPlugin';
import GoogleAnalyticsPlugin from './plugins/GoogleAnalyticsPlugin';

// --------------------------------------------------------------
// --------------------------------------------------------------
// --------------------------------------------------------------
// const iconCollections = [faUserSecret, faPlay, faMouse, faUser, faPlus, faCloud, faPause, faTrash, faCog, faCogs, faKeyboard, faCrop, faSignInAlt, faCompressArrowsAlt, faExpandArrowsAlt, faHandPaper, faTag, faCheckDouble, faMinusSquare];
// iconCollections.forEach((icon) => library.add(icon));

// Socket Implementation's
const URL = TestServiceBaseUrl;
const socket = io(URL, {
    auth: {
        token: getToken(),
    },
    transports: ['polling', 'websocket'],
    //  "force new connection" : true,
    //  "reconnection": true,
    //  "reconnectionDelay": 2000,                  //starts with 2 secs delay, then 4, 6, 8, until 60 where it stays forever until it reconnects
    //  "reconnectionDelayMax" : 60000,             //1 minute maximum delay between connections
    //  "reconnectionAttempts": "Infinity",         //to prevent dead clients, having the user to having to manually reconnect after a server restart.
    //  "timeout" : 10000,
});

const app = createApp({
    computed: {
        ViewComponent() {
            //Guest Router Re-Render
            var guestComponent = guests.find((g) => g.path == this.$route.path);

            if (guestComponent) {
                return guestComponent.component;
            }
            return App;
        },
    },
    render() {
        return h(this.ViewComponent);
    },
});

app.config.globalProperties.$appState = reactive({
    inputStyle: 'outlined',
});
app.config.globalProperties.$primevue = reactive({
    ripple: true,
});

// app.component('font-awesome-icon', FontAwesomeIcon)
app.config.productionTip = false;

/* eslint-disable no-new */
app.config.globalProperties.$chrome = getBrowserInstance();

// Extension config

app.config.globalProperties.$extensionId = process.env.VUE_APP_EXTENSION_ID;

app.config.globalProperties.$extensionURL = `https://chrome.google.com/webstore/detail/testjet/${process.env.VUE_APP_EXTENSION_ID}`;

app.config.globalProperties.$socket = socket;

app.use(VueApexCharts);
app.use(ToastService);
app.use(router);
app.use(store);
app.use(PrimeVue);

app.directive('tooltip', Tooltip);
app.directive('ripple', Ripple);

//Add Component to app
Object.entries(ComponentLists).forEach(([name, vmComponent]) => {
    //console.log(name)
    app.component(name, vmComponent);
});

app.mount('#app');

/*
    Freshdesk widget's plugin!!
*/
app.use(FreshdeskPlugin)
app.use(GoogleAnalyticsPlugin)