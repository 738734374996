<template>
  <div class="card">
    <div class="new-test-header-section">
      <div class="to-test-cases">
        <Button icon="fas fa-chevron-left" class="p-button-rounded p-button-outlined" @click="() => $router.back()" />
      </div>
      <div class="new-test-header">New Mobile Test Case</div>
      <Button label="ADD VARIABLE" icon="fas fa-plus-circle" class="p-button-outlined add-variable-button"
        @click="displayAddVariableModal" />
    </div>
    <div class="p-grid" v-if="projectDetails">
      <div class="p-xl-6 p-lg-8 p-md-12">
        <div class="new-test-config-container p-fluid">

          <div class="input">
            <label for="testName">Test case name <span class="required-icon"> *</span></label>
            <InputText id="testName" :class="errorMsg.testCaseName ? 'p-invalid' : ''" type="text"
              placeholder="Enter Test Name" v-model="testCaseName" />
            <small class="p-invalid">{{ errorMsg.testCaseName }}</small>
          </div>

          <div class="input">
            <label for="platform">Mobile opertaing system </label>
            <InputText id="platform" type="text" placeholder="Android" v-model="platform" disabled />
            <small class="p-invalid">{{ errorMsg.platform }}</small>
          </div>

          <div class="input">
            <label for="appName">Selected application name </label>
            <InputText id="appName" :class="errorMsg.appName ? 'p-invalid' : ''" type="text" placeholder="Empty"
              v-model="appName" disabled />
            <small class="p-invalid">{{ errorMsg.appName }}</small>
          </div>

          <div class="input">
            <label>Priority</label>
            <Dropdown v-model="selectedPriority" :options="priorities" optionLabel="name" optionValue="name"
              placeholder="Select a Priority" class="w-full md:w-14rem" />
          </div>

          <div class="input">
            <label>Category</label>
            <Dropdown v-model="selectedCategory" :options="categories" optionLabel="name" optionValue="id" filter
              placeholder="Select a Category" class="w-full md:w-14rem" />
          </div>

          <div class="input tags">
            <label for="tag">Add Tags</label>
            <br><br>
            <InputText id="tag" type="text" placeholder="tag name" v-model="newTagName" @keypress="itemEnter"
              @blur="tagsBlured" />
            <Chip :label="tag" v-for="tag in tags" :key="tag" removable @remove="onRemoveTag(tag)"
              :style="{ marginTop: '1rem' }" />
          </div>
          <div class="create-button-section">
            <div class="create-button">
              <Button label="CREATE TEST CASE" v-on:click="handleMobileTestCaseSubmit" />
            </div>
          </div>
        </div>
      </div>
      <div v-if="variables.length" class="p-xl-6 p-lg-8 p-md-12">
        <div class="variables-container">
          <h3>Variables</h3>
          <div v-for="(variable, index) in variables" :key="index" class="variable-container" style="position: relative;">
            <div class="info">
              <p class="name">{{ variable.name }}</p>
              <p class="value">{{ variable.value }}</p>
            </div>
            <i class="fas fa-ellipsis-v" @click="(event) => displayOptions(event, variable)"></i>
            <OverlayPanel :ref="variable.id" :key="variable.id" appendTo="body">
              <div class="options">
                <div class="option" @click="displayEditVariableModal(variable, index)">Update <i class="fas fa-sync"></i>
                </div>
                <!-- <div class="option" @click="copyVariableHandler(variable)">Copy</div> -->
                <!-- <div class="option" @click="shareToProjectHandler">Share to project</div> -->
                <div class="option" @click="deleteVariableHandler(index)">Delete <i class="fas fa-trash"></i></div>
              </div>
            </OverlayPanel>
          </div>
        </div>
      </div>
      <div v-else class="p-xl-6 p-lg-8 p-md-12">
        <!-- No variable added yet. Please click ‘Add Variable’ to add -->
        <div class="variables-container">
          <h3>Variables</h3>
          <div style="font-size: 16px; font-weight: bold; text-align: center;">No Data</div>
        </div>
      </div>
    </div>
    <Loader v-else/>
    <Dialog :draggable="false" class="add-variable-modal" v-model:visible="displayAddVariable" modal header="Add Variable"
      :style="{ width: '30vw' }" @hide="closeAddVariableModal">
      <div class="add-variable-container p-fluid">
        <div class="custom-p-field-setup-account">
          <label>Variable Name <span class="required-icon"> *</span></label>
          <InputText id="var_name" v-model="variableName" :class="requiredField.variableName" type="text"
            placeholder="Enter Variable Name" @keypress="nameFieldValidation" @blur="onBlurUniqueVariableNameCheck" />
          <small class="p-invalid">{{ errorMsg.varName || errorMsg.varNameLength }}</small>
        </div>
        <label> Scope <span class="required-icon"> *</span></label>
        <div class="scope-container">
          <div class="scope">
            <RadioButton v-model="scope" inputId="scope1" name="pizza" value="project" />
            <label for="scope1" class="ml-2">Project Scope Variable</label>
          </div>
          <div class="scope">
            <RadioButton v-model="scope" inputId="scope2" name="pizza" value="testCase" />
            <label for="scope2" class="ml-2">Test Case Scope Variable</label>
          </div>
        </div>
        <div class="custom-p-field-dropdown">
          <label>Type <span class="required-icon"> *</span></label>
          <Dropdown v-model="selectedType" :options="types" optionLabel="name" optionValue="name"
            :class="requiredField.selectedType" filter="true" @change="onChangeType" placeholder="Select Type" />
        </div>
        <div class="custom-p-field-setup-account">
          <label>Value <span class="required-icon"> *</span></label>
          <InputText v-model="variableValue" :class="requiredField.variableValue" type="text" placeholder="Enter Value" />
          <small class="p-invalid">{{ errorMsg.varValue }}</small>
        </div>
      </div>
      <template #footer>
        <Button label="CREATE VARIABLE" @click="clickAddVariable" autofocus />
      </template>
    </Dialog>
    <Dialog :draggable="false" class="edit-variable-modal" v-model:visible="displayEditVariable" modal
      header="Edit Variable" :style="{ width: '30vw' }" @hide="closeEditVariableModal">
      <div class="edit-variable-container p-fluid">
        <div class="custom-p-field-setup-account">
          <label>Variable Name <span class="required-icon"> *</span></label>
          <InputText disabled v-model="updatedvariableName" :class="requiredField.updatedvariableName" type="text"
            placeholder="Enter Variable Name" />
        </div>
        <label> Scope <span class="required-icon"> *</span></label>
        <div class="scope-container">
          <div class="scope">
            <RadioButton v-model="updatedScope" inputId="scope1" name="pizza" value="project" />
            <label for="scope1" class="ml-2">Project Scope Variable</label>
          </div>
          <div class="scope">
            <RadioButton v-model="updatedScope" inputId="scope2" name="pizza" value="testCase" />
            <label for="scope2" class="ml-2">Test Case Scope Variable</label>
          </div>
        </div>
        <div class="custom-p-field-dropdown">
          <label>Type <span class="required-icon"> *</span></label>
          <Dropdown v-model="selectedType" :options="types" optionLabel="name" optionValue="name"
            :class="requiredField.selectedType" filter="true" @change="onChangeType" placeholder="Select Type" />
        </div>
        <div class="custom-p-field-setup-account">
          <label>Value <span class="required-icon"> *</span></label>
          <InputText v-model="updatedvariableValue" :class="requiredField.updatedvariableValue" type="text"
            placeholder="Enter Value" />
        </div>
      </div>
      <template #footer>
        <Button label="UPDATE VARIABLE" @click="clickUpdateVariable" autofocus />
      </template>
    </Dialog>
    <Dialog :draggable="false" header="Header" v-model:visible="displayDeleteConfirmationModal" closable :modal="true"
      class="delete-confirmation-modal" @hide="closeDeleteConfirmationModal">
      <template #header>
        <img src="/assets/warning-icon.svg" alt="warning-icon.svg" />
        <p>Delete Confirmation</p>
      </template>

      Are you sure you want to delete this variable?

      <template #footer>
        <Button label="No" class="p-button-outlined" @click="clickCancelDeteleVariable" />
        <Button label="Yes" class="p-button-primary" @click="clickDeleteVariable" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { getSelectedCurrentProjectId, setSelectedCaseId } from '../../utils/localstorage';
import Loader from '../commonComponents/Loader.vue';
import { ReservedKeywords } from '@/utils/ReservedKeywords';

export default {
  name: 'MobileTestCaseCreatePage',
  components: {
    Loader
  },
  data() {
    return {
      noSpecialCharsRegex: /^[A-Za-z0-9 ]+$/,
      errorMsg: {},
      newTagName: '',
      testCaseName: '',
      platform: '',
      // appName: '',
      tags: [],
      selectedPriority: null,
      priorities: [
        {
          name: 'Critical',
          code: 1,
        },
        {
          name: 'High',
          code: 2,
        },
        {
          name: 'Medium',
          code: 3,
        },
        {
          name: 'Low',
          code: 4,
        },
      ],
      requiredField: {
        testCaseName: '',
        noSpecialCharsRegex: '',
        appName: '',
        variableName: '',
        variableValue: '',
        updatedvariableName: '',
        updatedvariableValue: '',
      },
      types: [
        {
          id: 1,
          name: 'TEXT',
        }
      ],
      selectedType: 'TEXT',
      variableName: '',
      variableValue: '',
      scope: 'testCase',
      categories: [],
      selectedCategory: null,
      variables: [],
      projectDetails: null,
      selectedVariableIndexForDelete: null,
      displayDeleteConfirmationModal: false,
      displayAddVariable: false,
      displayEditVariable: false,
      updateVariableIndex: null,
      updatedvariableName: '',
      updatedvariableValue: '',
      updatedScope: '',
    }
  },
  created() {
    if (this.selectedProjectId) {
      this.projectDetails = this.projectList?.find(project => project._id == this.selectedProjectId);
      // this.projectDetails && this.loadInitialData(this.projectDetails);
      // this.getTagsByProjectId(this.selectedProjectId);
      this.platform = this.projectDetails?.type || '';
      this.getCategories(this.selectedProjectId).then(res => {
        if (res?.status === 200 && res?.data?.length) {
          this.categories = res.data;
        } else {
          this.categories = [];
        }
      })
    }
  },
  methods: {
    ...mapActions({
      actionInsertNewMobileTestCase: 'mobileTestCases/actionInsertNewMobileTestCase',
      getTagsByProjectId: 'project/actionFetchTagsByProjectId',
      getCategories: 'category/actionGetCategories',
      checkUniqueVariableName: 'variable/actionUniqueVariableNameCheck',
    }),
    deleteVariableHandler(index) {
      // this.variables.splice(index, 1);
      // this.showSuccess("Successful", "The variable has been deleted successfully.");

      this.selectedVariableIndexForDelete = index;
      this.displayDeleteConfirmationModal = true;
    },
    clickDeleteVariable() {
      this.variables.splice(this.selectedVariableIndexForDelete, 1);
      this.selectedVariableIndexForDelete = null;
      this.displayDeleteConfirmationModal = false;
      this.showSuccess("Successful", "The variable has been deleted successfully.");
    },

    clickCancelDeteleVariable() {
      this.selectedVariableIndexForDelete = null;
      this.displayDeleteConfirmationModal = false;
    },

    closeDeleteConfirmationModal() {
      this.selectedVariableIndexForDelete = null;
      this.displayDeleteConfirmationModal = false;
    },
    displayAddVariableModal() {
      this.displayAddVariable = true;
    },
    resetAddVariableValues() {
      this.variableName = '';
      this.variableValue = '';
      this.scope = 'testCase';
      this.errorMsg.varName = '';
      this.errorMsg.varNameLength = '';
      this.errorMsg.varValue = '';
      this.requiredField.variableName = '';
      this.requiredField.variableValue = '';
    },
    resetEditVariableValues() {
      this.updateVariableIndex = null;
      this.updatedvariableName = '';
      this.updatedvariableValue = '';
      this.updatedScope = '';
      this.errorMsg.varValue = '';
      this.requiredField.updatedvariableName = '';
      this.requiredField.updatedvariableValue = '';
    },
    isAddVariableRequiredFieldEmpty() {
      let requiredFieldEmpty = false;

      if (this.variableName === '') {
        requiredFieldEmpty = true;
        this.requiredField.variableName = 'p-invalid';
        this.errorMsg.varName = 'Please provide variable name';
      };

      if (this.variableValue === '') {
        requiredFieldEmpty = true;
        this.requiredField.variableValue = 'p-invalid';
        this.errorMsg.varValue = 'Please provide value';
      };

      return requiredFieldEmpty;
    },
    isEditVariableRequiredFieldEmpty() {
      let requiredFieldEmpty = false;

      if (this.updatedvariableName === '') {
        requiredFieldEmpty = true;
        this.requiredField.updatedvariableName = 'p-invalid';
      };

      if (this.updatedvariableValue === '') {
        requiredFieldEmpty = true;
        this.requiredField.updatedvariableValue = 'p-invalid';
      };

      return requiredFieldEmpty;
    },
    closeAddVariableModal() {
      this.resetAddVariableValues();
    },
    closeEditVariableModal() {
      this.resetEditVariableValues();
    },
    displayOptions(event, variable) {
      this.$refs[variable.id][0].toggle(event);
    },
    displayEditVariableModal(variable, index) {
      this.updateVariableIndex = index;
      this.updatedvariableName = variable.name;
      this.updatedvariableValue = variable.value;
      this.updatedScope = variable.scope;
      this.displayEditVariable = true;
    },
    nameFieldValidation(evt) {
      let key = evt.key;
      let keyCode = evt.keyCode || evt.which;

      if (this.variableName.length == 0 && (keyCode >= 48 && keyCode <= 57)) {
        evt.preventDefault();
        this.errorMsg.varNameLength = '';
        this.errorMsg.varName = `'${key}' cannot be used as first character in the variable name`;
      }
      else {
        let regExp = /[\$_a-zA-Z0-9]/g;
        if (!regExp.test(key)) {
          evt.preventDefault();
          // this.showWarningMsg(`'${key}' can not be used in variable name`)
          this.errorMsg.varNameLength = '';
          this.errorMsg.varName = `'${key}' cannot be used in the variable name`;
        }
        else {
          this.errorMsg.varNameLength = '';
          this.errorMsg.varName = '';
        }
      }

      if (evt.target.value.length > 255) {
        evt.preventDefault();
        this.errorMsg.varNameLength = '';
        this.errorMsg.varName = 'Reached maximum character length of 255 for variable name';
      }
    },
    onBlurUniqueVariableNameCheck() {
      if (this.variableName.length) {
        this.checkUniqueVariableName({ name: this.variableName, projectId: this.selectedProjectId }).then(res => {
          if (res.isUnique) {
            if (this.variables.filter(variable => variable.name == this.variableName).length > 0) {
              this.errorMsg.varName = `Variable with name ( ${this.variableName} ) already exists. Please enter a unique name.`;
            }
          }
          else {
            this.errorMsg.varName = `Variable with name ( ${this.variableName} ) already exists. Please enter a unique name.`;
          }
        })
      }
    },
    clickAddVariable() {
      if (!this.isAddVariableRequiredFieldEmpty() && this.errorMsg.varName == '' && this.errorMsg.varNameLength == '') {
        let variable = {
          id: (this.variables.length + 1).toString(),
          name: this.variableName,
          type: this.selectedType,
          value: this.variableValue,
          scope: this.scope,
        };

        this.variables.push(variable);
        this.displayAddVariable = false;
        this.resetAddVariableValues();
        this.showInfo('Info', 'Variable is created successfully but will not be published until the test case is created.')

      }
    },

    clickUpdateVariable() {
      if (!this.isEditVariableRequiredFieldEmpty()) {
        let updatedVariable = {
          id: (this.updateVariableIndex + 1).toString(),
          name: this.updatedvariableName,
          type: this.selectedType,
          value: this.updatedvariableValue,
          scope: this.updatedScope,
        };

        this.variables[this.updateVariableIndex] = updatedVariable;
        this.showSuccess("Successful", "Variable updated successfully");
        this.resetEditVariableValues();
        this.displayEditVariable = false;
      }
    },
    tagsBlured(event) {
      console.log(event.target.value);
      if (event.target.value?.length) {
        this.tags.push(event.target.value);
        this.newTagName = ''
      }
    },
    itemEnter(e) {
      if (e.which == 13 && !this.tags.includes(this.newTagName) && this.newTagName.length) {
        this.tags = [...this.tags, this.newTagName]
        this.newTagName = ''
        return
      }
    },
    showSuccess(summary, detail) {
      this.$toast.add({ severity: 'success', summary: summary, detail: detail, life: 5000 });
    },

    showError(detail) {
      this.$toast.add({ severity: 'error', summary: 'Error Message', detail: detail, life: 3000 });
    },

    showWarningMsg(msgDetail) {
      this.$toast.add({ severity: 'warn', summary: 'Warn Message', detail: msgDetail, life: 5000 });
    },

    showInfo(summary, detail) {
      this.$toast.add({ severity: 'info', summary: summary, detail: detail, life: 3000 });
    },

    onRemoveTag(label) {
      this.tags = this.tags.filter((tag) => tag !== label);
    },


    async handleMobileTestCaseSubmit() {
      if (!this.isRequiredFieldEmpty()) {
        if (getSelectedCurrentProjectId()) {

          const variables = this.variables?.length
          ?
          this.variables.map(variable => {
            delete variable.id;
            return variable;
          })
          :
          []

          await new Promise((r) => setTimeout(r, 200));

          let newTestCaseObj = {
            name: this.testCaseName,
            description: 'hello!',
            priority: this.selectedPriority,
            // divice: this.divice,
            // platform: this.platform,
            // apk: this.appName,
            variables,
            tags: this.tags,
            projectID: getSelectedCurrentProjectId(),
            category_id: this.selectedCategory,
            // projectID: '2N9LW27lx8i9-rxSBmWdZ',
            // created_by: userLoginInfo._id,
            organizationId: this.loggedInUserInfo.organizationId,
          };
          this.actionInsertNewMobileTestCase(newTestCaseObj)
            .then((resBody) => {
              this.showSuccess('Test Case create successfully.');
              setTimeout(() => this.$router.push({ path: `/test-editor-mobile/${resBody.data.testCase._id}` }), 1000);
            })
            .catch((err) => {
              this.showError('Test Case create Failed.');
              console.log('create-project-res-err', err);
            });
        } else {
          this.showWarningMsg('Please select project first.');
        }
      }
    },
    isRequiredFieldEmpty() {

      let requiredFieldEmpty = false;

      if (!this.testCaseName) {
        this.errorMsg['testCaseName'] = "Test case name is Required !"
        requiredFieldEmpty = true;
        // this.requiredField.testCaseName = 'p-invalid';
      } else if (!this.noSpecialCharsRegex.test(this.testCaseName)) {
        this.errorMsg['testCaseName'] = "Special characters are not allowed"
        requiredFieldEmpty = true;
        // this.requiredField.noSpecialCharsRegex = 'p-invalid';
      }
      if (!this.appName && this.platform == 'android') {
        this.errorMsg['appName'] = "Please Upload an APK From Project Settings (Required)!"
        requiredFieldEmpty = true;
        // this.requiredField.appName = 'p-invalid';
      }

      return requiredFieldEmpty;
    }
  },
  computed: {
    ...mapGetters({
      getTestCaseList: 'testCase/testCaseList',
      projectList: 'project/projectList',
      selectedProjectId: 'project/projectID',
      tagList: 'project/tagList',
      loggedInUserInfo: 'auth/loggedInUserInfo',
    }),
    appName() {
      if (this.selectedProjectId) {
        const selectedProject = this.projectList?.find(project => project._id == this.selectedProjectId);
        if (selectedProject?.appInfo?.fileName) {
          return this.appName = selectedProject.appInfo.fileName
        } else if (selectedProject?.appInfo?.bundleId) {
          return this.appName = selectedProject?.appInfo?.bundleId
        }
      }
    }
  },
  watch: {
    projectList(val) {
      if (!this.selectedProjectId || this.projectDetails) {
        return;
      }
      this.projectDetails = val?.find(project => project._id == this.selectedProjectId);
      // this.projectDetails && this.loadInitialData(this.projectDetails);
      // this.getTagsByProjectId(this.selectedProjectId);
      this.platform = this.projectDetails.type;
    },
    testCaseName(val) {
      delete this.errorMsg['testCaseName']
    },
    platform(val) {
      delete this.errorMsg['platform']
    },
    appName(val) {
      delete this.errorMsg['appName']
    },

    variableName(value) {
      if (value.length) {
        this.requiredField.variableName = '';

        let firstCharRegex = /[0-9]/g;

        if (value.length > 255) {
          this.errorMsg.varNameLength = 'Reached maximum character length of 255 for variable name';
        }
        else if (firstCharRegex.test(value[0])) {
          this.errorMsg.varNameLength = '';
          this.errorMsg.varName = `'${value[0]}' cannot be used as first character in the variable name`;
          this.variableName = '';
          document.getElementById('var_name').value = '';
        }
        else {
          let regex = /[\$_a-zA-Z0-9]/g;

          if (value.match(regex).length !== value.length) {
            this.errorMsg.varNameLength = '';
            this.errorMsg.varName = `'${value}' cannot be used as variable name`
            // this.variableName = '';
            // document.getElementById('var_name').value = '';
          }
          else if (ReservedKeywords.includes(value)) {
            this.errorMsg.varNameLength = '';
            this.errorMsg.varName = `'${value}' is a reserved JavaScript keyword. Please enter a different name.`;
          }
          else {
            this.errorMsg.varNameLength = '';
            this.errorMsg.varName = '';
          }
        }
      }
      else {
        this.errorMsg.varNameLength = '';
        this.errorMsg.varName = '';
      }
    },
    variableValue(value) {
      if (value.length) {
        this.requiredField.variableValue = '';
        this.errorMsg.varValue = '';
      }
    },
    updatedvariableValue(value) {
      if (value.length) {
        this.requiredField.updatedvariableValue = '';
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.card {
  padding: 0;
  min-height: 847px;

  .new-test-header-section {
    padding: 1.75rem 2rem;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #dee2e6;

    .to-test-cases {
      width: 40px;
      height: 40px;

      .p-button-rounded {
        width: 40px;
        height: 40px;
        border: 1px solid #dee2e6;
        color: #00a0ee;
      }
    }

    .new-test-header {
      padding-left: 2rem;
      font-size: 24px;
    }

    .add-variable-button {
      margin-left: auto;
    }
  }

  .new-test-config-container {
    padding: 2rem;

    .input {
      margin-bottom: 2rem;

      label {
        padding-left: 0.5rem;
      }

      input {
        margin-top: 0.5rem;
      }

      textarea {
        margin-top: 0.5rem;
      }

      .p-dropdown {
        margin-top: 0.5rem;
      }

      .base-url-method {
        // display: flex;
        // input {
        //     border-top-left-radius: 0;
        //     border-bottom-left-radius: 0;
        // }
        // .p-dropdown {
        //     border-top-right-radius: 0;
        //     border-bottom-right-radius: 0;
        // }
      }
    }

    .resolution-input {
      position: relative;

      .custom-input-field {
        color: #282828;
        opacity: 1;
        cursor: pointer;
      }

      .edit-button {
        width: 2.357rem;
        height: 37px;
        top: 8px;
      }

      .resolution-update-modal-wrapper {
        width: 100%;
        // height: 18rem;
        height: auto;
        background-color: #ffffff;
        border: 1px solid #dee2e6;
        box-shadow: 0px 3px 6px #00000029;
        position: absolute;
        top: 44px;
        left: 0px;
        z-index: 1;

        .modal-header {
          height: 48px;
          border: 1px solid #dee2e6;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-left: 24px;
          padding-right: 24px;
          cursor: default;

          .header-label {
            font-size: 16px;
            color: #282828;
            opacity: 1;
            // padding: 16px 0px 0px 24px;
          }

          .modal-close-icon {
            i {
              font-size: 16px;
              color: #6c757d;
              cursor: pointer;
            }
          }
        }

        .input-section {
          display: flex;
          justify-content: space-around;
          align-items: center;
          padding: 16px 24px;
          border-bottom: 1px solid #dee2e6;

          .input-field {
            .p-inputtext {
              width: 100%;
              font-size: 14px;
            }
          }

          .multiplication-icon {
            width: 36px;
            color: #a1b3c5;
            font-size: 12px;
            text-align: center;
          }

          .p-button.p-component.p-button-primary {
            height: 2rem;
            margin-left: 20px;
            color: #ffffff;
            margin-top: 6px;
          }
        }

        .resolution-list {
          ul {
            margin: 0;
            padding: 0;
            list-style: none;
          }

          li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 40px;
            padding: 10px 24px;
            cursor: pointer;

            label {
              // padding-right: 18vw;
            }

            i {
              color: #00a0ee;
              font-size: 16px;
              cursor: pointer;
            }

            &:hover {
              background-color: #f5fafc;
            }
          }
        }

        .icon {
          font-size: 16px;
        }

        .content {
          font-size: 12px;
          padding: 0 1.188rem 0 0.5rem;
        }
      }
    }

    .tags {

      // margin-top: 120px;
      label {
        padding-left: 0.5rem;
      }

      .p-chips {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        background: #dee2e6;
        border-radius: 16px;
        color: #212529;

        .p-chips-token {
          background: #dee2e6;
          border-radius: 16px;
          color: #212529;
        }

        .p-inputtext {
          height: 69px;
          overflow-y: scroll;
        }
      }
    }

    .create-button-section {
      margin-top: 120px;

      .create-button {
        button {
          height: 40px;
        }
      }
    }
  }

  .variables-container {
        padding: 2rem !important;
        height: 700px;
        overflow: auto;

        h3 {
          margin-bottom: 2rem;
          font-size: 24px;
          font-weight: 600;
        }

        .variable-container {
          padding: 0.75rem 1.5rem;
          border-radius: 4px;
          border: 1px solid #C3C9CF;
          box-shadow: 0px 8px 24px 0px rgba(62, 135, 174, 0.11);
          margin-bottom: 0.75rem;
          display: flex;
          justify-content: space-between;
          align-items: center;

          &:last-child {
            margin-bottom: 0;
          }

          .info {
            .name {
              font-size: 14px;
              font-weight: 600;
              margin-bottom: 0;
            }

            .value {
              font-size: 12px;
              font-weight: 400;
            }
          }

          i {
            color: #808080;
            font-size: 1.5rem;

            &:hover {
              cursor: pointer;
            }
          }
        }
      }

      .no-variables-container {
        padding: 2rem 17rem;
        height: 475px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #CBCBCB;
        font-size: 15px;
        font-weight: 400;
      }

      .add-variable-button {
          display: flex;
          align-items: center;
          width: 12%;
          padding: 0.625rem 1rem;
          margin-left: auto;
          border: 2px solid #00A0EE;
          border-radius: 4px;
          color: #00A0EE;
          font-size: 0.938rem;
          font-weight: 700;

          &:hover {
            border: 2px solid #00A0EE;
          }
        }
}
</style>
