import {
    fetchSingleMobileProjectOverviewInfo,
    fetchMobileDashboardInfo,
    fetchSingleMobileProjectRunsHistory,
    fetchSingleMobileProjectFailedCases,
    fetchSingleMobileProjectPassingRate,
    fetchSingleMobileProjectHeader,
    previewReport,
    insertReport,
    fetchTestReportByID,
    deleteTestReport,
    fetchTestReportList
} from '../../api/MobileTestReportService';

const state = {
    singleMobileProjectInfo: null,
    mobileDashboardInfo: null,
    testReportList: [],
    isTestReportListLoaded: false,
    mobileProjectOverviewHistory: [],
    mobileProjectOverviewFailedCases: [],
    mobileProjectOverviewPassingRate: null,
    mobileProjectOverviewHeader: null,
};
const mutations = {
    SAVE_SINGLE_MOBILE_PROJECT_INFO(state, singleMobileProjectInfo) {
        state.singleMobileProjectInfo = singleMobileProjectInfo;
    },
    MOBILE_DASHBOARD_INFO(state, mobileDashboardInfo) {
        state.mobileDashboardInfo = mobileDashboardInfo;
    },
    MOBILE_PROJECTOVERVIEW_HISTORY(state, data) {
        state.mobileProjectOverviewHistory = data;
    },
    MOBILE_PROJECTOVERVIEW_FAILED_CASES(state, data) {
        state.mobileProjectOverviewFailedCases = data;
    },
    MOBILE_PROJECTOVERVIEW_PASSINGRATE(state, data) {
        state.mobileProjectOverviewPassingRate = data;
    },
    MOBILE_PROJECTOVERVIEW_HEADER(state, data) {
        state.mobileProjectOverviewHeader = data;
    },
    SAVE_ALL_TEST_REPORT_LIST(state, res) {
        state.testReportList = res;
    },
    IS_TEST_REPORT_LIST_LOADED(state, status) {
        state.isTestReportListLoaded = status;
    },
}

const actions = {
    actionGetSingleMobileProjectOverviewInfo({commit}, projectID) {
        return fetchSingleMobileProjectOverviewInfo(projectID).then(resBody => {
            if (resBody?.data){
                commit('SAVE_SINGLE_MOBILE_PROJECT_INFO', resBody.data);
            }
        }).catch(err => {
            console.log(err);
        })
    },

    // actionGetMobileDashboardInfo({commit}) {
    //     commit('MOBILE_DASHBOARD_INFO', null);
    //     return fetchMobileDashboardInfo().then(resBody => {
    //       console.log("🍅 🍅 🍅 🍅 🍅 🍅 🍅 🍅 🍅 🍅 🍅 🍅 🍅 🍅 🍅 🍅 🍅 🍅 🍅 ", resBody.data);
    //         if (resBody?.data){
    //             commit('MOBILE_DASHBOARD_INFO', resBody.data);
    //         }
    //     }).catch(err => {
    //         console.log(err);
    //     })
    // },

    async actionSingleMobileProjectHeader({commit}, {projectID}) {
      let response =[null, null];
      // commit('MOBILE_PROJECTOVERVIEW_HEADER', []);

      try {
        const res = await fetchSingleMobileProjectHeader(projectID)
        response = [res.data, null];
        // console.log("🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 HEADER ", res.data);
        commit('MOBILE_PROJECTOVERVIEW_HEADER', res.data.failedTestCases);
      } catch (err) {
        // console.log("🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 HEADER ", err);
        response = [null, err.response];

      } finally {
        return response;
      }
  },

    async actionSingleMobileProjectPassingRate({commit}, {projectID, days}) {
        let response =[null, null];
        commit('MOBILE_PROJECTOVERVIEW_PASSINGRATE', null);

        try {
          const res = await fetchSingleMobileProjectPassingRate(projectID, days)

          const resBody = res.data.passingRate;

          console.log(resBody);

          let passignRateInfo = {
            totalSteps: resBody.totalSteps,
            totalPassed: resBody.totalPassedSteps,
            totalFailed: resBody.totalFailedSteps,
            passPercentage: resBody.passPercentage,
            passignRateGraph: [{
                name: 'passing rate',
                data: [],
            }],
        };

        for (let i = 0; i < resBody.dayWiseReport.length; i++) {
            // console.log(`\n ${i}`, resBody.dayWiseReport[i]);
            passignRateInfo.passignRateGraph[0].data.push(resBody.dayWiseReport[i].passPercentage);
        };

          response = [{main: res.data, modified: passignRateInfo}, null];
          // console.log("🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 PASSING RATE ", {main: res.data, modified: passignRateInfo});
          commit('MOBILE_PROJECTOVERVIEW_PASSINGRATE', passignRateInfo);
        } catch (err) {
          // console.log("🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 PASSING RATE ", err);
          response = [null, err.response];

        } finally {
          return response;
        }
    },


    async actionSingleMobileProjectFailedCases({commit}, {projectID, days}) {
        let response =[null, null];
        // commit('MOBILE_PROJECTOVERVIEW_FAILED_CASES', []);

        try {
          const res = await fetchSingleMobileProjectFailedCases(projectID, days)
          response = [res.data, null];
          // console.log("🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 FAILED CASES ", res.data);
          commit('MOBILE_PROJECTOVERVIEW_FAILED_CASES', res.data.failedTestCases);
        } catch (err) {
          // console.log("🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 FAILED CASES ", err);
          response = [null, err.response];

        } finally {
          return response;
        }
    },

    async actionSingleMobileProjectRunsHistory({commit}, {projectID, days}) {
        let response =[null, null];
        commit('MOBILE_PROJECTOVERVIEW_HISTORY', []);

        try {
          const res = await fetchSingleMobileProjectRunsHistory(projectID, days)
          response = [res.data, null];
          // console.log("🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 RUNS HISTORY ", res.data);
          commit('MOBILE_PROJECTOVERVIEW_HISTORY', res.data.executedRuns);
        } catch (err) {
          // console.log("🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 RUNS HISTORY ", err);
          response = [null, err.response];

        } finally {
          return response;
        }
    },

    actionInsertReport(state, payload) {
        return insertReport(payload)
    },

    async actionPreviewReport(state, reportData) {
        return previewReport(reportData);
    },

    async actionGetTestReportById(state, reportID) {
        return await fetchTestReportByID(reportID)
    },

    async actionDeleteTestReport({ state, commit }, reqData) {
        return await deleteTestReport(reqData).then(resBody => {
            return resBody
        })
            .catch(err => {

            })
    },

    actionGetAllTestReportList(state, filterObj) {
        state.commit('IS_TEST_REPORT_LIST_LOADED', false);
        console.log("report mamu filter====>", filterObj)

        return fetchTestReportList(filterObj)
            .then(resBody => {
                state.commit('IS_TEST_REPORT_LIST_LOADED', true);
                if (resBody.status.code === 200) {
                    state.commit('SAVE_ALL_TEST_REPORT_LIST', resBody && resBody.data && resBody.data.reports);
                    return resBody;
                } else {
                    state.commit('SAVE_ALL_TEST_REPORT_LIST', []);
                    return resBody.response;
                }
            })
            .catch(error => {
                state.commit('IS_TEST_REPORT_LIST_LOADED', true);
                state.commit('SAVE_ALL_TEST_REPORT_LIST', []);
                console.log(error);
            });
    },
}

const getters = {
    singleMobileProjectInfo: state => state.singleMobileProjectInfo,
    mobileDashboardInfo: state => state.mobileDashboardInfo,
    mobileProjectOverviewHistory: state => state.mobileProjectOverviewHistory,
    mobileFailedCases: state => state.mobileProjectOverviewFailedCases,
    projectOverviewPassingRate: state => state.mobileProjectOverviewPassingRate,
    projectOverviewHeader: state => state.mobileProjectOverviewHeader,
    testReportList: state => state.testReportList,
    isTestReportListLoaded: state => state.isTestReportListLoaded,
    testReportList: state => state.testReportList,
    isTestReportListLoaded: state => state.isTestReportListLoaded,
}

export default {
    namespaced: true,
    actions,
    state,
    mutations,
    getters,
};
