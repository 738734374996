<template>
  <div class="login-container">
    <div class="check-email-header">SIGN IN</div>
    <div class="check-email-icon"><img src="/assets/layout/images/Check-Email.svg" alt=""></div>
    <div class="check-email-container p-fluid">
      <div class="check-email-container-header">Check Email</div>
      <div class="check-email-container-detail">
        An email has been sent to <b>{{ expectedEmail }}</b> with an OTP and password reset link
      </div>
      <!-- <div class="check-email-link">
          <router-link to="#"><u>Click Here</u></router-link> to check email
      </div> -->
      <router-link to="/login">
        <Button class="back-to-sign-in-link" label="BACK TO SIGN IN"></Button>
      </router-link>
      <div class="check-email-sign-up-link">
        Don't have an account?
        <router-link to="/signup"><u> Sign Up</u></router-link>
      </div>
    </div>
    <!-- <div class="divider"></div>
    <div class="icon-option-header">Sign Up Using</div>
    <div class="icons">
        <div class="icon"><i class="fab fa-google icon-size-color"></i></div>
        <div class="icon"><i class="fab fa-github icon-size-color"></i></div>
    </div> -->
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'CheckEmail',
  computed: {
    ...mapGetters({expectedEmail: 'auth/forgetPassEmail'}),
  },
};
</script>

<style>
</style>