export default {
    data() {
        return {
            //comparison
            isComparisonShow: false,
            currentComparisonCase: {},
            isComparisonDataLoaded: false,
        };
    },
    created() {},
    methods: {
        onShowComparison(testSteps) {
            this.isComparisonShow = true;
            this.currentComparisonCase = testSteps;
            setTimeout(() => {
              this.isComparisonDataLoaded = true;
            }, 300)
        },
        oHideComparison() {
            this.isComparisonShow = false;
            this.currentComparisonCase = {};
            this.isComparisonDataLoaded = false
        },
    },
};
