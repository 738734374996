<template>
  <div class="email-assertion">
    <div class="card">
      <div v-for="(assertion, i) in assertions" :key="i" class="assertion">
        <div class="assertion-result" v-if="response && response.length">
          <span v-if="response && response[i]?.status === 'success'" class="status passed">Passed</span>
          <span v-else-if="response && response[i]?.status === 'failed'" class="status failed">Failed</span>
        </div>
        <div class="active-type">
          <div class="active">
            <Checkbox v-model="assertion.enabled" :disabled="!assertion['type']" :binary="true" />
          </div>
          <Dropdown v-model="assertion.type" :options="assertionTypes" optionLabel="name" optionValue="code"
          placeholder="NONE" class="type" @change="(e) => assertionTypeChange(e,assertion,i)"/>
        </div>
        <div class="arithmeticOption" v-if="assertion.type">
          <Dropdown v-model="assertion.arithmeticRule"
            :options="assertion.arithmeticOption" optionLabel="name" optionValue="code"
            placeholder="NONE" />
        </div>
        <div class="value">
          <div v-if="assertion.type" style="margin-left: 7%;">
            <InputText v-model="assertion.value" placeholder="Value" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "EmailAssertion",
  data: () => ({
    status: null,
    assertions: [],
    assertionTypes: [
      { name: 'From', code: 'from' },
      { name: 'Subject', code: 'subject' },
      { name: 'Body (Text)', code: 'body_text' },
    ],
    arithmaticOptions: [
      { name: 'Equals', code: 'equals' },
      { name: 'Does not equal', code: 'doesNotEqual' },
      { name: 'Contains', code: 'contains' },
    ],
  }),
  mounted() {
    if (this.allAssertions?.length) {
      const emptyelExists =  this.allAssertions.findIndex(el => el.type === null);

      if (emptyelExists === -1) {
        this.assertions = [...this.allAssertions, { type: null }]
      } else {
        this.assertions = [...this.allAssertions]
      }

    }  else {
      this.assertions.push({
        type: null
      })
    }
  },
  props: {
    allAssertions: {
      type: Array,
      default: []
    },
    assertionResult : {},
    response: {
      type: Object || null,
      default: null
    },
  },
  watch: {
    assertions: {
      handler(value) {
        this.$emit('assertionUpdated', value)
      },
      deep: true
    },
    // allAssertions: {
    //   handler(value){
    //     console.log("allAssertions WATCH \n", value);
    //   },
    //   deep: true
    // }
  },
  methods: {
    assertionTypeChange(event,assertion,index) {
      assertion.type = event.value;
      assertion.arithmeticOption = this.arithmaticOptions;
      assertion.arithmeticRule = this.arithmaticOptions[0].code;
      assertion.value = '';

      assertion.enabled = true;

      if (this.assertions?.length - 1 === index) {
        this.assertions.push({
          type: null
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.email-assertion {
        border-radius: 4px;
        border: 1px solid #DEE2E6;
        background: #FFF;
        padding: 1rem;
        height: 100%;
        overflow: scroll;
        position: relative;

        input {
            width: 100%;
        }
        
        .p-dropdown {
            width: 100%;
        }
        
        .card {
          min-height: 21rem;
          // max-height: 30rem;
          overflow: scroll;
          .assertion {
            display: flex;
            justify-content: flex-start;
            column-gap: 4%;
            flex-wrap: wrap;
            row-gap: 16px;
            padding-bottom: 16px;

            .active-type {
              flex-basis: 48%;
              display: flex;

              .active {
                flex-basis: 15%;
                display: flex;
                align-items: center;
                justify-content: center;
              }
              .type {
                flex-basis: 85%
              }
            }
            .arithmeticOption {
              flex-basis: 48%;
            }
            .value {
              flex-basis: 100%;
            }
            .assertion-result {
              flex-basis: 100%;
            }
            .status {
            grid-column: 1 / -1;
            color: white;
            padding: 8px 10px;
            border-radius: 2px;
            display: grid;
            width: 7em;
            place-items: center;
            font-size: 14px;
            font-weight: 700;
            margin-bottom: 10px;
            // position: absolute;
            // top: 0;

            &.passed {
              background: #32CE99;
            }

            &.failed {
              background: #D64564;
            }
          }
          }

        }

      }
</style>
