<template>
  <div style="margin: 16px 16px 47px 16px">
    <!-- v-show="restrictedRoles.includes(role) ? false : showProjectList" -->
    <Dropdown v-model="selectedProjectID"
              :options="projectList" optionLabel="title" optionValue="_id" @change="handleView"
              placeholder="Select project" style="width: 100%" :filter="true" filterIcon="pi pi-search">
      <template #value="slotProps">
        <div v-if="slotProps.value" >
          <span
              style="font-size: 16px;"
              :class="['p-mr-2', getProject(slotProps.value)?.platform === 'web' ? 'fas fa-globe' : getProject(slotProps.value)?.type == 'ios' ? 'fab fa-apple' : 'pi pi-android']"/>
          <span>{{ truncate(projectList?.find((itm) => itm._id === slotProps.value)?.title ,17)}}</span>
        </div>
        <span v-else>
            {{ slotProps.placeholder }}
        </span>
      </template>
      <template #option="slotProps">
        <div>
          <span style="font-size: 16px; width: 18px; text-align: center;" :class="['p-mr-2', slotProps.option.platform === 'web' ? 'fas fa-globe' : slotProps.option.type == 'ios' ? 'fab fa-apple' : 'pi pi-android']"/>
          <span>{{ truncate(slotProps.option.title, 17)}}</span>
        </div>
      </template>
    </Dropdown>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import {
  getSelectedCurrentProjectId,
  setSelectedCurrentProjectId,
  setSelectedCurrentProjectType
} from '@/utils/localstorage';

export default {
  name: 'AppInlineDropDownTJ',
  data() {
    return {
      showProjectList: true,
      role: '',
      restrictedRoles: ['tj_super_admin', 'tj_admin', 'tj_support'],
    };
  },

  created() {
    const projectId = getSelectedCurrentProjectId();
    if (projectId) {
      this.selectedProjectID = projectId;
      this.onChangeProjectHandler(projectId);
    }
  },
  computed: {
    ...mapGetters({
      projectList: 'project/projectList',
      projectID: 'project/projectID',
      reportFilterDuration: 'testReport/filterDuration',
      testRunFilterDuration: 'testRun/filterDuration',
      testCaseFilterDuration: 'testCase/filterDuration',
      loggedInUserInfo: 'auth/loggedInUserInfo',
    }),
    ...mapState({
      selectedProjectID: state => state.project.projectID,
    }),
    // eslint-disable-next-line vue/no-dupe-keys
    role() {
      return this.loggedInUserInfo && this.loggedInUserInfo.roleDetails && this.loggedInUserInfo.roleDetails.role;
    },
  },
  // emits: ['profile-click'],
  // props: {
  // 	expanded: {
  // 		type: Boolean,
  // 		default: null
  // 	}
  // },
  methods: {
    ...mapActions({
      fetchTestCases: 'testCase/actionFetchCaseList',
      fetchTestRunList: 'testRun/actionFetchTestRunList',
      fetchProjectOverview: 'testReport/actionGetProjectOverviewInfo',
      getAllTestReportList: 'testReport/actionGetAllTestReportList',
      onChangeProjectHandler: 'project/actionOnchangeProjectHandler',
      fetchSingleProjectPassingRateInfo: 'testReport/actionFetchSingleProjectPassingRateInfo',
      fetchProjectCardsAndRecentFailureInfo: 'testReport/actionFetchProjectCardsAndRecentFailureInfo',
      fetchGetProjectOverviewCloudInfo: 'testReport/actionGetProjectOverviewCloudInfo',
      fetchRecentlyExecutedCloudRunsInfo: 'testReport/actionGetRecentlyExecutedCloudRunsInfo',
      FetchWebExecutedRuns: 'testReport/actionFetchExecutedRuns',
    }),

    // onClick(event) {
    // 	this.$emit('profile-click', event);
    // 	event.preventDefault();
    // }
    getProject(id) {
      let prj = null;
      prj = this.projectList?.find((itm)=>itm._id == id)
      return prj;
    },
    truncate(str, length) {
      if (str?.length > length) {
        return str.slice(0, length) + '...';
      } else return str;
    },

    handleView(evt) {
      const projectType = this.projectList?.find((itm) => itm._id === evt.value).platform
      console.log('projectType', projectType, evt);
      // console.log("❣️ ❣️ ❣️ ❣️ ❣️ ❣️ ❣️ ❣️ ❣️ ❣️ ❣️ ", evt);

      if (this.$route && this.$route.path && this.$route.path.includes('/test-runs-details')) {
        this.$router.push({path: '/test-runs'});
      }

      if (this.$route && this.$route.path && this.$route.path.includes('/reports/create-new')) {
        this.$router.push({path: '/reports/all'});
      }

      if (this.$route && this.$route.path && this.$route.path.includes('/test-editor')) {
        this.$router.push({path: '/test-cases'});
      }
      this.onChangeProjectHandler(evt.value);
      setSelectedCurrentProjectId(evt.value);
      setSelectedCurrentProjectType(projectType);
      let projectOverviewUrl = '/project-overview/' + evt.value;
      if (projectType === 'mobile') {
        projectOverviewUrl = '/project-overview-mobile/' + evt.value;
      }
      console.log(projectOverviewUrl);
      this.$emit('newProjectSelected');
      this.$router.push({path: projectOverviewUrl});
    }
  },
  watch: {
    projectList(value) {
      if(value.length){
        if (!getSelectedCurrentProjectId()){
          this.onChangeProjectHandler(value[0]._id);
          setSelectedCurrentProjectId(value[0]._id);
          setSelectedCurrentProjectType(value[0].platform);
        }
        else {
          const selectedCurrentProject = value.find(pr => pr._id == getSelectedCurrentProjectId());
          if (selectedCurrentProject){
            this.onChangeProjectHandler(selectedCurrentProject._id);
            setSelectedCurrentProjectId(selectedCurrentProject._id);
            setSelectedCurrentProjectType(selectedCurrentProject.platform);
          }
          else {
            // Do something
          }
        }
      }
    },
    selectedProjectID(value) {
      console.log('value in watch', value);
      let projectOverviewReqObj = {
        projectID: value,
        duration: '3',
      };
      let projectPassingRateInfoReqObj = {
        projectID: value,
        duration: '3',
      };
      let projectCardsAndRecentFailureInfoReqObj = {
        projectID: value,
      };
      let testrunReqObj = {
        projectID: value,
        duration: this.testRunFilterDuration,
      };
      let testcaseReqObj = {
        projectID: value,
        duration: this.testCaseFilterDuration,
        issueTypeID: '',
        lastActivity: '',
      };
      let testReportReqObj = {
        projectId: value,
        duration: this.reportFilterDuration,
      };
      try {
        this.fetchProjectOverview(projectOverviewReqObj);
        this.fetchTestCases(testcaseReqObj);
        this.fetchTestRunList(testrunReqObj);
        this.getAllTestReportList(testReportReqObj);
        this.fetchSingleProjectPassingRateInfo(projectPassingRateInfoReqObj);
        this.fetchProjectCardsAndRecentFailureInfo(projectCardsAndRecentFailureInfoReqObj);
        this.fetchGetProjectOverviewCloudInfo(getSelectedCurrentProjectId());
        this.fetchRecentlyExecutedCloudRunsInfo(getSelectedCurrentProjectId());
        this.FetchWebExecutedRuns(getSelectedCurrentProjectId());
      } catch (error) {
        console.log(error)
      }
    },
  },
};
</script>

<style scoped></style>
