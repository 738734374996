import axios from 'axios';
import {DatabaseServiceBaseUrl} from '../base_urls';
// import interceptors from '../interceptors';

const DatabaseServiceRequest = axios.create({
    baseURL: `${DatabaseServiceBaseUrl}/api/`,
    timeout: 300000,
});

// interceptors(DatabaseServiceRequest);
export default DatabaseServiceRequest;