import {
    authInfoFetch,
    logout,
    resendEmailVerificationLink,
    resetPassword,
    resetPasswordLink,
    sendEmailVerificationLink,
    signIn,
    signup
} from '../../api/auth';
import {getToken, setToken} from '../../utils/cookies';

const state = {
    isLoggedIn: false,
    resetPasswordEmail: '',
    loginInfo: {
        username: '',
        password: '',
    },
    countryList: [],
    //authenticate user info
    user: {},
    newSignUpEmail: '',
};

const mutations = {
    onChangeLoginEmail(state, email) {
        state.loginInfo.username = email;
    },

    onChangeLoginPassword(state, password) {
        state.loginInfo.password = password;
    },

    setIsLoggedIn(state, payload) {
        state.isLoggedIn = payload;
        //console.log('payload', state.isLoggedIn);
    },

    login(state, payload) {
        state.loginInfo = payload;
    },

    signup(state, payload) {
        state.signupInfo = payload;
    },

    onChangeResetEmail(state, email) {
        state.resetPasswordEmail = email;
    },
    // mutate user
    AUTH_INFO(state, user) {
        state.user = user;
    },
    // newSignUpEmailVerify(state, token){
    //     state.newSignUpEmail = token;
    // }
};
const actions = {
    actionLoginHandler({state, commit}, formData) {
        commit(state.newSignUpEmail = formData.username);
        return signIn(formData).then(res => {
            if (res.status === 200) {
                commit('AUTH_INFO', res.data.user);
                setToken(res && res.data && res.data.accessToken);
                return res;
            } else {
                return res;
            }
        });
    },

    actionLogoutHandler() {
        let reqObject = {
            token: getToken(),
        };
        return logout(reqObject).then(data => {
            return data;
        });
    },

    actionSignupHandler({state, commit}, formData) {
        commit(state.newSignUpEmail = formData.email);
        return signup(formData).then(res => {
            if (res.status === 200) {
                return res;
            } else {
                return res;
            }
        });
    },

    actionResetPasswordLink({state, commit}, data) {
        let reqObj = {
            email: data ? data : state.resetPasswordEmail,
        };
        return resetPasswordLink(reqObj).then(res => {
            return res;
        });
    },

    actionResetPassword({state, commit}, formData) {
        return resetPassword(formData).then(res => {
            if (res.status === 200) {
                return res;
            } else {
                return res;
            }
        });
    },
    async actionFetchAuthInfo({state, commit}) {
        //Fetch Auth user info
        let response = [];

        try {
          const resp = await authInfoFetch();
          response = [resp.data, null];
          commit('AUTH_INFO', resp.data.user);

        } catch (err) {
          response = [null, err.response];

        } finally {
            return response;
        }
    },
    actionVerifyEmailLink({state, commit}, token) {
        return sendEmailVerificationLink(token).then(res => {
            if (res.status === 200) {
                return res;
            } else {
                return res;
            }
        });
    },
    actionResendVerifyEmailLink({state, commit}, email) {
        return resendEmailVerificationLink(email).then(res => {
            if (res.status === 200) {
                return res;
            } else {
                return res;
            }
        });
    },
};

const getters = {
    forgetPassEmail: state => state.resetPasswordEmail,
    newVerifyEmail: state => state.newSignUpEmail,
    countryList: state => state.countryList,
    loggedInUserInfo: state => state.user,
    subscriptionPlan: state => state.user.subscription_plan,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
