/* eslint-disable no-unused-vars */
import TestServiceRequest from '../utils/request/test_request';
import SelemiumRequest from '../utils/request/selenium_request';

export const insertNewReport = async reportData => {
    console.log("api page", reportData);
    return await TestServiceRequest.post('/test-report/save', reportData)
        .then(res => {
            console.log("report save api", res);
            return res;
        })
        .catch(error => {
            console.log("report save api error", error);
            return error;
        })
}

export const fetchAllExecutedTestRuns = async () => {
    return await TestServiceRequest.get('/v2/dashboard/executed-test-runs');
}

export const insertReport = async reportData => {
    return await TestServiceRequest.post('/v2/reports', reportData)
}

export const fetchProjectStatForDashboard = async (payload) => {
  const { days } = payload;
  return await TestServiceRequest.get(`/api/dashboard/project-status?days=${days}`);
};

export const previewReport = async (reportData) => {
    let response = [null, null];
    console.log('🇦🇨 🇦🇨 🇦🇨 🇦🇨 🇦🇨 🇦🇨 ', reportData);

    try {
        const res = await TestServiceRequest.post('/v2/reports/preview', reportData)
        console.log("🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷", res);
        response = [res, null];

    } catch (err) {
        console.log("🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷", err.response);
        response = [null, err.response];


    } finally {
        return response;
    }
}

export const fetchTestReportDetailsByID = async reportID => {
    return await TestServiceRequest.get(`test-report/details/by/report/` + reportID)
        .then(res => {
            return res;
        })
        .catch(error => {
            return error;
        });
};

export const fetchTestReportByID = async reportID => {
    return await TestServiceRequest.get(`v2/reports/` + reportID)
};

export const deleteTestReport = async (reqDataObj) => {
    return await TestServiceRequest.delete(`/v2/reports/many`, {data: reqDataObj}).then(res => {
        return res;
    }).catch(error => {
        return error
    })
}

export const fetchDashboardInfo = async data => {
    return await TestServiceRequest.get(`/admin/dashboard/info/` + data)
        .then(res => {
            return res;
        })
        .catch(error => {
            return error;
        });
};

export const fetchDashboardInfoSelenium = async () => {
    return await SelemiumRequest.get(`/dashboard/cloud/info/`)
        .then(res => {
            return res;
        })
        .catch(error => {
            return error;
        });
};

export const fetchSingleProjectOverviewInfo = async (reqDataObj) => {
    return await TestServiceRequest.get(`/admin/dashboard/project/info/${reqDataObj.projectID}/${reqDataObj.duration}`)
        .then(res => {
            return res;
        })
        .catch(error => {
            return error;
        });
};

export const fetchProjectOverviewCounters = async (projectID) => {
  return await TestServiceRequest.get(`/api/projects/${projectID}/overview`).then(res => {
      return res;
  })
  .catch(erorr => {
      console.log(erorr)
  })
}

export const fetchProjectCardsAndRecentFailureInfo = async (reqDataObj) => {
    return await TestServiceRequest.get(`/api/projects/${reqDataObj.projectID}/failed-cases${reqDataObj?.duration ? `?days=${reqDataObj.duration}` : ''}`).then(res => {
        return res;
    })
    .catch(erorr => {
        console.log(erorr)
    })
}

export const fetchSingleProjectPassingRateInfo = async (reqDataObj) => {
    return await TestServiceRequest.get(`/api/projects/${reqDataObj.projectID}/pass-rate?days=${reqDataObj.duration}`).then(res => {
        return res;
    })
    .catch(erorr => {
        return erorr;
    })
}

export const fetchSingleProjectOverviewInfoV2 = async (reqDataObj) => {
    return TestServiceRequest.get(`/v2/native-test-runs?project_id=${reqDataObj.projectID}`)
};

export const fetchLastExecutedRunsInfo = async () => {
    return await TestServiceRequest.get('/admin/dashboard/latest-runs/info')
        .then(res => {
            console.log('from last executed api===>', res.data)
            return res;
        })
        .catch(error => {
            return error;
        })
};

export const fetchLastExecutedRunsByUserId = (userId) => {
    return TestServiceRequest.get('/v2/native-test-runs', { params: { created_by: userId } })
};

export const fetchLastExecutedCloudRunsInfo = async () => {
    try {

        return await SelemiumRequest.get('/dashboard/cloud/latest-run/info')
            .then(res => {
                console.log('from last executed api===>', res.data)
                return res;
            })
            .catch(error => {
                return error;
            })
    } catch (error) {

    }
};

export const fetchProjectOverviewCloudInfo = async (ID) => {

    return await SelemiumRequest.get(`/dashboard/cloud/single-project/info/${ID}`)
        .then(res => {
            console.log('from fetch Project Overview Cloud Info api', res.data)
            return res;
        })
        .catch(error => {
            return error;
        })
};

export const fetchRecentlyExecutedCloudRunsInfo = async (ID) => {

    return await SelemiumRequest.get(`/dashboard/cloud/single-project/latest-run/info/${ID}`)
        .then(res => {
            console.log('from fetch Recently Executed Cloud Runs Infos api', res.data)
            return res;
        })
        .catch(error => {
            return error;
        })
};

export const fetchTestReportListOld = async (filterObj) => {
    return await TestServiceRequest.get(`/test-report/list/by/project/${filterObj.projectId}/${filterObj.duration}`)
        .then(res => {
            return res;
        })
        .catch(error => {
            return error;
        });
};

export const fetchTestReportList = async (filterObj) => {
    return await TestServiceRequest.get(`/v2/reports/projects/${filterObj.projectId}`)
};


export const fetchCloudTestRunReportById = async (runId) => {
    return await SelemiumRequest.get(`/api/v2/cloud-test-runs/${runId}`)
};

export const fetchExecutedRuns = async (projectID) => {
    return await TestServiceRequest.get(`/api/projects/${projectID}/executed-test-runs`)
        .then(res => {
            console.log('from last executed api===>', res.data)
            return res;
        })
        .catch(error => {
            return error;
        })
};

export const fetchDashboardCounts =  async () => {
    return await TestServiceRequest.get(`/api/dashboard`)
        .then(res => {
            return res;
        })
        .catch(error => {
            return error;
        });
};