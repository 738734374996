<template>
  <div>
    <AuthComponentLayout>
      <CheckEmail/>
    </AuthComponentLayout>
  </div>
</template>


<script>
import CheckEmail from '../components/guest/CheckEmail.vue';
import AuthComponentLayout from '../components/AuthComponentLayout.vue';

export default {
  components: {
    CheckEmail,
    AuthComponentLayout
  }
};
</script>

<style>
</style>