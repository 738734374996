<template>
  <div class="header-main req-header">
    <div class="head">
      <Button label="Header" :class="type === 'header' ? 'active' : ''" @click="type = 'header'" />
      <Button label="Authorization" :class="type === 'auth' ? 'active' : ''" @click="type = 'auth'" />
    </div>
    <div class="body">
      <div class="headers" v-if="type === 'header'">
        <span v-for="(header, i) in headers" :key="i">
          <Checkbox v-model="header['checked']" :binary="true" />
          <InputText v-model="header['header']" :disabled="!header['checked']" placeholder="Content-Type"
            @keyup="e => keyUP(e, i)" />
          <InputText v-model="header['value']" :disabled="!header['header'].length || !header['checked']"
            placeholder="application/json" />
        </span>
      </div>
      <div class="authorizations" v-else-if="type === 'auth'">
        <Checkbox v-model="authEnabled" :binary="true" />
        <Dropdown v-model="authType" :options="auths" optionLabel="name" optionValue="code" class="w-full md:w-14rem" />
        <InputText v-model="authValue" placeholder="token" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "request-header",
  props: {
    allHeaders: {
      type: Array,
    },
    auth: {
      type: Object,
    }
  },
  created() {
    if (this.allHeaders?.length) {
      this.headers = [...this.allHeaders]
      this.$emit("headerValueUpdated", this.headers)
    } else {
      this.headers = [
        {
          checked: true,
          header: 'Content-Type',
          value: 'application/json',
        },
        {
          checked: true,
          header: '',
          value: '',
        },
      ]
    }
    if (this.auth) {
      this.authType = this.auth.type;
      this.authValue = this.auth.value;
      this.$emit("authValueUpdated", { type: this.authType, value: this.authValue })
    }
  },
  data: () => ({
    type: 'header', //* header, auth
    headers: [],
    authEnabled: true,
    authType: 'basic',
    auths: [
      { name: 'BEARER', code: 'bearer' },
      { name: 'BASIC', code: 'basic' }
    ],
    authValue: '',
  }),
  watch: {
    authType(val) {
      this.$emit("authValueUpdated", { type: val, value: this.authValue })
    },
    headers: {
      handler(value) {
        this.$emit("headerValueUpdated", value)
      },
      deep: true
    },
    authValue(value) {
      this.$emit("authValueUpdated", { type: this.authType, value })
    },
  },
  methods: {
    keyUP(e, i) {
      const lastElement = (this.headers.length - 1) === i;
      const elementBeforeTheLastElement = (this.headers.length - 2) === i;

      if (lastElement && e.target.value) {
        this.headers.push({
          checked: true,
          header: '',
          value: '',
        })
      } else if (!lastElement && elementBeforeTheLastElement && !e.target.value) {
        this.headers.splice(i, 1)
      }
      // console.log('changed', e.target.value);
    }
  },
}
</script>
