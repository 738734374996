<template>
  <div class="api-validation-Logs" v-if="logger">
    <span class="head">Logs</span>
    <pre>
      <code id="code-block">
        {{ str }}
      </code>
    </pre>
  </div>
</template>

<script>
import hljs from 'highlight.js/lib/core';
// import xml from 'highlight.js/lib/languages/xml';


export default {
  name: "db-validation-Logs",
  props: {
    response: {
      type: Object || null,
      default: null,
    }
  },
  data: () => ({
    logger: null,
    str: '',
  }),
  created() {
    this.logger = hljs;
  },
  mounted() {
    const codeBlock = document.getElementById("code-block");
    hljs.highlightAll();

    if (this.response) {
      const result = this.response.executionResponse?.result;
      const clog = this.response.executionResponse?.console;
      for (let i = 0; i < clog?.length; i++) {
        const txt = clog[i];

        this.str = `${this.str}\n${txt}`
      }

      if (result?.error) {
        this.str = `\nERROR: ${result?.error}\n\n${this.str}`
      }

    } else {
      console.log("NO codeValidationResult IN RESPONSE");
    }
  },
  watch: {
      response: {
            handler(value) {
            },
            deep: true,
        },
    },
  methods: {

  },
}
</script>

<style lang="scss" scoped>

#code-block {
  text-wrap: balance;
}

.api-validation-Logs {
      margin-top: 3rem;

      .head {
        color: #282828;
        font-size: 20px;
        font-weight: 600;
        padding: 12px 16px;
        border-radius: 4px;
        border: 1px solid #C3C9CF;
      }

      .code_status {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 16px;
        padding: 4px 10px;

        &.error {
          background: rgba(214, 69, 100, 0.05);
          color: #D64564;
        }

        &.success {
          background: rgba(50, 206, 153, 0.05);
          color: #32CE99;
        }
      }

    }

</style>
