import {
    fetchAssigneListFromJira,
    fetchEpicLinkListFromJira,
    fetchFixVersionListFromJira,
    fetchIssuesFromJira,
    fetchIssueTypeListFromJira,
    fetchLabelListFromJira,
    fetchLinkedIssuesFromJira,
    fetchPriorityListFromJira,
    fetchProjectListFromJira,
    fetchReporterListFromJira,
    fetchSprintListFromJira
} from '../../api/JiraTicketService';

const state = {
    jiraProjectList: [],
};

const mutations = {};

const actions = {

    async actionFetchProjectListFromJira(store) {
        return fetchProjectListFromJira();
    },

    async actionFetchIssueTypeList(store) {
        return fetchIssueTypeListFromJira();
    },

    async actionFetchPriorityList(store) {
        return fetchPriorityListFromJira();
    },

    async actionFetchLabelList(store) {
        return fetchLabelListFromJira();
    },

    async actionFetchReporterList(store) {
        return fetchReporterListFromJira();
    },

    async actionFetchLinkedIssues(store) {
        return fetchLinkedIssuesFromJira();
    },

    async actionFetchIssues(store) {
        return fetchIssuesFromJira();
    },

    async actionFetchAssigneList(store) {
        return fetchAssigneListFromJira();
    },

    async actionFetchFixVersionList(store) {
        return fetchFixVersionListFromJira();
    },

    async actionFetchEpicLinkList(store) {
        return fetchEpicLinkListFromJira();
    },

    async actionFetchSprintList(store) {
        return fetchSprintListFromJira();
    },

};

const getters = {
    jiraProjectList: state => state.jiraProjectList,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
