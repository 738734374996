<template>
  <div>
    <Dialog :draggable="false" header="Add test case from TestRail" v-model:visible="showDialog" :style="{ width: '41.93vw' }" :modal="showDialog"
            @hide="$emit('hide')">

      <!-- <template class="head" #header>
          <h3>Add Testrail</h3>
      </template> -->

      <div class="p-grid p-fluid input-form-group m-t-i-26 m-r-0 m-l-0">
        <!--  -->

        <div class="center--testrail">
          <img class="image" alt="Logo" src="/assets/layout/images/testrail.svg" height="30"
               style="margin-bottom: 40px"/>
        </div>
        <!--  -->
        <div class="new_wrapper">
          <div v-if="isLoaded" class="p-col-12 m-2 p-0">
            <div class="p-col-12 m-2 p-0" v-if="testCaseList.length">
              <Dropdown class="tj_dropDown" type="text" :filter="true" filterIcon="pi pi-search" optionLabel="title" v-model="selectedTestCase" :options="testCaseList" placeholder="Select Test Case" :style="{ width: '100%' }">
                <template #value="slotProps">
                    <p v-if="slotProps.value && slotProps.value.title">{{ slotProps.value.title }}</p>
                    <p v-else>{{ slotProps.placeholder }}</p>
                </template>
                <template #option="slotProps">
                  <p :title="slotProps.option.title" class="tj_dropDown_option">{{ slotProps.option.title }}</p>
                </template>
              </Dropdown>
            </div>
            <div class="p-col-12 m-2 p-0" v-else>
              <p style="text-align: center; color: #555">Testcases not found in Project
                {{ testRailsConfig && testRailsConfig.current_project.name }}</p>
            </div>
          </div>
          <div v-else class="p-col-12 m-2 p-0 p-d-flex p-ai-center p-jc-center">
            <ProgressSpinner style="width: 20px; height: 20px" strokeWidth="2" fill="#fff" animationDuration=".5s"/>
          </div>
          <!--  -->
          <div class="p-col-12 m-0 p-0">
            <Button label="ADD TEST CASE" :disabled="!isRequiredFullFilled" class="p-button-primary"
                    @click="submitAddTestCase" :style="{ marginTop: '15px' }"/>
          </div>
        </div>
      </div>
    </Dialog>
  </div>
</template>
<script>
import {fetchTestrailProjects} from '@/api/testrailsService';
import {mapGetters} from 'vuex';
import {getSelectedCurrentProjectId} from "@/utils/localstorage";

export default {
  props: {
    testCase: {
      type: Object,
      default: {},
    },
    mobileVersion: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedTestCase: null,
      testCaseList: [],
      isLoaded: false,
      showDialog: false
    };
  },
  methods: {
    submitAddTestCase() {
      const projectId = this.testRailsConfig.current_project && this.testRailsConfig.current_project.id;

      if (this.mobileVersion) {
        this.$emit('onMapTestRail', {projectId, testRailTestCase: this.selectedTestCase, testCase: this.testCase});
        setTimeout(() => {
          this.selectedTestCase = null;
          this.$emit('hide');
          this.showDialog = false;
        }, 1000);
        return
      }

            this.$emit('onMapTestRail', {projectId, testRailTestCase: this.selectedTestCase, testCase: this.testCase});
            // this.selectedTestCase = null;
            this.$emit('hide');
            this.showDialog = false;

    },
    // Fetch Data
    async fetchData(projectId) {
      return await fetchTestrailProjects(projectId)
          .then(response => {
            // console.log("🌸 🌸 🌸 🌸 🌸 🌸 🌸 🌸 🌸 🌸 🌸 🌸 THEN", response, this.testRailsConfig);

            if (this.testRailsConfig?.current_project?.id){
              if (response?.data?.projects?.projects){

                response.data.projects.projects.forEach(project=>{
                  if (project.id == this.testRailsConfig.current_project.id){
                    this.testCaseList = project.cases;
                    this.isLoaded = true;
                    // console.log("🌸 🌸 🌸 🌸 🌸 🌸 🌸 🌸 🌸 🌸 🌸 🌸 REACHED");

                    this.$emit('afterFetchTestRail',{isLoaded: this.isLoaded});
                  }
                })

              }
            }
          })
          .catch((err) => {
            // console.log("🌸 🌸 🌸 🌸 🌸 🌸 🌸 🌸 🌸 🌸 🌸 🌸 CATCH");

            this.isLoaded = true;
            this.$emit('afterFetchTestRail',{isLoaded: this.isLoaded});
            this.$toast.add({
                severity: 'error',
                summary: 'Error Message',
                detail: err && err.response && err.response.data && err.response.data.message,
                life: 10000,
              })
          });
    },

    openDialog(selectedTestCase) {
      this.selectedTestCase = selectedTestCase;
      if (this.isLoaded) {
        this.showDialog = true;
      } else {
        this.$toast.add({
          severity: 'error',
          summary: 'Error Message',
          detail: 'There is an issue with integrated Testrail Account. Please contact your Testrail Administrator to learn more.',
          life: 10000,
        })
      }
    }
  },
  unmounted() {
    this.selectedTestCase = null;
  },

  mounted() {

  },
  watch: {
    testRailsConfig(value) {
      let projectId = getSelectedCurrentProjectId();
      this.fetchData(projectId);
      // console.log("🌸 🌸 🌸 🌸 🌸 🌸 🌸 🌸 🌸 🌸 🌸 🌸", value);
    }
  },
  computed: {
    ...mapGetters({
      testRailsConfig: 'settings/testRailsConfig',
    }),
    isRequiredFullFilled() {
      if (this.selectedTestCase && this.selectedTestCase.id) {
        return true;
      }
      return false;
    },
  }
};
</script>

<style scoped lang="scss">

.p-dialog-header {
  border: none !important;
}

.input-form-group {

  .new_wrapper {
    width: 100%;
    min-height: 22vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .tj_dropDown {

      .tj_dropDown_option {
        white-space: nowrap;
        max-width: 50em;
        overflow: hidden;
        text-overflow: ellipsis;
      }

    }

  }
}

</style>
