<template>
  <div>
    <AuthComponentLayout>
      <div v-if="!forgotPassEmailSent" class="login-container forgot-pass">
        <div class="login-container-header">Forgot Password</div>
        <div class="input p-fluid">
          <div class="email">
            <label for="email">Email</label>
            <InputText id="email" v-model="email" type="email" @keyup.enter="clickResetPasswordEmailHandler"
              placeholder="Enter Email" :class="requiredFieldState?.email" />
            <small class="p-invalid" v-if="msg?.email">{{ msg?.email }}</small>
          </div>


          <Button class="login-btn" label="Confirm" @click="clickResetPasswordEmailHandler" :disabled="false"></Button>
        </div>
      </div>
      <div v-else class="login-container check-email">
        <div class="login-container-header">Check email</div>
        <div class="input p-fluid">
          <!-- <div class="email">
            <label for="email">Email</label>
            <InputText id="email" v-model="email" type="email" @keyup.enter="clickForgotPasswordHandler" placeholder="Enter Email"
              :class="requiredFieldState?.email" />
            <small class="p-invalid" v-if="msg?.email">{{ msg?.email }}</small>
          </div> -->
          <div class="email-sent">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46 40" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M33.9274 0.0878906C36.8943 0.0878906 39.7484 1.26051 41.8481 3.36458C43.9499 5.46423 45.1248 8.29622 45.1248 11.261V28.7396C45.1248 34.9124 40.1024 39.9127 33.9274 39.9127H12.0702C5.89513 39.9127 0.875 34.9124 0.875 28.7396V11.261C0.875 5.08811 5.87301 0.0878906 12.0702 0.0878906H33.9274ZM37.4472 14.5576L37.6242 14.3806C38.153 13.7389 38.153 12.8097 37.5999 12.1681C37.2923 11.8384 36.8698 11.6371 36.4295 11.5928C35.9649 11.5685 35.5224 11.7256 35.1883 12.0353L25.2122 20.0003C23.9289 21.0645 22.0903 21.0645 20.7872 20.0003L10.831 12.0353C10.1429 11.5264 9.19154 11.5928 8.6185 12.1902C8.02113 12.7876 7.95476 13.7389 8.46142 14.4027L8.75125 14.6903L18.8181 22.5446C20.0571 23.5181 21.5593 24.0491 23.1324 24.0491C24.7011 24.0491 26.2299 23.5181 27.4667 22.5446L37.4472 14.5576Z"
                fill="#045AFF" />
            </svg>
            <p>An email has been sent to <strong>{{ email }}</strong> with an OTP and password reset link</p>
          </div>


          <Button class="login-btn" label="Back to Log in" @click="$router.go(-1)" :disabled="false" />
        </div>
      </div>
    </AuthComponentLayout>
  </div>
</template>

<script>
//! OLD AUTH COMPONENT LAYOUT ( this file still exists delete it when necessary && is signup design is changed! )
// import AuthComponentLayout from "../components/AuthComponentLayout.vue";

import { mapActions, mapGetters } from 'vuex';
//! NEW AUTH COMPONENT LAYOUT
import AuthComponentLayout from "../components/authPageComponents/AuthComponentLayout.vue";

export default {
  components: {
    AuthComponentLayout,
  },
  created() {
    // console.log(this.$route.query);
    const { eml } = this.$route.query;
    if (eml) {
      this.email = eml;
    }
  },
  data: () => ({
    forgotPassEmailSent: false,
    email: '',
    msg: {},
    requiredField: {
      email: '',
    },
    requiredFieldState: {
      email: '',
    },
  }),
  watch: {
    email(value) {
      // binding this to the data value in the email input
      if (value) {
        this.email = value;
        return this.validateEmail(value);
      }
      this.msg['email'] = '';
      this.requiredFieldState.email = '';
    },
  },
  methods: {
    ...mapActions({
      sendLinkToMail: 'auth/actionResetPasswordLink',
      GetOrganizationData: 'organization/GetOrganizationData',
    }),
    validateEmail(value) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
        this.msg['email'] = '';
        // this.error = true;
        this.requiredFieldState.email = '';
        this.emailValidation = true;
      } else {
        this.msg['email'] = 'Invalid email address.';
        // this.error = true;
        this.requiredFieldState.email = 'p-error';
        this.emailValidation = false;
      }
      this.error = false;
    },
    isRequiredFieldEmpty() {
      let requiredFieldEmpty = false;
      if (this.email == '') {
        requiredFieldEmpty = true;
        this.requiredField.email = 'p-invalid';
      }

      return requiredFieldEmpty;
    },
    clickResetPasswordEmailHandler() {
      if (!this.isRequiredFieldEmpty()) {
        if(this.msg['email']) {
          return;
        }
        this.sendLinkToMail(this.email.toLowerCase())
          .then((resBody) => {
            console.log('in email link send', resBody);
            if (resBody.status === 200) {
              this.$toast.add({ severity: 'success', summary: 'Email Sent', detail: 'Reset Password Link Sent to Your Email', life: 3000 });
              this.displayForgotPasswordModal = false;
              setTimeout(() => this.forgotPassEmailSent = true, 500);
            } else {
              this.$toast.add({ severity: 'error', summary: 'Error Message', detail: resBody.data.errors, life: 3000 });
            }
          })
          .catch((err) => {
            this.$toast.add({ severity: 'error', summary: 'Error Message', detail: 'Something went wrong!', life: 3000 });
          });
      } else {
        this.msg['email'] = 'Email is Required !';
        this.requiredFieldState.email = 'p-error';
        this.emailValidation = false;
      }
    },
  }
}
</script>

<style></style>
