export function rescale(x, y, videoWidth, videoHeight, deviceDimention) {
  const x0 = parseInt((x / videoWidth) * parseInt(deviceDimention.width));
  const y0 = parseInt((y / videoHeight) * parseInt(deviceDimention.height));

  return {
    x: x0,
    y: y0,
  }
}

export const generateRandomString = (num) => {

  var pattern =

    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  var sequence = "";

  for (var i = 0; i < num; i++) {

    sequence += pattern[Math.floor(Math.random() * pattern.length)];

  }

  return sequence;

};


export const customRunId = (str) => {
  let serial = String(str);
  const pad = (str, max) => {
      return str.toString().length < max ? pad("0" + str, max) : str;
  };
  if (serial) {
      return "TR-" + pad(serial, 8);
  } else {
      return "TR-" + pad(0, 8);
  }
};


export function convertMs( ms ) {
  // 1- Convert to seconds:
  let seconds = ms / 1000;
  // 2- Extract hours:
  const hours = parseInt( seconds / 3600 ); // 3,600 seconds in 1 hour
  seconds = seconds % 3600; // seconds remaining after extracting hours
  // 3- Extract minutes:
  const minutes = parseInt( seconds / 60 ); // 60 seconds in 1 minute
  // 4- Keep only seconds not extracted to minutes:
  seconds = seconds % 60;
  return {
    formated: `${hours}:${minutes}:${seconds}`,
    hrs: hours,
    mins: minutes,
    secs: seconds
  };
}
