<template>
  <div>
    <AuthComponentLayout>
      <LoginForm/>
    </AuthComponentLayout>

  </div>
</template>

<script>
//! OLD AUTH COMPONENT LAYOUT ( this file still exists delete it when necessary && is signup design is changed! )
// import AuthComponentLayout from "../components/AuthComponentLayout.vue";
// import LoginForm from "../components/guest/LoginForm.vue";

//! NEW AUTH COMPONENT LAYOUT
import AuthComponentLayout from "../components/authPageComponents/AuthComponentLayout.vue";
import LoginForm from "../components/guest/Login.vue";

export default {
  components: {
    AuthComponentLayout,
    LoginForm
  }
}
</script>

<style>

</style>
