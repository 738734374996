<template>
  <div class="api-validation-Logs" v-if="logger">
    <span class="head">Logs</span>
    <pre>
      <code id="code-block">
        {{ str }}
      </code>
    </pre>
  </div>
</template>

<script>
import hljs from 'highlight.js/lib/core';
// import xml from 'highlight.js/lib/languages/xml';


export default {
  name: "api-validation-Logs",
  props: {
    response: {
      type: Object || null,
      default: null,
    }
  },
  data: () => ({
    logger: null,
    // str: `
    // try {
    //   getPort = getBrowser().runtime.connect(this.$extensionId, { name: 'testjet-extension-port' });
    //   // Throw custom exception
    //   if (!getPort) {
    //     throw new ExtensionNotFoundException('Failed to connect extension');
    //   }
    //   this.attachPortListeners();

    //   // Add Event Listener
    // } catch (e) {
    //   console.error('Extension Exception', e);
    // }`,
    str: '',
  }),
  created() {
    this.logger = hljs;
  },
  mounted() {
    // hljs.registerLanguage('xml', xml);
    const codeBlock = document.getElementById("code-block");
    // console.log(hljs, '\n', codeBlock);
    hljs.highlightAll();

    if (this.response?.executionResponse) {
      const result = this.response.executionResponse.result;
      const clog = this.response.executionResponse.console;

      for (let i = 0; i < clog.length; i++) {
        const txt = clog[i];

        this.str = `${this.str}\n${txt}`
      }

      if (result.error) {
        this.str = `\nERROR: ${result.error}\n\n${this.str}`
      }
      // if (result.error) {
      //   this.str = `ERROR: ${result.error}\n\n${this.str}`
      // }


    } else {
      console.log("NO codeValidationResult IN RESPONSE");
    }
  },
  methods: {

  },
}
</script>

<style lang="scss" scoped>

#code-block {
  text-wrap: balance;
}

</style>
