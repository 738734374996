/* eslint-disable no-unsafe-finally */
import axios from 'axios';
import { DOMParser } from 'xmldom';

const backendUrl = process.env.VUE_APP_MOBILE_SERVICE_HOST;
const agentUrl = process.env.VUE_APP_AGENT_SERVICE_HOST;

export const $startStreamServer = async (deviceType) => {
    let response = []

    try {
        // const res = await axios.get(`http://localhost:4010/startRecord/${projectId}/${caseId}`);
        const res = await axios.get(`${agentUrl}/startStreamServer/${deviceType}`);

        response = [res.data, null]

    } catch (err) {
        response = [null, err.response]

    } finally {
        return response
    }
}

export const $startRecording = async (projectId, apkName, platformType) => {
    let response = []

    try {
        // const res = await axios.get(`http://localhost:4010/startRecord/${projectId}/${caseId}`);
        const res = await axios.get(`${agentUrl}/startRecord/${projectId}/${apkName}/${platformType}`);
        response = [res.data, null]

    } catch (err) {
        response = [null, err.response]

    } finally {
        return response
    }

}

export const $startRecordingWithBundle = async (projectId, apkName, platformType) => {
    let response = []

    try {
        // const res = await axios.get(`http://localhost:4010/startRecord/${projectId}/${caseId}`);
        const res = await axios.get(`${agentUrl}/startRecord/${projectId}/bundleId/${apkName}/${platformType}`);
        response = [res.data, null]

    } catch (err) {
        response = [null, err.response]

    } finally {
        return response
    }

}

export const $stopRecording = async () => {
    let response = []

    try {
        const res = await axios.get(`${agentUrl}/stopRecord`)

        response = [res.data, null]

    } catch (err) {
        response = [null, err.response]

    } finally {
        return response
    }

}

export const getCurrentDomTree = async () => {
  let response = [null, null];

  const res = await axios.get(`${agentUrl}/currentDomTree`);

  if(res.data.domTree !== null){
    const domTree = new DOMParser().parseFromString(res.data.domTree)
    response = [domTree, null];
  }
  else response = [null, "no appium session running"];
  console.log("🏤 🏤 🏤 🏤 🏤 🏤 🏤 🏤 getCurrentDomTree => finally", response);
  return response;
}

export const getCurrentPageSource = async () => {
    let response = null;
    const res = await axios.get(`${agentUrl}/currentDomTree`);
    if (res.data.domTree !== null) {
        response = res.data.domTree;
    }
    return response;
}

export const $startNativeAutomation = async (projectId, apkName, platformType) => {
    let response = []

    try {
        const res = await axios.get(`${agentUrl}/startNativeRun/${projectId}/${apkName}/${platformType}`);
        response = [res.data, null]

    } catch (err) {
        response = [null, err.response]

    } finally {
        return response
    }

}

export const $startNativeAutomationWithBundle = async (projectId, apkName, platformType) => {
    let response = []

    try {
        const res = await axios.get(`${agentUrl}/startNativeRun/${projectId}/bundleId/${apkName}/${platformType}`);
        response = [res.data, null]

    } catch (err) {
        response = [null, err.response]

    } finally {
        return response
    }

}

export const $stopNativeAutomation = async () => {
    let response = []

    try {
        const res = await axios.get(`${agentUrl}/stopNativeRun`);

        response = [res.data, null]

    } catch (err) {
        response = [null, err.response]

    } finally {
        return response
    }

}

export const waitForApp = async (appInfo) => {
    let response = []

    try {
        const res = await axios.post(`${agentUrl}/waitForApp`, appInfo);

        response = [res.data, null]

    } catch (err) {
        response = [null, err.response]

    } finally {
        return response
    }
}

export const mockTestEmailValidation = async (data, platformType) => {
  let response = []

  try {
      const res = await axios.post(`${agentUrl}/email-validation-mock-test/${platformType}`, data);
      response = [res.data, null]

  } catch (err) {
      response = [null, err.response]

  } finally {
      return response
  }

}

export const mockTestSmsValidation = async (data, platformType) => {
    let response = []
  
    try {
        const res = await axios.post(`${agentUrl}/sms-validation-mock-test/${platformType}`, data);
        response = [res.data, null]
  
    } catch (err) {
        response = [null, err.response]
  
    } finally {
        return response
    }
  
  }
