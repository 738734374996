<template>
  <div class="login-container">
    <div class="login-container-header">Log in</div>
    <!-- <div> -->
    <div class="input p-fluid">
      <div class="email">
        <label for="email">Email</label>
        <InputText id="email" v-model="email" type="email" @keyup.enter="clickLoginHandler" placeholder="Enter Email"
          :class="loginRequiredFields.email" />
        <small class="p-invalid" v-if="msg.email">{{ msg.email }}</small>
      </div>

      <div class="password">
        <label for="password">Password</label>
        <Password id="password" v-model="password" placeholder="Enter Password" @keyup.enter="clickLoginHandler"
          :class="loginRequiredFields.password" :feedback="false" :autocomplete="false" toggleMask></Password>
        <small class="p-invalid" v-if="msg.password">{{ msg.password }}</small>
      </div>

      <div class="options">
        <!-- <div class="float-left">
          <Checkbox id="remember-me" v-model="rememberMe" :binary="true" />
          <label for="remember-me">Remember Me</label>
        </div> -->
        <div class="float-right" @click="forgetPass">Forgot Password?</div>
      </div>

      <Button class="login-btn" label="LOG IN" @click="clickLoginHandler" :disabled="!email.length || !password.length"></Button>
      <!-- <div class="sign-up-link">
        Don't have an account?
        <router-link to="/signup">Sign Up</router-link>
      </div> -->
    </div>
    <div class="no-account">No account? Contact your account manager.</div>
  </div>
</template>

<script>
import { getToken } from '@/utils/cookies';
import decode from 'jwt-decode';
import { mapActions, mapGetters } from 'vuex';
import CryptoJS from 'crypto-js';

const moment = require('moment');

export default {
  data() {
    return {
      error: false,
      msg: [],
      email: '',
      password: '',
      rememberMe: false,
      resetEmail: '',
      loginRequiredFields: {
        email: '',
        password: '',
      },
      emailValidation: false,
      passwordValidation: false,
    };
  },

  created() {
    // var ciphertext = CryptoJS.AES.encrypt('1234a@', 'secret key 123');

    // // Decrypt
    // var bytes = CryptoJS.AES.decrypt(ciphertext.toString(), 'secret key 123');
    // var plaintext = bytes.toString(CryptoJS.enc.Utf8);

    // console.log(plaintext);

    let cookiesObjectArray = [];
    let cookiesArray = document.cookie.split('; ');
    if (cookiesArray != '') {
      cookiesArray.map((cookie) => {
        let cookieObject = {
          key: cookie.split('=')[0],
          value: cookie.split('=')[1],
        };
        cookiesObjectArray.push(cookieObject);
      });
    }
    let expires = new Date(moment().subtract(7, 'days')).toUTCString();

    console.log(expires);
    if (cookiesObjectArray.length > 1) {
      // this.rememberMe = true;
      let cookieKey = [];
      cookiesObjectArray.map((cookie) => {
        if (cookie.key === 'email') {
          cookieKey.push(cookie.key);
          this.email = cookie.value;
        } else if (cookie.key === 'password') {
          cookieKey.push(cookie.key);
          var bytes = CryptoJS.AES.decrypt(cookie.value.toString(), toString(process.env.VUE_APP_ENCRYPTION_SECRET));
          var decryptedPass = bytes.toString(CryptoJS.enc.Utf8);
          this.password = decryptedPass;
        }
      });

      if (cookieKey[0] === 'email' && cookieKey[1] === 'password') {
        this.rememberMe = true;
      }
    }
  },

  watch: {
    rememberMe(value) {
      if (value == true) {
        if (this.email && this.password) {
          let expires = new Date(moment().add(7, 'days')).toUTCString();
          let encryptedPass = CryptoJS.AES.encrypt(this.password, toString(process.env.VUE_APP_ENCRYPTION_SECRET));
          document.cookie = `email=${this.email}; expires=${expires}`;
          document.cookie = `password=${encryptedPass}; expires=${expires}`;
        }
      } else if (value == false) {
        let expired = new Date(moment().subtract(8, 'days')).toUTCString();
        document.cookie = `email= ; expires=${expired}`;
        document.cookie = `password= ; expires=${expired}`;
      }
    },

    email(value) {
      // binding this to the data value in the email input
      if (value) {
        this.email = value;
        return this.validateEmail(value);
      }
      this.msg['email'] = '';
      this.loginRequiredFields.email = '';
    },
    password(value) {
      this.msg['password'] = '';
      if (value) {
        return (this.password = value), (this.passwordValidation = true);
      }
      this.loginRequiredFields.password = '';
      this.passwordValidation = false;
    },
  },
  methods: {
    ...mapActions({
      GetOrganizationData: 'organization/GetOrganizationData',
    }),

    showSuccess(summary, detail) {
      this.$toast.add({ severity: 'success', summary: summary, detail: detail, life: 3000 });
    },

    showError(detail) {
      this.$toast.add({ severity: 'error', summary: 'Error Message', detail: detail, life: 3000 });
    },

    forgetPass () {
      const url = `/forget-password${this.emailValidation ? '?eml=' + this.email : ''}`;
      this.$router.push(url)
    },

    clickLoginHandler: async function () {
      const { redirect } = this.$route.query;
      if (this.email == '' && this.password == '') {
        this.error = true;
        this.msg['email'] = 'Please enter valid email.';
      }
      if (this.password === '') {
        this.msg['password'] = 'Please enter valid Password.';
      }

      if (this.email !== '' && this.emailValidation && this.password !== '' && this.passwordValidation) {
        this.error = false;
        let form_obj = {
          username: this.email.toLowerCase(),
          password: this.password,
          remember_me: this.rememberMe,
        };

        console.log('form-obj--', form_obj);

        try {
          const body = await this.$store.dispatch('auth/actionLoginHandler', form_obj)
          if (body.status === 200) {
            if (body.data.verification.email_verified === false) {
              this.$router.push({ path: '/verification-email' });
            } else {
              const decoded = decode(getToken());

              this.msg = [];
              this.error = false;
              await new Promise(r => setTimeout(r, 500))
              console.log('login==>', body.data.user.organizationId);
              this.GetOrganizationData(body.data.user.organizationId)
              if (body.data.user_status === 2 && body.data.invited == null) {
                this.$router.push({ path: '/user/set-up-account' }); //Admin Account Setting
              } else if (body.data.user_status === 2 && body.data.invited != null) {
                this.$router.push({ path: '/member/set-up-account' }); //Tester Account Setting
              }
              else if (body.data.user_status === 1) {
                redirect ? this.$router.push({ path: redirect }) : this.$router.push({ path: '/dashboard' });
              } else if (body.data.user_status === 0 || body.data.user_status === 3) {
                this.showError('Your account is suspended');
              }
            }
          } else {
            this.error = true;
            if (body && body.data && body.data.errorMsg) {
              if (body.data.errorMsg.username !== null) {
                this.msg['email'] = body.data.errorMsg.username;
                this.loginRequiredFields.email = 'p-error';
              }

              if (body.data.errorMsg.password !== null) {
                this.msg['password'] = body.data.errorMsg.password;
                this.loginRequiredFields.password = 'p-error';
              }

              // this.msg['email'] = body.data.errorMsg.username !== null ? body.data.errorMsg.username; loginRequiredFields.email="p-error" : '';
              // this.msg['password'] = body.data.errorMsg.password;
            }
          }
        } catch (er) {
          console.log(er);
        }
      }
    },

    validateEmail(value) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
        this.msg['email'] = '';
        // this.error = true;
        this.loginRequiredFields.email = '';
        this.emailValidation = true;
      } else {
        this.msg['email'] = 'Invalid email address.';
        // this.error = true;
        this.loginRequiredFields.email = 'p-error';
        this.emailValidation = false;
      }
      this.error = false;
    },

  },

  computed: {
    usernameError() {
      if (this.form.username == '') {
        return 'Email is required.';
      } else if (this.form.username) {
        return '';
      }
    },
    passwordError() {
      if (this.form.password === '') {
        return 'Password is required.';
      } else {
        if (this.form.password.length < 8) {
          return 'Your password must be at least 8 characters.';
        } else if (this.form.password.search(/[a-z]/i) < 0) {
          return 'Your password must contain at least one letter.';
        } else if (this.form.password.search(/[0-9]/) < 0) {
          return 'Your password must contain at least one digit.';
        }
      }
    },
    ...mapGetters({ resetEmail: 'auth/resetPasswordEmail' }),
  },
};
</script>

<style></style>
