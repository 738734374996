import {fetchClientList} from '../../api/TjAdminClientService';

const state = {
    clientList: [],
    isClientListLoaded: false,
    noDataFoundMsg: null,
};

const mutations = {
    SAVE_CLIENT_LIST(state, res) {
        state.clientList = res;
    },
    IS_CLIENT_LIST_LOADED(state, status) {
        state.isClientListLoaded = status;
    },
    NO_DATA_FOUND_MSG(state, msg) {
        state.noDataFoundMsg = msg;
    }
};

const actions = {
    actionGetClientList(state, filterObj) {
        state.commit('IS_CLIENT_LIST_LOADED', false);
        console.log("filter action===>", filterObj)
        let bodyData = {
            account_status: filterObj.account,
            payment_status: filterObj.payment
        }
        return fetchClientList(filterObj.duration, bodyData).then(resBody => {
            state.commit('IS_CLIENT_LIST_LOADED', true);
            state.commit('NO_DATA_FOUND_MSG', null);
            if (resBody.status === 200) {
                state.commit('NO_DATA_FOUND_MSG', null);
                console.log('clientList resBody', resBody);
                state.commit('SAVE_CLIENT_LIST', resBody.data.allClientList);
                return resBody;
            } else {
                console.log("client else action===>", resBody.response);
                state.commit('NO_DATA_FOUND_MSG', resBody && resBody.response && resBody.response.data && resBody.response.data.errors);
                state.commit('SAVE_CLIENT_LIST', []);
                return resBody.response;

            }
        });
    },

};

const getters = {
    clientList: state => state.clientList,
    isClientListLoaded: state => state.isClientListLoaded,
    noDataFoundMsg: state => state.noDataFoundMsg,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
