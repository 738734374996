export const ProjectType = Object.freeze({
    MOBILE: "mobile",
    WEB: "web",
    ANDROID: "android",
    IOS: "ios",
  });

export const MobileProjectAppType = Object.freeze({
  DEVICE: "device",
  APK: "apk",
  PACKAGE: 'package',
  IPA_APP: 'ipa/app'
});
