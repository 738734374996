export const GUEST_MENU = {
    // LOGIN: "/login",
    FORGOT_PASS: "/forget-password",
    SUCCESS: "/success",
    LOGIN_PAGE: "/login",
    SIGNUP_PAGE: "/signup",
    CHECKEMAIL_PAGE: "/check-email",
    VERIFY_EMAIL_PAGE: "/verification-email",
    VERIFY_EMAIL: "/verify-email",
    FORGET_PASSWORD: "/forget_password",
    RESET_PASSWORD: "/reset-password",
    SETUP_ACCOUNT: "/set-up-account"
};

export const TESTJET_ADMIN_MENU = {
    TJ_ADMIN_DASHBOARD: "/tj-admin-dashboard",
};

export const TESTJET_USER_MENU = {
    TJ_USER_DASHBOARD: "/tj-user-dashboard",
};
