// eslint-disable vue/no-mutating-props

// eslint-disable vue/no-mutating-props
<template>
  <Dialog :draggable="false" header="Screenshot Comparison" v-model:visible="/* eslint-disable vue/no-mutating-props */isVisible" :modal="isVisible"
          :breakpoints="{'960px': '95vw', '640px': '100vw'}" :style="{width: '80vw'}"
          @hide="$emit('hide')">
    <!-- TestCase Dialog -->
    <div class="step-screenshot-comparison">
      <div class="card">
        <div v-if="!isComparisonDataLoaded">
          <Loader />
        </div>
        <div v-else class="screenshot-comparison-wrapper">
          <div class="screenshot-container">
            <div class="captured-screenshot">
              <img v-if="testSteps.screenshot_src" class="image" alt="Logo" :src="testSteps.screenshot_src"/>
              <h4>Initial View (Captured)</h4>
            </div>
            <div class="automation-screenshot">
              <img v-if="testSteps.automation_screenshot_src" class="image" alt="Logo"
                   :src="testSteps.automation_screenshot_src"/>
              <h4>Last Execution View (Automation)</h4>
            </div>
          </div>

          <div class="test-description-wrapper">
            <div class="testcase-info-header-wrapper">
              <div class="testcase-info-header-bottom">
                <div class="testcase-base-config-info-left">
                  <div class="testcase-info-header-left">
                    <div class="testcase-info">
                      <div class="testcase-name">
                        <i class="fas fa-mouse"></i>
                        <p>{{ testSteps.description }}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="testcase-base-config-info-right">
                                <div class="base-config-right-element">
                                    <Button icon="fab fa-jira" class="p-button-rounded p-button-outlined testrail-icon">
                                        <img class="image" alt="Logo" src="/assets/layout/images/testrailLogo.png" />
                                    </Button>
                                    <Button icon="fab fa-jira" class="p-button-rounded p-button-outlined jira-icon">
                                        <img class="image" alt="Logo" src="/assets/layout/images/JiraLogo.png" />
                                    </Button>
                                </div>
                            </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script>
import Loader from '@/components/Loader.vue';
export default {
  components: {
    Loader
  },
  name: 'ComparisonAutomationDialog',
  props: {
    isVisible: {
      type: Boolean,
      default: true,
    },
    testSteps: {
      type: Object,
      default: {},
    },
    isComparisonDataLoaded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },

  created() {
    console.log(this.testSteps)
  },

  mounted() {
  },

  watch: {
    testSteps(elel) {
      console.log("🦊🦊🦊🦊🦊🦊🦊🦊🦊🦊🦊🦊🦊🦊🦊🦊🦊🦊🦊🦊🦊🦊🦊🦊🦊🦊 ", elel);
    }
  },

  methods: {},

  computed: {},
};
</script>
<style scoped></style>
