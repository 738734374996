<template>
    <div class="card sms_validation-container">
        <div class="sms_validation-wrapper">
            <section class="top">
                <div class="input_field">
                    <div class="p-inputgroup flex-1">
                        <span class="p-inputgroup-addon">Receiver</span>
                        <Dropdown v-model="validationInfo['number']" :options="generatedNumbers" optionValue="number"
                            optionLabel="number" placeholder="Select mobile number" />
                    </div>
                    <Button class="p-ml-3" label="RUN" :disabled="!validationInfo.number"
                        @click="sendMockTest('run_sms')" />
                </div>
                <Button v-if="componentMode === 'create' && !existingData?.is_drafted && !existingData?._id" label="ADD TO STEP" @click="SAVE('addToStep')" />
                <Button v-else-if="componentMode === 'update' && existingData?.createdAt && !existingData?.is_drafted" label="UPDATE" @click="SAVE('updateStep')" />
                <Button v-else-if="existingData?.is_drafted && existingData?.createdAt" label="Published" @click="SAVE('addToStepFromDraft')" />
                <Button v-else-if="existingData && !existingData?.createdAt" label="SAVE"  @click="SAVE('updateRunTime')" />
                <div>
                    <Button v-if="existingData && !existingData?.createdAt" class="saveDraft" label="Save as draft" @click="SAVE('updateAsDraftRunTime')" />
                    <Button v-else-if="existingData && existingData?.createdAt" class="saveDraft" label="Save as draft" @click="SAVE('udraft')" />
                    <Button v-else class="saveDraft" label="Save as draft" @click="SAVE('addToStep')" />
                </div>
            </section>

            <section class="section2">
                <div class="timeout_info" style="margin-left: 0">
                    <i class="fas fa-clock"></i>
                    <p>Override Timeout :</p>
                    <InputNumber v-model="validationInfo['timeout']" inputId="milliseconds" suffix=" ms" :min="1000"
                        :max="10000" placeholder="Step Timeout (milliseconds)" />
                </div>
                <AvailableVariables :componentVariables="variables" />
            </section>

            <section class="section3">
                <span>
                    <h4>Assertion</h4>
                </span>
                <span>
                    <h6><strong>Note:</strong> Assertion will run before additional code when both are there.</h6>
                    <i class="fas fa-info-circle"></i>
                </span>
                <SMSAssertion v-if="!loadingAssertionStatus" @assertionUpdated="assertionUpdated"
                    :allAssertions="assertions" :response="assertionResultResponse" />
                <section v-if="!loadingAssertionStatus">
                    <SMSBody v-if="!loadingAssertionStatus" :smsBody="smsBody" />
                    <div class="assertion-code-header">
                        <InputSwitch v-model="runCallbackScript" />
                        <h6>Run additional code on request results</h6>
                    </div>
                    <AssertionCode v-if="!loadingAssertionStatus && callback_script"
                        @updateAssertionCode="setCallBackScript" :assertionCode="callback_script"
                        :response="scriptResultResponse" />
                </section>

                <span v-if="loadingAssertionStatus">Loading...</span>
            </section>
            <Logs v-if="scriptResultResponse && !loadingAssertionStatus" :response="scriptResultResponse" />
        </div>
    </div>
</template>

<script>
import SMSAssertion from './SMSAssertion.vue';
import SMSBody from './SMSBody.vue';
import Logs from './Logs.vue';
import AvailableVariables from '../helperComponents/AvailableVariables.vue';
import AssertionCode from '../sms-validation/AssertionCode.vue';
import { getSelectedCurrentProjectId } from '@/utils/localstorage';
import { mapActions, mapGetters } from 'vuex';
import { mockTestSmsValidation } from '../../../dump/axios';

export default {
    name: 'SMSValidation',
    components: {
        AvailableVariables,
        SMSAssertion,
        SMSBody,
        AssertionCode,
        Logs,
    },
    data: () => ({
        // generatedNumbers: null,
        generatedNumbers: [],
        assertions: [],
        validationInfo: {
            number: null,
            assertions: [],
            timeout: 1000,
        },
        callback_script: null,
        runCallbackScript: false,
        variables: [],
        assertionResultResponse: null,
        scriptResultResponse: null,
        variableCount: 4,
        errors: {},
        componentMode: 'create', //* create / update
        sending: false,
        loadingAssertionStatus: false,
        smsBody: null,
        isEnableDraft: false,
    }),
    props: {
        existingData: {
            type: Object,
        },
        sendData: {
            type: Object || null,
        },
        platformType: {
            type: String
        },
        sockedConnected: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        this.setComponentVars();
        this.fetchGeneratedNumbers();

        if (this.existingData?.validationInfo) {
            console.log("this.existingData", this.existingData);
            const { validationInfo, error_obj, testCaseID } = this.existingData;
            console.log("👺👺👺👺👺👺👺👺👺👺👺👺👺👺👺👺👺👺👺👺👺👺👺👺👺👺👺👺", error_obj)
            this.validationInfo.number = validationInfo.number;
            this.validationInfo.timeout = validationInfo.timeout;
            this.callback_script = validationInfo.callback_script;
            this.runCallbackScript = validationInfo.runCallbackScript;

            this.assertions = validationInfo.assertions.length ? [...validationInfo.assertions] : [];
            this.dataLoaded = true;
            if (error_obj?.execution_result?.validationResult) {
                this.assertionResultResponse = error_obj.execution_result.validationResult.assertionResult;
                this.smsBody = error_obj.execution_result.validationResult.sms.body;
                this.scriptResultResponse = error_obj.execution_result.validationResult.scriptResult;
            }
            if (testCaseID) {
                this.componentMode = 'update';
                this.isEnableDraft = true;
            }
        } else {
            this.componentMode = 'create';
            this.smsBody = null;
            this.config = {};
            this.ms = 1000;
            this.callback_script = 'console.log(JSON.stringify(sms, null, 3));\n';
            this.runCallbackScript = false;
            this.dataLoaded = true;
        }
    },
    computed: {
        ...mapGetters({
            caseVariables: 'variable/caseVariables',
        }),
    },

    watch: {
        sendData: {
            deep: true,
            handler(value) {
                console.log('🥛 🥛 🥛 🥛 🥛 🥛 🥛 🥛 🥛 🥛 🥛 🥛 🥛 ', value);
                const { data, componentName } = value;
                if (componentName === 'smsValidation') {
                    const { validationResult, execution_time, type } = data;

                    this.loadingAssertionStatus = false;
                    this.apiValidationActiveMode = 'response';

                    this.assertionResultResponse = validationResult?.assertionResult;
                    if (validationResult?.sms?.body) {
                        console.log('=========================================================');
                        this.smsBody = validationResult?.sms?.body;
                    }
                    if (validationResult?.scriptResult) {
                        this.scriptResultResponse = validationResult?.scriptResult;
                    }
                    // this.assertions = validationInfo.assertions.length ? [...validationInfo.assertions] : [];
                    // this.callback_script = validationInfo?.callback_script?.length ? validationInfo.callback_script : '';
                }
            },
        },
        caseVariables() {
            this.setComponentVars();
        },
    },
    methods: {
        ...mapActions({
            getGeneratedNumbers: 'sms/actionGetPhoneNumberList',
        }),
        async fetchGeneratedNumbers() {
            if (getSelectedCurrentProjectId()) {
                await this.getGeneratedNumbers(getSelectedCurrentProjectId()).then((resBody) => {
                    console.log("🤬🤬🤬🤬🤬🤬🤬🤬🤬🤬🤬🤬🤬🤬🤬🤬🤬🤬🤬🤬🤬🤬🤬🤬🤬🤬", resBody);
                    this.generatedNumbers = resBody?.data?.phone_numbers;
                });
            } else {
                console.log('Please create or select project first.');
                // this.showWarningMsg('Please select project first.');
            }
        },

        async sendMockTest(actionType) {
            const data = {
                number: this.validationInfo.number,
                timeout: this.validationInfo.timeout,
                assertions: this.validationInfo.assertions,
                callback_script: this.callback_script,
                runCallbackScript: this.runCallbackScript,
                result: null,
                actionType: actionType,
            };

            if (actionType == 'run_sms') {
                this.sending = true;
                this.loadingAssertionStatus = true;
                this.assertions = this.validationInfo.assertions
            }

            if (!this.sockedConnected) return;

            const [resp, error] = await mockTestSmsValidation(data, this.platformType);
            console.log("👺👺👺👺👺👺👺👺👺👺👺👺👺👺👺👺👺👺👺👺👺👺", resp)

            if (resp?.data?.execution_result?.validationResult) {
                const { assertionResult, scriptResult, sms } = resp.data.execution_result.validationResult;
                this.smsBody = sms.body;

                if (assertionResult) this.assertionResultResponse = assertionResult;
                if (scriptResult) this.scriptResultResponse = scriptResult;
            }

            this.sending = false;
            this.loadingAssertionStatus = false;

            this.$emit('onSendMockSMSTest', data);
        },

        SAVE(actionType) {
            const obj = {
                // ...(this.existingData ? { existingStepId: this.existingData._id } : {}),
                number: this.validationInfo.number,
                timeout: this.validationInfo.timeout,
                assertions: this.validationInfo.assertions,
                callback_script: this.callback_script,
                runCallbackScript: this.runCallbackScript,
                actionType: actionType,
                ...(actionType === 'draft' ? { is_drafted: true } : {}),
                response: null,
            };

            if (Object.keys(this.errors).length > 0) {
                for (let i = 0; i < Object.values(this.errors).length; i++) {
                    const er = Object.values(this.errors)[i];
                    this.$toast.add({ severity: 'error', summary: 'ERROR', detail: er, life: 3000 });
                }
                return;
            }

            if (actionType === 'addToStep' || actionType === 'updateStep' || actionType === 'addToStepFromDraft' || actionType === 'udraft') {
                this.$emit('save', { validationInfo: obj, step: this.existingData });
                this.sending = true;
                this.loadingAssertionStatus = true;
            } else  if (actionType === 'updateRunTime' || actionType === 'updateAsDraftRunTime' || actionType === 'draft'){
                this.$emit('updateRunTimeData', { validationInfo: obj, step: this.existingData });
            }

            console.log(obj);
        },

        setComponentVars() {
            this.variables = null;

            this.variables = this.caseVariables.map((varr) => {
                return {
                    _id: varr._id,
                    [varr.name]: varr.value,
                };
            });
        },

        setCallBackScript(value) {
            this.callback_script = value;
        },

        assertionUpdated(assertions) {
            console.log("assertions from sms validation ==== ", assertions);
            this.validationInfo.assertions = [...assertions];
        },

        async updatedCode(val) {
            try {
                const response = await JSON.parse(val);
                if ('jsonError' in this.errors) {
                    delete this.errors['jsonError'];
                }
            } catch (err) {
                const msg = err.message;
                console.log(msg);
                if (msg?.toLowerCase()?.includes('unterminated string in json at position')) {
                    this.errors = { ...this.errors, jsonError: `Unterminated string found in json, please rechheck the request body` };
                } else {
                    this.errors = { ...this.errors, jsonError: msg };
                }
            }
        },
    },
};
</script>

<style lang="scss" src="@/styles/smsValidation.scss" />
