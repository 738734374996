import AutoComplete from 'primevue/autocomplete';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Button from 'primevue/button';
import Breadcrumb from 'primevue/breadcrumb';
import Calendar from 'primevue/calendar';
import Card from 'primevue/card';
import Carousel from 'primevue/carousel';
import Chart from 'primevue/chart';
import Checkbox from 'primevue/checkbox';
import Chips from 'primevue/chips';
import ColorPicker from 'primevue/colorpicker';
import Column from 'primevue/column';
import ContextMenu from 'primevue/contextmenu';
import DataTable from 'primevue/datatable';
import DataView from 'primevue/dataview';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import Fieldset from 'primevue/fieldset';
import FileUpload from 'primevue/fileupload';
// import FullCalendar from 'primevue/fullcalendar';
import InlineMessage from 'primevue/inlinemessage';
import Inplace from 'primevue/inplace';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import Galleria from 'primevue/galleria';
import Listbox from 'primevue/listbox';
import MegaMenu from 'primevue/megamenu';
import Menu from 'primevue/menu';
import Menubar from 'primevue/menubar';
import Message from 'primevue/message';
import MultiSelect from 'primevue/multiselect';
import OrderList from 'primevue/orderlist';
import OrganizationChart from 'primevue/organizationchart';
import OverlayPanel from 'primevue/overlaypanel';
import Paginator from 'primevue/paginator';
import Panel from 'primevue/panel';
import PanelMenu from 'primevue/panelmenu';
import Password from 'primevue/password';
import PickList from 'primevue/picklist';
import ProgressBar from 'primevue/progressbar';
import Rating from 'primevue/rating';
import RadioButton from 'primevue/radiobutton';
import Toast from 'primevue/toast';
import SelectButton from 'primevue/selectbutton';
import Slider from 'primevue/slider';
import Sidebar from 'primevue/sidebar';
import SplitButton from 'primevue/splitbutton';
import Steps from 'primevue/steps';
import TabMenu from 'primevue/tabmenu';
import TieredMenu from 'primevue/tieredmenu';
import Textarea from 'primevue/textarea';

import Toolbar from 'primevue/toolbar';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import ToggleButton from 'primevue/togglebutton';

import Tree from 'primevue/tree';
import TreeTable from 'primevue/treetable';
import TriStateCheckbox from 'primevue/tristatecheckbox';

import Knob from 'primevue/knob';
import Chip from './components/Chip.vue';
import Divider from 'primevue/divider';
import Tag from 'primevue/tag';
import ProgressSpinner from 'primevue/progressspinner'
import Skeleton from 'primevue/skeleton'

import Avatar from 'primevue/avatar';



export default {
    Skeleton,
    Avatar,
    ProgressSpinner,
    AutoComplete,
    Accordion,
    AccordionTab,
    Button,
    Breadcrumb,
    Calendar,
    Card,
    Carousel,
    Chart,
    Checkbox,
    Chips,
    ColorPicker,
    Column,
    ContextMenu,
    DataTable,
    DataView,
    DataViewLayoutOptions,
    Dialog,
    Dropdown,
    Fieldset,
    FileUpload,
    // FullCalendar,
    InlineMessage,
    Inplace,
    InputMask,
    InputNumber,
    InputSwitch,
    InputText,
    Galleria,
    Listbox,
    MegaMenu,
    Menu,
    Menubar,
    Message,
    MultiSelect,
    OrderList,
    OrganizationChart,
    OverlayPanel,
    Paginator,
    Panel,
    PanelMenu,
    Password,
    PickList,
    ProgressBar,
    Rating,
    RadioButton,
    Toast,
    SelectButton,
    Slider,
    Sidebar,
    SplitButton,
    Steps,
    TabMenu,
    TieredMenu,
    Textarea,
    Toolbar,
    TabView,
    TabPanel,
    ToggleButton,
    Tree,
    TreeTable,
    TriStateCheckbox,
    Knob,
    Chip,
    Divider,
    Tag
}
