<template>
  <div class="assertion-code">
    <div class="stat_section">
      <span class="status passed"
        v-if="response && 'executionResponse' in response && response?.isSuccess === true">Passed</span>
      <span class="status failed"
        v-if="response && 'executionResponse' in response && response?.isSuccess === false">Failed</span>
    </div>
    <div class="header" style="justify-content: start;">
      <p>
        <span class="command">function</span> assertion (email: <span>any</span>) {
      </p>
    </div>
    <div class="body">
      <editor :value="value" @code="(e) => { value = e }" @validJsCode="(e) => { invalidCodeBlock = !e }" />
    </div>
    <div class="footer">
      <p>}</p>
    </div>
  </div>
</template>

<script>
import Editor from './Editor.vue'
export default {
  name: "assertion-code",
  components: {
    Editor
  },
  props: {
    assertionCode: {
      type: String,
      default: '',
    },
    response: {
      type: Object || null,
      default: null,
    }
  },
  created() {
    if (this.assertionCode) {
      this.value = this.assertionCode;
    } else {
      this.value = 'console.log(JSON.stringify(email, null, 3));\n'
    }
  },
  watch: {
    value(val) {
      this.$emit('updateAssertionCode', val)
    },
    // assertionCode(val) {
    //   // console.log('callback_script callback_script callback_script', val)
    //   this.value = val
    // },
  },
  data: () => ({
    invalidCodeBlock: false,
    value: '',
  })
}
</script>
