<template>
  <div class="exception-body  error">
    <div class="exception-text">
      <div class="exception-box">
        <span>Error</span>
      </div>
      <span> Occured</span>
    </div>
    <div class="exception-panel">
      <div class="exception-image">
        <img src="assets/layout/images/exception/icon-error.png" alt="avalon-vue"/>
      </div>
      <div class="exception-panel-content">
        <div class="information-text">
          <h3>An error occured.</h3>
          <p>Please contact system administrator.</p>
        </div>
        <Button label="Go To Dashboard" @click="goDashboard"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goDashboard() {
      window.location = "/"
    }
  }
}
</script>

<style scoped>

</style>