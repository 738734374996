<template>
  <div class="new-auth-component-layout">
    <div class="container">
      <div class="logo-container">
        <img src="/assets/layout/images/logo-finale.png" alt="logo" @click="goToHome"/>
      </div>
      <div class="body">
        <slot></slot>
      </div>
      <div class="footer">
        <p>
          {{ `Copyright &copy; ${copyrightDate} TestJet All Rights Reserved` }}
        </p>
      </div>
    </div>
    <Toast />
  </div>
</template>

<script>
export default {
  data() {
    return {
      copyrightDate: ''
    }
  },
  mounted() {
    fetch('https://worldtimeapi.org/api/timezone/Europe/London')
      .then(data => data.json())
      .then(r => {
        this.copyrightDate = new Date(r.datetime).getFullYear()
      })
      .catch(err => {
        this.copyrightDate = new Date().getFullYear()
      })
  },
  methods: {
    goToHome() {
      this.$router.push('/')
    }
  },
}
</script>

<style>

</style>
