// eslint-disable vue/no-mutating-props

// eslint-disable vue/no-mutating-props
<template>
  <Dialog :draggable="false" header="Screenshot Comparison" v-model:visible="vis" :modal="true" :style="{ width: '50vw' }"
          @hide="$emit('hide')">
    <!-- TestCase Dialog -->
<!--      <pre>{{ testStep }}</pre>-->
    <div class="step-screenshot-comparison-mobile">
      <div class="card">
        <div class="screenshot-comparison-wrapper">
          <div class="screenshot-container">
            <div class="captured-screenshot">
              <img v-if="testStep && testStep.screenshot.uri" class="image" alt="Logo" :src="src(testStep.screenshot.uri)"/>
              <h4>Initial View (Captured)</h4>
            </div>
            <div class="automation-screenshot">
              <img class="image" alt="Logo" v-if='testStep.screenshot && testStep.screenshot.executedImgUrl'
                   :src="src(testStep.screenshot.executedImgUrl)"/>

              <img class="image" alt="Logo execution result" v-else-if='testStep.executionResult && testStep.executionResult.screenshot'
                   :src="src(testStep.executionResult.screenshot.uri)"/>

              <img class="image" alt="Logo no img" v-else :src="noImg"/>

              <h4>Last Execution View (Automation)</h4>
            </div>
          </div>

          <div class="test-description-wrapper">
            <div class="testcase-info-header-wrapper">
              <div class="testcase-info-header-bottom">
                <div class="testcase-base-config-info-left">
                  <div class="testcase-info-header-left">
                    <div class="testcase-info">
                      <div class="testcase-name">
                        <i class="fas fa-mouse"></i>
                        <p>{{ `${testStep.eventType} on ${testStep.selectors.class.split('.')[2]}` }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script>
export default {
  components: {},
  name: 'ComparisonAutomationDialogMobile',
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    testStep: {
      type: Object,
      default: null,
    },
    execSteps: {
        type: Array,
        default: [],
    }
  },
  watch: {
    isVisible(val) {
      this.vis = val
    }
  },
  data() {
    return {
      noImg: process.env.VUE_APP_NOT_EXECUTED_STEP_IMAGE,
      vis: false,
    };
  },

  created() {
  },

  mounted() {
      console.log({ testStep: this.testStep })
  },

  methods: {
    src(uri) {
      if(uri.includes('data:image/png;base64')) {
        return uri

      } else {
        return `${process.env.VUE_APP_MOBILE_SERVICE_HOST}/api/v1${uri}`
      }

    }
  },

  computed: {},
};
</script>
<style lang="scss" scoped>
.step-screenshot-comparison-mobile {
    .card {
        height: 100%;
        background-color: #fff;
        padding: 0;
        margin: 0;

        .screenshot-comparison-wrapper {
            background-color: #fff;
            width: 100%;
            height: 90%;
            flex: 100%;
            display: flex;
            padding: 0 2rem;
            flex-direction: column;
            .screenshot-container {
                display: flex;

                justify-content: space-between;

                .captured-screenshot {
                    flex: 50%;
                    padding-right: 20px;

                    h4 {
                        color: #282828;
                        font-size: 16px;
                        font-weight: bold;
                        text-align: center;
                        margin: 12px 0 0 0;
                    }

                    .image {
                        max-width: 540px;
                        width: 100%;

                        min-height: auto;
                        height: 500px;

                        margin-top: 3px;
                        object-fit: contain;
                        border: 1px solid #dee2e6;
                    }
                }

                .automation-screenshot {
                    padding-left: 20px;

                    flex: 50%;
                    h4 {
                        color: #282828;
                        font-size: 16px;
                        font-weight: bold;
                        text-align: center;
                        margin: 12px 0 0 0;
                    }
                    .image {
                        min-height: auto;

                        max-width: 540px;
                        width: 100%;

                        height: 500px;

                        margin-top: 3px;
                        object-fit: contain;
                        border: 1px solid #dee2e6;
                    }
                }
            }

            .test-description-wrapper {
                margin-top: 40px;
                .testcase-info-header-wrapper {
                    .testcase-info-header-bottom {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        height: 64px;

                        border-top: 1px solid #dee2e6;
                        border-bottom: 1px solid #dee2e6;

                        .testcase-base-config-info-left {
                            display: flex;
                            justify-content: space-between;
                            .testcase-info-header-left {
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                .chevron-left-icon {
                                    width: 40px;
                                    height: 40px;

                                    .p-button-rounded {
                                        width: 40px;
                                        height: 40px;
                                        border: 1px solid #dee2e6;
                                        color: #00a0ee;
                                    }
                                }

                                .testcase-info {
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;
                                    align-items: center;

                                    .testcase-name {
                                        display: flex;
                                        justify-content: space-between;
                                        align-items: center;
                                        .p-divider.p-component.p-divider-vertical.p-divider-solid.p-divider-center:before {
                                            // height: 10px;
                                            // width: .2px;
                                        }

                                        p {
                                            margin: 0 0 0 12px;
                                        }

                                        i {
                                            // margin-left: 46px;
                                        }
                                    }
                                }
                            }
                        }
                        .testcase-base-config-info-right {
                            .base-config-right-element {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                position: relative;
                                z-index: 99;

                                label {
                                    margin-right: 16px;
                                    padding: 3px 16px;
                                    background-color: #edf1f6;
                                    border-radius: 100px;
                                    font-size: 12px;
                                }

                                i {
                                    font-size: 14px;
                                }
                                .bug-icon {
                                    margin-right: 8px;
                                    // @include p-button-rounded(32px, 32px, #d64564, #d64564, 14px);
                                }
                                .jira-icon {
                                    // @include p-button-rounded(32px, 32px, #dee2e6, #d64564, 14px);
                                    img {
                                        height: 18px;
                                        width: 18px;
                                    }
                                }

                                .testrail-icon {
                                    margin-right: 8px;
                                    // @include p-button-rounded(32px, 32px, #dee2e6, #d64564, 14px);
                                    img {
                                        height: 34px;
                                        width: 34px;
                                    }
                                }

                                .issue-type-wrapper {
                                    width: 192px;
                                    // height: 288px;
                                    position: absolute;
                                    top: 34px;

                                    box-shadow: 0px 3px 6px #00000029;
                                    border: 1px solid #dee2e6;
                                    border-radius: 4px;
                                    background-color: #ffffff;

                                    .issue-type-header {
                                        padding: 11px 0px 11px 24px;
                                        border: 1px solid #dee2e6;
                                    }

                                    .issue-type-content {
                                        ul {
                                            margin: 0;
                                            padding: 0;
                                            list-style: none;
                                        }

                                        li {
                                            display: flex;
                                            justify-content: space-between;
                                            align-items: center;
                                            height: 40px;
                                            padding: 10px 24px;

                                            i {
                                                color: #00a0ee;
                                                font-size: 16px;
                                            }
                                            &:hover {
                                                background-color: #f5fafc;
                                                cursor: pointer;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
