import {
  deleteScheduleRun,
  deleteTestRun,
  fetchBrowserWiseData,
  fetchCloudRunStatusofTestRun,
  fetchNativeTestRunsReports,
  fetchStepComparisonData,
  fetchStepComparisonData2,
  fetchTestRunDetailsByID,
  fetchTestRunList,
  fetchTestRunLogsList,
  fetchTestRunLogsListNative,
  fetchTestRunsReports,
  insertTestRun,
  insertTestRunAutomation,
  updateTestRun,
  sendNativeAutomationResultToServer,
} from '../../api/TestRunService';

const state = {
  testRunList: [],
  testRunReports: {},
  testRunDetailsInfo: {},
  selectedTestRunInfo: {},
  stepComparisonData: [],
  localAutomationResult: null,
  browserWiseData: [],
  testCaseList: [],
  filterDuration: '7',
  loadReportForid: '',
  isTestRunListLoaded: false,
  isTestRunLogsListLoaded: false,
  isTestRunReportsLoaded: false,
  isTestRunDetailsLoaded: false,
  isBrowserWiseDataLoaded: false,
  isStepComparisonDataLoaded: false,
  noDataFoundMSg: null,
};

const mutations = {
  FETCH_TEST_RUN_LIST(state, res) {
    state.testRunList = res;
  },
  FETCH_TEST_RUN_REPORTS_LIST(state, res) {
    state.testRunReports = res;
  },
  FETCH_TEST_RUN_DETAILS_LIST(state, res) {
    state.testRunDetailsInfo = res;
  },
  GET_SELECTED_TEST_RUN_INFO(state, res) {
    state.selectedTestRunInfo = res;
  },
  FETCH_TEST_CASE_LIST(state, res) {
    state.testCaseList = res;
  },
  SET_TEST_CASES(state, testCases) {
    state.testCaseList = testCases;
  },
  FILTER_DURATION(state, duration) {
    state.filterDuration = duration;
  },
  IS_TESTRUNLIST_LOADED(state, status) {
    state.isTestRunListLoaded = status;
  },
  IS_TESTRUNLOGSLIST_LOADED(state, status) {
    state.isTestRunLogsListLoaded = status;
  },
  IS_BROWSERWISE_DATA_LOADED(state, status) {
    state.isBrowserWiseDataLoaded = status;
  },
  FETCH_BROWSERWISE_DATA(state, res) {
    state.browserWiseData = res;
  },
  IS_TESTRUNREPORTS_LOADED(state, status) {
    state.isTestRunReportsLoaded = status;
  },
  IS_TESTRUNDETAILS_LOADED(state, status) {
    state.isTestRunDetailsLoaded = status;
  },
  NO_DATA_FOUND_MSG(state, msg) {
    state.noDataFoundMSg = msg;
  },
  LOAD_REPORT_FOR_ID(state, value) {
    state.loadReportForid = value;
  },
  FETCH_STEP_COMPARISON_DATA(state, res) {
    state.stepComparisonData = res;
  },
  LOCAL_AUTOMATION_RESULT(state, res) {
    state.localAutomationResult = res;
  },
  IS_STEP_COMPARISON_DATA_LOADED(state, status) {
    state.isStepComparisonDataLoaded = status;
  },
};

const actions = {
  //---Test Run
  actionInsertTestRun(state, testRunObj) {
    return insertTestRun(testRunObj); //method from service
  },

  loadReportForId(state, id) {
    state.commit('LOAD_REPORT_FOR_ID', id);
  },

  actionFetchStepComparisonData(state, reqData) {
    state.commit('IS_STEP_COMPARISON_DATA_LOADED', false);
    console.log("Fetching action step comparison data for ======> ", reqData);

    fetchStepComparisonData(reqData)
      .then(resBody => {
        state.commit('IS_STEP_COMPARISON_DATA_LOADED', true);
        if (resBody.status === 200) {
          // console.log("⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐", resBody);
          resBody.data.testSteps[0].automated_test_step_info.test_step_id.automation_screenshot_src = resBody.data.localAutomationResult?.automation_screenshot_src ? resBody.data.localAutomationResult.automation_screenshot_src : null;
          state.commit('NO_DATA_FOUND_MSG', null);
          state.commit('FETCH_STEP_COMPARISON_DATA', resBody && resBody.data && resBody.data.testSteps);
          state.commit('LOCAL_AUTOMATION_RESULT', resBody && resBody.data && resBody.data.localAutomationResult);
          console.log("actionFetchStepComparisonData ===> ", resBody);
        } else {
          console.log('resBody', resBody.response);
          state.commit('FETCH_STEP_COMPARISON_DATA', []);
          state.commit('NO_DATA_FOUND_MSG', resBody && resBody.response && resBody.response.data && resBody.response.data.message && resBody.response.data.message.MSG_SHORT);
        }
      })
      .catch(err => {
        console.log('err', err);

        state.commit('FETCH_STEP_COMPARISON_DATA', []);
        state.commit('IS_STEP_COMPARISON_DATA_LOADED', true);
        state.commit('NO_DATA_FOUND_MSG', null);
        return err
      });
  },

  async actionFetchStepComparisonData2(state, reqData) {
    // console.log("Fetching action step comparison data for ======> ", reqData);
    let response = [];

    try {
      const res = await fetchStepComparisonData2(reqData);
      const ss = res.data?.native_test_steps?.length ? res.data.native_test_steps[0].automation_screenshot_src : '';
      response = [ss, null];
    } catch (err) {
      response = [null, err];
    } finally {
      console.log("⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐", response);
      return response;
    }
  },

  actionFetchTestRunList(state, reqDataObj) {
    state.commit('IS_TESTRUNLIST_LOADED', false);
    state.commit('FILTER_DURATION', reqDataObj.duration);
    fetchTestRunList(reqDataObj.projectID, reqDataObj.duration)
      .then(resBody => {
        state.commit('IS_TESTRUNLIST_LOADED', true);
        if (resBody.status === 200) {
          state.commit('NO_DATA_FOUND_MSG', null);
          state.commit('FETCH_TEST_RUN_LIST', resBody && resBody.data && resBody.data.runStatusObj && resBody.data.runStatusObj.executed_runs);
        } else {
          console.log('resBody', resBody.response);
          state.commit('FETCH_TEST_RUN_LIST', []);
          // state.commit('NO_DATA_FOUND_MSG', resBody && resBody.response && resBody.response.data && resBody.response.data.message && resBody.response.data.message.MSG_SHORT);
          state.commit('NO_DATA_FOUND_MSG', `No test runs found on this duration`);
        }
      })
      .catch(err => {
        console.log('err', err);

        state.commit('FETCH_TEST_RUN_LIST', []);
        state.commit('IS_TESTRUNLIST_LOADED', true);
        state.commit('NO_DATA_FOUND_MSG', null);
      });
  },

  async actionFetchTestRunLogsList(state, reqData) {
    state.commit('IS_TESTRUNLOGSLIST_LOADED', false);

    console.log("🇧🇩 🇧🇩 🇧🇩 🇧🇩 🇧🇩 🇧🇩 🇧🇩 🇧🇩 🇧🇩 🇧🇩 🇧🇩 🇧🇩 🇧🇩 => ", reqData);
    let response = [null, null];

    try {
      const res = await Promise.all([fetchTestRunLogsListNative(reqData), fetchTestRunLogsList(reqData)])
      const [ nativesRes, cloudsRes ] = res

      console.log("🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 ", {"natives": nativesRes, "clouds": cloudsRes});

      // cloudsRes.data.testRuns.forEach((run, i)=>{
      //   run.browsers = {
      //     chrome: false,
      //     firefox: false,
      //     edge: false
      //   }
      //   run.executed_browsers_runwise.forEach((browser_data, i)=> {
      //     switch (browser_data.browser_name) {
      //       case 'chrome':
      //         run.browsers.chrome = true
      //         break;
      //       case 'firefox':
      //         run.browsers.firefox = true
      //         break;
      //       case 'edge':
      //         run.browsers.edge = true
      //         break;
      //     }
      //   })
      // })
      let mergedLists = [
        ...(nativesRes.data?.native_test_runs ? nativesRes.data.native_test_runs : []),
        ...(cloudsRes.data?.cloud_test_runs ? cloudsRes.data.cloud_test_runs : [])
      ];

      mergedLists.sort(function(a, b) {
        let da = new Date(a.updatedAt).getTime();
        let db = new Date(b.updatedAt).getTime();

        return db - da;
      })

      response = [mergedLists, null]

    } catch (err) {
      console.log("🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 ", err.response);
      response = [null, err.response]

    } finally {
      return response
    }

  },

  async actionSendExecutedAutomationResult(state, reqData) {

    let response = [null, null];
    console.log("🇧🇩 🇧🇩 🇧🇩 🇧🇩 🇧🇩 🇧🇩 🇧🇩 🇧🇩 🇧🇩 🇧🇩 🇧🇩 🇧🇩 🇧🇩 => ", reqData);

    try {
      const res = await sendNativeAutomationResultToServer(reqData);
      console.log("🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 ", res.data);
      response = [res.data, null]

    } catch (err) {
      console.log("🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 ", err.response);
      response = [null, err.response]

    } finally {
      return response
    }
  },

  actionFetchTestRunReports(state, reqData) {
    state.commit('IS_TESTRUNREPORTS_LOADED', false);
    console.log("Fetching Test run logs list for ======> ", reqData);

    fetchTestRunsReports(reqData)
      .then(resBody => {
        state.commit('IS_TESTRUNREPORTS_LOADED', true);
        if (resBody.status === 200) {
          state.commit('NO_DATA_FOUND_MSG', null);
          state.commit('FETCH_TEST_RUN_REPORTS_LIST', resBody && resBody.data);
        } else {
          console.log('resBody', resBody.response);
          state.commit('FETCH_TEST_RUN_REPORTS_LIST', []);
          state.commit('NO_DATA_FOUND_MSG', resBody && resBody.response && resBody.response.data && resBody.response.data.message && resBody.response.data.message.MSG_SHORT);
        }
        return "getValue"
      })
      .catch(err => {
        console.log('err', err);

        state.commit('FETCH_TEST_RUN_REPORTS_LIST', []);
        state.commit('IS_TESTRUNREPORTS_LOADED', true);
        state.commit('NO_DATA_FOUND_MSG', null);
        return err
      });
  },

  async actionFetchNativeRunReports(state, runId = '63295f6a47be31212ee80035') {
    console.log("🇧🇩 🇧🇩 🇧🇩 🇧🇩 🇧🇩 🇧🇩 🇧🇩 🇧🇩 🇧🇩 🇧🇩 🇧🇩 🇧🇩 🇧🇩 => ", runId);

    // array [data, err] initially both will be null;
    let response = [null, null];

    try {
      const res = await fetchNativeTestRunsReports(runId)

      console.log('🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 ', res.data);
      response = [res.data, null];

    } catch (err) {
      console.log('🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 ', err);
      response = [null, err.response];

    } finally {
      return response
    }

  },

  actionFetchBrowserWiseData(state, reqData) {
    console.log("Fetching Test run logs list for ======> ", reqData);

    const res = fetchBrowserWiseData(reqData)
    return res
  },

  actionUpdateTestRun(state, testRunObj) {
    let res = updateTestRun(testRunObj); //method from service
    return res;
  },

  actionDeleteTestRun(state, testRunId) {
    let res = deleteTestRun(testRunId); //method from service
    return res;
  },

  actionDeleteScheduleRun(state, testRunIds) {
    let res = deleteScheduleRun(testRunIds); //method from service
    return res;
  },

  actionFetchTestRunDetailsByID(state, testRunId) {
    state.commit('IS_TESTRUNDETAILS_LOADED', false);

    return new Promise((resolve, reject) => {
      fetchTestRunDetailsByID(testRunId)
        .then(resBody => {
          state.commit('IS_TESTRUNDETAILS_LOADED', true);
          if (resBody.status === 200) {
            let filteredTestCaseList = [];
            console.log("❄️ ❄️ ❄️ ❄️ ❄️ ❄️ ❄️ ❄️ ❄️ ❄️ ❄️ ❄️ ❄️ ❄️ ", resBody.data);
            resBody.data.testRunResult.map(item => {
              if (item.type === 'group') {
                filteredTestCaseList.push(...item.children);
              } else {
                filteredTestCaseList.push(item);
              }
            });
            resolve();
            state.commit('FETCH_TEST_CASE_LIST', filteredTestCaseList);
            state.commit('FETCH_TEST_RUN_DETAILS_LIST', resBody.data);
          } else {
            state.commit('FETCH_TEST_RUN_DETAILS_LIST', resBody);
          }
        })
        .catch(err => {
          state.commit('IS_TESTRUNDETAILS_LOADED', true);

          reject(err);
        });
    });
  },

  actionResetTestRunDetails(state) {
    console.log("====================>>>>>>>>>>>>>>>>>>>>> RESET DATA");
    state.commit('IS_TESTRUNDETAILS_LOADED', false);
    state.commit('FETCH_TEST_CASE_LIST', []);
    state.commit('FETCH_TEST_RUN_DETAILS_LIST', {});
  },

  // eslint-disable-next-line no-unused-vars
  actionSelectedtestRunInfo({state, commit}, reqDataObj) {
    state.commit('GET_SELECTED_TEST_RUN_INFO', reqDataObj);
  },
  //--- END Test Run

  // Test Run Automation

  actionInsertTestRunAutomation(state, insertData) {
    return insertTestRunAutomation(insertData); //method from service
  },

  actionClearTestRunList({commit}) {
    commit('FETCH_TEST_RUN_LIST', [])
  },

  async actionFetchCloudRunStatusofTestRun(testRunId) {
    let id = testRunId.getters.testRunDetailsInfo.testRunObj._id;
    let res = await fetchCloudRunStatusofTestRun(id);
    if (res.status === 200) {
      return res.data;
    }
  }
};

const getters = {
  loadReportForId: state => state.loadReportForid,
  testRunReports: state => state.testRunReports,
  testRunList: state => state.testRunList,
  testRunDetailsInfo: state => state.testRunDetailsInfo,
  selectedTestRunInfo: state => state.selectedTestRunInfo,
  testCaseList: state => state.testCaseList,
  filterDuration: state => state.filterDuration,
  isTestRunListLoaded: state => state.isTestRunListLoaded,
  isTestRunLogsListLoaded: state => state.isTestRunLogsListLoaded,
  noDataFoundMSg: state => state.noDataFoundMSg,
  isTestRunDetailsLoaded: state => state.isTestRunDetailsLoaded,
  getBrowserWiseData: state => state.browserWiseData,
  isBrowserWiseDataLoaded: state => state.isBrowserWiseDataLoaded,
  isStepComparisonDataLoaded: state => state.isStepComparisonDataLoaded,
  getStepComparisonData: state => state.stepComparisonData,
  getLocalAutomationResult: state => state.localAutomationResult,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
