import axios from 'axios';
import {AuthServiceBaseUrl} from '../base_urls';
import interceptors from '../interceptors';

const AuthServiceRequest = axios.create({
    baseURL: AuthServiceBaseUrl,
    timeout: 30000,
});
interceptors(AuthServiceRequest, 'authService');
export default AuthServiceRequest;
