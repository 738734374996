<template>
  <div class="card email_validation-container overlay-modal-readOnly">
    <div class="url_validation-wrapper" v-if="dataLoaded">
      <section class="top-modal">
        <div class="input_field">
          <div class="p-inputgroup flex-1">
              <span class="p-inputgroup-addon">Receiver</span>
              <InputText v-model="validationInfo.email" optionValue="email" optionLabel="email" />
          </div>
        </div>
      </section>

      <section class="section3">
          <span class="second">
              <h4>Assertion</h4>
          </span>
          <EmailAssertion v-if="!loadingAssertionStatus" @assertionUpdated="assertionUpdated" :allAssertions="validationInfo.assertions" :response="assertionResultResponse" />
          <section>
              <div v-if="!loadingAssertionStatus" class="assertion-code">
                <div class="head">
                    <h6>Email Body</h6>
                </div>
                <div class="body">
                  <div v-html="decodeHtml"></div>
                    <!-- <Textarea :value="responseBody?.email ? responseBody?.email :'' " rows="5" cols="30" /> -->
                </div>
              </div>
              <div v-if="!loadingAssertionStatus" class="assertion-code-header">
                  <InputSwitch v-model="runCallbackScript" />
                  <h6>Run additional code on request results</h6>
              </div>
              <AssertionCode v-if="!loadingAssertionStatus && callback_script" @updateAssertionCode="setCallBackScript" :assertionCode="callback_script" :response="scriptResultResponse"/>
          </section>

          <span v-if="loadingAssertionStatus">Loading...</span>
        </section>
      <Logs v-if="scriptResultResponse && !loadingAssertionStatus" :response="scriptResultResponse" />
    </div>
    <div v-else>Loading...</div>
  </div>
</template>

<script>
import EmailAssertion from './EmailAssertion.vue';
import Logs from './Logs.vue';
import AssertionCode from './AssertionCode.vue';

export default {
  name: 'EmailValidationModal',
  components: {
    EmailAssertion,
    AssertionCode,
    Logs,
  },
  data: () => ({
    dataLoaded: false,
    validationInfo: {
      email : null,
      assertions: [],
      timeout: 1000
    },
    callback_script: null,
    runCallbackScript : false,
    assertionResultResponse: null,
    scriptResultResponse: null,
    responseBody: null,
    sending: false,
    loadingAssertionStatus: false,
  }),
  props: {
    existingData: {
      type: Object || null,
    },
  },
  mounted() {
    console.log(this.existingData);

    if (this.existingData?.validationInfo) {
      this.componentMode = 'update';
      const { validationInfo, error_obj } = this.existingData;
      this.validationInfo.email = validationInfo.email;
      this.validationInfo.timeout = validationInfo.timeout;
      this.callback_script = validationInfo.callback_script;
      this.runCallbackScript = validationInfo.runCallbackScript;
      this.validationInfo.assertions = validationInfo.assertions;

      this.dataLoaded = true;
    }
    else {
      this.componentMode = 'create';
      this.sqlQuery = null;
      this.config = {};
      this.ms = 1000;
      this.callback_script = 'console.log(JSON.stringify(email, null, 3));\n';
      this.runCallbackScript = false;
      this.dataLoaded = true;
    }

    if (this.existingData?.error_obj?.execution_result?.validationResult) {

      const { assertionResult, scriptResult } = this.existingData.error_obj.execution_result?.validationResult
      this.responseBody = this.existingData.error_obj.execution_result.validationResult

      if (assertionResult) this.assertionResultResponse = assertionResult;
      if (scriptResult) this.scriptResultResponse = scriptResult;
    }

  },
  computed: {
    decodeHtml() {
      if (this.responseBody?.email?.html_data){
        return decodeURIComponent(this.responseBody.email.html_data)
      }
      return '<p></p>'
    }
  },
}
</script>

<style lang="scss" src="@/styles/emailValidation.scss" />
