import TestServiceRequest from '../utils/request/test_request';
import MobileServiceRequest from '../utils/request/mobile_request';
import AuthServiceRequest from "../utils/request/auth_request";
import { result } from 'lodash';

//---START Issue related
export const insertIssueType = async (data) => {
    console.log('issue-save-obj', data);
    return await AuthServiceRequest.post(`/issue-types/save`, data).then(res => {
        return res;
    }).catch(error => {
        console.log('save-issue-Error', error);
    })
}

export const fetchIssueTypeList = async () => {
    return await AuthServiceRequest.get(`/issue-types/list`).then(res => {
        return res;
    }).catch(error => {
        console.log('get-issueList-Error', error);
    })
}

export const updateIssueType = async (data) => {
    console.log('issue-update-obj', data);
    return await AuthServiceRequest.put(`/issue-types/update`, data).then(res => {
        return res;
    }).catch(error => {
        console.log('save-issue-Error', error);
    })
}

export const deleteIssueType = async (reqDataObj) => {
    return await AuthServiceRequest.delete(`/issue-types/Delete`, { data: reqDataObj }).then(res => {
        return res;
    }).catch(error => {
        console.log('delete-issue-Error', error);
    })
}
//---END Issue related

//---START Resolution related
export const insertResolution = async (data) => {
    console.log('resolution-save-obj', data);
    return await AuthServiceRequest.post(`/screen-resolution/save`, data).then(res => {
        return res;
    }).catch(error => {
        console.log('save-resolution-Error', error);
    })
}

export const fetchResolutionList = async () => {
    return await AuthServiceRequest.get(`/screen-resolution/list`).then(res => {
        return res;
    }).catch(error => {
        console.log('get-resolution-Error', error);
    })
}

export const fetchResolutionListByCaseId = async (testCaseId) => {
    return await AuthServiceRequest.get(`/screen-resolution/list/by/${testCaseId}`).then(res => {
        return res;
    }).catch(error => {
        console.log('get-resolution-Error', error);
    })
}

export const updateResolution = async (data) => {
    console.log('resolution-update-obj', data);
    return await AuthServiceRequest.put(`/screen-resolution/update`, data).then(res => {
        return res;
    }).catch(error => {
        console.log('save-resolution-Error', error);
    })
}

export const deleteResolution = async (reqDataObj) => {
    return await AuthServiceRequest.delete(`/screen-resolution/delete`, { data: reqDataObj }).then(res => {
        return res;
    }).catch(error => {
        console.log('delete-resolution-Error', error);
    })
}

//---END Resolution related

//---START Access related

export const insertAccess = async (data) => {
    console.log('resolution-save-obj', data);
    return await AuthServiceRequest.post(`/accesses/save`, data).then(res => {
        return res;
    }).catch(error => {
        console.log('save-resolution-Error', error);
    })
}

export const fetchAccessList = async () => {
    return await AuthServiceRequest.get(`/accesses/list`).then(res => {
        return res;
    }).catch(error => {
        console.log('get-resolution-Error', error);
    })
}

export const updateAccess = async (data) => {
    console.log('resolution-update-obj', data);
    return await AuthServiceRequest.put(`/accesses/update`, data).then(res => {
        return res;
    }).catch(error => {
        console.log('save-resolution-Error', error);
    })
}

export const deleteAccess = async (reqDataObj) => {
    return await AuthServiceRequest.delete(`/accesses/delete`, { data: reqDataObj }).then(res => {
        return res;
    }).catch(error => {
        console.log('delete-resolution-Error', error);
    })
}

//---END Access related

//---START Role related

export const insertRole = async (data) => {
    console.log('role-save-obj', data);
    return await AuthServiceRequest.post(`/roles/save`, data).then(res => {
        return res;
    }).catch(error => {
        console.log('save-role-Error', error);
        return error
    })
}

export const fetchRoleList = async () => {
    return await AuthServiceRequest.get(`/roles/list`).then(res => {
        return res;
    }).catch(error => {
        console.log('get-role-Error', error);
    })
}

export const updateRole = async (data) => {
    console.log('role-update-obj', data);
    return await AuthServiceRequest.put(`/roles/update`, data).then(res => {
        return res;
    }).catch(error => {
        console.log('save-role-Error', error);
    })
}

export const deleteRole = async (reqDataObj) => {
    return await AuthServiceRequest.delete(`/roles/delete`, { data: reqDataObj }).then(res => {
        return res;
    }).catch(error => {
        console.log('delete-role-Error', error);
    })
}

//---END Role related

//--Invite Member

export const memberInvite = async memberInfo => {
    return await AuthServiceRequest.post('/member/add', memberInfo)
        .then(res => {
            console.log('member invite api===>', res)
            return res;
        })
        .catch(error => {
            return error.response;
        });
}

export const manageOrganizationMember = async memberInfo => {
    return await AuthServiceRequest.post('/member/assign-members', memberInfo)
    .then(res => {
        console.log('member invite api res ====> ', res);
        return res;
    })
    .catch(error => {
        return error;
    })
}

export const removeMember = async memberId => {
    return await AuthServiceRequest.post('/member/remove', memberId)
        .then(res => {
            console.log("member remove api===>", res);
            return res;
        })
        .catch(error => {
            return error.response;
        })
}

//--New Single Project Member Invite
export const inviteMemberSingleProject = async data => {
    return await TestServiceRequest.post(`/projects/${data.projectId}/assign-members`, data)
        .then(res => {
            console.log("👀👀 member invite api res====>", res);
            return res;
        })
        .catch(error => {
            return error.response
        })
}
//--New Single Project Member Invite

// Old Single Project Member Remove API
// export const removeSingleMember = async access => {
//     return await TestServiceRequest.put('/projects/remove-single-member', access)
//         .then(res => {
//             console.log("single member remove api===>", res);
//             return res;
//         })
//         .catch(error => {
//             return error.response;
//         })
// }

// New Single Project Member Remove API
export const removeSingleMember = async data => {
    return await TestServiceRequest.delete(`/projects/${data.project_id}/members/${data.member_id}`)
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response;
        })
}
export const removeSingleMemberMobile = async access => {
    return await MobileServiceRequest.patch('/projects/members/remove-single', access)
        .then(res => {
            console.log("single member remove api Mobile===>", res);
            return res;
        })
        .catch(error => {
            return error.response;
        })
}

export const updateMember = async selectedMemberInfo => {
    return await AuthServiceRequest.put('/member/update', selectedMemberInfo)
        .then(res => {
            console.log("member update api===>", res);
            return res;
        })
        .catch(error => {
            return error.response;
        })
}

export const fetchMembers = async () => {
    return await AuthServiceRequest.get('/member/info')
        .then(res => {
            console.log('members api', res)
            return res;
        })
        .catch(error => {
            return error.response
        })
}

// Old Single Project Member Fetch API
// export const fetchSingleProjectMembers = async memberIds => {
//     return await AuthServiceRequest.post('/member/info/single-project', memberIds)
//         .then(res => {
//             console.log('project member api', res);
//             return res;
//         })
//         .catch(error => {
//             return error.response;
//         })
// }

// New Single Project Member Fetch API
export const fetchSingleProjectMembers = async projectId => {
    return await TestServiceRequest.get(`/projects/${projectId}/members`)
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response;
        })
}