import {fetchCityList, fetchCountryList, fetchSuperAdminInfo, userProfileSetup} from '../../api/ProfileService';

const state = {
    countryList: [],
    cityList: [],
    superAdmin: {},
};
const mutations = {
    FETCH_COUNTRY_LIST(state, res) {
        state.countryList = res;
    },
    FETCH_CITY_LIST(state, res) {
        state.cityList = res;
    },
    FETCH_SUPER_ADMIN_INFO(state, res) {
        state.superAdmin = res;
    }
};
const actions = {
    actionFetchCountryList(state) {
        return fetchCountryList()
            .then((resBody) => {
                console.log("action from country===>", resBody);
                if (resBody.status === 200) {
                    state.commit("FETCH_COUNTRY_LIST", resBody.data.country);
                    return resBody.data;
                } else {
                    state.commit("FETCH_COUNTRY_LIST", resBody.data);
                }
            })
            .catch(error => {

            })
    },

    actionFetchCityList(state, countryId) {
        return fetchCityList(countryId)
            .then((resBody) => {
                console.log("action from city===>", resBody);
                if (resBody.status === 200) {
                    state.commit("FETCH_CITY_LIST", resBody.data.city);
                    return resBody.data;
                } else {
                    state.commit("FETCH_CITY_LIST", resBody.data);
                    return resBody.data;
                }
            })
            .catch(error => {

            })
    },

    actionFetchSuperAdminInfo(state) {
        return fetchSuperAdminInfo()
            .then((resBody) => {
                console.log("action from super admin===>", resBody);
                if (resBody.status === 200) {
                    state.commit("FETCH_SUPER_ADMIN_INFO", resBody.data.admin);
                    return resBody.data.admin;
                } else {
                    state.commit("FETCH_SUPER_ADMIN_INFO", resBody.data);
                    return resBody.data;
                }
            })
    },

    async actionUserProfileSetup(state, userProfileInfo) {
        return userProfileSetup(userProfileInfo)
        // .then(res => {
        //     console.log('action from user profile update===>', res)
        //     if (res.status === 200) {
        //         console.log("⛽ ⛽ ⛽ ⛽ ⛽ ⛽ ⛽ ⛽ ⛽ if", res);
        //         return res;
        //     }
        //     else {
        //         console.log("⛽ ⛽ ⛽ ⛽ ⛽ ⛽ ⛽ ⛽ ⛽ else", res);
        //         return res;
        //     }
        // }).catch(err => {
        //     console.log("⛽ ⛽ ⛽ ⛽ ⛽ ⛽ ⛽ ⛽ ⛽ catch", res);
        //     return res;
        // })
    }
};

const getters = {
    countryList: state => state.countryList,
    cityList: state => state.cityList,
    superAdmin: state => state.superAdmin
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
