/*
    Embeded code from google analytics.
*/
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());

if(process.env.VUE_APP_ENVIRONMENT === 'stage'){
    gtag('config', 'G-JYM9M7B4H4');
}
else if(process.env.VUE_APP_ENVIRONMENT === 'production'){
    gtag('config', 'G-1NZM9EXXM7');
}

/*
    Google analytics widget's embed code!!
*/
let script = document.createElement('script')
script.type='text/javascript';
if(process.env.VUE_APP_ENVIRONMENT === 'stage'){
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-JYM9M7B4H4";
}
else if(process.env.VUE_APP_ENVIRONMENT === 'production'){
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-1NZM9EXXM7";
}
script.async = true;
document.head.appendChild(script)
