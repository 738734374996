/* eslint-disable no-unused-vars */
import DatabaseServiceRequest from '@/utils/request/db_java_request';

// START TEST_CASE SECTION


export const authenticateDatabase = async (data) => {
    return DatabaseServiceRequest.post(`/authenticate`, data)
};

// START TEST_STEPS SECTION
export const executeQuery = async (dataObj, token) => {
    return await DatabaseServiceRequest.post(`/connections/execute`, dataObj, { headers: { 'Authorization': `Bearer ${token}` }})
        .then(res => {
            return res;
        })
        .catch(error => {
            return error;
        });
};

export default DatabaseServiceRequest;
