<template>
  <div class="login-container">
    <div class="verify-email-header">VERIFY EMAIL</div>
    <div class="check-email-icon"><img src="/assets/layout/images/Check-Email.svg" alt=""/></div>
    <div class="check-email-container p-fluid">
      <div class="check-email-container-header" v-if="emailVerified">Email Verified Successfully</div>
      <div class="check-email-container-header" v-else-if="emailAlreadyVerified">Email Already Verified</div>
      <div class="check-email-link" v-else-if="linkExp">
        <h4>Email Verification Failed</h4>
        <p>The verification link is either invalid or has expired.</p>Please
        <a class="resendlink" @click="clickResendVerifyEmailHandler()"><u>click here</u></a> to request another
        verification link.
      </div>
      <!-- <div class="check-email-container-header" v-else>Email Verification Failed</div> -->
      <div class="check-email-container-detail" v-if="emailVerified || emailAlreadyVerified">
        Go to
        <router-link to="/login"><u> Sign In</u></router-link>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex';
import decode from 'jwt-decode';

export default {
  name: 'VerifyEmail',
  data() {
    return {
      emailVerified: false,
      linkExp: false,
      emailAlreadyVerified: false,
      resendEmail: false,
    };
  },
  created() {
    this.clickVerifyEmailHandler();
  },
  methods: {
    ...mapActions({
      sendVerifyEmailToLink: 'auth/actionVerifyEmailLink',
      resendVerifyEmailToLink: 'auth/actionResendVerifyEmailLink',
    }),

    showSuccess(summary, detail) {
      this.$toast.add({severity: 'success', summary: summary, detail: detail, life: 3000});
    },

    showError(detail) {
      this.$toast.add({severity: 'error', summary: 'Error Message', detail: detail, life: 3000});
    },

    clickVerifyEmailHandler() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const token = urlParams.get('token');
      let bodyData = {token: token};
      this.sendVerifyEmailToLink(bodyData)
          .then((resBody) => {
            if (resBody.status === 200) {
              this.emailVerified = true;
              this.showSuccess('Verified', 'Email Verified Successfully');
            } else if (resBody.status === 409) {
              this.emailAlreadyVerified = true;
              this.showSuccess('Already Verified', 'Email Already Verified');
            } else {
              this.emailVerified = false;
              this.linkExp = true;
              this.showError('The verification link is either invalid or has expired.');
            }
          })
          .catch((err) => {
            console.log(token);
            console.log('Verified email error', err.response);
          });
    },
    clickResendVerifyEmailHandler() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const token = urlParams.get('token');
      let userLoginInfo = decode(token);
      let bodyData = {email: userLoginInfo.email};
      this.resendVerifyEmailToLink(bodyData)
          .then((resBody) => {
            if (resBody.status === 200) {
              this.resendEmail = true;
              this.showSuccess('Resend', 'Email Sent');
            } else if (resBody.status === 409) {
              this.emailAlreadyVerified = true;
              this.showSuccess('Already Verified', 'Email Already Verified');
            } else {
              this.showError('Email Sending Failed');
            }
          })
          .catch((err) => {
            console.log('Verified email error', err.response);
          });
    },
  },
};
</script>

<style>
</style>