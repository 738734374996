<template>
  <div class="auth-component-layout-wrapper">
    <div class="container">
      <div class="body">
        <div class="left-panel">
          <div class="logo-title-container">
            <div class="logo-container">

            </div>

            <p class="title-container">
              Create Resillient end-to-end Test Automation
            </p>

          </div>

          <div class="image-container">
            <img src="/assets/layout/images/login_signup_resetPass__image.png" alt="login_signup_resetPass__image.png"
              class="img">
          </div>
        </div>
        <div class="right-panel">
          <slot></slot>
        </div>
      </div>
      <div class="footer">
        <p>
          {{ `Copyright &copy; ${copyrightDate} TestJet All Rights Reserved` }}
        </p>
      </div>
    </div>
    <Toast />
  </div>
</template>

<script>
export default {
  data() {
    return {
      copyrightDate: ''
    }
  },
  mounted() {
    fetch('https://worldtimeapi.org/api/timezone/Europe/London')
      .then(data => data.json())
      .then(r => {
        this.copyrightDate = new Date(r.datetime).getFullYear()
      })
      .catch(err => {
        this.copyrightDate = new Date().getFullYear()
      }) 
  },
}
</script>

<style>

</style>