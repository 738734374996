export default {
  data: () => ({

  }),
  created() { },
  methods: {
    getExports(message) {
      let exportedVariables = null;

      //* ALL TYPES OF VALIDATION STEPS EXCEPT API VALIDATION
      if (message?.error_obj?.validationResult?.scriptResult?.executionResponse?.result?.exports) {
        exportedVariables = message.error_obj.validationResult.scriptResult.executionResponse.result.exports

      } else {
        console.log("NO MAMA ++++++++ >>>> ", message);
      }

      return exportedVariables;
    },
    async updateCaseVariable(exportedVars) {
      console.log("⛺ ⛺ ⛺ ⛺ ⛺ ⛺ ⛺ ⛺ ⛺ ⛺ ⛺ ⛺ ⛺ ⛺ ⛺ ⛺ ⛺ ⛺ ⛺ ⛺ ⛺ ", exportedVars);

      if (!exportedVars) return;

      const newObjArray = [];
      console.log("⛎ ⛎ ⛎ ⛎ ⛎ ⛎ ⛎ ⛎ ⛎ ⛎ ⛎ ⛎ ", exportedVars);
      const varNames = Object.keys(exportedVars);

      for (let i = 0; i < varNames.length; i++) {
        const varName = varNames[i]
        const varValue = exportedVars[varName];
        const actualSavedVariable = this.caseVariables.filter(v => v.name == varName)[0];
        const existingVarId = actualSavedVariable ? actualSavedVariable._id : null;
        const existingVarScope = actualSavedVariable ? actualSavedVariable.scope : null;

        const obj = { id: existingVarId, name: varName, scope: existingVarScope, value: typeof varValue !== 'string' ? JSON.stringify(varValue, null, 3) : varValue };
        newObjArray.push(obj);
      }

      for (let v = 0; v < newObjArray.length; v++) {
        const el = newObjArray[v];

        if (el.id) {
          await this.actionUpdateTestCaseVariable({data: el, type: 'web'});
          // const [resp, er] = await this.actionUpdateTestCaseVariable(el);
          // console.log("🔰 🔰 🔰 🔰 🔰 🔰 🔰 🔰 🔰 🔰 🔰 🔰 🔰 🔰 🔰 🔰 🔰 ", resp, '----____________----', er);
        }
      }
      this.getVariables && await this.getVariables();
    },
  },
};


