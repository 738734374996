import {mapActions} from 'vuex';
import {getToken} from '@/utils/cookies';

export default {
    created() {
        this.getProjectsList();
        this.fetchResolution();
        this.$socket.auth.token = getToken();
        this.$socket.connect();

    },
    methods: {
        ...mapActions({
            getProjectsList: 'project/actionGetProjectsList',
            fetchResolution: 'TjAdminFeatures/actionFetchResolutionList',
        }),
    },
};
