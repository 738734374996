import {
    getOrganizationPackageConsumptionApi,
    getOrganizationApi,
    getOrganizationMembersApi,
    updateOrganizationApi
} from '../../api/OrganizationService';

const state = {
    consumption: '',
    organization: null,
    organizationMembers: null,
    organizationHasMembers: false,
    isTeamMemberListLoaded: false,
};

const mutations = {
    // mutate user
    CONSUMPTION_INFO(state, consumption) {
        state.consumption = consumption;
    },
    ORGANIZATION_INFO(state, organization) {
        state.organization = organization;
    },
    ORGANIZATION_HAS_MEMBERS(state, members) {
        state.organizationHasMembers = members;
    },
    IS_TEAM_MEMBER_LIST_LOADED(state, status) {
        state.isTeamMemberListLoaded = status;
    },
};
const actions = {
    async GetOrganizationPackageConsumption({ state, commit }, id) {
        //Fetch organization consumptionInfo info


        let response = [];
        try {
            const resp = await getOrganizationPackageConsumptionApi(id);
            response = [resp.data, null];
            commit('CONSUMPTION_INFO', resp.data);

        } catch (err) {
            response = [null, err.response];
            commit('CONSUMPTION_INFO', null);

        } finally {
            return response;
        }
    },

    async GetOrganizationData({ state, commit }, id) {
        let response = [];
        try {
            const resp = await getOrganizationApi(id);
            response = [resp.data.organization, null];
            commit('ORGANIZATION_INFO', resp.data.organization);

        } catch (err) {
            response = [null, err.response];
            commit('ORGANIZATION_INFO', null);

        } finally {
            return response;
        }
    },

    async GetOrganizationMembers({ state, commit }, id) {
        let response = [];

        try {
            const resp = await getOrganizationMembersApi(id);
            response = [resp, null];
            commit('IS_TEAM_MEMBER_LIST_LOADED', true);
            commit('ORGANIZATION_HAS_MEMBERS', true);
            this.organizationMembers = resp.data.memberList;

        } catch (err) {
            response = [null, err.response];
            this.organizationMembers = null;

        } finally {
            return response;
        }
    },

    updateOrganizationById({ state, commit }, data) {
        // new Promise((resolve,reject)=>{

        // })
        let id = data.id;
        delete data.id;
        return updateOrganizationApi(id, data).then(resBody => {
            console.log("org update action ====================", resBody)
            if (resBody.status.code === 200) {
                commit('ORGANIZATION_INFO', resBody.data.organization);
                return resBody;
            } else {
                commit("ORGANIZATION_INFO", null);
                return resBody.data;
            }
        })
        // let response = [];

        // try {
        //     const resp = await updateOrganizationApi(id,data);
        //     response = [resp, null];
        //     commit('ORGANIZATION_INFO', resp.data.organization);
        // } catch (err) {
        //     response = [null, err.response];
        // } finally {
        //     return response;
        // }
    },

};

const getters = {
    consumptionInfo: state => state.consumption,
    organizationData: (state) => state.organization,
    organizationMembers: (state) => state.organizationMembers,
    organizationHasMembers: (state) => state.organizationHasMembers,
    isTeamMemberListLoaded: (state) => state.isTeamMemberListLoaded,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
