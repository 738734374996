<template>
  <div class="header-main req-header">
    <div class="head">
      <Button label="Header" :class="type === 'header' ? 'active' : ''" @click="type = 'header'" />
      <!-- <Button label="Authorization" :class="type === 'auth' ? 'active' : ''" @click="type = 'auth'" /> -->
    </div>
    <div class="body">
      <div class="headers" v-if="type === 'header'">
        <span v-for="(header, i) in headers" :key="i">
          <small></small>
          <InputText v-model="header['header']" :disabled="true" placeholder="Content-Type" />
          <InputText v-model="header['value']" :disabled="true" placeholder="application/json" />
        </span>
      </div>
      <div class="authorizations" v-else-if="type === 'auth'">
        <small></small>
        <Dropdown v-model="authType" :options="auths" optionLabel="name" optionValue="code" class="w-full md:w-14rem"
          :disabled="true" />
        <InputText v-model="authValue" placeholder="token" :disabled="true" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "response-header",
  props: {
    allHeaders: {
      type: Array,
    },
    auth: {
      type: Object,
    }
  },
  created() {

  },
  mounted() {
    console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>",this.allHeaders);
    if (this.auth) {
      this.authType = this.auth.type;
      this.authValue = this.auth.value;
    }
    if (this.allHeaders) {
      const outputArray = [];

      for (const key in this.allHeaders) {
        if (this.allHeaders.hasOwnProperty(key)) {
          const header = key;
          const value = this.allHeaders[key].split(';')[0]; // Extract the part before the first semicolon

          outputArray.push({
            "header": header,
            "value": value.trim() // Remove leading/trailing spaces
          });
        }
      }

      // console.log("㊗️ ㊗️ ㊗️ ㊗️ ㊗️ ㊗️ ㊗️ ㊗️ ㊗️ ㊗️ ", outputArray);
      this.headers = outputArray;
      // this.dataloaded = true;
    } else {
      this.headers = [{
        header: '',
        value: '',
      }];
      // this.dataloaded = true;
    }
  },
  data: () => ({
    type: 'header', //* header, auth
    headers: [
      {
        checked: true,
        header: 'Content-Type',
        value: 'application/json',
      },
      {
        checked: true,
        header: '',
        value: '',
      },
    ],
    authEnabled: true,
    authType: 'basic',
    auths: [
      { name: 'BEARER', code: 'bearer' },
      { name: 'BASIC', code: 'basic' }
    ],
    authValue: '',
    dataloaded: false,
  }),
  watch: {
    // authType(val) {
    //   this.authValue = '';
    // },
  },
  methods: {

  },
}
</script>
