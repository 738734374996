<template>
    <div :class="containerClass" @click="onDocumentClick">
      <div class="layout-wrapper-content">
        <AppTopBarTJ
            :topbarMenuActive="topbarMenuActive"
            :profileMode="profileMode"
            :horizontal="layoutMode === 'horizontal'"
            :activeTopbarItem="activeTopbarItem"
            @menubutton-click="onMenuButtonClick"
            @topbar-menubutton-click="onTopbarMenuButtonClick"
            @topbar-item-click="onTopbarItemClick"
        />
        <!-- <div>hello</div> -->
  
        <transition name="layout-menu-container">
          <div :class="menuClass" @click="onMenuClick" v-show="isMenuVisible()">
            <div class="menu-scroll-content">
              <!-- <AppInlineProfile v-if="profileMode === 'inline' && layoutMode !== 'horizontal'" @profile-click="onProfileClick" :expanded="profileExpanded"></AppInlineProfile> -->
              <!-- <AppInlineDropDownTJ @newProjectSelected="newProjectSelected" /> -->
              <!-- <h3 style="font-size: 16px; font-weight: 600; padding: 16px 32px 0 18px;">Profile</h3> -->
              <AppMenu :model="menu" :layoutMode="layoutMode" :active="menuActive" @menuitem-click="onMenuItemClick"
                       @root-menuitem-click="onRootMenuItemClick"></AppMenu>
            </div>
          </div>
        </transition>
  
        <div class="layout-main">
          <div v-if="duration" :class="expireClass">
            <div class="p-grid">
              <div class="p-col-12 container">
                <div class="card">
                  <i class="fas fa-exclamation-triangle"></i>
                  <p v-if="showDurationDetails">
                    Your {{ subscriptionType }} will expire in
                    {{ durationValue > 1 ? durationValue + ' days' : durationValue + ' day' }}. Please click <a
                      href="https://testjet.ai/pricing/" target="_blank">here</a> to subscribe or talk to one of our
                    support agents.
                  </p>
                  <p v-if="!showDurationDetails">Your {{ subscriptionType }} has expired. Please click <a
                      href="https://testjet.ai/pricing/" target="_blank">here</a> to subscribe and reactivate your account or talk to our
                    support agents.</p>
                </div>
              </div>
            </div>
          </div>
          <transition name="fade" v-if="isNetworkAvailable">
            <router-view/>
          </transition>
          <internet-connection-page v-else/>
        </div>
  
        <!-- <AppConfig
            :layoutMode="layoutMode"
            @layout-change="onLayoutChange"
            :darkMenu="darkMenu"
            @menu-color-change="onMenuColorChange"
            :profileMode="profileMode"
            @profile-mode-change="onProfileModeChange"
            :layout="layout"
            :layouts="layoutColors"
            :layoutSpecialColors="layoutSpecialColors"
            @layout-color-change="onLayoutColorChange"
            :theme="theme"
            :themes="themeColors"
            @theme-change="onThemeChange"
        ></AppConfig> -->
  
        <div class="layout-mask"></div>
  
        <!-- <AppFooter /> -->
      </div>
  
      <Toast/>
    </div>
  </template>

  <script>
  import AppTopBarTJ from './AppTopbarTJ.vue';
  import AppInlineDropDownTJ from './AppInlineDropDownTJ.vue';
  import AppMenu from './AppMenu.vue';
  import EventBus from '@/event-bus';
  import {mapActions, mapGetters} from 'vuex';
  import dataFetchMixins from './mixins/data-fetch';
  import InternetConnectionPage from '../views/InternetConnectionPage.vue';
  import {ClientProfileMenu} from '@/utils/menu/ClientProfileMenu';
  import {TJSuperAdminMenu} from '@/utils/menu/TJSuperAdminMenu';
  import {TJAdminMenu} from '@/utils/menu/TJAdminMenu';
  import {TJSupportMenu} from '@/utils/menu/TJSupportMenu';
  import {ClientAdminMenu} from '@/utils/menu/ClientAdminMenu';
  import { ClientAdminMobileMenu } from '@/utils/menu/ClientAdminMobileMenu';
  import {ClientTesterMenu} from '@/utils/menu/ClientTesterMenu';
  import {UserMenu} from '@/utils/menu/UserMenu';
  import {FreeClientTesterMenu} from '@/utils/menu/FreeClientTesterMenu';
  
  const moment = require('moment');
  
  export default {
    mixins: [dataFetchMixins],
    data() {
      return {
        duration: false,
        durationValue: null,
        expireClass: '',
        showDurationDetails: null,
        isNetworkAvailable: true,
        layoutMode: 'static',
        profileMode: 'inline',
        overlayMenuActive: false,
        staticMenuDesktopInactive: false,
        staticMenuMobileActive: false,
        topbarMenuActive: false,
        activeTopbarItem: null,
        rotateMenuButton: false,
        darkMenu: false,
        menu: null,
        menuActive: false,
        profileExpanded: false,
        theme: 'blue',
        themeColors: [
          {name: 'Amber', file: 'amber', color: '#F8BD0C'},
          {name: 'Blue', file: 'blue', color: '#007bff'},
          {name: 'Cyan', file: 'cyan', color: '#17A2B8'},
          {name: 'Indigo', file: 'indigo', color: '#6610F2'},
          {name: 'Purple', file: 'purple', color: '#883cae'},
          {name: 'Teal', file: 'teal', color: '#20C997'},
          {name: 'Orange', file: 'orange', color: '#FD7E14'},
          {name: 'Deep Purple', file: 'deeppurple', color: '#612FBE'},
          {name: 'Light Blue', file: 'lightblue', color: '#4DA3FF'},
          {name: 'Green', file: 'green', color: '#28A745'},
          {name: 'Light Green', file: 'lightgreen', color: '#61CC79'},
          {name: 'Brown', file: 'brown', color: '#986839'},
          {name: 'Dark Grey', file: 'darkgrey', color: '#6C757D'},
          {name: 'Pink', file: 'pink', color: '#E83E8C'},
          {name: 'Lime', file: 'lime', color: '#74CD32'},
        ],
        layoutColors: [
          {name: 'Testjet', file: 'testjet', color1: 'red', color2: 'green'},
          {name: 'Blue', file: 'blue', color1: '#146fd7', color2: '#146fd7'},
          {name: 'Cyan', file: 'cyan', color1: '#0A616F', color2: '#0D7A8B'},
          {name: 'Indigo', file: 'indigo', color1: '#470EA2', color2: '#510DBE'},
          {name: 'Purple', file: 'purple', color1: '#391F68', color2: '#4E2A8F'},
          {name: 'Teal', file: 'teal', color1: '#136E52', color2: '#13956E'},
          {name: 'Pink', file: 'pink', color1: '#771340', color2: '#B12D69'},
          {name: 'Lime', file: 'lime', color1: '#407916', color2: '#569D21'},
          {name: 'Green', file: 'green', color1: '#1F8E38', color2: '#1F8E38'},
          {name: 'Amber', file: 'amber', color1: '#7A5E06', color2: '#C5980F'},
          {name: 'Brown', file: 'brown', color1: '#593E22', color2: '#6F4925'},
          {name: 'Orange', file: 'orange', color1: '#904100', color2: '#CB5C00'},
          {name: 'Deep Purple', file: 'deeppurple', color1: '#341A64', color2: '#46208E'},
          {name: 'Light Blue', file: 'lightblue', color1: '#14569D', color2: '#2A7BD1'},
          {name: 'Light Green', file: 'lightgreen', color1: '#2E8942', color2: '#3EA655'},
          {name: 'Dark Grey', file: 'darkgrey', color1: '#343A40', color2: '#424A51'},
        ],
        layoutSpecialColors: [
          // { name: 'Testjet', file: 'testjet', color1: '#ff0000', color2: '#008000' },
          {name: 'Influenza', file: 'influenza', color1: '#a83279', color2: '#f38e00'},
          {name: 'Calm', file: 'calm', color1: '#5f2c82', color2: '#0DA9A4'},
          {name: 'Crimson', file: 'crimson', color1: '#521c52', color2: '#c6426e'},
          {name: 'Night', file: 'night', color1: '#2c0747', color2: '#6441a5'},
          {name: 'Skyline', file: 'skyline', color1: '#2b32b2', color2: '#1488cc'},
          {name: 'Sunkist', file: 'sunkist', color1: '#ee8a21', color2: '#f2c94c'},
          {name: 'Little Leaf', file: 'littleleaf', color1: '#4DB865', color2: '#80D293'},
          {name: 'Joomla', file: 'joomla', color1: '#1e3c72', color2: '#2a5298'},
          {name: 'Firewatch', file: 'firewatch', color1: '#cb2d3e', color2: '#ef473a'},
          {name: 'Suzy', file: 'suzy', color1: '#834d9b', color2: '#d04ed6'},
        ],
        layout: 'testjet',
      };
    },
    watch: {
      $route() {
        this.menuActive = false;
        this.$toast.removeAllGroups();
      },
    },
    methods: {
      ...mapActions({
        fetchSettings: 'settings/fetchSettings',
        actionFetchAuthInfo: 'auth/actionFetchAuthInfo',
      }),
  
      durationDeatils() {
        console.log('hello==============================>', this.loggedInUserInfo);
        if (this.loggedInUserInfo && this.loggedInUserInfo.subscription_plan && this.loggedInUserInfo.subscription_plan.status_code != 0) {
          let client_info = this.loggedInUserInfo;
          let duration = moment(client_info && client_info.subscription && client_info.subscription.activeTill).diff({hours: 0}, 'days');
          // let duration = 12;
          let subscriptionType = client_info && client_info.subscription && client_info.subscription.name;
          console.log('duration=====>', duration);
          console.log('type===>', subscriptionType);
          if (duration < 14 && duration > 0) {
            this.duration = true;
            this.showDurationDetails = true;
            this.durationValue = duration;
            this.expireClass = 'expire-duration-msg';
            if (subscriptionType.toLowerCase().includes('free')) {
              this.subscriptionType = 'free trial';
            } else {
              this.subscriptionType = 'subscription';
            }
          } else if (duration <= 0) {
            this.duration = true;
            this.showDurationDetails = false;
            this.expireClass = 'expired-msg';
            if (subscriptionType.toLowerCase().includes('free')) {
              this.subscriptionType = 'free trial';
            } else {
              this.subscriptionType = 'subscription';
            }
          }
        }
      },
  
      onDocumentClick() {
        if (!this.topbarItemClick) {
          this.activeTopbarItem = null;
          this.topbarMenuActive = false;
        }
  
        if (!this.menuClick) {
          if (this.isHorizontal() || this.isSlim()) {
            this.menuActive = false;
            EventBus.emit('reset-active-index');
          }
  
          this.hideOverlayMenu();
        }
  
        this.topbarItemClick = false;
        this.menuClick = false;
      },
      onProfileClick(event) {
        this.profileExpanded = !this.profileExpanded;
        if (this.isHorizontal() || this.isSlim()) {
          EventBus.emit('reset-active-index');
        }
  
        event.preventDefault();
      },
      onMenuClick() {
        this.menuClick = true;
      },
      onMenuItemClick(event) {
        if (!event.item.items) {
          EventBus.emit('reset-active-index');
          this.hideOverlayMenu();
        }
        if (!event.item.items && this.isHorizontal()) {
          this.menuActive = false;
        }
      },
      onRootMenuItemClick() {
        this.menuActive = !this.menuActive;
      },
      onMenuButtonClick(event) {
        this.menuClick = true;
  
        this.rotateMenuButton = !this.rotateMenuButton;
        this.topbarMenuActive = false;
  
        let userRole = this.loggedInUserInfo && this.loggedInUserInfo.roleDetails && this.loggedInUserInfo.roleDetails.role;
  
        if (this.layoutMode === 'overlay') {
          this.overlayMenuActive = !this.overlayMenuActive;
        } else if (window.location.pathname === '/dashboard' && !userRole.includes('tj_')) {
          this.staticMenuDesktopInactive = true;
        } else {
          if (this.isDesktop()) this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
          else this.staticMenuMobileActive = !this.staticMenuMobileActive;
        }
        event.preventDefault();
      },
      onTopbarMenuButtonClick(event) {
        this.topbarItemClick = true;
        this.topbarMenuActive = !this.topbarMenuActive;
        this.hideOverlayMenu();
        event.preventDefault();
      },
      onTopbarItemClick(event) {
        this.topbarItemClick = true;
  
        if (this.activeTopbarItem === event.item) this.activeTopbarItem = null;
        else this.activeTopbarItem = event.item;
  
        event.originalEvent.preventDefault();
      },
      hideOverlayMenu() {
        this.rotateMenuButton = false;
        this.overlayMenuActive = false;
        this.staticMenuMobileActive = false;
      },
      isDesktop() {
        return window.innerWidth > 1024;
      },
      isHorizontal() {
        return this.layoutMode === 'horizontal';
      },
      isSlim() {
        return this.layoutMode === 'slim';
      },
      isMenuVisible() {
        if (this.isDesktop()) {
          if (this.layoutMode === 'static') return !this.staticMenuDesktopInactive;
          else if (this.layoutMode === 'overlay') return this.overlayMenuActive;
          else return true;
        } else {
          if (this.staticMenuMobileActive) return true;
          else if (this.layoutMode === 'overlay') return this.overlayMenuActive;
          else return true;
        }
      },
      onLayoutChange(layoutMode) {
        console.log('LayoutMode', layoutMode);
  
        this.layoutMode = layoutMode;
        this.staticMenuDesktopInactive = false;
        this.overlayMenuActive = false;
  
        if (this.isHorizontal()) {
          this.profileMode = 'top';
          this.profileMenuActive = false;
        }
      },
      onMenuColorChange(menuColor) {
        console.log('app-menuColor', menuColor);
  
        this.darkMenu = menuColor;
      },
      onProfileModeChange(profileMode) {
        this.profileMode = profileMode;
      },
      onLayoutColorChange(layout, special) {
        console.log('layout', layout);
  
        this.layout = layout;
        this.changeStyleSheetUrl('layout-css', layout, 'layout');
  
        if (special) {
          this.darkMenu = true;
        }
      },
      onThemeChange(theme) {
        console.log('Theme', theme);
  
        this.theme = theme;
        this.changeStyleSheetUrl('theme-css', theme, 'theme');
      },
      changeStyleSheetUrl(id, value, prefix) {
        let element = document.getElementById(id);
        let urlTokens = element.getAttribute('href').split('/');
        urlTokens[urlTokens.length - 1] = prefix + '-' + value + '.css';
        let newURL = urlTokens.join('/');
  
        this.replaceLink(element, newURL);
      },
      replaceLink(linkElement, href) {
        const id = linkElement.getAttribute('id');
        const cloneLinkElement = linkElement.cloneNode(true);
  
        cloneLinkElement.setAttribute('href', href);
        cloneLinkElement.setAttribute('id', id + '-clone');
  
        linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);
  
        cloneLinkElement.addEventListener('load', () => {
          linkElement.remove();
          cloneLinkElement.setAttribute('id', id);
        });
      },
      // newProjectSelected() {
      //   if(localStorage.getItem('projectType').toLowerCase().includes('mobile')) {
      //     this.menu = ClientAdminMobileMenu;
      //   } else {
      //     this.menu = ClientAdminMenu;
      //   }
      // }
    },
    computed: {
      containerClass() {
        return [
          'layout-wrapper',
          {
            'menu-layout-static': this.layoutMode !== 'overlay',
            'menu-layout-overlay': this.layoutMode === 'overlay',
            'layout-menu-overlay-active': this.overlayMenuActive,
            'menu-layout-slim': this.layoutMode === 'slim',
            'menu-layout-horizontal': this.layoutMode === 'horizontal',
            'layout-menu-static-inactive': this.staticMenuDesktopInactive,
            'layout-menu-static-active': this.staticMenuMobileActive,
            'p-input-filled': this.$appState.inputStyle === 'filled',
            'p-ripple-disabled': this.$primevue.ripple === false,
          },
        ];
      },
      menuClass() {
        return ['layout-menu-container', {'layout-menu-dark': this.darkMenu}];
      },
      // subscription() {
      //     console.log("info===>", this.loggedInUserInfo)
      // },
      ...mapGetters({
        jiraConfig: 'settings/jiraConfig',
        testRailsConfig: 'settings/testRailsConfig',
        projectID: 'project/projectID',
        loggedInUserInfo: 'auth/loggedInUserInfo',
      }),
    },
    components: {
      AppTopBarTJ: AppTopBarTJ,
      AppMenu: AppMenu,
      AppInlineDropDownTJ: AppInlineDropDownTJ,
      InternetConnectionPage,
    },
    updated() {
      // if(localStorage.getItem('projectType') && localStorage.getItem('projectType').toLowerCase().includes('mobile')) {
      //   this.menu = ClientAdminMobileMenu;
      // } else {
      //   this.menu = ClientAdminMenu;
      // }
    },
    mounted() {
      EventBus.on('network-failed', (data) => {
        this.isNetworkAvailable = false;
      });
  
      //Fetch all setting
      this.fetchSettings();
      // console.log(' this.loggedInUserInfo', this.loggedInUserInfo.roleDetails.role);
      const role = this.loggedInUserInfo && this.loggedInUserInfo.roleDetails && this.loggedInUserInfo.roleDetails.role;
      const subscriptionPackage = this.loggedInUserInfo && this.loggedInUserInfo.subscription_plan && this.loggedInUserInfo.subscription_plan.active_plan && this.loggedInUserInfo.subscription_plan.active_plan.title;
  
      console.log("👀👀👀👀", ClientProfileMenu)
  
      this.menu = ClientProfileMenu;
  
      this.durationDeatils();
    },
    created() {
      //this.actionFetchAuthInfo()
  
      // this section important for handle chrome runtime connect exception
      // handle global error as true
      window.onerror = function (errorMsg, url, lineNumber, column, errorObj) {
        return true;
      };
      //=======================================
    },
  };
  </script>
  