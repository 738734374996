<template>
  <div class="main_mobile_editor-component">
    <Android v-if="deviceType === 'android'" :thisProject="thisProject" />
    <Ios v-else-if="deviceType === 'ios'" :thisProject="thisProject" />
    <span class="loading" v-else>
      <div class="border-round border-1 surface-border p-4 surface-card">
        <div class="head flex mb-3">
          <Skeleton shape="circle" size="4rem" class="mr-2"></Skeleton>
          <div>
            <Skeleton width="4rem" class="line mb-2"></Skeleton>
            <Skeleton width="10rem" class="line mb-2"></Skeleton>
            <Skeleton height=".5rem" class="line "></Skeleton>
          </div>
          <br>
        </div>
        <div>
          <br>
          <Skeleton height="3rem"></Skeleton>
          <br>
        </div>
        <Skeleton width="100%" height="750px"></Skeleton>
        <!-- <div class="flex justify-content-between mt-3">
          <Skeleton width="4rem" height="2rem"></Skeleton>
          <Skeleton width="4rem" height="2rem"></Skeleton>
        </div> -->
      </div>
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Android from './MobileTestEditorPage/Android.vue';
import Ios from './MobileTestEditorPage/Ios.vue';

export default {
  name: 'MobileTestEditorPage',
  components: {
    Android,
    Ios,
  },
  data: () => ({
    thisProject: null,
    deviceType: '',
  }),
  mounted() {
    setTimeout(() => {
      if (!this.thisProject) {
        this.thisProject = this.projectList?.filter((prj) => prj._id == this.projectID)[0];
        this.deviceType = this.thisProject?.type?.toLowerCase()
      }
    }, 1500);
  },
  watch: {
    projectList(lists) {
      if (!this.thisProject) {
        this.thisProject = lists.filter((prj) => prj._id == this.projectID)[0];
        this.deviceType = this.thisProject?.type?.toLowerCase();
      }
    },
    // thisProject(val) {
    //   console.log("🅾️ 🅾️ 🅾️ 🅾️ 🅾️ 🅾️ 🅾️ 🅾️ 🅾️ 🅾️ 🅾️ 🅾️ 🅾️ 🅾️ 🅾️ 🅾️ 🅾️ 🅾️ 🅾️ 🅾️ ++++++++ ", val);
    // }
  },
  computed: {
    ...mapGetters({
      projectID: 'project/projectID',
      projectList: 'project/projectList',
    }),
  }
}
</script>

<style lang="scss" scoped>
.main_mobile_editor-component {
  // position: relative;

  span.loading {
    background: #ffffff;
    display: block;
    padding: 24px;

    .head {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      .line {
        margin-bottom: 5px;
      }
    }
  }
}
</style>
