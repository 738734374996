/* eslint-disable no-unused-vars */
import TestServiceRequest from '../utils/request/test_request';
import seleniumRequest from '../utils/request/selenium_request';
import axios from 'axios';

export const insertTestRun = async data => {
    return await TestServiceRequest.post(`/test-runs/save`, data)
        .then(res => {
            return res;
        })
        .catch(error => {
            return error
        });
};

export const fetchTestRunList = async (projectID, duration) => {
    return await TestServiceRequest.get(`/test-runs/list/by/project/${projectID}/${duration}`)
        .then(res => {
            return res;
        })
        .catch(error => {
            return error;
        });
};

export const fetchTestRunLogsList = async (runId) => {
    return await seleniumRequest.get(`api/v2/cloud-test-runs?recorded_test_run_id=${runId}`)
        .then(res => {
            return res;
        })
        .catch(error => {
            return error;
        });
};
export const fetchTestRunLogsListNative = async (runId) => {
    return await TestServiceRequest.get(`/v2/native-test-runs/by-recorded-run/${runId}`)
};

export const fetchTestRunsReports = async (runId) => {
    return await seleniumRequest.get(`/automated/test-runs/report/${runId}`)
        .then(res => {
            return res;
        })
        .catch(error => {
            return error;
        });
};

export const fetchNativeTestRunsReports = async (runId) => {
    return TestServiceRequest.get(`/v2/native-test-runs/${runId}`)
        // .then(res => {
        //     return res;
        // })
        // .catch(error => {
        //     return error;
        // });
};

export const fetchBrowserWiseData = async (automatedTestrunId) => {
    return await seleniumRequest.get(`/browser-wise-data/${automatedTestrunId}`)
        .then(res => {
            return res;
        })
        .catch(error => {
            return error;
        });
};

export const fetchStepComparisonData = async (data) => {
    const {aTestRunId, aTestCaseId, testStepId, testRunId, run} = data.data;
    // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", data.data);
    return await seleniumRequest.get(`/test-step-comparison/${testRunId}/${aTestRunId}/${aTestCaseId}/${testStepId}`)
        .then(res => {
            return res;
        })
        .catch(error => {
            return error;
        });
};

export const fetchStepComparisonData2 = async (data) => {
    const { testStepId } = data;
    // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", data.data);
    return await TestServiceRequest.get(`v2/native-test-steps?recorded_test_step_id=${testStepId}&__sort=-createdAt`)
    // return await seleniumRequest.get(`/test-step-comparison/${testRunId}/${aTestRunId}/${aTestCaseId}/${testStepId}`)
        .then(res => {
            return res;
        })
        .catch(error => {
            return error;
        });
};

export const deleteScheduleRun = async (arrIds) => {
    return await seleniumRequest.delete(`/schedule/delete`, {data: arrIds})
        .then(res => {
            console.log('res', res);
            return res;
        })
        .catch(error => {
            return error;
        });
};

export const updateTestRun = async data => {
    return await TestServiceRequest.put(`/test-runs/update`, data)
        .then(res => {
            return res;
        })
        .catch(error => {
            console.log('update-testcase-error', error);
        });
};

export const deleteTestRun = async testRunId => {
    console.log('testRunId-', testRunId);
    return await TestServiceRequest.delete(`/test-runs/delete/by/run/${testRunId}`)
        .then(res => {
            return res;
        })
        .catch(error => {
            console.log('delete-case-Error', error);
        });
};


export const fetchTestRunDetailsByID = async testRunId => {
    console.log('testRunId-', testRunId);
    return await TestServiceRequest.get(`/test-runs/details/by/run/${testRunId}`);
};

export const fetchCloudRunStatusofTestRun = async testRunId => {
    return await seleniumRequest.get(`/manage/cloud-run/details/${testRunId}`)
}

// END TEST_RUN SECTION

// TEST_RUN_AUTOMATION

export const insertTestRunAutomation = async data => {
    return await TestServiceRequest.post(`/automation-message/save/all/cases`, data)
        .then(res => {
            return res;
        })
        .catch(error => {
            console.log('run-automation--Error', error);
        });
};

export const sendNativeAutomationResultToServer = async (data) => {
    return TestServiceRequest.post(`/v2/native-test-runs`, data)
};

