<template>
  <div class="no-network-wrapper">
    <div class="card">
      <div class="logo">
        <img src="/assets/layout/images/TestJET-Logo-Final-3.png" alt=""/>
      </div>
      <p class="label"><b>Network Not Available</b></p>
      <div class="not-found-image">
        <img src="/assets/layout/images/Network-Not-Available.png" alt=""/>
      </div>
      <div class="details">
        <div>Please check your internet connection</div>
        <div>and try again</div>
      </div>
      <Button label="REFRESH PAGE" @click="refresh"/>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    refresh() {
      // window.history.back();
      window.location.reload();
    },
  },
};
</script>