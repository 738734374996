/* eslint-disable no-unused-vars */
import domEvents from '../utils/dom-events-to-record';
import customValidation from '../utils/custom_validation_list';
import {v4 as uuidv4} from 'uuid';
import {mongoObjectId} from '../utils/helpers';

export default class CaseBuilder {
    /**
     * Create case from extension with proper constructor data
     * @param {*} url
     * @param {*} selector
     * @param {*} attributes
     * @param {*} xpath
     * @param {*} xpath_collections
     * @param {*} bounding_react
     * @param {*} action
     * @param {*} value
     * @param {*} tag_name
     * @param {*} key_code
     * @param {*} href
     * @param {*} coordinates
     * @param {*} screenshot_src
     * @param {*} text
     * @param {*} name
     * @param {*} screen_size
     * @param {*} child_nodes
     * @param {*} parent_nodes
     * @param {*} broken_links
     */
    constructor(
        url = null,
        selector = null,
        attributes = [],
        xpath = null,
        xpath_collections = [],
        bounding_react = {},
        action = null,
        value = null,
        tag_name = null,
        key_code = null,
        href = null,
        coordinates = null,
        screenshot_src = null,
        tabInfo = null,
        variable_id = null,
        apiInfo = null,
        validationInfo = null,
        target = {},
        frameLocation = {},
        text = null,
        title = null,
        name = null,
        screen_size = {},
        child_nodes = [],
        parent_nodes = [],
        broken_links = [],
        frameIndex = null,
        eventInfo = null,
        is_drafted= false
    ) {
        this._url = url;
        this._xpath_collections = xpath_collections;
        this._selector = selector;
        this._xpath = xpath;
        this._text = text;
        this._title = title;
        this._type = action;
        this._value = value;
        this._key_code = key_code;
        this._x_axis = 0;
        this._y_axis = 0;
        this._position = null;
        this._screenshot_src = screenshot_src;
        this._tabInfo = tabInfo;
        this._variable_id = variable_id;
        this._apiInfo = apiInfo;
        this._validationInfo = validationInfo;
        this._is_drafted = is_drafted;
        this._target = target;
        this._frameLocation = frameLocation;
        this._name_attr = null;
        this._tag_name = tag_name;
        this._coordinates = coordinates;
        this._domTree = [];
        this._name = name;
        this._screen_size = screen_size;
        this._parent_nodes = parent_nodes;
        this._child_nodes = child_nodes;

        this._bounding_react = bounding_react;
        this._attributes = attributes;
        this._error_obj = {
            status: false,
            type: '', // warning/ error /failed
            execution_time: 0,
            message: '',
        };
        this._broken_links = broken_links;
        this._frameIndex = frameIndex;
        this._eventInfo = eventInfo;
    }

    /**
     * Get case description
     *
     * @param {*} _text
     * @returns
     */
    _getCaseDescription(_text) {

        var description = '';
        const {
            TextToAssign,
            Refresh,
            Sleep,
            CHECK_BROKEN_LINK,
            ValidateDatabse,
            API_VALIDATION,
            ValidateCssProperty,
            SMS_Validation,
            ValidateHtmlAttribute,
            ValidateElementVisible,
            ValidateElementNotVisible,
            ValidateCheckbox,
            ValidateRadioButton,
            Hover,
            Navigate,
            URL_Validation,
            Email_Validation,
            ValidateEmail,
            ValidateSMS,
            GENERATE_PHONE_NUMBER,
            OTP_VALIDATION,
            InsertVariable,
        } = customValidation;

        if (this._type != null) {
            switch (this._type) {
                case domEvents.CLICK:
                    var attr = this._attributes.length > 0 ? this._attributes.find((attribute) => attribute.type) : {type: ''};
                    console.log('this._attributes', this._attributes);
                    console.log(
                        'this.type',
                        this._attributes.filter((attribute) => {
                            return attribute.type !== undefined;
                        })
                    );
                    if (this._title) {
                        let len = this._title.length;
                        description = len > 0 ? `Click '${len > 30 ? String(this._title).substring(0, 30) + '....' : this._title}'` : 'Click';
                    }
                    else if (this._text) {
                        let len = _text.length;
                        description = len > 0 ? `Click '${len > 30 ? String(_text).substring(0, 30) + '....' : _text}'` : 'Click';
                    } else if (this._value){
                        if (attr && attr.type === 'checkbox') {
                            description = `Checkbox '${this._value}'`;
                        } else if (attr && attr.type === 'radio') {
                            description = `Radiobutton '${this._value}'`;
                        } else {
                            description = `Click '${this._value}'`;
                        }
                    }
                    else {
                        description = 'Click';
                    }
                    break;
                case domEvents.CHANGE:
                    description = `Change`;
                    break;
                case domEvents.SCROLL:
                    if (!this._coordinates.section) {
                        this._coordinates = {section: '#document', x: 0, y: 0};
                        console.log('_coordinates', this._coordinates);
                    }
                    this._coordinates.section == '#document' ? (description = 'Scroll on page') : (description = 'Scroll on ' + this._coordinates.section);
                    break;
                case domEvents.WHEEL:
                    description = 'Wheel';
                    break;
                case domEvents.RESIZE:
                    description = 'Resize';
                    break;
                case domEvents.DBLCLICK:
                    description = 'Double Click';
                    break;
                case domEvents.CONTEXTMENU:
                    if (!this._value) {
                        if (_text) {
                            description = String(_text).length > 0 ? `Right Click  '${_text.length > 30 ? String(_text).substring(0, 30) + '....' : _text}'` : 'Right Click ';
                        } else {
                            description = 'Right Click';
                        }
                    } else {
                        description = `Right Click '${this._value.length > 30 ? String(this._value).substring(0, 30) + '...' : this._value}'`;
                    }
                    break;
                case domEvents.SUBMIT:
                    description = 'Submit';
                    break;
                case domEvents.SELECT:
                    description = 'SELECT';
                    break;
                case 'keydown':
                    console.log("🤒 🤒 🤒 🤒 🤒 🤒 🤒 🤒 🤒 🤒 this 🤒 🤒 🤒 🤒 🤒 🤒 🤒 🤒 🤒 🤒 ", this)
                    if(this._key_code === 13) {
                        description = 'Enter';
                    } else if(this._key_code === 9) {
                        description = 'Tab';
                    } else {
                        description = 'Pressed'
                    }
                    break;
                case domEvents.INPUT:
                    if (this._title && this._tag_name.toLowerCase() === 'select') {
                        description = `Select '${ this._title}'`;
                    } else {
                        description = `Set text`;
                    }
                    break;
                case TextToAssign.action:
                    description = _text.length > 0 ? `Validate text '${_text.length > 30 ? String(_text).substring(0, 30) + '....' : _text}'` : 'Validate text';

                    break;
                case ValidateCssProperty.action:
                    description = ValidateCssProperty.name;
                    break;
                // case AddCustomValidation.action:
                //     description = AddCustomValidation.name;
                //     break;
                case ValidateHtmlAttribute.action:
                    description = ValidateHtmlAttribute.name;
                    break;
                case ValidateElementVisible.action:
                    description = ValidateElementVisible.name;
                    break;
                case ValidateElementNotVisible.action:
                    description = ValidateElementNotVisible.name;
                    break;
                case Refresh.action:
                    description = Refresh.name;
                    break;
                case CHECK_BROKEN_LINK.action:
                    description = CHECK_BROKEN_LINK.name;
                    break;
                case ValidateDatabse.action:
                    description = ValidateDatabse.name;
                    break;
                case API_VALIDATION.action:
                    description = API_VALIDATION.name;
                    break;
                case Hover.action:
                    description = Hover.name;
                    break;
                case ValidateCheckbox.action:
                    description = ValidateCheckbox.name;
                    break;
                case ValidateRadioButton.action:
                    description = ValidateRadioButton.name;
                    break;
                case Sleep.action:
                    description = Sleep.name;
                    break;
                case URL_Validation.action:
                    description = URL_Validation.name;
                    break;
                case Navigate.action:
                    description = this._url ? Navigate.name + '(' + this._url + ')' : Navigate.name;
                    break;
                // case SMS_Validation.action:
                //     description = SMS_Validation.name;
                //     break;
                case OTP_VALIDATION.action:
                    description = OTP_VALIDATION.name;
                    break;
                case GENERATE_PHONE_NUMBER.action:
                    description = GENERATE_PHONE_NUMBER.name;
                    break;
                case InsertVariable.action:
                    description = InsertVariable.name;
                    break;
                case ValidateEmail.action:
                    description = ValidateEmail.name;
                    break;
                case ValidateSMS.action:
                    description = ValidateSMS.name;
                    break;
                default:
                    break;
            }
        } else {
            throw 'Type of action is required';
        }
        return description;
    }

    /**
     * Build case data
     * @returns
     */
    build() {
        return Object.assign(
            {},
            {
                _id: mongoObjectId(),
                url: this._url,
                selector: this._selector,
                xpath: this._xpath,
                xpath_collections: this._xpath_collections,
                tag_name: this._tag_name,
                description: this._getCaseDescription(this._text),
                dom: this._selector != null ? this._selector.split(' > ') : null,
                value: this._value,
                screenshot_src: this._screenshot_src,
                tabInfo: this._tabInfo,
                variable_id: this._variable_id,
                apiInfo: this._apiInfo,
                validationInfo: this._validationInfo,
                target: this._target,
                frameLocation: this._frameLocation,
                automation_screenshot_src: null,
                text: this._text,
                title: this._title,
                action: this._type,
                parent_nodes: this._parent_nodes,
                child_nodes: this._child_nodes,
                coordinates: this._coordinates,
                x_axis: this._x_axis,
                y_axis: this._y_axis,
                screen_size: this._screen_size,
                key_code:this._key_code,
                key: uuidv4(),
                children: [],
                type: 'testStep',
                bounding_react: this._bounding_react,
                attributes: this._attributes,
                error_obj: this._error_obj,
                // 1000 ms
                sleep_for: 1000,
                // Custom validation properties
                css_property: {
                    property: '',
                    expected: '',
                },
                attr_property: {
                    property: '',
                    expected: '',
                },
                broken_links: this._broken_links,
                sms_from: null,
                is_checked: false,
                fail_causes: {
                    id: 2,
                    value: 'Mark error & continue',
                },
                override_timeout: false,
                frameIndex: this._frameIndex,
                eventInfo: this._eventInfo,
            }
        );
    }
}
