import { fetchDashboardProjectStat } from '@/api/MobileTestReportService';
import {
    deleteTestReport,
    fetchDashboardInfo,
    fetchDashboardInfoSelenium,
    fetchLastExecutedCloudRunsInfo,
    fetchLastExecutedRunsInfo,
    fetchAllExecutedTestRuns,
    fetchProjectOverviewCloudInfo,
    fetchRecentlyExecutedCloudRunsInfo,
    fetchSingleProjectOverviewInfo,
    fetchProjectCardsAndRecentFailureInfo,
    fetchSingleProjectPassingRateInfo,
    fetchSingleProjectOverviewInfoV2,
    fetchTestReportDetailsByID,
    fetchTestReportList,
    insertNewReport,
    fetchLastExecutedRunsByUserId,
    previewReport,
    insertReport,
    fetchProjectStatForDashboard,
    fetchTestReportByID,
    fetchCloudTestRunReportById,
    fetchExecutedRuns,
    fetchProjectOverviewCounters,
    fetchDashboardCounts
} from '../../api/TestReportService';

const state = {
    testReportDetails: null,
    projectStatusDashboard: null,
    dashboardInfo: null,
    dashboardInfoCloud: null,
    singleProjectInfo: null,
    singleProjectPassingRateInfo: null,
    singleProjectCardsAndRecentFailureInfo: null,
    recentlyExecutedCloudRunsInfo: null,
    allExecutedTestRunHistory: null,
    testReportList: [],
    filterDuration: '7',
    isTestReportListLoaded: false,
    noDataFoundMsg: null,
    projectOverviewTopCounters: null,
};

const mutations = {
    SAVE_TEST_REPORT_DETAILS(state, testReportDetails) {
        state.testReportDetails = testReportDetails;
    },
    DASHBOARD_PROJECT_STATUS(state, data) {
        state.projectStatusDashboard = data;
    },
    SAVE_DASHBOARD_INFO(state, dashboardInfo) {
        state.dashboardInfo = dashboardInfo;
    },
    SAVE_DASHBOARD_INFO_CLOUD(state, dashboardInfo) {
        state.dashboardInfoCloud = dashboardInfo;
    },

    SAVE_SINGLE_PROJECT_INFO(state, singleProjectInfo) {
        state.singleProjectInfo = singleProjectInfo;
    },

    ALL_EXECUTED_TEST_RUN_HISTORY(state, allExecutedTestRunHistory) {
        state.allExecutedTestRunHistory = allExecutedTestRunHistory;
    },
    
    STORE_WEB_EXECUTED_RUNS(state, webExecutedRuns) {
        state.webExecutedRuns = webExecutedRuns;
    },
    SAVE_SINGLE_PROJECT_PASSING_RATE_INFO(state, singleProjectPassingRateInfo) {
        state.singleProjectPassingRateInfo = singleProjectPassingRateInfo;
    },
    SAVE_PROJECT_CARDS_AND_RECENT_FAILURE_INFO(state, singleProjectCardsAndRecentFailureInfo) {
        state.singleProjectCardsAndRecentFailureInfo = singleProjectCardsAndRecentFailureInfo;
    },
    SAVE_ALL_TEST_REPORT_LIST(state, res) {
        state.testReportList = res;
    },
    SAVE_RECENTLY_EXECUTED_CLOUD_RUNS_INFO(state, res) {
        state.recentlyExecutedCloudRunsInfo = res;
    },
    FILTER_DURATION(state, duration) {
        state.filterDuration = duration;
    },
    IS_TEST_REPORT_LIST_LOADED(state, status) {
        state.isTestReportListLoaded = status;
    },
    NO_DATA_FOUND_MSG(state, msg) {
        state.noDataFoundMsg = msg;
    },
    CLOUD_RUN_REPORT(state, res) {
        state.cloudRunReport = res;
    },
    PROJECT_OVERVIEW_COUNTERS(state, projectOverviewCounters) {
        state.projectOverviewCounters = projectOverviewCounters;
    },
};

const actions = {
    actionInsertNewReport(state, reportData) {
        // console.log('action page', reportData);
        return insertNewReport(reportData).then(res => {
            if (res.status === 200) {
                console.log('report save action', res);
                return res;
            } else {
                return res;
            }
        })
            .catch(err => {

            })
    },

    actionInsertReport(state, payload) {
        return insertReport(payload)
    },

    async actionPreviewReport(state, reportData) {
        return previewReport(reportData);
    },

    actionFetchAllExecutedTestRuns({ state, commit }) {
        return fetchAllExecutedTestRuns().then(res => {
            if (res.status === 200) {
                commit('ALL_EXECUTED_TEST_RUN_HISTORY', res.data.executed_test_runs);
                return res;
            }
        })
    },

    actionRemoveCurrentReport({ state, commit }) {
        commit('SAVE_TEST_REPORT_DETAILS', null);
    },

    async actionGetTestReportById(state, reportID) {
        return await fetchTestReportByID(reportID)
    },

    async actionGetTestReportDetails({ state, commit }, reportID) {
        console.log('reportID in action', reportID);
        return await fetchTestReportDetailsByID(reportID).then(resBody => {
            console.log('resData in action', resBody);
            if (resBody.status == 200) {
                commit('SAVE_TEST_REPORT_DETAILS', resBody && resBody.data && resBody.data.testReportDetails);
                return resBody;
            } else {
                commit('SAVE_TEST_REPORT_DETAILS', null);
                return resBody;
            }
        })
            .catch(err => {

            })
    },

    async actionDeleteTestReport({ state, commit }, reqData) {
        return await deleteTestReport(reqData).then(resBody => {
            if (resBody && resBody.message && resBody.message.STATUS_CODE === 200) {
                commit('SAVE_TEST_REPORT_DETAILS', null);
            }
            return resBody
        })
            .catch(err => {

            })
    },

    async actionProjectStatForDashboard({state, commit}, reqData) {
        commit('DASHBOARD_PROJECT_STATUS', null);
        let response = [];
        try {
          const resp = await fetchProjectStatForDashboard(reqData);
          commit('DASHBOARD_PROJECT_STATUS', resp?.data || []);
          console.log("🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 ", resp.data);
          response = [resp.data, null]
        } catch (err) {
          commit('DASHBOARD_PROJECT_STATUS', []);
          console.log("🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 ", err);
          response = [null, err]
        } finally {
          return response
        }
    },

    async actionGetDashboardInfo({state, commit}, reqData) {
        commit('SAVE_DASHBOARD_INFO', null);
        let mobileProjectDetails = { projectDetailsArray: [] };
        try {
            const mobileServiceRes = await fetchDashboardProjectStat({ days: reqData });
            if (mobileServiceRes.data?.projectStats?.projectDetails?.projectDetailsArray) {
                mobileProjectDetails = mobileServiceRes.data.projectStats.projectDetails;
            }

        } catch (error) {
            console.log(error)
        }
        return await fetchDashboardInfo(reqData).then(resBody => {
            if (resBody && resBody.data && resBody.data.projectDetails) {
                const combinedData = resBody.data.projectDetails;
                combinedData.projectDetailsArray = [...resBody.data.projectDetails.projectDetailsArray, ...mobileProjectDetails.projectDetailsArray]

                combinedData.projectDetailsArray.sort((x, y) => {
                    let a = new Date(x.createdAt),
                        b = new Date(y.createdAt);
                    return b - a;
                })

                commit('SAVE_DASHBOARD_INFO', combinedData);
            }
            return resBody;
        })
            .catch(err => {

            })
    },
    actionGetAllTestReportList(state, filterObj) {
        state.commit('IS_TEST_REPORT_LIST_LOADED', false);
        state.commit('NO_DATA_FOUND_MSG', null);
        state.commit('FILTER_DURATION', filterObj.duration);
        console.log("report filter====>", filterObj)

        // console.log('projectID in action', projectID);
        return fetchTestReportList(filterObj)
            .then(resBody => {
                state.commit('IS_TEST_REPORT_LIST_LOADED', true);
                // console.log('all report resBody', resBody);
                if (resBody.status === 200) {
                    state.commit('NO_DATA_FOUND_MSG', null);
                    state.commit('SAVE_ALL_TEST_REPORT_LIST', resBody && resBody.data && resBody.data.reports);
                    return resBody;
                } else {
                    console.log('report else action===>', resBody.response);
                    state.commit('NO_DATA_FOUND_MSG', resBody && resBody.response && resBody.response.data && resBody.response.data.errors);
                    state.commit('SAVE_ALL_TEST_REPORT_LIST', []);
                    return resBody.response;
                }
            })
            .catch(error => {
                state.commit('IS_TEST_REPORT_LIST_LOADED', true);
                state.commit('SAVE_ALL_TEST_REPORT_LIST', []);
                console.log(error);
            });
    },

    async actionGetDashboardInfoCloud({ state, commit }) {
        commit('SAVE_DASHBOARD_INFO_CLOUD', null);
        console.log('fetching cloud dashboard infos in action');
        return await fetchDashboardInfoSelenium().then(resBody => {
            commit('SAVE_DASHBOARD_INFO_CLOUD', resBody && resBody.data && resBody.data.cloudTestRunAndStepResult);
            return resBody;
        })
            .catch(err => {

            })
    },

    // actionFetchLastExecutedRunsInfo(state) {
    //     return fetchLastExecutedRunsInfo()
    //         .then(resBody => {
    //             console.log('action last executed', resBody);
    //             if (resBody.status === 200) {
    //                 return resBody.data.projectStatusObj.executed_runs;
    //             }
    //         })
    //         .catch(error => {
    //             // console.log(errors);
    //         });
    // },

    // getLastExecutedRunsByUserId(state, userId) {
    //     return fetchLastExecutedRunsByUserId(userId)
    // },

    // actionFetchLastExecutedCloudRunsInfo(state) {
    //     return fetchLastExecutedCloudRunsInfo()
    //         .then(resBody => {
    //             console.log('action last executed cloud', resBody);
    //             if (resBody.status === 200) {
    //                 return resBody.data.cloudLatestRun;
    //             }
    //         })
    //         .catch(error => {
    //             // console.log(errors);
    //         });
    // },

    actionGetProjectOverviewCloudInfo({ state, commit }, actionData) {
        return fetchProjectOverviewCloudInfo(actionData)
            .then(resBody => {
                console.log('action project overview cloud info', resBody);
                if (resBody.status === 200) {
                    return resBody.data.cloudTestRunAndStepResult;
                }
            })
            .catch(error => {
                console.log(error);
            });
    },

    async actionGetProjectOverviewInfo({ state, commit }, actionData) {
        return await fetchSingleProjectOverviewInfo(actionData).then(resBody => {
            if (resBody.status === 200) {
                // console.log("⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐======>>.", {resBody, actionData});

                // let pso = Object.create(resBody.data.projectStatusObj);
                let pso = resBody.data.projectStatusObj;
                let failed = [];

                // console.log("++++++++++++++++++++++ object", pso);
                pso.recent_test_cases = [];
                pso.executed_runs.map(async (runItem, runIndex) => {

                    await runItem.case_info.forEach((cas) => {
                        if (cas.failed) {
                            failed.push(cas)
                        }
                    })

                    await runItem.test_case_ids.forEach((cas) => {
                        failed.forEach(function (ca) {
                            if (ca._id == cas._id) {
                                // console.log("::::::::::::::::::::::::::::::", pso.recent_test_cases, "::::::::::::::::::::::::::::::", failed);

                                pso.recent_test_cases[pso.recent_test_cases.length] = {
                                    test_run_name: runItem.test_run_name,
                                    test_case_id: cas._id,
                                    test_case_name: cas.test_case_name,
                                    issue_type_id: cas.issue_type_id,
                                    updatedAt: new Date(cas.updatedAt),
                                };


                            }
                        })

                    })

                    const sortedActivities = pso.recent_test_cases.sort((a, b) => b.updatedAt - a.updatedAt);
                    pso.recent_test_cases == sortedActivities;

                    // await runItem.test_case_ids.map((caseItem, caseIndex) => {
                    //     if (caseItem.issue_type_id && pso.recent_test_cases.length < 4) {

                    //         pso.recent_test_cases[pso.recent_test_cases.length] = {
                    //             test_run_name: runItem.test_run_name,
                    //             test_case_id: caseItem._id,
                    //             test_case_name: caseItem.test_case_name,
                    //             issue_type_id: caseItem.issue_type_id,
                    //             updatedAt: new Date(caseItem.updatedAt),
                    //         };

                    //         const sortedActivities = pso.recent_test_cases.sort((a, b) => b.updatedAt - a.updatedAt);
                    //         pso.recent_test_cases == sortedActivities;
                    //     }
                    // });


                });

                commit('SAVE_SINGLE_PROJECT_INFO', resBody && resBody.data && resBody.data.projectStatusObj);
                return resBody;
            } else {
                commit('SAVE_SINGLE_PROJECT_INFO', null);
                return resBody.response;
            }
        }).catch(err => {
            console.log(err);
        })
    },

    async actionFetchProjectCardsAndRecentFailureInfo({ state, commit }, reqData) {
        return await fetchProjectCardsAndRecentFailureInfo(reqData).then(resBody => {
            console.log("🌊🌊🌊🌊🌊🌊🌊🌊🌊🌊🌊🌊", resBody)
            if (resBody.status === 200) {
                commit('SAVE_PROJECT_CARDS_AND_RECENT_FAILURE_INFO', resBody.data);
                return resBody;
            }
        })
            .catch(err => {
                console.log(err);
            })
    },

    async actionFetchProjectOverviewCounters({ state, commit }, reqData) {
        return await fetchProjectOverviewCounters(reqData).then(resBody => {
            console.log("🌊🌊🌊🌊🌊🌊🌊🌊🌊🌊🌊🌊", resBody)
            if (resBody.status === 200) {
                commit('PROJECT_OVERVIEW_COUNTERS', resBody.data);
                return resBody;
            }
        })
            .catch(err => {
                console.log(err);
            })
    },

    async actionFetchSingleProjectPassingRateInfo({ state, commit }, reqData) {
        return await fetchSingleProjectPassingRateInfo(reqData).then(resBody => {
            if (resBody.status === 200) {
                let passignRateInfo = {
                    totalSteps: resBody.data.totalSteps,
                    totalPassed: resBody.data.totalPassedSteps,
                    passPercentage: resBody.data.passPercentage,
                    passignRateGraph: [{
                        name: 'passing rate',
                        data: [],
                    }],
                };

                for (let i = 0; i < resBody.data.dayWiseReport.length; i++) {
                    passignRateInfo.passignRateGraph[0].data.push(resBody.data.dayWiseReport[i].passPercentage);
                };

                commit('SAVE_SINGLE_PROJECT_PASSING_RATE_INFO', passignRateInfo);
                return resBody;
            }
        })
            .catch(error => {
                console.log(error);
            })
    },


    async actionGetNativeTestRuns({ state, commit }, actiondata) {
        let response = [null, null];

        try {
            const res = await fetchSingleProjectOverviewInfoV2(actiondata);
            // console.log('🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 ', res.data);
            response = [res.data, null];

        } catch (err) {
            // console.log('🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 ', err);
            response = [null, err.response];

        } finally {
            // eslint-disable-next-line no-unsafe-finally
            return response
        }

    },

    actionGetRecentlyExecutedCloudRunsInfo(state, id) {
        return fetchRecentlyExecutedCloudRunsInfo(id)
            .then(resBody => {
                if (resBody.status === 200) {
                    console.log('actionGetRecentlyExecutedCloudRunsInfo if action===>', resBody.response);
                    state.commit('SAVE_RECENTLY_EXECUTED_CLOUD_RUNS_INFO', resBody && resBody.data && resBody.data.singleProjectLatestCloudRun);
                    return resBody;
                } else {
                    state.commit('SAVE_RECENTLY_EXECUTED_CLOUD_RUNS_INFO', []);
                    console.log('actionGetRecentlyExecutedCloudRunsInfo else action===>', resBody.response);
                    return resBody.response;
                }
            })
            .catch(error => {
                // console.log(error);
            });
    },
    actionFetchCloudRunReportById(state, id) {
        return  fetchCloudTestRunReportById(id)
            .then(resBody => {
                if (resBody.status === 200) {
                    console.log('fetch Cloud TestRun Report ById ===>', resBody.data.cloud_test_run);
                    state.commit('CLOUD_RUN_REPORT', resBody && resBody.data && resBody.data.cloud_test_run);
                    return resBody;
                } else {
                    return resBody.message;
                }
            })
            .catch(error => {
                // console.log(error);
            });
    },
    actionFetchExecutedRuns({ state, commit }, actionData) {
        return fetchExecutedRuns(actionData)
            .then(resBody => {
                console.log('action last executed', resBody);
                if (resBody.status === 200) {
                    commit('STORE_WEB_EXECUTED_RUNS', resBody && resBody.data && resBody.data.executed_test_runs);
                    return resBody.data.executed_test_runs;
                }
            })
            .catch(error => {
                commit('STORE_WEB_EXECUTED_RUNS', null);
                // console.log(errors);
            });
    },
    actionGetDashboardCounts(state) {
        return fetchDashboardCounts()
        .then(resBody => {
            if (resBody.status === 200) {
                return resBody.data;
            }
        })
        .catch(error => {
            // console.log(errors);
        });;
    },

};

const getters = {
    testReportDetails: state => state.testReportDetails,
    dashboardInfo: state => state.dashboardInfo,
    dashboardInfoCloud: state => state.dashboardInfoCloud,
    singleProjectInfo: state => state.singleProjectInfo,
    passignRateInfoObj: state => state.singleProjectPassingRateInfo,
    cardsAndRecentFailureObj: state => state.singleProjectCardsAndRecentFailureInfo,
    recentlyExecutedCloudRunsInfo: state => state.recentlyExecutedCloudRunsInfo,
    testReportList: state => state.testReportList,
    filterDuration: state => state.filterDuration,
    isTestReportListLoaded: state => state.isTestReportListLoaded,
    noDataFoundMsg: state => state.noDataFoundMsg,
    projectStatusDashboard: state => state.projectStatusDashboard,
    cloudRunReport: state => state.cloudRunReport,
    webExecutedRuns: state => state.webExecutedRuns,
    projectOverviewCounters: state => state.projectOverviewCounters,
    allExecutedTestRunHistory: state => state.allExecutedTestRunHistory,

};

export default {
    namespaced: true,
    actions,
    state,
    mutations,
    getters,
};
