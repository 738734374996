import {
    addMobileTestRun,
    fetchExecutedMobileTestRunById,
    fetchMobileTestRuns,
    fetchSingleMobileTestRun,
    sendNativeExecRunResult,
    fetchExecutedMobileTestRunsByRunId,
    initiateCloudRun,
    initiateIosCloudRun,
    fetchExecutedMobileTestRunByIdCloud,
    fetchExecutedMobileTestRunByArn,
    updateMobileTestRunsByRunId,
    fetchCloudRunStatusByRunId,
    deleteMobileTestRunByRunId,
    fetchSingleExecutedMobileTestRun
  } from '../../api/MobileTestRunService';

  const state = {
    testRuns: [],
    testRunReports: [],
    testRunDetails: {},
    executedNativeTestRun: {},
    selectedTestRun: {},
    stepComparisonData: [],
    filterDuration: '7',
    isTestRunsLoaded: false,
    isTestRunReportsLoaded: false,
    isTestRunDetailsLoaded: false,
    isExecutedNativeTestRunLoaded: false,
    isStepComparisonDataLoaded: false,
  };

  const mutations = {
    SET_TEST_RUNS(state, testRuns) {
      state.testRuns = testRuns;
    },
    SET_TEST_RUN_REPORTS(state, testRunReports) {
      state.testRunReports = testRunReports;
    },
    SET_TEST_RUN_DETAILS(state, testRunDetails) {
      state.testRunDetails = testRunDetails;
    },
    SET_EXECUTED_NATIVE_TEST_RUN(state, executedNativeTestRun) {
      state.executedNativeTestRun = executedNativeTestRun;
    },
    SET_SELECTED_TEST_RUN(state, selectedTestRun) {
      state.selectedTestRun = selectedTestRun;
    },
    IS_TEST_RUNS_LOADED(state, isTestRunsLoaded) {
      state.isTestRunsLoaded = isTestRunsLoaded;
    },
    IS_TEST_RUN_REPORTS_LOADED(state, isTestRunReportsLoaded) {
      state.isTestRunReportsLoaded = isTestRunReportsLoaded;
    },
    IS_TEST_RUN_DETAILS_LOADED(state, isTestRunDetailsLoaded) {
      state.isTestRunDetailsLoaded = isTestRunDetailsLoaded;
    },
    IS_EXECUTED_NATIVE_TEST_RUN_LOADED(state, isExecutedNativeTestRunLoaded) {
      state.isExecutedNativeTestRunLoaded = isExecutedNativeTestRunLoaded;
    },
    IS_STEP_COMPARISON_DATA_LOADED(state, isTestRunDetailsLoaded) {
        state.isTestRunDetailsLoaded = isTestRunDetailsLoaded;
    },
  };

  const actions = {
    actionAddTestRun(state, testRun) {
      return addMobileTestRun(testRun);
    },

    actionUpdateMobileProject(state, projectData) {
      return null
    },

    actionFetchTestRuns(state, payload) {
        state.commit('IS_TEST_RUNS_LOADED', false);
        fetchMobileTestRuns(payload)
            .then(response => {

              state.commit('IS_TEST_RUNS_LOADED', true);
              state.commit('SET_TEST_RUNS', response.data.testRuns);
            })
            .catch(err => {
              state.commit('SET_TEST_RUNS', []);
            });
    },

    actionFetchExecutedTestRuns(state, payload) {
      return fetchExecutedMobileTestRunsByRunId(payload.runId)
    },

    async actionFetchExecutedNativeTestRunById(state,executedRunId) {
      state.commit('IS_EXECUTED_NATIVE_TEST_RUN_LOADED', false);
      state.commit('SET_EXECUTED_NATIVE_TEST_RUN', {});
      let res = [];

      try {
        const response = await fetchExecutedMobileTestRunById(executedRunId,{populateCases: true,populateSteps: true});
        state.commit('IS_EXECUTED_NATIVE_TEST_RUN_LOADED', true);
        state.commit('SET_EXECUTED_NATIVE_TEST_RUN', response.data.data);
        res = [response.data.data, null]
      } catch (e) {
        state.commit('SET_EXECUTED_NATIVE_TEST_RUN', {});
        res = [null, e.data]
      } finally {
        return res
      }

      // return fetchExecutedMobileTestRunById(executedRunId,{populateCases: true,populateSteps: true})
      //         .then(response=>{
      //           state.commit('IS_EXECUTED_NATIVE_TEST_RUN_LOADED', true);
      //           state.commit('SET_EXECUTED_NATIVE_TEST_RUN', response);
      //         })
      //         .catch(error=>{
      //           state.commit('SET_EXECUTED_NATIVE_TEST_RUN', {});
      //         })
    },

    async actionFetchExecutedCloudTestRunById(state, runIds) {

      let response = [null, null];
      try {
        const res = await fetchExecutedMobileTestRunByIdCloud(runIds.runId, runIds.executedRunId);
        // response = [res.data.executedRun, null]
        response = [res, null]

      } catch (err) {
        response = [null, err.response]

      } finally {
        return response;
      }
    },

    async updateEmailTestRunById(state, data) {
      let response = [null, null];

      try {
        const res = await updateMobileTestRunsByRunId(data._id, {reportRecepientsEmails: data.reportRecepientsEmails});
        response = [res, null]

      } catch (err) {
        response = [null, err.response]

      } finally {
        return response;
      }
    },
    async actionUpdateTestRunById(state, data) {
      let response = [null, null];

      try {
        const res = await updateMobileTestRunsByRunId(data._id, data);
        response = [res, null]

      } catch (err) {
        response = [null, err.response]

      } finally {
        return response;
      }
    },

    async actionDeleteTestRunById(state, id) {
      let response = [null, null];

      try {
        const res = await deleteMobileTestRunByRunId(id);
        response = [res, null]

      } catch (err) {
        response = [null, err.response]

      } finally {
        return response;
      }
    },

    async actionFetchExecutedResultWithArn (state, runIds) {
      let response = [null, null];

      try {
        const res = await fetchExecutedMobileTestRunByArn(runIds.runId, runIds.arn);
        response = [res, null]

      } catch (err) {
        response = [null, err.response]

      } finally {
        return response;
      }
    },

    async actionFetchSingleNativeTestRunById(state, RunId) {
      let response = [null, null];

      try {
        const res = await fetchSingleMobileTestRun(RunId)
        response = [res.data.testRun, null]

      } catch (err) {
        response = [null, err.response]

      } finally {
        return response
      }

    },

    async actionFetchSingleExecutedNativeRunByID(state, RunId) {
      let response = [null, null];

      try {
        const res = await fetchSingleExecutedMobileTestRun(RunId)
        console.log("🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 actionFetchSingleExecutedNativeRunByID", res);
        response = [res.data.executedTestRuns, null]

      } catch (err) {
        response = [null, err.response]
        console.log("🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 actionFetchSingleExecutedNativeRunByID", err.response);

      } finally {
        return response
      }

    },

    async sendNativeExecutedTestRunResult(state, data) {
      let response = [null, null];

      try {
        const res = await sendNativeExecRunResult(data)
        response = [res, null]

      } catch (err) {
        response = [null, err.response]

      } finally {
        return response
      }

    },

    async activateCloudRun(state, RunInfo) {
      let response = [null, null];

      try {
        const res = await initiateCloudRun(RunInfo)
        response = [res, null]

      } catch (err) {
        response = [null, err.response]

      } finally {
        return response
      }

    },

    async activateIosCloudRun(state, RunInfo) {
      let response = [null, null];

      try {
        const res = await initiateIosCloudRun(RunInfo);
        response = [res, null];

      } catch (err) {
        response = [null, err.response];

      } finally {
        return response
      }

    },

    async isActivateCloudRun(state, runId) {
        let response = [null, null];

        try {
            const res = await fetchExecutedMobileTestRunById(runId, {populateCases: false, populateSteps: false});
            console.log("🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 isActivateCloudRun", res);
            response = [res.data, null]

        } catch (err) {
            response = [null, err.data]
            console.log("🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 isActivateCloudRun", err.response);

        } finally {
            return response
        }
    }



    // actionFetchTestRunReports(state, reqData) {
    //   state.commit('IS_TEST_RUN_REPORTS_LOADED', false);

    //   fetchTestRunsReports(reqData)
    //     .then(resBody => {
    //       state.commit('IS_TEST_RUN_REPORTS_LOADED', true);
    //       if (resBody.status === 200) {
    //         state.commit('NO_DATA_FOUND_MSG', null);
    //         state.commit('FETCH_TEST_RUN_REPORTS_LIST', resBody && resBody.data);
    //       } else {
    //         console.log('resBody', resBody.response);
    //         state.commit('FETCH_TEST_RUN_REPORTS_LIST', []);
    //         state.commit('NO_DATA_FOUND_MSG', resBody && resBody.response && resBody.response.data && resBody.response.data.message && resBody.response.data.message.MSG_SHORT);
    //       }
    //       return "getValue"
    //     })
    //     .catch(err => {
    //       state.commit('FETCH_TEST_RUN_REPORTS_LIST', []);
    //       state.commit('IS_TESTRUNREPORTS_LOADED', true);
    //       state.commit('NO_DATA_FOUND_MSG', null);
    //       return err
    //     });
    // },

  };

  const getters = {
    testRuns: state => state.testRuns,
    testRunReports: state => state.testRunReports,
    testRunDetails: state => state.testRunDetails,
    executedNativeTestRun: state => state.executedNativeTestRun,
    selectedTestRun: state => state.selectedTestRun,
    filterDuration: state => state.filterDuration,
    isTestRunsLoaded: state => state.isTestRunsLoaded,
    isTestRunDetailsLoaded: state => state.isTestRunDetailsLoaded,
    isExecutedNativeTestRunLoaded: state => state.isExecutedNativeTestRunLoaded,
    isTestRunReportsLoaded: state => state.isTestRunReportsLoaded,
    isStepComparisonDataLoaded: state => state.isStepComparisonDataLoaded,
  };

  export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
  };
