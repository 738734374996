const state = {
    name: 'TestJet'
}
const mutations = {}
const actions = {}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}