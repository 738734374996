<template>
    <!-- <div class="validationPanel">
        <div class="p-xl-3"> -->
    <div class="card">
      <h5>Add Step</h5>
      <div class="search-box p-fluid">
              <span class="p-input-icon-right">
                  <i class="pi pi-search"/>
                  <InputText type="text" v-model="searchValidation" placeholder="Search"/>
              </span>
      </div>

      <div class="validation-nav">
        <div class="all" :class="activeTabIndex == 0 ? 'active-color' : ''" @click="changeTabIndex(0)"><b>All</b></div>
        <!-- <i class="fas fa-check-circle" @click="changeTabIndex(2)" :class="activeTabIndex == 2 ? 'active-color' : ''"></i> -->
        <!-- <i class="fas fa-clock" @click="changeTabIndex(3)" :class="activeTabIndex == 3 ? 'active-color' : ''"></i> -->
        <!-- <i class="fas fa-folder" title="Group" @click="changeTabIndex(4)" :class="activeTabIndex == 4 ? 'active-color' : ''"></i> -->
      </div>
      <!-- <div v-if="testEditoServerInfo">Server is running!</div> -->
      <div v-if="activeTabIndex == 0" class="all-tab-content">
        <!-- :class="['list-container', isFreeTrial() && isValidationDisabledInFreeTrial(validation.action) ? 'disabled-container' : '']" -->
        <div class="validations">
            <ul>
                <li v-for="(validation, k) in filteredValidationList" :key="k" >
                <div :class="['list-container']" @click="$emit('onListClick', validation)" >
                <i :class="`${validation.icon}`"></i>
                {{ validation.name }}
                </div>
            </li>
            </ul>
        </div>

        <div class="save-btn p-fluid">
          <Button label="SAVE TEST" class="p-button-secondary" :style="{ height: '40px' }" @click="$emit('clickSave')" :disabled="savingTestCase" />
        </div>
        <!-- <div class="save-btn p-fluid">
            <Button label="Send Data" class="p-button-secondary" :style="{ height: '40px' }" @click="$emit('clickSendStepsToSelenuim')" />
        </div> -->
      </div>
      <div v-if="activeTabIndex == 1" class="all-tab-content">

      </div>
      <div v-if="activeTabIndex == 2" class="all-tab-content">

      </div>
      <div v-if="activeTabIndex == 3" class="all-tab-content">

      </div>
      <div v-if="activeTabIndex == 4" class="all-tab-content">
        <div class="validations">
          <ul>
            <li v-for="shareGroup in shareableGroupList" :key="shareGroup.item._id">
              <div v-if="shareGroup.test_step_ids.length > 0" class="list-container">
                <Checkbox :id="shareGroup.item._id" name="shareGroup" :value="shareGroup" v-model="checkedShareGroup"/>
                <!-- <label>{{shareGroup.test_step_ids.length}}</label> -->
                <label :for="shareGroup.item._id"> {{
                    shareGroup && shareGroup.test_step_ids && shareGroup.test_step_ids.length > 0 ? shareGroup.test_step_ids[0].description : ''
                  }}</label>
              </div>
            </li>
            <!--  -->
          </ul>
        </div>
        <div class="save-btn p-fluid">
          <Button label="ADD GROUP" class="p-button-secondary" :style="{ height: '40px' }" @click="$emit('clickAddSharedGroup', checkedShareGroup)"/>
        </div>
      </div>
      <!-- <pre>shareableGroupList===>{{shareableGroupList}}</pre> -->
    </div>
  </template>

  <script>

import { iosLists, androidLists } from '../../../utils/mobile/mobile_validation_list';

  export default {
    name: 'ValidationPanelMobile',
    components: {},
    props: {
      deviceType: {
        type: String,
        default: '',
      },
      savingTestCase: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        validationList: [],
        searchValidation: '',
        activeTabIndex: 0,
        checkedShareGroup: null,
      };
    },

    mounted() {
      if (this.deviceType === 'ios') {
        this.validationList = Object.values(iosLists)
      } else if (this.deviceType === 'android') {
        this.validationList = Object.values(androidLists)
      }
    },

    methods: {
      changeTabIndex(activeIndex) {
        this.activeTabIndex = activeIndex;
      },
    },

    computed: {
      filteredValidationList() {
        return this.validationList.filter(validation => {
          return validation.name.toLowerCase().includes(this.searchValidation.toLowerCase())
        })
      }
    },

    watch: {
      testEditoServerInfo(value, oldValue) {
        console.log("value==>", value + "< >" + oldValue)
      }
    }
  };
  </script>

  <style>
  .active-color {
    color: #00a0ee;
  }
  </style>
