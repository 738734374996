<template>
    <div>
        <div :class="['test-run-details test-run-details-native', cloudRunActive ? 'stretched' : '']">
            <div class="test-run-details-container">
                <div v-if="testRun">
                    <Message severity="info" v-if="cloudRunActive">Cloud run is in progress, you will be redirected
                        automatically after the execution is complete.</Message>
                    <ProgressBar mode="indeterminate" style="height: .5em" v-if="cloudRunActive" />
                    <div class="header-wrapper card">
                        <div class="details-wrapper">
                            <div class="back-btn">
                                <Button @click="$router.back()" icon="fas fa-chevron-left"
                                    class="p-button-rounded p-button-outlined" />
                            </div>

                            <div class="test-run-name-wrapper">
                                <div class="test-run-name">
                                    <p>{{ testRun.name }}</p>
                                </div>
                                <div class="tags">
                                    <Tag class="p-mr-2" :value="tag" v-for="(tag, index) in testRun.tags" :key="index"
                                        rounded></Tag>
                                </div>
                            </div>
                        </div>
                        <div class="action-icons-wrapper">
                            <div class="action-btn">
                                <Button icon="fas fa-envelope" title="Email" class="p-button-rounded p-button-outlined"
                                    @click="clickEditEmailModal" />
                            </div>

                            <div class="action-btn">
                                <Button icon="fas fa-edit" title="Edit" class="p-button-rounded p-button-outlined"
                                    @click="clickEditModal" :disabled="nativeRunActive || nativeRunActive" />
                            </div>

                            <div class="action-btn">
                                <Button icon="fas fa-stop stop_icon" title="Stop Native Run"
                                    class="p-button-rounded p-button-outlined stop_button"
                                    :style="{ display: nativeRunActive ? '' : 'none' }" @click="_deactivateNativeRun" />

                                <Button icon="fas fa-play" title="Execute Native Run"
                                    class="p-button-rounded p-button-outlined"
                                    :style="{ display: nativeRunActive && !nativeRunPaused ? 'none' : '' }"
                                    @click="_activateNativeRun" :disabled="cloudRunActive" />

                                <Button icon="fas fa-pause" title="Pause Native Run"
                                    class="p-button-rounded p-button-outlined pause_button"
                                    :style="{ display: !nativeRunActive || nativeRunPaused ? 'none' : '' }"
                                    @click="_pauseNativeRun" />
                                <!-- :style="{ display: !nativeRunActive ? 'none' : '' }" @click='_pauseNativeRun nativeRunPaused' -->
                            </div>

                            <div class="action-btn">
                                <!-- :style="{ display: cloudRunActive ? 'none' : '' }" -->
                                <Button title="Execute Cloud Run" icon="fas fa-cloud"
                                    class="p-button-rounded p-button-outlined" @click="_cloudRunActivate"
                                    :disabled="nativeRunActive || cloudRunActive" />
                                <!-- <Button title='Stop Cloud Run' icon='fas fa-stop' class='p-button-rounded p-button-outlined'
                                        :style="{ display: cloudRunActive ? '' : 'none' }"
                                        @click='_deactivateCloudRun' /> -->
                            </div>
                        </div>
                    </div>

                    <div class="execution_status">
                        <div class="info">
                            <p class="total">{{ totalTestSteps ? totalTestSteps : 0 }}</p>
                            <span>Total Steps</span>
                        </div>

                        <div class="info">
                            <p class="total">{{ (nativeRunActive || cloudRunActive) && executedSteps.length ?
                                executedSteps.length : 0 }}</p>
                            <span>Executed Steps</span>
                        </div>

                        <div class="info">
                            <p class="total">{{ (nativeRunActive || cloudRunActive) ? totalTestSteps - executedSteps.length
                                : 0 }}</p>
                            <span>Steps Left</span>
                        </div>
                    </div>

                    <div class="test-cases">
                        <div class="table-header">
                            <div class="section-1">
                                Test Cases
                                <span>{{ testCaseCount }}</span>
                            </div>
                            <div class="section-2" v-if="testRailsConfig">
                                <p class="name">{{ `${isTestRail ? 'Disable' : 'Enable'} TestRail Integration` }}</p>
                                <div class="switch-integration">
                                    <InputSwitch v-model="isTestRail" @change="handleTestrailIntegration"
                                        :disabled="testrailsBtnDisabled" />
                                </div>
                            </div>
                        </div>

                        <div class="test_cases_list">
                            <DataTable :value="testRun.testCases" v-model:expandedRows="expandedRows" dataKey="_id"
                                @rowExpand="onRowExpand" class="test_case_table" responsiveLayout="scroll">
                                <Column :expander="true" :style="{ width: '0' }" ref="test_case_table" />
                                <Column header="head">
                                    <template #body="slotProps">
                                        <div class="name">
                                            <div class="rates">
                                                <!-- activeRunningCase -->
                                                <img v-if="activeRunningCase && activeRunningCase._id == slotProps.data._id"
                                                    src="@/assets/double-ring-loader.svg" alt="" />
                                                <i v-else-if="slotProps?.data?.status === 'passed'"
                                                    class="fas fa-check-circle pass"></i>
                                                <i v-else-if="slotProps?.data?.status === 'failed'"
                                                    class="fas fa-exclamation-circle fail"></i>
                                            </div>
                                            <div class="case_name">
                                                {{ slotProps.data.name }}
                                            </div>
                                        </div>
                                    </template>
                                </Column>
                                <Column header="actions">
                                    <template #body="slotProps">
                                        <div class="case_actions_wrapper">
                                            <!-- <label v-if="slotProps?.data?.issue_type_id" class="issue_type_label" style="padding-right: 4px"> {{`${findIssueType(slotProps.data.issue_type_id)}` }} </label> -->
                                            <!-- <Button icon="fas fa-bug" title="View Issue Type" class="p-button-rounded p-button-outlined bug-icon" @click="toggle($event, slotProps.data)" /> -->
                                            <Button title="Map test case from TestRail" icon="fab fa-testrail" :class="['p-button-rounded', 'p-button-outlined', 'testrail-icon', 'testrail-icon-active', 'testrail-icon-disabled',
                                                'testrails_testcase' in slotProps.data ? 'bordered' : ''
                                            ]" @click="onTestRailPressed(slotProps.data)"
                                                :disabled="nativeRunActive || cloudRunActive || !testRailsConfig">
                                                <img class="image" alt="Logo"
                                                    src="/assets/layout/images/testRailLogo.png" />
                                            </Button>
                                        </div>
                                    </template>
                                </Column>
                                <template #expansion="slotProps">
                                    <div class="testcase_steps">
                                        <MobileNestedTestSteps ref="mobileNestedTestSteps" :showCheckBox="false"
                                            :listsOfSteps="getStepsBySlotProps(slotProps.data)"
                                            :allExecutedSteps="executedSteps" :activeStep="activeRunningStep"
                                            :status="projectStatus" @onImageComparison="onImageComparison"
                                            @showEmailModal="onValidationEmail" 
                                            @showSmsModal="onValidationSMS"
                                            :autoScrollOff="true" />
                                    </div>
                                </template>
                            </DataTable>
                        </div>
                    </div>
                </div>

                <div class="loader" v-else>Loading...</div>
            </div>
            <div :class="['test-run-details-midSection', cloudRunActive ? 'noDisplay' : '']">
                <div id="streamParent">
                    <video id="player" :style="{ display: deviceType === 'android' ? '' : 'none' }" :width="videoWidth"
                        :height="videoHeight" autoplay muted></video>
                    <img id="player_2" :src="`http://localhost:9100/?q=${streamTimestamp}`"
                        :style="{ display: deviceType === 'ios' ? '' : 'none' }" :width="videoWidth" :height="videoHeight"
                        @error="resetDimensions" />

                </div>
            </div>
            <RunInfoRightBar showLiveRun :runInfo="testRun" :runType="cloudRunActive ? 'Cloud' : 'Native'"
                :percentage="percentage" :execTime="execTime"
                :apkName="thisProject && thisProject.appInfo && thisProject.appInfo.fileName ? thisProject.appInfo.fileName : ''"
                :serial="testRun && testRun.serial ? testRun.serial : 0"
                :createdAt="testRun && testRun.createdAt ? testRun.createdAt : ''" :status="projectStatus" />

            <Dialog :draggable="false" header="Email Notifications" v-model:visible="editModalVisibleForEmail" :style="{ width: '30rem' }"
                :modal="true">
                <div class="p-grid p-fluid">
                    <div class="p-col-12 m-0 p-0">
                        <div class="input-group p0">
                            <TjCustomChipsMobile :tagList="testRun.reportRecepientsEmails"
                                @onUpdateChipsList="onUpdateChipsListEmail" :labelName="'Enter email address'"
                                placeholder="Enter email address" type="email" />
                            <Button label="Save" class="p-button-primary save-changes-button"
                                @click="clickUpdateRecipientEmail" :style="{ marginTop: '15px' }" />
                        </div>
                    </div>
                </div>
            </Dialog>

            <Dialog :draggable="false" header="Edit Test Run" v-model:visible="editModalVisible" :style="{ width: '24rem' }" :modal="true">
                <div class="p-grid p-fluid">
                    <div class="p-col-12 m-0 p-0">
                        <div class="input-group">
                            <div class="p-field input-description">
                                <label>Test Run Name</label>
                                <InputText :class="{ 'p-error': updateTestRunError.name }" v-model="testRun.name"
                                    type="text" placeholder="Enter Test Run Name" />
                                <small class="p-invalid" v-if="updateTestRunError.name">Test run name is required</small>
                            </div>

                            <TjCustomChipsMobile :tagList="testRun.tags" @onUpdateChipsList="onUpdateChipsList" />
                            <Button label="Save Changes" class="p-button-primary save-changes-button"
                                @click="clickEditTestRun" :style="{ marginTop: '15px' }" />
                        </div>
                    </div>
                </div>
            </Dialog>

            <Dialog :draggable="false" header="Email Validation" v-model:visible="showValidationEmailModal" modal="true"
                class="broken-link-modal" :style="{ width: '75vw' }">
                <email-validation-modal :existingData="emailData"  />
            </Dialog>

            <Dialog :draggable="false" header="SMS Validation" v-model:visible="showValidationSmsModal" modal="true"
                class="broken-link-modal" :style="{ width: '75vw' }">
                <SMSValidationModal :existingData="smsData"  />
            </Dialog>

            <OverlayPanel v-for="testcase in overLayTestCases" :key="testcase._id" :ref="testcase._id">
                <div class="issue-type-wrapper" :id="testcase._id">
                    <div class="issue-type-header">Add Issue Type</div>
                    <div class="issue-type-content">
                        <ul>
                            <li v-for="issueType in issueTypeList.issueType" :key="issueType._id"
                                @click="handleSelectedBugType(issueType, testcase)">
                                <span class="icon"><i class="fas fa-bug"></i></span>
                                <p>{{ issueType.title }}</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </OverlayPanel>
        </div>
        <ComparisonAutomationDialogMobile :isVisible="showComparisonModal" :testStep="comparisonStep"
            @hide="hideComparisonModal" />
        <test-rails-mapper :mobileVersion="true" ref="testRailMapperRef" @hide="onTestRailClose"
            v-model:testCase="currentTestCaseRail" @onMapTestRail="onMapTestRail" />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import WSAvcPlayer from 'ws-avc-player';
import { io } from 'socket.io-client';
import { cloneDeep } from 'lodash';
import RunInfoRightBar from './testRunMobileDetails/RunInfoRightBar.vue';
import MobileNestedTestSteps from '../commonComponents/MobileNestedTestSteps.vue';
import TjCustomChipsMobile from '../commonComponents/TjCustomChipsMobile.vue';
import Step from '../utility/classes/Step';
import { GetTestSteps } from '../utility/classes/getTestSteps';
import nativeRunMixin from './testRunMobileDetails/mixins/nativeRunMixin';
import cloudRunMixin from './testRunMobileDetails/mixins/cloudRunMixin';
import variableMixin from './MobileTestEditorPage/mixins/variableMixin';
import mobileTestRunTestRailMixin from './testRunMobileDetails/mixins/mobileTestRunTestRailMixin';
import { $stopNativeAutomation } from '../dump/axios';
import { SendTestSteps } from '../utility/classes/sendTestSteps';
import TestRailsMapper from '@/components/TestRailsMapper.vue';
import ComparisonAutomationDialogMobile from '../commonComponents/ComparisonAutomationDialogMobile.vue';
import { generateRandomString } from '../utility/utils';
import { getSelectedCurrentProjectId } from "@/utils/localstorage";
import SMSValidationModal from '../components/customValidationComponents/sms-validation/SMSValidationModal.vue';
import EmailValidationModal from '../components/customValidationComponents/email-validation/EmailValidationModal.vue';

export default {
    components: {
        EmailValidationModal,
        SMSValidationModal,
        RunInfoRightBar,
        MobileNestedTestSteps,
        TestRailsMapper,
        TjCustomChipsMobile,
        ComparisonAutomationDialogMobile,
    },
    data() {
        return {
            emailData : null,
            showValidationEmailModal : false,
            smsData : null,
            showValidationSmsModal : false,
            wsavc: null,
            streamParent: null,
            deviceType: 'android',
            videoElement: null,
            videoElement2: null,
            streamInterval: null,
            seekInterval: null,
            streamSocket: null,
            jmuxer: null,
            socket: null,
            videoHeight: 0,
            videoWidth: 0,
            expandedRows: null,
            nativeRunPaused: false,
            nativeRunActive: false,
            cloudRunActive: false,
            testRun: null,
            totalTestSteps: 0,
            percentage: [0, 0, 100], // [pass, fail, remaining]
            thisProject: null,
            executedSteps: [],
            currentStepCnt: -1,
            activeRunningCaseIndex: -1,
            activeRunningCase: null,
            activeRunningStep: { _id: 'asd' },
            streamConnected: false,
            streamTimestamp: Date.now(),
            execTime: {
                start: null,
                end: null,
                delayed: '',
            },
            exeTimePaused: {
                start: null,
                end: null,
            },
            projectStatus: 'Pending',
            isTestRail: false,
            currentTestCaseRail: {},
            editModalVisibleForEmail: false,
            editModalVisible: false,
            updateTestRunError: {},
            showComparisonModal: false,
            comparisonStep: null,
            apiCallInterval: null,
            latestExecutedTestCases: []
        };
    },
    created() {
        this.fetchCurrentTestRun();
        this.getProjectsList();
        // this.fetchIssueTypeList();
        this.fetchIntegrationSettings({ project_id: getSelectedCurrentProjectId(), type: 'testrails' });

        setTimeout(() => {
            this.getLatestExecutedTestRun();
        }, 300);
    },
    computed: {
        ...mapGetters({
            projectList: 'project/projectList',
            issueTypeList: 'TjAdminFeatures/issueTypeList',
            testRailsConfig: 'settings/testRailsConfig',
            loggedInUserInfo: 'auth/loggedInUserInfo',
            caseVariables: 'variable/caseVariables',
        }),
        overLayTestCases() {
            if (this.testRun?.testCases) {
                return this.testRun.testCases;
            }
            return [];
        },
        testCaseCount() {
            return this.testRun.testCases.length > 1 ? `${this.testRun.testCases.length} cases` : `${this.testRun.testCases.length} case`;
        },
    },
    mounted() {
        this._getHtmlElements();
        // this.wsavc = new WSAvcPlayer({ useWorker: true });
        setTimeout(() => {
            this.thisProject = this.projectList?.filter((prj) => prj._id === localStorage.getItem('projectId'))[0];
            this.deviceType = this.thisProject?.type?.toLowerCase()
        }, 1500);

        this.socket = io(process.env.VUE_APP_AGENT_SERVICE_HOST);

        this.socket.on('executedStep', async (step) => {

            let fileId = generateRandomString(15);
            const [s3Data, err] = await this.sendScreenshotToS3({ prjId: this.thisProject._id, caseId: step.testCaseID, testStep: step, filename: step._id })

            step.screenshot && s3Data ? step.screenshot.executedImgUrl = s3Data.uri : null;

            this.executedSteps = [...this.executedSteps, step];

            for (let i = 0; i < this.testRun.testCases.length; i++) {
                let _case = this.testRun.testCases[i];

                if (_case._id == step.testCaseID) {
                    for (let x = 0; x < _case.testSteps.length; x++) {
                        let _step = _case.testSteps[x];

                        if (_step._id == step._id) {
                            //! modifying the original testrun => case => steps by the steps we are recieving from agent

                            this.testRun.testCases[i].testSteps[x] = { ...step };

                            //! checking for if there is an error in an step. And set the case status accordingly.
                            if (this.testRun.testCases[i]?.status === 'failed') {
                                null;
                            } else {
                                step.error_obj.error ? (this.testRun.testCases[i].status = 'failed') : (this.testRun.testCases[i].status = 'passed');
                            }

                            break;
                        }
                    }

                    break;
                }
            }

            const vars = this.getExports(step.error_obj);

            if (vars) {
              await this.updateCaseVariable(vars);
            }

            if (step._id == this.activeRunningCase.testSteps[this.activeRunningCase.testSteps.length - 1]._id) {
                await new Promise(r => setTimeout(r, 700))
                await $stopNativeAutomation();

                this.activeRunningStep = { _id: 'asd' };
                this.currentStepCnt = -1;
                await new Promise((r) => setTimeout(r, 200));
                this.testRun.testCases[this.activeRunningCaseIndex].endTime = Date.now();
                this.activeRunningCase = null;
                this.activeRunningCaseIndex++;
                this.testCaseScroll();

            } else {
                this.currentStepCnt++;
            }

        });
        this.socket.on('pausedState', (data) => console.log("🌦️ 🌦️ 🌦️ 🌦️ 🌦️ 🌦️ 🌦️ 🌦️ 🌦️ 🌦️ 🌦️ 🌦️ ", data))
        this.socket.on('action-failed', (err) => {
            console.log(`🟠 🟠 🟠 🟠 🟠 🟠 🟠 🟠 action failed with ${err}`);
        });


        this.seekInterval = setInterval(() => {
            this.seekVideo();
        }, 5000);
    },
    watch: {
        projectList(lists) {
            this.thisProject = lists.filter((prj) => prj._id == localStorage.getItem('projectId'))[0];
            this.deviceType = this.thisProject?.type?.toLowerCase();
            console.log(" 🟠 🟠 🟠 🟠 🟠 🟠 🟠 🟠 🟠 +++++++++++ 🟠 🟠 🟠 🟠 🟠 🟠 🟠 🟠 🟠", this.thisProject);
        },
        executedSteps(_steps) {
            if (!this.nativeRunActive) {
                this.evaluatePreviousExecutedResult()
                return;
            }

            if (!_steps.length) {
                return;
            }

            let stepsCount = this.totalTestSteps;
            let passCnt = 0;
            let failCnt = 0;

            for (let i = 0; i < _steps.length; i++) {
                if (_steps[i].error_obj.error) failCnt++;
                else passCnt++;
            }
            let notExecuted = stepsCount - failCnt - passCnt;
            this.percentage = [parseFloat((passCnt / stepsCount) * 100), parseFloat((failCnt / stepsCount) * 100), parseFloat((notExecuted / stepsCount) * 100)];

            if (_steps.length === this.totalTestSteps) {
                this.execTime = {
                    ...this.execTime,
                    end: Date.now(),
                };

                // setting end time for last testCase;
                this.testRun.testCases[this.testRun.testCases.length - 1].endTime = Date.now();

                this.$afterExecutionDone(this.testRun);

            }
        },
        testRun: {
            handler: function (value) {
                if (value?.name) {
                    this.updateTestRunError.name = false;
                }
            },
            deep: true
        },

    },
    unmounted() {
        this.socket.emit('connectionEnd');
        this.socket.disconnect();
        try {
            if (this.streamSocket && this.streamSocket.close && this.streamSocket.readyState === 1) {
                this.streamSocket.close();
                this.streamSocket = null;
            }
        } catch (ex) {
            console.log(ex);
        }
        this.latestExecutedTestCases = []
        if (this.apiCallInterval) {
            clearInterval(this.apiCallInterval);
        }
        if (this.seekInterval) {
            clearInterval(this.seekInterval);
        }
        if (this.streamInterval) {
            clearInterval(this.streamInterval);
        }
    },
    methods: {
        ...mapActions({
            getTestRun: 'mobileTestRun/actionFetchSingleNativeTestRunById',
            getExecutedTestRun: 'mobileTestRun/actionFetchSingleExecutedNativeRunByID',
            sendNativeExecutedTestRunResult: 'mobileTestRun/sendNativeExecutedTestRunResult',
            isActivateCloudRun: 'mobileTestRun/isActivateCloudRun',
            activateCloudRun: 'mobileTestRun/activateCloudRun',
            activateIosCloudRun: 'mobileTestRun/activateIosCloudRun',
            getProjectsList: 'project/actionGetProjectsList',
            fetchWithArn: 'mobileTestRun/actionFetchExecutedResultWithArn',
            fetchIssueTypeList: 'TjAdminFeatures/actionFetchIssueTypeList',
            actionUpdateCase: 'mobileTestCases/actionUpdateMobileTestcase',
            updateTestRunEmailRecipient: 'mobileTestRun/updateEmailTestRunById',
            sendScreenshotToS3: 'mobileTestCases/actionSendTestStepsScreenshotToS3',
            updateTestRun: 'mobileTestRun/actionUpdateTestRunById',
            actionFetchExecutedTestRuns: 'mobileTestRun/actionFetchExecutedTestRuns',
            actionFetchExecutedNativeTestRunById: 'mobileTestRun/actionFetchExecutedNativeTestRunById',
            fetchIntegrationSettings: 'settings/fetchIntegrationSettings',
            orgConsumption: 'organization/GetOrganizationPackageConsumption',
            actionGetTestCaseVariables: 'variable/actionGetTestCaseVariables',
            actionUpdateTestCaseVariable: 'variable/actionUpdateTestCaseVariable',
        }),

        async getVariables(id) {
          if (!id) {return;}

          const data = {
            data: { caseId: id },
            type: 'mobile'
          }
          const [resp, err] = await this.actionGetTestCaseVariables(data);
          return [resp, err];
        },
        resetDimensions() {
            this.videoHeight = 0;
            this.videoWidth = 0;
        },
        seekVideo(threshold = 3) {
            if (!this.videoElement || this.videoElement.readyState < 3) {
                return;
            }
            // Get the buffered time ranges of the video
            const buffered = this.videoElement.buffered;
            // Get the end time of the last buffered range
            if (buffered.length < 5) {
                return;
            }
            const lastBuffered = buffered.end(buffered.length - 2);
            // Get the current time of the video
            const currentTime = this.videoElement.currentTime;
            // Calculate the lag
            const lag = lastBuffered - currentTime;
            // If the lag is larger than the threshold, seek the video to the end of the buffered range
            if (lag > threshold) {
                this.videoElement.currentTime = lastBuffered;
            }
        },
        testCaseScroll() {
            const parent = document.querySelector('.p-datatable-wrapper');
            const childElement = parent.querySelector('.listed_steps');
            if (childElement === null) {
                return;
            }
            const childHeight = childElement.getBoundingClientRect().height

            parent.scrollTo({
                top: childHeight + 100,
                left: 0,
                behavior: 'smooth',
            });
        },

        async getLatestExecutedTestRun() {
            const [executedNativeTestRun, executedNativeTestRunErr] = await this.getExecutedTestRun(this.$route.params.id);

            if (executedNativeTestRun?.executedCases && !this.cloudRunActive) {

                for (let i = 0; i < executedNativeTestRun.executedCases.length; i++) {
                    let cas = executedNativeTestRun.executedCases[i];

                    this.latestExecutedTestCases = [...this.latestExecutedTestCases, cas]
                    this.executedSteps = [...this.executedSteps, ...cas.executedSteps]
                }
            }

        },

        evaluatePreviousExecutedResult() {
            let stepsCount = this.totalTestSteps;
            let passCnt = 0;
            let failCnt = 0;

            for (let i = 0; i < this.testRun?.testCases.length; i++) {
                let mainCase = this.testRun?.testCases[i]

                for (let j = 0; j < this.latestExecutedTestCases?.length; j++) {
                    const executedCase = this.latestExecutedTestCases[j]
                    let stat = 'passed';


                    for (let execInd = 0; execInd < executedCase.executedSteps.length; execInd++) {
                        const execStep = executedCase.executedSteps[execInd];

                        // TO CALCULATE STATUS TO SHOW BESIDE THE CASE
                        if (executedCase.testCaseID == execStep.testCaseID && mainCase._id == execStep.testCaseID) {

                            if (this.cloudRunActive) {
                                continue;
                            }


                            if (execStep.error_obj.error) {
                                stat = 'failed';
                                this.testRun.testCases[i].status = stat;
                            }

                            if (stat !== 'failed' && stat === 'passed') {
                                this.testRun.testCases[i].status = stat;
                            }

                        }

                        // TO CALCULATE PERCENTAGE FOR DONUTCHART
                        if (executedCase.testCaseID == mainCase._id) {
                            if (execStep.error_obj.error) {
                                failCnt++
                            } else {
                                passCnt++
                            }
                        }
                    };

                    let notExecuted = stepsCount - failCnt - passCnt;

                    if (this.cloudRunActive) {
                        this.percentage = [0, 0, 100];
                    } else {
                        this.percentage = [parseFloat((passCnt / stepsCount) * 100), parseFloat((failCnt / stepsCount) * 100), parseFloat((notExecuted / stepsCount) * 100)];
                    }

                }

            }

        },

        validateEmptyTestCase() {
            let emptyCaseCount = 0;
            let validation = { isValid: true, message: '' };

            for (let i = 0; i < this.testRun.testCases.length; i++) {
                const testCase = this.testRun.testCases[i];
                if (testCase.testSteps.length < 1) {
                    emptyCaseCount++;
                }
            }

            if (this.testRun.testCases.length == 1) {
                if (emptyCaseCount == 1) {
                    validation = { isValid: false, message: 'This test case does not contain any test steps' };
                }
            }
            else {
                if (this.testRun.testCases.length == emptyCaseCount) {
                    validation = { isValid: false, message: 'These test cases do not contain any test steps' };
                } else if (emptyCaseCount == 1) {
                    validation = { isValid: false, message: 'One of the test cases does not contain any test steps' };
                } else if (emptyCaseCount > 1) {
                    validation = { isValid: false, message: 'Some of the test cases do not contain any test steps' };
                }
            }
            return validation;
        },
        hideComparisonModal() {
            this.showComparisonModal = false;
            this.comparisonStep = null
        },
        onImageComparison(step, executedStep) {
            // process.env.VUE_APP_NOT_EXECUTED_STEP_IMAGE
            // step.executedImgUrl

            if (executedStep) {
                console.log("🎭 🎭 🎭 🎭 🎭 🎭 🎭 🎭 🎭 🎭 🎭 🎭 🎭 🎭 ", step)
            }

            this.showComparisonModal = true;
            this.comparisonStep = step
        },

        onValidationEmail(step) {
            const { validationInfo, automation_result } = step;
            console.log("+_+_+_+_+onValidationEmail+_+_+_+_+_+", validationInfo)

            if (validationInfo) {
                this.emailData = step;
                this.showValidationEmailModal = true
            }
        },

        onValidationSMS(step) {
            const { validationInfo, automation_result } = step;
            console.log("+_+_+_+_+onValidationSMS+_+_+_+_+_+", validationInfo)

            if (validationInfo) {
                this.smsData = step;
                this.showValidationSmsModal = true
            }
        },
    
        async clickUpdateRecipientEmail() {
            const data = { _id: this.testRun._id, reportRecepientsEmails: this.testRun.reportRecepientsEmails }

            const [res, err] = await this.updateTestRunEmailRecipient(data)

            if (err) {
                console.log('update-res-err', err);
                this.$toast.add({
                    severity: 'error',
                    summary: 'Recipients emails were not updated.',
                    life: 3000,
                })
                return;
            }

            this.$toast.add({
                severity: 'success',
                summary: 'Recipients emails were updated.',
                life: 3000,
            })
            this.editModalVisibleForEmail = false;
        },
        onUpdateChipsList(updatedChipsList) {
            this.testRun.tags = updatedChipsList;
        },

        clickEditTestRun() {
            if (!this.testRun.name) {
                this.updateTestRunError.name = true;
                return
            }
            this.updateTestRun(this.testRun)
                .then((resBody) => {
                    if (resBody[0].status.code === 200) {
                        this.showSuccess(resBody[0].message, resBody[0].message);
                        this.editModalVisible = !this.editModalVisible;
                        this.editModalVisibleForEmail = false;
                    } else {
                        this.showErrorMsg('Test Run Update Unsuccessful.');
                    }
                })
                .catch((err) => {
                    console.log('update-res-err', err.response);
                });
        },
        onUpdateChipsListEmail(updatedChipsList) {
            // return console.log(updatedChipsList);
            this.testRun.reportRecepientsEmails = updatedChipsList;
        },
        clickEditEmailModal() {
            this.editModalVisibleForEmail = true;
        },
        clickEditModal() {
            this.editModalVisible = true;
        },
        _updateResolution(data) {
            console.log('_updateResolution');
            console.log(JSON.stringify(data));
            const elem = document.querySelector('#streamParent');
            this.deviceDimension = { height: data.height, width: data.width };
            this.videoHeight = data.height;
            this.videoWidth = data.width;


            if (elem) {
                const rect = elem.getBoundingClientRect();
                const parentHeight = rect.height;
                const parentWidth = rect.width;

                const deviceRatio = parseFloat(data.width) / parseFloat(data.height);

                let videoWidth = parseFloat(parentHeight) * deviceRatio;
                let videoHeight = parentHeight;
                // might be caused by device being landscape orientation
                if (videoWidth > parentWidth) {
                    videoWidth = parentWidth;
                    videoHeight = parseFloat(videoWidth / deviceRatio);

                }
                this.videoWidth = parseInt(videoWidth);
                this.videoHeight = parseInt(videoHeight);
                this.videoElement.style.height = `${parseInt(videoHeight)}px`;
                this.videoElement.style.width = `${parseInt(videoWidth)}px`;
            }
        },

        async checkcloudResponse() {
            const ids = { runId: this.$route.params.id, arn: this.testRun.activeCloudRun.latestArn };
            // await this.fetchCurrentTestRun();

            this.fetchWithArn(ids).then(async (response) => {
                const [data, error] = response;
                if (error) {
                    this.$toast.add({
                        severity: 'warn',
                        summary: 'Something went wrong!',
                        detail: '',
                        life: 3000,
                    });
                }

                if (data) {
                    if (Object.keys(data.data).length) {
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Cloud run executed successfully!',
                            detail: '',
                            life: 3000,
                        });
                        await new Promise((r) => setTimeout(r, 1000));
                        this.$router.push({ path: `/mobile-test-run-report/${data.data.testRunID}/cloud/${data.data._id}` });
                    }
                    else {
                        this.$toast.add({
                            severity: 'success',
                            summary: data.message,
                            detail: '',
                            life: 3000,
                        });
                    }
                }
            });
        },
        async fetchCurrentTestRun() {
            this.totalTestSteps = 0;
            try {
                const res = await this.getTestRun(this.$route.params.id);
                const [_testRun, err] = res;
                if (err) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: 'Something went wrong',
                        life: 3000,
                    });
                    return
                }
                const convertedTestRunObj = {
                    ..._testRun,
                    testCases: _testRun.testCases.map((_case) => {
                        return {
                            ..._case,
                            status: '',
                            executionResult: {},
                        };
                    }),
                };
                this.testRun = convertedTestRunObj;

                if (convertedTestRunObj.activeCloudRun.status.includes('pending') || convertedTestRunObj.activeCloudRun.status.includes('running')) {
                    this._resetNativeRunStates()
                    this.cloudRunActive = true;
                } else {
                    this.cloudRunActive = false;
                }

                this.testRun.testCases.forEach(($case) => {
                    // console.log($case.testSteps.length);
                    this.totalTestSteps = this.totalTestSteps + $case.testSteps.length;
                });

                if (this.testRun?.activeCloudRun?.status == 'pending' || this.testRun?.activeCloudRun?.status == 'running') {
                    this.projectStatus = this.testRun?.activeCloudRun?.status.charAt(0).toUpperCase() + this.testRun?.activeCloudRun?.status.slice(1);
                    this.checkCloudRun();
                }
            } catch (error) {
                console.error(error);
            }
        },
        _getHtmlElements() {
            this.streamParent = document.querySelector('#streamParent');
            this.videoElement = document.querySelector('#player');
            this.videoElement2 = document.querySelector('#player_2');
        },
        showSuccess(summary, detail) {
            this.$toast.add({
                severity: 'success',
                summary: summary,
                detail: detail,
                life: 3000,
            });
        },
        showError(detail) {
            this.$toast.add({
                severity: 'error',
                summary: 'Error Message',
                detail: detail,
                life: 3000,
            });
        },
        onRowExpand(e) {
            console.log('onRowExpand +++++++++++++++++ ', e.data);
        },
        getStepsBySlotProps(data) {
            let el = this.expandedRows.filter((cas) => cas._id == data._id)[0];
            return el.testSteps;
        },

        async _resetNativeRunStates(val) {
            if (!val) {
                this.percentage = [0, 0, 100];
                this.projectStatus = 'Pending';
                this.execTime = {
                    start: null,
                    end: null,
                    delayed: '',
                };
            }
            this.executedSteps = val ? [...this.executedSteps] : [];
            this.currentStepCnt = -1;
            this.activeRunningCaseIndex = -1;
            this.nativeRunPaused = false;
            this.activeRunningCase = null;
            this.activeRunningStep = { _id: 'asd' };
            this.percentage = [0, 0, 100];
            this.streamConnected = false;
            try {
                if (this.streamSocket && this.streamSocket.close)
                    await this.streamSocket.close()
            } catch (e) {
                console.log(e);
            }
            await $stopNativeAutomation();
            for (let ind = 0; ind < this.testRun.testCases.length; ind++) {
                this.testRun.testCases[ind].status = ''
            }
        },

        _deactivateCloudRun() {
            this.cloudRunActive = false;
        },

        async _cloudRunActivate() {
            const limitValidation = await this.checkExecutionRunLimit('cloud');
            if (!limitValidation.isValid) {
                this.showError(limitValidation.message);
                return
            }
            this.cloudRunActive = true;
            this._resetNativeRunStates()
            const runInfo = {
                testRunID: this.testRun._id,
                isTestRail: this.isTestRail
            }
            if (this.testRun.deviceType == 'ios') {
                this.activateIosCloudRun(runInfo).then(([res, err]) => {
                    if (err) {
                        this.cloudRunActive = false;
                        this.showError('Cloud run did not activated !');
                        return;
                    }
                    this.checkCloudRun(res.data.executedRunId);
                    this.showSuccess('Cloud run Started', 'Your cloud run has been started on AWS Device Farm Successfully !');
                });
            }
            else {
                this.activateCloudRun(runInfo).then(([res, err]) => {
                if (err) {
                    this.cloudRunActive = false;
                    this.showError('Cloud run did not activated !');
                    return;
                }
                this.checkCloudRun(res.data.executedRunId);
                this.showSuccess('Cloud run Started', 'Your cloud run has been started on AWS Device Farm Successfully !');
            });
            }
        },

        _deactivateNativeRun() {
            this.nativeRunActive = false;
            this._resetNativeRunStates("don't remove all values");
        },

        async _toggleStream(value) {
            const _pageContext = this;
            if (value === 'disconnect') {
                this.videoHeight = 0;
                this.videoWidth = 0;
            }
            if (value === 'disconnect' && this.streamSocket && this.streamSocket.close && this.streamSocket.readyState === 1) {
                this.streamConnected = false;
                try {
                    await this.streamSocket.close();
                } catch (e) {
                    console.log('error', e);
                }
                return;
            }
            //! if stream is connected, disconnect stream
            if (this.streamSocket && this.streamSocket.close && this.streamSocket.readyState === 1) {
                try {
                    await this.streamSocket.close();
                } catch (e) {
                    console.log('error', e);
                }
            }
            //! connecting stream
            try {
                if (this.deviceType === 'android') {
                    _pageContext.jmuxer = new JMuxer({
                        node: 'player',
                        mode: 'video',
                        flushingTime: 0,
                        fps: 60,
                        debug: false
                    });
                    // this._updateResolution(_pageContext.deviceDimension);
                    // this.wsavc.connect(`ws://${process.env.VUE_APP_STREAM_SOCKET_HOST}:${process.env.VUE_APP_STREAM_SOCKET_PORT}/device`);
                    this.streamSocket = new WebSocket(`ws://${process.env.VUE_APP_STREAM_SOCKET_HOST}:${process.env.VUE_APP_STREAM_SOCKET_PORT}/device`);
                    this.streamConnected = true;

                    this.streamSocket.binaryType = 'arraybuffer';

                    this.streamSocket.addEventListener('message', function (event) {
                        try {
                            _pageContext.jmuxer.feed({
                                video: new Uint8Array(event.data)
                            });
                        } catch (e) {
                            console.log('error', e)
                        }

                        if ((_pageContext.videoElement.paused || _pageContext.videoElement.ended) && _pageContext.videoElement.readyState >= 2) {
                            _pageContext.videoElement.play();
                        }
                    });
                    this.streamSocket.addEventListener('close', function (event) {
                        console.log('Socket Closed');
                        _pageContext.streamConnected = false;
                        _pageContext.jmuxer.destroy();
                        _pageContext.jmuxer = null;
                        _pageContext.videoElement.style.height = '0px';
                        _pageContext.videoElement.style.width = '0px';
                        _pageContext.streamSocket = null;
                    });
                    this.streamSocket.addEventListener('connect', function (event) {
                        console.log('Socket Connected');
                    });
                    this.streamSocket.addEventListener('error', function (e) {
                        console.log('Socket Error');
                    });
                } else {
                  this.streamInterval = setInterval(() => {
                      this.streamTimestamp = Date.now();
                  }, 1000);
                }
            } catch (e) {
                console.log('error starting stream', e);
            }
        },

        async $afterExecutionDone(executedRuns) {
            console.log('🏋️‍♂️ 🏋️‍♂️ 🏋️‍♂️ 🏋️‍♂️ 🏋️‍♂️ 🏋️‍♂️ 🏋️‍♂️ 🏋️‍♂️ 🏋️‍♂️ 🏋️‍♂️ ', 'ALL CASES EXECUTED SUCCESSFULLY', executedRuns);
            await $stopNativeAutomation();

            this.projectStatus = 'Finished';

            if (this.exeTimePaused.start && this.exeTimePaused.end) {
                let delayedTime = this.exeTimePaused.end - this.exeTimePaused.start;
                this.execTime.delayed = delayedTime;
            }


            this.sendNativeExecutionResults(executedRuns);
        },

        sendNativeExecutionResults(runResult) {
            let passedSteps = 0;
            let failedSteps = 0;

            runResult.testCases.forEach((cas) => {
                cas.status = 'passed';

                cas.testSteps.forEach((st) => {

                    if (st.error_obj.error) {
                        cas.status = 'failed';
                        failedSteps++
                    } else {
                        passedSteps++
                    }
                });
            });

            let clonedExecutedRun = cloneDeep(runResult, true)
            this.showSuccess("Native run executed Successfully !")
            this.nativeRunActive = false;

            let runObjectToSend = {
                name: clonedExecutedRun.name,
                appInfo: {
                    name: this.thisProject.appInfo.fileName || '',
                    activity: this.thisProject.appInfo.activity || '',
                },
                reportRecepientsEmails: this.testRun.reportRecepientsEmails,
                projectID: clonedExecutedRun.projectID,
                projectTitle: this.thisProject.title,
                testRunID: clonedExecutedRun._id,
                organizationId: this.loggedInUserInfo.organizationId,
                type: 'native',
                tags: clonedExecutedRun.tags,
                executionResult: {
                    executionTime: (this.execTime.end - this.execTime.start) - (this.execTime.delayed ? this.execTime.delayed : 0),
                    passedSteps,
                    failedSteps,
                    pendingSteps: this.totalTestSteps - passedSteps - failedSteps,
                    status: failedSteps > 0 ? 'failed' : 'passed'
                },

                testCases: clonedExecutedRun.testCases.map((cas) => {
                    let stPassed = 0;
                    let stFailed = 0;
                    let totalExecutedTime = cas.endTime - cas.startTime;

                    cas.testSteps.map((st, i) => {
                        st.error_obj.error ? stFailed++ : stPassed++;
                        // totalExecutedTime += st.executionResult.executionTime;
                        st.testStepID = st._id;
                        st.projectID = this.thisProject._id;
                        st.testCaseID = cas._id;

                        const attrToDel = ['id', '_id', '__v'];

                        for (let j = 0; j < attrToDel.length; j++) {
                            let attr = attrToDel[j];
                            delete st[attr];
                        }


                        let execStep = cas.testSteps[i];

                        if ('screenshot' in execStep) {
                            cas.testSteps[i].executionResult = {
                                ...cas.testSteps[i].executionResult,
                                screenshot: {
                                    uri: execStep.screenshot.executedImgUrl,
                                    mimeType: "image/png",
                                }
                            }
                        }

                    });

                    return {
                        name: cas.name,
                        description: cas.description,
                        projectID: cas.projectID,
                        tags: cas.tags,
                        testCaseID: cas._id,
                        type: 'native',
                        platform: cas.platform,
                        status: cas.status,
                        testrails_projectId: cas.testrails_projectId,
                        testrails_testcase: cas.testrails_testcase,
                        executionResult: {
                            executionTime: totalExecutedTime,
                            passedSteps: stPassed,
                            failedSteps: stFailed,
                            pendingSteps: cas.testSteps.length - stPassed - stFailed,
                        },
                        testSteps: cas.testSteps,
                        organizationId: this.loggedInUserInfo.organizationId
                    };
                }),
            };
            this.sendNativeExecutedTestRunResult(runObjectToSend).then(([data, err]) => {
                console.log(data, err);
            });

            if (this.isTestRail) {
                this.addTestRunToTestrails(runObjectToSend);
            }
        },
        toggle(event, data) {
            this.$refs[data._id][0].toggle(event);
        },
        handleSelectedBugType(issueType, data) {
            const payload = {
                _id: data._id,
                issue_type_id: issueType._id,
            };
            this.actionUpdateCase(payload)
                .then((response) => {
                    data.issue_type_id = issueType._id;
                    this.$refs[data._id][0].hide();
                })
                .catch((error) => { });
        },
        findIssueType(id) {
            let selectedIssueType =
                this.issueTypeList &&
                this.issueTypeList?.issueType?.find((issueType) => {
                    if (issueType?._id === id) {
                        return issueType;
                    }
                });
            return selectedIssueType?.title;
        },
        checkCloudRun(id) {
            const that = this;
            this.apiCallInterval = setInterval(function () {
                // console.log(that.testRun.activeCloudRun)
                that.isActivateCloudRun(id ? id : that.testRun.activeCloudRun.executedRunId)
                    .then(([res, err]) => {
                        if (res?.data?.status == 'finished') {
                            this.cloudRunActive = false;
                            that.goToMobileCloudReport(res.data._id);
                        }

                        if (res?.data?.status) {
                            that.projectStatus = res?.data?.status.charAt(0).toUpperCase() + res?.data?.status.slice(1);

                            if (this.cloudRunActive === false) this.cloudRunActive = true
                        }
                    })
                    .catch(error => {

                    })
            }, 10000);
        },
        goToMobileCloudReport(id) {
            this.$router.push({ path: `/mobile-test-run-report/${this.testRun._id}/cloud/${id}` });
        },
        async checkExecutionRunLimit(type) {
            const [consumption, errConsumtion] = await this.orgConsumption(this.loggedInUserInfo.organizationId);
            let limitValidation = { isValid: true, message: '' };
            if (consumption?.mobile?.testRun?.execution?.native?.left < 1 && type == 'native') {
                limitValidation = { isValid: false, message: 'Mobile Native Test Run Execution Limit End' };
                return limitValidation
            } else if (consumption?.mobile?.testRun?.execution?.cloud?.left < 1 && type == 'cloud') {
                limitValidation = { isValid: false, message: 'Mobile Cloud Test Run Execution Limit End' };
            }
            return limitValidation
        }
    },
    mixins: [nativeRunMixin, cloudRunMixin, mobileTestRunTestRailMixin, variableMixin],
};
</script>

<style lang='scss' scoped></style>
