<template>
  <div class="subscription-packages-info-container">
    <div class="header-wrapper">
      <div class="header-info">
        <p>Subscription Packages</p>
      </div>
      <div class="action-icons-wrapper">
        <router-link :to="{ path: '/create-subscription', query: { type: 'insert' } }">
          <Button class="new-package-btn" label="CREATE NEW PACKAGE"/>
        </router-link>
      </div>
    </div>

    <div class="content-wrapper">
      <div class="p-grid subscription-package-card" v-for="(pkg, k) in subscriptionList" :key="k">
        <div class="card">
          <div class="header-info">
            <h4>{{ pkg.title }}</h4>
            <!-- <p>{{ Math.round(pkg.validity_in_days / 30) }} {{ pkg.validity_in_days / 30 === 1 ? 'Month' : 'Months' }}</p> -->
            <h3>${{ pkg.price }}<span>{{ pkg.title.toLowerCase().includes("free") ? `${pkg.validity_in_days} days` : '/month' }}</span></h3>
            <p>{{ pkg.description }}</p>
          </div>

          <div class="panel-inner">
            <div class="subscription-package-card-line">
              <div class="line-left">
                                <span class="line-icon">
                                    <i class="fas fa-check"></i>
                                </span>
                <span class="line-label">{{
                    pkg && pkg.package_configuration && pkg.package_configuration.test_run_limit
                  }} Test Runs Per Month</span>
              </div>
            </div>

            <div class="subscription-package-card-line">
              <div class="line-left">
                                <span class="line-icon">
                                    <i class="fas fa-check"></i>
                                </span>
                <span class="line-label">{{
                    pkg && pkg.package_configuration && pkg.package_configuration.test_case_limit === 0 ? "Unlimited" : pkg && pkg.package_configuration && pkg.package_configuration.test_case_limit
                  }} Test Cases Per Month</span>
              </div>
            </div>

            <div class="subscription-package-card-line">
              <div class="line-left">
                                <span class="line-icon">
                                    <i class="fas fa-check"></i>
                                </span>
                <span class="line-label">{{
                    pkg && pkg.package_configuration && pkg.package_configuration.project_limit
                  }} Projects</span>
              </div>
            </div>

            <!-- <div class="subscription-package-card-line">
                <div class="line-left ">
                    <span class="line-icon">
                        <i class="fas fa-check"></i>
                    </span>
                    <span class="line-label">{{ pkg && pkg.package_configuration && pkg.package_configuration.sms_limit }} SMS</span>
                </div>
            </div>
            <div class="subscription-package-card-line">
                <div class="line-left ">
                    <span class="line-icon">
                        <i class="fas fa-check"></i>
                    </span>
                    <span class="line-label">{{ pkg && pkg.package_configuration && pkg.package_configuration.email_limit }} Email</span>
                </div>
            </div> -->

            <div class="subscription-package-card-line">
              <div class="line-left">
                                <span class="line-icon">
                                    <i v-if="pkg.is_upgrade" class="fas fa-check"></i>
                                    <i v-else class="fas fa-times"></i>
                                </span>
                <span class="line-label">Upgradable</span>
              </div>
            </div>
            <div class="subscription-package-card-line">
              <div class="line-left">
                                <span class="line-icon">
                                    <i v-if="pkg.is_renewal" class="fas fa-check"></i>
                                    <i v-else class="fas fa-times"></i>
                                </span>
                <span class="line-label">Renewable</span>
              </div>
            </div>
            <div class="subscription-package-card-line">
              <div class="line-left">
                                <span class="line-icon">
                                    <i v-if="pkg.mobile_app_testing" class="fas fa-check"></i>
                                    <i v-else class="fas fa-times"></i>
                                </span>
                <span class="line-label">Mobile App Testing</span>
              </div>
            </div>
            <div class="subscription-package-card-line">
              <div class="line-left">
                                <span class="line-icon">
                                    <i v-if="pkg.execution_in_cloud" class="fas fa-check"></i>
                                    <i v-else class="fas fa-times"></i>
                                </span>
                <span class="line-label">Test Executed in Cloud (SaaS)</span>
              </div>
            </div>
            <div class="subscription-package-card-line">
              <div class="line-left">
                                <span class="line-icon">
                                    <i v-if="pkg.web_testing" class="fas fa-check"></i>
                                    <i v-else class="fas fa-times"></i>
                                </span>
                <span class="line-label">Web Testing</span>
              </div>
            </div>
            <div class="subscription-package-card-line">
              <div class="line-left">
                                <span class="line-icon">
                                    <i v-if="pkg.mobile_web_testing" class="fas fa-check"></i>
                                    <i v-else class="fas fa-times"></i>
                                </span>
                <span class="line-label">Mobile Web Testing</span>
              </div>
            </div>
            <div class="subscription-package-card-line">
              <div class="line-left">
                                <span class="line-icon">
                                    <i v-if="pkg.test_rail_integration" class="fas fa-check"></i>
                                    <i v-else class="fas fa-times"></i>
                                </span>
                <span class="line-label">TestRail Integration</span>
              </div>
            </div>
            <div class="subscription-package-card-line">
              <div class="line-left">
                                <span class="line-icon">
                                    <i v-if="pkg.jira_integration" class="fas fa-check"></i>
                                    <i v-else class="fas fa-times"></i>
                                </span>
                <span class="line-label">JIRA Integration</span>
              </div>
            </div>
            <div class="subscription-package-card-line">
              <div class="line-left">
                                <span class="line-icon">
                                    <i v-if="pkg.chrome_browser_testing" class="fas fa-check"></i>
                                    <i v-else class="fas fa-times"></i>
                                </span>
                <span class="line-label">Chrome Browser Testing</span>
              </div>
            </div>
            <div class="subscription-package-card-line">
              <div class="line-left">
                                <span class="line-icon">
                                    <i v-if="pkg.chrome_extention" class="fas fa-check"></i>
                                    <i v-else class="fas fa-times"></i>
                                </span>
                <span class="line-label">Chrome Extension</span>
              </div>
            </div>
            <div class="subscription-package-card-line">
              <div class="line-left">
                                <span class="line-icon">
                                    <i v-if="pkg.local_browser_testing" class="fas fa-check"></i>
                                    <i v-else class="fas fa-times"></i>
                                </span>
                <span class="line-label">Test Executed in Local Browser</span>
              </div>
            </div>
            <div class="subscription-package-card-line">
              <div class="line-left">
                                <span class="line-icon">
                                    <i v-if="pkg.live_chat_technical_support" class="fas fa-check"></i>
                                    <i v-else class="fas fa-times"></i>
                                </span>
                <span class="line-label">Live Chat Teschnical Support 24/7</span>
              </div>
            </div>
            <div class="subscription-package-card-line">
              <div class="line-left">
                                <span class="line-icon">
                                    <i v-if="pkg.parallel_test_run" class="fas fa-check"></i>
                                    <i v-else class="fas fa-times"></i>
                                </span>
                <span class="line-label">Parallel Test Run</span>
              </div>
            </div>
            <div class="subscription-package-card-line">
              <div class="line-left">
                                <span class="line-icon">
                                    <i v-if="pkg.dedicated_resource" class="fas fa-check"></i>
                                    <i v-else class="fas fa-times"></i>
                                </span>
                <span class="line-label">1x Dedicated Resource</span>
              </div>
            </div>
          </div>
          <div class="hover-element">
            <Button for="file-input" icon="fas fa-edit" class="p-button-rounded p-button-outlined upload-icon"
                    @click="clickUpdatePkgBtn(pkg)"/>
            <Button icon="fas fa-trash" class="p-button-rounded p-button-outlined remove-icon"
                    @click="clickDeleteBtn(pkg && pkg._id)"/>
          </div>
        </div>
      </div>
      <!-- <div class="hover-element">
          <Button for="file-input" icon="fas fa-upload" class="p-button-rounded p-button-outlined upload-icon" />
          <Button icon="fas fa-times-circle" class="p-button-rounded p-button-outlined remove-icon" />
      </div> -->
      <!--
      <div class="next-btn">
          <router-link to="/test-cases"><Button icon="fas fa-chevron-right" class="p-button-rounded p-button-outlined"/></router-link>
      </div> -->
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
  components: {},
  name: 'SubscriptionPackages',
  data() {
    return {};
  },
  productService: null,
  interval: null,

  created() {
    this.fetchSubscriptionPackageList();
  },

  // computed: {},

  methods: {
    ...mapActions({
      getSubscriptionList: 'TjAdminSubscription/actionGetSubscriptionList',
      deleteSubsPackage: 'TjAdminSubscription/actionDeleteSubscriptionPackage',
      getSelectedSubscriptionPkgInfo: 'TjAdminSubscription/actionSelectedSubscriptionPkgInfo',
    }),

    clickDeleteBtn(packageID) {
      let reqDataObj = {
        _id: packageID,
      };

      this.deleteSubsPackage(reqDataObj)
          .then((resBody) => {
            console.log('in vue-save-res', resBody);
            if (resBody.status === 200) {
              this.showSuccess(resBody.message.MSG_SHORT, resBody.message.MSG_LONG);
              this.fetchSubscriptionPackageList();
            } else {
              this.showError(resBody && resBody.data && resBody.data.errorMsg);
            }
          })
          .catch((err) => {
            console.log('issue-res-err', err);
          });
    },

    clickUpdatePkgBtn(pkgObj) {
      this.getSelectedSubscriptionPkgInfo(pkgObj);
      console.log("🇦🇨 🇦🇨 🇦🇨 🇦🇨 🇦🇨 🇦🇨 🇦🇨 🇦🇨 🇦🇨 🇦🇨 🇦🇨 🇦🇨 🇦🇨 🇦🇨 = = = = > > >", pkgObj);
      this.$router.push({path: '/create-subscription', query: {type: 'update'}});
    },
    showSuccess(summary, detail) {
      this.$toast.add({severity: 'success', summary: summary, detail: detail, life: 3000});
    },

    showError(detail) {
      this.$toast.add({severity: 'error', summary: 'Error Message', detail: detail, life: 3000});
    },

    fetchSubscriptionPackageList() {
      this.getSubscriptionList();
    },
  },
  computed: {
    ...mapGetters({
      subscriptionList: 'TjAdminSubscription/subscriptionList',
    }),
    errorsSuccessList() {
      return true;
    },
    errorsFailedList() {
      return false;
      // return this.steps.filter((step) => step.error && step.errors.status === false) === this.steps.length;
    },
    //
  },
};
</script>
<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
