<template>
  <div class="login-container">
    <div class="verification-email-header">VERIFY EMAIL</div>
    <div class="check-email-icon"><img src="/assets/layout/images/Check-Email.svg" alt=""/></div>
    <div class="check-email-container p-fluid">
      <div class="check-email-container-header">Verify your email</div>
      <!-- <Button label="VEIRIFY EMAIL" @click="clickResetPasswordHandler" /> -->
      <div class="check-email-container-detail" v-if="resendEmail">
        We’ve resend an email to <b>{{ userEmail }}</b> to verify your email address and activate your account.
      </div>
      <div class="check-email-container-detail" v-else>
        We’ve sent an email to <b>{{ userEmail }}</b> to verify your email address and activate your account.
      </div>
      <div class="check-email-link">
        <a class="resendlink" @click="clickVerifyEmailHandler()"><u>Click here</u></a> if you did not receive an email.
      </div>
      <!-- <router-link to="/login"><Button class="back-to-sign-in-link" label="BACK TO SIGN IN"></Button></router-link> -->
      <div class="check-email-sign-up-link">
        If you have an account?
        <router-link to="/login"><u> Sign In</u></router-link>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'VerifyEmail',
  computed: {
    ...mapGetters({userEmail: 'auth/newVerifyEmail'}),
  },
  data() {
    return {
      resendEmail: false,
    };
  },
  methods: {
    ...mapActions({
      resendVerifyEmailToLink: 'auth/actionResendVerifyEmailLink',
    }),

    showSuccess(summary, detail) {
      this.$toast.add({severity: 'success', summary: summary, detail: detail, life: 3000});
    },

    showError(detail) {
      this.$toast.add({severity: 'error', summary: 'Error Message', detail: detail, life: 3000});
    },

    async clickVerifyEmailHandler() {
      let bodyData = {email: this.userEmail};
      await this.resendVerifyEmailToLink(bodyData)
          .then((resBody) => {
            if (resBody.status === 200) {
              this.resendEmail = true;
              this.showSuccess('Resend', 'Email Sent');
            } else if (resBody.status === 409) {
              this.showSuccess('Already Verified', 'Email Already Verified');
              // setTimeout(() => this.$router.push({ path: '/login' }), 5000);
            } else {
              this.showError('Email Sending Failed');
            }
          })
          .catch((err) => {
            console.log('Verified email error', err.response);
          });
    },
  },
};
</script>

<style>
</style>