<template>
  <div class="wizard-body">
    <div class="wizard-wrapper">
      <div class="wizard-container">
        <div class="wizard-header">
          <div class="wizard-tabs-container">
            <div class="wizard-tab register-tab" :class="{'active-tab': activeTab !== null}"
                 @click="clickNext('register')">
              <div class="tab-progressbar"></div>
              <div class="tab-header"></div>
            </div>
            <div class="wizard-tab tier-tab" :class="{'active-tab': activeTab === 'tier' || activeTab === 'payment'}"
                 @click="clickNext('tier')">
              <div class="tab-progressbar"></div>
              <div class="tab-header"></div>
            </div>
            <div class="wizard-tab payment-tab" :class="{'active-tab': activeTab === 'payment'}"
                 @click="clickNext('payment')">
              <div class="tab-progressbar"></div>
              <div class="tab-header"></div>
            </div>
          </div>
        </div>

        <div class="wizard-content register" :class="{'active-content': activeContent === 'register'}">
          <div class="content-header">
            <div class="p-grid">
              <div class="p-col-6 title">
                <h1>REGISTER</h1>
                <span>Please complete steps to register</span>
              </div>
              <div class="p-col-6 icon">
                <img src="assets/layout/images/extensions/icon-register.svg" class="layout-image" alt="avalon-layout"/>
              </div>
            </div>
          </div>

          <div class="content">
            <div class="p-grid forms">
              <div class="p-col-12 p-md-6">
                <label for="name">Username</label>
                <InputText id="name" type="text" placeholder="Name" class="form-element"/>

                <label for="email">Email</label>
                <InputText id="email" type="text" placeholder="Email" class="form-element"/>

                <label for="password">Password</label>
                <InputText id="password" type="password" placeholder="Password" class="form-element"/>
              </div>

              <div class="p-col-12 p-md-6">
                <label for="timezone">Timezone</label>
                <Dropdown id="timezone" :options="dropdownOptions1" optionLabel="name" v-model="selectDropdownOptions1"
                          placeholder="Select Time Zone" class="form-element"></Dropdown>

                <label for="popup">Birthdate</label>
                <Calendar id="popup" v-model="birthdate" class="form-element" placeholder="Birthdate"></Calendar>

                <label for="avalon">Where did you hear Avalon</label>
                <Dropdown id="avalon" :options="dropdownOptions2" optionLabel="label" v-model="selectDropdownOptions2"
                          placeholder="Where did you hear Avalon" class="form-element"></Dropdown>
              </div>
            </div>
            <div class="p-grid button">
              <Button class="continue-button" label="CONTINUE" style="width: 100%" @click="clickNext('tier')"></Button>
            </div>
          </div>
        </div>


        <div class="wizard-content tier" :class="{'active-content': activeContent === 'tier'}">
          <div class="content-header">
            <div class="p-grid">
              <div class="p-col-6 title">
                <h1>CHOOSE TIER</h1>
                <span>Now choose your account type</span>
              </div>
              <div class="p-col-6 icon">
                <img src="assets/layout/images/extensions/icon-tier@2x.png" class="layout-image" alt="avalon-layout"/>
              </div>
            </div>
          </div>


          <div class="content">
            <div class="p-grid cards">
              <div class="p-col-12 p-md-4">
                <div class="tier-card basic" :class="{'active-tier-card': activeCard === 'basic'}"
                     @click="selectTier('basic')">
                  <div class="tier-card-header">
                    <div class="p-col-8">
                      <h1>BEGINNER</h1>
                      <span>For starters and side projects</span>
                    </div>
                    <div class="p-col-4 icon">
                      <i class="pi pi-check"></i>
                    </div>
                  </div>
                  <div class="tier-card-content">
                    <div class="row even">
                      Responsive
                    </div>
                    <div class="row">
                      Push Mesaages
                    </div>
                  </div>
                </div>
              </div>

              <div class="p-col-12 p-md-4 ">
                <div class="tier-card pro" :class="{'active-tier-card': activeCard === 'pro'}"
                     @click="selectTier('pro')">
                  <div class="tier-card-header">
                    <div class="p-col-8">
                      <h1>PROFFESIONAL</h1>
                      <span>For companies</span>
                    </div>
                    <div class="p-col-4 icon">
                      <i class="pi pi-check"></i>
                    </div>
                  </div>
                  <div class="tier-card-content">
                    <div class="row even">
                      Responsive
                    </div>
                    <div class="row">
                      Push Mesaages
                    </div>
                    <div class="row even">
                      7/24 Support
                    </div>
                  </div>
                </div>
              </div>

              <div class="p-col-12 p-md-4">
                <div class="tier-card pro-plus" :class="{'active-tier-card': activeCard === 'pro-plus'}"
                     @click="selectTier('pro-plus')">
                  <div class="tier-card-header">
                    <div class="p-col-8">
                      <h1>ENTERPRISE</h1>
                      <span>For custom needs</span>
                    </div>
                    <div class="p-col-4 icon">
                      <i class="pi pi-check"></i>
                    </div>
                  </div>
                  <div class="tier-card-content">
                    <div class="row even">
                      Responsive
                    </div>
                    <div class="row">
                      Push Mesaages
                    </div>
                    <div class="row even">
                      7/24 High Priority Support
                    </div>
                    <div class="row">
                      Free Shipping
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-grid button">
              <Button class="continue-button" label="CONTINUE" style="width: 100%"
                      @click="clickNext('payment')"></Button>
            </div>
          </div>
        </div>


        <div class="wizard-content payment" :class="{'active-content': activeContent === 'payment'}">
          <div class="p-grid">
            <div class="p-col-12 p-md-8 payment-info">
              <div class="content-header">
                <div class="p-grid">
                  <div class="p-col-12 title">
                    <h1>PAYMENT</h1>
                    <span>Give me the money</span>
                  </div>
                </div>
              </div>
              <div class="content">
                <div class="p-grid forms">
                  <div class="p-col-12">
                    <label for="cardName">Cardholder Name</label>
                    <InputText id="cardName" type="text" placeholder="Cardholder Name" class="form-element"/>
                  </div>

                  <div class="p-col-12 p-md-6">
                    <label for="cardNumber">Card Number</label>
                    <InputText id="cardNumber" type="text" placeholder="Card Number" class="form-element"/>
                  </div>

                  <div class="p-col-6 p-md-3">
                    <label for="cardDate">Date</label>
                    <InputText id="cardDate" type="text" placeholder="MO/YE" class="form-element"/>
                  </div>

                  <div class="p-col-6 p-md-3">
                    <label for="ccv">CCV</label>
                    <InputText id="ccv" type="text" placeholder="XYZ" class="form-element"/>
                  </div>
                </div>

                <div class="p-grid checkbox">
                  <Checkbox v-model="checked" :binary="true" class="p-mr-2"></Checkbox>
                  <label>Save credit card information for future usage</label>
                </div>
                <div class="p-grid button">
                  <Button label="CONTINUE" style="width: 100%"></Button>
                </div>
              </div>
            </div>

            <div class="p-col-12 p-md-4 order-info">
              <div class="order order-default">
                <div class="summary">
                  <div class="p-grid">
                    <div class="p-col-12">
                      <h1>ORDER SUMMARY</h1>
                    </div>
                  </div>
                  <div class="p-grid">
                    <div class="p-col-8">Tier -</div>
                    <div class="p-col-4 price">$0.00</div>
                  </div>
                  <div class="p-grid">
                    <div class="p-col-8">VAT (%18)</div>
                    <div class="p-col-4 price">$0.00</div>
                  </div>
                </div>
                <div class="p-grid total">
                  <div class="p-col-8">Total</div>
                  <div class="p-col-4 price">$0.00</div>
                  <div class="p-col-12">
                    Please select one tier.
                  </div>
                </div>
              </div>
              <div class="order order-basic" :class="{'selected-order': activeCard === 'basic'}">
                <div class="summary">
                  <div class="p-grid">
                    <div class="p-col-12">
                      <h1>ORDER SUMMARY</h1>
                    </div>
                  </div>
                  <div class="p-grid">
                    <div class="p-col-8">Tier - Basic</div>
                    <div class="p-col-4 price">$5.00</div>
                  </div>
                  <div class="p-grid">
                    <div class="p-col-8">VAT (%18)</div>
                    <div class="p-col-4 price">$0.90</div>
                  </div>

                </div>
                <div class="p-grid total">
                  <div class="p-col-8">Total</div>
                  <div class="p-col-4 price">$5.90</div>
                </div>
              </div>
              <div class="order order-pro" :class="{'selected-order': activeCard === 'pro'}">
                <div class="summary">
                  <div class="p-grid">
                    <div class="p-col-12">
                      <h1>ORDER SUMMARY</h1>
                    </div>
                  </div>
                  <div class="p-grid">
                    <div class="p-col-8">Tier - Pro</div>
                    <div class="p-col-4 price">$25.00</div>
                  </div>
                  <div class="p-grid">
                    <div class="p-col-8">VAT (%18)</div>
                    <div class="p-col-4 price">$4.50</div>
                  </div>
                </div>
                <div class="p-grid total">
                  <div class="p-col-8">Total</div>
                  <div class="p-col-4 price">$29.50</div>
                </div>
              </div>
              <div class="order order-pro-plus" :class="{'selected-order': activeCard === 'pro-plus'}">
                <div class="summary">
                  <div class="p-grid">
                    <div class="p-col-12">
                      <h1>ORDER SUMMARY</h1>
                    </div>
                  </div>
                  <div class="p-grid">
                    <div class="p-col-8">Tier - Pro+</div>
                    <div class="p-col-4 price">$50.00</div>
                  </div>
                  <div class="p-grid">
                    <div class="p-col-8">VAT (%18)</div>
                    <div class="p-col-4 price">$9.00</div>
                  </div>
                </div>
                <div class="p-grid total">
                  <div class="p-col-8">Total</div>
                  <div class="p-col-4 price">$59.00</div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeContent: 'register',
      activeTab: 'register',
      activeCard: 'pro',
      selectDropdownOptions1: null,
      selectDropdownOptions2: null,
      birthdate: null,
      checked: false,
      dropdownOptions1: [
        {name: 'UTC-12.00', code: '-12'},
        {name: 'UTC-11.00', code: '-11'},
        {name: 'UTC-10.00', code: '-10'},
        {name: 'UTC-09.30', code: '-93'},
        {name: 'UTC-09.00', code: '-09'},
        {name: 'UTC-08.00', code: '-08'},
        {name: 'UTC-07.00', code: '-07'},
        {name: 'UTC-06.00', code: '-06'},
        {name: 'UTC-05.00', code: '-05'},
        {name: 'UTC-04.00', code: '-04'},
        {name: 'UTC-03.30', code: '-33'},
        {name: 'UTC-03.00', code: '-03'},
        {name: 'UTC-02.00', code: '-02'},
        {name: 'UTC-01.00', code: '-01'},
        {name: 'UTC-+00.00', code: '-00 '},
        {name: 'UTC+01.00', code: '+01'},
        {name: 'UTC+02.00', code: '+02'},
        {name: 'UTC+03.00', code: '+03'},
        {name: 'UTC+03.30', code: '+33'},
        {name: 'UTC+04.00', code: '+04'},
        {name: 'UTC+04.30', code: '+43'},
        {name: 'UTC+05.00', code: '+05'},
        {name: 'UTC+05.30', code: '+53'},
        {name: 'UTC+05.45', code: '+54'},
        {name: 'UTC+06.00', code: '+06'},
        {name: 'UTC+06.30', code: '+63'},
        {name: 'UTC+07.00', code: '+07'},
        {name: 'UTC+08.00', code: '+08'},
        {name: 'UTC+08.45', code: '+84'},
        {name: 'UTC+09.00', code: '+09'},
        {name: 'UTC+09.30', code: '+93'},
        {name: 'UTC+10.00', code: '+10'},
        {name: 'UTC+10.30', code: '+13'},
        {name: 'UTC+01.00', code: '+11'},
        {name: 'UTC+01.00', code: '+12'},
        {name: 'UTC+01.00', code: '+24'},
        {name: 'UTC+01.00', code: '+13'},
        {name: 'UTC+01.00', code: '+14'},
      ],
      dropdownOptions2: [
        {label: 'Blogs', value: 'Blogs'},
        {label: 'Google Ads', value: 'google'},
        {label: 'Your Forum', value: 'prime-forum'},
        {label: 'Youtube', value: 'Youtube'},
        {label: 'Reddit', value: 'Reddit'},
        {label: 'Events', value: 'Events'},
        {label: 'Other', value: 'Other'}
      ]
    }
  },
  methods: {
    clickNext(step) {
      if (step === 'register') {
        if (this.activeTab === 'register') {
          this.activeTab = 'register';
          this.activeContent = 'register';
        } else if (this.activeTab === 'tier') {
          setTimeout(() => {
            this.activeContent = 'register';
          }, 600);
          this.activeTab = 'register';
        } else {
          this.activeTab = 'tier';
          setTimeout(() => {
            this.activeTab = 'register';
          }, 600);
          setTimeout(() => {
            this.activeContent = 'register';
          }, 1200);
        }
      }
      if (step === 'tier') {
        this.activeTab = 'tier';
        setTimeout(() => {
          this.activeContent = 'tier';
        }, 600);
      }

      if (step === 'payment') {
        if (this.activeTab === 'payment') {
          this.activeTab = 'payment';
          this.activeContent = 'payment';
        } else if (this.activeTab === 'tier') {
          setTimeout(() => {
            this.activeContent = 'payment';
          }, 600);
          this.activeTab = 'payment';
        } else {
          this.activeTab = 'tier';
          setTimeout(() => {
            this.activeTab = 'payment';
          }, 600);
          setTimeout(() => {
            this.activeContent = 'payment';
          }, 1200);
        }
      }
    },

    selectTier(card) {
      this.activeCard = card;
      setTimeout(() => {
        this.activeTab = 'payment';
      }, 600);
      setTimeout(() => {
        this.activeContent = 'payment';
      }, 600);
    }
  }
}
</script>