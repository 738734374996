import CaseBuilder from './../case/index';
import ctrl from './control_keys';
import customValidationList from './custom_validation_list';
import guest from '@/router/guest';
const moment = require("moment");
const stepsWhichShouldNotBeAddedToTheListBeforePropertiesAreAdded = [customValidationList.Navigate.action, customValidationList.Sleep.action, customValidationList.URL_Validation.action, customValidationList.ValidateHtmlAttribute.action, customValidationList.ValidateCssProperty.action];

/**
 * Case Builder factory function
 * @param {*} vm
 * @param {*} data
 * @returns
 */
export const caseBinder = (vm, data) => {
    const lastIndex = Array.from(vm.steps).length - 1;

    // console.log("🦓 🦓 🦓 🦓 🦓 🦓 🦓 🦓 🦓 🦓 CASE BINDER", data);

    if (vm.steps[lastIndex]) {
        vm.steps[lastIndex].selector == data.selector;
    }

    if (vm.exceptEvents.includes(data.action) && vm.steps.length === 0) {
        var caseBuilder = createCase(vm, data);
        // console.log("IN IF 0");

        if(!stepsWhichShouldNotBeAddedToTheListBeforePropertiesAreAdded.includes( caseBuilder.action )) {
          vm.steps.push(caseBuilder);
        }

        return caseBuilder;
    } else if (vm.steps[lastIndex] && data.action === 'dblclick' && vm.steps[lastIndex].selector == data.selector) {
        const start = lastIndex - 1;
        const end = lastIndex;

        if (vm.steps[start].selector === vm.steps[end].selector && vm.steps[start].action === 'click' && vm.steps[end].action === 'click') {
            var caseBuilder = createCase(vm, data);
            // console.log("IN IF 1");

            vm.steps.push(caseBuilder);
        }
        return caseBuilder;
    } else if (vm.steps[lastIndex] && data.action === 'input' && vm.steps[lastIndex].action === 'input') {
        var caseBuilder = createCase(vm, data);
        if (vm.steps[lastIndex].action === 'input' && vm.steps[lastIndex].tag_name.toLowerCase() !== 'select') {
            vm.steps.pop();
            // console.log("IN IF 2");

            vm.steps.push(caseBuilder);
        } else {
            // console.log("IN ELSE 0");

            vm.steps.push(caseBuilder);
        }

        return caseBuilder;
    } else if (lastIndex >= 0 && vm.steps[lastIndex].selector == data.selector && data.action == 'scroll' && vm.steps[lastIndex].action == 'scroll') {
        var caseBuilder = createCase(vm, data);
        // console.log("IN ELSE IF 0");

        vm.steps.pop();
        vm.steps.push(caseBuilder);

        return caseBuilder;
    } else if (data.action == 'resize' && vm.steps[lastIndex].action == 'resize') {
        var caseBuilder = createCase(vm, data);
        vm.steps.pop();
        // console.log("IN ELSE IF 1");

        vm.steps.push(caseBuilder);
        return caseBuilder;
    } else if (data.action == 'dragover' && vm.steps[lastIndex].action == 'dragover') {
        var caseBuilder = createCase(vm, data);
        vm.steps.pop();
        // console.log("IN ELSE IF 2");
        vm.steps.push(caseBuilder);
        return caseBuilder;
    } else if (data.action == 'click') {
        var attributes = data.attributes;
        var attribute = attributes.find((attribute) => attribute.type);
        var lastStep = {};
        if (lastStep && vm.steps.length) {
            lastStep = vm.steps[lastIndex];
        }

        if (lastStep && lastStep.key_code === 13 && attribute && attribute.type === "submit" && (data.tag_name == 'INPUT' || data.tag_name == 'BUTTON')) {
            const updatedEnterStep = { ...lastStep };
            updatedEnterStep.isSubmittable = true;
            vm.steps[vm.steps.length - 1] = updatedEnterStep;
        } else if (lastStep.action !== customValidationList.ValidateCheckbox.action) {
            if (attribute && attribute.type === 'checkbox') {
                var caseBuilder = createCase(vm, data);
                // if (data.value && lastStep.value) {
                //     const lastVal = String(lastStep.value).slice(1, -1);
                //     const dataVal = String(data.value).slice(1, -1);

                //     if (lastVal !== dataVal) {
                //         console.log('Boolean', data.value.slice(1, -1));
                //     }
                // }

                if (lastStep.tag_name && ['span', 'label'].includes(lastStep.tag_name.toLowerCase())) {
                    vm.steps.pop();
                }

                // console.log("IN ELSE IF 3 IF STATEMENT");

                vm.steps.push(caseBuilder);
                return caseBuilder;
            } else {
                var caseBuilder = createCase(vm, data);
                // console.log("IN ELSE IF 3 ELSE STATEMENT");

                vm.steps.push(caseBuilder);
                return caseBuilder;
            }
        } else {
            // console.log("IN ELSE FINAL");

            var caseBuilder = createCase(vm, data);
            vm.steps.push(caseBuilder);
        }
    } else {
        var caseBuilder = createCase(vm, data);
        // console.log("IN ELSE FINAL =>> ", caseBuilder);
        if(!stepsWhichShouldNotBeAddedToTheListBeforePropertiesAreAdded.includes( caseBuilder.action )) {
          vm.steps.push(caseBuilder);
        }
        return caseBuilder;
    }
};

// draw bounding rect if both bounding_react and screenshot_src are available
function drawBoundingRect(data) {
    if (data.bounding_react && data.screenshot_src && data.tabInfo && data.tabInfo.size) {
        const img = new Image();
        img.src = data.screenshot_src;
        img.onload = () => {
            const canvas = document.createElement('canvas');
            // if there's tabInfo in data, use that for height and width from tabInfo.size
            canvas.width = data.tabInfo.size.width;
            canvas.height = data.tabInfo.size.height;

            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, data.tabInfo.size.width, data.tabInfo.size.height);
            // Check if the bounding_react is within the image boundaries
            if (data.bounding_react.x >= 0 && data.bounding_react.y >= 0 && data.bounding_react.x + data.bounding_react.width <= img.width && data.bounding_react.y + data.bounding_react.height <= img.height) {
                ctx.beginPath();
                ctx.lineWidth = '3';
                ctx.strokeStyle = 'red';
                ctx.rect(data.bounding_react.x, data.bounding_react.y, data.bounding_react.width, data.bounding_react.height);
                ctx.stroke();
            }
            data.screenshot_src = canvas.toDataURL("image/webp", 0.5);
        };
    }
    return data;
}


export const caseBuilder = ({
    url = null,
    selector = null,
    attributes = [],
    xpath = null,
    xpath_collections = [],
    bounding_react = {},
    action = null,
    value = null,
    tag_name = null,
    key_code = null,
    href = null,
    coordinates = null,
    screenshot_src = null,
    text = null,
    tabInfo = null,
    variable_id = null,
    apiInfo = null,
    validationInfo = null,
    title = null,
    name = null,
    target = {},
    is_drafted= false,
    frameLocation = {},
    screen_size = {},
    child_node = [],
    parent_nodes = [],
    broken_links = [],
    frameIndex = null,
    eventInfo = null,
}) => {
    let newCase = new CaseBuilder(url, selector, attributes, xpath, xpath_collections, bounding_react, action, value, tag_name, key_code, href, coordinates, screenshot_src, tabInfo, variable_id, apiInfo, validationInfo, target, frameLocation,  text, title, name, screen_size, child_node, parent_nodes, broken_links, frameIndex, eventInfo, is_drafted).build();
    newCase = drawBoundingRect(newCase);
    return newCase;
};
/**
 * Create Case Builder
 * @param {*} vm
 * @param {*} data
 * @returns
 */
export const createCase = (vm, data) => {
    return caseBuilder(data);
};
/**
 * Get os name
 * @returns {boolean}
 */
export const getOsName = () => {
    var OSName = 'Unknown';

    if (window.navigator.userAgentData.platform == 'Windows') OSName = 'Windows';
    if (window.navigator.userAgentData.platform == 'Linux') OSName = 'Linux';
    if (window.navigator.userAgentData.platform == 'macOS') OSName = 'MacOs';
    // if (window.navigator.userAgent.indexOf('Windows NT 6.2') != -1) OSName = 'Windows 8';
    // if (window.navigator.userAgent.indexOf('Windows NT 6.1') != -1) OSName = 'Windows 7';
    // if (window.navigator.userAgent.indexOf('Windows NT 6.0') != -1) OSName = 'Windows Vista';
    // if (window.navigator.userAgent.indexOf('Windows NT 5.1') != -1) OSName = 'Windows XP';
    // if (window.navigator.userAgent.indexOf('Windows NT 5.0') != -1) OSName = 'Windows 2000';
    // if (window.navigator.userAgent.indexOf('Mac') != -1) OSName = 'Mac/iOS';
    // if (window.navigator.userAgent.indexOf('X11') != -1) OSName = 'UNIX';
    // if (window.navigator.userAgent.indexOf('Linux') != -1) OSName = 'Linux';

    return OSName;
};
/**
 * Get browser name from agent
 * @returns
 */
export const getBrowserName = () => {
    var browser = '';
    if ((navigator.userAgent.indexOf('Opera') || navigator.userAgent.indexOf('OPR')) != -1) {
        browser = 'Opera';
    } else if (navigator.userAgent.indexOf('Chrome') != -1) {
        browser = 'Chrome';
    } else if (navigator.userAgent.indexOf('Safari') != -1) {
        browser = 'Safari';
    } else if (navigator.userAgent.indexOf('Firefox') != -1) {
        browser = 'Firefox';
    } else if (navigator.userAgent.indexOf('MSIE') != -1 || !!document.documentMode == true) {
        //IF IE > 10
        browser = 'IE';
    } else {
        browser = 'unknown';
    }
    return browser;
};
/**
 * This function is used to get the current date and time
 * @param {*} fn
 * @param {*} t
 */
export const Timer = (fn, t) => {
    var vm = this;
    /**
     *
     */
    var timerObj = setInterval(() => {
        fn(vm);
    }, t);

    this.stop = function () {
        if (timerObj) {
            clearInterval(timerObj);
            timerObj = null;
        }
        return this;
    };

    // start timer using current settings (if it's not already running)
    this.start = function () {
        if (!timerObj) {
            this.stop();
            timerObj = setInterval(() => {
                fn(vm);
            }, t);
        }
        return this;
    };

    // start with new or original interval, stop current interval
    this.reset = function (newT = t) {
        t = newT;
        return this.stop().start();
    };
};
export const getBrowser = () => {
    return chrome || browser;
};

/**
 * Runner for the test cases
 */
export class Runner {
    /**
     * Test Suite Runner
     * @param {*} _port
     * @returns
     */
    constructor(_port) {
        if (!Runner.instance) {
            this._events = [];
            Runner.instance = this;
            _port.onMessage.addListener((message) => {
                const { type } = message;
                console.log('type', message.type, message.from);

                if (message.from === 'background') {
                    switch (type) {
                        case ctrl.TABID:
                            this._callableFunction(ctrl.TABID, message);
                            break;
                        case ctrl.RECEIVED_AUTOMATION_CONFIRMATION:
                            this._callableFunction(ctrl.RECEIVED_AUTOMATION_CONFIRMATION, message);
                            break;
                        case ctrl.CURRENT_AUTOMATION_STEP:
                            this._callableFunction(ctrl.CURRENT_AUTOMATION_STEP, message);
                            break;
                        case ctrl.BEFORE_NAVIGATION:
                            this._callableFunction(ctrl.BEFORE_NAVIGATION, message);
                            break;
                        case ctrl.COMPLETED_STEP:
                            this._callableFunction(ctrl.COMPLETED_STEP, message);
                            break;
                        case ctrl.RECEIVED_TEST_RESULT:
                            this._callableFunction(ctrl.RECEIVED_TEST_RESULT, message);
                            break;
                        case ctrl.PAUSE_AUTOMATION:
                            this._callableFunction(ctrl.PAUSE_AUTOMATION, message);
                            break;
                        case ctrl.RESTART_AUTOMATION:
                            this._callableFunction(ctrl.RESTART_AUTOMATION, message);
                            break;
                        case ctrl.WINDOWCLOSED:
                            this._callableFunction(ctrl.WINDOWCLOSED, message);
                            break;
                        case ctrl.PAUSE:
                            this._callableFunction(ctrl.PAUSE, message);
                            break;
                        case ctrl.PlAY_AGAIN:
                            this._callableFunction(ctrl.PlAY_AGAIN, message);
                            break;
                        default:
                            throw new Error('Please add valid event type');
                    }
                }
            });
        }
        return Runner.instance;
    }

    /**
     * Callable function to call any function as private
     * @param {*} type
     * @param {*} data
     */
    _callableFunction(type, data) {
        if (this._events[type]) {
            if (typeof this._events[type] === 'function') {
                this._events[type].call(this, data);
            } else {
                throw new Error('Callable Function Needed');
            }
        }
    }

    /**
     * Add event listener dynamically
     * @param {*} event
     * @param {*} callback
     */
    addListener(event, callback = null) {
        this._events[event] = callback;
    }
}

/**
 * Date Formatter with moment instance
 * @param {*} date
 * @returns
 */
export const dateFormatter = (date) => {
    const moment = require('moment');
    let formattedDate = moment(date).format('M.D.YYYY');
    return formattedDate;
};

/**
 * Date Formatter with moment instance
 * @param {*} date
 * @returns
 */
export const standardDateFormatter = (date) => {
    const moment = require('moment');
    let formattedDate = moment(date).format('MMM D, YYYY');
    return formattedDate;
};
/**
 * Date Time Formatter with moment instance
 * @param {*} date
 * @returns
 */
export const dateTimeFormatter = (date) => {
    const moment = require('moment');
    let formattedDate = moment(date).format('MMM D, YYYY - hh:mm A');
    return formattedDate;
};
/**
 * Time Formatter hh:mma format return with moment instance
 * @param {*} time
 * @returns
 */
export const timeFormatter = (time) => {
    const moment = require('moment');
    let formattedDate = moment(time).format('hh:mm A');
    return formattedDate;
};
/**
 * Generate MongoObject Id to set as _id
 * @returns
 */
export const mongoObjectId = function () {
    var timestamp = ((new Date().getTime() / 1000) | 0).toString(16);
    return (
        timestamp +
        'xxxxxxxxxxxxxxxx'
            .replace(/[x]/g, function () {
                return ((Math.random() * 16) | 0).toString(16);
            })
            .toLowerCase()
    );
};
/**
 * Flat the array of any steps
 * @param {Array<any>} st
 * @param {Array<any>} steps
 * @param {string} type
 */
export const childrenTree = (st, steps, type = 'testStep') => {
    var i = 0;
    if (st && st.length > 0) {
        while (i < st.length) {
            if (st[i].type === type) {
                steps.push(st[i]);
            }
            if (st && st[i].children && st[i].children.length > 0) {
                childrenTree(st[i].children, steps, type);
            }
            i++;
        }
    }
};
/**
 * Check Permission
 * @param {*} to
 * @param {*} role
 * @returns
 */
export const hasPermission = ({ meta, path }, role, except = '/access-denied') => {
    const guestPathList = guest.map((g) => g.path);
    if (guestPathList.includes(path)) {
        return true;
    } else {
        if (meta && meta.roles) {
            return meta.roles.includes(role);
        } else {
            return false;
        }
    }
};

/**
 *  Get Browser chrome or firefox instance to connect extension
 *  individually check for chrome or firefox
 */
export const getBrowserInstance = () => {
    let browserInstance = undefined;

    if (getBrowserName() === 'Chrome') {
        browserInstance = chrome;
    }
    return browserInstance;
};

export const checkExtentionExists = async function (extId) {
    let resp;

    getBrowser().runtime.sendMessage(extId, 'installed?', async function (response) {
        if (response) {
            // The extension is installed
            console.log("EXTENTION INSTALLED +++++ 🫂 🫂 🫂 🫂 🫂 🫂 🫂 🫂 🫂 🫂 🫂 🫂 🫂 🫂 ", response);
            resp = response
        } else {
            // The extension is not installed or disabled
            console.log("EXTENTION NOT INSTALLED +++++ 🗣 🗣 🗣 🗣 🗣 🗣 🗣 🗣 🗣 🗣 🗣 🗣 🗣 ");
            resp = false
        }
    });

    return resp;
}

export const browserVersion = () => {
    var ua, versionSplitted, M;

    ua = window.navigator.userAgent;
    M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

    if (M[0] && M[0].length) {
        versionSplitted = M[0].split('/');
        return versionSplitted[1]
    } else {
        return "0.00"
    }
}
export const stringPrefix = (str, len = 15) => {
    const dotprefix = str.length > len ? '....' : '';
    return str && String(str).substr(0, len) + dotprefix;
}

export const generateCustomRunId = (str) => {
    let serial = String(str);
    const pad = (str, max) => {
        return str.toString().length < max ? pad("0" + str, max) : str;
    };
    if (serial) {
        return "TR-" + pad(serial, 8);
    } else {
        return "TR-" + pad(0, 8);
    }
};

export const dateComparison = (validity_expire) => {
    const newDate = moment().format('YYYY-MM-DDTHH:mm:ss.SSSSZ')
    const expDate = moment(validity_expire)._i
    const isExpired = moment(newDate).isAfter(moment(expDate));
    return isExpired;
};

export const sendKeepAliveMessage = (port) => {
    if (!port) return;
    return port.postMessage({ type: "keep_alive" });
}
