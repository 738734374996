import { $startNativeAutomation, $startNativeAutomationWithBundle, $stopNativeAutomation, waitForApp } from "@/mobile/dump/axios";
import Step from '../../../utility/classes/Step';

export default {

    methods : {
        async _activateNativeRun() {
            const limitValidation = await this.checkExecutionRunLimit('native');
            if (!limitValidation.isValid) {
                this.showError(limitValidation.message);
                return
            }
            if (this.nativeRunPaused) {
                this.socket.emit('play', this.activeRunningStep);
                this.exeTimePaused = {
                    ...this.exeTimePaused,
                    end: Date.now()
                };
                this.projectStatus = 'Running';
                this.nativeRunPaused = false;
                this.socket.emit('action', this.activeRunningStep);
                return;
            }

            const emptyTestCasesData =  this.validateEmptyTestCase();

            if(!emptyTestCasesData.isValid) {
                this.$toast.add({
                    severity: 'warn',
                    summary: emptyTestCasesData.message,
                    life: 3000,
                })
                return ;
            }

            this.projectStatus = 'Running'
            this.percentage = [0, 0, 100];
            this.executedSteps = [];
            this.nativeRunActive = true;
            this.activeRunningCaseIndex++;
            this.execTime = {
                ...this.execTime,
                start: Date.now(),
                end : null
            }
            for (let ind = 0; ind < this.testRun.testCases.length; ind++) {
                this.testRun.testCases[ind].status = ''
            }
        },

        async _pauseNativeRun() {
            this.$toast.add({
                severity: 'success',
                summary: 'Your test run has been paused.',
                life: 3000,
            })
            this.socket.emit('pause', this.activeRunningStep);
            this.projectStatus = 'Paused';
            this.nativeRunPaused = true;
            this.exeTimePaused = {
                ...this.exeTimePaused,
                start: Date.now()
            };

        },


        async executeTestCase(caseInd) {
            this.currentStepCnt = -1;
            this.activeRunningStep = {_id : 'asd'};

            if (caseInd === 0) {
                this.testRun.testCases[caseInd].startTime = this.execTime.start;

            } else {
                this.testRun.testCases[caseInd].startTime = this.testRun.testCases[caseInd - 1].endTime;
            }

            this.activeRunningCase = this.testRun.testCases[caseInd];
            await this.getVariables(this.activeRunningCase._id)

            const projectType = this.thisProject.type.toLowerCase() === 'ios' ? 'ios' : 'android';
            let data, err;
            if (projectType === 'ios') {
                if (this.thisProject?.appInfo?.fileName) {
                    [data, err] = await $startNativeAutomation(this.thisProject._id, this.thisProject.appInfo.fileName, this.deviceType);
                } else if (this.thisProject?.appInfo?.bundleId) {
                    [data, err] = await $startNativeAutomationWithBundle(this.thisProject._id, this.thisProject.appInfo.bundleId, this.deviceType);
                }
            } else {
                [data, err] = await $startNativeAutomation(this.thisProject._id, this.thisProject.appInfo.fileName, projectType);
            }

            if (err) {
                return console.log('_____________________', err);
            } else {
                await new Promise(r => setTimeout(r, 1000));
                this._toggleStream('connect')
            }

            this.deviceDimension = { height: data.height, width: data.width };
            console.log('|||||||||||||||||| data', data)
            this._updateResolution(data);

            if (projectType === 'ios') {

                this.currentStepCnt++

            } else {
              const stepsActivitySet = new Set();
              const stepsPackageSet = new Set();

              let projectActivity = this.thisProject.appInfo.activity;
              let projectPackageName = this.thisProject.appInfo.package;

              stepsActivitySet.add(projectActivity)
              stepsPackageSet.add(projectPackageName)

              for (let ind = 0; ind < this.activeRunningCase.testSteps.length; ind++) {
                const step = this.activeRunningCase.testSteps[ind];

                if (Boolean(step?.appInfo?.activity)) {
                  stepsActivitySet.add(step?.appInfo?.activity)
                }

                if (Boolean(step?.appInfo?.package)) {
                  stepsPackageSet.add(step?.appInfo?.package)
                }

              }

              const packages = Array.from(stepsPackageSet)
              const activities = Array.from(stepsActivitySet)

              const [waitForAppRes, waitForAppError] = await waitForApp({ packages, activities });

              if (waitForAppError) {
                console.log("⚧️ ⚧️ ⚧️ ⚧️ ⚧️ ⚧️ ⚧️ ⚧️ ⚧️ ⚧️ ⚧️ ⚧️ ⚧️ ⚧️ ⚧️ error", waitForAppError.data.msg);
                this.showError(`${waitForAppError.data.msg}`)
                this.nativeRunActive = false,
                this._resetNativeRunStates()

              } else {
                this.currentStepCnt++;
              //   console.log("⚧️ ⚧️ ⚧️ ⚧️ ⚧️ ⚧️ ⚧️ ⚧️ ⚧️ ⚧️ ⚧️ ⚧️ ⚧️ ⚧️ ⚧️ response", waitForAppRes);
              }
            }
        }
    },



    watch: {
        async currentStepCnt(i) {
            if (i == -1) {
                return;
            }

            console.log(i, 'step count');
            await new Promise(r => setTimeout(r, 2500));
            this.activeRunningStep = this.activeRunningCase.testSteps[i];


            if (this.nativeRunPaused) {
                return;
            }

            this.socket.emit('action', this.activeRunningCase.testSteps[i]);
        },


        async activeRunningCaseIndex(i){

            if(i == -1) {
                return;
            }

            if(i === this.testRun.testCases.length){
                this.activeRunningCaseIndex = -1;
                return;
            }

            console.log(this.activeRunningCase);

            await this.executeTestCase(i);


        }
    },

};
