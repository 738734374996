<template>
  <div class="layout">
    <div class="main">
<!--      <header>-->
<!--        <img alt="Logo" src="/assets/layout/images/logo.png" class="topbar-logo" @click="$router.push('/')">-->
<!--      </header>-->
      <div class="top">
        <h1>Set up the TestJet Agent</h1>
        <h2>TestJet Agent makes it easy to set up everything you need for mobile automated testing in minutes. Simply download, install, and you're ready to start testing.</h2>
      </div>
      <section :style="{display: 'grid', placeItems: 'center', padding: '4em 0'}">
        <iframe class="yt-video"  src="https://www.youtube.com/embed/c89llVHPrY4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </section>
      <section class="section sec-0">
<!--        <p class="heading">Installation</p>-->
        <p class="description">You're just a few steps away from creating top-notch automated tests with TestJet. Here's what you need to do:</p>
      </section>

      <section class="section sec-1">
        <p class="title"> <span>1</span> Prerequisites:</p>
        <ul class="table-of-contents">
          <li> <a href="https://developer.android.com/studio" target="_blank" rel="nofollow">Android Studio</a></li>
          <li><span>Android SDK + Platform Tools + Command-Line Tools</span></li>
          <li> <a href="https://github.com/appium/appium-desktop/releases/download/v1.22.3-4/Appium-Server-GUI-windows-1.22.3-4.exe" target="_blank" rel="nofollow">Appium Server GUI</a> </li>
          <li> <a href="https://builds.openlogic.com/downloadJDK/openlogic-openjdk/11.0.18+10/openlogic-openjdk-11.0.18+10-windows-x64.msi" target="_blank" rel="nofollow">Java 11 - OpenLogic OpenJDK 11</a> </li>
        </ul>
      </section>

      <section class="section sec-2">
        <p class="title"> <span>2</span>Android Studio Setup:</p>
        <p class="sub-title">Here are the steps to set up Android Studio on Windows:</p>
        <ul class="table-of-contents">
          <li>
            <strong>Download Android Studio:</strong>
            <span>Go to the official <a href="https://developer.android.com/studio">Android Studio website</a> and click on the "Download" button to download the latest version of Android Studio for Windows.</span>
          </li>

          <li>
            <strong>Install Android Studio:</strong>
            <span>Double-click on the downloaded .exe file to start the installation process. Follow the on-screen instructions to install Android Studio on your Windows machine.</span>
          </li>

          <li>
            <strong>Launch Android Studio:</strong>
            <span>After the installation is complete, launch Android Studio by clicking on the shortcut icon on your desktop or by searching for "Android Studio" in the Windows search bar.</span>
          </li>

          <li>
            <strong>Accept the license agreements:</strong>
            <span>During the installation process, you'll be asked to accept the license agreements.</span>
            <ol>
              <li><span>Select "android-sdk-license" and press on "Accept"</span></li>
              <li><span>Select "intel-android-extra-license" and press on "Accept" </span></li>
              <li><span>Press on "Finish"</span></li>
            </ol>
          </li>

          <li>
            <strong>Setup Completion:</strong>
            <span>Wait for the downloads to complete and press on "Finish"</span>
          </li>

          <li>
            <strong>Open the SDK Manager</strong>
            <span>Once Android Studio is open,</span>
            <ol>
              <li><span>click on the "Welcome to Android Studio" window </span></li>
              <li><span>click on the "More Actions" button on the right and select "SDK Manager".</span></li>
            </ol>
            <p>This will open the SDK Manager, which allows you to download and install the necessary components.</p>
            <img src="../assets/agent-installation-guide/welcome-to-android-studio.png" alt="">
          </li>

          <li>
            <strong>Install Command-line Tools</strong>
            <span>In the SDK Manager, </span>
            <ol>
              <li><span>go to the "SDK Tools" tab </span></li>
              <li><span>select the "Android SDK Command-line Tools" package.</span></li>
              <li><span>click on the "Apply" button to install the command-line tools.</span></li>
              <li><span>After installation press Finish/OK to finish the setup process.</span></li>
            </ol>
            <img src="../assets/agent-installation-guide/install-android-studio-commandline-tool.png" alt="">
          </li>

          <li>
            <span>Close Android Studio: After installing the command-line tools, close the SDK Manager and close Android Studio.</span>
            <p class="note">Note: Make sure your computer meets the minimum system requirements for Android Studio, including having a 64-bit version of Windows and at least 8 GB of RAM.</p>
          </li>

        </ul>
      </section>

      <section class="section sec-3">
        <p class="title"> <span>3</span>Java JDK Setup</p>
        <p class="sub-title">Here are the steps to install OpenJDK JDK 11 on Windows 64-bit using the OpenLogic website:</p>

        <ul>
          <li>
            <span>Download the OpenJDK JDK 11: <a href="https://builds.openlogic.com/downloadJDK/openlogic-openjdk/11.0.18+10/openlogic-openjdk-11.0.18+10-windows-x64.msi" target="_blank" rel="nofollow">https://builds.openlogic.com/downloadJDK/openlogic-openjdk/11.0.18+10/openlogic-openjdk-11.0.18+10-windows-x64.msi</a> </span>
          </li>

          <li>
            <span>Install the OpenJDK JDK 11: Double-click on the downloaded .msi file to start the installation process. Follow the on-screen instructions to install OpenJDK JDK 11 on your Windows machine.</span>
          </li>
        </ul>

      </section>

      <section class="section sec-4">
        <p class="title"> <span>4</span>Install Appium Server GUI</p>

        <ul>
          <li>
            <span>Download Appium Server Desktop:  <a href="https://github.com/appium/appium-desktop/releases/download/v1.22.3-4/Appium-Server-GUI-windows-1.22.3-4.exe" target="_blank" rel="nofollow">https://github.com/appium/appium-desktop/releases/download/v1.22.3-4/Appium-Server-GUI-windows-1.22.3-4.exe</a> </span>
          </li>

          <li>
            <span>Double click the downloaded exe file and continue on-screen instructions.</span>
          </li>
        </ul>
      </section>

      <section class="section sec-5">
        <p class="title"> <span>5</span>Set Environment Variable (PowerShell):</p>

        <ul>
          <li>
            <span>Download the TestJet Agent - if it hasn't been downloaded yet, <a href="https://testjet-public.s3.us-west-1.amazonaws.com/testjet-agent-setup-1.1.1.exe" download="TestJet-Agent" target="_blank">Click here</a> to download for Windows.</span>
          </li>

          <li>
            <span>Copy the downloaded TestJet Agent zip archive to any directory and Unzip the TestJet Agent on your computer.</span>
          </li>

          <li>
            <span>Search “PowerShell” from start menu and right click to run as administrator.</span>
            <img src="../assets/agent-installation-guide/powershell-open.png" alt="">
          </li>

          <li>
            <span>Give the command “Set-Executionpolicy Unrestricted” and press enter.</span>
          </li>

          <li>
            <span>Press ‘A’ and press enter</span>
            <img src="../assets/agent-installation-guide/set-execution-policy-unrestricted.png" alt="">
          </li>

          <li>
            <span>Go inside agent folder and right click on "WindowsEnvironmentSetup.ps1" and select "Run with PowerShell" (Windows 11 requires to press "more options" for this option to be visible)</span>
          </li>

          <li>
            <span>Press Open if there’s a security warning (If this prompt is not shown, this step can be ignored)</span>
            <img src="../assets/agent-installation-guide/security-warning.png" alt="">
          </li>

          <li>
            <span>Press ‘R’ and enter (If this prompt is not shown, this step can be ignored)</span>
            <img src="../assets/agent-installation-guide/security-warning-2.png" alt="">
          </li>

          <li>
            <span>Press "Yes" and wait for the process to finish.</span>
            <img src="../assets/agent-installation-guide/finish-set-execution-policy-unrestricted.png" alt="">
          </li>

          <li>
            <span>Close the PowerShell window</span>
          </li>

        </ul>
      </section>

<!--      <section class="section sec-6">-->
<!--        <p class="title"> <span>6</span>Set Environment Variable Method 2 GUI):</p>-->
<!--        <p class="note">Skip this step if you have already done “Set Environment variable Method 1 (PowerShell)”</p>-->

<!--        <ul>-->
<!--          <li>-->
<!--            <span>JAVA HOME</span>-->

<!--            <ol>-->
<!--              <li><span> Search “Environment Variable” from start and click “Edit the system environment variables”</span></li>-->
<!--              <li><span> In the System Properties window, click on the "Environment Variables" button.</span></li>-->
<!--              <li><span> In the Environment Variables window, under "System Variables", scroll down and find the "JAVA_HOME" variable. If “JAVA_HOME” is missing, create it by pressing “new” button. </span></li>-->
<!--              <li><span> Click on the "Edit" button and set the value to the installation path of OpenJDK JDK 8, for example " C:\Program Files\OpenJDK\jdk-8.0.362.09-hotspot". </span></li>-->
<!--              <li><span> Scroll down and find the "Path" variable.</span></li>-->
<!--              <li><span> Click on the "Edit" button and add the following paths to the end of the value: "%JAVA_HOME%\bin"</span></li>-->
<!--              <li><span> Verify the installation: Search on start menu with the keyword “cmd” and press enter. Enter the following command to verify the installation: "java -version". If the installation was successful, you should see the version of OpenJDK JDK 8 that you installed.</span></li>-->
<!--            </ol>-->
<!--          </li>-->

<!--          <li>-->
<!--            <span>ANDROID_HOME</span>-->

<!--            <ol>-->

<!--              <li>-->
<!--                <span>Open File Explorer and put the path “%LocalAppData%\Android\Sdk” and press enter.</span>-->
<!--                <img src="../assets/agent-installation-guide/set-environment-variable.png" alt="">-->
<!--              </li>-->

<!--              <li>-->
<!--                <span>Copy the full path of Sdk from File Explorer</span>-->
<!--                <img src="../assets/agent-installation-guide/set-environment-variable-2.png" alt="">-->
<!--              </li>-->

<!--              <li>-->
<!--                <span>Search “Environment Variable” from start and click “Edit the system environment variables”.</span>-->
<!--              </li>-->

<!--              <li>-->
<!--                <span>In the System Properties window, click on the "Environment Variables" button.</span>-->
<!--              </li>-->

<!--              <li>-->
<!--                <span>Press “New” button under User variables.</span>-->
<!--              </li>-->

<!--              <li>-->
<!--                <span>Give the name “ANDROID_HOME” and set the value as copied path of Android SDK.</span>-->
<!--              </li>-->

<!--            </ol>-->

<!--          </li>-->

<!--          <li>-->
<!--            <span class="custom">Add Platform Tools + Command-Line Tools + Emulator to path</span>-->

<!--            <ol>-->
<!--              <li><span>Search “Environment Variable” from start and click “Edit the system environment variables”.</span></li>-->
<!--              <li><span>In the System Properties window, click on the "Environment Variables" button.</span></li>-->
<!--              <li><span>Select Path under “User variables” sections and press “Edit”</span></li>-->
<!--              <li><span>From “Edit Environment Variable” window, press “New” and put the path: %ANDROID_HOME%\cmdline-tools\latest\binand then press enter.</span></li>-->
<!--              <li><span>Press “New” button again and put the path:%ANDROID_HOME%\platform-toolsand then press enter.</span></li>-->
<!--              <li><span>Press “OK” to close the window.</span></li>-->
<!--            </ol>-->

<!--          </li>-->

<!--        </ul>-->

<!--      </section>-->

      <section class="section sec-7">
<!--        <p class="title"> <span>7</span>Download the TestJet Agent</p>-->
        <p class="title"> <span>6</span>Download the TestJet Agent</p>
        <p class="intro"> Download for Windows</p>
        <p class="note">If you have already downloaded and extracted the agent during the step “Set Environment variable (PowerShell)” you can skip the first two steps here.</p>
        <ul>
          <li><span>Download the TestJet Agent - if it hasn't been downloaded yet, <a href="https://testjet-public.s3.us-west-1.amazonaws.com/testjet-agent-setup-1.1.1.exe" download="TestJet-Agent" target="_blank">Click here</a> to download for Windows.</span></li>
          <li><span>Copy the downloaded TestJet Agent zip archive to any directory and Unzip the TestJet Agent on your computer.</span></li>
          <li><span>Double click on “agent.exe” to start the agent</span></li>
          <li>
            <span>If there’s a warning about “unrecognized app” press “more info”</span>
            <img src="../assets/agent-installation-guide/windows-security.png" alt="">
          </li>

          <li>
            <span>Press “Run Anyway”</span>
            <img src="../assets/agent-installation-guide/windows-security-2.png" alt="">
          </li>

          <li>
            <span>Check both Private and Public network connection permission on “Windows security alert” window and press “Allow access”</span>
            <img src="../assets/agent-installation-guide/windows-defender-allow-access.png" alt="">
          </li>
        </ul>

<!--        <p class="title"> <span>8</span>Run the agent</p>-->
        <p class="title"> <span>7</span>Run the agent</p>

        <ul>
          <li><span>Search Appium Server GUI from start and open it.</span></li>
          <li>
            <span>Press on “startServer”</span>
            <img src="../assets/agent-installation-guide/run-appium-gui.png" alt="">
          </li>
          <li>
            <span>If there’s a “Windows Security Alert” window, check both Private and Public network connection permission on “Windows security alert” window and press “Allow access”</span>
            <img src="../assets/agent-installation-guide/windows-allow-access-to-gui.png" alt="">
          </li>
          <li>
            <span>It should show the message “the server is running”</span>
            <img src="../assets/agent-installation-guide/appium-gui.png" alt="">
          </li>
          <li>
            <span>If the agent is not running, double click on agent.exe to start it. Once started, a command prompt should open and show the output “started server”.</span>
            <img src="../assets/agent-installation-guide/run-testjet-agent.png" alt="">
          </li>

          <li><span>Connect an Android device with USB-Debugging turned on.</span></li>
          <li><span>Search “cmd” from start and enter the command: adb devices If it says the word “Unauthorized” press the “Allow” button on device to authorize it.</span></li>
          <li><span>If “adb devices” command shows the word “device” then the device is properly connected.</span></li>
          <li><span>Agent is now ready. Proceed to use TestJet for mobile application testing.</span></li>

        </ul>
      </section>

    </div>
  </div>
</template>

<script>

export default {
  name: "AgentInstallationGuideLine",
  mounted() {
    let sidePanel = document.querySelector('.layout-menu-container')
    let menuToggleButton = document.querySelector('#menu-button')
    let layout = document.querySelector('.menu-layout-static')


    sidePanel.style.display = 'none'
    menuToggleButton.style.display = 'none'
    layout.classList.add('layout-menu-static-inactive')
  },
}
</script>

<style lang="scss" scoped>
  .layout {

  }

  .main {
    $color-black: #282828;
    $color-bg: #E2EFF6;
    $color-blue: #00A0EE;
    $color-red: #E25686;
    background: linear-gradient(90.13deg, #FFFFFF 0.06%, #EEF9FF 99.83%);


    img {
      border-radius: 6px !important;
      overflow: hidden !important;
      max-width: 80%;
    }


    max-width: 75%;
    width: 100%;
    margin: 0 auto;
    padding-bottom: 30px;

    a {
      text-decoration: underline;
    }

    p,span {
      color: $color-black;
    }

    ul, ol {
      li {
        list-style-type: inherit;
      }
    }


    header {
      padding: 20px 0 10px 0;
      width: 100%;
      margin-bottom: 3em;

      .topbar-logo {
        cursor: pointer;
      }
    }

    .top {
      text-align: center;
      display: grid;
      place-items: center;
      padding: 0 7em;

      h1 {
        margin-top: 1.2em;
        font-weight: 700;
        font-size: 32px;
        background: #E2EFF6;
        display: grid;
        place-items: center;
        color: #0091d8;
        width: 100%;
        padding: .8em 0;
        border-radius: 4px;
      }
      h2 {
        font-weight: 400;
        font-size: 18px;
        max-width: 60%;
      }
    }

    .section {
      margin: 4em 0;
    }

    section {
      width: 100%;
      padding: 0 7em;

      .heading {
        background: $color-bg;
        width: 215px;
        height: 60px;
        font-weight: 600;
        font-size: 32px;
        display: grid;
        place-items: center;
        color: $color-blue;
      }
      .description {
        font-weight: 400;
        font-size: 16px;
      }

      .title {
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: 22px;
        background: #e3f6ff;


        span {
          background: $color-blue;
          width: 45px;
          height: 47px;
          display: grid;
          place-items: center;
          font-weight: 600;
          font-size: 16px;
          color: #FFFFFF;
          margin-right: 20px;
        }
      }

      .sub-title {
        font-weight: 600;
        font-size: 16px;
      }

      .note {
        font-weight: 500;
        font-size: 16px;
        color: $color-red;
        margin: 1em 0;
        margin: 1.3em 0 2em 0;
      }

      p {
        font-weight: 500;
        color: $color-black;
        font-size: 16px;
      }

      ul, ol {
        li {
          &::marker {
            font-size: 16px;
          }

          strong {
            font-size: 16px;
            font-weight: 600;
            margin-right: 10px;
          }

          span {
            font-weight: 500;
            color: $color-black;
            font-size: 16px;
          }

          img {
            width: 712px;
            height: fit-content;
            object-fit: contain;
          }
        }
      }

    }
    .yt-video {
      aspect-ratio: 16 / 9;
      width: 70%;
    }
    .sec-0 {
      margin: 3em 0 0 0;
    }

    .sec-1 {
      margin: 4em 0;
      ul {
        a, span {
          font-weight: 600;
          font-size: 16px;
        }
        li {
          margin-bottom: 5px;
        }
      }
    }

    .sec-2 {

      p {
        margin-bottom: 1.5em;
      }


      ul {

        li {
          line-height: 1.8;
          margin-bottom: 1.3em;

          img {
            display: flex;
            margin: 0 auto;
            margin-bottom: 3em;
          }

          p {
            margin: 2em 0;
          }

          ol {
            margin: 1em 0;

            li {
              &::marker {
                font-size: 16px;
                font-weight: bold;
              }
            }
          }
        }
      }

    }

    .sec-3 {
      p {
        margin-bottom: 1.5em;
      }
      ul {
        li {
          line-height: 1.8;
          margin-bottom: 1.3em;
        }
      }
    }

    .sec-4 {
      p {
        margin-bottom: 1.5em;
      }
      ul {
        li {
          line-height: 1.8;
          margin-bottom: 1.3em;
        }
      }
    }

    .sec-5 {
      p {
        margin-bottom: 1.5em;
      }
      ul {
        li {
          line-height: 1.8;
          margin-bottom: 1.3em;

          span {
            display: block;
          }
          img {
            display: flex;
            margin: 3em auto;
          }
        }
      }
    }

    .sec-6 {
      p {
        margin-bottom: 1.5em;
      }

      .custom {
        color: $color-blue;
      }

      ul, ol {
        li {
          line-height: 1.8;
          margin-bottom: 1.3em;

          &::marker {
            font-size: 16px;
            font-weight: bold;
          }

          span {
            display: block;
            font-weight: 600;

          }
          img {
            display: flex;
            margin: 3em auto;

          }
        }
      }

      ol {
        margin: 3em 0;

        li {
          span {
            font-weight: 500;
          }
        }
      }
    }

    .sec-7 {
      p {
        margin-bottom: 1.5em;
      }

      .intro {
        color: $color-blue;
        font-weight: 700;
        font-size: 26px;
      }


      ul {
        li {
          line-height: 1.8;
          margin-bottom: 1.3em;

          span {
            display: block;
          }
          img {
            display: flex;
            margin: 3em auto;
          }
        }
      }
    }


  }
</style>
