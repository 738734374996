import axios from 'axios';
import {AgentServiceBaseUrl} from '../base_urls';
import interceptors from '../interceptors';

const AgentServiceRequest = axios.create({
    baseURL: `${AgentServiceBaseUrl}`,
    timeout: 30000,
});

interceptors(AgentServiceRequest);
export default AgentServiceRequest;