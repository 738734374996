export default class Step {
  /**
   * Create Steps with these data
   * @param {string} projectID
   * @param {string} testCaseID
   * @param {string} singleStepID
   * @param {number || string} listIndex
   * @param {number} startCord
   * @param {number} endCord
   * @param {number} x
   * @param {number} y
   * @param {object} coordinates
   * @param {string} bounds
   * @param {string} id
   * @param {string} xpath
   * @param {string} className
   * @param {boolean} clickable
   * @param {boolean} focusable
   * @param {boolean} longClickable
   * @param {boolean} scrollable
   * @param {boolean} isDisplayed
   * @param {boolean} isEnabled
   * @param {boolean} isFocused
   * @param {boolean} isChecked
   * @param {boolean} isSelected
   * @param {boolean} isPassword
   * @param {string} uri
   * @param {number} height
   * @param {number} width
   * @param {string} elementScreenshot
   * @param {string} executedImgUrl
   * @param {string} text
   * @param {string} inputText
   * @param {object} validation
   * @param {object} error_obj
   * @param {string} contentDescription
   * @param {string || null} [hierarchyXpath = null]
   */
  step = null;

  constructor(stepData = {}, type = null) {
    console.log("🐼 🐼 🐼 🐼 🐼 🐼 🐼 🐼 🐼 🐼 from Step Class Constructor ====>>> ", { stepData, type })

    if (type === 'swipe') {
      const {
        projectID,
        testCaseID,
        singleStepID,
        listIndex,
        startCord,
        endCord,
        height,
        width,
        error_obj,
      } = stepData

      this.step = {
        projectID: projectID ? projectID : null,
        testCaseID: testCaseID ? testCaseID : null,
        singleStepID: singleStepID ? singleStepID : null,
        listIndex: listIndex ? listIndex : null,
        coordinates: {
          start: {
            x: !!startCord.x ? startCord.x : 0,
            y: !!startCord.y ? startCord.y : 0,
          },
          end: {
            x: !!endCord.x ? endCord.x : 0,
            y: !!endCord.y ? endCord.y : 0,
          }
        },
        eventType: "swipe",
        screenSize: {
          height: height ? height : null,
          width: width ? width : null,
        },
        error_obj: error_obj ? {
          error: error_obj.error ? error_obj.error : false,
          status: error_obj.status ? error_obj.status : "success",
          error_cause: error_obj.error_cause ? error_obj.error_cause : "",
        } : {
          error: false,
          status: "success",
          error_cause: "",
        },
        createdAt: new Date(),
      }
      return this.step;
    }

    else if (type === 'tap') {
      const {
        projectID,
        testCaseID,
        singleStepID,
        listIndex,
        x,
        y,
        coordinates,
        bounds,
        id,
        xpath,
        className,
        clickable,
        focusable,
        longClickable,
        scrollable,
        isDisplayed,
        isEnabled,
        isFocused,
        isChecked,
        isSelected,
        isPassword,
        uri,
        height,
        width,
        elementScreenshot,
        executedImgUrl,
        text,
        inputText,
        validation,
        error_obj,
        contentDescription,
        hierarchyXpath
      } = stepData


      this.step = {
        projectID: projectID ? projectID : null,
        testCaseID: testCaseID ? testCaseID : null,
        singleStepID: singleStepID ? singleStepID : null,
        listIndex: listIndex ? listIndex : null,
        coordinates: coordinates ? coordinates : null,
        boundingRect: {
          x: x ? x : 0,
          y: y ? y : 0,
          bounds: bounds ? bounds : "[0,0][0,0]",
        },
        selectors: {
          id: id ? id : null,
          xPath: xpath ? xpath : null,
          hierarchyXpath: hierarchyXpath ? hierarchyXpath : null,
          class: className ? className : ""
        },
        actionables: {
          clickable: clickable ? clickable : true,
          focusable: focusable ? focusable : true,
          longClickable: longClickable ? longClickable : false,
          scrollable: scrollable ? scrollable : false,
        },
        states: {
          isDisplayed: isDisplayed ? isDisplayed : true,
          isEnabled: isEnabled ? isEnabled : true,
          isFocused: isFocused ? isFocused : false,
          isChecked: isChecked ? isChecked : false,
          isSelected: isSelected ? isSelected : false,
          isPassword: isPassword ? isPassword : false
        },
        validation: {
          expectedOutput: validation?.expectedOutput ? validation.expectedOutput : "",
          shouldValidate: validation?.validate ? validation.validate : false,
          validationType: validation?.validationType ? validation.validationType : ""
        },
        screenshot: {
          ...(elementScreenshot ? { elementScreenshot } : { elementScreenshot: null }),
          ...(executedImgUrl ? { executedImgUrl } : { executedImgUrl: null }),
          ...(uri ? { uri } : { uri: null }),
          mimeType: "image/png"
        },
        eventType: "tap",
        screenSize: {
          height: height ? height : null,
          width: width ? width : null,
        },
        attributes: {
          contentDescription: contentDescription ? contentDescription : null,
        },
        text: text ? text : null,
        inputText: inputText ? inputText : null,
        error_obj: error_obj ? {
          error: error_obj.error ? error_obj.error : false,
          status: error_obj.status ? error_obj.status : "success",
          error_cause: error_obj.error_cause ? error_obj.error_cause : "",
        } : {
          error: false,
          status: "success",
          error_cause: "",
        },
        createdAt: new Date(),
      }
      return this.step;
    }

    else if (type === 'input') {
      const {
        projectID,
        testCaseID,
        singleStepID,
        listIndex,
        coordinates,
        x,
        y,
        bounds,
        id,
        xpath,
        height,
        width,
        className,
        clickable,
        focusable,
        longClickable,
        scrollable,
        isDisplayed,
        isEnabled,
        isFocused,
        isChecked,
        isSelected,
        isPassword,
        uri,
        elementScreenshot,
        executedImgUrl,
        text,
        inputText,
        validation,
        contentDescription,
        error_obj,
        hierarchyXpath,
      } = stepData


      this.step = {
        projectID: projectID ? projectID : null,
        testCaseID: testCaseID ? testCaseID : null,
        singleStepID: singleStepID ? singleStepID : null,
        listIndex: listIndex ? listIndex : null,
        coordinates: coordinates ? coordinates : null,
        boundingRect: {
          x: x ? x : 0,
          y: y ? y : 0,
          bounds: bounds ? bounds : "[0,0][0,0]",
        },
        selectors: {
          id: id ? id : null,
          xPath: xpath ? xpath : null,
          hierarchyXpath: hierarchyXpath ? hierarchyXpath : null,
          class: className ? className : ""
        },
        actionables: {
          clickable: clickable ? clickable : true,
          focusable: focusable ? focusable : true,
          longClickable: longClickable ? longClickable : false,
          scrollable: scrollable ? scrollable : false,
        },
        states: {
          isDisplayed: isDisplayed ? isDisplayed : true,
          isEnabled: isEnabled ? isEnabled : true,
          isFocused: isFocused ? isFocused : false,
          isChecked: isChecked ? isChecked : false,
          isSelected: isSelected ? isSelected : false,
          isPassword: isPassword ? isPassword : false
        },
        validation: {
          expectedOutput: validation?.expectedOutput ? validation.expectedOutput : "",
          shouldValidate: validation?.validate ? validation.validate : false,
          validationType: validation?.validationType ? validation.validationType : ""
        },
        screenshot: {
          ...(elementScreenshot ? { elementScreenshot } : { elementScreenshot: null }),
          ...(executedImgUrl ? { executedImgUrl } : { executedImgUrl: null }),
          ...(uri ? { uri } : { uri: null }),
          mimeType: "image/png"
        },
        eventType: "tap",
        screenSize: {
          height: height ? height : null,
          width: width ? width : null,
        },
        attributes: {
          contentDescription: contentDescription ? contentDescription : null,
        },
        text: text ? text : null,
        inputText: inputText ? inputText : null,
        error_obj: error_obj ? {
          error: error_obj.error ? error_obj.error : false,
          status: error_obj.status ? error_obj.status : "success",
          error_cause: error_obj.error_cause ? error_obj.error_cause : "",
        } : {
          error: false,
          status: "success",
          error_cause: "",
        },
        createdAt: new Date(),
      }
      return this.step;
    }

    else if (type === 'sleep') {
      const {
        projectID,
        testCaseID,
        singleStepID,
        listIndex,
        sleepFor,
        height,
        width,
        error_obj,
      } = stepData

      this.step = {
        projectID: projectID ? projectID : null,
        testCaseID: testCaseID ? testCaseID : null,
        singleStepID: singleStepID ? singleStepID : null,
        listIndex: listIndex ? listIndex : null,
        sleepFor: sleepFor ? sleepFor : 1000,
        eventType: 'sleep',
        screenSize: {
          height: height ? height : null,
          width: width ? width : null,
        },
        error_obj: error_obj ? {
          error: error_obj.error ? error_obj.error : false,
          status: error_obj.status ? error_obj.status : "success",
          error_cause: error_obj.error_cause ? error_obj.error_cause : "",
        } : {
          error: false,
          status: "success",
          error_cause: "",
        },
        createdAt: new Date(),
      }
      return this.step;
    }

    else {
      console.log("From Step Constructor On ELSE ====>>>  ", { stepData, type })
      const {
        projectID,
        testCaseID,
        singleStepID,
        listIndex,
        coordinates,
        x,
        y,
        bounds,
        id,
        xpath,
        height,
        width,
        className,
        clickable,
        focusable,
        longClickable,
        scrollable,
        isDisplayed,
        isEnabled,
        isFocused,
        isChecked,
        isSelected,
        isPassword,
        uri,
        elementScreenshot,
        executedImgUrl,
        text,
        inputText,
        validation,
        contentDescription,
        error_obj,
        hierarchyXpath,
      } = stepData


      this.step = {
        projectID: projectID ? projectID : null,
        testCaseID: testCaseID ? testCaseID : null,
        singleStepID: singleStepID ? singleStepID : null,
        listIndex: listIndex ? listIndex : null,
        coordinates: coordinates ? coordinates : null,
        boundingRect: {
          x: x ? x : 0,
          y: y ? y : 0,
          bounds: !bounds?.includes('NaN') ? bounds : "[0,0][0,0]",
        },
        selectors: {
          id: id ? id : null,
          xPath: xpath ? xpath : null,
          hierarchyXpath: hierarchyXpath ? hierarchyXpath : null,
          class: className ? className : ""
        },
        actionables: {
          clickable: clickable ? clickable : false,
          focusable: focusable ? focusable : false,
          longClickable: longClickable ? longClickable : false,
          scrollable: scrollable ? scrollable : false,
        },
        states: {
          isDisplayed: isDisplayed ? isDisplayed : false,
          isEnabled: isEnabled ? isEnabled : false,
          isFocused: isFocused ? isFocused : false,
          isChecked: isChecked ? isChecked : false,
          isSelected: isSelected ? isSelected : false,
          isPassword: isPassword ? isPassword : false
        },
        validation: {
          expectedOutput: validation?.expectedOutput ? validation.expectedOutput : "",
          shouldValidate: validation?.validate ? validation.validate : false,
          validationType: validation?.validationType ? validation.validationType : ""
        },
        screenshot: {
          ...(elementScreenshot ? { elementScreenshot } : { elementScreenshot: null }),
          ...(executedImgUrl ? { executedImgUrl } : { executedImgUrl: null }),
          ...(uri ? { uri } : { uri: null }),
          mimeType: "image/png"
        },
        eventType: type ? type : null,
        screenSize: {
          height: height ? height : null,
          width: width ? width : null,
        },
        attributes: {
          contentDescription: contentDescription ? contentDescription : null,
        },
        text: text ? text : null,
        inputText: inputText ? inputText : null,
        error_obj: error_obj ? {
          error: error_obj.error ? error_obj.error : false,
          status: error_obj.status ? error_obj.status : "success",
          error_cause: error_obj.error_cause ? error_obj.error_cause : "",
        } : {
          error: false,
          status: "success",
          error_cause: "",
        },
        createdAt: new Date(),
      }

      return this.step;
    }
  }


}
