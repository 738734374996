<template>
    <div class="run-info-right-bar">
        <div class="info_container">
            <div class="donut-chart">
                <apexchart :options="chartOptions" :series="percentage"></apexchart>
            </div>

            <div class="card">
                <h5>{{ runType }} Run Information</h5>
                <ul>
                    <li class="run-info-wrapper">
                        <span class="icon"><i class="fas fa-file-code" aria-hidden="true"></i></span>
                        <div class="run-info"><span class="label">Run Number</span><span class="info">{{customRunId(serial ? serial : 0)}}</span></div>
                    </li>
                    <li class="run-info-wrapper">
                        <span class="icon"><i class="fas fa-clock" aria-hidden="true"></i></span>
                        <div class="run-info"><span class="label">Execution Time</span><span class="info">{{ executedTime() }} </span></div>
                    </li>
                    <li class="run-info-wrapper">
                        <span class="icon"><i class="fab fa-google-play" aria-hidden="true"></i></span>
                        <div class="run-info"><span class="label">APP</span><span class="info">{{apkName? apkName : ''}}</span></div>
                    </li>
                    <li class="run-info-wrapper">
                        <span class="icon"><i class="fas fa-calendar-day" aria-hidden="true"></i></span>
                        <div class="run-info"><span class="label">Created On</span><span class="info">{{ createdAt && moment ? moment(createdAt).format('DD.MM.YYYY') : '' }}</span></div>
                    </li>
                    <li class="run-info-wrapper">
                        <span class="icon"><!--v-if--><img class="m-0" src="/img/ex-count.350c2204.svg" /><!--v-if--></span>
                        <div class="run-info"><span class="label">Status</span><span class="info">{{ status }}</span></div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import {getToken} from '@/utils/cookies';
import decode from 'jwt-decode';
import {convertMs} from "@/mobile/utility/utils";

export default {
    data() {
        return {
            encodedToken: null,
            moment: null,
            chartOptions: {
                plotOptions: {
                    pie: {
                        donut: {
                            size: '50%',
                        },
                    },
                },
                chart: {
                    width: 200,
                    type: 'donut',
                    animations: {
                        enabled: true,
                    },
                },
                states: {
                    hover: {
                        filter: {
                            type: 'none',
                        },
                    },
                    active: {
                        // allowMultipleDataPointsSelection: false,
                        filter: {
                            type: 'darken',
                            // value: 0.35,
                        }
                    },
                },
                labels: ["Passed", 'Failed', "Pending"],
                dataLabels: {
                    enabled: true,
                    style: {
                        fontSize: '12px',
                        fontFamily: 'Open Sans',
                        fontWeight: 300,
                        colors: ['#ffffff', '#ffffff', '#808080'],
                    },
                    dropShadow: {
                        enabled: false,
                    },
                },
                colors: ['#28DC9F', '#D64564', '#DEE2E6'],
                legend: {
                    show: false,
                },
                fill: {
                    type: 'linear',
                }
            },
        };
    },
    updated() {
        this.encodedToken = decode(getToken());
    },
    mounted() {
        this.moment = moment;
    },
    methods: {
        executedTime() {
            if (this.execTime && this.execTime.start && this.execTime.end && !(Object.values(this.execTime).includes(null))) {
              let execTimeFrame = this.execTime.end - this.execTime.start;
              const val = convertMs(execTimeFrame)
              let formatedTime = ''

                if (val.hrs) {
                  formatedTime = `${val.hrs} hrs `
                }

                if (val.mins) {
                  formatedTime = `${formatedTime} ${val.mins} min `
                }

                if (val.secs) {
                  formatedTime = `${formatedTime} ${val.secs.toFixed(0)} secs `
                }

                // console.log("👾 👾 👾 👾 👾 👾 👾 👾 👾 👾 👾 👾 👾 ", formatedTime)

                return formatedTime

            } else if (this.execTime && String(this.execTime).includes('min')) {
              // console.log("👾 👾 👾 👾 👾 👾 👾 👾 👾 👾 👾 👾 👾 ", this.execTime)
                return this.execTime

            } else if (this.execTime && !Object.keys(this.execTime).length) {
                const val = convertMs(this.execTime)

                let formatedTime = ''

                if (val.hrs) {
                  formatedTime = `${val.hrs} hrs `
                }

                if (val.mins) {
                  formatedTime = `${formatedTime} ${val.mins} min `
                }

                if (val.secs) {
                  formatedTime = `${formatedTime} ${val.secs.toFixed(0)} secs `
                }

                return formatedTime
            } else {
                return '00 sec'
            }
        },
        customRunId(_serial = '0'){
            let serial = _serial;
            function pad (str, max) {
                return str.toString().length < max ? pad("0" + str, max) : str;
            };

            if (serial) {
                return "TR-" + pad(serial, 8);
            } else {
                return "TR-" + pad(0, 8);
            }
        },
    },
    props: {
        showLiveRun: {
            type: Boolean,
            required: false,
            default: false,
        },
        runInfo: {
            type: Object,
            required: false,
            default: {},
        },
        runType: {
            type: String,
            required: true,
            default: 'Native',
        },
        percentage: {
            type: Array,
            required: false,
            default: [0, 0, 0],
        },
        execTime: {
            type: String || Object,
            require: true
        },
        apkName: {
            type: String,
            required: true
        },
        serial: {
            type: Number,
            required: true
        },
        createdAt: {
            type: String,
            required: true
        },
        status: {
            type: String,
            required: true
        }
    },
    watch: {
        percentage(val) {
            if(!this.showLiveRun) {
                return;
            }

            let pendingPercentage = 0;
            const [passPercentage, failPercentage ] = val;

            pendingPercentage = 100 - ( passPercentage + failPercentage );

            if (this.$socket.connected === true) {

            console.log('automationStatus updated', this.status);

            this.$socket.emit(`${this.encodedToken?._id}`,
                {
                    liveWebNativeRunId: this.runInfo?._id,
                    automationStatus: this.status,
                    customTestRunID: this.customRunId(this.serial),
                    testRunName: this.runInfo ? this.runInfo.name : '',
                    projectID: this.runInfo ? this.runInfo.projectID : '',
                    testRunSuccessPercentage: passPercentage.toFixed(2),
                    testRunErrorPercentage: failPercentage.toFixed(2),
                    testRunPendingPercentage: pendingPercentage.toFixed(2),
                    socketID: this.$socket.id,
                },

                (response) => {
                    console.log('✴️ ✴️ ✴️ ✴️ ✴️ ✴️ ✴️ ✴️ ✴️ ✴️ ✴️ ✴️ ✴️ ✴️ ✴️ ✴️ ✴️ ✴️ ✴️ ✴️ ✴️ ✴️ ✴️ response', response.status); // ok
                }
            );
            }
        },
        status(val) {
            if(!this.showLiveRun) {
                return;
            }

            let pendingPercentage = 0;
            const [passPercentage, failPercentage ] = this.percentage;

            pendingPercentage = 100 - ( passPercentage + failPercentage );

            if (this.$socket.connected === true) {

            console.log('automationStatus updated', val);

            this.$socket.emit(`${this.encodedToken?._id}`,
                {
                    liveWebNativeRunId: this.runInfo?._id,
                    automationStatus: val,
                    customTestRunID: this.customRunId(this.serial),
                    testRunName: this.runInfo ? this.runInfo.name : '',
                    projectID: this.runInfo ? this.runInfo.projectID : '',
                    testRunSuccessPercentage: passPercentage.toFixed(2),
                    testRunErrorPercentage: failPercentage.toFixed(2),
                    testRunPendingPercentage: pendingPercentage.toFixed(2),
                    socketID: this.$socket.id,
                },

                (response) => {
                    console.log('✴️ ✴️ ✴️ ✴️ ✴️ ✴️ ✴️ ✴️ ✴️ ✴️ ✴️ ✴️ ✴️ ✴️ ✴️ ✴️ ✴️ ✴️ ✴️ ✴️ ✴️ ✴️ ✴️ response', response.status); // ok
                }
            );
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.run-info-right-bar {
    background-color: #fff;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 305px;
    border-radius: 4px;
    padding-bottom: 111px;
    height: 842px;
}

.info_container {
    .donut-chart {
        margin-top: 64px;
    }

    .card {
        ul {
            padding: 0;

            li {
                margin-top: 12px;

                i {
                    font-size: 18px;
                    color: #00a0ee;
                }
            }
        }

        h5 {
            font-size: 16px;
            font-weight: 700;
            color: #282828;
            text-align: center;
        }

        .run-info-wrapper {
            padding: 0;
            border: 1px solid #dee2e6;
            display: flex;
            justify-content: space-between;
            border-radius: 4px;

            .icon {
                display: flex;
                justify-content: center;
                align-items: center;
                border-right: 1px solid #dee2e6;
                width: 100%;
                max-width: 64px;
                height: 64px;
            }

            .run-info {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                width: 100%;
                max-width: 242px;
                margin-left: 5px;

                .label {
                    color: #a1b3c5;
                    font-size: 11px;
                    width: 100%;
                }

                .info {
                    color: #282828;
                    font-size: 14px;
                    width: 121px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}
</style>
