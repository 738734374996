import {
    fetchCFilteredlientActivityInfo,
    fetchClientActivityInfo,
    fetchSubscriptionAndOverviewInfo,
    fetchTotalSubscriberInfo
} from '../../api/TjAdminDashboardService';

const state = {
    headersInfo: null,
    projectOverview: {},
    subscriptionWiseClients: [],
    isClientListLoaded: false,
    noDataFoundMsg: null,
};

const mutations = {
    HEADER_INFO(state, headerInfo) {
        state.headersInfo = headerInfo;
    },
    PROJECT_OVERVIEW(state, overviewInfo) {
        state.projectOverview = overviewInfo;
    },
    SUBSCRIPTION_WISE_CLIENT_LIST(state, clientList) {
        state.subscriptionWiseClients = clientList;
    },
    IS_CLIENT_LIST_LOADED(state, status) {
        state.isClientListLoaded = status;
    },
    NO_DATA_FOUND_MSG(state, msg) {
        state.noDataFoundMsg = msg;
    }
};

const actions = {
    actionFetchSubscriptionAndOverviewInfo(state) {
        return fetchSubscriptionAndOverviewInfo().then(async resBody => {
            if (resBody.status === 200) {
                console.log("action===>", resBody)
                state.commit('HEADER_INFO', resBody && resBody.data && resBody.data.headersInfo && resBody.data.headersInfo.subscriptionWiseClients);
                state.commit('PROJECT_OVERVIEW', resBody && resBody.data && resBody.data.projectOverview);
                return resBody;
            } else {
                return resBody.response;
            }
        })
    },

    actionFetchTotalSubscriberInfo(state, reqDataObj) {
        return fetchTotalSubscriberInfo(reqDataObj.duration).then(resBody => {
            if (resBody.status === 200) {
                return resBody.data;
            } else {
                return resBody;
            }
        })
    },

    actionFetchClientActivity(state, reqDataObj) {
        return fetchClientActivityInfo(reqDataObj.duration).then(resBody => {
            if (resBody.status === 200) {
                return resBody.data.clientActivity;
            } else {
                return resBody;
            }
        })
    },

    actionFetchFilteredClientActivity(state, reqDataObj) {
        state.commit('IS_CLIENT_LIST_LOADED', false)
        let duration = reqDataObj.duration;
        let bodyData = {
            subscription_type: reqDataObj.subscription,
            payment_status: reqDataObj.payment
        }
        console.log("filter action", reqDataObj)
        return fetchCFilteredlientActivityInfo(duration, bodyData).then(resBody => {
            state.commit('IS_CLIENT_LIST_LOADED', true);
            if (resBody.status === 200) {
                // return resBody.data.clientActivity;
                state.commit('SUBSCRIPTION_WISE_CLIENT_LIST', resBody.data.clientActivity);
                return resBody;
            } else {
                return resBody;
            }
        })
            .catch(err => {
                state.commit('IS_CLIENT_LIST_LOADED', true);
                state.commit('SUBSCRIPTION_WISE_CLIENT_LIST', []);
            })
    },
}

const getters = {
    headersInfo: state => state.headersInfo,
    projectOverview: state => state.projectOverview,
    subscriptionWiseClients: state => state.subscriptionWiseClients,
    isClientListLoaded: state => state.isClientListLoaded,
    // noDataFoundMsg: state => state.noDataFoundMsg,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
