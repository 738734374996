<template>
  <div>
    <!-- <div v-if="task && task.key"> -->
    <draggable
        :disabled="disabled"
        class="item-container"
        tag="ul"
        :list="testSteps"
        :group="{ name: 'g1' }"
        item-key="testSteps._id"
        :move="(evt) => $emit('onMove', evt, evt.draggedContext, evt.relatedContext, 'root')"
        :onChoose="onChoose"
        :add="(evt) => $emit('onAdd', evt, 'root')"
        :change="(evt) => $emit('onChange', evt, 'root')"
        @end="(evt) => $emit('onEnd', evt, evt.draggedContext, 'root')"
    >
      <template #item="{ element }">
        <div :class="['testStep-wrapper', srcType === 'testRunDetails' ? 'rmv-padding' : '']" :ref="element._id"
             :id="'step-' + element._id">
          <!-- {{ element.executeStatus }} -->
          <div
              :class="['test-step', errorsClassNameIndicator(element, current_step), srcType === 'testRunDetails' ? 'rmv-padding' : '']">
            <!-- <div class="test-step"> -->
            <div class="test-step__checkbox" v-if="srcType == 'testSteps'">
              <base-checkbox :isChecked="element.is_checked" :id="element._id"
                             @click="(e, is_checked) => handleCheckbox(e, is_checked, element)"/>
            </div>
            <div class="test-step__screenShot" :ref="element._id">
              <div v-if="element.type === 'group'">
                <div v-if="element.children && element.children.length > 0">
                  <div v-if="element.children[0].screenshot_src == null">
                    <img
                        @click="(evt) => emitter('onShowComparison', element, evt)"
                        v-if="!['input', 'scroll', 'resize', 'refresh', 'sleep', 'url_validation', 'navigate'].includes(element.children[0].action) && element.children[0].screenshot_src === null"
                        class="image 1"
                        :alt="element.type"
                        :src="element.children[0].screenshot_src"
                    />
                    <p v-else>{{ element.children[0].value !== null ? element.children[0].value : 'group' }}</p>
                  </div>
                  <div v-else>
                    <p>
                      {{ element.description }}
                    </p>
                  </div>
                </div>
                <div v-else>
                  <p>'{{ element.description }}'</p>
                </div>
              </div>
              <div v-else>
                <img @click="(evt) => emitter('onShowComparison', element, evt)" class="image 2" :alt="element.description"
                     :src="element.screenshot_src" v-if="!exceptEvents.includes(element.action)"/>

                <screenshot-label
                    v-else
                    :domEvents="domEvents"
                    :customValidationList="customValidationList"
                    :action="element.action"
                    :coordinates="element.coordinates"
                    :screen_size="element.screen_size"
                    :sleep_for="element.sleep_for"
                    :text="element.title || element.text || element.value"
                />
              </div>
            </div>

            <div class="test-step__description" @mouseenter="onStepMouseEnter($event, element)">
              <div class="test-event-icon">

                <div v-if="element.type === 'group'">
                  <div class="folder-icon">
                    <i class="fas fa-folder"></i>
                  </div>
                  <div class="expand-icon">
                    <i :class="expendIds.includes(element._id) ? 'fas fa-chevron-down' : 'fas fa-chevron-right'"
                       @click="handleExpenadOrCollaps(element._id)"/>
                  </div>
                </div>
                <div v-else-if="element.action === 'validate_database'">
                  <i class="fas fa-database"></i>
                </div>
                <div v-else-if="element.action === 'validate_email'">
                  <i class="fas fa-envelope"></i>
                </div>
                <div v-else-if="element.action === 'validate_api'">
                  <i class="fas fa-sitemap"></i>
                </div>
                <div v-else-if="element.action === 'validate_sms'">
                  <i class="fas fa-sms"></i>
                </div>
                <div v-else><i class="fas fa-mouse"></i></div>
              </div>

              <div
                  :class="['test-event-name', 
                    element.action === customValidationList.CHECK_BROKEN_LINK.action 
                    || element.action === customValidationList.API_VALIDATION.action 
                    || element.action === customValidationList.ValidateEmail.action 
                    || element.action === customValidationList.ValidateSMS.action 
                    || element.action === customValidationList.ValidateDatabse.action 
                    ? 'pointer' : '']"
                  @click="() => $stepClick(element)"
              >
                <span>
                  {{ element.description }}
                </span>
                <span v-if="customValidationList.CHECK_BROKEN_LINK.action === element.action">
                  (Failed: {{ totalBrokenLinks(element) }}, Success: {{ totalSuccessLinks(element) }})
                </span>
                <div class="test-event-description">
                  <!-- <div class="step-execution-element"> -->
                  <span v-if="element.error_obj && element.error_obj.status"
                        :style="{ color: 'red' }">{{ element.error_obj.message }}</span>
                  <!-- </div> -->
                  <!-- Static text is display text that is not changed at run time. However, static text allows the designer many options at author time. Similar to text boxes in layout programs -->
                  <!-- {{ element.type === 'group' ? 'Test Step Group' : 'Test Step ' }} -->
                </div>
              </div>
              <!-- <step-execution-element :element="element" :current_step="current_step" /> -->
              <!-- <div class="step-execution-element" v-if="element.type !== 'group' && current_step && current_step._id === element._id">

                  <step-run-loader />
              </div>
              <div class="step-execution-element" v-else-if="element.type === 'group' && current_step._id">
                  <step-run-loader v-if="element.children.find((c) => c._id === current_step._id)" />
              </div> -->

              <!-- <div class="step-execution-element">
                  <span v-if="element.error_obj && element.error_obj.status" :style="{ color: 'red' }">{{ element.error_obj.message }}</span>
              </div> -->
            </div>
            <div :class="[current_step._id === element._id ? 'testcase-loader' : '']">
              <step-execution-element :element="element" :current_step="current_step"/>
            </div>

            <step-action-list
                :current_step="current_step"
                :element="element"
                :break_point="break_point"
                :hovered_step="hoveredStep"
                @onSetting="(e) => $emit('onSetting', e)"
                @onBreakPoint="(e) => $emit('onBreakPoint', e)"
                v-if="srcType === 'testSteps'"
            />

            <!-- <div :class="[current_step._id !== element._id ? 'test-step__action_icons' : '']" style="display: block">
                <div class="action__right" v-if="current_step._id !== element._id">
                    <span class="action-button">
                        <Button icon="fas fa-cog" class="p-button-rounded p-button-outlined" @click="$emit('onSetting', element)" />
                    </span>
                    <span class="action-button" v-if="element._id !== break_point._id">
                        <Button icon="fas fa-pause" class="p-button-rounded p-button-outlined" @click="$emit('onBreakPoint', element)" />
                    </span>
                    <span class="action-button" v-else>
                        <Button icon="fas fa-play" :style="{ ...(element._id == break_point._id ? { border: '5px solid rgba(0,0,255,0.7)' } : {}) }" class="p-button-rounded p-button-outlined" />
                    </span>
                </div>
            </div> -->
          </div>

          <nested-draggable
              v-if="expendIds.includes(element._id)"
              @onMove="(evt) => $emit('onMove', evt, evt.draggedContext, evt.relatedContext, 'children')"
              @onEnd="(evt) => $emit('onEnd', evt, 'children', element)"
              @onAdd="$emit('onAdd', element, 'children')"
              @onChange="$emit('onChange', element, 'children')"
              @getCheckedValue="(checkValue) => $emit('getCheckedValue', checkValue)"
              @removeCheckedValue="(checkValue) => $emit('removeCheckedValue', checkValue)"
              class="item-sub"
              :class="[element ? 'ch-_id-' + element._id : '']"
              :testSteps="element.children"
              @checkFunc="(event, el, element) => $emit('checkFunc', event, el, element)"
              :current_step="current_step"
              :checkedList="checkedList"
              :srcType="srcType"
              :break_point="break_point"
              @onBreakPoint="(e) => $emit('onBreakPoint', e)"
              @onSetting="(e) => $emit('onSetting', e)"
              @onBrokenLink="(e) => $emit('onBrokenLink', {...e, base_url: testCase ? testCase.base_url : ''})"
              @onDatabaseValidation="(e) => $emit('onDatabaseValidation', {...element})"
              @onApiValidation="(e) => $emit('onApiValidation', {...element})"
              @onEmailValidation="(e) => $emit('onEmailValidation', {...element})"
              @onSMSValidation="(e) => $emit('onSMSValidation', {...element})"
              @stepScroll="(e) => $emit('stepScroll', e)"
              @onShowComparison="(e) => $emit('onShowComparison', e)"
          />
        </div>
      </template>
    </draggable>
    <!-- {{ restarted }} -->
  </div>
</template>
<script>
import draggable from 'vuedraggable';
import ScreenshotLabel from '../components/ScreenshotLabel.vue';
import StepExecutionElement from '../components/StepExecutionElement.vue';
import StepActionList from '../components/test-editor/StepActionList.vue';
import customValidationList from '../utils/custom_validation_list';
import domEvents from '../utils/dom-events-to-record';
import {TestServiceBaseUrl} from '../utils/base_urls';
import BaseCheckbox from '../components/BaseCheckbox.vue';
import {watch} from 'vue';

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    testSteps: {
      required: true,
      type: Array,
    },
    testCase: {
      required: true,
      type: Object,
    },
    current_step: {
      type: Object,
      required: true,
    },
    break_point: {
      type: Object,
      required: false,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {},
    },
    srcType: String,
    checkedList: {
      type: Array,
      default: () => [],
    },
    restarted: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  components: {
    draggable,
    ScreenshotLabel,
    StepExecutionElement,
    StepActionList,
    BaseCheckbox,
  },

  data() {
    // console.log('task', JSON.stringify(this.tasks));
    return {
      selectedCheckValue: [],
      selectedValue: '',
      baseUrl: TestServiceBaseUrl,
      expenadable: false,
      expendIds: [],
      checked: false,
      hover: false,
      customValidationList,
      domEvents,
      exceptEvents: ['resize', 'scroll', 'wheel', 'input', 'refresh', 'sleep', 'check_broken_link', 'url_validation', 'navigate', 'validate_api', 'validate_database','validate_email', 'validate_sms'],
      hoveredStep: {},
      arrayIndexOfSelectedStep: null,
    };
  },
  mounted() {
    var stepCounter = 0;
    var vm = this;

    // console.log(" ㊙️ ㊙️ ㊙️ ㊙️ ㊙️ ㊙️ ㊙️ ㊙️ ㊙️ ㊙️", this.testCase)

    watch(
        () => this.current_step,
        () => {
          let element = document.getElementById('step-' + this.current_step._id);
          let rect = element && element.getBoundingClientRect();

          // console.log('size', vm.testSteps.length);
          // console.log('stepCounter', stepCounter);

          if (element && rect) {
            this.$emit('stepScroll', rect.height * stepCounter);
            stepCounter++;
          }
          let size = vm.testSteps.length - 1;

          if (stepCounter >= size) {
            stepCounter = 0;
          }
        }
    );
  },
  updated() {
    // console.log(this.testCase)
  },
  methods: {
    $stepClick(element) {
      // console.log(element.action, ' ======== ', customValidationList.CHECK_BROKEN_LINK.action, '  ------  ', this.testCase);
      switch (element.action) {
        case customValidationList.CHECK_BROKEN_LINK.action:
          this.$emit('onBrokenLink', {...element, base_url: this.testCase ? this.testCase.base_url : ''})
          break;
        case customValidationList.ValidateDatabse.action:
          console.log(element);
          this.$emit('onDatabaseValidation', {...element})
          break;

        case customValidationList.API_VALIDATION.action:
          console.log(element);
          this.$emit('onApiValidation', {...element})
          break;

        case customValidationList.ValidateEmail.action:
          console.log(element);
          this.$emit('onEmailValidation', {...element})
          break;

        case customValidationList.ValidateSMS.action:
          console.log(element);
          this.$emit('onSMSValidation', {...element})
          break;

        default:
          console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>> $stepClick", element)
          break;
      }
    },
    emitter(type, elem, evt) {
      console.log("⭕⭕⭕⭕⭕⭕⭕⭕⭕⭕⭕⭕⭕⭕⭕⭕⭕⭕⭕⭕", {type, elem, evt});
      this.$emit(type, elem)
    },
    handleCheckbox(e, is_checked, element) {
      console.log("🧨🧨🧨", e)
      console.log("📢📢📢", element)
      console.log(is_checked);
      if (!is_checked) {
        element.arrayIndex = this.arrayIndexOfSelectedStep;
        this.$emit('getCheckedValue', element);
      } else {
        this.$emit('removeCheckedValue', element);
      }
    },
    handleCheckUncheckBox(e, checked, element) {
      var vm = this;

      var childrenTree = (st) => {
        var i = 0;
        while (i < st.length) {
          var current = st[i];

          if (st[i].children.length > 0) {
            childrenTree(st[i].children);
          }
          i++;
        }
      };

      childrenTree(element.children);

      // if (e.target.checked) {
      //     if (element.children && element.children.length > 0) {
      //         var childrenTree = (st) => {
      //             var i = 0;
      //             st[i].is_checked = e.target.checked;
      //             while (i < st.length) {
      //                 if (st[i].children.length > 0) {
      //                     childrenTree(st[i].children);
      //                 }
      //                 i++;
      //             }
      //         };
      //         childrenTree(element.children);

      //         // element.children.filter((step) => {
      //         //     step.is_checked = e.target.checked;
      //         //     // selectedCheckValue.push(element);
      //         // });
      //     }
      // } else {
      //     if (element.children && element.children.length > 0) {
      //         element.children.filter((step) => {
      //             step.is_checked = e.target.checked;
      //             // remove from selectedCheckValue;
      //         });
      //     } else {
      //         // remove from selectedCheckValue;
      //     }
      // }
    },

    resetCheckValue() {
      console.log('steps-nested-table-checkbox-reset-called');
      this.selectedCheckValue = [];
    },

    handleExpenadOrCollaps(selectedId) {
      if (this.expendIds.indexOf(selectedId) !== -1) {
        const filteredIds = this.expendIds.filter((id) => {
          return id != selectedId;
        });
        this.expendIds = filteredIds;
      } else {
        this.expendIds.push(selectedId);
      }
    },

    onEnd: function (/**Event*/ evt) {
      var itemEl = evt.item; // dragged HTMLElement
      evt.to; // target list
      evt.from; // previous list
      evt.oldIndex; // element's old index within old parent
      evt.newIndex; // element's new index within new parent
      evt.oldDraggableIndex; // element's old index within old parent, only counting draggable elements
      evt.newDraggableIndex; // element's new index within new parent, only counting draggable elements
      evt.clone; // the clone element
      evt.pullMode; // when item is in another sortable: `"clone"` if cloning, `true` if moving
    },

    checkMove: function (evt) {
      return evt.draggedContext.element.name !== 'apple';
    },

    onChoose: function (/**Event*/ evt) {
      console.log("👀👀👀", evt)
      this.arrayIndexOfSelectedStep = evt.oldIndex;
      evt.oldIndex; // element index within parent
    },

    // Start for dialog confirmation
    openConfirmation() {
      this.displayConfirmation = true;
    },
    closeConfirmation() {
      this.displayConfirmation = false;
    },
    onStepMouseEnter(e, element) {
      this.hoveredStep = element;
    },
    errorsClassNameIndicator(element, current_step) {
      if (element.type === 'group') {
        // console.log('element',element)
        var steps = [];
        // Check children of group is existed
        var childrenTree = (st) => {
          var i = 0;
          if (st && st.length > 0) {
            while (i < st.length) {
              if (st[i].type === 'testStep') {
                steps.push(st[i]);
              }
              if (st && st[i].children && st[i].children.length > 0) {
                childrenTree(st[i].children);
              }
              i++;
            }
          }
        };
        if (element.children && element.children.length) {
          childrenTree(Array.from(element.children));
        } else {
          return '';
        }

        if (steps.every((s) => s.error_obj.hasOwnProperty('status'))) {
          // console.log(
          //     'error_obj',
          //     steps.map((s) => s.error_obj)
          // );
          var searchedStep = steps.every((s) => (s.error_obj ? (s.error_obj.status === false ? true : false) : false));
          if (searchedStep) {
            return 'box__success';
          } else if (!searchedStep) {
            return 'box__error';
          } else {
            return '';
          }
        } else {
          return '';
        }

        // return 'box__success';
      } else {

        if(element.action === customValidationList.CHECK_BROKEN_LINK.action && element.broken_links.length) {

          const brokenLinks = element.broken_links.filter(el => !String(el.status).startsWith('2'));

          if (element.error_obj && String(element.error_obj.status).length) {
            if (brokenLinks.length) {
              return 'box__error';
            } else {
              return 'box__success';
            }
          } else {
            return '';
          }

        } else {

          if (element.error_obj && element.error_obj.status === true) {
            return element.error_obj.type === 'failed' ? 'box__error' : '';
          } else if (element.error_obj && element.error_obj.status === false) {
            return element.error_obj.type === 'success' ? 'box__success' : '';
          } else {
            return '';
          }

        }
      }
    },
    groupsClassNameIndicator(element) {
      if (element.type === 'group') {
        if (element.error_obj && element.error_obj.status === true) {
          return element.error_obj.type === 'failed' ? 'box__error' : '';
        } else if (element.error_obj && element.error_obj.status === false) {
          return element.error_obj.type === 'success' ? 'box__success' : '';
        } else {
          return '';
        }
      }
      return '';
    },

    totalSuccessLinks(element) {
      var total = 0;
      Array.from(element.broken_links)
          .filter((broken_link) => broken_link.status === 200)
          .forEach((n) => {
            total += 1;
          });
      return total;
    },
    totalBrokenLinks(element) {
      var total = 0;
      Array.from(element.broken_links).forEach((n) => {
        // console.log(n.statusCode);
      });
      Array.from(element.broken_links)
          .filter((broken_link) => broken_link.status !== 200)
          .forEach((n) => {
            total += 1;
          });
      return total;
    },
    isSelected(element, checkedList) {
      var isStepExisted = checkedList.find((c) => c === element._id);
      var vm = this;
      // if (element.type === 'group' && element.description === 'G1') {
      //     console.log(document.getElementById(element._id) && document.getElementById(element._id).checked);

      //     console.log(element.description, this.$refs[element._id] && this.$refs[element._id].value);

      //     //console.log('isStepExisted',element.type,element.description)
      // }
      if (isStepExisted) {
        return true;
      } else {
        return false;
      }
    },
  },
  computed: {},
  name: 'nested-draggable',
};
</script>
<style scoped lang="scss">
.rmv-padding {
  padding: 0 !important;
  text-align: center !important;

  .item-sub {
    margin: 0 0 0 1.8rem;
  }

  .item-sub > .item-container > .testStep-wrapper > .test-step {
    padding: 0 !important;
    text-align: center !important;
  }
}

.item-container {
  max-height: 585px;
  margin: 0;
  padding: 0;
}

.item-sub {
  margin: 0 0 0 5.8rem;
  /* display: none; */
}

.left-margin {
  margin-left: 1.5rem !important;
}

.custom-right-icon {
  width: 18px;
  height: 14px;
}

.custom-right-icon::before {
  content: '\e902';
}

.main-row-section {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 80px;

  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  opacity: 1;
}
.ch-1 {
  flex: 1;
}

.ch-2 {
  flex: 10;
}

.ch-3 {
  flex: 1;
}
</style>
