import AuthServiceRequest from '../utils/request/auth_request';

export const createSubscription = async data => {
    return await AuthServiceRequest.post(`/subscriptions/save`, data);
};

export const fetchClientList = async (duration, bodyData) => {

    return await AuthServiceRequest.post(`/tj-admin/client/list/${duration}`, bodyData)
        .then(res => {
            return res;
        })
        .catch(error => {
            return error;
        });
};

export const deleteSubscriptionPackage = async reqDataObj => {
    return await AuthServiceRequest.delete(`/subscriptions/delete`, {data: reqDataObj});
};

export const updateSubscription = async data => {
    return await AuthServiceRequest.put(`/subscriptions/update`, data);
};


