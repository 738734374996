<template>
  <div :class="containerClass" v-if="visible" :style="style">
    <slot>
      <img :src="image" v-if="image">
      <span :class="iconClass" v-else-if="icon"></span>
      <div class="p-chip-text p-mr-1" v-if="label">{{ label }}</div>
      <span v-if="removable" tabindex="0" :class="removeIconClass"
            @click="close($event,label)" @keydown.enter="close($event,label)"></span>
    </slot>
  </div>
</template>

<script>
export default {
  emits: ['remove'],
  props: {
    label: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    image: {
      type: String,
      default: null
    },
    removable: {
      type: Boolean,
      default: false
    },
    removeIcon: {
      type: String,
      default: 'pi pi-times-circle'
    },
    style: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      visible: true
    }
  },
  methods: {
    close(event, label) {
      this.visible = false;
      this.$emit('remove', event, label);
    }
  },
  computed: {
    containerClass() {
      return ['p-chip p-component', {
        'p-chip-image': this.image != null
      }];
    },
    iconClass() {
      return ['p-chip-icon', this.icon];
    },
    removeIconClass() {
      return ['pi-chip-remove-icon', this.removeIcon];
    }
  }
}
</script>

<style scoped lang="scss">
.p-chip {
  display: inline-flex;
  align-items: center;
  background-color: #EDF1F6;
  color: #282828;
  border-radius: 16px;
  padding: 0.25rem 0.9375rem;
  margin-left: 8px;

}

.p-chip-text {
  line-height: 1.5;
  font-size: 0.75rem;

}

.p-chip-icon.pi {
  line-height: 1.5;
}

.pi-chip-remove-icon {
  line-height: 1.5;
  cursor: pointer;
}

.p-chip img {
  border-radius: 50%;

  & .p-chip-text {
    line-height: 1.5;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }

  width: 2rem;
  height: 2rem;
  margin-left: -0.5rem;
  margin-right: 0.5rem;

}

</style>
