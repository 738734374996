import TestServiceRequest from '../utils/request/test_request';
// Test Rail API Section
/**
 * Fetch testrails project list
 * @returns
 */
export const fetchTestrailProjects = async (prjId) => {
    return await TestServiceRequest.get(`/bug-tracker/integration/${prjId}/projects`);
};
/**
 * Fetch testrails test case list
 * @param {*} projectId
 * @returns
 */
export const fetchTestrailTestCases = async (projectId) => {
    return await TestServiceRequest.post(`/testrails/test-cases/${projectId}`);
};
/**
 * Save testrails test case
 * @param {*} data
 * @returns
 */
export const storeTestrailRun = async (data) => {
    console.log("🌍 🌍 🌍 🌍 🌍 🌍 🌍 🌍 🌍 🌍 🌍 🌍 🌍 ======>>>>>", data);
    return await TestServiceRequest.post(`/testrails/create-run`, data);
};
