import MobileServiceRequest from '@/utils/request/mobile_request';

export const fetchSingleMobileProjectOverviewInfo = (projectID) => {
    return  MobileServiceRequest.get(`/projects/${projectID}/overview`)
};

export const fetchSingleMobileProjectRunsHistory = (projectID, days = 'all') => {
    const queryParam = days === 'all' ? '' : `?days=${days}`;
    return  MobileServiceRequest.get(`/projects/${projectID}/executed-test-runs${queryParam}`)
};

export const fetchSingleMobileProjectHeader = (projectID) => {
    return  MobileServiceRequest.get(`/projects/${projectID}/failed-cases`)
};

export const fetchSingleMobileProjectPassingRate = (projectID, days = 'all') => {
    const queryParam = days === 'all' ? '' : `?days=${days}`;
    return  MobileServiceRequest.get(`/projects/${projectID}/pass-rate${queryParam}`)
};

export const fetchSingleMobileProjectFailedCases = (projectID, days = 'all') => {
    const queryParam = days === 'all' ? '' : `?days=${days}`;
    return  MobileServiceRequest.get(`/projects/${projectID}/failed-cases${queryParam}`)
};

export const fetchMobileDashboardInfo = () => {
    return  MobileServiceRequest.get(`/dashboard`)
};

export const fetchDashboardProjectStat = async payload => {
    return await MobileServiceRequest.get(`/dashboard/project-status/` + payload.days);
};

export const previewReport = async (reportData) => {
    let response = [null, null];
    console.log('🇦🇨 🇦🇨 🇦🇨 🇦🇨 🇦🇨 🇦🇨 ', reportData);

    try {
        const res = await MobileServiceRequest.post('/reports/preview', reportData)
        console.log("🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷 🇧🇷", res);
        response = [res, null];

    } catch (err) {
        console.log("🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷 🇦🇷", err.response);
        response = [null, err.response];


    } finally {
        return response;
    }
}

export const insertReport = async reportData => {
    return await MobileServiceRequest.post('/reports/', reportData)
}

export const fetchTestReportByID = async reportID => {
    return await MobileServiceRequest.get(`/reports/` + reportID)
};

export const deleteTestReport = async (reqDataObj) => {
    return await MobileServiceRequest.delete(`/reports/`, {data: reqDataObj}).then(res => {
        return res;
    }).catch(error => {
        return error
    })
}

export const fetchTestReportList = async (filterObj) => {
    return await MobileServiceRequest.get(`/reports?project=${filterObj.projectId}`)
};
