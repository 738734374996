<template>
  <div class="app">
    <span class="main-app-loader" v-if="loading">
      <Loader />
    </span>
    <router-view v-else />
  </div>
</template>

<script>
import EventBus from '@/event-bus';
import store from '@/store';
import { getToken, removeToken } from './utils/cookies';
import Loader from './components/Loader.vue';
import { mapActions, mapGetters } from 'vuex';
import { removeSelectedCurrentProjectId, removeSelectedCurrentProjectType } from './utils/localstorage';
import { whiteListed } from './permission';

export default {
  created() {
    console.log(store);
    this.fetchUserInfoByToken();
    this.FetchCountries();
  },
  mounted() {
    EventBus.on('show-message', (data) => {
      this.$toast.add({ severity: data.type, summary: data.message, life: 5000 });
    });
  },
  components: {
    Loader,
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapGetters({
      loggedInUserInfo: 'auth/loggedInUserInfo',
    }),
  },
  methods: {
    ...mapActions({
      GetOrganizationData: 'organization/GetOrganizationData',
      FetchAuthInfo: 'auth/actionFetchAuthInfo',
      FetchCountries: 'profile/actionFetchCountryList',
      LogoutHandler: 'auth/actionLogoutHandler',
      clearProjectListState: 'project/actionClearProjectList',
      clearTestCaseListSate: 'testCase/actionClearTestCaseList',
      clearTestRunListState: 'testRun/actionClearTestRunList',
      onChangeProjectHandler: 'project/actionOnchangeProjectHandler',
    }),

    logoutUser() {
      // this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Authentication Failed !', life: 3000 });
      this.clearProjectListState();
      this.clearTestCaseListSate();
      this.clearTestRunListState();
      removeSelectedCurrentProjectId();
      this.onChangeProjectHandler()
      removeSelectedCurrentProjectType();
      removeToken();
      this.$socket?.disconnect();
      this.LogoutHandler();
      setTimeout(() => {
        // console.log("🍥 🍥 🍥 🍥 🍥 🍥 🍥 🍥 🍥 🍥 🍥 🍥 🍥 🍥 ", this.$route.path);
        if (!whiteListed?.includes(this.$route?.path)) {
          this.$router.push({ path: '/login' });
        }
      }, 200);
    },

    async fetchUserInfoByToken() {
      this.loading = true;
      const token = getToken();

      if (token) {
        console.log("TOKEN FOUND");
        const [authResp, authErr] = await this.FetchAuthInfo();

        if (authErr) {
          this.logoutUser()
          this.loading = false;
          return;
        }

        await this.GetOrganizationData(this.loggedInUserInfo.organizationId)
      } else {
        console.log("NO TOKEN");
        this.logoutUser();
      }

      this.loading = false;
    }
  },
};
</script>

<style lang="scss">
@import './App.scss';

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.main-app-loader {
  height: 100vh;
  display: grid;
  place-items: center;
}
</style>
