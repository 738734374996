import {createRouter, createWebHistory} from 'vue-router';
import TestEditorPage from '../views/TestEditorPage.vue';
import TestCaseCreatePage from '../views/TestCaseCreatePage.vue';
import ClientInformation from '../views/ClientInformation.vue';
import SubscriptionPackages from '../views/SubscriptionPackages.vue';
import CreateSubscription from '../views/CreateSubscription.vue';

import PageNotFound from '../views/PageNotFound.vue';
import NetworkNotFound from '../views/NetworkNotFound.vue';
import MainLayout from '@/layouts/MainLayout.vue';
import DashboardLayout from '@/layouts/DashboardLayout.vue';

import ClientProfileLayout from '@/layouts/ClientProfileLayout.vue'
import MobileTestEditorPage from "@/mobile/components/MobileTestEditorPage";
import TestCaseListPageMobile from "@/mobile/components/TestCaseListPageMobile";
import TestRunMobileDetailsPage from "@/mobile/components/TestRunMobileDetailsPage";
import MobileTestCaseCreatePage from "@/mobile/components/MobileTestCaseCreatePage";
import Downloads from "@/pages/Downloads.vue";
import AgentGuideLine from "@/pages/AgentGuideLine.vue";

const routes = [
    {
        path: '/',
        redirect: '/dashboard',
    },

    {
        path: '/user/set-up-account',
        name: 'User Account Setup',
        component: () => import('../views/UserAccountSetupForm.vue'),
        meta: {
            requiresAuth: true,
            title: 'User Account Setup',
            roles: ['tj_super_admin', 'admin', 'user'],
        },
    },
    {
        path: '/member/set-up-account',
        name: 'Member Account Setup',
        component: () => import('../views/MemberAccountSetupForm.vue'),
        meta: {
            requiresAuth: true,
            title: 'Member Account Setup',
            roles: ['tj_support', 'tj_admin', 'admin', 'tester', 'readonly'],
        },
    },
    {
        path: '/team-management',
        component: ClientProfileLayout,
        children: [
            {
                path: '/team-management',
                name: 'Team Management',
                component: () => import('../components/admin-team-management/TeamMember.vue'),
                meta: {
                    requiresAuth: true,
                    title: 'Team management',
                    roles: ['tj_super_admin', 'tj_admin', 'admin'],
                },
            },
        ],
    },
    {
        path: '/project-edit',
        component: MainLayout,
        children: [
            {
                path: '/project-edit',
                name: 'Project Edit',
                component: () => import('../components/settings-components/ProjectEdit.vue'),
                meta: {
                    // requiresAuth: true,
                    title: 'Project Edit',
                },
            },
        ],
    },
    {
        path: '/project-member-edit',
        component: MainLayout,
        children: [
            {
                path: '/project-member-edit',
                name: 'Project Member Edit',
                component: () => import('../components/settings-components/ProjectMemberEdit.vue'),
                meta: {
                    // requiresAuth: true,
                    title: 'Project Member Edit',
                },
            },
        ],
    },
    {
        path: '/admin-account-edit',
        component: MainLayout,
        children: [
            {
                path: '/admin-account-edit',
                name: 'Admin Account Edit',
                component: () => import('../components/settings-components/AdminAccountSettings.vue'),
                meta: {
                    requiresAuth: true,
                    title: 'Admin Account Edit',
                    roles: ['tj_super_admin', 'tj_admin', 'admin'],
                },
            },
        ],
    },
    {
        path: '/tester-account-edit',
        component: MainLayout,
        children: [
            {
                path: '/tester-account-edit',
                name: 'Tester Account Edit',
                component: () => import('../components/settings-components/TesterAccountSettings.vue'),
                meta: {
                    requiresAuth: true,
                    title: 'Tester Account Edit',
                    roles: ['tester', 'tj_admin', 'readonly', 'user'],
                },
            },
        ],
    },
    {
        path: '/variables',
        name: 'variables',
        component: MainLayout,
        children: [
            {
                path: '/',
                children: [
                    {
                        path: '/variables/',
                        component: () => import('../views/Variables.vue'),
                    },
                ],
            },
        ],
        meta: {
            requiresAuth: true,
            title: 'Settings',
            roles: ['tj_super_admin', 'tj_admin', 'tj_support', 'admin', 'tester', 'readonly', 'user']
        },
    },
    {
        path: '/settings',
        name: 'settings',
        component: MainLayout,
        children: [
            {
                path: '/',
                children: [
                    {
                        path: '/settings/',
                        component: () => import('../views/SettingsPage.vue'),
                    },
                ],
            },
        ],
        meta: {
            requiresAuth: true,
            title: 'Settings',
            roles: ['tj_super_admin', 'tj_admin', 'tj_support', 'admin', 'tester', 'readonly', 'user']
        },
    },
    {
        path: '/organization-details',
        component: ClientProfileLayout,
        children: [
            {
                path: '',
                name: 'Organization Details',
                component: () => import('../components/client-profile-settings/OrganizationDetails.vue'),
                meta: {requiresAuth: true, title: 'Organization Details'},
            },
        ],
        meta: {
            requiresAuth: true,
            title: 'Organization Details',
            roles: ['tj_super_admin', 'tj_admin', 'tj_support', 'admin', 'tester', 'readonly', 'user']
        },
    },
    {
        path: '/account-settings',
        component: ClientProfileLayout,
        children: [
            {
                path: '',
                name: 'Account Settings',
                component: () => import('../components/client-profile-settings/AccountSettings.vue'),
                meta: {requiresAuth: true, title: 'Account Settings'},
            },
        ],
        meta: {
            requiresAuth: true,
            title: 'Account Settings',
            roles: ['tj_super_admin', 'tj_admin', 'tj_support', 'admin', 'tester', 'readonly', 'user']
        },
    },
    {
        path: '/package-details',
        component: ClientProfileLayout,
        children: [
            {
                path: '',
                name: 'Package Details',
                component: () => import('../components/client-profile-settings/PackageDetails.vue'),
                meta: {requiresAuth: true, title: 'Package Details'},
            },
        ],
        meta: {
            requiresAuth: true,
            title: 'Package Details',
            roles: ['tj_super_admin', 'tj_admin', 'tj_support', 'admin', 'tester', 'readonly', 'user']
        },
    },
    {
        path: '/billing-history',
        component: ClientProfileLayout,
        children: [
            {
                path: '',
                name: 'Billing History',
                component: () => import('../components/client-profile-settings/BillingHistory.vue'),
                meta: {requiresAuth: true, title: 'Billing History'},
            },
        ],
        meta: {
            requiresAuth: true,
            title: 'Billing History',
            roles: ['tj_super_admin', 'tj_admin', 'tj_support', 'admin', 'tester', 'readonly', 'user']
        },
    },
    //Create problem when use nested function , keep it that way
    {
        path: '/broken-link',
        component: MainLayout,
        children: [
            {
                path: '/broken-link',
                name: 'Broken Link',
                component: () => import('../components/broken-link/BrokenLink.vue'),
                meta: {requiresAuth: true, title: 'Broken Link'},
            },
        ],
    },
    {
        path: '/broken-link',
        component: MainLayout,
        children: [
            {
                path: '/broken-link',
                name: 'Broken Link',
                component: () => import('../components/broken-link/BrokenLink.vue'),
                meta: {requiresAuth: true, title: 'Broken Link'},
            },
        ],
    },
    {
        path: '/clients',
        component: MainLayout,
        children: [
            {
                path: '/clients',
                name: 'clientlist',
                component: () => import('../views/ClientListPage.vue'),
                meta: {requiresAuth: true, title: 'Client List', roles: ['tj_super_admin', 'tj_admin']},
            },
        ],
    },
    {
        path: '/tj-admin-features',
        component: MainLayout,
        children: [
            {
                path: '/tj-admin-features',
                name: 'tjadminfeatures',
                component: () => import('../views/TjAdminFeaturePage.vue'),
                meta: {
                    requiresAuth: true,
                    title: 'Admin Features',
                    roles: ['tj_super_admin', 'tj_admin', 'tj_support']
                },
            },
        ],
    },
    {
        path: '/reports/create-new',
        component: MainLayout,
        children: [
            {
                path: '/reports/create-new',
                name: 'reports',
                component: () => import('../views/CreateNewReport.vue'),
                meta: {requiresAuth: true, title: 'Create Reports', roles: ['admin', 'tester']},
            },
        ],
    },
    {
        path: '/mobile-reports/create-new',
        component: MainLayout,
        children: [
            {
                path: '/mobile-reports/create-new',
                name: 'Mobile Reports',
                component: () => import('../mobile/views/CreateNewReport.vue'),
                meta: {requiresAuth: true, title: 'Create Reports', roles: ['admin', 'tester']},
            },
        ],
    },
    {
        path: '/create/new-testcase',
        component: MainLayout,
        children: [
            {
                name: 'TestCaseCreatePage',
                path: '/create/new-testcase',
                component: TestCaseCreatePage,
            },
        ],
        meta: {requiresAuth: true, title: 'Test Editor', roles: ['admin', 'tester']},
    },
    {
        path: '/test-editor/',
        component: MainLayout,
        children: [
            {
                name: 'TestEditorPage',
                path: '/test-editor/:id',
                component: TestEditorPage,
            }
        ],
        meta: {requiresAuth: true, title: 'Test Editor', roles: ['admin', 'tester']},
    },
    {
        path: '/client-information',
        component: MainLayout,
        children: [
            {
                name: 'Client Information',
                path: '/client-information',
                component: ClientInformation,
            },
        ],
        meta: {requiresAuth: true, title: 'Client Information', roles: ['tj_super_admin', 'tj_admin', 'tj_support']},
    },
    {
        path: '/subscription-packages',
        component: MainLayout,
        children: [
            {
                name: 'Subscription Packages',
                path: '/subscription-packages',
                component: SubscriptionPackages,
            },
        ],
        meta: {requiresAuth: true, title: 'Client Information', roles: ['tj_super_admin', 'tj_admin', 'tj_support']},
    },
    {
        path: '/create-subscription',
        component: MainLayout,
        children: [
            {
                name: 'Create Subscription',
                path: '/create-subscription',
                component: CreateSubscription,
            },
        ],
        meta: {requiresAuth: true, title: 'Create Subscription', roles: ['tj_super_admin', 'tj_admin']},
    },
    {
        path: '/downloads',
        // fullPath: '/downloads',
        name: 'downloadspage',
        component: MainLayout,
        children: [
            {
                path: '',
                component: () => import('../pages/Downloads.vue'),
                meta: {requiresAuth: true, roles: ['tj_super_admin', 'tj_admin', 'tj_support', 'admin', 'user']}
            }
        ]
    },
    {
        path: '/agent-installation-guideline',
        // fullPath: '/downloads',
        name: 'agentGuideLine',
        component: MainLayout,
        children: [
            {
                path: '',
                component: () => import('../pages/AgentGuideLine.vue'),
                meta: {requiresAuth: true, roles: ['tj_super_admin', 'tj_admin', 'tj_support', 'admin', 'user']}
            }
        ]
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: DashboardLayout,
        children: [
            {
                path: '',
                component: () => import('../components/client-project-dashboard/ClientProjectDashboard.vue'),
                children: [
                    {
                        path: '',
                        component: () => import('../components/client-project-dashboard/ProjectList.vue'),
                    },
                    {
                        path: '/dashboard/create-project',
                        component: () => import('../components/client-project-dashboard/CreateProject.vue'),
                        meta: {roles: ['admin', 'tester', 'user']}
                    },
                ],
            },
        ],
        meta: {
            requiresAuth: true,
            title: 'Dashboard',
            roles: ['tj_super_admin', 'tj_admin', 'tj_support', 'admin', 'user']
        },
    },
    {
        path: '/admin-dashboard',
        name: 'admin dashboard',
        component: MainLayout,
        children: [
            {
                path: '',
                component: () => import('../components/admin-dashboard/AdminDashboard.vue'),
                // children: [
                //     {
                //         path: '',
                //         component: () => import('../components/client-project-dashboard/ProjectList.vue'),
                //     },
                //     {
                //         path: '/dashboard/create-project',
                //         component: () => import('../components/client-project-dashboard/CreateProject.vue'),
                //         meta: {roles: ['admin', 'tester', 'user']}
                //     },
                // ],
            },
        ],
        meta: {requiresAuth: true, title: 'Admin Dashboard', roles: ['tj_super_admin', 'tj_admin', 'tj_support']},
    },
    {
        path: '/subscription-wise-clients',
        name: 'clients',
        component: MainLayout,
        children: [
            {
                path: '',
                component: () => import('../views/SubscriptionWiseClients.vue'),
                // children: [
                //     {
                //         path: '',
                //         component: () => import('../components/client-project-dashboard/ProjectList.vue'),
                //     },
                //     {
                //         path: '/dashboard/create-project',
                //         component: () => import('../components/client-project-dashboard/CreateProject.vue'),
                //         meta: {roles: ['admin', 'tester', 'user']}
                //     },
                // ],
            },
        ],
        meta: {requiresAuth: true, title: 'Clients', roles: ['tj_super_admin', 'tj_admin', 'tj_support']},
    },
    {
        path: '/test-cases',
        name: 'Test Cases',
        component: MainLayout,
        children: [
            {
                path: '/test-cases',
                name: 'TestCaseListPage',
                component: () => import('../views/TestCaseListPage.vue'),
                meta: {requiresAuth: true, title: 'TestCase Page', roles: ['admin', 'tester']},
            },
        ],
    },
    // {
    //     path: '/new-test-cases',
    //     name: 'New Test Cases',
    //     component: MainLayout,
    //     children: [
    //         {
    //             path: '/new-test-cases',
    //             name: 'NewTestCaseListPage',
    //             component: () => import('../views/TestCaseListPage.vue'),
    //             meta: {requiresAuth: true, title: 'TestCase Page', roles: ['admin', 'tester']},
    //         },
    //     ],
    // },
    {
        path: '/new-test',
        name: 'NewTest',
        component: () => import('../components/NewTest.vue'),
        meta: {requiresAuth: true, title: 'NewTest', roles: ['admin', 'tester']},
    },
    {
        path: '/test-runs-mobile',
        name: 'Mobile Test Runs',
        component: MainLayout,
        children: [
            {
                path: '/test-runs-mobile',
                name: 'Mobile Test Runs',
                component: () => import('../mobile/views/TestRuns.vue'),
                meta: {requiresAuth: true, title: 'Mobile Test Runs', roles: ['admin', 'tester']},
            },
        ],
    },
    {
        path: '/test-runs',
        name: 'Test Runs List Page',
        component: MainLayout,
        children: [
            {
                path: '/test-runs',
                name: 'Test Runs List Page',
                component: () => import('../views/TestRunsListPage.vue'),
                meta: {requiresAuth: true, title: 'Test Runs List Page', roles: ['admin', 'tester']},
            },
        ],
    },
    {
        path: '/test-runs-details/:id',
        name: 'Test Runs Details',
        component: MainLayout,
        children: [
            {
                path: '/test-runs-details/:id',
                name: 'Test Runs Details',
                component: () => import('../views/TestRunDetails.vue'),
                meta: {requiresAuth: true, title: 'Test Runs Details', roles: ['admin', 'tester']},
            },
        ],
    },
    {
        path: '/test-runs-report',
        name: 'Test Runs Report',
        component: MainLayout,
        children: [
            {
                path: '/test-runs-report/native/:id?/:reportId?',
                name: 'Test Runs Report Native',
                component: () => import('../views/TestRunReportNative.vue'),
                meta: {requiresAuth: true, title: 'Test Runs Report Native', roles: ['admin', 'tester']},
            },
            {
                path: '/test-runs-report/cloud/:id?/:reportId?',
                name: 'Test Runs Report Cloud',
                component: () => import('../views/TestRunReportCloud.vue'),
                meta: {requiresAuth: true, title: 'Test Runs Report Cloud', roles: ['admin', 'tester']},
            },
            {
                path: '/test-runs-report/schedule/:id?/:reportId?',
                name: 'Test Runs Report Schedule',
                component: () => import('../views/TestRunReportSchedule.vue'),
                meta: {requiresAuth: true, title: 'Test Runs Report Schedule', roles: ['admin', 'tester']},
            },
        ],
    },
    {
        path: '/step-screenshot-comparison',
        name: 'Step Screenshot Comparison',
        component: MainLayout,
        children: [
            {
                path: '/step-screenshot-comparison',
                name: 'Step Screenshot Comparison',
                component: () => import('../components/ComparisonAutomationDialog.vue'),
                meta: {requiresAuth: true, title: 'Step Screenshot Comparison', roles: ['admin', 'tester']},
            },
        ],
    },
    {
        path: '/project-overview/:id',
        name: 'ProjectOverview',
        component: MainLayout,
        children: [
            {
                path: '/project-overview/:id',
                name: 'ProjectOverview',
                component: () => import('../components/project-overview/ProjectOverview.vue'),
                meta: {requiresAuth: true, title: 'ProjectOverview', roles: ['admin', 'tester', 'readonly']},
            },
        ],
    },
    {
        path: '/test-run-case-report',
        name: 'TestRunReportInfo',
        component: MainLayout,
        children: [
            {
                path: '/test-run-case-report',
                name: 'TestRunAndCaseReportInfo',
                component: () => import('../components/TestRunAndCaseReportInfo.vue'),
                meta: {requiresAuth: true, title: 'TestRunAndCaseReportInfo'},
            },
        ],
    },
    {
        path: '/reports/details/:id',
        name: 'TestRunAndCaseReportPage',
        component: MainLayout,
        children: [
            {
                path: '/reports/details/:id',
                name: 'TestRunAndCaseReport.Page',
                component: () => import('../views/TestRunAndCaseReportPage.vue'),
                meta: {requiresAuth: true, title: 'TestRunAndCaseReportPage', roles: ['admin', 'tester', 'readonly']},
            },
        ],
    },
    {
        path: '/reports/all',
        name: 'All Report Page',
        component: MainLayout,
        children: [
            {
                path: '/reports/all',
                name: 'All Report Page',
                component: () => import('../views/AllReport.vue'),
                meta: {requiresAuth: true, title: 'All Report Page', roles: ['admin', 'tester', 'readonly']},
            },
        ],
    },
    {
        path: '/mobile-reports/all',
        name: 'All Mobile Report Page',
        component: MainLayout,
        children: [
            {
                path: '/mobile-reports/all',
                name: 'All Mobile Report Page',
                component: () => import('../mobile/views/AllReport.vue'),
                meta: {requiresAuth: true, title: 'All Report Page', roles: ['admin', 'tester', 'readonly']},
            },
        ],
    },
    {
        path: '/validation-panel',
        name: 'PropertiesSideBar',
        component: () => import('../components/PropertiesSideBar.vue'),
    },
    {
        path: '/jira/ticket',
        name: 'JiraTicket',
        component: MainLayout,
        children: [
            {
                path: '/jira/ticket',
                name: 'JiraTicket',
                component: () => import('../components/JiraTicket.vue'),
                meta: {requiresAuth: true, title: 'JiraTicket'},
            },
        ],
    },
    //* ***************************************** MOBILE ROUTES ********************************* MOBILE ROUTES
    //! ***************************************** MOBILE ROUTES ********************************* MOBILE ROUTES
    //? ***************************************** MOBILE ROUTES ********************************* MOBILE ROUTES
    //! ***************************************** MOBILE ROUTES ********************************* MOBILE ROUTES
    //* ***************************************** MOBILE ROUTES ********************************* MOBILE ROUTES
    {
        path: '/project-overview-mobile/:id',
        name: 'MobileProjectOverview',
        component: MainLayout,
        children: [
            {
                path: '/project-overview-mobile/:id',
                name: 'MobileProjectOverview',
                component: () => import('../mobile/components/MobileProjectOverview.vue'),
                meta: {requiresAuth: true, title: 'MobileProjectOverview', roles: ['admin', 'tester', 'readonly']},
            },
        ],
    },
    {
        path: '/test-editor-mobile/',
        component: MainLayout,
        children: [
            {
                name: 'MobileTestEditorPage',
                path: '/test-editor-mobile/:id',
                component: MobileTestEditorPage,
            }
        ],
        meta: {requiresAuth: true, title: 'Test Editor', roles: ['admin', 'tester']},
    },
    {
        path: '/test-cases-mobile',
        name: 'Test Cases mobile',
        component: MainLayout,
        children: [
            {
                path: '/test-cases-mobile',
                name: 'TestCaseListPageMobile',
                component: TestCaseListPageMobile,
                meta: {requiresAuth: true, title: 'TestCase Page', roles: ['admin', 'tester']},
            },
        ],
    },
    {
        path: '/create/new-testcase-mobile',
        component: MainLayout,
        children: [
            {
                name: 'MobileTestCaseCreatePage',
                path: '/create/new-testcase-mobile',
                component: MobileTestCaseCreatePage,
            },
        ],
        meta: {requiresAuth: true, title: 'Test Editor', roles: ['admin', 'tester']},
    },
    {
        path: '/test-runs-details-mobile/:id',
        name: 'Test Runs Details Page Mobile',
        component: MainLayout,
        children: [
            {
                path: '/test-runs-details-mobile/:id',
                name: 'test run details mobile page',
                component: TestRunMobileDetailsPage,
                meta: {requiresAuth: true, title: 'test run details page mobile', roles: ['admin', 'tester']},
            },
        ],
    },
    {
        path: '/mobile-test-run-report',
        name: 'Test Runs Mobile Report',
        component: MainLayout,
        children: [
            {
                path: '/mobile-test-run-report/:runId?/native/:executedRunId?',
                name: 'Test Runs Mobile Report Native',
                component: () => import('../mobile/views/testRunMobileNativeReport.vue'),
                meta: {requiresAuth: true, title: 'Test Runs Mobile Report Native', roles: ['admin', 'tester']},
            },
            {
                path: '/mobile-test-run-report/:runId/cloud/:executedRunId?',
                name: 'Test Runs Mobile Report Cloud',
                component: () => import('../mobile/views/testRunMobileCloudReport.vue'),
                meta: {requiresAuth: true, title: 'Test Runs Mobile Report Cloud', roles: ['admin', 'tester']},
            },
        ],
    },

    {path: '/no-internet', component: NetworkNotFound},
    // Page not found
    {path: '/:pathMatch(.*)*', component: PageNotFound},

];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
