<template>
  <div :style="{ display: 'flex', justifyContent: 'center' }">
    <ProgressSpinner style="margin-top: 50px; width: 50px; height: 50px" strokeWidth="2" fill="#fff"
                     animationDuration=".5s"/>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>
