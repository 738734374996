import { getOptimalXPath, getOptimalClassChain, getOptimalPredicateString } from "@/utils/locatorUtils";

export class OverlayHandler {
  constructor(xmlString, imageElement, xmlOverlay) {
    this.xmlDoc = new DOMParser().parseFromString(xmlString, "text/xml");
    this.imageElement = imageElement;
    this.xmlOverlay = xmlOverlay;
    this.htmlXmlMap = new Map();
    this.height = 0;
    this.width = 0;
    this.ratio = 0;
  }

  getElement(div) {
    if (this.htmlXmlMap.has(div)) {
      return this.htmlXmlMap.get(div);
    } else {
      return null;
    }
  }

  getLocators(div) {
    const context = this;
    const xmlElement = context.getElement(div);

    // return null if the element is not found in the map
    if (xmlElement === null) return null;
    const locators = {
      xPath: '',
      classChain: '',
      predicateString: ''
    }

    try {
      locators.xPath = getOptimalXPath(context.xmlDoc, xmlElement);
      locators.classChain = getOptimalClassChain(context.xmlDoc, xmlElement);
      locators.predicateString = getOptimalPredicateString(context.xmlDoc, xmlElement);
    } catch (_e) {
      // ignore
    }
    return locators;
  }

  sortRectanglesByArea(rectangles) {
    rectangles.sort((rect1, rect2) => {
      const area1 = rect1.height * rect1.width;
      const area2 = rect2.height * rect2.width;
      return parseInt(area2 - area1);
    });
  }

  sortRectanglesByCentroid(rectangles) {
    rectangles.sort((rect1, rect2) => {
      // Calculate centroids
      const centroid1 = {
        x: (rect1.x + rect1.x2) / 2,
        y: (rect1.y + rect1.y2) / 2
      };
      const centroid2 = {
        x: (rect2.x + rect2.x2) / 2,
        y: (rect2.y + rect2.y2) / 2
      };

      // Compare centroids
      if (
        centroid1.x >= rect2.x &&
        centroid1.x <= rect2.x2 &&
        centroid1.y >= rect2.y &&
        centroid1.y <= rect2.y2
      ) {
        // rect1's centroid is inside rect2, so it should go after rect2
        return 1;
      } else if (
        centroid2.x >= rect1.x &&
        centroid2.x <= rect1.x2 &&
        centroid2.y >= rect1.y &&
        centroid2.y <= rect1.y2
      ) {
        // rect2's centroid is inside rect1, so it should go after rect1
        return -1;
      } else {
        // Sort based on the original criteria
        if (rect1.x !== rect2.x) {
          return rect1.x - rect2.x;
        } else if (rect1.y !== rect2.y) {
          return rect1.y - rect2.y;
        } else if (rect1.x2 !== rect2.x2) {
          return rect1.x2 - rect2.x2;
        } else {
          return rect1.y2 - rect2.y2;
        }
      }
    });
  }

  replaceElementNames(xmlDoc) {
    const context = this;
    // Check if the input is a valid XML document
    if (!(xmlDoc instanceof Document)) {
      console.error('Invalid XML document');
      return;
    }

    // Recursive function to traverse and replace element names
    function traverse(node) {
      if (node.nodeType === Node.ELEMENT_NODE) {
        // Replace the element name with "div"
        const newElement = xmlDoc.createElement('div');

        newElement.setAttribute('style', `
          top: ${parseFloat(node.getAttribute('y')) * context.ratio / context.height * 100}%;
          left: ${parseFloat(node.getAttribute('x')) * context.ratio / context.width * 100}%;
          width: ${parseFloat(node.getAttribute('width')) * context.ratio / context.width * 100}%;
          height: ${parseFloat(node.getAttribute('height')) * context.ratio / context.height * 100}%;
        `);
        // Replace the old element with the new "div" element
        node.parentNode.replaceChild(newElement, node);

        // Traverse through the child nodes of the new "div" element
        for (let i = 0; i < node.childNodes.length; i++) {
          traverse(newElement.appendChild(node.childNodes[i].cloneNode(true)));
        }
      }
    }

    // Start traversing from the root of the XML document
    traverse(xmlDoc.documentElement.children[0]);
  }

  traverseXml(xmlDoc) {
    const visitedElements = [];
    const context = this;

    function visitElement(currentElement) {
      visitedElements.push(currentElement);
      const children = Array.from(currentElement.childNodes)
        .filter((child) => child.nodeType === 1)
        .sort((a, b) => {
          const accA = a.getAttribute("accessible") === "true" ? 1 : 0;
          const accB = b.getAttribute("accessible") === "true" ? 1 : 0;
          return accA - accB;
        });
      for (let i = 0; i < children.length; i++) {
        if (children[i].nodeType === 1) {
          // Check if it's an element node (Node.ELEMENT_NODE)
        }
      }
      for (let i = 0; i < children.length; i++) {
        if (children[i].nodeType === 1) {
          // Check if it's an element node (Node.ELEMENT_NODE)
          visitElement(children[i]);
        }
      }
    }

    const rootElement = xmlDoc.documentElement;
    visitElement(rootElement);

    return visitedElements;
  }

  traverseXml(xmlDoc) {
    const visitedElements = [];
    function visitElement(currentElement) {
      visitedElements.push(currentElement);
      const children = Array.from(currentElement.childNodes)
        .filter((child) => child.nodeType === 1);
      for (let i = 0; i < children.length; i++) {
        if (children[i].nodeType === 1) {
          // Check if it's an element node (Node.ELEMENT_NODE)
          visitElement(children[i]);
        }
      }
    }
    const rootElement = xmlDoc.documentElement;
    visitElement(rootElement);
    return visitedElements;
  }

  getElementInfo(el) {
    let bounds = el.getAttribute("bounds");
    // console.log('bounds', bounds)
    if (bounds?.length) bounds = bounds.replace('][', ',')
    const hasBounds = bounds && bounds !== "";

    const x = hasBounds
      ? parseInt(bounds.split(",")[0].slice(1))
      : parseInt(el.getAttribute("x"));
    const y = hasBounds
      ? parseInt(bounds.split(",")[1])
      : parseInt(el.getAttribute("y"));
    const width = hasBounds
      ? parseInt(bounds.split(",")[2]) - x
      : parseInt(el.getAttribute("width"));
    const height = hasBounds
      ? parseInt(bounds.split(",")[3].slice(0, -1)) - y
      : parseInt(el.getAttribute("height"));
    const isVisible = el.getAttribute("visible") === "true" || el.getAttribute("displayed") === "true";

    return { x, y, height, width, isVisible };
  }

  async processXml() {

    const context = this;
    if (!context.xmlDoc) return;

    const xmlRoot = context.xmlDoc.documentElement.getElementsByTagName("*")[0];

    if (context.imageElement.tagName === 'IMG') {
      await Promise.race([
        new Promise((r) => setTimeout(r, 3000)),
        new Promise((r) => {
          if (context.imageElement.complete) {
            r(context.imageElement);
          } else {
            context.imageElement.onload = () => {
              r(context.imageElement);
            };
          }
        }),
      ]);
    } else if (context.imageElement.tagName === 'VIDEO') {
      await Promise.race([
        new Promise((r) => setTimeout(r, 10000)),
        new Promise((r) => {
          if (context.imageElement.readyState >= 2) {
            r(context.imageElement);
          } else {
            context.imageElement.loadeddata = () => {
              r(context.imageElement);
            };
          }
        }),
      ])
    }

    const styleWidth = parseInt(context.imageElement.style.width, 10);
    const styleHeight = parseInt(context.imageElement.style.height, 10);
    this.width = (styleWidth > 0) ? styleWidth : context.imageElement.width;
    this.height = (styleHeight > 0) ? styleHeight : context.imageElement.height;

    const ratio = context.width / this.getElementInfo(xmlRoot).width;

    const xmlElements = context.traverseXml(context.xmlDoc);
    const overlayElements = []
    xmlElements.forEach((el) => {

      const { x, y, height, width, isVisible } = context.getElementInfo(el);
      // console.log('values:  ', { x, y, height, width, isVisible })
      const div = document.createElement("div");
      div.style.top = `${((y * ratio) / context.height) * 100
        }%`;
      div.style.left = `${((x * ratio) / context.width) * 100
        }%`;
      div.style.width = `${((width * ratio) / context.width) * 100
        }%`;
      div.style.height = `${((height * ratio) / context.height) *
        100
        }%`;
      
      context.htmlXmlMap.set(div, el);
      if (isVisible) {
        overlayElements.push({
          div,
          x,
          y,
          x2: x + width,
          height,
          width,
          y2: y + height,
        });
      }

    });
    context.sortRectanglesByArea(overlayElements);
    context.xmlOverlay.innerHTML = '';
    overlayElements.forEach((obj) => {
      context.xmlOverlay.appendChild(obj.div);
    });
    context.xmlOverlay.style.height = `${context.height}px`;
    context.xmlOverlay.style.width = `${context.width}px`;

    // context.xmlOverlay.addEventListener("click", (e) => {
    //   const xmlElement = context.htmlXmlMap.get(e.target);
    //   console.log("div element", e.target);
    //   console.log("app element", xmlElement);
    // });
  }
}
