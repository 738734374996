<template>
  <div>
    <div class="step-execution-element"
         v-if="element.type !== 'group' && current_step && current_step._id === element._id">
      <step-run-loader/>
    </div>
    <div class="step-execution-element testcase-loader" v-else-if="element.type === 'group' && current_step._id">
      <step-run-loader v-if="isLoaderShow"/>
    </div>
  </div>
</template>
<script>
import StepRunLoader from './StepRunLoader.vue';

export default {
  components: {StepRunLoader},
  props: {
    element: {
      type: Object,
      default: {},
    },
    current_step: {
      type: Object,
      default: {},
    },
  },
  methods: {},
  computed: {
    isLoaderShow() {
      var steps = [];
      // Check children of group is existed
      var childrenTree = (st) => {
        var i = 0;
        while (i < st.length) {
          if (st[i].type === 'testStep') {
            steps.push(st[i]);
          }
          if (st[i].children && st[i].children.length > 0) {
            childrenTree(st[i].children);
          }
          i++;
        }
      };
      if (this.element && this.element.children && this.element.children.length) {
        childrenTree(this.element.children);
      }

      return steps.find((c) => c._id === this.current_step._id);
    },
  },
};
</script>