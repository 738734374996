<template>
  <div>You don't have permission to view this page</div>
</template>
<script>
export default {
  name: "PermissionPage",
  data() {
    return {};
  },
  methods: {},
};
</script>