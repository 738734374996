<template>
    <div id="editor" :class="[readonly ? 'readonly' : '', invalidCodeBlock ? 'error-code' : '']"
      style="width: 100%; height: 100%"></div>
  </template>
  
  <script>
  import loader from "@monaco-editor/loader";
  const acorn = require("acorn");
  
  export default {
    name: "Editor",
    props: {
      value: { type: String },
      readonly: { type: Boolean, default: false },
    },
    data: () => ({
      code: '',
      invalidCodeBlock: false,
    }),
    created() {
      if (this.value) {
        this.code = this.value
      }
    },
    watch: {
      code(val) {
        this.isCodeValid(val)
      }
    },
    async mounted() {
      const that = this;
  
      loader.init().then((monaco) => {
        const editorOptions = {
          value: that.code,
          theme: '',
          language: "javascript",
          minimap: { enabled: false },
        };
  
        const stateStore = {
          editorFocused: false,
        }
  
        const editor = monaco.editor.create(document.getElementById("editor"), editorOptions);
  
        function debounceCodeData() {
          let timeout = null;
  
          if (timeout) {
            clearTimeout(timeout);
          }
  
          timeout = setTimeout(() => {
            that.code = editor.getValue();
          }, 300);
        }
  
  
        editor.getContainerDomNode().addEventListener('paste', (e) => {
          debounceCodeData()
        }, true)
  
        editor.onKeyUp(e => {
          debounceCodeData();
        })
  
        // editor.onDidDispose((e) => {
        //   console.log("📫 📫 📫 📫 📫 📫 📫 📫 ON DID DISPOSE", e, '\n');
        // })
  
        editor.onDidFocusEditorText((e) => {
          stateStore.editorFocused = true;
          debounceCodeData();
        })
  
        editor.onDidBlurEditorText((e) => {
          stateStore.editorFocused = false;
          debounceCodeData();
        })
  
  
        editor.onDidType((e) => {
          debounceCodeData();
        })
  
      });
  
    },
  
    methods: {
      isCodeValid(jsCode) {
        this.$emit('code', jsCode)
      }
    },
  };
  
  </script>
  
  <style lang="scss" scoped>
  .readonly {
    position: relative;
  
    &::before {
      content: "";
      user-select: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      // height: 100%;
      bottom: 6px;
      z-index: 99999999;
      background: rgba(0, 0, 0, 0.33);
    }
  }
  
  .error-code {
    position: relative;
  
    &::before {
      content: '';
      position: absolute;
      border: 2px solid red;
      position: absolute;
      top: -2px;
      left: -3px;
      width: 100%;
      bottom: 4px;
    }
  }
  </style>
  