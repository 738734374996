/* eslint-disable no-unused-vars */
import TestServiceRequest from '../utils/request/test_request';
import seleniumRequest from '../utils/request/selenium_request';
import axios from 'axios';

// START TEST_CASE SECTION

export const insertNewTestCase = async data => {
    return await TestServiceRequest.post(`/test-cases/save`, data)
        .then(res => {
            return res;
        })
        .catch(error => {
            console.log('TestCaseService-newcase--Error', error);
        });
};

export const fetchTestCaseList = async (projectId, duration, bodyData) => {
    return await TestServiceRequest.post(`/test-cases/list/${projectId}/${duration}`, bodyData)
        .then(res => {
            return res;
        })
        .catch(error => {
            return error;
        });
};

export const fetchCurrentTestCase = async testCaseId => {
    return await TestServiceRequest.get(`/test-cases/by/${testCaseId}`);
    // .then(res => {
    //     return res;
    // })
    // .catch(error => {
    //     console.log('TestCaseService-getTestCaseList-Error', error);
    // });
};

// export const insertTestCaseGroup = () => {
//   console.log('new-case-group-create-data', data);
//   return TestServiceRequest.post(`/test-cases/save`, data).then(res => {
//     console.log('newcase-save-response', res);
//     return res;
//   }).catch(error => {
//     console.log('TestCaseService-newcase--Error', error);
//   })
// }

export const insertCloneTestCase = async data => {
    return await TestServiceRequest.post(`test-cases/clone`, data)
        .then(res => {
            return res;
        })
        .catch(error => {
            return error;
        });
};

export const updateTestcase = async data => {
    return await TestServiceRequest.put(`/test-cases/update`, data)
        .then(res => {
            return res;
        })
        .catch(error => {
            console.log('update-testcase-error', error);
        });
};

export const updateTestcaseOnMouseMove = async data => {
    return await TestServiceRequest.put(`/test-cases/update/by/index`, data)
        .then(res => {
            return res;
        })
        .catch(error => {
            console.log('update-onMouseMove-testcase-error', error);
        });
};

export const deleteTestcases = async reqDataArray => {
    return await TestServiceRequest.delete(`/test-cases/delete`, {data: reqDataArray})
        .then(res => {
            return res;
        })
        .catch(error => {
            console.log('delete-case-Error', error);
        });
};
// Map TestRail Test case
export const mapTestRailTestCase = async (testCaseId, projectId, data) => {
    return await TestServiceRequest.post(`/test-cases/testrails/${testCaseId}/${projectId}`, data);
};

export const fetchTestrunResult = async testCaseId => {
    return await TestServiceRequest.get(`/test-cases/test-run-result/by/${testCaseId}`).then(res => {
        return res;
    })
        .catch(error => {
            return error;
        });
};

export const fetchCaseResultsFromRuns = async testCaseId => {
    return await TestServiceRequest.get(`/test-cases/case-results-from-runs/${testCaseId}`).then(res => {
        return res;
    })
        .catch(error => {
            return error;
        });
};
// END TEST_CASE SECTION

// START TEST_STEPS SECTION
export const insertTestStep = async data => {
    return await TestServiceRequest.post(`/test-steps/save`, data)
        .then(res => {
            return res;
        })
        .catch(error => {
            return error;
        });
};

export const checkExistingGroupName = async data => {
    return await TestServiceRequest.post(`/test-steps/groups/duplicate`, data)
        .then(res => {
            return [res, null];
        })
        .catch(error => {
            console.log('error', error);
            return [null, error];
        });
};

export const insertTestStepOnEachMove = async data => {
    return await TestServiceRequest.put(`/test-steps/update/by/index`, data)
        .then(res => {
            return res;
        })
        .catch(error => {
            console.log('TestCaseService-stepsMoveUpdate-Error', error);
        });
};

export const fetchTestStepList = async (testCaseId) => {
    return await TestServiceRequest.get(`/test_steps/list`)
        .then(res => {
            return res;
        })
        .catch(error => {
            console.log('TestCaseService-getTestCaseList-Error', error);
        });
};

export const deleteTestSteps = async reqDataObj => {
    return await TestServiceRequest.delete(`/test-steps/delete`, {data: reqDataObj})
};

export const insertShareableGroup = async data => {
    return await TestServiceRequest.post(`/test-steps/shareable/save`, {test_steps_shareable: data})
        .then(res => {
            return res;
        })
        .catch(error => {
            console.log('TestCaseService-shareableGroup-Error', error);
        });
};

export const fetchShareableGroup = async projectId => {
    return await TestServiceRequest.get(`test-steps/shareable/list/by/` + projectId)
        .then(res => {
            return res;
        })
        .catch(error => {
            console.log('TestCaseService-fetchShareable-group-Error', error);
        });
};

// END TEST_STEPS SECTION

export const updateTestStep = async (stepId, testStep) => {
  console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> ', stepId, testStep);
    return await TestServiceRequest.put(`/test-steps/update/by/test-step/${stepId}`, testStep)
        .then(res => {
            return res;
        })
        .catch(error => {
            console.log('TestCaseService-properties-save-Error', error);
        });
};

export const insertPropertieChanges = async newPropertiesObj => {
    return await TestServiceRequest.put(`/test-steps/update/by/test-step/${newPropertiesObj.id}`, newPropertiesObj.propertiesObj)
        .then(res => {
            return res;
        })
        .catch(error => {
            console.log('TestCaseService-properties-save-Error', error);
        });
};

// START TEST STEPS SHAREABLE

export const insertTestStepShareable = async data => {
    return await TestServiceRequest.post(`/test-steps/shareable/assign`, data)
        .then(res => {
            return res;
        })
        .catch(error => {
            console.log('TestCaseService-saveTestCases-Error', error);
        });
};

// END TEST STEPS SHAREABLE

export const fetchBrowserWiseData = async (automatedTestrunId) => {
    return await seleniumRequest.get(`/browser-wise-data/${automatedTestrunId}`)
        .then(res => {
            return res;
        })
        .catch(error => {
            return error;
        });
};

export const serverInfo = async () => {
    return await axios.get('http://localhost:5000/test-editor/server-info')
        .then(res => {
            return res;
        })
        .catch(error => {
            return error;
        })
}
export const insertNewGeneratedEmail = async (data) => {
    return await TestServiceRequest.post(`/v2/emails`, data)
        .then(res => {
            return res;
        })
        .catch(error => {
            console.log('TestCaseService--insertNewGeneratedEmail--Error', error);
        });
};
export const getGeneratedEmails = async (projectId) => {
    return await TestServiceRequest.get(`/v2/emails/${projectId}/project`)
        .then(res => {
            return res;
        })
        .catch(error => {
            console.log('TestCaseService--getGeneratedEmails--Error', error);
        });
};
export const deleteGeneratedEmailById = async (id) => {
    return await TestServiceRequest.delete(`/v2/emails/${id}`);
};
