<template>
  <div class="p-grid p-fluid">
    <div class="p-col-12 m-0 p-0">
      <div class="properties-input-wrapper">
        <div class="p-field input-description">
          <label>Test Description</label>
          <InputText v-model="testDescription" type="text" placeholder="Enter Test Description"/>
        </div>
      </div>
    </div>

    <div class="p-col-12 m-0 p-0">
      <div class="properties-input-wrapper">
        <div class="p-field input-description">
          <label>When This Step Fails</label>
          <Dropdown :options="testStepsFailOptions" optionLabel="name" v-model="testStepsFailReason"
                    placeholder="Choose When Steps Fail"/>
          <!-- <InputText v-model="testDescription" type="text" placeholder="Enter Test Description" /> -->
        </div>
      </div>
    </div>

    <div class="p-col-12 m-0 p-0">
      <div class="properties-input-wrapper">
        <div class="p-field input-description">
          <label>Change Resolution</label>
          <Dropdown :options="resolutionOptions" optionLabel="name" v-model="testCaseResolution"
                    placeholder="Choose Resolution"/>
        </div>

        <label class="custom-lable">Add Custom Resolution (px)</label>
        <div class="custom-resolution-section">
          <div class="input-field">
            <InputText v-model="resolutionWidth" type="text" placeholder="Width"/>
          </div>
          <div class="multiplication-icon">X</div>
          <div class="input-field">
            <InputText v-model="resolutionWidth" type="text" placeholder="Height"/>
          </div>
          <Button icon="fas fa-save" class="p-button-primary"/>
        </div>
      </div>
    </div>

    <div class="p-col-12 m-0 p-0">
      <div class="properties-input-wrapper">
        <label class="custom-lable">Scroll Position</label>
        <div class="custom-scroll-position">
          <div class="custom-scroll-left-info">
            <Checkbox v-model="checkedXaxis" :binary="true"/>
            <div class="input-field">
              <InputText v-model="resolutionWidth" type="text" placeholder="X-axis"/>
            </div>
          </div>

          <div class="custom-scroll-right-info">
            <Checkbox v-model="checkedYaxis" :binary="true"/>
            <div class="input-field">
              <InputText v-model="resolutionWidth" type="text" placeholder="Y-axis"/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="p-col-12 m-0 p-0">
      <div class="properties-input-wrapper">
        <div class="p-field input-description">
          <label>Test in TestRail</label>
          <Dropdown :options="teststepsOptions" optionLabel="name" v-model="selectedTeststeps"
                    placeholder="Select Test Case"/>
        </div>
      </div>
    </div>

    <div class="p-col-12 m-0 p-0">
      <div class="properties-input-wrapper">
        <div class="properties-override-timeout">
          <Checkbox v-model="override" :binary="true"/>
          <label>Override Timeout</label>
        </div>

        <div class="properties-sharesteps">
          <Checkbox v-model="sharesteps" :binary="true"/>
          <label>Shared Steps</label>
        </div>
      </div>
    </div>

    <div class="p-col-12 m-0 p-0">
      <div class="properties-input-wrapper border-none">
        <label class="custom-lable">Target Element</label>

        <div class="target-element-screen">
          <img alt="Logo" src="/assets/layout/images/screenShort.png"/>

          <span class="view-locator-button" @click="ViewLocatorDisplay = true"> View locator </span>
        </div>

        <Button label="Save Changes" class="p-button-primary save-changes-button"/>

        <!-- <div class="view-locator-button">
            <Button label="View Locator" />
        </div> -->
      </div>
    </div>

    <Dialog :draggable="false" header="View Locator" class="custom-properties-dialog" v-model:visible="ViewLocatorDisplay"
            :style="{ width: '20.9vw', height: '720px' }" :modal="true" :position="right">
      <Accordion :multiple="true" :activeIndex="[0]" class="custom-properties-accordian">
        <AccordionTab header="Header I">

          <div class="element">
            <label>Id</label>
            <p>Id Information</p>
          </div>

          <div class="element">
            <label>Class</label>
            <p>Class Information</p>
          </div>

        </AccordionTab>
        <AccordionTab header="Header II">

          <div class="element">
            <label>Id</label>
            <p>Id Information</p>
          </div>

          <div class="element">
            <label>Class</label>
            <p>Class Information</p>
          </div>

        </AccordionTab>
        <AccordionTab header="Header III">
          <div class="element">
            <label>Id</label>
            <p>Id Information</p>
          </div>

          <div class="element">
            <label>Class</label>
            <p>Class Information</p>
          </div>
        </AccordionTab>

      </Accordion>

    </Dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ViewLocatorDisplay: false,
      testDescription: '',
      resolutionOptions: [
        {name: 'FHD 1920X1080', value: '1920x1080'},
        {name: 'Laptop 1366X768', value: '1366x768'},
        {name: 'Tablet', value: '768x435'},
      ],
      testCaseResolution: '',

      testStepsFailOptions: [
        {name: 'Mark Error and Stops', value: '0'},
        {name: 'A', value: 'A'},
        {name: 'B', value: 'B'},
        {name: 'C', value: 'C'},
      ],

      testStepsFailReason: '0',

      checkedXaxis: false,
      checkedYaxis: false,

      teststepsOptions: [
        {name: 'A', value: 'A'},
        {name: 'B', value: 'B'},
        {name: 'C', value: 'C'},
      ],
      selectedTeststeps: '',

      override: false,
      sharesteps: false,
    };
  },
};
</script>

<style>
</style>