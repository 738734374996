<template>
  <!-- <div class="validationPanel">
      <div class="p-xl-3"> -->
  <div class="card">
    <h5>Add Step</h5>
    <div class="search-box p-fluid">
            <span class="p-input-icon-right">
                <i class="pi pi-search"/>
                <InputText type="text" v-model="search" placeholder="Search" @input="$emit('onSearchChange', search)"/>
            </span>
    </div>

    <div class="validation-nav">
      <div class="all" :class="activeTabIndex == 0 ? 'active-color' : ''" @click="changeTabIndex(0)"><b>All</b></div>
      <!-- <p>All</p> -->
      <!-- <i class="fas fa-hand-pointer" @click="changeTabIndex(1)" :class="activeTabIndex == 1 ? 'active-color' : ''"></i>
      <i class="fas fa-check-circle" @click="changeTabIndex(2)" :class="activeTabIndex == 2 ? 'active-color' : ''"></i>
      <i class="fas fa-clock" @click="changeTabIndex(3)" :class="activeTabIndex == 3 ? 'active-color' : ''"></i> -->
      <!-- <i class="fas fa-folder" title="Shared Group" @click="changeTabIndex(4)"
         :class="activeTabIndex == 4 ? 'active-color' : ''"></i> -->
    </div>
    <!-- <div v-if="testEditoServerInfo">Server is running!</div> -->
    <div v-if="activeTabIndex == 0" class="all-tab-content">
      <validations-list :customValidationList="customValidationList"
                        @onListClick="(validationItem) => $emit('onListClick', validationItem)"/>
      <div class="save-btn p-fluid">
        <Button label="SAVE TEST" :disabled="disableSaveBtn" class="p-button-secondary" :style="{ height: '40px' }" @click="$emit('clickSave')"/>
      </div>
      <!-- <div class="save-btn p-fluid">
          <Button label="Send Data" class="p-button-secondary" :style="{ height: '40px' }" @click="$emit('clickSendStepsToSelenuim')" />
      </div> -->
    </div>
    <div v-if="activeTabIndex == 1" class="all-tab-content">
      <validations-list :customValidationList="customValidationList"
                        @onListClick="(validationItem) => $emit('onListClick', validationItem)"
                        :filteredList="['hover', 'refresh']"/>
    </div>
    <div v-if="activeTabIndex == 2" class="all-tab-content">
      <validations-list
          :customValidationList="customValidationList"
          @onListClick="(validationItem) => $emit('onListClick', validationItem)"
          :filteredList="[
                    'text_to_assign',
                    'validate_css_property',
                    'validate_html_attribute',
                    'validate_element_visible',
                    'validate_element_not_visible',
                    'validate_checkbox',
                    'validate_radio_button',
                    'check_broken_link',
                    'navigate',
                    'url_validation',
                    'sms_validation',
                    'email_validation',
                    'ValidateEmail',
                    'ValidateSMS',
                ]"
      />
    </div>
    <div v-if="activeTabIndex == 3" class="all-tab-content">
      <validations-list :customValidationList="customValidationList"
                        @onListClick="(validationItem) => $emit('onListClick', validationItem)"
                        :filteredList="['sleep']"/>
    </div>
    <div v-if="activeTabIndex == 4" class="all-tab-content">
      <div class="validations">
        <ul>
          <li v-for="shareGroup in shareableGroupList" :key="shareGroup.item._id">
            <div v-if="shareGroup.test_step_ids.length > 0" class="list-container">
              <Checkbox :id="shareGroup.item._id" name="shareGroup" :value="shareGroup" v-model="checkedShareGroup"/>
              <!-- <label>{{shareGroup.test_step_ids.length}}</label> -->
              <label :for="shareGroup.item._id"> {{
                  shareGroup && shareGroup.test_step_ids && shareGroup.test_step_ids.length > 0 ? shareGroup.test_step_ids[0].description : ''
                }}</label>
            </div>
          </li>
          <!--  -->
        </ul>
      </div>
      <div class="save-btn p-fluid">
        <Button label="ADD GROUP" class="p-button-secondary" :style="{ height: '40px' }"
                @click="$emit('clickAddSharedGroup', checkedShareGroup)"/>
      </div>
    </div>
    <!-- <pre>shareableGroupList===>{{shareableGroupList}}</pre> -->
  </div>
</template>

<script>
// import customValidationList from '../utils/custom_validation_list';
import ValidationsList from './ValidationsList.vue';

export default {
  name: 'ValidationPanel',
  components: {
    ValidationsList,
  },
  props: {
    disableSaveBtn: {
        type: Boolean,
        default: false,
    },
    customValidationList: {
      type: Array,
      default: [],
    },
    shareableGroupList: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      search: '',

      activeTabIndex: 0,
      checkedShareGroup: null,
    };
  },

  methods: {
    changeTabIndex(activeIndex) {
      this.activeTabIndex = activeIndex;
    },
  },

  watch: {
    testEditoServerInfo(value, oldValue) {
      console.log("value==>", value + "< >" + oldValue)
    }
  }
};
</script>

<style>
.active-color {
  color: #00a0ee;
}
</style>
