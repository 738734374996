<template>
  <div class="not-found-wrapper">
    <div class="card">
      <div class="logo">
        <img src="/assets/layout/images/TestJET-Logo-Final-3.png" alt=""/>
      </div>
      <p class="label"><b>Internet Connection Failed</b></p>
      <div class="not-found-image">
        <img src="/assets/layout/images/Page-Not-Found.png" alt=""/>
      </div>
      <div class="details">
        <div>The page you're looking was removed, moved or</div>
        <div>never existed</div>
      </div>
      <Button label="BACK TO PREVIOUS PAGE" icon="fas fa-chevron-left" iconPos="left" @click="goBack"/>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    goBack() {
      window.history.back();
    },
  },
};
</script>