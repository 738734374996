export default {
    START_RECORD: 'start_record',
    WINDOW_CREATED: "window_created",
    STOP_RECORD: 'stop_record',
    START_AUTOMATION: 'start_automation',
    PAUSE_AUTOMATION: "pause_automation",
    RESTART_AUTOMATION: "restart_automation",
    STOP_AUTOMATION: "stop_automation",
    EVENT: 'event',
    TABID: 'tabId',
    WINDOWCLOSED: 'windowclosed',
    PLAY_AUTOMATION: 'playautomation',
    RECEIVED_AUTOMATION_CONFIRMATION: 'received_automation_confirmation',
    RECEIVED_TEST_RESULT: 'received_test_result',
    CUSTOM_VALIDATION_START: 'custom_validation_start',
    CURRENT_AUTOMATION_STEP: 'current_automation_step',
    BEFORE_NAVIGATION: 'before_navigation',
    ON_COMPLETE: 'on_complete',
    COMPLETED_STEP: 'completed_step',
    PAUSE: 'pause',
    PlAY_AGAIN: 'play_again',
    Jira: 'jira',
    JiraError: 'jiraerror',
    HARD: 'hard',
    NORMAL: 'normal',
    SET_PHONE_NUMBER: 'set_phonenumber',
    ADD_PREDEFINED_STEP : 'add_predefined_step'
};