<template>
    <div class="assertion-code">
        <div class="head">
            <h6>SMS Body</h6>
        </div>
        <div class="body">
            <Textarea v-model="query" rows="5" cols="30" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'sms-body',
    props: {
        smsBody: {
            type: String,
        },
    },
    data: () => ({
        checked: false,
        query: '',
    }),
    created() {
        if (this.smsBody) {
            this.query= this.smsBody;
        }
    },
    watch: {
        smsBody(value) {
            this.query = value;
        },
        // query(value) {
        //     this.$emit('smsBodyUpdated', this.query);
        // },
    },
};
</script>
