export default {
    CLICK: 'click',
    CHANGE: 'change',
    SELECT: 'select',
    SUBMIT: 'submit',
    LOAD: 'load',
    UNLOAD: 'unload',
    INPUT: 'input',
    SCROLL: 'scroll',
    WHEEL: 'wheel',
    RESIZE: 'resize',
    PROGRESS: 'progress',
    DRAGOVER: 'dragover',
    DROP: 'drop',
    DBLCLICK: 'dblclick',
    CONTEXTMENU: "contextmenu"
}
