<template>
  <div class="card email_validation-container">
    <div class="url_validation-wrapper" v-if="dataLoaded">
      <section class="top">
        <div class="input_field">
          <div class="p-inputgroup flex-1">
              <span class="p-inputgroup-addon">Receiver</span>
              <Dropdown v-model="validationInfo.email" :options="generatedEmails" optionValue="email" optionLabel="email" placeholder="Select email" />
          </div>
          <Button class="p-ml-3" label="EXECUTE" :disabled="!validationInfo.email" @click="sendMockTest('execute_email')"/>
        </div>
        <Button class="saveDraft" label="Save as draft" @click="SAVE('draft')" />
        <Button v-if="componentMode === 'create' && !existingData?.is_drafted" label="ADD TO STEP"
          @click="SAVE('addToStep')" />
        <Button v-else-if="componentMode === 'update' && !existingData?.is_drafted" label="UPDATE"
          @click="SAVE('updateStep')" />
        <Button v-else label="ADD TO STEP" @click="SAVE('addToStepFromDraft')" />
      </section>

      <section class="section2">
        <div class="timeout_info" style="margin-left: 0;">
          <i class="fas fa-clock"></i>
          <p>Override Timeout : </p>
          <InputNumber v-model="validationInfo.timeout" inputId="milliseconds" suffix=" ms" :min="1000" :max="10000" placeholder="Step Timeout (milliseconds)" />
        </div>
        <div class="varaible_info">
          <h6>Available Variables: </h6>
          <div class="variables" v-if="variables && variables.length">
            <span v-for="(variable, i) in variables" :key="i"
              :style="{ display: i < variableCount - 1 ? 'inherit' : 'none' }">
              <p class="var variable" v-tooltip.bottom="Object.values(variable)[1]">{{ Object.keys(variable)[1] }}{{ i <
                variables.length - 1 ? ',' : '' }}</p>
            </span>
            <p class="var more_variable" v-if="hiddenVariableCount" v-tooltip.bottom="hiddenVariableString">{{ `more
              +${hiddenVariableCount}` }}</p>
          </div>
          <p v-else class="var no_variables">No variables</p>
        </div>
      </section>

      <section class="section3">
          <span>
              <h6><strong>Note:</strong> Assertion will run before additional code when both are there.</h6>
              <i class="fas fa-info-circle"></i>
          </span>          
          <span class="second">
              <h4>Assertion</h4>
          </span>
          <EmailAssertion v-if="!loadingAssertionStatus" @assertionUpdated="assertionUpdated" :allAssertions="validationInfo.assertions" :response="assertionResultResponse" />
          <section>
              <div v-if="!loadingAssertionStatus" class="assertion-code">
                <div class="head">
                    <h6>Email Body</h6>
                </div>
                <div class="resBody">
                  <div v-html="decodeHtml"></div>
                    <!-- <Textarea :value="responseBody?.email ? responseBody?.email :'' " rows="5" cols="30" /> -->
                </div>
              </div>
              <div v-if="!loadingAssertionStatus" class="assertion-code-header">
                  <InputSwitch v-model="runCallbackScript" />
                  <h6>Run additional code on request results</h6>
              </div>
              <AssertionCode v-if="!loadingAssertionStatus && callback_script" @updateAssertionCode="setCallBackScript" :assertionCode="callback_script" :response="scriptResultResponse"/>
          </section>

          <span v-if="loadingAssertionStatus">Loading...</span>
        </section>
      <Logs v-if="scriptResultResponse && !loadingAssertionStatus" :response="scriptResultResponse" />
    </div>
    <div v-else>Loading...</div>
  </div>
</template>

<script>
import EmailAssertion from './EmailAssertion.vue';
import Logs from './Logs.vue';
import AssertionCode from '../../components/email-validation/AssertionCode.vue';
import { getSelectedCurrentProjectId } from '../../utils/localstorage';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'EmailValidation',
  components: {
    EmailAssertion,
    AssertionCode,
    Logs
  },
  data: () => ({
    dataLoaded: false,
    generatedEmails: null,
    validationInfo: {
      email : null,
      assertions: [],
      timeout: 1000
    },
    callback_script: null,
    runCallbackScript : false,
    variables: [],
    assertionResultResponse: null,
    scriptResultResponse: null,
    responseBody: null,
    variableCount: 4,
    errors: {},
    componentMode: 'create', //* create / update
    sending: false,
    loadingAssertionStatus: false,
  }),
  props: {
    existingData: {
      type: Object,
    },
    sendData: {
      type: Object || null
    }
  },
  mounted() {
    this.setComponentVars();
    this.fetchGeneratedEmails();

    if (this.existingData) {
      this.componentMode = 'update';
      const { validationInfo, error_obj } = this.existingData;
      this.validationInfo.email = validationInfo.email;
      this.validationInfo.timeout = validationInfo.timeout;
      this.callback_script = validationInfo.callback_script;
      this.runCallbackScript = validationInfo.runCallbackScript;
      this.validationInfo.assertions = validationInfo.assertions;

      this.assertionResultResponse = error_obj?.validationResult?.assertionResult;
      this.responseBody = error_obj?.validationResult;
      if (error_obj?.validationResult?.scriptResult) {
            this.scriptResultResponse = error_obj.validationResult.scriptResult;
      }

      if (error_obj?.validationResult) {
        this.response = error_obj.validationResult.assertionResult;
      }
      this.dataLoaded = true;
    }
    else {
      this.componentMode = 'create';
      this.sqlQuery = null;
      this.config = {};
      this.ms = 1000;
      this.callback_script = 'console.log(JSON.stringify(email, null, 3));\n';
      this.runCallbackScript = false;
      this.dataLoaded = true;
      }
  },
  computed: {
    ...mapGetters({
      caseVariables: 'variable/caseVariables',
    }),
    hiddenVariableCount() {
      if (this.variables.length > this.variableCount) {
        return this.variables.length - this.variableCount;
      } else {
        return null;
      }
    },
    hiddenVariableString() {
      if (this.variables.length > this.variableCount) {
        let varsString = '';
        const vars = this.variables.slice(this.variableCount - 1, this.variables.length - 1);
        for (let i = 0; i < vars.length; i++) {
          const element = vars[i];
          varsString += `${Object.keys(element)[0]}: ${Object.values(element)[0]} \n`;
        }
        return varsString
      } else {
        return null;
      }
    },
    decodeHtml() {
      if (this.responseBody?.email?.html_data){
        return decodeURIComponent(this.responseBody.email.html_data)
      }
      return '<p></p>'
    }
  },

  watch: {
    sendData: {
      deep: true,
      handler(value) {
        console.log("🥛 🥛 🥛 🥛 🥛 🥛 🥛 🥛 🥛 🥛 🥛 🥛 🥛 ", value);
        const { data, componentName } = value;
        if (componentName === 'emailValidation') {
          const { validationResult, execution_time, type } = data;

          this.loadingAssertionStatus = false;
          this.apiValidationActiveMode = 'response';

          this.responseBody = validationResult;
          this.assertionResultResponse = validationResult.assertionResult;
          if (validationResult?.scriptResult) {
            this.scriptResultResponse = validationResult.scriptResult;
          }
          // this.callback_script = validationInfo?.callback_script?.length ? validationInfo.callback_script : '';
        }
      }
    },
    caseVariables() {
      this.setComponentVars();
    }
  },
  methods: {
    ...mapActions({
            getGeneratedEmails: 'testCase/actionGetGeneratedEmails'
        }),
    async fetchGeneratedEmails() {
            if (getSelectedCurrentProjectId()) {
                await this.getGeneratedEmails(getSelectedCurrentProjectId()).then((resBody) => {
                    console.log(resBody);
                    this.generatedEmails = resBody?.data;
                });
            } else {
                console.log('Please create or select project first.');
                // this.showWarningMsg('Please select project first.');
            }
        },

    sendMockTest(actionType){
      const data = {
        "email": this.validationInfo.email,
        "timeout": this.validationInfo.timeout,
        "assertions": this.validationInfo.assertions,
        "callback_script": this.callback_script,
        "runCallbackScript":this.runCallbackScript,
        "result": null,
        "actionType": actionType,
      }

      if (actionType == 'execute_email') {
        this.sending = true;
        this.loadingAssertionStatus = true;
      }

      this.$emit('onSendMockEmailTest', data);
    },

    SAVE(actionType) {
      const obj = {
        ...(this.existingData ? { "existingStepId": this.existingData._id } : {}),
        "email": this.validationInfo.email,
        "timeout": this.validationInfo.timeout,
        "assertions": this.validationInfo.assertions,
        "callback_script": this.callback_script,
        "runCallbackScript": this.runCallbackScript,
        "actionType": actionType,
        "response": null,
      }

      if(Object.keys(this.errors).length > 0) {
        for (let i = 0; i < Object.values(this.errors).length; i++) {
          const er = Object.values(this.errors)[i];
          this.$toast.add({ severity: 'error', summary: 'ERROR', detail: er, life: 3000 });
        }
        return
      }

      if (actionType === 'addToStep') {
        this.sending = true;
        this.loadingAssertionStatus = true;
      }

      console.log(obj);
      this.$emit('save', obj);
    },

    setComponentVars() {
      this.variables = null;

      this.variables = this.caseVariables.map(varr => {

        return {
          _id: varr._id,
          [varr.name]: varr.value
        }
      });
    },

    setCallBackScript(value) {
      this.callback_script = value;
    },

    assertionUpdated(assertions) {
      this.validationInfo.assertions = [...assertions];
    },

    async updatedCode(val) {
      try {
        const response = await JSON.parse(val)
        if ('jsonError' in this.errors) {
          delete this.errors["jsonError"]
        }
      } catch (err) {
        const msg = err.message;
        console.log(msg);
        if (msg?.toLowerCase()?.includes('unterminated string in json at position')) {
          this.errors = {...this.errors, jsonError: `Unterminated string found in json, please rechheck the request body`};
        } else {
          this.errors = {...this.errors, jsonError: msg};
        }
      }
    }
  },

}
</script>

<style lang="scss" src="@/styles/emailValidation.scss" />
