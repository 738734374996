import TestServiceRequest from '../utils/request/test_request';
import MobileServiceRequest from '../utils/request/mobile_request';

export const  fetchVariablesByProjectId = async (projectId, type) => {
  if (type === 'mobile') {
    return await MobileServiceRequest.get(`/projects/${projectId}/variables`);
  } else {
    return await TestServiceRequest.get(`/v2/projects/${projectId}/variables`);
  }
}

/**
 * Creates a variable.
 *
 * @param {Object} data - An object containing the value and scope of the variable.
 * @param {string} data.value - The value of the variable.
 * @param {string} data.scope - The scope of the variable (e.g., "project").
 * @return {Promise} A promise that resolves to the response from the API request.
 */
export const  createVariable = async (data, type) => {
  if (type === 'mobile') {
    return await MobileServiceRequest.post(`/variables`, data);
  } else {
    return await TestServiceRequest.post(`/api/variables`, data);
  }
}

/**
 * Update multiple variables at the same time.
 *
 * @param {object} data - An object containing the data for creating the variable. It has the following properties:
 *   - projectId (string): The ID of the project.
 *   - variables (array): An array of objects representing the variables.
 * @return {Promise} A promise that resolves with the response from the server.
 */
export const  updateVariables = async (data, type) => {
  if (type === 'mobile') {
    return await MobileServiceRequest.patch(`/variables`, data);
  } else {
    return await TestServiceRequest.patch(`/api/variables`, data);
  }
}

/**
 * Get the test case variables for a given case ID.
 *
 * @param {Object} data - The data object containing the some data.
 * @param {string} data.caseId - The ID of the test case.
 * @return {Promise} A promise that resolves with the test case variables.
 */
export const getTestCaseVariables = async (data) => {
  return await TestServiceRequest.get(`/v2/test-cases/${data.caseId}/variables`)
};

export const getTestCaseVariablesMobile = async (data) => {
  return await MobileServiceRequest.get(`/test-cases/${data.caseId}/variables`)
};

/**
 * Updates the test case variables.
 *
 * @param {Object} data - The data containing some data and the variables to be updated.
 * @param {string} data.caseId - The ID of the test case.
 * @param {string} data.id - The ID of the variable.
 * @param {string} data.value - The value to update the variable with.
 * @param {string} data.scope - The scope of the variable.
 * @return {Promise} A promise that resolves with the updated test case variables.
 */
export const updateTestCaseVariables = async (data, type) => {
  if (type === 'mobile') {
    return await MobileServiceRequest.patch(`/variables/${data.id}`, {"value": data.value, "scope": data.scope})
  } else {
    return await TestServiceRequest.patch(`/api/variables/${data.id}`, {"value": data.value, "scope": data.scope})
  }
};

/**
 * Check variable name uniqueness
 *
 * @param {Object} data - The data containing variable name and project id.
 */
export const uniqueVariableNameCheck = async (data) => {
  return await TestServiceRequest.post('/api/variables/check-uniqueness', data);
}

export const deleteVariable = async (data, type) => {
  if (type === 'mobile') {
    return await MobileServiceRequest.delete(`/variables/${data.id}`);
  } else {
    return await TestServiceRequest.delete(`/api/variables/${data.id}`)
  }
};
