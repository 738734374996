import SeleniumRequest from '../utils/request/selenium_request';


// START Selenium Request
export const sendTestStepToSelenium = async data => {
    console.log("sendTestStepToSelenium server:", data);
    return await SeleniumRequest.post(`/test`, data)
        .then(res => {
            return res;
        })
        .catch(error => {
            console.log('error', error);
            return error;
        });
};

