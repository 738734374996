export default {
    Play_Scenario: {
        name: "Play Scenario",
        icon: "fa-folder-plus"
    },
    Edit_Labels_for_the_Test: {
        name: "Edit Labels for the Test",
        icon: "fa-clipboard"
    },
    Delete_Step: {
        name: "Delete Step",
        icon: "fa-hand-pointer"
    },
    Copy_Step: {
        name: "Copy Step",
        icon: "fa-location-arrow"
    },
    Paste: {
        name: "Paste",
        icon: "fa-location-arrow"
    },
    Add_Group: {
        name: "Add Group",
        icon: "fa-location-arrow"
    },
    Export_to_Code: {
        name: "Export to Code",
        icon: "fa-code"
    },
}