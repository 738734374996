// Localhost
// export const TestServiceBaseUrl = 'http://localhost:4001/';
// export const AuthServiceBaseUrl = 'http://localhost:4000/';
// export const SeleniumServiceBaseUrl = 'http://localhost:4002/';

/*live server*/
// export const AuthServiceBaseUrl = 'http://103.111.166.49:9000/';
// export const TestServiceBaseUrl = 'http://103.111.166.49:9001/';

/*local server*/
// export const AuthServiceBaseUrl = 'http://192.168.88.233:9000/';
// export const TestServiceBaseUrl = 'http://192.168.88.233:9001/';

// Shahrear vy
// export const AuthServiceBaseUrl = 'http://103.172.112.25:4000/';
// export const TestServiceBaseUrl = 'http://103.172.112.25:4001/';
export const SeleniumServiceBaseUrl = process.env.VUE_APP_SELENIUM_SERVICE_HOST;

// Shohan Server
// export const AuthServiceBaseUrl = 'http://192.168.88.247:4000/';
// export const TestServiceBaseUrl = 'http://192.168.88.247:4001/';

/*  AWS 3.135.205.104 */
// export const AuthServiceBaseUrl = 'http://3.142.93.170:4000/';
// export const TestServiceBaseUrl = 'http://3.142.93.170:4001/';https://backend_dev.mytestjet.com:4000/

//Jira BaseUrl
export const JiraBaseUrl = 'https://cisscomllc.atlassian.net';

/* OFFICE LOCAL SERVER */
export const AuthServiceBaseUrl = process.env.VUE_APP_AUTH_SERVICE_HOST;
export const TestServiceBaseUrl = process.env.VUE_APP_TEST_SERVICE_HOST;
export const MobileServiceBaseUrl = process.env.VUE_APP_MOBILE_SERVICE_HOST;
export const AgentServiceBaseUrl = process.env.VUE_APP_AGENT_SERVICE_HOST;
export const DatabaseServiceBaseUrl = process.env.VUE_APP_DATABASE_SERVICE_HOST;


// export const SeleniumServiceBaseUrl = 'http://113.11.89.61:4002/';

/* DEV LIVE SERVER CICD*/
// export const AuthServiceBaseUrl = 'https://dev-auth.mytestjet.com/';
// export const TestServiceBaseUrl = 'https://dev-test.mytestjet.com/';
// export const SeleniumServiceBaseUrl = 'https://dev-cloud.mytestjet.com/';

// /* QA LIVE SERVER CICD */
// export const AuthServiceBaseUrl = 'https://qa-auth.mytestjet.com/';
// export const TestServiceBaseUrl = 'https://qa-test.mytestjet.com/';
// export const SeleniumServiceBaseUrl = 'https://qa-cloud.mytestjet.com/';

/* PROD LIVE SERVER CICD */
// export const AuthServiceBaseUrl = 'https://auth.mytestjet.com/';
// export const TestServiceBaseUrl = 'https://test.mytestjet.com/';
// export const SeleniumServiceBaseUrl = 'https://cloud.mytestjet.com/';
