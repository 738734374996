<template>
    <div class='p-grid test-case-list-table-wrapper' :style="{ padding: '0', margin: '0' }">
        <div class='test-case-list-table-container'>

            <div class='p-grid test-case-list-table-wrapper' :style="{ padding: '0', margin: '0' }">
                <DataTable :value="filteredTestCaeses" dataKey="_id" :paginator="true" :rows="10"
                    :rowsPerPageOptions="[10, 20, 50]" responsiveLayout="scroll" v-model:selection="selectedCaseIds"
                    class="test-case-list-table-container">
                    <template #empty>
                        <div class="text-center" style="font-size: 20px; font-weight: 700">No Data</div>
                    </template>
                    <Column selectionMode="multiple" headerStyle="width: 2em"></Column>
                    <Column field="name" header="Title" headerStyle="width: 25rem">
                        <template #body="slotProps">
                            <div class='description'>
                                <!--                  <span class="check-box">-->
                                <!--                      <Checkbox name="city" :value="slotProps.data._id" v-model="selectedCaseIds" />-->
                                <!--                  </span>-->
                                <div class='test-event-name'>
                                    <span @click='$emit(redirectToTestEditorPage(slotProps.data._id))'>
                                        {{ slotProps.data.name }}
                                    </span>

                                    <div class='actionables'>
                                        <span>
                                            <i class='fa fa-tag' title='Tag' aria-hidden='true'
                                                @click='updateTags(slotProps.data)'></i>
                                        </span>
                                        <span>
                                            <i class='fa fa-play' title='Create a Test Run'
                                                @click='createNewTestRun(slotProps.data)'></i>
                                        </span>
                                        <span>
                                            <i class='fa fa-trash' title='Delete'
                                                @click='deleteSingleTestCase(slotProps.data)'></i>
                                        </span>
                                        <!--                        <span><i class="far fa-clone" title="Clone" @click="$emit('onCloneSingleTestcase', 'element')"></i></span>-->
                                    </div>
                                </div>
                            </div>
                        </template>
                    </Column>
                    <Column field="priority" header="Priority"></Column>
                    <Column header="Category">
                        <template #body="slotProps">
                            {{ slotProps.data?.category?.name }}
                        </template>
                    </Column>
                    <Column field="tags" header="Tags">
                        <template #body="slotProps">
                            <div class='tags-wrapper'>
                                <Chip :label='tagItem' v-for='(tagItem, index) in slotProps.data.tags' :key='index'
                                    class='tag' @remove="$emit('removeTag', element, tagItem)" />
                            </div>
                        </template>
                    </Column>
                    <Column field="updatedAt" header="Last Modified Date">
                        <template #body="slotProps">
                            {{ moment(slotProps.data.updatedAt).format('MMM DD, YYYY - hh:mm A') }}
                        </template>
                    </Column>
                    <Column>
                        <template #body="slotProps">
                            <div class="result-container">
                                <p @click="openResultModal(slotProps.data)">Status</p>
                            </div>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
        <Dialog :draggable="false" header='Add Tags' v-model:visible='displayAddOrRemoveTagsModal'
            :style="{ width: '20vw' }" :modal='true'>
            <div class='p-grid p-fluid'>
                <div class='p-col-12 m-0 p-0'>
                    <div class='input-group'>
                        <TjCustomChipsMobile :tagList='tags' @onUpdateChipsList='onUpdateChipsList' />
                        <Button label='SAVE CHANGE' class='p-button-primary save-changes-button' @click='clickFrUpdateTag'
                            :style="{ marginTop: '15px' }" />
                    </div>
                </div>
            </div>
        </Dialog>

        <Dialog :draggable="false" header='Delete Testcase' v-model:visible='visibleDeleteModal' closable :modal='true'
            class='delete-confirmation-modal'>
            <template #header>
                <img src='/assets/warning-icon.svg' alt='warning-icon.svg' />
                <p>Delete Confirmation</p>
            </template>

            Are you sure you want to delete?

            <template #footer>
                <Button label='No' class='p-button-outlined' @click='visibleDeleteModal = !visibleDeleteModal' />
                <Button label='Yes' class='p-button-primary' @click='deleteEachTestCase' />
            </template>
        </Dialog>
        <Dialog :draggable="false" header="Status" v-model:visible="displayResultModal" @hide="onHideResultDialog"
            :style="{ width: '50.83vw' }" :modal="true" class="result-dialog">
            <div class="result-modal">
                <div class="testcase-id">
                    <span>Test Case ID</span>
                    <p>{{ selectedRowdata.serial }}</p>
                </div>

                <div class="testcase-name">
                    <span>Test Case Name</span>
                    <p>{{ selectedRowdata.name }}</p>
                </div>

                <div class="date">
                    <span>Date of creation</span>
                    <p>{{ selectedRowdata.createdAt ? dateTimeFormatter(selectedRowdata.createdAt) : '' }}</p>
                </div>

                <div class="col-12">
                    <div class="card">
                        <DataTable scrollable scrollHeight="300px" :value="caseResults" v-if="isCaseResultListLoaded"
                            class="p-datatable-customers" :rows="5" dataKey="id" :rowHover="true" paginator
                            :rowsPerPageOptions="[5, 10, 20]">
                            <template #empty>
                                <div style="text-align: center">No native run has been executed yet</div>
                            </template>
                            <Column field="createdAt" header="Date" sortable>
                                <template #body="slotProps">
                                    <span>{{ slotProps.data.createdAt ? dateTimeFormatter(slotProps.data.createdAt) : ''
                                    }}</span>
                                </template>
                            </Column>
                            <Column field="serial" header="Test Run ID" sortable>
                                <template #body="slotProps">
                                    <span style="color: #808080;"
                                        :class="slotProps.data?.type == 'instant' ? 'fas fa-cloud' : 'fas fa-laptop-code'" />
                                    <span v-if="slotProps.data?.type == 'instant'" style="margin-left: 14px;">{{
                                        slotProps.data.customAutomatedRunId ?
                                        slotProps.data.customAutomatedRunId : '' }}</span>
                                    <span v-else style="margin-left: 14px;">{{ slotProps.data.serial ?
                                        slotProps.data.serial : '' }}</span>
                                </template>
                            </Column>
                            <Column field="testRunName" header="Run Name">
                                <template #body="slotProps">
                                    <span>{{ slotProps.data.testRunName }}</span>
                                </template>
                            </Column>
                            <Column header="Issues">
                                <template #body="slotProps">
                                    <div v-if="slotProps.data.issue_names?.length" class="issue-container">
                                        <Chip :label="slotProps.data?.issue_names[0]" />
                                        <p v-if="slotProps.data?.issue_names.length > 1" class="more-issue-info">+{{ slotProps.data?.issue_names.length - 1 }} More</p>
                                    </div>
                                </template>
                            </Column>
                            <Column field="executionResult.failedSteps" header="Status" sortable>
                                <template #body="slotProps">

                                    <span v-if="slotProps.data?.type == 'native'"
                                        :class="slotProps.data.executionResult?.failedSteps === 0 ? 'pass' : 'fail'"><b>{{
                                            slotProps.data.executionResult?.failedSteps === 0 ? 'Pass' : 'Fail'
                                        }}</b></span>
                                    <span v-else-if="slotProps.data?.type == 'instant'"
                                        :class="slotProps.data.executionResult?.failedSteps === 0 ? 'pass' : 'fail'"><b>{{
                                            slotProps.data.executionResult?.failedSteps === 0 ? 'Pass' : 'Fail'
                                        }}</b></span>
                                </template>
                            </Column>
                        </DataTable>
                        <Loader v-else />
                    </div>
                </div>

            </div>
        </Dialog>
        <div class='custom-pagination-wrapper'>
            <!--            <Paginator :rows='eachRow' :totalRecords='testCaseCount' v-model:first='currentIndex' :rowsPerPageOptions='[10, testCaseCount]' @page='onPage($event)' />-->
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { getSelectedCurrentProjectId } from '../../../utils/localstorage';
import TjCustomChipsMobile from '../../commonComponents/TjCustomChipsMobile.vue';
import { dateTimeFormatter } from '@/utils/helpers';
import moment from 'moment';
import { forEach } from 'lodash';


export default {
    components: {
        TjCustomChipsMobile,
    },
    props: {
        searchTestcase: {
            type: String,
            default: '',
        },
        selectedPriorities: {
            type: String,
            default: '',
        },
        selectedCategories: {
            type: Object,
            default: '',
        }
    },
    data() {
        return {
            displayAddOrRemoveTagsModal: false,
            visibleDeleteModal: false,
            caseToEdit: null,
            tags: [],
            selectedCaseIds: [],
            cases: [...new Array(20)].map((el, i) => {
                return {
                    _id: Math.random(),
                    test_case_name: `random ${i}`,
                    tags: ['sadsadsa', 'adads', 'zx', 'cxzczx'],
                };
            }),
            subscriptionIsExpired: false,
            moment: moment,
            dateTimeFormatter: dateTimeFormatter,
            caseResults: [],
            selectedRowData: {},
            displayResultModal: false,
            categories: [],
        };
    },
    created() {
        this.getCategories(this.selectedProjectId).then(res => {
        if (res?.status === 200 && res?.data?.length) {
            this.categories = res.data;
        }
    })
    },
    mounted() {
        this.fetchMobileTestCaseList();
        // this.subscriptionIsExpired = dateComparison(this.subscriptionPlan.consumed_plan.validity_expire);
    },
    watch: {
        selectedCaseIds(val) {
            console.log("selectedCaseIds", val)
            this.$emit('selectedTestCaseIds', val);
        }
    },
    computed: {
        ...mapGetters({
            getMobileTestCaseList: 'mobileTestCases/mobileTestCaseList',
            projectList: 'project/projectList',
            selectedProjectId: 'project/projectID',
            tagList: 'project/tagList',
            subscriptionPlan: 'auth/subscriptionPlan',
            isCaseResultListLoaded: 'mobileTestCases/isCaseResultListLoaded',
        }),
        ...mapState(['MobileTestCases']),

        filteredTestCaeses() {
            let filteredList = this.getMobileTestCaseList;
            const caseList = [...filteredList];
            if (caseList.length) {
               caseList.forEach((item) => {
                    const category = this.categories.find(el => el.id == item?.category_id)
                    if (category!=null) {
                        item.category = category;
                    }
                })
            }
            if (this.searchTestcase.length) {
                filteredList = filteredList.filter((item) => {
                    return item.name?.toLowerCase().includes(this.searchTestcase?.toLowerCase()) || item.tags.includes(this.searchTestcase);
                });
            }

            if (this.selectedPriorities?.length) {
                let priority_names = this.selectedPriorities.map(c=> c.name);
                filteredList = filteredList.filter((item) => {
                    return priority_names.includes(item.priority)
                });
            }

            if (this.selectedCategories?.length) {
                let category_ids = this.selectedCategories.map(c=> c._id);
                filteredList = filteredList.filter((item) => {
                    return category_ids.includes(item.category_id)
                });

            }
            return filteredList;
        }
    },
    methods: {
        ...mapActions({
            getMobileCaseList: 'mobileTestCases/actionGetMobileCaseList',
            updateMobileTestCase: 'mobileTestCases/actionUpdateMobileTestcase',
            deleteTestcase: 'mobileTestCases/ActionDeleteTestcase',
            fetchCaseResultsFromRuns: 'mobileTestCases/actionGetCaseResultsFromRuns',
            // getTestrunResultList: 'mobileTestCases/actionGetTestrunResult',
            getCategories: 'category/actionGetCategories',
        }),
        deleteEachTestCase() {
            this.deleteTestcase([this.caseToEdit?._id])
                .then((resBody) => {

                    // if (resBody.status && resBody.status.code == 200) {
                        this.visibleDeleteModal = false;
                        this.$emit('deleteSingleTestCase', { ...this.caseToEdit });
                        this.caseToEdit = null;
                        this.$toast.add({ severity: 'success', summary: 'Test Case deleted successfully.', life: 3000 });
                    // } else {
                    //     this.$toast.add({ severity: 'success', summary: 'Test Case delete Unsuccessful.', life: 3000 });
                    // }
                })
                .catch((err) => {
                    this.$toast.add({ severity: 'error', summary: 'Something went wrong!', life: 3000 });
                    console.log('delete-res-err', err.response);
                });
        },
        onUpdateChipsList(updatedChipsList) {
            this.tags = updatedChipsList;
        },

        async clickFrUpdateTag() {
            this.caseToEdit.tags = [...this.tags];

            const res = await this.updateMobileTestCase(this.caseToEdit);

            if (res.status && res.status.code == 200) {
                this.$toast.add({ severity: 'success', summary: res.message, life: 3000 });
                this.$emit('updateTag', { ...this.caseToEdit });

            } else {
                this.$toast.add({ severity: 'success', summary: 'Failed to update testcase!', life: 3000 });
            }

            this.displayAddOrRemoveTagsModal = false;
            this.caseToEdit = null;
            this.tags = [];

        },

        updateTags(testCase) {
            if (this.subscriptionIsExpired) {
                this.displayAddOrRemoveTagsModal = false;
                this.checkSubscription();
                return;
            }
            console.log('tag', testCase);
            this.caseToEdit = testCase;
            this.tags = [...testCase.tags];
            this.displayAddOrRemoveTagsModal = true;
        },
        showErrorMsg(msgDetail) {
            this.$toast.add({ severity: 'error', summary: 'Error Message', detail: msgDetail, life: 5000 });
        },

        checkSubscription() {
            if (this.subscriptionIsExpired) {
                this.showErrorMsg('Your free trial has expired. Please renew or upgrade your subscription package.');
            }
        },
        openResultModal(testCase) {
            this.displayResultModal = true;
            this.selectedRowdata = testCase;
            // this.getTestrunResultList(testCase._id);
            this.fetchCaseResultsFromRuns(testCase._id)
                .then((resBody) => {
                    if (resBody.status.code === 200) {
                        this.caseResults = resBody.data.caseResultsFromExecutedCases;
                    } else {
                        this.caseResults = [];
                    }
                });
            this.displayResultModal = true;
        },
        onHideResultDialog() {
            this.resultList = [];
            this.caseResults = [];
            this.selectedRowdata = {};
            this.displayResultModal = false;
        },
        createNewTestRun(testCase) {
            console.log('createNewTestRun', testCase);
            this.$emit('createNewTestRun', testCase);
        },
        deleteSingleTestCase(testCase) {
            if (this.subscriptionIsExpired) {
                this.visibleDeleteModal = false;
                this.checkSubscription();
                return;
            }
            console.log('deleteSingleTestCase', testCase);
            this.caseToEdit = testCase;
            this.visibleDeleteModal = true;
        },
        fetchMobileTestCaseList() {
            if (getSelectedCurrentProjectId()) {
                let reqBody = {
                    projectID: getSelectedCurrentProjectId(),
                };
                this.getMobileCaseList(reqBody);
            } else {
                console.log('Please create or select project first.');
                // this.showWarningMsg('Please select project first.');
            }
        },
        redirectToTestEditorPage(selectedTestCaseId) {
            if (this.subscriptionIsExpired) {
                this.checkSubscription();
                return;
            }
            this.$router.push({ path: `/test-editor-mobile/${selectedTestCaseId}` });
        },
    },
};
</script>

<style lang='scss' scoped>
.item-container {

    .test-case-wrapper {

        .test-case {

            .test-name-wrapper {

                .checkbox {}

                .description {
                    max-width: 63em;
                    width: 100%;
                }

                .tags-wrapper {}

            }

        }
    }

}

.custom-pagination-wrapper {
    margin: 0 auto;
}
</style>
