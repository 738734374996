<template>
  <div class="step__run__loader">
    <div class="lds-facebook">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'StepRunLoader',
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="scss">
.lds-facebook {
  display: inline-block;
  position: relative;
  width: 8px;
  height: 8px;

}

.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 3px;
  margin-bottom: 10px !important;
  width: 6px;
  height: 6px;
  background: #00a0ee;
  border-radius: 5px;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.lds-facebook div:nth-child(1) {
  left: 4px;
  animation-delay: -0.24s;
}

.lds-facebook div:nth-child(2) {
  left: 20px;
  animation-delay: -0.12s;
}

.lds-facebook div:nth-child(3) {
  left: 38px;
  animation-delay: 0;
}

@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}

.step__run__loader {
  margin-top: -20px !important;
  margin-right: 40px !important;
}
</style>