/* eslint-disable no-unused-vars */
import TestServiceRequest from '../utils/request/test_request';
import MobileServiceRequest from '@/utils/request/mobile_request';

// START TEST_CASE SECTION


export const sendImgToS3 = async (projId, caseId, screenshot) => {
    return MobileServiceRequest.post(`/screenshots/${projId}/${caseId}`, screenshot, { headers: { 'Content-Type': 'multipart/form-data' } })
};

export const deleteTestSteps = async (arrOfIds) => {
    let arr = [...arrOfIds]
    return MobileServiceRequest.delete(`/test-steps/many`, {data: arr})
};

export const insertNewMobileTestCase = async data => {
    return await MobileServiceRequest.post(`/test-cases`, data)
        .then(res => {
            return res;
        })
        .catch(error => {
            console.log('TestCaseService-newcase--Error', error);
        });
};

export const fetchMobileTestCaseList = async (payload, metaData=null) => {
    const {projectID, duration} = payload;
    console.log(payload)
    if (!projectID || !duration) {
      null
    } else {
      return await MobileServiceRequest.get(`/projects/${projectID}/${duration}/test-cases`)
        .then(res => {
          return res;
        })
        .catch(error => {
          return error;
        });
    }


};

export const fetchMobileTestCase = async (testCaseId) => {
    return await MobileServiceRequest.get(`/test-cases/${testCaseId}`)
        .then(res => {
            return res;
        })
        .catch(error => {
            return error;
        });
};


export const updateMobileTestcase = data => {
    return MobileServiceRequest.put(`/test-cases/${data._id}`, data)
};

export const deleteTestcases = async reqDataArray => {
    return await MobileServiceRequest.delete(`/test-cases/delete`, {data: reqDataArray})
};


// START TEST_STEPS SECTION
export const insertMobileTestSteps = async data => {
    return await MobileServiceRequest.post(`/test-steps`, data)
        .then(res => {
            return res;
        })
        .catch(error => {
            return error;
        });
};

export const fetchMobileTestStepList = async (testCaseId) => {
    return await MobileServiceRequest.get(`/test_steps`)
        .then(res => {
            return res;
        })
        .catch(error => {
            console.log('TestCaseService-getTestCaseList-Error', error);
        });
};

export const deleteMobileTestSteps = async reqDataObj => {
    return await MobileServiceRequest.delete(`/test-steps`, {data: reqDataObj})
};

export const fetchCaseResultsFromRuns = async testCaseID => {
    return await MobileServiceRequest.get(`/test-cases/case-results-from-runs/${testCaseID}`).then(res => {
        return res;
    })
        .catch(error => {
            return error;
        });
};

export default MobileServiceRequest;


export const updateTestStep = async (stepId, testStep) => {
      return await MobileServiceRequest.put(`/test-steps/${stepId}`, testStep)
          .then(res => {
              return res;
          })
          .catch(error => {
              console.log('TestCaseService-properties-save-Error', error);
          });
  };