import {storeTestrailRun} from '@/api/testrailsService';

export default {
    methods: {
        handleTestrailIntegration(e) {
            if (this.testRailsConfig && this.testRailsConfig.current_project) {
                if (this.isTestRail) {
                this.isTestCaseRail = true;
                } else {
                this.isTestCaseRail = false;
                }
            } else {
                this.isTestRail = !this.isTestRail;
                this.$toast.add({
                severity: 'warn',
                summary: 'Please select Project in TestRail Integration',
                life: 3000,
                });
            }
        },
        onTestRailPressed(testCase) {
            if (this.testRailsConfig) {
                if (this.isTestRail) {
                  this.$refs.testRailMapperRef.openDialog();
                  this.currentTestCaseRail = testCase;
                } else {
                  this.$toast.add({
                      severity: 'warn',
                      summary: 'Please click on the toggle button to enable TestRail integration',
                      life: 4000,
                  });
                }
            } else {
                this.$toast.add({
                severity: 'warn',
                summary: 'Testrail account is not connected',
                life: 3000,
                });
            }
        },

        async onMapTestRail({projectId, testRailTestCase, testCase}) {
            // console.log({projectId, testRailTestCase, testCase: testCase.name});
                
            const payload = {
                _id : testCase._id,
                testrails_projectId: projectId,
                testrails_testcase : testRailTestCase
            }

            try {
                const resp = await this.actionUpdateCase(payload)
                // console.log("👩‍❤️‍👩 👩‍❤️‍👩 👩‍❤️‍👩 👩‍❤️‍👩 👩‍❤️‍👩 👩‍❤️‍👩 👩‍❤️‍👩 👩‍❤️‍👩 👩‍❤️‍👩 ", resp);

                let testcaseInd = this.testRun.testCases.findIndex(_case => _case._id == testCase._id)

                this.testRun.testCases[testcaseInd] = {
                    ...this.testRun.testCases[testcaseInd],
                    testrails_projectId: `${projectId}`,
                    testrails_testcase: testRailTestCase
                }

                // console.log(this.testRun.testCases[testcaseInd]);
                // console.log("RUN __________ RUN", this.testRun);
                
            } catch (err) {
                console.log(err);
            }

        },
        async addTestRunToTestrails(executedTestRun) {
            const testRailMappedTestruns = executedTestRun.testCases.filter((testCase) => testCase.testrails_projectId !== null);
            // If result will send to testrails
      
            if (this.isTestRail && testRailMappedTestruns.length) {
              // StatusCode of testrail
              const failedCode = 5;
              const passCode = 1;
              // process data
              const data = {
                projectId: this.testRailsConfig.current_project && this.testRailsConfig.current_project.id,
                testRun: {
                  suite_id: 1,
                  name: executedTestRun.name + new Date().toTimeString(),
                  include_all: false,
                  case_ids: testRailMappedTestruns.map((testCase) => testCase.testrails_testcase && testCase.testrails_testcase.id),
                },
                testCases: testRailMappedTestruns.map((testCase) => {
                  return {
                    case_id: testCase.testrails_testcase && testCase.testrails_testcase.id,
                    status_id: testCase.status === 'failed' ? failedCode : passCode,
                    comment: testCase.status === 'failed' ? 'This test failed' : 'This test passed',
                    defects: testCase._id,
                  };
                }),
              };
      
              // Testrail run
              // if testRail integration is enabled then send steps failure and passing rates to testrail
              await storeTestrailRun(data)
                  .then((data) => {
                    this.$toast.add({
                      severity: 'success',
                      summary: 'All testCases were mapped to testrail !',
                      life: 3000,
                    });
                  })
                  .catch((err) => {
                    this.$toast.add({
                      severity: 'warn',
                      summary: 'something went wrong with testrail ',
                      life: 3000,
                    });
                  });
            } else {
              // this.$toast.add({
              //     severity: 'warn',
              //     summary: 'Please map all testcase with testrail',
              //     life: 3000,
              // });
              return;
            }
          },
    }
    
};
