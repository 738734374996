<template>
  <ul class="assertion-main">
    <li v-for="(assertion, i) in assertions" :key="i" :class="assertion['type'] ? 'collapse' : ''">
      <div class="stat_section">
        <span class="status passed" v-if="response && response[i]?.status === 'success'">Passed</span>
        <span class="status failed" v-else-if="response && response[i]?.status === 'failed'">Failed</span>
      </div>
      <Checkbox v-model="assertion['enabled']" :disabled="!assertion?.type" :binary="true" />
      <Dropdown v-model="assertion['type']" :options="assertionTypes" optionLabel="name" optionValue="code"
        placeholder="NONE" class="" @change="assertionTypeChange(assertion)" />
      <Dropdown v-if="assertion['type']" v-model="assertion['arithmeticOption']"
        :options="getarithmeticOptionsBasedOnAssertionType(assertion)" optionLabel="name" optionValue="code"
        placeholder="NONE" class="" />

      <InputNumber
        :style="{ display: assertion?.type === 'statusCode' && assertion['arithmeticOption'] === 'equals' ? 'block' : 'none' }"
        v-model="assertion['value']" placeholder="value" />
      <InputNumber
        :style="{ display: assertion?.type === 'statusCode' && assertion['arithmeticOption'] === 'doesNotEqual' ? 'block' : 'none' }"
        v-model="assertion['value']" placeholder="value" />
      <InputNumber
        :style="{ display: assertion?.type === 'statusCode' && assertion['arithmeticOption'] === 'range' ? 'block' : 'none' }"
        v-model="assertion['from']" placeholder="From" />
      <InputNumber
        :style="{ display: assertion?.type === 'statusCode' && assertion['arithmeticOption'] === 'range' ? 'block' : 'none' }"
        v-model="assertion['to']" placeholder="to" />

      <InputText :style="{ display: assertion?.type === 'header' ? 'block' : 'none' }" v-model="assertion['key']"
        placeholder="name" />
      <InputText :style="{ display: assertion?.type === 'header' ? 'block' : 'none' }" v-model="assertion['value']"
        placeholder="value" />

      <InputText :style="{ display: assertion?.type === 'jsonBody' ? 'block' : 'none' }" v-model="assertion['key']"
        placeholder="name" />
      <InputText :style="{ display: assertion?.type === 'jsonBody' ? 'block' : 'none' }" v-model="assertion['value']"
        placeholder="value" />
      <!-- <pre>{{ assertion['arithmeticOption'] }}</pre> -->
    </li>
  </ul>
</template>

<script>
export default {
  name: "assetion",
  data: () => ({
    status: null,
    assertions: [],
    assertionTypes: [
      { name: 'Status Code', code: 'statusCode' },
      { name: 'Header', code: 'header' },
      { name: 'Body (JSON)', code: 'jsonBody' },
    ],
    headerArithmaticOptions: [
      { name: 'Equals', code: 'equals' },
      { name: 'Does not equal', code: 'doesNotEqual' },
      { name: 'Contains', code: 'contains' },
    ],
    statusCodeArithmaticOptions: [
      { name: 'Equals', code: 'equals' },
      { name: 'Does not equal', code: 'doesNotEqual' },
      // { name: 'Range', code: 'range' },
    ],
    jsonArithmaticOptions: [
      { name: 'Equals', code: 'equals' },
      { name: 'Does not equal', code: 'doesNotEqual' },
    ],
  }),
  mounted() {
    if (this.allAssertions.length) {
      this.assertions = [...this.allAssertions]

    } else if (this.response) {
      this.assertions = [...this.response]

    } else {
      this.assertions = [
        {
          index: 0,
          enabled: false,
          type: null,
          arithmeticOption: 'equals',
          key: null,
          value: 200,
          from: null,
          to: null,
        }
      ]
    }
  },
  props: {
    // readonly: {
    //   type: Boolean,
    //   default: false
    // },
    allAssertions: {
      type: Array,
      default: []
    },
    response: {
      type: Object || null,
      default: null
    },
  },
  watch: {
    assertions: {
      handler(value) {
        this.$emit('assertionUpdated', value)
      },
      deep: true
    }
  },
  methods: {
    assertionTypeChange(assertion) {
      console.log(assertion);
      const indexOfchangedAssertion = this.assertions.indexOf(assertion)

      assertion.enabled = true;
      assertion.arithmeticOption = 'equals';


      if (assertion.type === 'statusCode') {
        assertion.key = null
        assertion.value = 200
      }

      if (assertion.type === 'header') {
        assertion.key = null
        assertion.value = null
      }

      if (assertion.type === 'jsonBody') {
        assertion.key = null
        assertion.value = null
      }



      if (indexOfchangedAssertion === this.assertions.length - 1) {
        this.assertions.push({
          index: this.assertions.length - 1,
          enabled: false,
          arithmeticOption: 'equals',
          type: null,
          key: null,
          value: 200,
          from: null,
          to: null,
        })
      }
    },
    getarithmeticOptionsBasedOnAssertionType(assertion) {
      if (assertion.type === 'statusCode') {
        return this.statusCodeArithmaticOptions
      } else if (assertion.type === 'header') {
        return this.headerArithmaticOptions
      } else if (assertion.type === 'jsonBody') {
        return this.jsonArithmaticOptions
      }
    }
  },
}
</script>

<style lang="scss" scoped></style>
