export const androidLists = {
    InsertVariable: {
      name: 'Insert variable',
      action:'insert_variable',
      icon: 'fa fa-location-arrow',
      hasOwnComponent: false,
      InputRequired: false,
    },
    CustomTap: {
      name: 'Custom tap',
      action: 'custom_tap',
      icon: 'fas fa-hand-point-up',
      hasOwnComponent: false,
      InputRequired: false,
    },
    CheckboxChecked: {
      name: 'Validate Checked',
      action: 'validate_checked',
      icon: 'fas fa-check-square',
      hasOwnComponent: false,
      InputRequired: false,
    },
    ValidateCheckbox: {
      name: 'Validate Checkbox',
      action: 'validate_checkbox',
      icon: 'far fa-check-square',
      hasOwnComponent: false,
      InputRequired: false,
    },

    IsInputField: {
      name: 'Validate Input Field',
      action: 'validate_input_field',
      icon: 'fas fa-keyboard',
      hasOwnComponent: false,
      InputRequired: false,
    },

    IsButton: {
      name: 'Validate Button',
      action: 'validate_button',
      icon: 'far fa-dot-circle',
      hasOwnComponent: false,
      InputRequired: false,
    },

    ValidateText: {
      name: 'Validate Text',
      action: 'validate_text',
      icon: 'fas fa-text-width',
      hasOwnComponent: false,
      InputRequired: true,
    },

    ValidateElementClickable: {
      name: 'Validate element clickable',
      action: 'validate_element_clickable',
      icon: 'fas fa-hand-pointer',
      hasOwnComponent: false,
      InputRequired: false,
    },

    ValidateElementEnabled: {
      name: 'Validate element enabled',
      action: 'validate_element_enabled',
      icon: 'fas fa-toggle-on',
      hasOwnComponent: false,
      InputRequired: false,
    },

    ValidateElementVisible: {
      name: 'Validate element visible',
      action: 'validate_element_visible',
      icon: 'fas fa-eye',
      hasOwnComponent: false,
      InputRequired: false,
    },

    ValidateElementInvisible: {
      name: 'Validate element invisible',
      action: 'validate_element_invisible',
      icon: 'fas fa-eye-slash',
      hasOwnComponent: false,
      InputRequired: false,
    },

    ElementSleep: {
      name: 'Sleep',
      action: 'sleep',
      icon: 'fas fa-bed',
      hasOwnComponent: false,
      InputRequired: true,
    },

    ValidateEmail: {
      name: 'Validate Email',
      action: 'validate_email',
      icon: 'fas fa-envelope',
      hasOwnComponent: true,
      InputRequired: false,
    },

    ValidateSMS: {
      name: 'Validate SMS',
      action: 'validate_sms',
      icon: 'fas fa-sms',
      hasOwnComponent: true,
    },

    // ValidateElementPresent: {
    //     name: 'Validate element present',
    //     action: 'validate_element_present',
    //     icon: 'fas fa-check',
    // },

    // ValidateElementSelected: {
    //     name: 'Validate element selected',
    //     action: 'validate_element_selected',
    //     icon: 'fas fa-check-square',
    // },
};

export const iosLists = {
    InsertVariable: {
      name: 'Insert variable',
      action:'insert_variable',
      icon: 'fa fa-location-arrow',
      hasOwnComponent: false,
      InputRequired: false,
    },
    CustomTap: {
        name: 'Custom tap',
        InputRequired: false,
        action: 'custom_tap',
        icon: 'fas fa-hand-point-up',
    },
    // CheckboxChecked: {
    //     name: 'Validate Checked',
    //     InputRequired: false,
    //     action: 'validate_checked',
    //     icon: 'fas fa-check-square',
    // },
    // ValidateCheckbox: {
    //     name: 'Validate Checkbox',
    //     InputRequired: false,
    //     action: 'validate_checkbox',
    //     icon: 'far fa-check-square',
    // },

    IsInputField: {
        name: 'Validate Input Field',
        InputRequired: false,
        action: 'validate_input_field',
        // icon: 'fas fa-money-check-edit',
        icon: 'fas fa-keyboard',
    },

    IsButton: {
        name: 'Validate Button',
        InputRequired: false,
        action: 'validate_button',
        // icon: 'fad fa-hockey-puck',
        icon: 'far fa-dot-circle',
    },

    ValidateText: {
        name: 'Validate Text',
        action: 'validate_text',
        icon: 'fas fa-text-width',
        InputRequired: true,
    },

    ValidateElementClickable: {
        name: 'Validate element clickable',
        InputRequired: false,
        action: 'validate_element_clickable',
        icon: 'fas fa-hand-pointer',
    },

    ValidateElementEnabled: {
        name: 'Validate element enabled',
        InputRequired: false,
        action: 'validate_element_enabled',
        icon: 'fas fa-toggle-on',
    },

    // ValidateElementVisible: {
    //     name: 'Validate element visible',
    //     action: 'validate_element_visible',
    //     icon: 'fas fa-eye',
    // },

    // ValidateElementInvisible: {
    //     name: 'Validate element invisible',
    //     action: 'validate_element_invisible',
    //     icon: 'fas fa-eye-slash',
    // },

    ElementSleep: {
        name: 'Sleep',
        action: 'sleep',
        icon: 'fas fa-bed',
        InputRequired: true,
    },

    // ValidateElementPresent: {
    //     name: 'Validate element present',
    //     action: 'validate_element_present',
    //     icon: 'fas fa-check',
    // },

    // ValidateElementSelected: {
    //     name: 'Validate element selected',
    //     action: 'validate_element_selected',
    //     icon: 'fas fa-check-square',
    // },
};
