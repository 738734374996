export const TJSuperAdminMenu = [
    {
        label: 'Overview',
        icon: 'fas fa-columns',
        to: '/admin-dashboard',
        items: [
            {
                label: 'Clients',
                icon: 'fas fa-user-tie',
                to: `/clients`,
            },
            {
                label: 'Subscription',
                icon: 'fas fa-money-check-alt',
                to: '/subscription-packages',
            },
        ]
    },
    {
        label: 'Teams',
        icon: 'fas fa-user-friends',
        to: '/team-management',
    },
    {
        label: 'Configuration',
        icon: 'fas fa-wrench',
        to: '/tj-admin-features',
    },
    {
        label: 'Settings',
        icon: 'fas fa-cog',
        to: '/settings',
    },
];
