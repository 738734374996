<template>
  <div class="client-info-container">
    <div class="header-wrapper">
      <div class="header-info">
        <div class="back-btn">
          <!-- <router-link to="/clients"><Button icon="fas fa-chevron-left" class="p-button-rounded p-button-outlined"/></router-link> -->
          <Button icon="fas fa-chevron-left" class="p-button-rounded p-button-outlined" @click="changeView"/>
        </div>

        <p>Client Information</p>
      </div>
      <div class="action-icons-wrapper">
        <div class="action-btn">
          <Button disabled="true" icon="fas fa-edit" @click="addOrRemoveTags"
                  class="p-button-rounded p-button-outlined"/>
        </div>

        <div class="action-btn">
          <Button disabled="true" icon="fas fa-play" class="p-button-rounded p-button-outlined"/>
        </div>
      </div>
    </div>
    <div class="content-wrapper">
      <div class="p-grid client-info-overview">
        <div class="">
          <div class="card">
            <div class="message">
              <!-- <img :src="image ? image : defaultAvatar" alt="client-avatar" class="messager-img" /> -->
              <avatar class="p-avatar-lg" shape="circle" v-if="image" :image="image"/>
              <avatar
                  class="p-avatar-lg"
                  shape="circle"
                  style="background-color: #dee2e6"
                  v-else-if="!image && accountStatus === 1"
                  :label="client_information.user_profile.first_name[0].toUpperCase() + client_information.user_profile.last_name[0].toUpperCase()"
              />
              <avatar class="p-avatar-lg" shape="circle" v-else image="/assets/layout/images/Default-User-Avatar.png"/>
              <div class="messager">
                <p>{{ name === 'undefined undefined' ? '---' : name }}</p>
                <span>{{ role }}</span>
              </div>
            </div>

            <div class="panel-inner">
              <div class="client-info-overview-line">
                <div class="line-left">
                  <span class="line-label">Email</span>
                </div>

                <div class="line-right">
                  <span class="line-value">{{ email }}</span>
                </div>
              </div>
              <div class="client-info-overview-line">
                <div class="line-left">
                  <span class="line-label">Phone</span>
                </div>

                <div class="line-right">
                  <span class="line-value">{{ accountStatus === 2 ? '---' : phone }}</span>
                </div>
              </div>

              <div class="client-info-overview-line">
                <div class="line-left">
                  <span class="line-label">Country</span>
                </div>

                <div class="line-right">
                  <span class="line-value">{{ accountStatus === 2 ? '---' : country }}</span>
                </div>
              </div>

              <div class="client-info-overview-line">
                <div class="line-left">
                  <span class="line-label">City</span>
                </div>

                <div class="line-right">
                  <span class="line-value">{{ accountStatus === 2 ? '---' : city }}</span>
                </div>
              </div>

              <div class="client-info-overview-line">
                <div class="line-left">
                  <span class="line-label">Address</span>
                </div>

                <div class="line-right">
                  <span class="line-value">{{ accountStatus === 2 ? '---' : address }}</span>
                </div>
              </div>
              <div class="client-info-overview-line">
                <div class="line-left">
                  <span class="line-label">Company Name</span>
                </div>

                <div class="line-right">
                  <span class="line-value">{{ accountStatus === 2 ? '---' : companyName }}</span>
                </div>
              </div>
              <div class="client-info-overview-line">
                <div class="line-left">
                  <span class="line-label">Industry</span>
                </div>

                <div class="line-right">
                  <span class="line-value">{{ accountStatus === 2 ? '---' : industry }}</span>
                </div>
              </div>
              <div class="client-info-overview-line">
                <div class="line-left">
                  <span class="line-label">Account Status</span>
                </div>

                <div class="line-right">
                  <span class="line-value">{{
                      accountStatus === 0 ? 'Inactive' : accountStatus === 1 ? 'Active' : accountStatus === 2 ? 'Pending' : accountStatus === 3 ? 'Suspended' : ''
                    }}</span>
                </div>
              </div>
              <div class="client-info-overview-line">
                <div class="line-left">
                  <span class="line-label">Payment Status</span>
                </div>

                <div class="line-right">
                  <span class="line-value">{{
                      paymentStatus === 0 ? 'Inactive' : paymentStatus === 1 ? 'Paid' : paymentStatus === 2 ? 'Paid Pending' : paymentStatus === 3 ? 'Suspended' : paymentStatus === 4 ? 'Trial' : ''
                    }}</span>
                </div>
              </div>
              <div class="client-info-overview-line">
                <div class="line-left">
                  <span class="line-label">Registered</span>
                </div>

                <div class="line-right">
                  <span class="line-value">{{ registered }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="p-grid subscription-package-card">
        <div class="card">
          <div v-if="paymentStatus != 0">
            <div class="header-info">
              <h4>{{ packageTitle ? packageTitle : '' }}</h4>
              <!-- <p>1 Month</p> -->
              <h3>${{ packagePrice ? packagePrice : '' }}<span>/month</span></h3>
              <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et</p> -->
            </div>

            <div class="panel-inner">
              <div class="subscription-package-card-line">
                <div class="line-left">
                                    <span class="line-icon">
                                        <i class="fas fa-check"></i>
                                    </span>
                  <span class="line-label">{{ testRuns }} Test Runs Per Month</span>
                </div>
              </div>

              <div class="subscription-package-card-line">
                <div class="line-left">
                                    <span class="line-icon">
                                        <i class="fas fa-check"></i>
                                    </span>
                  <span class="line-label">{{ testCases === 0 ? 'Unlimited' : testCases }} Test Cases Per Month</span>
                </div>
              </div>

              <div class="subscription-package-card-line">
                <div class="line-left">
                                    <span class="line-icon">
                                        <i class="fas fa-check"></i>
                                    </span>
                  <span class="line-label">{{ projects }} Projects</span>
                </div>
              </div>

              <div class="subscription-package-card-line">
                <div class="line-left">
                                    <span class="line-icon">
                                        <i v-if="upgradeable" class="fas fa-check"></i>
                                        <i v-else class="fas fa-times"></i>
                                    </span>
                  <span class="line-label">Upgradable</span>
                </div>
              </div>
              <div class="subscription-package-card-line">
                <div class="line-left">
                                    <span class="line-icon">
                                        <i v-if="renewable" class="fas fa-check"></i>
                                        <i v-else class="fas fa-times"></i>
                                    </span>
                  <span class="line-label">Renewable</span>
                </div>
              </div>
              <div class="subscription-package-card-line">
                <div class="line-left">
                                    <span class="line-icon">
                                        <i v-if="mobileAppTesting" class="fas fa-check"></i>
                                        <i v-else class="fas fa-times"></i>
                                    </span>
                  <span class="line-label">Mobile App Testing</span>
                </div>
              </div>
              <div class="subscription-package-card-line">
                <div class="line-left">
                                    <span class="line-icon">
                                        <i v-if="cloudExecution" class="fas fa-check"></i>
                                        <i v-else class="fas fa-times"></i>
                                    </span>
                  <span class="line-label">Test Executed in Cloud (SaaS)</span>
                </div>
              </div>
              <div class="subscription-package-card-line">
                <div class="line-left">
                                    <span class="line-icon">
                                        <i v-if="webTesting" class="fas fa-check"></i>
                                        <i v-else class="fas fa-times"></i>
                                    </span>
                  <span class="line-label">Web Testing</span>
                </div>
              </div>
              <div class="subscription-package-card-line">
                <div class="line-left">
                                    <span class="line-icon">
                                        <i v-if="mobileWebTesting" class="fas fa-check"></i>
                                        <i v-else class="fas fa-times"></i>
                                    </span>
                  <span class="line-label">Mobile Web Testing</span>
                </div>
              </div>
              <div class="subscription-package-card-line">
                <div class="line-left">
                                    <span class="line-icon">
                                        <i v-if="testRailIntegration" class="fas fa-check"></i>
                                        <i v-else class="fas fa-times"></i>
                                    </span>
                  <span class="line-label">TestRail Integration</span>
                </div>
              </div>
              <div class="subscription-package-card-line">
                <div class="line-left">
                                    <span class="line-icon">
                                        <i v-if="jiraIntegration" class="fas fa-check"></i>
                                        <i v-else class="fas fa-times"></i>
                                    </span>
                  <span class="line-label">JIRA Integration</span>
                </div>
              </div>
              <div class="subscription-package-card-line">
                <div class="line-left">
                                    <span class="line-icon">
                                        <i v-if="chromeBrowserTesting" class="fas fa-check"></i>
                                        <i v-else class="fas fa-times"></i>
                                    </span>
                  <span class="line-label">Chrome Browser Testing</span>
                </div>
              </div>
              <div class="subscription-package-card-line">
                <div class="line-left">
                                    <span class="line-icon">
                                        <i v-if="chromeExtension" class="fas fa-check"></i>
                                        <i v-else class="fas fa-times"></i>
                                    </span>
                  <span class="line-label">Chrome Extension</span>
                </div>
              </div>
              <div class="subscription-package-card-line">
                <div class="line-left">
                                    <span class="line-icon">
                                        <i v-if="testExecutedLocally" class="fas fa-check"></i>
                                        <i v-else class="fas fa-times"></i>
                                    </span>
                  <span class="line-label">Test Executed in Local Browser</span>
                </div>
              </div>
              <div class="subscription-package-card-line">
                <div class="line-left">
                                    <span class="line-icon">
                                        <i v-if="liveChat" class="fas fa-check"></i>
                                        <i v-else class="fas fa-times"></i>
                                    </span>
                  <span class="line-label">Live Chat Teschnical Support 24/7</span>
                </div>
              </div>
              <div class="subscription-package-card-line">
                <div class="line-left">
                                    <span class="line-icon">
                                        <i v-if="parallelTestRun" class="fas fa-check"></i>
                                        <i v-else class="fas fa-times"></i>
                                    </span>
                  <span class="line-label">Parallel Test Run</span>
                </div>
              </div>
              <div class="subscription-package-card-line">
                <div class="line-left">
                                    <span class="line-icon">
                                        <i v-if="dedicatedResource" class="fas fa-check"></i>
                                        <i v-else class="fas fa-times"></i>
                                    </span>
                  <span class="line-label">1x Dedicated Resource</span>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="no-subscription">
            <h1>No Subscription</h1>
          </div>
        </div>
      </div>

      <div class="subscription-status">
        <div class="card">
          <div v-if="paymentStatus != 0">
            <div class="subscription-status-header">Subscription Status</div>
            <div class="subscription-status-icons">
              <div class="status-info">
                                <span class="status-icon">
                                    <i class="fas fa-hourglass-half"></i>
                                </span>
                <h4>{{ expireDate }}</h4>
                <p>Expire Date</p>
              </div>
              <Divider layout="vertical"></Divider>
              <div class="status-info">
                                <span class="status-icon">
                                    <!-- <i class="fas fa-hourglass-half"></i> -->
                                    <img alt="Logo" src="/assets/layout/images/circle-progress-bar.png"
                                         class="circle-progress-bar"/>
                                </span>
                <h4>{{ timeLeft }} Days Left</h4>
                <p>Subscription</p>
              </div>
            </div>

            <DataTable :value="subscriptionStatus" :resizableColumns="true" columnResizeMode="fit">
              <Column field="title" bodyStyle="width: 10em">
                <template #body="slotProps">
                  <div class="package-name">{{ slotProps.data.title }}</div>
                </template>
              </Column>
              <Column field="consumed" bodyStyle="width: 12em">
                <template #body="slotProps">
                  <div class="package-no">{{ slotProps.data.consumed }}</div>
                </template>
              </Column>
              <Column field="remaining">
                <template #body="slotProps">
                  <div class="limits">
                    <p>{{ slotProps.data.remaining }}</p>
                  </div>
                </template>
              </Column>
            </DataTable>
          </div>
          <div v-else class="no-subscription">
            <h1>No Subscription</h1>
          </div>
        </div>
      </div>
    </div>
    <!-- <pre>image===>{{ image }}</pre>
    <pre>name===>{{ name }}</pre>
    <pre>email===>{{ email }}</pre>
    <pre>phone===>{{ phone }}</pre>
    <pre>country===>{{ country }}</pre>
    <pre>city===>{{ city }}</pre>
    <pre>address===>{{ address }}</pre>
    <pre>companyName===>{{ companyName }}</pre>
    <pre>industry===>{{ industry }}</pre>
    <pre>accountStatus===>{{ accountStatus }}</pre>
    <pre>paymentStatus===>{{ paymentStatus }}</pre>
    <pre>registered===>{{ registered }}</pre>
    <pre>packageTitle===>{{ packageTitle }}</pre>
    <pre>packagePrice===>{{ packagePrice }}</pre>
    <pre>testRuns===>{{ testRuns }}</pre>
    <pre>testCases===>{{ testCases }}</pre>
    <pre>projects===>{{ projects }}</pre>
    <pre>upgradeable===>{{ upgradeable }}</pre>
    <pre>renewable===>{{ renewable }}</pre>
    <pre>mobileAppTesting===>{{ mobileAppTesting }}</pre>
    <pre>cloudExecution===>{{ cloudExecution }}</pre>
    <pre>webTesting===>{{ webTesting }}</pre>
    <pre>mobileWebTesting===>{{ mobileWebTesting }}</pre>
    <pre>mobileWebTesting===>{{ mobileWebTesting }}</pre>
    <pre>testRailIntegration===>{{ testRailIntegration }}</pre>
    <pre>jiraIntegration===>{{ jiraIntegration }}</pre>
    <pre>chromeBrowserTesting===>{{ chromeBrowserTesting }}</pre>
    <pre>chromeExtension===>{{ chromeExtension }}</pre>
    <pre>testExecutedLocally===>{{ testExecutedLocally }}</pre>
    <pre>liveChat===>{{ liveChat }}</pre>
    <pre>parallelTestRun===>{{ parallelTestRun }}</pre>
    <pre>dedicatedResource===>{{ dedicatedResource }}</pre>
    <pre>image===>{{ image }}</pre>
    <pre>image===>{{ image }}</pre>
    <pre>image===>{{ image }}</pre>
    <pre>packageTitle===>{{ packageTitle }}</pre>
    <pre>client_information===>{{ client_information }}</pre> -->
  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import ProductService from '../service/ProductService';
import {dateFormatter} from '@/utils/helpers';

const moment = require('moment');

export default {
  name: 'ClientInformation',
  props: {
    client_information: {
      type: Object,
      required: true,
    },
  },
  components: {},
  name: 'ClientInformation',
  data() {
    return {
      defaultAvatar: '/assets/layout/images/TestJet-Logo-Final.png',
      image: '',
      name: '',
      role: '',
      email: '',
      phone: '',
      country: '',
      city: '',
      address: '',
      companyName: '',
      industry: '',
      email: '',
      accountStatus: '',
      paymentStatus: '',
      registered: '',

      packageTitle: '',
      packagePrice: '',
      testRuns: null,
      testCases: null,
      projects: null,
      upgradeable: null,
      renewable: null,
      mobileAppTesting: null,
      cloudExecution: null,
      webTesting: null,
      mobileWebTesting: null,
      testRailIntegration: null,
      jiraIntegration: null,
      chromeBrowserTesting: null,
      chromeExtension: null,
      testExecutedLocally: null,
      liveChat: null,
      parallelTestRun: null,
      dedicatedResource: null,

      expireDate: null,
      timeLeft: null,
      subscriptionStatus: null,
    };
  },
  productService: null,
  interval: null,

  created() {
    this.productService = new ProductService();
  },
  mounted() {
    // this.productService.getProductsWithOrdersSmall().then((data) => (this.products = data));
    this.startProgress();

    let firstName = this.client_information && this.client_information.user_profile && this.client_information.user_profile.first_name;
    let lastName = this.client_information && this.client_information.user_profile && this.client_information.user_profile.last_name;
    this.image = this.client_information && this.client_information.user_profile && this.client_information.user_profile.user_photo && this.client_information.user_profile.user_photo.photo_url;
    this.name = firstName + ' ' + lastName;
    this.role = this.client_information && this.client_information.role_id && this.client_information.role_id.name;
    this.email = this.client_information && this.client_information.email;
    this.phone = this.client_information && this.client_information.user_profile && this.client_information.user_profile.phone;
    this.country =
        this.client_information && this.client_information.user_profile && this.client_information.user_profile.city_id && this.client_information.user_profile.city_id.country_id && this.client_information.user_profile.city_id.country_id.name;
    this.city = this.client_information && this.client_information.user_profile && this.client_information.user_profile.city_id && this.client_information.user_profile.city_id.name;
    this.address = this.client_information && this.client_information.user_profile && this.client_information.user_profile.address;
    this.companyName = this.client_information && this.client_information.user_profile && this.client_information.user_profile.company_name;
    this.industry = this.client_information && this.client_information.user_profile && this.client_information.user_profile.industry;
    this.accountStatus = this.client_information && this.client_information.user_status;
    this.paymentStatus = this.client_information && this.client_information.subscription_plan && this.client_information.subscription_plan.status_code;
    this.registered = dateFormatter(this.client_information && this.client_information.createdAt);

    this.packageTitle = this.client_information && this.client_information.subscription_plan && this.client_information.subscription_plan.active_plan && this.client_information.subscription_plan.active_plan.title;
    this.packagePrice = this.client_information && this.client_information.subscription_plan && this.client_information.subscription_plan.active_plan && this.client_information.subscription_plan.active_plan.price;
    this.testRuns =
        this.client_information &&
        this.client_information.subscription_plan &&
        this.client_information.subscription_plan.active_plan &&
        this.client_information.subscription_plan.active_plan.package_configuration &&
        this.client_information.subscription_plan.active_plan.package_configuration.test_run_limit;
    this.testCases =
        this.client_information &&
        this.client_information.subscription_plan &&
        this.client_information.subscription_plan.active_plan &&
        this.client_information.subscription_plan.active_plan.package_configuration &&
        this.client_information.subscription_plan.active_plan.package_configuration.test_case_limit;
    this.projects =
        this.client_information &&
        this.client_information.subscription_plan &&
        this.client_information.subscription_plan.active_plan &&
        this.client_information.subscription_plan.active_plan.package_configuration &&
        this.client_information.subscription_plan.active_plan.package_configuration.project_limit;
    this.upgradeable = this.client_information && this.client_information.subscription_plan && this.client_information.subscription_plan.active_plan && this.client_information.subscription_plan.active_plan.is_upgrade;
    this.renewable = this.client_information && this.client_information.subscription_plan && this.client_information.subscription_plan.active_plan && this.client_information.subscription_plan.active_plan.is_renewal;
    this.mobileAppTesting = this.client_information && this.client_information.subscription_plan && this.client_information.subscription_plan.active_plan && this.client_information.subscription_plan.active_plan.mobile_app_testing;
    this.cloudExecution = this.client_information && this.client_information.subscription_plan && this.client_information.subscription_plan.active_plan && this.client_information.subscription_plan.active_plan.execution_in_cloud;
    this.webTesting = this.client_information && this.client_information.subscription_plan && this.client_information.subscription_plan.active_plan && this.client_information.subscription_plan.active_plan.web_testing;
    this.mobileWebTesting = this.client_information && this.client_information.subscription_plan && this.client_information.subscription_plan.active_plan && this.client_information.subscription_plan.active_plan.mobile_web_testing;
    this.testRailIntegration = this.client_information && this.client_information.subscription_plan && this.client_information.subscription_plan.active_plan && this.client_information.subscription_plan.active_plan.test_rail_integration;
    this.jiraIntegration = this.client_information && this.client_information.subscription_plan && this.client_information.subscription_plan.active_plan && this.client_information.subscription_plan.active_plan.jira_integration;
    this.chromeBrowserTesting = this.client_information && this.client_information.subscription_plan && this.client_information.subscription_plan.active_plan && this.client_information.subscription_plan.active_plan.chrome_browser_testing;
    this.chromeExtension = this.client_information && this.client_information.subscription_plan && this.client_information.subscription_plan.active_plan && this.client_information.subscription_plan.active_plan.chrome_extention;
    this.testExecutedLocally = this.client_information && this.client_information.subscription_plan && this.client_information.subscription_plan.active_plan && this.client_information.subscription_plan.active_plan.local_browser_testing;
    this.liveChat = this.client_information && this.client_information.subscription_plan && this.client_information.subscription_plan.active_plan && this.client_information.subscription_plan.active_plan.live_chat_technical_support;
    this.parallelTestRun = this.client_information && this.client_information.subscription_plan && this.client_information.subscription_plan.active_plan && this.client_information.subscription_plan.active_plan.parallel_test_run;
    this.dedicatedResource = this.client_information && this.client_information.subscription_plan && this.client_information.subscription_plan.active_plan && this.client_information.subscription_plan.active_plan.dedicated_resource;

    this.expireDate = moment(this.client_information && this.client_information.subscription_plan && this.client_information.subscription_plan.consumed_plan && this.client_information.subscription_plan.consumed_plan.validity_expire).format(
        'DD MMMM, YYYY'
    );
    // this.expectedDate = moment(this.client_information && this.client_information.subscription_plan && this.client_information.subscription_plan.consumed_plan && this.client_information.subscription_plan.consumed_plan.validity_expire).format('YYYY-MM-DD');
    // let current = moment().startOf('day');
    // this.timeLeft = moment.duration(expectedDate.diff(current)).asDays();
    // this.timeLeft = moment(this.client_information && this.client_information.subscription_plan && this.client_information.subscription_plan.consumed_plan && this.client_information.subscription_plan.consumed_plan.validity_expire).fromNow('days', true);
    this.timeLeft = moment(this.client_information && this.client_information.subscription_plan && this.client_information.subscription_plan.consumed_plan && this.client_information.subscription_plan.consumed_plan.validity_expire).diff(
        {hours: 0},
        'days'
    );
    // let start = moment();
    // let end = this.expireDate;
    // this.timeLeft = moment.duration(end.diff(start)).asDays();

    let totalProjects =
        this.client_information &&
        this.client_information.subscription_plan &&
        this.client_information.subscription_plan.active_plan &&
        this.client_information.subscription_plan.active_plan.package_configuration &&
        this.client_information.subscription_plan.active_plan.package_configuration.project_limit;
    let consumedProjects = this.client_information && this.client_information.subscription_plan && this.client_information.subscription_plan.consumed_plan && this.client_information.subscription_plan.consumed_plan.project_limit;

    let remainingProjects = totalProjects > consumedProjects ? `${totalProjects - consumedProjects} Left for this Subscription` : '0 Left for this Subscription';
    let remainingTestRuns =
        this.client_information &&
        this.client_information.subscription_plan &&
        this.client_information.subscription_plan.active_plan &&
        this.client_information.subscription_plan.active_plan.package_configuration &&
        this.client_information.subscription_plan.active_plan.package_configuration.test_run_limit === 0
            ? 'Unlimited'
            : (this.client_information &&
                this.client_information.subscription_plan &&
                this.client_information.subscription_plan.active_plan &&
                this.client_information.subscription_plan.active_plan.package_configuration &&
                this.client_information.subscription_plan.active_plan.package_configuration.test_run_limit) -
            (this.client_information && this.client_information.subscription_plan && this.client_information.subscription_plan.consumed_plan && this.client_information.subscription_plan.consumed_plan.test_run_limit);
    let remainingTestCases =
        this.client_information &&
        this.client_information.subscription_plan &&
        this.client_information.subscription_plan.active_plan &&
        this.client_information.subscription_plan.active_plan.package_configuration &&
        this.client_information.subscription_plan.active_plan.package_configuration.test_case_limit === 0
            ? 'Unlimited'
            : (this.client_information &&
                this.client_information.subscription_plan &&
                this.client_information.subscription_plan.active_plan &&
                this.client_information.subscription_plan.active_plan.package_configuration &&
                this.client_information.subscription_plan.active_plan.package_configuration.test_case_limit) -
            (this.client_information && this.client_information.subscription_plan && this.client_information.subscription_plan.consumed_plan && this.client_information.subscription_plan.consumed_plan.test_case_limit);
    let remainingDedicatedResource =
        this.client_information && this.client_information.subscription_plan && this.client_information.subscription_plan.active_plan && this.client_information.subscription_plan.active_plan.dedicated_resource
            ? '0 Left for this Subscription'
            : 'No Dedicated Resource for this Subscription';

    this.subscriptionStatus = [
      {
        title: 'Projects',
        consumed: this.client_information && this.client_information.subscription_plan && this.client_information.subscription_plan.consumed_plan && this.client_information.subscription_plan.consumed_plan.project_limit,
        remaining: remainingProjects,
      },
      {
        title: 'Test Runs',
        consumed: this.client_information && this.client_information.subscription_plan && this.client_information.subscription_plan.consumed_plan && this.client_information.subscription_plan.consumed_plan.test_run_limit,
        remaining: remainingTestRuns,
      },
      {
        title: 'Test Cases',
        consumed: this.client_information && this.client_information.subscription_plan && this.client_information.subscription_plan.consumed_plan && this.client_information.subscription_plan.consumed_plan.test_case_limit,
        remaining: remainingTestCases,
      },
      {
        title: 'Dedicated Resource',
        consumed:
            this.client_information && this.client_information.subscription_plan && this.client_information.subscription_plan.active_plan && this.client_information.subscription_plan.active_plan.dedicated_resource
                ? '1'
                : 'No Dedicated Resource',
        remaining: remainingDedicatedResource,
      },
    ];
  },
  computed: {
    ...mapState({
      username: (state) => state.user.name,
    }),

    ...mapGetters({
      getTestCaseList: 'testCase/testCaseList',
    }),
  },

  methods: {
    // reload() {
    //     location.reload();
    // },

    changeView() {
      this.$emit('changeView', 1);
    },

    toggleDropdown(event) {
      event.currentTarget.classList.toggle('is-active');
    },
    addOrRemoveTags() {
      this.displayAddOrRemoveTags = true;
    },
    handleEnter() {
      if (this.tagname.length > 0) {
        this.tags.push(this.tagname);
        this.tagname = '';
      }
    },
    startProgress() {
      this.interval = setInterval(() => {
        let newValue = this.progressBar + Math.floor(Math.random() * 10) + 1;
        if (newValue >= 100) {
          newValue = 100;
        }
        this.progressBar = newValue;
      }, 2000);
    },
    endProgress() {
      clearInterval(this.interval);
      this.interval = null;
    },
    onSortChange(event) {
      const value = event.value.value;
      const sortValue = event.value;

      if (value.indexOf('!') === 0) {
        this.sortOrder = -1;
        this.sortField = value.substring(1, value.length);
        this.sortKey = sortValue;
      } else {
        this.sortOrder = 1;
        this.sortField = value;
        this.sortKey = sortValue;
      }
    },
    toggle(event) {
      this.$refs.op.toggle(event);
    },
    onRowExpand(event) {
      this.$toast.add({severity: 'info', summary: 'Product Expanded', detail: event.data.name, life: 3000});
    },
    onRowCollapse(event) {
      this.$toast.add({severity: 'success', summary: 'Product Collapsed', detail: event.data.name, life: 3000});
    },
    expandAll() {
      this.expandedRows = this.products.filter((p) => p.id);
      this.$toast.add({severity: 'success', summary: 'All Rows Expanded', life: 3000});
    },
    collapseAll() {
      this.expandedRows = null;
      this.$toast.add({severity: 'success', summary: 'All Rows Collapsed', life: 3000});
    },
    formatCurrency(value) {
      return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
    },
    handleSelectedBugType(selectedBugId) {
      this.bugTypeModalVisible = !this.bugTypeModalVisible;
      console.log('selectedBugType', selectedBugId);
    },
  },
  computed: {
    errorsSuccessList() {
      return true;
    },
    errorsFailedList() {
      return false;
      // return this.steps.filter((step) => step.error && step.errors.status === false) === this.steps.length;
    },
    //
    successExecutionTime() {
      var times = [];
      Array.from(this.steps).map((s) => (s.errors.status === false && s.errors.type === 'success' ? s.errors.execution_time : 0));
      var total = 0;
      Array.from(times).forEach((t) => {
        total += t;
      });
      return total / 1000;
    },
    executionTime() {
      var times = Array.from(this.steps).map((s) => (s.errors ? s.errors.execution_time : 0));
      var total = 0;

      Array.from(times).forEach((t) => {
        total += t;
      });
      return total / 1000;
    },
  },
};
</script>
<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
